import React from 'react'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import {
	DOCTOR_GROUP_SUBMENU_GENERAL_KEY,
	DOCTOR_GROUP_SUBMENU_TEAMS_KEY,
	DOCTOR_GROUP_SUBMENU_EXAMS_KEY,
	DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY,
	DoctorGroupUtils,
	DoctorGroupViewTP,
} from 'modules/doctor-groups/utils/DoctorGroupUtils'

/**
 */
export function ScreenDoctorGroup(): JSX.Element {
	/**
	 */

	if (!AppStateUtils.getCurrentDoctorGroup()) return <WelcomeContentSelectDoctorGroupCP />

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: {
					doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()!.code,
					permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
				},
			}}
			redirect={{ redirectTo: PrivateUserRouter.HOME, shouldRedirect: true }}
		>
			<LayoutSubmenuCP<DoctorGroupViewTP>
				titleBrowser={'Grupo de Médico'}
				loggedDomain={AppStateUtils.getDomain()}
				menuTitle={AppStateUtils.getCurrentDoctorGroup()?.name ?? 'Selecione um Grupo de Médicos'}
				defaultSubmenu={'doctorGroupData'}
				defaultOpenKeys={[
					DOCTOR_GROUP_SUBMENU_GENERAL_KEY,
					DOCTOR_GROUP_SUBMENU_TEAMS_KEY,
					DOCTOR_GROUP_SUBMENU_EXAMS_KEY,
					DOCTOR_GROUP_SUBMENU_FINANCIAL_KEY,
				]}
				submenuConfigs={DoctorGroupUtils.getSubMenuConfigs(AppStateUtils.getCurrentDoctorGroup()!.code)}
			/>
		</AccessControlCP>
	)
}
