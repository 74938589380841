import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ClinicRelationPermissionTP } from 'modules/auth/permissions/ClinicPermissionUtils'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { SelectRectificationReason } from 'modules/exams/components/exam-analysis/select-rectification-reason/SelectRectificationReason'

interface ICPProps {
	reportType: ReportTypeEnum
	isLoading?: boolean
	filterFormStateManager: IFormStateManager<CommonReportsFilterFormModel>
	onFilter: () => void
	fieldsConfig?: {
		hideDoctorFilter?: boolean
		showConsiderArchived?: boolean
		dateRangeMode?: 'month'
		hideDateSwitch?: boolean
		showReasonFielter?: boolean
	}
}

/**
 */
export function FiltersReportCommonCP(props: ICPProps): JSX.Element {
	const permissions: ClinicRelationPermissionTP[] = []
	switch (props.reportType) {
		case ReportTypeEnum.CLINIC_REPORT:
			permissions.push('isAdmin', 'isUser')
			break

		case ReportTypeEnum.DOCTOR_GROUP_REPORT:
			permissions.push('isDoctorGroupAdmin', 'isDoctorGroupUser')
			break

		case ReportTypeEnum.DOCTOR_REPORT:
			permissions.push('isDoctor')
			break
	}

	/**
	 */
	function filter(): void {
		const formValues = props.filterFormStateManager.getFormValues()
		if (!FormStateManagerUtils.validateRequiredDateRange(formValues?.dateRange)) return

		props.onFilter()
	}

	return (
		<ContentSCP>
			<FlexOneICP>
				<RowCP>
					<ColumnCP size={props.fieldsConfig?.hideDoctorFilter ? 8 : 6}>
						<FlexCP>
							<DateRangePickerCP
								showBorder={true}
								marginTop={23}
								fastFilter={'all'}
								formStateManager={props.filterFormStateManager}
								fieldName={'dateRange'}
								mode={props.fieldsConfig?.dateRangeMode}
								allowClear={false}
							/>
							{!props.fieldsConfig?.hideDateSwitch && (
								<SwitchWrapperSCP>
									<SwitchCP
										label={'Data de:'}
										labelAbove={true}
										checkedLabels={{
											checked: 'Laudo',
											unchecked: 'Exame',
										}}
										formStateManager={props.filterFormStateManager}
										fieldName={'shouldConsiderMedicalReportDate'}
									/>
								</SwitchWrapperSCP>
							)}
						</FlexCP>
					</ColumnCP>
					<ColumnCP size={props.fieldsConfig?.hideDoctorFilter ? 8 : 4}>
						<SelectClinicFromLoggedUserCP
							permissions={permissions}
							value={{
								byFormStateManager: {
									formStateManager: props.filterFormStateManager,
									fieldName: 'clinicCodes',
								},
							}}
							multiple={{
								isMultiple: true,
								maxTagCount: 1,
								clearSearchTextOnSelect: false,
							}}
						/>
					</ColumnCP>
					<ColumnCP size={props.fieldsConfig?.hideDoctorFilter ? 8 : 4}>
						<SelectDoctorGroupFromLoggedUserCP
							formStateManager={props.filterFormStateManager}
							fieldName={'doctorGroupCode'}
							required={props.reportType === ReportTypeEnum.DOCTOR_GROUP_REPORT}
							allowClear={props.reportType !== ReportTypeEnum.DOCTOR_GROUP_REPORT}
						/>
					</ColumnCP>
					{!props.fieldsConfig?.hideDoctorFilter && (
						<ColumnCP size={4}>
							<SelectDoctorGroupPeopleCP
								doctorGroupCode={props.filterFormStateManager.getFieldValue('doctorGroupCode')}
								role={UserTypeEnum.DOCTOR}
								formStateManager={props.filterFormStateManager}
								formField={'doctorCode'}
							/>
						</ColumnCP>
					)}
					<ColumnCP size={6}>
						{props.fieldsConfig?.showReasonFielter && (
							<SelectRectificationReason
								allowClear={true}
								fieldName={'rectificationReasons'}
								formStateManager={props.filterFormStateManager}
								multiple={{
									isMultiple: true,
									maxTagCount: 1,
								}}
							/>
						)}
					</ColumnCP>
				</RowCP>
			</FlexOneICP>

			<FlexCP alignItems={'center'}>
				{props.fieldsConfig?.showConsiderArchived && (
					<SwitchCP label={'Arquivados?'} labelAbove={true} formStateManager={props.filterFormStateManager} fieldName={'considerArchived'} />
				)}

				<ButtonCP marginLeft={10} marginTop={25} type={'primary'} onClick={filter} loading={props.isLoading}>
					Filtrar
				</ButtonCP>
			</FlexCP>
		</ContentSCP>
	)
}

const ContentSCP = styled.div`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
	display: flex;
	align-items: center;
`

const SwitchWrapperSCP = styled.div`
	margin-top: 10px;
	margin-left: 5px;
	.ant-typography {
		line-height: 20px;
	}
`
