import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/list/main.css'
import '@fullcalendar/timegrid/main.css'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import React, { useEffect, useRef } from 'react'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export interface IFullCalendarEvent {
	id: number
	title: string
	start: string | Date
	end: string | Date
	backgroundColor?: string
	textColor?: string
	borderColor?: string
	extendedProps?: any
}

interface IFullCalendarCPProps {
	onDateClick?: (dateInfo: any) => void
	onEventClick?: (eventInfo: any) => void
	onDateRangeChange?: (dateRange: DateRangeSearchRequestDTO) => void
	events: IFullCalendarEvent[]
	date?: Date
	loading?: boolean
}

/**
 * Componente FullCalendar para exibicao do calendario.
 */
export function FullCalendarCP(props: IFullCalendarCPProps): JSX.Element {
	const screenSize = useScreenSize()
	const calendarRef = useRef<FullCalendar>(null)

	useEffect(() => {
		if (!!calendarRef.current && props.date) {
			const calendarApi = calendarRef.current.getApi()
			calendarApi.gotoDate(props.date)
		}
	}, [props.date])

	return (
		<CalendarWrapperSCP isSmallDevice={screenSize.smd}>
			<LoadingOverlayCP show={!!props.loading} />

			<FullCalendar
				ref={calendarRef}
				height={'parent'}
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
				defaultView={screenSize.smd ? 'timeGridDay' : 'timeGridWeek'}
				locale={{
					code: 'pt-br',
					buttonText: {
						prev: 'Anterior',
						next: 'Próximo',
						today: 'Hoje',
						month: 'Mês',
						week: 'Semana',
						day: 'Dia',
						list: 'Lista',
					},
					weekLabel: 'Sm',
					allDayText: 'dia inteiro',
					eventLimitText(n: string) {
						return `mais +${n}`
					},
					eventTimeFormat: {
						// Formato do horário dos eventos
						hour: 'numeric',
						minute: '2-digit',
						separator: ' - ', // Separador entre o horário de início e fim
						meridiem: false, // Não exibir AM/PM
					},
					noEventsMessage: 'Não há eventos para mostrar',
				}}
				nowIndicator={true}
				slotLabelInterval={'01:00'}
				slotDuration={'01:00'}
				slotLabelFormat={{
					hour: 'numeric',
					minute: '2-digit',
					omitZeroMinute: false,
					meridiem: 'short',
				}}
				header={{
					left: 'prev,next today',
					center: 'title',
					right: screenSize.smd ? '' : 'dayGridMonth, timeGridWeek, listWeek',
				}}
				allDaySlot={false}
				events={props.events}
				dateClick={props.onDateClick}
				eventClick={props.onEventClick}
				datesRender={(arg) => {
					if (!!props.onDateRangeChange) props.onDateRangeChange({ beginDate: arg.view.activeStart, endDate: arg.view.activeEnd })
				}}
			/>
		</CalendarWrapperSCP>
	)
}

const CalendarWrapperSCP = styled.div<{ isSmallDevice: boolean }>`
	height: auto;
	justify-content: center;
	align-items: center;
	display: flex;

	.fc-header-toolbar {
		padding: 20px 20px 0 20px;

		h2 {
			font-size: ${(props) => (props.isSmallDevice ? '18px' : '')};
		}
	}

	.fc-view,
	.fc-resourceTimeGridDay-view,
	.fc-timeGrid-view,
	.fc.fc-ltr.fc-unthemed {
		width: 100%;
	}

	.fc-dayGrid-view .fc-body .fc-row {
		height: 100px;
	}

	.fc-list-heading td {
		padding: 10px;
		background: #fce6d6 !important;
		color: ${ThemeFrameworkCommon.browserDefaultColorLight};
	}

	.fc-list-item:hover td {
		background-color: ${ThemeProject.gray} !important;
	}

	.fc-list-item td {
		padding: 7px 10px;
		border: none;
		border-bottom: solid 1px #dddddd;
	}

	.fc-event {
		border-radius: 0 !important;
		z-index: -9999;
		display: block;

		.fc-title {
			white-space: normal;
			font-weight: 500;
		}

		.fc-content {
			padding: 2px;
		}
	}

	.fc-ltr .fc-time-grid .fc-event-container {
		margin: 0;
	}

	.fc-view {
		overflow-x: scroll;
	}

	.fc-view > table {
		width: auto;
		/* min-width: 150%; */
		border: none;
		border-collapse: collapse;
		table-layout: fixed;
		z-index: 999;

		.fc-head-container {
			border-top: none;
		}
	}

	.fc-license-message {
		display: none;
	}

	.fc-scroller,
	.fc-time-grid-container {
		white-space: nowrap;
		//height: 66vh !important;
		overflow-x: visible !important;
		overflow-y: scroll !important;
	}

	.fc-widget-header {
		border: none;
	}

	.fc-body > tr > td.fc-widget-content:first-child {
		border: none;
	}

	.fc-day {
		border-left: none !important;
	}

	.fc-today {
		background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight} !important;
	}

	.fc-axis {
		border-right: none !important;
		background: #fce6d6;
		color: #083991;
		font-size: 13px;
		padding: 0 10px !important;
	}

	.fc-slats td {
		height: 2rem !important;

		&:hover {
		}

		&.fc-widget-content {
			border-left: none !important;
			z-index: 99999;
		}
	}

	th.fc-resource-cell {
		height: 4rem;
		vertical-align: middle;
		color: #645869;
		font-weight: 900;
		font-size: 15px;
	}

	th.fc-axis {
		border-bottom: 1px solid #fce2d0 !important;
		border-radius: 0.5rem 0.5rem 0 0;
	}

	th:nth-of-type(2) {
		border-left: none !important;
	}

	.fc-row table {
		border-bottom: none;
	}

	tr[data-time]:last-of-type {
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	}

	.fc-toolbar h2 {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
`
