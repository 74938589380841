import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { ButtonMultiActionMedicalReportCP } from 'modules/exams/components/medical-report/button-multi-action-medical-report/ButtonMultiActionMedicalReportCP'
import React from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ButtonBackMedicalReportHeaderCP } from 'modules/exams/screens/screen-medical-report/header/inner/ButtonBackMedicalReportHeaderCP'
import styled from 'styled-components'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	searchExamDto?: ExamSearchRequestDTO
}

/**
 * Header da tela de edição de laudo.
 */
export function HeaderMedicalReportScreenCP(props: ICPProps): JSX.Element {
	return (
		<HeaderCP
			title={
				<>
					<ButtonBackMedicalReportHeaderCP exam={props.exam} formStateManager={props.formStateManager} searchExamDto={props.searchExamDto} />
					<TitleCP marginBottom={2}>Laudo</TitleCP>
				</>
			}
			middleContent={
				<ExamInfoSCP>
					<AvatarExamModalityCP modality={props.exam.modality} fontSize={'normal'} />

					<TextWrapperSCP>
						<TextCP text={props.exam.description} strong={true} size={'extraLarge'} />
						<TextCP text={props.exam.patient.name} size={'extraSmall'} />
					</TextWrapperSCP>
				</ExamInfoSCP>
			}
		>
			<ButtonMultiActionMedicalReportCP exam={props.exam} formStateManager={props.formStateManager} searchExamDto={props.searchExamDto} />
		</HeaderCP>
	)
}

const ExamInfoSCP = styled.div`
	display: flex;

	@media (max-width: 992px) {
		display: none;
	}
`

const TextWrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 5px;
`
