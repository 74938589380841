import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { ProjectWhiteLabelPatientPortalDomainsEnum, ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'

export const PATIENT_PORTAL_PATH = 'public/patient-portal-login'

/**
 */
export class PatientPortalAccessUtils {
	/**
	 */
	static getBeeRadsLaudoUrl(showFullUrl: boolean, whiteLabel?: ProjectWhiteLabelsEnum): string {
		let path = `${whiteLabel ?? ProjectWhiteLabelsEnum.VX}/${PATIENT_PORTAL_PATH}`

		if (NeritFrameworkProjectConfig.isDev()) return `http://localhost:3000/${path}`

		const shortPath = ProjectWhiteLabelPatientPortalDomainsEnum.VX

		return showFullUrl ? `${shortPath}/${path}` : shortPath
	}
}
