import React from 'react'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { CommentCP } from 'submodules/nerit-framework-ui/common/components/comment/CommentCP'
import { AuditResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/AuditResponseDTO'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface ICPProps {
	audit: AuditResponseDTO
}

/**
 */
export function PopoverAuditCP(props: ICPProps): JSX.Element {
	if (!props.audit.comments) return <></>

	return (
		<PopOverCP
			trigger={'hover'}
			placement={'topLeft'}
			content={
				<CommentCP
					comment={{
						text: props.audit.comments,
						user: props.audit.doctor,
						date: new Date(props.audit.date),
					}}
				/>
			}
		>
			<IconICP iconName={'message'} />
		</PopOverCP>
	)
}
