import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerExamPendingAnalysisCP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/DrawerExamPendingAnalysisCP'

interface ICPProps {
	examCode: number
	examId: string
	onSave: () => void
}

/**
 * Botao para triar exame.
 */
export function ButtonAnalysePendingExamCP(props: ICPProps): JSX.Element {
	const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP type={'primary'} size={'small'} onClick={() => setIsDrawerVisible(true)} icon={'file-search'} marginRight={5}>
				Analisar
			</ButtonCP>

			<DrawerExamPendingAnalysisCP
				examCode={props.examCode}
				examId={props.examId}
				visible={isDrawerVisible}
				onCancel={() => setIsDrawerVisible(false)}
				onSave={() => {
					setIsDrawerVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
