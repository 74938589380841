import { Tabs } from 'antd'
import * as React from 'react'
import { TabItemTP } from 'submodules/nerit-framework-ui/common/components/tabs/inner/TabItemTP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps extends BasicStyleWrapperCPProps {
	tabs: TabItemTP[]
	type?: 'line' | 'card' | 'editable-card'
	tabPosition?: 'top' | 'right' | 'bottom' | 'left'
	extraContent?: JSX.Element
	defaultActiveKey?: string
	onChangeTab?: (key: string) => void
	editableConfig?: {
		onAddTab: () => void
		onRemoveTab: (tabKey: string) => void
	}
}

/**
 * Componente de abas customizado para o projeto.
 */
export function TabsCP(props: ICPProps): JSX.Element {
	/**
	 * Ao clicar para adicionar ou remover uma aba
	 */
	function onEdit(tab: string | React.MouseEvent<HTMLElement>, action: 'add' | 'remove'): void {
		if (action === 'add' && !!props.editableConfig?.onAddTab) return props.editableConfig.onAddTab()

		if (action === 'remove' && !!props.editableConfig?.onRemoveTab && typeof tab === 'string') return props.editableConfig.onRemoveTab(tab)
	}

	return (
		<BasicStyleWrapperCP margin={props.margin} padding={props.padding}>
			<WrapperSCP>
				<Tabs
					type={props.type}
					defaultActiveKey={props.defaultActiveKey}
					// activeKey={props.defaultActiveKey}
					tabPosition={props.tabPosition}
					animated={false} // AntD esta com bug quando tem animacao e conteudo dinamico, por isso passando sempre fasle
					tabBarExtraContent={props.extraContent}
					onEdit={onEdit}
					onChange={props.onChangeTab}
				>
					{props.tabs
						.filter((tab) => !tab.hide)
						.map((tab: TabItemTP) => (
							<Tabs.TabPane tab={tab.title} key={tab.key} disabled={tab.disabled}>
								{tab.content}
							</Tabs.TabPane>
						))}
				</Tabs>
			</WrapperSCP>
		</BasicStyleWrapperCP>
	)
}

const WrapperSCP = styled.div`
  
  .ant-tabs > .ant-tabs-bar > .ant-tabs-nav-container > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll > .ant-tabs-nav {

    .ant-tabs-ink-bar {
      background: ${ThemeProject.secondary};
      height: 3px;
    }

    .ant-tabs-tab {
      color: ${ThemeFrameworkCommon.browserDefaultColorDark};
      font-weight: 500;
      background-color: inherit;
    }
    
    .ant-tabs-tab-active{
      background-color: inherit;
    }
  }

  .ant-tabs-bar {
    border-bottom: 1px solid ${ThemeFrameworkCommon.browserDefaultColorDark}10;
  }

  .ant-tabs .ant-tabs-extra-content .ant-tabs-new-tab {
    width: 40px;
    height: 40px;
    background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}30;
    color: ${ThemeFrameworkCommon.browserDefaultColorDark};
    border-radius: 3px;
    border: none;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab{
    border: 1px solid ${ThemeFrameworkCommon.browserDefaultColorDark}30;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active{
    border-bottom: 1px solid ${ThemeFrameworkCommon.browserDefaultColorLight};
  }
  
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x svg {
    fill: ${ThemeFrameworkCommon.browserDefaultColorDark};
  }

  .ant-tabs .ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: 1px solid ${ThemeFrameworkCommon.browserDefaultColorDark}10;
  }

  .ant-tabs-extra-content { 
    margin: 0;
  }

  .ant-tabs-bar {
    margin: 0;
  }
  
  .ant-tabs-content {
	  color: ${ThemeFrameworkCommon.browserDefaultColorDark}
    margin-top: 20px;
  } 
`
