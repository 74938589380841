import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

export const PendingExamUtils = {
	/**
	 * Retorna a modalidade de um nome do exame. Considera que antes do ESPAÇO há sempre a modalidade. Ex.: TC TORAX
	 */
	getModalityFromDescription(examDescription?: string): ExamModalityEnum | undefined {
		if (!examDescription) return undefined

		return examDescription.split(' ')[0] as ExamModalityEnum
	},
}
