import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import styled from 'styled-components'
import { AvcFormModel } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/form-model/AvcFormModel'

export interface IAvcSectionProps {
	formStateManager: IFormStateManager<AvcFormModel>
}

interface ICPProps {
	title?: string
	children: React.ReactNode
	appearance?: {
		paddingTop?: number
	}
}

export function AvcSectionContentICP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP paddingTop={props.appearance?.paddingTop ?? 0}>
			<CardCP width={'100%'} margin={'0'} title={props.title}>
				{props.children}
			</CardCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ paddingTop?: number }>`
	.ant-row {
		margin-bottom: 20px;
	}
	.ant-card-body {
		padding: ${(props) => props.paddingTop}px 10px 10px 10px !important;
	}
	.ant-card {
		border-radius: 0;
	}
	width: 100%;
`
