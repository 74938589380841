import React from 'react'
import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { BoldIconCP } from '_old/main/common/components/icons/BoldIconCP'
import { ItalicIconCP } from '_old/main/common/components/icons/ItalicIconCP'
import { TableIconCP } from '_old/main/common/components/icons/TableIconCP'
import { VoiceInputPlugin } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/VoiceInputPlugin'
import { VoiceIptPluginBtnPropsTP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceInputPluginTypes'
import { OrderedListIconCP } from '_old/main/common/components/icons/OrderedListIconCP'
import { UnorderedListIconCP } from '_old/main/common/components/icons/UnorderedListIconCP'
import { IToolbarDefaultBtnProps } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/IToolbarDefaultBtnProps'
import { ButtonGroupTP } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorTypes'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TableButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/TableButtonICP'
import { HistoryControlButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/HistoryControlButtonICP'
import { ButtonToolbarAddPhraseICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/ButtonToolbarAddPhraseICP'
import { TitleToggleButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/TitleToggleButtonICP'
import { VoiceInputButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/VoiceInputButtonICP'
import { HelpButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/HelpButtonICP'
import { FullscreenControlButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/FullscreenControlButtonICP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons'
import { LEORadButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/leoRad/LEORadButtonICP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

const SIZE_ICON_DEFAULT = 20

/**
 * Define lista de botoes padrao da barra de ferramentas do editor wysiwyg / draft js customizado.
 */
export const MedicalReportToolbarUtils = {
	getButtons(examModality?: ExamModalityEnum, hideAddPhraseButton?: boolean): Array<ButtonGroupTP<IToolbarDefaultBtnProps>> {
		return [
			{
				buttons: [
					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<ToolbarButtonICP
							inlineStyle={'BOLD'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<BoldIconCP color={ThemeFrameworkCommon.browserDefaultColorDark} size={SIZE_ICON_DEFAULT} />}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<ToolbarButtonICP
							inlineStyle={'ITALIC'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<ItalicIconCP color={ThemeFrameworkCommon.browserDefaultColorDark} size={SIZE_ICON_DEFAULT} />}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<TitleToggleButtonICP editorState={props.editorState} onEditorStateChange={props.onEditorStateChange} />
					),
				],
			},

			{
				buttons: [
					// (props: IToolbarDefaultBtnProps): JSX.Element => (
					//     <ToolbarButtonICP
					//         inlineStyle={'left'}
					//         editorState={props.editorState}
					//         onEditorStateChange={props.onEditorStateChange}
					//         innerContent={<FontAwsomeIconCP type={faAlignLeft} size={'lg'}/>}
					//     />
					// ),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<ToolbarButtonICP
							inlineStyle={'center'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<FontAwsomeIconCP type={faAlignCenter} size={'lg'} />}
						/>
					),

					// (props: IToolbarDefaultBtnProps): JSX.Element => (
					//     <ToolbarButtonICP
					//         inlineStyle={'justify'}
					//         editorState={props.editorState}
					//         onEditorStateChange={props.onEditorStateChange}
					//         innerContent={<FontAwsomeIconCP type={faAlignJustify} size={'lg'}/>}
					//     />
					// ),
					//
					// (props: IToolbarDefaultBtnProps): JSX.Element => (
					//     <ToolbarButtonICP
					//         inlineStyle={'right'}
					//         editorState={props.editorState}
					//         onEditorStateChange={props.onEditorStateChange}
					//         innerContent={<FontAwsomeIconCP type={faAlignRight} size={'lg'}/>}
					//     />
					// ),
				],
			},

			{
				buttons: [
					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<TableButtonICP
							showAddTableModal={props.showAddTableModal}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<TableIconCP color={ThemeFrameworkCommon.browserDefaultColorDark} size={SIZE_ICON_DEFAULT} />}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<ToolbarButtonICP
							blockType={'ordered-list-item'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<OrderedListIconCP color={ThemeFrameworkCommon.browserDefaultColorDark} size={SIZE_ICON_DEFAULT} />}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<ToolbarButtonICP
							blockType={'unordered-list-item'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							innerContent={<UnorderedListIconCP color={ThemeFrameworkCommon.browserDefaultColorDark} size={SIZE_ICON_DEFAULT} />}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<HistoryControlButtonICP
							type={'undo'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							iconColor={ThemeFrameworkCommon.browserDefaultColorDark}
							iconSize={SIZE_ICON_DEFAULT}
						/>
					),

					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<HistoryControlButtonICP
							type={'redo'}
							editorState={props.editorState}
							onEditorStateChange={props.onEditorStateChange}
							iconColor={ThemeFrameworkCommon.browserDefaultColorDark}
							iconSize={SIZE_ICON_DEFAULT}
						/>
					),
				],
			},

			{
				buttons: [
					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<FullscreenControlButtonICP
							setFullScreenMode={props.setFullScreenMode}
							isFullScreen={props.isFullScreen}
							iconColor={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
							iconSize={SIZE_ICON_DEFAULT}
						/>
					),
				],
			},

			{
				buttons: [
					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<VoiceInputPlugin.VoiceInputControl
							onVoiceDetected={props.onEditorStateChange}
							editorState={props.editorState}
							inlineStyle={props.voiceInputInlineStyle}
							renderButton={(buttonProps: VoiceIptPluginBtnPropsTP): JSX.Element => (
								<VoiceInputButtonICP
									editorState={props.editorState}
									onEditorChange={props.onEditorStateChange}
									isRecording={buttonProps.isRecording}
									isUnavailable={buttonProps.isUnavailable}
									onToggleIsRecording={buttonProps.onToggleIsRecording}
								/>
							)}
						/>
					),

					(): JSX.Element => <HelpButtonICP />,
				],
			},

			{
				hide: !AppStateUtils.getLoggedUser()?.preferences?.leoRadToken,
				buttons: [
					(props: IToolbarDefaultBtnProps): JSX.Element => (
						<LEORadButtonICP editorState={props.editorState} onEditorStateChange={props.onEditorStateChange} examModality={examModality} />
					),
				],
			},

			{
				hide: hideAddPhraseButton,
				buttons: [(props) => <ButtonToolbarAddPhraseICP examModality={examModality} {...props} />],
			},
		]
	},
}
