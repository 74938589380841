import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps extends BasicStyleWrapperCPProps {
	label?: string
	textToCopy: string
	onCopy?: () => void
	size?: ButtonSizeTP
	type?: ButtonTypeTP
	icon?: string
}

/**
 */
export function ButtonCopyToClipboardCP(props: ICPProps): JSX.Element {
	/**
	 * Ao clicar em copiar
	 */
	function onCopy(): void {
		navigator.clipboard.writeText(props.textToCopy)
		NotificationHelper.success('Yes', 'Copiado')

		if (!!props.onCopy) props.onCopy()
	}

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<ButtonCP icon={props.icon ?? 'copy'} onClick={onCopy} size={props.size} type={props.type}>
				{props.label}
			</ButtonCP>
		</BasicStyleWrapperCP>
	)
}
