import React, { useEffect, useState } from 'react'
import { ScreenWrapperQualityAssurance } from 'modules/quality-assurance/components/screen-wrapper-quality-assurance/ScreenWrapperQualityAssurance'
import { FiltersExamsCP } from 'modules/exams/components/exams/filters-exams/FiltersExamsCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TableExamsToAuditCP } from 'modules/quality-assurance/components/table-exams-to-audit/TableExamsToAuditCP'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

/**
 */
export function ScreenQualityAssuranceExams(): JSX.Element {
	const [formValidator, setFormValidator] = useState<ExamFilterFormModel>()
	const formStateManager = useFormStateManager<ExamFilterFormModel>(formValidator)

	const [loadList, setLoadList] = useState<number>(1)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		// Monta os filtros
		const examFilterFormModel = ExamFiltersFormModelConverter.formatFormModel({
			viewAsDoctor: false,
			statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE),
		})
		setFormValidator(examFilterFormModel)
	}

	return (
		<ScreenWrapperQualityAssurance headerTitle={'Central de Qualidade - Exames'} appearance={{ hideMargin: true }}>
			{!!formStateManager.getFormValues() && !!AppStateUtils.getCurrentDoctorGroup() && (
				<>
					<FiltersExamsCP
						filterFormStateManager={formStateManager}
						isLoading={isLoadingList}
						onFilter={() => {
							setLoadList(TableUtils.getReloadNumber())
							setIsLoadingList(true)
						}}
						isScreenInitialized={true}
						fieldsConfig={{
							disableStatus: true,
							disableDoctorGroup: true,
						}}
					/>

					<TableExamsToAuditCP reloadTable={loadList} onLoading={setIsLoadingList} filterStateManager={formStateManager} />
				</>
			)}
		</ScreenWrapperQualityAssurance>
	)
}
