import { NonDicomViewerICP } from 'modules/exams/components/exams/dicom-viewer-embedded/inner/NonDicomViewerICP'
import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { VxViewerParamTP } from 'submodules/beerads-sdk/services/exams/exams/types/VxViewerParamTP'

interface IEmbedViewerCPProps {
	exam: ExamResponseDTO
	onUnEmbedViewer: () => void
}

/**
 */
export function DicomViewerEmbeddedCP(props: IEmbedViewerCPProps): JSX.Element {
	const viewerParams: VxViewerParamTP = {
		token: AppStateUtils.getAuthData()!.token,
		examId: props.exam.id,
		examDescription: props.exam.description,
		patientName: props.exam.patient.name,
		viewBy: 'user',
	}

	if (!!props.exam.imageData.metadata) {
		return (
			<DicomViewerWrapperSCP>
				<FlexCP className={'header'} justifyContent={'space-between'} alignItems={'center'}>
					BeeRads Viewer
					<ButtonCP icon={'close'} size={'small'} onClick={props.onUnEmbedViewer} />
				</FlexCP>

				<iframe
					src={RoutingHelper.getBeeRadsViewerUrl(viewerParams, props.exam.imageData.metadata)}
					title={`${AppStateUtils.getDomainName()} Viewer`}
				/>
			</DicomViewerWrapperSCP>
		)
	}

	return <NonDicomViewerICP examId={props.exam.id} />
}

const DicomViewerWrapperSCP = styled.div`
	width: 100%;
	height: 100%;
	margin: 20px 0;

	.header {
		height: 42px;
		background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		padding: 8px;
		border: solid 1px #e8e8e8;
	}

	iframe {
		height: 100%;
		width: 100%;
		border: none;
	}
`
