import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

interface ICPProps {
	medicalReportVideo: UploadMedicalReportVideoResponseDTO
}

export function ButtonShareMedicalReportVideoWhatsappCP(props: ICPProps): JSX.Element {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
	const [phoneNumber, setPhoneNumber] = useState('')
	const [name, setName] = useState('')

	return (
		<PopOverCP
			trigger={'click'}
			placement={'right'}
			visible={isPopoverVisible}
			onClose={() => setIsPopoverVisible(false)}
			title={'Compartilhar no Whatsapp'}
			content={
				<>
					<InputCP label={'Nome'} value={name} onChange={(value) => setName(value)} />

					<InputCP label={'Telefone'} mask={InputMaskTypeEnum.PHONE} value={phoneNumber} onChange={(value) => setPhoneNumber(value)} />

					<ButtonCP
						type={'primary'}
						onClick={() => {
							const expirationMessage = `Este vídeo expirará em: ${DateUtils.formatDate(
								props.medicalReportVideo.expirationDate,
								DateFormatEnum.BR_WITH_TIME_H_M,
							)}`
							const url = `https://wa.me/${phoneNumber}/?text=${encodeURIComponent(
								`Olá: ${name},\n\nAqui está o link do seu vídeo laudo:\n\n${props.medicalReportVideo.url}\n\n${expirationMessage}`,
							)}`
							window.open(url)
						}}
					>
						Enviar
					</ButtonCP>
				</>
			}
		>
			<ButtonCP>
				<FontAwsomeIconCP type={faWhatsapp} style={{ marginRight: 8 }} />
				Compartilhar no Whatsapp
			</ButtonCP>
		</PopOverCP>
	)
}
