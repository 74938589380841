import { OsirixIconCP } from 'modules/exams/components/exams/icons/IconOsirixCP'
import { ButtonSize, ButtonType } from 'antd/es/button'
import { BeeRadsIconCP } from '_old/main/common/components/icons/BeeRadsIconCP'
import React, { useState } from 'react'
import { ButtonMultiActionCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonMultiActionCP'
import { ExamIconCP } from '_old/main/common/components/icons/ExamIconCP'
import { ExamImageDataResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/inner/ExamImageDataResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RoutingHelper } from 'config/RoutingHelper'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { UiUtils } from 'submodules/nerit-framework-ui/common/utils/UiUtils'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { isSafari } from 'react-device-detect'
import { IconWeasisCP } from 'modules/exams/components/exams/icons/IconWeasisCP'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AppConfig } from 'config/AppConfig'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PatientPortalRequests } from 'submodules/beerads-sdk/services/patient-portal/PatientPortalRequests'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ExamsDownloadsRequests } from 'submodules/beerads-sdk/services/exams/exams-downloads/ExamsDownloadsRequests'
import { VxViewerParamTP } from 'submodules/beerads-sdk/services/exams/exams/types/VxViewerParamTP'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { IntercomEventEnum } from 'app/components/intercom/inner/IntercomEventEnum'
import { ExamImageBucketEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamImageBucketEnum'

interface ICPProps {
	downloadConfig: {
		single?: {
			examId: string
			examDescription: string
			patientName: string
			imageData?: ExamImageDataResponseDTO
		}
		multiple?: {
			examIds: string[]
		}
		downloadBy: 'patient' | 'user'
	}
	iconOnly?: boolean
	size?: ButtonSize
	type?: ButtonType
}

/**
 */
export function ButtonMultiActionDownloadExamDicomCP(props: ICPProps): JSX.Element {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const isAWSImageDataBucketType: boolean = props.downloadConfig.single?.imageData?.bucketType === ExamImageBucketEnum.AWS_S3

	/**
	 * Handle para acoes.
	 */
	async function handleClick(actionType: 'osirix' | 'viewer' | 'weasis' | 'getLink'): Promise<void> {
		if (!!props.downloadConfig.single && !props.downloadConfig.single.imageData)
			return NotificationHelper.error('Ops', 'Exame sem detalhes das imagens')

		if (actionType === 'viewer' && !!props.downloadConfig.single?.imageData) {
			const viewerParams: VxViewerParamTP = {
				token: props.downloadConfig.downloadBy === 'user' ? AppStateUtils.getAuthData()!.token : AppStateUtils.getLoggedPatientPortal()!.token,
				examId: props.downloadConfig.single.examId,
				examDescription: props.downloadConfig.single.examDescription,
				patientName: props.downloadConfig.single.patientName,
				viewBy: props.downloadConfig.downloadBy,
			}

			const beeRadsViewerUrl = RoutingHelper.getBeeRadsViewerUrl(viewerParams, props.downloadConfig.single.imageData.metadata)
			if (!!beeRadsViewerUrl) {
				console.log('Abrindo BR Viewer na URL:', beeRadsViewerUrl)
				IntercomUtils.trackEvent(IntercomEventEnum.EXAM_IMAGE_VX_VIEWER)
				window.open(beeRadsViewerUrl, '_blank')
			}

			return
		}

		const examIds = !!props.downloadConfig.single?.examId ? [props.downloadConfig.single?.examId] : props.downloadConfig.multiple?.examIds
		if (ArrayUtils.isEmpty(examIds)) return NotificationHelper.error('Ops', 'Nenhum exame selecionado')

		if (actionType === 'osirix') {
			const osirixViewerUrl = RoutingHelper.getOsirixViewerUrl(examIds!)
			console.log('Abrindo OSIRIX Viewer na URL:', osirixViewerUrl)
			IntercomUtils.trackEvent(IntercomEventEnum.EXAM_IMAGE_OSIRIX)
			window.open(osirixViewerUrl, '_self')
			return
		}

		if (actionType === 'weasis') {
			for (const examCode of examIds!) {
				console.log(`Download multiplo de exames: [${examIds?.join(', ')}]. Atual: `, examCode)
				IntercomUtils.trackEvent(IntercomEventEnum.EXAM_IMAGE_WEASIS)
				window.open(RoutingHelper.getWeasisViewerUrl(examCode), '_self')
				await SystemUtils.sleep(2000)
			}
			return
		}

		if (actionType === 'getLink') {
			NotificationHelper.info('Aguarde', `Preparando download, aguarde... ${examIds!.length > 1 ? 'O início do download é sequencial.' : ''}`)
			setIsLoading(true)

			for (const examId of examIds!) {
				try {
					let requestConfig: RequestConfigTP
					if (props.downloadConfig.downloadBy === 'user')
						requestConfig = ExamsDownloadsRequests.getExamImagesLink({ examId: examId }, AppConfig.getInstance().api2BaseUrl)
					else requestConfig = PatientPortalRequests.getExamImagesLink(examId, AppConfig.getInstance().api2BaseUrl)

					const response = await RequestHelper.runRequest<IApiReturn<string>>(requestConfig)
					const result = response?.data?.data

					IntercomUtils.trackEvent(IntercomEventEnum.EXAM_IMAGE_DOWNLOAD)

					if (!result) NotificationHelper.error('Ops', 'Erro ao buscar as imagens de um do(s) exame(s) selecionados')
					else NeritFrameworkRoutingHelper.downloadFile(result)
				} catch (e) {
					NotificationHelper.error('Ops', 'Erro ao buscar as imagens de um do(s) exame(s) selecionados')
					console.log(e)
				}
			}

			setIsLoading(false)
			return
		}
	}

	// Botao fake para desabilitar o download
	if (isLoading) {
		return (
			<ButtonCP loading={isLoading} size={props.size} disabled={(props.downloadConfig.multiple?.examIds.length ?? 1) > 5}>
				{props.iconOnly ? '' : 'Preparando Download...'}
			</ButtonCP>
		)
	}

	return (
		<TooltipCP
			text={(props.downloadConfig.multiple?.examIds.length ?? 1) > 5 ? 'Só é possível baixar 5 exames por vez' : undefined}
			showSpan={(props.downloadConfig.multiple?.examIds.length ?? 1) > 5}
		>
			<ButtonMultiActionCP
				options={[
					{
						label: 'Baixar Imagens',
						onClick: () => handleClick('getLink'),
						icon: <ExamIconCP color={props.type === 'primary' ? `${ThemeFrameworkCommon.browserDefaultColorLight};` : undefined} size={16} />,
					},
					{
						label: `Abrir no ${AppStateUtils.getDomainName()} Viewer`,
						onClick: () => handleClick('viewer'),
						icon: <BeeRadsIconCP size={16} />,
						hide: !props.downloadConfig.single?.imageData || !isAWSImageDataBucketType,
						disabled: !isAWSImageDataBucketType,
					},
					{
						label: `Ver no Osirix ${isSafari ? '' : '(acesse pelo safari)'}`,
						onClick: () => handleClick('osirix'),
						icon: <OsirixIconCP size={18} />,
						hide: props.downloadConfig.downloadBy === 'patient' || !UiUtils.testOS('mac'),
						disabled: !isSafari,
					},
					{
						label: 'Ver no Weasis',
						onClick: () => handleClick('weasis'),
						icon: <IconWeasisCP size={18} />,
						hide: props.downloadConfig.downloadBy === 'patient',
					},
				]}
				loading={isLoading}
				size={props.size}
				appearance={{
					onlyIcon: props.iconOnly,
				}}
				disabled={(props.downloadConfig.multiple?.examIds.length ?? 1) > 5}
			/>
		</TooltipCP>
	)
}
