import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { OitContentReportTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/OitContentReportTP'
import { OitFormModel } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/form-model/OitFormModel'

export class OitMedicalReportFormModelConverter {
	static getOitContent(formStateManager: IFormStateManager<OitFormModel>): OitContentReportTP | undefined {
		const values = formStateManager.getFormValues()
		if (!values) return

		return {
			question1: {
				A: values.answer1A,
				B: values.answer1B,
				C: values.answer1C,
			},
			question2: {
				A: values.answer2A,
				B: {
					primary: {
						PS: values.answer2BPrimaryPS,
						QT: values.answer2BPrimaryQT,
						RU: values.answer2BPrimaryRU,
					},
					secondary: {
						PS: values.answer2BSecondaryPS,
						QT: values.answer2BSecondaryQT,
						RU: values.answer2BSecondaryRU,
					},
					zone: [values.answer2BZone1, values.answer2BZone2, values.answer2BZone3],
					profusion: values.answer2BProfusion,
				},
				C: values.answer2C,
			},
			question3: {
				A: values.answer3A,
				B: [
					values.answer3B,
					{
						first: {
							profile: values.answer3BProfileFirst,
							front: values.answer3BFrontFirst,
							diafragma: values.answer3BDiafragmaFirst,
							other: values.answer3BOtherFirst,
						},
						second: {
							profile: values.answer3BProfileSecond,
							front: values.answer3BFrontSecond,
							diafragma: values.answer3BDiafragmaSecond,
							other: values.answer3BOtherSecond,
						},
						extension: {
							OD: values.answer3BExtension0D,
							D123: values.answer3BExtensionD123,
							OE: values.answer3BExtension0E,
							E123: values.answer3BExtensionE123,
						},
						width: {
							OD: values.answer3BWidth0D,
							Dabc: values.answer3BWidthDabc,
							OE: values.answer3BWidth0E,
							Eabc: values.answer3BWidthEabc,
						},
					},
				],
				C: values.answer3C,
				D: [
					values.answer3D,
					{
						first: {
							profile: values.answer3DProfileFirst,
							front: values.answer3DFrontFirst,
						},
						second: {
							profile: values.answer3DProfileSecond,
							front: values.answer3DFrontSecond,
						},
						extension: {
							OD: values.answer3DExtension0D,
							D123: values.answer3DExtensionD123,
							OE: values.answer3DExtension0E,
							E123: values.answer3DExtensionE123,
						},
						width: {
							OD: values.answer3DWidth0D,
							Dabc: values.answer3DWidthDabc,
							OE: values.answer3DWidth0E,
							Eabc: values.answer3DWidthEabc,
						},
					},
				],
			},
			question4: {
				A: values.answer4A,
				B: values.answer4B,
				C: values.answer4C,
			},
		}
	}
}
