import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ModalCreateAuditsCP } from 'modules/quality-assurance/components/modal-create-audits/ModalCreateAuditsCP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

interface ICPProps {
	doctorGroupCode: number
	selectedExamCodes?: number[]
	onSave: () => void
	audit?: {
		codes: number[]
		doctorCode?: number
	}
	appearance?: {
		size?: ButtonSizeTP
		onlyIcon?: boolean
		type?: ButtonTypeTP
	}
}

/**
 */
export function ButtonCreateAuditsCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const auditText = !!props.audit?.codes ? 'Reatribuir' : 'Criar Auditoria'

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: {
					doctorGroupCode: props.doctorGroupCode,
					permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
				},
			}}
		>
			<ButtonCP
				icon={ThemeProjectCommon.icons.assignExam}
				onClick={() => setIsModalVisible(true)}
				marginRight={5}
				type={props.appearance?.type ?? 'primary'}
				size={props.appearance?.size}
				tooltip={props.appearance?.onlyIcon ? auditText : undefined}
			>
				{props.appearance?.onlyIcon ? undefined : auditText}
			</ButtonCP>

			<ModalCreateAuditsCP
				doctorGroupCode={props.doctorGroupCode}
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				examCodes={props.selectedExamCodes}
				audit={props.audit}
				onSave={() => {
					setIsModalVisible(false)
					props.onSave()
				}}
			/>
		</AccessControlCP>
	)
}
