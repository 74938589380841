import React from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ButtonExportTableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/inner/ButtonExportTableFromApiCP'
import { RectificationReportRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/RectificationReportRequestDTO'
import { MedicalReportsRectificationAnalysisListItemResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/MedicalReportsRectificationAnalysisListItemResponseDTO'
import { RectificationReasonsLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/RectificationReasonsEnum'

interface ICPProps {
	reportType: ReportTypeEnum
	reloadTable: number
	onLoading?: (isLoading: boolean) => void
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
}

/**
 */
export function TableExamRectificationAnalysisCP(props: ICPProps): JSX.Element {
	return (
		<TableFromApiCP<MedicalReportsRectificationAnalysisListItemResponseDTO, RectificationReportRequestDTO>
			shouldLoadData={props.reloadTable}
			onLoading={props.onLoading}
			apiConfig={{
				hasPagination: true,
				filters: CommonReportsFilterFormModelConverter.formatRectificationDto(props.reportType, props.filterStateManager.getFormValues()),
				requestConfigTP: (filters) => DashboardRequests.medicalReportsRectificationAnalysis(filters!),
			}}
			appearance={{
				wrappedOnCard: true,
				showTotalOnHeader: true,
				showReloadButton: true,
				topBarLeftContent: (
					<ButtonExportTableFromApiCP
						requestConfigTP={() =>
							DashboardRequests.medicalReportsRectificationAnalysisExport(
								CommonReportsFilterFormModelConverter.formatRectificationDto(props.reportType, props.filterStateManager.getFormValues()),
							)
						}
						fileName={'analise_laudos_conformes'}
					/>
				),
			}}
			row={{
				setClass: (record) => (record.hasClinicalImpact ? 'row-nerit-error' : ''),
			}}
			columns={[
				{
					title: 'Data do Exame',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.examDate, DateFormatEnum.BR_WITHOUT_TIME),
				},
				{
					title: 'Data do Laudo',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.medicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Data de Solicitação Ret.',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.rectificationRequestDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Data da Retificação',
					align: 'center',
					render: (text, record) => DateUtils.formatDate(record.rectificationDate, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Motivo de Ret.',
					render: (text, record) => !!record.rectificationReason && RectificationReasonsLabelEnum[record.rectificationReason],
				},
				{
					title: 'Comentário da Ret.',
					render: (text, record) => record.rectificationRequestComment,
				},
				{
					title: 'Paciente',
					render: (text, record) => record.patientName,
				},
				{
					title: 'Modalidade',
					align: 'center',
					render: (text, record) => ExamModalityLabelEnum[record.modality],
				},
				{
					title: 'Exame',
					render: (text, record) => record.examDescription,
				},
				{
					title: 'Unidade Hospitalar',
					render: (text, record) => record.clinic.name,
				},
				{
					title: 'Médico',
					render: (text, record) => record.doctor.name,
				},
				{
					title: 'Médico Revisor',
					render: (text, record) => record.revisionDoctor.name,
				},
				{
					title: 'Impacto Clínico?',
					render: (text, record) => (record.hasClinicalImpact ? 'Sim' : 'Não'),
				},
			]}
		/>
	)
}
