import * as _ from 'lodash'

import { IPluginFunctions } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/common/IPluginFunctions'
import {
	BlockRendererCallbackTP,
	BlockRendererReturnTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/common/DraftJSPluginTypes'
import {
	ITablePluginBlockProps,
	TPluginTableCP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/components/table-component/TPluginTableCP'
import { EditorState, ContentBlock, AtomicBlockUtils, ContentState, EntityInstance } from 'draft-js'
import {
	PluginTableDataTP,
	CellLocationTP,
	OnTableChangeCallbackTP,
	TablePluginConfigTP,
	PluginTableRowTP,
	PluginTableCellTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginTypes'
import { TablePluginConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginConfig'

/**
 * UTILITARIOS
 * Encapsula metodos uteis para definicao do plugin de tabelas
 * para editor wysiwyg / draft JS customizado.
 *
 * @see https://github.com/draft-js-plugins/draft-js-plugins/blob/master/HOW_TO_CREATE_A_PLUGIN.md
 * @author hjcostabr
 */
export class TablePluginUtils {
	private constructor() {
		/** Construtor privado impede instanciacao. */
	}

	/** Gera & insere 01 nova tabela no estado do editor. */
	static addTable(editorState: EditorState, rows: number, columns: number): EditorState {
		const contentState = editorState.getCurrentContent()
		const contentStateWithEntity = contentState.createEntity(
			TablePluginConfig.TABLE_ENTITY_TYPE,
			'IMMUTABLE',
			TablePluginUtils.getDataForTableAdding(rows, columns),
		)

		const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
		const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
		return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, 'tabela')
	}

	/**
	 * Gera & retorna 01 metodo do tipo 'blockRendererFn' que trata a reenderizacao customizada
	 * de 01 entidade do tipo 'tabela', no editor.
	 */
	static getTableContentBlockRendererFn(config: TablePluginConfigTP): BlockRendererCallbackTP {
		return (block: ContentBlock, pluginFunctions: IPluginFunctions): BlockRendererReturnTP<ITablePluginBlockProps> | undefined => {
			if (block.getType() !== 'atomic') return

			try {
				const content = pluginFunctions.getEditorState().getCurrentContent()
				const tableData = TablePluginUtils.getTableDataFromBlock(content, block)

				if (!tableData) return

				const onCellBlurCallback = (_contentState: ContentState, cellLocation: CellLocationTP, cellData: PluginTableCellTP) =>
					TablePluginUtils.updateTableEntity(config.onTableChange, _contentState, cellLocation, cellData)

				return {
					component: TPluginTableCP,
					editable: false,
					props: {
						tableData,
						onCellBlur: onCellBlurCallback,
						setReadOnly: config.setReadOnly,
						customStyles: config.customStyles,
					},
				}
			} catch (error) {
				console.error('FALHA - TablePluginUtils.getTableContentBlockRendererFn: ', error)
				return
			}
		}
	}

	/**
	 * Gera & retorna 01 funcao do tipo 'blockRenderer' usada na geracao de HTML a partir
	 * do estado do editor para incluir elementos do tipo <table> gerados pelo plugin.
	 */
	static getTableHtmlBlockRendererFn(content: ContentState, defaultStyles?: {}): (block: ContentBlock) => string {
		return (block: ContentBlock): string => {
			// Verifica SE eh 01 entidade tabela
			const tableData = TablePluginUtils.getTableDataFromBlock(content, block)
			if (!tableData) return ''

			// Monta linhas da tabela
			let rowsHtml = ''
			const tableRows = _.get(tableData, 'rows', [])

			tableRows.forEach((row: PluginTableRowTP) => {
				const cellCount = row.cells.length
				const cellWidth = `${100 / cellCount}%`

				const cellContent = row.cells.map((cell) => `<td style="width:${cellWidth}">${cell.text || ''}</td>`).join('')

				rowsHtml += `<tr style="height: 23px;">${cellContent}</tr>`
			})

			// Inclui estilos, monta & retorna tabela
			let cssString = ''

			if (!!defaultStyles) {
				Object.keys(defaultStyles).forEach((styleName) => (cssString += `${styleName}: ${defaultStyles[styleName]}; `))
			}

			return `<table border style="${cssString}">${rowsHtml}</table>`
		}
	}

	/**
	 * Captura & retorna dados de 01 entidade to tipo tabela
	 * contida num bloco (se houver).
	 */
	private static getTableDataFromBlock(content: ContentState, block: ContentBlock): PluginTableDataTP | undefined {
		const entityKey = block.getEntityAt(0)
		if (!entityKey) return

		const entity: EntityInstance = content.getEntity(entityKey)
		if (!entity || entity.getType() !== TablePluginConfig.TABLE_ENTITY_TYPE) return

		return entity.getData()
	}

	/** Gera & retorna dados para parametrizar a inclusao de 01 nova tabela, no editor. */
	private static getDataForTableAdding(rows: number, columns: number): PluginTableDataTP {
		const tableData: PluginTableDataTP = { rows: [] }

		for (let i = 0; i < rows; i++) {
			const row: PluginTableRowTP = { cells: [] }

			for (let j = 0; j < columns; j++) row.cells.push({})

			tableData.rows.push(row)
		}

		return tableData
	}

	/**
	 * Atualiza o estado de 01 entidade tabela e invoca callback externo que permite
	 * atualizacao do estado do editor (incorporando alteracao no conteudo da tabela.).
	 */
	private static updateTableEntity(
		onTableChange: OnTableChangeCallbackTP,
		contentState: ContentState,
		cellLocation: CellLocationTP,
		cellNewData: PluginTableCellTP,
	): void {
		const tableBlock = contentState.getBlockForKey(cellLocation.blockKey)
		const tableKey = tableBlock.getEntityAt(0)
		const tableEntity = contentState.getEntity(tableKey)

		const tableData: PluginTableDataTP = tableEntity.getData()
		const tableRows: PluginTableRowTP[] = _.get(tableData, 'rows', [])

		tableData.rows = tableRows.map((tableRow: PluginTableRowTP, rowNumber: number) => {
			if (rowNumber === cellLocation.rowNumber) {
				tableRow.cells = tableRow.cells.map((tableCell: PluginTableCellTP, cellNumber: number) =>
					cellNumber === cellLocation.cellNumber ? cellNewData : tableCell,
				)
			}

			return tableRow
		})

		const nextContentState = contentState.replaceEntityData(tableKey, tableData)
		onTableChange(nextContentState)
	}
}
