import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'

export class DuplicateExamFormModel extends FormModel {
	@IsRequired()
	currentExam: IPendingExamDescription

	@IsRequired()
	exams: IPendingExamDescription[]

	constructor(initialData?: ObjectPropsTP<DuplicateExamFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
