import { ThemeProject } from 'config/theme/project/ThemeProject'
import * as React from 'react'
import styled from 'styled-components'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ILinkCPProps {
	text: string
	onClick: () => void
	textSize?: FontSizeTP
	color?: string
}

/**
 * COMPONENTE: Link.
 */
export function LinkCP(props: ILinkCPProps): JSX.Element {
	return (
		<LinkSCP textSize={props.textSize} color={props.color} onClick={props.onClick}>
			<a>{props.text}</a>
		</LinkSCP>
	)
}

const LinkSCP = styled.div<{ textSize?: string; color?: string }>`
	color: ${ThemeProject.gray100};
	font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.textSize ?? 'extraSmall']};
	text-decoration: underline;
	cursor: pointer;
	a {
		color: ${ThemeProject.gray100};
	}
`
