export enum NpsResponderTypeEnum {
	PROMOTER = 'PROMOTER',
	PASSIVE = 'PASSIVE',
	DETRACTOR = 'DETRACTOR',
}

export enum NpsResponderTypeLabelEnum {
	PROMOTER = 'Promotor',
	PASSIVE = 'Neutro',
	DETRACTOR = 'Detrator',
}

export enum NpsResponderTypePluralLabelEnum {
	PROMOTER = 'Promotores',
	PASSIVE = 'Neutros',
	DETRACTOR = 'Detratores',
}
