import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import React from 'react'

/**
 * Icon: Exam
 * @author Lucas Rosa
 */
export const ExamIconCP = (props: IIconProps): JSX.Element => (
	<svg
		style={{
			width: props.size || '22',
			height: props.size || '22',
			fill: props.color || 'inherit',
		}}
		width={props.size || '22'}
		height={props.size || '22'}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="5 5 9 6"
	>
		{/* tslint:disable-next-line: max-line-length*/}
		<path d="M9.41 8.81v.629h.409a3.208 3.208 0 0 1 1.801.466.298.298 0 0 1 .107.409.298.298 0 0 1-.428.097 2.632 2.632 0 0 0-1.472-.372H9.41v.672a.3.3 0 1 1-.599 0v-.672h-.404a2.624 2.624 0 0 0-1.471.372.301.301 0 0 1-.414-.092.301.301 0 0 1 .092-.414 3.226 3.226 0 0 1 1.802-.466h.394V8.81h-.403a2.586 2.586 0 0 0-1.456.371c-.137.093-.324.059-.417-.077s-.059-.323.078-.417l.018-.012a3.18 3.18 0 0 1 1.786-.464h.395v-.627h-.363a1.639 1.639 0 0 0-1.275.385.3.3 0 1 1-.434-.413 2.193 2.193 0 0 1 1.719-.571h.354v-.616h-.435a1.383 1.383 0 0 0-.705.246.3.3 0 0 1-.386-.458c.316-.236.697-.37 1.092-.386h1.48c.4.008.789.144 1.107.387a.3.3 0 0 1-.389.456c-.21-.15-.46-.235-.719-.244H9.41v.616h.366c.453 0 1.271.076 1.725.578a.3.3 0 0 1-.442.402 1.78 1.78 0 0 0-1.282-.379H9.41v.627h.409a3.225 3.225 0 0 1 1.801.466.299.299 0 0 1 .107.41.298.298 0 0 1-.428.096 2.632 2.632 0 0 0-1.472-.372H9.41V8.81zm3.247 1.002a.3.3 0 0 0 .299-.299V5.741a1.198 1.198 0 0 0-1.196-1.196H6.491a1.198 1.198 0 0 0-1.196 1.196v5.268c.001.66.536 1.195 1.196 1.196h5.268a1.198 1.198 0 0 0 1.196-1.196.3.3 0 0 0-.599 0 .6.6 0 0 1-.598.599H6.491a.6.6 0 0 1-.599-.599V5.741a.6.6 0 0 1 .599-.598h5.268a.6.6 0 0 1 .599.598v3.771a.3.3 0 0 0 .298.3h.001z" />
	</svg>
)
