import React, { useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { PatientDataCP } from 'modules/exams/components/exams/patient-data/PatientDataCP'
import { ExamDataCP } from 'modules/exams/components/exams/exam-data/ExamDataCP'
import { RawHtmlCP } from 'submodules/nerit-framework-ui/common/components/raw-html/RawHtmlCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { PdfViewerCP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/PdfViewerCP'
import styled from 'styled-components'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ICPProps {
	exam: ExamResponseDTO
}

/**
 */
export function SiderLeftAuditCP(props: ICPProps): JSX.Element {
	const [view] = useState<'pdf' | 'content'>('content')

	return (
		<SiderCP width={'50%'} bgColor={'transparent'}>
			<div>
				<CardCP title={'Laudo'} titleRight={props.exam.hadRectification ? <TagCP content={'Laudo retificado!'} color={'yellow'} /> : ''}>
					<FlexCP justifyContent={'center'} margin={{ bottom: 20 }}>
						{/* @TODO Por hora deixar escondido o PDF pois a auditoria tem que ser as cegas*/}
						{/*<RadioGroupCP*/}
						{/*    type={'button'}*/}
						{/*    options={[*/}
						{/*        { value: 'pdf', content: 'Pré-visualizar PDF', },*/}
						{/*        { value: 'content', content: 'Conteúdo do Laudo', },*/}
						{/*    ]}*/}
						{/*    onChange={setView}*/}
						{/*    selected={view}*/}
						{/*/>*/}
					</FlexCP>

					{props.exam.medicalReports?.map((medicalReport, index) => (
						<FileWrapperSCP key={index}>
							{view === 'pdf' && <PdfViewerCP file={medicalReport.fileUrl} paginationType={'scroll'} viewType={'inline'} />}

							{view === 'content' && (
								<BasicStyleWrapperCP margin={{ top: 10, bottom: 10, left: 10, right: 10 }}>
									<RawHtmlCP html={medicalReport.html} />
								</BasicStyleWrapperCP>
							)}
						</FileWrapperSCP>
					))}
				</CardCP>

				<CardCP title={props.exam.patient.name}>
					<PatientDataCP exam={props.exam} margin={{ bottom: 30 }} />

					<ExamDataCP exam={props.exam} />
				</CardCP>
			</div>
		</SiderCP>
	)
}

const FileWrapperSCP = styled.div`
	overflow-y: scroll;
	border: dashed 1px ${ThemeProject.gray300};
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 10px;

	.nerit-viewer-wrapper {
		height: auto;
	}
`
