import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { TableExamsCP } from 'modules/exams/components/exams/table-exams/TableExamsCP'
import styled from 'styled-components'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FiltersDrawerExamsICP } from 'modules/exams/components/exams/drawer-exams/inner/FiltersDrawerExamsICP'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	visible: boolean
	onClose: (dataChanged: boolean) => void
	doctor?: NameAndCodeResponseDTO
}

/**
 * Drawer que mosta listagem de exames.
 */
export function DrawerExamsCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<ExamFilterFormModel>()
	const filterStateManager = useFormStateManager<ExamFilterFormModel>(formModel)

	const [dataChanged, setDataChanged] = useState<boolean>(false)
	const [loadList, setLoadList] = useState<number>(0)

	useEffect(init, [props.doctor, props.visible])

	/**
	 * Inicializa
	 */
	function init(): void {
		if (!props.visible) return

		setFormModel(
			new ExamFilterFormModel({
				viewAsDoctor: false,
				doctorCodes: props.doctor ? [props.doctor.code] : undefined,
				status: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.AWAITING),
				assigned: ExamAssignedFilterEnum.YES,
				doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
			}),
		)

		setLoadList(1)
	}

	return (
		<DrawerCP
			title={`Exames ${!!props.doctor ? `atribuídos para ${props.doctor.name}` : ''}`}
			width={'95%'}
			visible={props.visible}
			onClose={() => props.onClose(dataChanged)}
		>
			{!filterStateManager.getFormValues() ? (
				<LoadingCP show={true} />
			) : (
				<WrapperSCP>
					<FiltersDrawerExamsICP filterFormStateManager={filterStateManager} onFilter={() => setLoadList(TableUtils.getReloadNumber())} />
					<TableExamsCP
						reloadTable={loadList}
						examStatusGroup={ExamStatusGroupEnum.AWAITING}
						filterStateManager={filterStateManager}
						onChangeAnyData={() => setDataChanged(true)}
					/>
				</WrapperSCP>
			)}
		</DrawerCP>
	)
}

const WrapperSCP = styled.div`
	margin-top: -20px;
`
