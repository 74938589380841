import React, { useEffect, useState } from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { CategoryResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/categories/responses/CategoryResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CategoriesRequests } from 'submodules/beerads-sdk/services/doctor-groups/categories/CategoriesRequests'
import { ICategoryDetails } from 'submodules/beerads-sdk/services/doctor-groups/categories/types/ICategoryDetails'

interface IICPProps {
	doctorGroupCode: number
	formStateManager: IFormStateManager<any>
	originalCategoryCode?: number
	originalCategoryDetails?: ICategoryDetails // Detalhes da categoria originais do BD
	categoryFieldName: string
	width?: number
}

/**
 */
export function SelectClinicCategoryCP(props: IICPProps): JSX.Element {
	const [categories, setCategories] = useState<CategoryResponseDTO[]>([])

	const getCategoriesRequest = useRequest<ListResponseDTO<CategoryResponseDTO>>()
	useEffect(onGetClinicCategoriesRequestChange, [getCategoriesRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Carrega todos.
	 */
	function init(): void {
		getCategoriesRequest.runRequest(CategoriesRequests.search(props.doctorGroupCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicCategoriesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getCategoriesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setCategories(getCategoriesRequest.responseData!.list)
	}

	return (
		<SelectCP
			label={'Categoria'}
			formStateManager={props.formStateManager}
			fieldName={props.categoryFieldName}
			options={categories.map((category) => ({
				value: category.code,
				label: <TagCP content={category.name} color={category.color} />,
			}))}
			allowClear={true}
			width={props.width}
		/>
	)
}
