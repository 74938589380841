import { ExamDataCP } from 'modules/exams/components/exams/exam-data/ExamDataCP'
import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { PatientDataCP } from 'modules/exams/components/exams/patient-data/PatientDataCP'
import { ExamPatientPortalDataCP } from 'modules/patient-portal/components/exam-patient-portal-data/ExamPatientPortalDataCP'
import styled from 'styled-components'

interface ICPProps {
	exam: ExamResponseDTO
	onEditData?: (closeDrawer?: boolean) => void
}

/**
 * Componente para exibir detalhes do exame.
 */
export function TabContentExamMainDataICP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<PatientDataCP
				exam={props.exam}
				margin={{ bottom: 30 }}
				appearance={{
					showName: true,
					showTitle: true,
				}}
				editExamOption={{
					onEditData: () => {
						if (!!props.onEditData) props.onEditData(false)
					},
				}}
			/>

			<ExamDataCP
				exam={props.exam}
				duplicateExamOption={{
					onDuplicateExam: () => {
						if (!!props.onEditData) props.onEditData(true)
					},
				}}
			/>

			<ExamPatientPortalDataCP margin={{ top: 30 }} exam={props.exam} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	line-height: 32px;
`
