import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { AvcContentReportTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/AvcContentReportTP'
import { AvcFormModel } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/form-model/AvcFormModel'

/**
 */
export class AvcMedicalReportFormModelConverter {
	/**
	 */
	static toContentTP(formStateManager: IFormStateManager<AvcFormModel>): AvcContentReportTP | undefined {
		const formValues = formStateManager.getFormValues()
		if (!formValues) return

		return {
			question1: {
				hemoSuba: formValues.q1HemoSuba,
				hemoSubd: formValues.q1HemoSubd,
				hemoExtr: formValues.q1HemoExtr,
				hemoIntra: formValues.q1HemoIntra,
			},
		}
	}

	/**
	 */
	static toFormModel(contentReportTP: AvcContentReportTP): AvcFormModel {
		return new AvcFormModel({
			q1HemoSuba: contentReportTP?.question1?.hemoSuba,
			q1HemoSubd: contentReportTP?.question1?.hemoSubd,
			q1HemoExtr: contentReportTP?.question1?.hemoExtr,
			q1HemoIntra: contentReportTP?.question1?.hemoIntra,
		})
	}
}
