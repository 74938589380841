import React, { useEffect, useState } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { HtmlMedicalReportsCP } from 'modules/exams/components/medical-report/html-medical-reports/HtmlMedicalReportsCP'
import styled from 'styled-components'
import { PdfViewerCP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/PdfViewerCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TableMedicalReportHistoryCP } from 'modules/exams/components/medical-report/table-medical-report-history/TableMedicalReportHistoryCP'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { ButtonIntegrateMedicalReportCP } from 'modules/exams/components/exam-history/button-integrate-medical-report/ButtonIntegrateMedicalReportCP'
import { ButtonIntegrateMedicalReportXmlCP } from 'modules/exams/components/exam-history/button-integrate-medical-report-xml/ButtonIntegrateMedicalReportXmlCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ListMedicalReportVideoCP } from 'modules/quality-assurance/components/list-medical-report-video/ListMedicalReportVideoCP'

type MedicalReportViewModeTP = 'content' | 'pdf' | 'history' | 'video'

interface ICPProps {
	exam: ExamResponseDTO
	onRegenerateMedicalReport: () => void
}

/**
 */
export function TabContentExamMedicalReportICP(props: ICPProps): JSX.Element {
	const canSeeMedicalReportHistory: boolean = ClinicPermissionUtils.hasAnyPermissionsInClinic(props.exam.clinic.code, [
		'isDoctorGroupAdmin',
		'isDoctorGroupUser',
		'isAdmin',
	])

	let canSeeMedicalReportVideo = false
	if (!!props.exam.doctorGroup)
		canSeeMedicalReportVideo = DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(props.exam.doctorGroup.code, ['isAuditor'])

	const [view, setView] = useState<MedicalReportViewModeTP>('content')

	const regeneraMedicalReportPdfRequest = useRequest<void>('none')
	useEffect(onSaveClinicRequestChange, [regeneraMedicalReportPdfRequest.isAwaiting])

	/**
	 */
	function onSaveClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(regeneraMedicalReportPdfRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, undefined, true)) return

		NotificationHelper.success('Pronto', 'Laudo regerado com sucesso, pode fazer o download do laudo novamente')
		props.onRegenerateMedicalReport()
	}

	return (
		<>
			<FlexCP justifyContent={'center'} margin={{ bottom: 20 }}>
				<RadioGroupCP<MedicalReportViewModeTP>
					type={'button'}
					options={[
						{ value: 'content', content: 'Conteúdo do Laudo' },
						{ value: 'pdf', content: 'Pré-visualizar PDF' },
						{ value: 'history', content: 'Versões do Laudo', hide: !canSeeMedicalReportHistory },
						{ value: 'video', content: 'Vídeo Laudo', hide: !canSeeMedicalReportVideo },
					]}
					onChange={setView}
					selected={view}
				/>
			</FlexCP>

			{view === 'content' && <HtmlMedicalReportsCP examCode={props.exam.code} examModality={props.exam.modality} />}

			{view === 'pdf' &&
				props.exam.medicalReports?.map((medicalReport) => (
					<>
						<FileWrapperSCP>
							<PdfViewerCP file={medicalReport.fileUrl} paginationType={'scroll'} viewType={'inline'} />
						</FileWrapperSCP>

						<FlexCP justifyContent={'space-between'}>
							{!!props.exam.clinic.risIntegration && (
								<FlexCP>
									<ButtonIntegrateMedicalReportCP examCode={props.exam.code} label={'Reenviar laudo para o RIS'} />
									<ButtonIntegrateMedicalReportXmlCP
										margin={{ left: 5 }}
										examCode={props.exam.code}
										risIntegrationPartner={props.exam.clinic.risIntegration.partner}
									/>
								</FlexCP>
							)}
							{!ArrayUtils.isEmpty(props.exam.medicalReports?.find((medicalReport) => !!medicalReport.html)) && (
								<ButtonCP
									onClick={() =>
										regeneraMedicalReportPdfRequest.runRequest(MedicalReportRequests.regenerateMedicalReportPdf(props.exam.code))
									}
									loading={regeneraMedicalReportPdfRequest.isAwaiting}
									size={'small'}
									confirmMsg={'Você tem certeza que deseja regerar o laudo buscando o novo template?'}
								>
									Regerar PDF do laudo
								</ButtonCP>
							)}
						</FlexCP>
					</>
				))}

			{view === 'history' && <TableMedicalReportHistoryCP examCode={props.exam.code} examModality={props.exam.modality} />}

			{view === 'video' && props.exam.doctorGroup?.code && (
				<ListMedicalReportVideoCP examCode={props.exam.code} doctorGroupCode={props.exam.doctorGroup?.code} />
			)}
		</>
	)
}

const FileWrapperSCP = styled.div`
	overflow-y: scroll;
	border: dashed 1px ${ThemeProject.gray300};
	border-radius: 5px;
	margin-top: 10px;
	margin-bottom: 10px;

	.nerit-viewer-wrapper {
		height: auto;
	}
`
