import React, { useEffect } from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { RoutingHelper } from 'config/RoutingHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'

interface ICPProps {
	exam: ExamResponseDTO
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function AlertPatientExamsForMedicalReportCP(props: ICPProps): JSX.Element {
	const searchPatientExamsRequest = useRequest<ListResponseDTO<ExamListItemResponseDTO>>()
	useEffect(searchPatientExamsRequestChange, [searchPatientExamsRequest.isAwaiting])

	useEffect(init, [])

	/**
	 */
	function init(): void {
		if (!props.exam.patient.name || !props.exam.patient.birthday || !props.exam.patient.gender) return

		const dto: ExamSearchRequestDTO = {
			viewAsDoctor: true,
			doctorGroupCode: props.exam.doctorGroup?.code,
			patientName: props.exam.patient.name,
			patientGender: props.exam.patient.gender,
			patientBirthday: DateUtils.toDate(props.exam.patient.birthday, DateFormatEnum.US_WITHOUT_TIME),
			clinicCodes: [props.exam.clinic.code],
			statuses: [ExamStatusEnum.AWAITING],
		}
		searchPatientExamsRequest.runRequest(ExamsRequests.search(dto))
	}

	/**
	 */
	function searchPatientExamsRequestChange(): void {
		// Avalia retorno da ultima requisicao
		if (!RequestUtils.isValidRequestReturn(searchPatientExamsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return
	}

	if (ArrayUtils.isEmpty(searchPatientExamsRequest.responseData?.list)) return <></>

	return (
		<AlertCP
			closable={true}
			type={'info'}
			message={
				<WrapperSCP>
					<span>Este paciente possui outros exames aguardando, clique nos exames desejados abaixo:</span>
					<FlexCP flexWrap={'wrap'}>
						{searchPatientExamsRequest.responseData?.list
							?.filter((exam) => exam.code !== props.exam.code)
							.map((exam) => (
								<TagCP
									key={`key-exam-tag-${exam.code}`}
									content={`${ExamModalityLabelEnum[exam.modality]} ${exam.description}`}
									color={'red'}
									onClick={() => RoutingHelper.goToMedicalReport(exam.id, true, undefined, props.searchExamDto)}
								/>
							))}
					</FlexCP>
				</WrapperSCP>
			}
		/>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.ant-tag {
		margin: 5px 5px 5px 0;
		font-size: 13px;
	}
`
