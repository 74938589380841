import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormDoctorGroupDoctorCP } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/FormDoctorGroupDoctorCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { DoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/inner/DoctorGroupDoctorFormModel'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { DoctorGroupPersonResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPersonResponseDTO'
import { DoctorGroupDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupDoctorSaveRequestDTO'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onSave: () => void
	doctorGroupDoctor: DoctorGroupPersonResponseDTO
}

/**
 * Modal com dados do medico em um grupo.
 */
export function ModalDoctorGroupDoctorCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<DoctorGroupDoctorFormModel>(new DoctorGroupDoctorFormModel())
	const formStateManager = useFormStateManager<DoctorGroupDoctorFormModel>(formModel)

	const updateDoctorGroupDoctorRequest = useRequest<void>('none')
	useEffect(onUpdateDoctorGroupDoctorRequestChange, [updateDoctorGroupDoctorRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setFormModel(new DoctorGroupDoctorFormModel({}))
		if (!props.visible) return

		setFormModel(
			new DoctorGroupDoctorFormModel({
				specialty: props.doctorGroupDoctor.extraDoctorData?.specialty,
				doctorLevel: props.doctorGroupDoctor.extraDoctorData?.doctorLevel,
				teamCodes: props.doctorGroupDoctor.extraDoctorData?.teams?.map((team) => team.code),
				isAuditor: props.doctorGroupDoctor.extraDoctorData?.isAuditor,
			}),
		)
	}

	/**
	 * Atualiza os dados
	 */
	async function update(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto: DoctorGroupDoctorSaveRequestDTO = {
			teamCodes: formValues.teamCodes,
			specialty: formValues.specialty ?? null,
			doctorLevel: formValues.specialty ? formValues.doctorLevel : null,
			isAuditor: formValues.isAuditor,
		}
		updateDoctorGroupDoctorRequest.runRequest(DoctorGroupsRequests.updateDoctorData(props.doctorGroupDoctor.code, dto))
	}

	/**
	 * Retorno do link
	 */
	function onUpdateDoctorGroupDoctorRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateDoctorGroupDoctorRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				'Vinculado com sucesso',
				true,
			)
		)
			return
	}

	return (
		<ModalCP
			visible={props.visible}
			actionLoading={updateDoctorGroupDoctorRequest.isAwaiting}
			onCancel={props.onCancel}
			onOk={update}
			title={`Perfil do ${StringUtils.getFirstName(props.doctorGroupDoctor.person.name)} no grupo`}
			width={400}
		>
			<FormDoctorGroupDoctorCP doctorGroupCode={props.doctorGroupDoctor.doctorGroupCode} formStateManager={formStateManager} />
		</ModalCP>
	)
}
