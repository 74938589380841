import { applyMiddleware, combineReducers, createStore, Store, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import reduxThunkMiddleware from 'redux-thunk'
import { AuthActions } from 'app/utils/AuthActions'
import { ReduxUtils } from 'submodules/nerit-framework-ui/common/redux/ReduxUtils'
import { IAction } from 'submodules/nerit-framework-ui/common/redux/types/IAction'
import { ReduxPersistorHelper } from 'app/redux/helpers/ReduxPersistorHelper'
import { IReduxState } from 'app/redux/interfaces/IReduxState'
import { Reducers } from 'app/redux/Reducers'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'

/**
 * Encapsula logica para configuracao do gerenciamento de estado da aplicacao via redux.
 */
export class ReduxHelper {
	/** Store (guarda estado global da aplicacao). */
	private readonly _store: Store

	/** Persistor (elemento que garante persistencia de partes do estado global). */
	private readonly _persistor: Persistor

	/** Instancia unica da classe. */
	private static _instance: ReduxHelper // eslint-disable-line @typescript-eslint/member-ordering

	private constructor() {
		const combinedReducers = combineReducers<IReduxState>(Reducers)
		const persistedReducer = persistReducer(ReduxPersistorHelper.getPersistorConfig(), combinedReducers)
		this._store = createStore(persistedReducer, ReduxHelper._getMiddleware())
		this._persistor = persistStore(this._store) as unknown as Persistor
	}

	/** Gera & retorna instancia unica da manager. */
	static getInstance(): ReduxHelper {
		if (!this._instance) this._instance = new ReduxHelper()
		return this._instance
	}

	/**
	 */
	static updateStoredValue(key: string, value: any): void {
		ReduxHelper.getInstance().store.dispatch({
			type: key,
			payload: value,
		})
	}

	/** Gera & retorna lista de middleware a ser aplicado no redux. */
	private static _getMiddleware(): StoreEnhancer {
		const middlewareList: any[] = [
			reduxThunkMiddleware,
			ReduxUtils.createMiddleware(this._rehydrateMWCallback, ReduxPersistorHelper.ACTION_REHYDRATE), // Rehydrate middleware
		]

		const appliedMiddleware = applyMiddleware(...middlewareList)
		if (!NeritFrameworkProjectConfig.isDev()) return appliedMiddleware

		return composeWithDevTools(appliedMiddleware)
	}

	/**
	 * Callback para middleware que intercepta action de 'reidratacao' de estado
	 * (redux-persist).
	 */
	private static _rehydrateMWCallback(action: IAction): void {
		const token = ((action.payload as IReduxState)?.auth as AuthResponseDTO)?.token
		if (!!token) AuthActions.refreshToken(token)
	}

	get store(): Store<IReduxState> {
		return this._store
	}

	get persistor(): Persistor {
		return this._persistor
	}
}
