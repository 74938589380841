import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ModalNewClinicCP } from 'modules/clinic/components/modal-new-clinic/ModalNewClinicCP'
import { TableClinicsDoctorGroupsUtils } from 'modules/doctor-groups/components/clinic-doctor-groups/table-clinics-doctor-group/inner/TableClinicsDoctorGroupsUtils'
import { DrawerClinicDoctorGroupCP } from 'modules/doctor-groups/components/clinic-doctor-groups/drawer-clinic-doctor-group/DrawerClinicDoctorGroupCP'
import { ButtonNewClinicCP } from 'modules/clinic/components/button-new-clinic/ButtonNewClinicCP'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ClinicDoctorGroupListItemResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupListItemResponseDTO'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
// import { DrawerPricingClinicCP } from 'modules/doctor-groups/components/drawer-pricing-clinic/DrawerPricingClinicCP'
import { ClinicDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'
import styled from 'styled-components'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

type FilterStatusTP = 'all' | 'active' | 'inactive' | 'awaiting' | 'refused'

interface ICPProps {
	doctorGroupCode: number
}

/**
 * Tabela de listagem das clinicas de um group
 */
export function TableClinicsDoctorGroupCP(props: ICPProps): JSX.Element {
	const [isClinicModalWizardVisible, setIsClinicModalWizardVisible] = useState(false)
	const [isClinicRelationEditVisible, setIsClinicRelationEditVisible] = useState(false)
	const [selected, setSelected] = useState<ClinicDoctorGroupListItemResponseDTO>()

	// const [drawerPricingClinicVisible, setDrawerPricingClinicVisible] = useState(false)

	const [selectedStatusFilter, setSelectedStatusFilter] = useState<ClinicDoctorGroupStatusEnum[]>([ClinicDoctorGroupStatusEnum.ACTIVE])

	const [clinicDoctorGroupStatus, setClinicDoctorGroupStatus] = useState<FilterStatusTP>('active')

	const [pendingConfiguration, setPendingConfiguration] = useState<boolean>(false)

	const [reloadTable, setReloadTable] = useState<number>()
	useEffect(() => setReloadTable(TableUtils.getReloadNumber()), [props.doctorGroupCode, selectedStatusFilter, pendingConfiguration])

	/**
	 * Ao selecionar um item,
	 */
	function onSelect(selected: ClinicDoctorGroupListItemResponseDTO): void {
		setSelected(selected)
		setIsClinicRelationEditVisible(true)
	}

	// function openPriceList(selected: ClinicDoctorGroupListItemResponseDTO): void {
	// 	setSelected(selected)
	// 	setDrawerPricingClinicVisible(true)
	// }

	function onSelectStatus(value: FilterStatusTP): void {
		switch (value) {
			case 'active':
				setSelectedStatusFilter([ClinicDoctorGroupStatusEnum.ACTIVE])
				break
			case 'awaiting':
				setSelectedStatusFilter([ClinicDoctorGroupStatusEnum.AWAITING_CLINIC, ClinicDoctorGroupStatusEnum.AWAITING_DOCTOR_GROUP])
				break
			case 'inactive':
				setSelectedStatusFilter([ClinicDoctorGroupStatusEnum.INACTIVE])
				break
			case 'refused':
				setSelectedStatusFilter([ClinicDoctorGroupStatusEnum.REFUSED])
				break
			case 'all':
				setSelectedStatusFilter([])
				break
		}
		setClinicDoctorGroupStatus(value)
	}

	return (
		<>
			<FlexCP alignItems="center" justifyContent="space-between" margin={{ right: 20, left: 20 }}>
				<FlexCP alignItems="center" gap="15px">
					<RadioGroupCP<FilterStatusTP>
						type="button"
						selected={clinicDoctorGroupStatus}
						label={'Filtrar unidades hospitalares pelo status'}
						options={[
							{ value: 'active', content: 'Ativas' },
							{ value: 'inactive', content: 'Inativas' },
							{ value: 'awaiting', content: 'Aguardando' },
							{ value: 'refused', content: 'Recusado' },
							{ value: 'all', content: 'Todas' },
						]}
						onChange={(value) => onSelectStatus(value)}
					/>
					<FlexCP flexDirection="column" margin={{ top: 14 }}>
						<TextCP text={'Visualizar apenas pendentes?'} />

						<SwitchCP value={pendingConfiguration} onChange={(value) => setPendingConfiguration(value)} />
					</FlexCP>
				</FlexCP>
				<ButtonNewClinicCP doctorGroupCode={props.doctorGroupCode} onSave={() => setReloadTable(TableUtils.getReloadNumber())} />
			</FlexCP>
			<TableWrapperSCP>
				<TableFromApiCP<ClinicDoctorGroupListItemResponseDTO>
					shouldLoadData={reloadTable}
					appearance={{
						wrappedOnCard: true,
						showReloadButton: true,
						showTotalOnHeader: true,
						recordLabel: 'unidades hospitalares',
					}}
					apiConfig={{
						hasPagination: false,
						requestConfigTP: () =>
							ClinicDoctorGroupRequests.search({
								doctorGroupCode: props.doctorGroupCode,
								statuses: selectedStatusFilter,
								hasIncompleteConfiguration: pendingConfiguration,
							}),
					}}
					row={{
						setClass: (record) => (record.hasPriceConfigured ? '' : 'not-configured-clinic'),
					}}
					columns={TableClinicsDoctorGroupsUtils.getColumns(
						onSelect,
						// openPriceList,
						() => setReloadTable(TableUtils.getReloadNumber()),
						props.doctorGroupCode,
					)}
					filter={{
						position: 'left',
						onFilterLoadedList: (list, searchTxt) => list?.filter((record) => TableUtils.containsTxt(record.clinic.name, searchTxt)),
					}}
				/>
			</TableWrapperSCP>

			<ModalNewClinicCP
				visible={isClinicModalWizardVisible}
				doctorGroupCode={props.doctorGroupCode}
				onCancel={() => setIsClinicModalWizardVisible(false)}
				onSave={() => {
					setIsClinicModalWizardVisible(false)
				}}
			/>

			{!!selected && (
				<DrawerClinicDoctorGroupCP
					visible={isClinicRelationEditVisible}
					onClose={(dataChanged) => {
						setIsClinicRelationEditVisible(false)
						if (dataChanged) setReloadTable(TableUtils.getReloadNumber())
					}}
					clinicCode={selected.clinic.code}
					doctorGroupCode={props.doctorGroupCode}
				/>
			)}

			{/* {
				!!selected && drawerPricingClinicVisible &&
				<DrawerPricingClinicCP
					visible={drawerPricingClinicVisible}
					onClose={() => setDrawerPricingClinicVisible(false)}
					clinicCode={selected.clinic.code}
					doctorGroupCode={props.doctorGroupCode}
				/>
			} */}
		</>
	)
}

const TableWrapperSCP = styled.div`
	// .not-configured-clinic {
	// 	background-color: ${ThemeProjectCommon.examUrgentTypeColor['URGENT']};
	// }
`
