import React from 'react'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ITitleAndTextCPProps extends BasicStyleWrapperCPProps {
	title: string
	text?: string | JSX.Element
	align?: 'center'
	invertBold?: boolean
	size?: FontSizeTP
	icon?: string // Icone do Ant
	color?: string
}

/**
 * COMPONTENTE Exibe um text em negrito como titulo e texto normal ao lado.
 */
export function TextAndTitleCP(props: ITitleAndTextCPProps): JSX.Element {
	return (
		<FlexCP justifyContent={props.align} margin={props.margin ?? { bottom: 5 }}>
			<TextCP icon={props.icon} text={props.title} strong={!props.invertBold} marginRight={5} size={props.size} color={props.color} />
			{typeof props.text === 'string' ? <TextCP color={props.color} text={props.text} size={props.size} strong={props.invertBold} /> : props.text}
		</FlexCP>
	)
}
