import { Checkbox } from 'antd'
import { FormItemICP } from '_old/main/common/components/form-fields/inner/FormItemICP'
import { IFormItemCommonProps } from '_old/main/common/components/form-fields/inner/IFormItemCommonProps'
import React from 'react'

interface IRadioGroupFormCPProps<OptionTP = string> extends IFormItemCommonProps {
	options: string[]
}

export function CheckBoxGroupFormCP<T>(props: IRadioGroupFormCPProps<T>): JSX.Element {
	return (
		<FormItemICP
			label={props.label}
			onChange={props.onChange}
			fieldName={props.fieldName}
			formStateManager={props.formStateManager}
			required={props.required}
			width={props.width}
			onFormSubmit={props.onFormSubmit}
			value={props.value}
			mask={props.mask}
		>
			<Checkbox.Group options={props.options} />
		</FormItemICP>
	)
}
