import React from 'react'
import { IDraftPluginProps } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/common/IDraftPluginProps'
import {
	IVoiceIptPluginControlCPProps,
	VoiceIptPluginControlCP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceIptPluginControlCP'

interface IVoiceInputPluginProps extends IDraftPluginProps {
	VoiceInputControl: (props: IVoiceIptPluginControlCPProps) => JSX.Element
}

/**
 * PLUGIN
 * Implementa funcionalidade de tabelas dentro do editor wysiwyg
 * customizado montado em cima do framework Draft JS.
 *
 * @author hjcostabr
 */
export const VoiceInputPlugin: IVoiceInputPluginProps = {
	VoiceInputControl: (props: IVoiceIptPluginControlCPProps) => <VoiceIptPluginControlCP {...props} />,
}
