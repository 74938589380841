import React from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { UploaderImageFromApiCP } from 'submodules/nerit-framework-ui/common/components/uploader/uploader-image-from-api/UploaderImageFromApiCP'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	personCode: number
	signatureUrl?: string
}

/**
 */
export function CardProfileChangeSignatureCP(props: ICPProps): JSX.Element | null {
	return (
		<CardCP title={'Assinatura'}>
			<WrapperSCP>
				<UploaderImageFromApiCP
					type={'orginal'}
					currentData={{
						code: props.personCode,
						imgUrl: props.signatureUrl,
					}}
					appearance={{
						previewWidth: '300px',
						previewHeight: '100px',
					}}
					requestApiConfig={{
						requestApi: PeopleRequests.changeSignature,
					}}
				/>

				<p>DIMENSÕES: Largura 300px X 100px de Altura</p>
			</WrapperSCP>
		</CardCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-style: italic;
		color: ${ThemeProject.gray300};
	}
`
