import React, { useEffect } from 'react'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { UserDoctorGroupUpdateStatusRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/UserDoctorGroupUpdateStatusRequestDTO'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	doctorGroupCode: number
	status: UserDoctorGroupStatusEnum
	onSave?: () => void
}

/**
 * Botao que coloca uma pessoa do grupo como ativo ou tira o ativo dela.
 */
export function ButtonToggleUserDoctorGroupStatusCP(props: ICPProps): JSX.Element {
	const updateUserDoctorGroupStatusRequest = useRequest<void>('none')

	useEffect(onUpdateUserDoctorGroupStatusRequestChange, [updateUserDoctorGroupStatusRequest.isAwaiting])

	/**
	 * Muda ou nao pra ativo.
	 */
	function toggleStatus(): void {
		const dto: UserDoctorGroupUpdateStatusRequestDTO = {
			status: props.status === UserDoctorGroupStatusEnum.ACTIVE ? UserDoctorGroupStatusEnum.INACTIVE : UserDoctorGroupStatusEnum.ACTIVE,
		}

		updateUserDoctorGroupStatusRequest.runRequest(DoctorGroupsRequests.updateUserDoctorGroupStatus(props.doctorGroupCode, dto))
	}

	/**
	 * Retorno ao tornar pessoa ativa.
	 */
	function onUpdateUserDoctorGroupStatusRequestChange(): void {
		const isRequestValid = RequestUtils.isValidRequestReturn(
			updateUserDoctorGroupStatusRequest,
			NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
			'Status atualizado com sucesso!',
			true,
		)

		if (!isRequestValid) return

		if (!!props.onSave) props.onSave()
	}

	return (
		<ButtonCP
			type={props.status === UserDoctorGroupStatusEnum.ACTIVE ? 'danger' : undefined}
			icon={'poweroff'}
			confirmMsg={`Você tem certeza que deseja ${props.status === UserDoctorGroupStatusEnum.ACTIVE ? 'desativar?' : 'ativar?'}`}
			onClick={toggleStatus}
			loading={updateUserDoctorGroupStatusRequest.isAwaiting}
			size={'small'}
		/>
	)
}
