import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalTimetableGroupByTP } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableGroupByTP'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DoctorOnMedicalTimetableDeleteRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableDeleteRequestDTO'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
	doctorGroupCode: number
	dayOfWeek: WeekDaysEnum
	period: MedicalTimetablePeriodEnum
	modality: ExamModalityEnum
	groupBy: MedicalTimetableGroupByTP
	id: MedicalTimetableIdEnum
	doctorCode: number
	date: Date
	isRecurrent?: boolean
	onSave: () => void
}

/**
 */
export function ButtonMedicalTimetableUnassignCP(props: ICPProps): JSX.Element {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)

	const unassignRequest = useRequest<void>('none')
	useEffect(onAssingRequestChange, [unassignRequest.isAwaiting])

	/**
	 */
	function unassignDoctor(shouldDeleteRecurrency: boolean): void {
		const dto: DoctorOnMedicalTimetableDeleteRequestDTO = {
			dayOfWeek: props.dayOfWeek,
			doctorGroupCode: props.doctorGroupCode,
			modality: props.modality,
			groupBy: props.groupBy,
			userDoctorCode: props.doctorCode,
			period: props.period,
			id: props.id,
			date: props.date,
			shouldDeleteFollowingDays: shouldDeleteRecurrency,
		}
		unassignRequest.runRequest(MedicalTimetableRequests.deleteDoctorsOnMedicalTimetable(dto))
	}

	/**
	 */
	function onAssingRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				unassignRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
		setIsPopoverVisible(false)
	}

	return (
		<>
			{!props.isRecurrent ? (
				<ButtonCP
					icon={'close'}
					size={'small'}
					onClick={() => unassignDoctor(false)}
					loading={unassignRequest.isAwaiting}
					confirmMsg={'Você tem certeza que deseja tirar o médico da escala?'}
				/>
			) : (
				<PopOverCP
					visible={isPopoverVisible}
					onClose={() => setIsPopoverVisible(false)}
					trigger={'click'}
					title={'Esta alocação é recorrente!'}
					content={
						<WrapperSCP>
							Você deseja remover a alocação do médico apenas neste dia específico (
							{DateUtils.formatDate(props.date, DateFormatEnum.BR_DAY_MONTH)}) OU deseja apagar a recorrência deste dia em diante?
							<FlexCP justifyContent={'space-between'} margin={{ top: 30 }}>
								<ButtonCP
									size={'small'}
									onClick={() => unassignDoctor(true)}
									loading={unassignRequest.isAwaiting}
									type={'danger'}
									confirmMsg={'Você tem certeza que deseja remover a recorrência, essa ação não pode ser desfeita!'}
								>
									Remover daqui em diante
								</ButtonCP>

								<ButtonCP size={'small'} onClick={() => unassignDoctor(false)} loading={unassignRequest.isAwaiting}>
									Remover apenas este dia
								</ButtonCP>
							</FlexCP>
						</WrapperSCP>
					}
				>
					<ButtonCP icon={'close'} size={'small'} onClick={() => setIsPopoverVisible(true)} />
				</PopOverCP>
			)}
		</>
	)
}

const WrapperSCP = styled.div`
	width: 400px;
`
