import React from 'react'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { WidgetCountExamsRectificationAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-awaiting/WidgetCountExamsRectificationAwaitingCP'
import { WidgetCountExamsAssignedCP } from 'modules/reports/components/widgets/widget-count-exams-assigned/WidgetCountExamsAssignedCP'
import { WidgetCountExamsAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-awaiting/WidgetCountExamsAwaitingCP'
import { WidgetCountAuditsOpenCP } from 'modules/reports/components/widgets/widget-count-audits-open/WidgetCountAuditsOpenCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { WidgetCountExamsBySlaCP } from 'modules/reports/components/widgets/widget-count-exams-by-sla/WidgetCountExamsBySlaCP'
import { CountExamsWidgetEnum } from 'submodules/beerads-sdk/services/dashboard/widgets/enums/CountExamsWidgetEnum'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'

interface ICPProps {
	clinicCode?: number
}

/**
 */
export function ScreenContentHomeDoctor(props: ICPProps): JSX.Element {
	return (
		<>
			<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
				<WidgetCountExamsAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={true} />
				<WidgetCountExamsAssignedCP clinicCode={props.clinicCode} viewAsDoctor={true} />
				<WidgetCountExamsRectificationAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={true} />

				<AccessControlCP permission={{ byHasAnyDoctorGroupRelationPermission: ['isAuditor'] }}>
					<WidgetCountAuditsOpenCP
						filters={{
							doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
							doctorAuditorCode: AppStateUtils.getLoggedUser()?.code,
						}}
						shouldLoad={1}
					/>
				</AccessControlCP>
			</WidgetsRowCP>

			<WidgetsRowCP margin={{ top: 80, bottom: 30 }}>
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.BY_SLA_DELAYED}
					assignedType={ExamAssignedFilterEnum.YES}
					viewAsDoctor={true}
				/>
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.ASSIGNED_BY_SLA_2H}
					assignedType={ExamAssignedFilterEnum.YES}
					viewAsDoctor={true}
				/>
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.ASSIGNED_BY_SLA_6H}
					assignedType={ExamAssignedFilterEnum.YES}
					viewAsDoctor={true}
				/>
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.ASSIGNED_BY_SLA_24H}
					assignedType={ExamAssignedFilterEnum.YES}
					viewAsDoctor={true}
				/>
			</WidgetsRowCP>
		</>
	)
}
