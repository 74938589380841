import React from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps {
	formStateManager: IFormStateManager<ClinicFormModel>
}

/**
 * Formulário das informações do responsavel tecnico da clincia
 */
export function FormClinicTechnicalResponsibleDataCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
				Responsável Técnico
			</TitleCP>

			<RowCP>
				<ColumnCP size={12}>
					<InputCP label={'Nome Completo'} formStateManager={props.formStateManager} fieldName={'responsibleTechName'} />
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP label={'RQE'} formStateManager={props.formStateManager} fieldName={'responsibleTechSpecialty'} />
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP label={'CRM'} formStateManager={props.formStateManager} fieldName={'responsibleTechId'} />
				</ColumnCP>
			</RowCP>
		</>
	)
}
