import React from 'react'
import styled from 'styled-components'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ScpProps {
	color?: string
	borderColor?: string
	size?: number
	onClick?: () => void
	hideMarginRight?: boolean
}

interface ICPProps extends ScpProps {
	text?: React.ReactNode
	tooltip?: React.ReactNode
	loading?: boolean
}

/**
 * Forma de exibir a cor na legenda.
 */
export function RoundedWrapperCP(props: ICPProps): JSX.Element {
	return (
		<TooltipCP text={props.tooltip}>
			<WrapperSCP
				color={props.color ?? 'transparent'}
				borderColor={props.borderColor}
				size={props.size ?? 18}
				onClick={props.onClick}
				hideMarginRight={props.hideMarginRight}
			>
				{props.loading ? <LoadingCP size={16} show={true} /> : props.text}
			</WrapperSCP>
		</TooltipCP>
	)
}

const WrapperSCP = styled.div<ScpProps>`
	background-color: ${(props) => props.color};
	border: ${(props) => (props.borderColor ? `solid 4px ${props.borderColor}` : undefined)};
	height: ${(props) => props.size}px;
	width: ${(props) => props.size}px;
	margin-right: ${(props) => (props.hideMarginRight ? undefined : '5px')};
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: ${(props) => (!!props.onClick ? 'pointer' : undefined)};
	color: ${ThemeFrameworkCommon.browserDefaultColorLight};
`
