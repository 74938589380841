import { TableExamsCP } from 'modules/exams/components/exams/table-exams/TableExamsCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import React, { useEffect, useState } from 'react'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'

interface ICPProps {
	clinicCode?: number
}

export function TableCriticalExamCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<ExamFilterFormModel>()
	const filterStateManager = useFormStateManager<ExamFilterFormModel>(formModel)

	const [loadList, setLoadList] = useState<number>(0)

	useEffect(init, [props.clinicCode])

	/**
	 * Inicializa
	 */
	function init(): void {
		setFormModel(
			new ExamFilterFormModel({
				viewAsDoctor: false,
				clinicCodes: !!props.clinicCode ? [props.clinicCode] : undefined,
				status: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE),
				medicalReportInHourLimit: 12,
				isCritical: true,
				sorter: {
					orderingCriteria: ExamSearchOrderingEnum.MEDICAL_REPORT_DATE,
					orderingAscending: false,
				},
			}),
		)

		setLoadList(1)
	}

	return (
		<>
			<TableExamsCP
				appearance={{
					title: 'Achado Crítico das últimas 12 horas',
				}}
				reloadTable={loadList}
				filterStateManager={filterStateManager}
			/>
		</>
	)
}
