import React from 'react'
import styled from 'styled-components'
import { Timeline } from 'antd'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export interface ITimelineItem {
	color?: string
	icon?: string | JSX.Element
	title?: string
	content: string | JSX.Element
}

interface ICPProps extends BasicStyleWrapperCPProps {
	pending?: boolean | string | JSX.Element
	pendingDot?: string | JSX.Element
	reverse?: boolean
	mode?: 'left' | 'alternate' | 'right'
	items?: ITimelineItem[]
	loading?: boolean
}

/**
 * Componente wrapper TIMELINE do antd
 */
export function TimelineCP(props: ICPProps): JSX.Element {
	if (props.loading) return <LoadingCP show={true} />

	if (ArrayUtils.isEmpty(props.items)) return <EmptyCP />

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<Timeline pending={props.pending} pendingDot={props.pendingDot} reverse={props.reverse} mode={props.mode}>
				{props.items?.map((item) => (
					<Timeline.Item color={item.color} dot={item.icon} key={item.title}>
						<ItemWrapperSCP>
							{!!item.title && <TextCP text={item.title} fontStyle={'italic'} color={ThemeProject.gray300} />}
							{item.content}
						</ItemWrapperSCP>
					</Timeline.Item>
				))}
			</Timeline>
		</BasicStyleWrapperCP>
	)
}

const ItemWrapperSCP = styled.div`
	margin: 0 10px;
`
