import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import * as _ from 'lodash'

import { ValidationTypes } from '_old/main/common/validation/ValidationTypes'

/**
 * VALIDATOR
 * Valida se valor esta na lista de valores permitidos.
 *
 * @author guilherme.diniz
 * @author hjcostabr
 */
@ValidatorConstraint({ name: ValidationTypes.IS_IN })
class IsInConstraint implements ValidatorConstraintInterface {
	validate(value: any, args: ValidationArguments): boolean {
		const validator = new Validator()
		return value instanceof Array ? _.every(value, (val) => validator.isIn(val, args.constraints)) : validator.isIn(value, args.constraints)
	}

	defaultMessage(args: ValidationArguments): string {
		return 'Valor não permitido'
	}
}

/**
 * DECORATOR
 * @param {any[]} values
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsIn(values: any[], validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: values,
			validator: IsInConstraint,
		})
	}
}
