import { ButtonSize } from 'antd/es/button'
import FileSaver from 'file-saver'
import React, { useEffect, useState } from 'react'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MimeTypeUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/MimeTypeUtils'
import { ButtonMultiActionCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonMultiActionCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ModalMedicalReportPdfPrinterCP } from 'modules/exams/components/medical-report/modals/modal-medical-report-pdf-printer/ModalMedicalReportPdfPrinterCP'
import { PatientPortalRequests } from 'submodules/beerads-sdk/services/patient-portal/PatientPortalRequests'

interface ICPProps extends BasicStyleWrapperCPProps {
	fileName: string
	size?: ButtonSize
	type?: 'primary' | 'ghost' | 'dashed'
	onDownloadDone?: () => void
	showPrinter?: boolean
	digitalCertification?: {
		isDigitalCertificateRequired?: boolean
		isDigitallySigned?: boolean
	}
	downloadedByConfig: {
		user?: {
			examIds: string[]
		}
		patient?: {
			examId: string
		}
	}
}

/**
 * Botao de acao de download de LAUDO.
 */
export function ButtonDownloadMedicalReportCP(props: ICPProps): JSX.Element {
	const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false)

	const downloadMedicalReportRequest = useRequest<Blob>('none')
	useEffect(onDownloadMedicalReportRequestChange, [downloadMedicalReportRequest.isAwaiting])

	/**
	 */
	function download(): void {
		// Downlod feito pelo usuario do sistema
		if (!!props.downloadedByConfig.user) {
			downloadMedicalReportRequest.runRequest(MedicalReportRequests.downloadMedicalReports({ examIds: props.downloadedByConfig.user.examIds }))
			return
		}

		// Download feito pelo paciente
		if (!!props.downloadedByConfig.patient) {
			downloadMedicalReportRequest.runRequest(PatientPortalRequests.downloadMedicalReport(props.downloadedByConfig.patient.examId))
			return
		}

		NotificationHelper.error('Ops', 'Informe se o download será feito pelo paciente ou usuário')
	}

	/**
	 */
	function onDownloadMedicalReportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(downloadMedicalReportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const fileName = `${props.fileName}${MimeTypeUtils.getExtensonByMimeType(downloadMedicalReportRequest.responseType)}`
		FileSaver.saveAs(downloadMedicalReportRequest.responseData!, fileName)

		if (!!props.onDownloadDone) props.onDownloadDone()
	}

	return (
		<>
			<ButtonMultiActionCP
				options={[
					{
						label: 'Baixar Laudo(s)',
						onClick: download,
						icon: <IconICP size={16} iconName={'download'} />,
						disabled: props.digitalCertification?.isDigitalCertificateRequired && !props.digitalCertification?.isDigitallySigned,
					},
					{
						label: 'Imprimir Laudo(s)',
						icon: <IconICP size={16} iconName={'printer'} />,
						onClick: () => setIsPrintModalVisible(true),
						hide:
							!props.showPrinter ||
							(props.digitalCertification?.isDigitalCertificateRequired && !props.digitalCertification?.isDigitallySigned),
					},
				]}
				loading={downloadMedicalReportRequest.isAwaiting}
				size={props.size}
				type={props.type}
				margin={props.margin}
				tooltip={
					props.digitalCertification?.isDigitalCertificateRequired && !props.digitalCertification?.isDigitallySigned
						? 'O laudo ainda não foi assinado digitalmente'
						: undefined
				}
				disabled={props.digitalCertification?.isDigitalCertificateRequired && !props.digitalCertification?.isDigitallySigned}
			/>

			<ModalMedicalReportPdfPrinterCP
				examId={props.downloadedByConfig.patient?.examId ?? props.downloadedByConfig.user?.examIds?.[0]}
				onCancel={() => setIsPrintModalVisible(false)}
				visible={isPrintModalVisible}
				downloadBy={props.downloadedByConfig.patient ? 'patient' : 'user'}
			/>
		</>
	)
}
