import { TextCP } from '_old/main/common/components/text/TextCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ExamModalityEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'
import { ExamExtraDataTP } from 'submodules/beerads-sdk/services/exams/exams/types/ExamExtraDataTP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ICPProps {
	modality: ExamModalityEnum
	description?: string
	patientName?: string
	fontSize?: FontSizeTP
	size?: number
	borderColor?: string
	tag?: boolean
	onClick?: () => void
	extraData?: ExamExtraDataTP
}

/**
 * Componente para exibir sigla da modalidade de exame
 */
export function AvatarExamModalityCP(props: ICPProps): JSX.Element {
	return (
		<FlexCP alignItems={'center'} onClick={props.onClick}>
			<ContainerSCP tag={props.tag} size={props.size ?? 45}>
				<TextCP color={'#5E85BB'} text={ExamModalityLabelEnum[props.modality]} size={props.fontSize} />
			</ContainerSCP>
			{!!props.description && (
				<DescriptionSCP>
					{props.patientName && <TextCP text={props.patientName} strong={true} size={'normal'} />}
					<FlexCP alignItems="center" gap="4px">
						<TextCP wrapText text={props.description} size={'small'} />
						{props.extraData?.doNotBilling && <TagCP content="Este exame não será faturado" small />}
					</FlexCP>
				</DescriptionSCP>
			)}
		</FlexCP>
	)
}

const ContainerSCP = styled.div<{ tag?: boolean; size?: number }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	background-color: #dae1ef;
	min-height: ${(props) => props.size}px;
	min-width: ${(props) => props.size}px;
	${(props) => (props.tag ? 'padding: 0 5px;' : '')};
	margin-right: 5px;
`

const DescriptionSCP = styled.div`
	margin-left: 8px;
	display: flex;
	flex-direction: column;
`
