import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import React, { useEffect, useState } from 'react'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	examCode: number
	examStatus: ExamStatusEnum
	isDigitalCertificateRequired: boolean
	onSave?: () => void
	doctorGroupCode?: number
}

export function ButtonToggleExamDigitalCertificateCP(props: ICPProps): JSX.Element {
	const [isDigitalCertificateRequired, setDigitalCertificateRequired] = useState<boolean>(props.isDigitalCertificateRequired)

	const updateToggleDigitalCertificateRequest = useRequest<void>('none')
	useEffect(onUpdateToggleDigitalCertificateRequestChange, [updateToggleDigitalCertificateRequest.isAwaiting])

	function toggleDigitalCertificate(): void {
		updateToggleDigitalCertificateRequest.runRequest(ExamsRequests.toggleDigitalCertificate(props.examCode))
	}

	function onUpdateToggleDigitalCertificateRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateToggleDigitalCertificateRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		setDigitalCertificateRequired(!props.isDigitalCertificateRequired)

		if (!!props.onSave) props.onSave()
	}

	if (!props.doctorGroupCode || props.examStatus === ExamStatusEnum.PRINTED) {
		return <></>
	}

	return (
		<AccessControlCP
			permission={{
				byHasAnyPermissionInDoctorGroup: {
					doctorGroupCode: props.doctorGroupCode,
					permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
				},
			}}
		>
			<ButtonCP
				size={'small'}
				icon={'safety'}
				tooltip={isDigitalCertificateRequired ? 'Desativar exigência do certificado digital' : 'Ativar exigência do certificado digital'}
				tooltipPlacement={'bottomRight'}
				onClick={toggleDigitalCertificate}
				type={isDigitalCertificateRequired ? 'primary' : undefined}
				confirmMsg={
					isDigitalCertificateRequired
						? 'Tem certeza de que deseja desativar a exigência do certificado digital?'
						: 'Tem certeza de que deseja ativar a exigência do certificado digital?'
				}
			/>
		</AccessControlCP>
	)
}
