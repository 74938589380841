import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { DuplicateExamFormModel } from 'modules/exams/components/exams/modal-duplicate-exam/inner/DuplicateExamFormModel'
import { DuplicateExamFormDataICP } from 'modules/exams/components/exams/modal-duplicate-exam/inner/DuplicateExamFormDataICP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { PendingExamFormModelConverter } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModelConverter'
import { DuplicateExamRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/DuplicateExamRequestDTO'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { DuplicateExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/DuplicateExamResponseDTO'

interface ICPProps {
	exam: ExamResponseDTO
	onCancel: () => void
	onSave: (createdExamIds: string[]) => void
	visible: boolean
}

/**
 */
export function ModalDuplicateExamCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<DuplicateExamFormModel>()
	const formStateManager = useFormStateManager<DuplicateExamFormModel>(formValidator)

	const duplicateExamRequest = useRequest<DuplicateExamResponseDTO>()
	useEffect(onDuplicateExamRequestChange, [duplicateExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 */
	function init(): void {
		formStateManager.reset(undefined)
		if (!props.visible) return

		setFormValidator(
			new DuplicateExamFormModel({
				currentExam: {
					modality: props.exam.modality,
					description: props.exam.description,
					accessionNumber: props.exam.extraData?.accessionNumber ?? props.exam.extraData?.serviceOrder,
				},
				exams: [
					{
						modality: props.exam.modality,
						description: '',
					},
				],
			}),
		)
	}

	/**
	 */
	async function duplicate(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		if (!PendingExamFormModelConverter.validateExamDescriptions([formValues.currentExam, ...formValues.exams], props.exam.doctorGroup)) return

		const dto: DuplicateExamRequestDTO = {
			currentExamDescription: formValues.currentExam,
			newExamDescriptions: formValues.exams,
		}
		duplicateExamRequest.runRequest(ExamsRequests.duplicateExam(props.exam.code, dto))
	}

	/**
	 */
	function onDuplicateExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				duplicateExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		props.onSave(duplicateExamRequest.responseData!.createdExamIds)
	}

	if (!formStateManager.getFormValues()) return <></>

	return (
		<ModalCP
			title={'Criar nova entrada do exame'}
			visible={props.visible}
			onOk={duplicate}
			actionLoading={duplicateExamRequest.isAwaiting}
			onCancel={props.onCancel}
			top={10}
			width={700}
		>
			<DuplicateExamFormDataICP formStateManager={formStateManager} exam={props.exam} />
		</ModalCP>
	)
}
