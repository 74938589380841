import React from 'react'
import { RectificationReasonsEnum, RectificationReasonsLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/RectificationReasonsEnum'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	onChange?: (selected: RectificationReasonsEnum) => void
	required?: boolean
	allowClear?: boolean
	fieldName?: string
	formStateManager?: IFormStateManager<any>
	multiple?: {
		isMultiple: boolean
		maxTagCount?: number
	}
}

export function SelectRectificationReason(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={'Motivo:'}
			options={Object.values(RectificationReasonsEnum).map((reasonEnum) => ({
				value: reasonEnum,
				label: RectificationReasonsLabelEnum[reasonEnum],
			}))}
			required={props.required}
			onChange={props.onChange}
			allowClear={props.allowClear}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			isMultiple={props.multiple?.isMultiple}
			maxTagCount={props.multiple?.maxTagCount}
		/>
	)
}
