import React, { useState } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputZipCodeSearchCP } from 'submodules/nerit-framework-ui/features/address/components/input-zip-code-search/InputZipCodeSearchCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'

interface ICPProps {
	formStateManager: IFormStateManager<AddressFormModel>
}

/**
 * Campos de endereco para paineis de formularios de cadastro.
 */
export function FormAddressCP(props: ICPProps): JSX.Element {
	const [isAwaiting, setIsAwaiting] = useState<boolean>(false)

	return (
		<>
			<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
				Endereço
			</TitleCP>

			<RowCP>
				<ColumnCP size={6}>
					<InputZipCodeSearchCP
						formStateManager={props.formStateManager}
						fieldName={'zipCode'}
						onSearchStart={() => setIsAwaiting(true)}
						onSearchEnd={() => setIsAwaiting(false)}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={14}>
					<InputCP label={'Rua'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'street'} />
				</ColumnCP>

				<ColumnCP size={4}>
					<InputCP label={'No.'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'number'} />
				</ColumnCP>

				<ColumnCP size={6}>
					<InputCP label={'Comp.'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'complement'} />
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={10}>
					<InputCP label={'Bairro'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'neighborhood'} />
				</ColumnCP>

				<ColumnCP size={10}>
					<InputCP label={'Cidade'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'city'} />
				</ColumnCP>

				<ColumnCP size={4}>
					<InputCP label={'UF'} disabled={isAwaiting} formStateManager={props.formStateManager} fieldName={'state'} />
				</ColumnCP>
			</RowCP>
		</>
	)
}
