import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { LogoCP } from 'submodules/nerit-framework-ui/common/components/logo/LogoCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import styled from 'styled-components'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { QrCodeCP } from 'submodules/nerit-framework-ui/common/components/qr-code/QrCodeCP'
import { LinkHtmlCP } from 'submodules/nerit-framework-ui/common/components/link-html/LinkHtmlCP'
import { PatientPortalAccessUtils } from 'submodules/beerads-sdk/services/patient-portal/utils/PatientPortalAccessUtils'

interface ICPProps {
	exam: ExamResponseDTO
	clinic: ClinicResponseDTO
}

/**
 */
export function PatientPortalDataPdfHeaderICP(props: ICPProps): JSX.Element {
	// URL com dados de acesso
	const beeRadsLaudoUrl = PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppStateUtils.getDomain())

	return (
		<FlexCP justifyContent={'space-between'} alignItems={'center'} className={'header-1'}>
			<LogoCP marginTop={0} marginBottom={0} imgUrl={props.clinic.imageUrl} width={250} />
			<RightSCP>
				<FlexCP>
					<div>
						<TextCP text={'Portal de Laudo Online'} size={'extraLarge'} />
						<TextCP text={props.exam.clinic.name} style={{ fontSize: '15px' }} />
					</div>

					<QrCodeCP margin={{ left: 10 }} url={beeRadsLaudoUrl} size={60} />
				</FlexCP>

				<FlexCP flexDirection={'column'} alignItems={'flex-end'} className={'header-3'} margin={{ top: 10 }}>
					<TextAndTitleCP title={'Código do Exame:'} text={props.exam.code.toString()} />
					<TextAndTitleCP
						title={'Data de Nascimento:'}
						text={DateUtils.getFormatted(props.exam.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME)}
					/>
					<TextAndTitleCP title={'Nome Completo:'} text={props.exam.patient.name} />
					<LinkWrapperSCP>
						<LinkHtmlCP type={'link'} href={beeRadsLaudoUrl}>
							{PatientPortalAccessUtils.getBeeRadsLaudoUrl(false, AppStateUtils.getDomain())}
						</LinkHtmlCP>
					</LinkWrapperSCP>
				</FlexCP>
			</RightSCP>
		</FlexCP>
	)
}

const RightSCP = styled.div`
	text-align: right;
`

const LinkWrapperSCP = styled.div`
	a {
		padding: 0px;
		text-decoration: underline;
	}
`
