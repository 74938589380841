import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalReportConfigRequests } from 'submodules/beerads-sdk/services/clinics/medical-report-config/MedicalReportConfigRequests'
import { MedicalReportConfigPreviewRequestDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/request/MedicalReportConfigPreviewRequestDTO'

interface ICPProps {
	mode: 'default' | 'oit'
	clinicCode: number
}

/**
 * Componente para personalização das informações do laudo
 */
export function ButtonPreviewClinicReportTemplateCP(props: ICPProps): JSX.Element {
	const previewRequest = useRequest<string>()
	useEffect(onPreviewRequestChange, [previewRequest.isAwaiting])

	/**
	 * Preview do laudo.
	 */
	function getPreview(): void {
		const dto: MedicalReportConfigPreviewRequestDTO = {
			type: props.mode,
		}
		previewRequest.runRequest(MedicalReportConfigRequests.previewPdf(props.clinicCode, dto))
	}

	/**
	 * Retorno do preview.
	 */
	function onPreviewRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(previewRequest, 'Ocorreu algum erro ao gerar o preview')) return

		if (previewRequest.responseData) window.open(previewRequest.responseData, '_blank')
	}

	return (
		<ButtonCP loading={previewRequest.isAwaiting} onClick={getPreview}>
			Pré visualizar
		</ButtonCP>
	)
}
