import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NpsTotalResponderByTypeCP } from 'submodules/space4leads-components-ui/survey/components/nps-answers-by-type/NpsTotalResponderByTypeCP'
import { NpsResponderTypeEnum } from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'
import { faGrinHearts } from '@fortawesome/free-solid-svg-icons'
import { NpsDashboardResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/NpsDashboardResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NPSDashboardRequestDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/request/NPSDashboardRequestDTO'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { CardNpsCP } from 'submodules/space4leads-components-ui/report/components/card-nps/CardNpsCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'

interface ICPProps {
	reload: number
	type: 'project' | 'account'
	filters?: {
		tags?: string[]
		projectCode?: number
		dateRange?: DateRangeSearchRequestDTO
	}
}

/**
 * Widgets das notas de NPS
 */
export function WidgetsNpsScore(props: ICPProps): JSX.Element {
	const [npsScores, setNpsScores] = useState<NpsDashboardResponseDTO>()

	const getNpsScoresRequest = useRequest<NpsDashboardResponseDTO>()
	useEffect(onGetNpsScoresRequestChange, [getNpsScoresRequest.isAwaiting])

	useEffect(init, [props.reload])

	/**
	 * Inicializa dados para tela.
	 */
	function init(): void {
		const filters: NPSDashboardRequestDTO = {
			tags: props.filters?.tags,
			dateRange: props.filters?.dateRange,
		}

		if (props.type === 'project') getNpsScoresRequest.runRequest(SurveysRequests.getNpsDashboard(filters, props.filters?.projectCode))
		else getNpsScoresRequest.runRequest(SurveysRequests.getNpsDashboardAccount(filters, props.filters?.projectCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetNpsScoresRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getNpsScoresRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setNpsScores(getNpsScoresRequest.responseData)
	}

	return (
		<FlexCP alignItems={'center'}>
			<FlexOneICP>
				<WrapperSCP>
					{!!npsScores && (
						<AnswerSCP>
							<NpsTotalResponderByTypeCP type={NpsResponderTypeEnum.DETRACTOR} value={npsScores.detractors} total={npsScores.totalAnswers} />
							<NpsTotalResponderByTypeCP type={NpsResponderTypeEnum.PASSIVE} value={npsScores.passives} total={npsScores.totalAnswers} />
							<NpsTotalResponderByTypeCP type={NpsResponderTypeEnum.PROMOTER} value={npsScores.promoters} total={npsScores.totalAnswers} />
						</AnswerSCP>
					)}
				</WrapperSCP>
			</FlexOneICP>

			<CardNpsCP
				loading={getNpsScoresRequest.isAwaiting}
				value={npsScores?.npsScore}
				prefix={<FontAwsomeIconCP type={faGrinHearts} size={'3x'} />}
				title={'NPS atual'}
				valueStyle={{ fontSize: '50px' }}
			/>
		</FlexCP>
	)
}

const WrapperSCP = styled.div`
	margin-left: 20px;
`

const AnswerSCP = styled.div`
	display: flex;
`
