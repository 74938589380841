import { DoctorGroupUtils } from 'modules/doctor-groups/utils/DoctorGroupUtils'
import React from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { AddressFormValidator } from '_old/main/common/validators/AddressFormValidator'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { FormAddressCP } from 'submodules/nerit-framework-ui/features/address/components/form-address/FormAddressCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormClinicResponsibleDataCP } from 'modules/clinic/components/form-clinic/inner/FormClinicResponsibleDataCP'
import { FormClinicTechnicalResponsibleDataCP } from 'modules/clinic/components/form-clinic/inner/FormClinicTechnicalResponsibleDataCP'

interface ICPProps {
	clinicFormStateManager: IFormStateManager<ClinicFormModel>
	addressFormStateManager: IFormStateManager<AddressFormValidator>
	canEditSlug: boolean
	showSlug: boolean
}

/**
 * Formulário das informações da clínica
 */
export function FormClinicMainDataCP(props: ICPProps): JSX.Element {
	return (
		<>
			<RowCP>
				{props.showSlug && (
					<ColumnCP size={12}>
						<InputCP
							label={'Domínio'}
							formStateManager={props.clinicFormStateManager}
							fieldName={'slug'}
							onChange={(value) => props.clinicFormStateManager.changeFieldValue('slug', DoctorGroupUtils.slugMask(value))}
							value={props.clinicFormStateManager.getFieldValue('slug')}
							required={true}
							disabled={!props.canEditSlug}
							hint={{
								type: 'tooltip',
								text: 'O domínio deve ser minúsculo, sem espaço e caracteres especiais. O domínio será utilizado na integração com o equipamento',
							}}
						/>
					</ColumnCP>
				)}
				<ColumnCP size={12}>
					<InputCP
						label={'CNPJ'}
						fieldName={'cnpj'}
						mask={InputMaskTypeEnum.CNPJ}
						formStateManager={props.clinicFormStateManager}
						required={true}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<InputCP label={'Razão Social'} fieldName={'socialName'} formStateManager={props.clinicFormStateManager} required={true} />
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP label={'Nome Fantasia'} fieldName={'fantasyName'} formStateManager={props.clinicFormStateManager} required={true} />
				</ColumnCP>
			</RowCP>

			<FormClinicResponsibleDataCP clinicFormStateManager={props.clinicFormStateManager} />
			<FormClinicTechnicalResponsibleDataCP formStateManager={props.clinicFormStateManager} />
			<FormAddressCP formStateManager={props.addressFormStateManager} />
		</>
	)
}
