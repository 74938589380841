import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RadioOptionsYesOrNo } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/inner/RadioOptionTP'
import { AvcSectionContentICP, IAvcSectionProps } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/AvcSectionContentICP'

/**
 */
export function AvcQuestion2ICP(props: IAvcSectionProps): JSX.Element {
	return (
		<AvcSectionContentICP title={'2. SINAIS TOMOGRÁFICOS QUE SUGIRAM ISQUEMIA RECENTE'}>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSuba'}
						label={'Apagamento insular:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSubd'}
						label={'Hipodensidade nucleocapsular:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Artéria densa:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={' Pontilhado ACM (ramos sylvianos):'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Assimetria de sulcos corticais:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={' Território - Circul. Ant.:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território - Circul. Post.:'}
					/>
				</ColumnCP>
			</RowCP>
		</AvcSectionContentICP>
	)
}
