import { PendingRectificationAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/inner/PendingRectificationAnalysisExamFormModel'
import ModalRefuseRectificationCP from 'modules/exams/components/exam-analysis/modal-refuse-rectification/ModalRefuseRectification'
import React, { useEffect, useState } from 'react'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { ExamPendingRectificationAnalysisResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingRectificationAnalysisResolveRequestDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	examCode: number
	onSave: () => void
}

export default function ButtonRefuseRectification(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<PendingRectificationAnalysisExamFormModel>(new PendingRectificationAnalysisExamFormModel())
	const formStateManager = useFormStateManager<PendingRectificationAnalysisExamFormModel>(formModel)
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	const resolveExamRequest = useRequest<void>('none')

	useEffect(init, [])
	useEffect(onResolveExamRequestChange, [resolveExamRequest.isAwaiting])

	function init(): void {
		setFormModel(new PendingRectificationAnalysisExamFormModel())
	}

	function handleSaveRefuseRectification(sendTo: ExamStatusEnum.DONE_RECTIFICATION): void {
		if (!FormStateManagerUtils.validateRequiredFields(formStateManager)) {
			return NotificationHelper.error('Ops', 'Preencha a resolução para prosseguir')
		}

		const formValues = formStateManager.getFormValues()!

		const dto: ExamPendingRectificationAnalysisResolveRequestDTO = {
			sendTo,
			comments: formValues.comments,
			doctorCode: undefined,
		}
		resolveExamRequest.runRequest(ExamsAnalysisRequests.resolvePendingRectificationAnalysisExam(props.examCode, dto))
	}

	function onResolveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				resolveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		) {
			return
		}

		props.onSave()
	}

	return (
		<>
			<ButtonCP
				size={'small'}
				icon={'arrow-left'}
				onClick={() => setIsModalVisible(true)}
				tooltip={'Negando o pedido, é declarado que o laudo atual e o exame irão para "Retificação Concluída"'}
			>
				Negar pedido de retificação
			</ButtonCP>
			<ModalRefuseRectificationCP
				formStateManager={formStateManager}
				isVisible={isModalVisible}
				onSave={() => handleSaveRefuseRectification(ExamStatusEnum.DONE_RECTIFICATION)}
				onCancel={() => setIsModalVisible(false)}
			/>
		</>
	)
}
