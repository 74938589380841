import { FindPersonResultICP } from 'modules/person/components/common/find-person-box/inner/FindPersonResultICP'
import { FormPersonCP } from 'modules/person/components/user/form-user/FormPersonCP'
import React, { useEffect, useState } from 'react'
import { InputSearchPersonByCpfCP } from 'modules/person/components/common/input-search-person-by-cpf/InputSearchPersonByCpfCP'
import { PersonByCpfResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonByCpfResponseDTO'
import { PersonFormModel } from 'modules/person/components/user/form-user/inner/PersonFormModel'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { DoctorGroupDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupDoctorSaveRequestDTO'

interface ICPProps {
	type: UserTypeEnum
	doctorGroupCode?: number
	onClickToAdd: (personCode: number, doctorData?: DoctorGroupDoctorSaveRequestDTO) => void
	isAdding: boolean
}

/**
 * Caixa com busca e exibicao de pessoa caso encontrada.
 */
export function FindPersonBoxCP(props: ICPProps): JSX.Element {
	const [formValidator] = useState<PersonFormModel>(new PersonFormModel())
	const formStateManager = useFormStateManager(formValidator)

	const [showInfo, setShowInfo] = useState(false)
	const [selectedPerson, setSelectedPerson] = useState<PersonByCpfResponseDTO>()

	useEffect(init, [])

	/**
	 * Ao inicializar
	 */
	function init(): void {
		setShowInfo(false)
		setSelectedPerson(undefined)
	}

	/**
	 * Ao criar a pessoa, usuario
	 */
	function onSaveUser(person: PersonResponseDTO): void {
		if (props.type === UserTypeEnum.USER) return props.onClickToAdd(person.code)

		// Para medico mostra as informacoes adicionais a serem preenchidas
		setSelectedPerson(person)
		setShowInfo(true)
	}

	return (
		<>
			{!showInfo && (
				<InputSearchPersonByCpfCP
					formStateManager={formStateManager}
					onResult={(person) => {
						setSelectedPerson(person)
						setShowInfo(true)
					}}
				/>
			)}

			{showInfo && !!selectedPerson && (
				<FindPersonResultICP
					onClickAdd={(doctorData) => props.onClickToAdd(selectedPerson!.code, doctorData)}
					person={selectedPerson!}
					loading={props.isAdding}
					type={props.type}
					doctorGroupCode={props.doctorGroupCode}
				/>
			)}

			{showInfo && !selectedPerson && (
				<>
					<AlertCP
						message={`Ops! ${
							props.type === UserTypeEnum.USER ? 'Usuário' : 'Médico'
						} não encontrado no sistema. Preencha os campos para criar um novo.`}
						type={'warning'}
						margin={{ bottom: 10, top: 0 }}
					/>
					<FormPersonCP formStateManager={formStateManager} onSave={onSaveUser} loading={props.isAdding} type={props.type} />
				</>
			)}
		</>
	)
}
