import React from 'react'
import { ScreenError404 } from 'submodules/nerit-framework-ui/common/screens/ScreenError404'
import { RouterSwitchCP } from 'submodules/nerit-framework-ui/common/router/components/router-switch/RouterSwitchCP'
import { ScreenPatientPortalHome } from 'modules/patient-portal/screens/screen-patient-portal-home/ScreenPatientPortalHome'

PrivatePatientPortalRouter.ROOT = 'patient-portal'

PrivatePatientPortalRouter.HOME = `${PrivatePatientPortalRouter.ROOT}/home`

/**
 */
export function PrivatePatientPortalRouter(): JSX.Element {
	return (
		<RouterSwitchCP
			routes={[
				{ path: PrivatePatientPortalRouter.HOME, component: ScreenPatientPortalHome, exact: true },
				{ component: () => <ScreenError404 redirectTo={PrivatePatientPortalRouter.HOME} /> },
			]}
		/>
	)
}
