import React from 'react'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 */
export function LoadingVxCP(): JSX.Element | null {
	return (
		<LoadingWrapperSCP>
			<img src={ThemeProject.logoLoadingUrl} width={200} />
		</LoadingWrapperSCP>
	)
}

const LoadingWrapperSCP = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	animation: heartbeat 1.5s infinite;
	transform-origin: center;

	@keyframes heartbeat {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.2);
		}
		100% {
			transform: scale(1);
		}
	}
`
