export enum AuditScoreEnum {
	SCORE_10 = 'SCORE_10',
	SCORE_8 = 'SCORE_8',
	SCORE_6 = 'SCORE_6',
	SCORE_4 = 'SCORE_4',
	SCORE_2 = 'SCORE_2',
	SCORE_0 = 'SCORE_0',
}

export enum AuditScoreLabelEnum {
	SCORE_10 = '10',
	SCORE_8 = '8',
	SCORE_6 = '6',
	SCORE_4 = '4',
	SCORE_2 = '2',
	SCORE_0 = '0',
}

export enum AuditScoreDescriptionEnum {
	SCORE_10 = 'Concordância total',
	SCORE_8 = 'Concordância clínica, mas com necessidade de ajustes',
	SCORE_6 = 'Concordância parcial faltando descrição de achados adicionais',
	SCORE_4 = 'Discordância parcial com relevância clínica leve',
	SCORE_2 = 'Discordância parcial com relevância clínica moderada',
	SCORE_0 = 'Discordância total',
}
