import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'
import { SelectExamStatusCP } from 'modules/exams/components/exams/select-exam-status/SelectExamStatusCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { SkeletonCP } from 'submodules/nerit-framework-ui/common/components/skeleton/SkeletonCP'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { SwitchExamCriticalCP } from 'modules/exams/components/exams/switch-exam-critical/SwitchExamCriticalCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { SelectExamSlaCP } from 'modules/exams/components/exams/select-exam-sla/SelectExamSlaCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { ExamAssignedFilterEnum, ExamAssignedFilterLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { SelectClinicCategoryCP } from 'modules/doctor-groups/components/doctor-groups/select-clinic-category/SelectClinicCategoryCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface ICPProps {
	examStatusGroup?: ExamStatusGroupEnum
	isLoading: boolean
	onFilter: () => void
	isScreenInitialized: boolean
	filterFormStateManager: IFormStateManager<ExamFilterFormModel>
	fieldsConfig?: {
		disableStatus?: boolean
		disableDoctorGroup?: boolean
	}
	originalExamFilters?: ExamSearchRequestDTO
}

/**
 * Componente de filtros da tela de exames.
 */
export function FiltersExamsInnerICP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()
	const hasDoctorGroup = !ArrayUtils.isEmpty(AppStateUtils.getLoggedUser()?.doctorGroups)

	// Sera separado dos demais por questoes de performance
	const [patientName, setPatientName] = useState<string>()
	const [searchString, setSearchString] = useState<string>()
	const [reloadSearchField, setReloadSearchField] = useState<number>()

	useEffect(init, [props.isScreenInitialized])

	/**
	 */
	function init(): void {
		setSearchString(props.originalExamFilters?.searchString)
		setPatientName(props.originalExamFilters?.patientName)
		setReloadSearchField(TableUtils.getReloadNumber())
	}

	/**
	 */
	function onFilter(): void {
		props.filterFormStateManager.changeFieldValue('searchString', searchString)
		props.filterFormStateManager.changeFieldValue('patientName', patientName)

		props.onFilter()
	}

	if (!props.isScreenInitialized) {
		return (
			<ContentSCP>
				<SkeletonCP />
			</ContentSCP>
		)
	}

	return (
		<ContentSCP>
			<RowCP>
				<ColumnCP md={5} sm={12} xs={24}>
					<InputCP label={'Nome do Paciente'} onChange={setPatientName} value={patientName} reloadField={reloadSearchField} allowClear={true} />
				</ColumnCP>
				<ColumnCP md={4} sm={12} xs={24}>
					<InputCP
						label={'Exame / Nº Pedido / ID Paciente'}
						onChange={setSearchString}
						value={searchString}
						reloadField={reloadSearchField}
						allowClear={true}
					/>
				</ColumnCP>
				<ColumnCP md={5} sm={12} xs={24}>
					<FlexCP>
						<DateRangePickerCP
							showBorder={true}
							marginTop={23}
							fastFilter={'all'}
							formStateManager={props.filterFormStateManager}
							fieldName={'dateRange'}
						/>
						<SwitchWrapperSCP>
							<SwitchCP
								label={'Data de:'}
								labelAbove={true}
								checkedLabels={{
									checked: 'Laudo',
									unchecked: 'Exame',
								}}
								formStateManager={props.filterFormStateManager}
								fieldName={'shouldConsiderMedicalReportDate'}
							/>
						</SwitchWrapperSCP>
					</FlexCP>
				</ColumnCP>
				<ColumnCP md={4} sm={12} xs={24}>
					<SelectClinicFromLoggedUserCP
						permissions={
							props.filterFormStateManager.getFieldValue('viewAsDoctor') === true
								? ['isDoctor']
								: ['isAdmin', 'isDoctorGroupAdmin', 'isUser']
						}
						value={{
							byOnChange: {
								value: props.filterFormStateManager.getFieldValue('clinicCodes'),
								onChange: (values) => props.filterFormStateManager.changeFieldValue('clinicCodes', values),
								showSelectAll: true,
							},
						}}
						multiple={{
							isMultiple: true,
							maxTagCount: 1,
							clearSearchTextOnSelect: false,
						}}
					/>
				</ColumnCP>
				<ColumnCP md={2} sm={12} xs={24}>
					<SelectExamModalityCP
						label={'Modalidades'}
						isMultiple={true}
						formStateManager={props.filterFormStateManager}
						fieldName={'modalities'}
					/>
				</ColumnCP>
				<ColumnCP md={2} sm={12} xs={24}>
					<SelectExamSpecialtyCP label={'Especialidade'} formStateManager={props.filterFormStateManager} fieldName={'specialty'} />
				</ColumnCP>
				<ColumnCP md={2} sm={12} xs={12}>
					<SelectExamUrgencyTypeCP label={'Prioridade'} formStateManager={props.filterFormStateManager} fieldName={'urgencies'} />
				</ColumnCP>
			</RowCP>

			<FlexCP alignItems={'center'}>
				<FlexOneICP>
					<RowCP>
						<ColumnCP md={hasDoctorGroup ? 12 : 22} sm={24} xs={24}>
							<SelectExamStatusCP
								formStateManager={props.filterFormStateManager}
								fieldName={'status'}
								viewAsDoctor={props.filterFormStateManager.getFieldValue('viewAsDoctor')}
								disabled={props.fieldsConfig?.disableStatus}
							/>
						</ColumnCP>
						<ColumnCP md={2} sm={12} xs={24}>
							<SelectExamSlaCP formStateManager={props.filterFormStateManager} fieldName={'sla'} />
						</ColumnCP>
						{hasDoctorGroup && (
							<ColumnCP md={10} sm={12} xs={24}>
								<FlexCP>
									<SelectDoctorGroupFromLoggedUserCP
										formStateManager={props.filterFormStateManager}
										fieldName={'doctorGroupCode'}
										disable={props.fieldsConfig?.disableDoctorGroup}
									/>
									{!!props.filterFormStateManager.getFieldValue('doctorGroupCode') &&
										DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(
											props.filterFormStateManager.getFieldValue('doctorGroupCode'),
											['isDoctorGroupAdmin'],
										) && (
											<>
												<BasicStyleWrapperCP margin={{ left: 10, right: 10 }}>
													<SelectClinicCategoryCP
														doctorGroupCode={props.filterFormStateManager.getFieldValue('doctorGroupCode')}
														formStateManager={props.filterFormStateManager}
														categoryFieldName={'clinicCategoryCode'}
														width={130}
													/>
												</BasicStyleWrapperCP>
											</>
										)}
								</FlexCP>
							</ColumnCP>
						)}
					</RowCP>
				</FlexOneICP>

				<FlexCP alignItems={'center'}>
					{hasDoctorGroup && (
						<RadioGroupWrapperSCP>
							<RadioGroupCP<ExamAssignedFilterEnum>
								fontSize={'small'}
								label={'Atribuído'}
								type={'button'}
								options={[
									{
										value: ExamAssignedFilterEnum.ALL,
										content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.ALL],
									},
									{
										value: ExamAssignedFilterEnum.YES,
										content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.YES],
									},
									{
										value: ExamAssignedFilterEnum.NO,
										content: ExamAssignedFilterLabelEnum[ExamAssignedFilterEnum.NO],
										hide: props.filterFormStateManager.getFieldValue('viewAsDoctor') === true,
									},
								]}
								formStateManager={props.filterFormStateManager}
								fieldName={'assigned'}
								margin={{ left: 5, right: 5 }}
								paddingTop={0}
								buttonWidth={50}
							/>
						</RadioGroupWrapperSCP>
					)}

					{props.filterFormStateManager.getFieldValue('viewAsDoctor') === false &&
						props.filterFormStateManager.getFieldValue('assigned') === ExamAssignedFilterEnum.YES && (
							<SelectDoctorGroupPeopleCP
								width={200}
								role={UserTypeEnum.DOCTOR}
								formStateManager={props.filterFormStateManager}
								formField={'doctorCodes'}
								isMultiple={true}
								doctorGroupCode={props.filterFormStateManager.getFieldValue('doctorGroupCode')}
								filters={{
									status: [UserDoctorGroupStatusEnum.ACTIVE],
								}}
							/>
						)}

					<SwitchWrapperSCP>
						<SwitchExamCriticalCP
							label={'Crítico'}
							labelAbove={true}
							formStateManager={props.filterFormStateManager}
							fieldName={'isCritical'}
						/>
					</SwitchWrapperSCP>
				</FlexCP>

				{!screenSize.smd && (
					<ButtonCP marginLeft={10} marginTop={22} icon={'search'} type={'primary'} onClick={onFilter} loading={props.isLoading}>
						Filtrar
					</ButtonCP>
				)}
			</FlexCP>

			{screenSize.smd && (
				<ButtonSmallScreeSCP>
					<ButtonCP icon={'search'} type={'primary'} onClick={onFilter} loading={props.isLoading} size={'large'}>
						Filtrar
					</ButtonCP>
				</ButtonSmallScreeSCP>
			)}
		</ContentSCP>
	)
}

const ContentSCP = styled.div`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
`

const ButtonSmallScreeSCP = styled.div`
	margin-top: 20px;

	> div {
		width: 100%;

		button {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
`

const SwitchWrapperSCP = styled.div`
	margin-top: 10px;
	margin-left: 5px;
	.ant-typography {
		line-height: 20px;
	}
`

const RadioGroupWrapperSCP = styled.div`
	margin-top: 5px;
	.ant-radio-button-wrapper {
		line-height: 25px;
	}
`
