import { TableClinicsDoctorGroupCP } from 'modules/doctor-groups/components/clinic-doctor-groups/table-clinics-doctor-group/TableClinicsDoctorGroupCP'
import React from 'react'

interface ICPProps {
	doctorGroupCode: number
}

export function ScreenContentDoctorGroupClinics(props: ICPProps): JSX.Element {
	return <TableClinicsDoctorGroupCP doctorGroupCode={props.doctorGroupCode} />
}
