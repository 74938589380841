import React, { useState } from 'react'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalSetPasswordCP } from 'submodules/nerit-framework-ui/features/user/components/button-set-password/set-password-modal/ModalSetPasswordCP'

interface ICPProps {
	requestConfig: () => RequestConfigTP
	hasPermission: boolean
}

/**
 */
export function ButtonSetPasswordCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	if (!props.hasPermission) return <></>

	return (
		<>
			<ButtonCP icon={'key'} size={'small'} onClick={() => setIsModalVisible(true)} tooltip={'Resetar senha do usuário'} />
			<ModalSetPasswordCP visible={isModalVisible} onClose={() => setIsModalVisible(false)} requestConfig={props.requestConfig} />
		</>
	)
}
