import React, { useEffect, useState } from 'react'
import { AttachmentViewerCP } from 'modules/exams/components/exams/attachment-viewer/AttachmentViewerCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ExamImageDataResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/inner/ExamImageDataResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { ExamAttachmentSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/request/ExamAttachmentSearchRequestDTO'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'

interface ICPProps {
	exam: {
		id: string
		imageData?: ExamImageDataResponseDTO
	}
	appearance?: {
		iconOnly?: boolean
		size?: ButtonSizeTP
		type?: ButtonTypeTP
	}
}

/**
 * Botao visualizar exames não-dicom.
 */
export function ButtonDownloadNonDicomExamCP(props: ICPProps): JSX.Element {
	const [shouldShowView, setShouldShowViewer] = useState<boolean>(false)

	const [loadedExamImages, setLoadedExamImages] = useState<FileDataTP[]>()
	const getExamImagesRequest = useRequest<ListResponseDTO<FileDataTP>>()
	useEffect(onListRequestChange, [getExamImagesRequest.isAwaiting])

	/**
	 * Carrega as imagens do exame
	 */
	function loadExamImages(): void {
		if (!!loadedExamImages) {
			setShouldShowViewer(true)
			return
		}

		const dto: ExamAttachmentSearchRequestDTO = {
			isExam: true,
		}
		getExamImagesRequest.runRequest(ExamAttachmentsRequests.searchAttachments(props.exam.id, dto))
	}

	/**
	 * Retorno da API
	 */
	function onListRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamImagesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setShouldShowViewer(true)
		setLoadedExamImages(getExamImagesRequest.responseData?.list)
	}

	if (props.exam.imageData?.isDicom) return <></>

	return (
		<>
			<ButtonCP
				size={props.appearance?.size}
				type={props.appearance?.type}
				onClick={loadExamImages}
				loading={getExamImagesRequest.isAwaiting}
				icon={'file-image'}
			>
				{props.appearance?.iconOnly ? undefined : 'Imagens do Exame'}
			</ButtonCP>

			{shouldShowView && !!loadedExamImages && <AttachmentViewerCP attachments={loadedExamImages} onClose={() => setShouldShowViewer(false)} />}
		</>
	)
}
