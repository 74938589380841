import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerExamPendingRectificationAnalysisCP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/DrawerExamPendingRectificationAnalysisCP'

interface ICPProps {
	examCode: number
	examId: string
	onSave: () => void
}

/**
 * Botao para triar exame.
 */
export function ButtonAnalysePendingRectificationExamCP(props: ICPProps): JSX.Element {
	const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP type={'primary'} size={'small'} onClick={() => setIsDrawerVisible(true)} icon={'file-search'} marginRight={5}>
				Pedido Retificação
			</ButtonCP>

			<DrawerExamPendingRectificationAnalysisCP
				examCode={props.examCode}
				examId={props.examId}
				visible={isDrawerVisible}
				onCancel={() => setIsDrawerVisible(false)}
				onSave={() => {
					setIsDrawerVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
