import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { ListMedicalReportCompliance } from 'modules/exams/components/medical-report/list-medical-report-compliance/ListMedicalReportCompliance'
import { ButtonsFinishMedicalReportCP } from 'modules/exams/components/medical-report/buttons-finish-medical-report/ButtonsFinishMedicalReportCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import styled from 'styled-components'
import { TabsPreviewMedicalReportsCP } from 'modules/exams/components/medical-report/tabs-preview-medical-reports/TabsPreviewMedicalReportsCP'
import { FinalizeMedicalReportFormFieldsICP } from 'modules/exams/components/medical-report/modal-finalize-medical-report/inner/FinalizeMedicalReportFormFieldsICP'
import { ModalExamsSuggestionsCP } from 'modules/exams/components/exams/modal-exams-suggestions/ModalExamsSuggestionsCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	visible: boolean
	onCancel: () => void
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ModalFinalizeMedicalReportCP(props: ICPProps): JSX.Element {
	const [visible, setVisible] = useState<boolean>(props.visible)
	useEffect(() => setVisible(props.visible), [props.visible])

	const [finalizedMedicalReportModalVisible, setFinalizedMedicalReportModalVisible] = useState<boolean>(false)

	return (
		<>
			<ModalCP title={'Finalizar Laudo'} visible={visible} onCancel={props.onCancel} noFooter={true} width={1100} top={10}>
				<WrapperSCP>
					<RowCP>
						<ColumnCP size={14}>
							<TabsPreviewMedicalReportsCP
								examCode={props.exam.code}
								medicalReportCotents={props.formStateManager.getFieldValue('medicalReports')}
							/>
						</ColumnCP>

						<ColumnCP size={10}>
							<HelpCP text={'Confirme todas as informações antes de finalizar o laudo'} type={'text'} marginBottom={10} />

							<ListMedicalReportCompliance exam={props.exam} formStateManager={props.formStateManager} />

							<FinalizeMedicalReportFormFieldsICP exam={props.exam} formStateManager={props.formStateManager} />

							<FlexCP margin={{ top: 40 }} justifyContent={'center'}>
								<ButtonsFinishMedicalReportCP
									exam={props.exam}
									formStateManager={props.formStateManager}
									onSave={() => {
										setVisible(false)
										setFinalizedMedicalReportModalVisible(true)
									}}
								/>
							</FlexCP>
							<HelpCP
								text={'Preview! Verifique se o resultado do laudo ao lado está com uma visualização agradável'}
								type={'text'}
								marginTop={10}
							/>
						</ColumnCP>
					</RowCP>
				</WrapperSCP>
			</ModalCP>

			<ModalExamsSuggestionsCP visible={finalizedMedicalReportModalVisible} exam={props.exam} searchExamDto={props.searchExamDto} />
		</>
	)
}

const WrapperSCP = styled.div`
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	margin-top: -10px;
`
