/**
 * CONFIG
 * Encapsula definicao de parametros de configuracao para plugin
 * customizado de tabelas para o editor wysiwyg / draft JS customizado.
 *
 * @author hjcostabr
 */
export class TablePluginConfig {
	static readonly TABLE_ENTITY_TYPE = 'table-type'

	static readonly TABLE_MIN_WIDTH = 600
	static readonly TABLE_MAX_WIDTH = 1000

	static readonly MAX_COLUMNS = 10

	private constructor() {
		/** Construtor privado impede instanciacao. */
	}
}
