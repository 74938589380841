import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { NoteSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/request/NoteSaveRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { ExamAttachmentSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/request/ExamAttachmentSearchRequestDTO'
import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { MedicalReportVideoFileResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/MedicalReportVideoFileResponseDTO'

export class ExamAttachmentsRequests {
	public static CONTROLLER_ROOT = 'exam-attachments'
	public static SEARCH_ATTACHMENTS_EP = 'search-attachments/:examId'
	public static SEARCH_NOTES_EP = 'search-notes/:examCode'
	public static SEARCH_MEDICAL_REPORT_VIDEO_EP = 'search-medical-report-video/:examCode'
	public static UPLOAD_ATTACHMENT_EP = 'upload-attachment/:examId'
	public static DELETE_ATTACHMENT_EP = 'delete-attachment/:code'
	public static CREATE_NOTE_EP = 'create-note'
	public static DELETE_NOTE_EP = 'delete-note/:code'
	public static UPLOAD_MEDICAL_REPORT_VIDEO_EP = 'upload-video/:examId'
	public static DELETE_MEDICAL_REPORT_VIDEO_EP = 'delete-uploaded-video/:code'

	static searchAttachments = (examId: string, dto?: ExamAttachmentSearchRequestDTO): RequestConfigTP<FileDataTP> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.SEARCH_ATTACHMENTS_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static searchNotes = (examCode: number): RequestConfigTP<FileDataTP> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.SEARCH_NOTES_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static uploadAttachment = (examId: string, formData: any): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.UPLOAD_ATTACHMENT_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.POST,
		params: formData,
	})

	static deleteAttachment = (code: number): RequestConfigTP<FileDataTP> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.DELETE_ATTACHMENT_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static searchMedicalReportVideo = (examCode: number): RequestConfigTP<MedicalReportVideoFileResponseDTO[]> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.SEARCH_MEDICAL_REPORT_VIDEO_EP.replace(
			':examCode',
			examCode.toString(),
		)}`,
		method: HttpMethodEnum.GET,
	})

	static uploadMedicalReportVideo = (examId: string, formData: any): RequestConfigTP<UploadMedicalReportVideoResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.UPLOAD_MEDICAL_REPORT_VIDEO_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.PUT,
		avoidParamTransformer: true,
		params: formData,
	})

	static deleteMedicalReportVideo = (code: number): RequestConfigTP<FileDataTP> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.DELETE_MEDICAL_REPORT_VIDEO_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static deleteNote = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.DELETE_NOTE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static createNote = (dto: NoteSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamAttachmentsRequests.CONTROLLER_ROOT}/${ExamAttachmentsRequests.CREATE_NOTE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})
}
