export enum ClinicDoctorGroupStatusEnum {
	AWAITING_DOCTOR_GROUP = 'AWAITING_DOCTOR_GROUP',
	AWAITING_CLINIC = 'AWAITING_CLINIC',
	REFUSED = 'REFUSED',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum ClinicDoctorGroupStatusLabelEnum {
	AWAITING_DOCTOR_GROUP = 'Aguardando Grupo de Médico',
	AWAITING_CLINIC = 'Aguardando Unidade Hospitalar',
	REFUSED = 'Recusado',
	ACTIVE = 'Ativo',
	INACTIVE = 'Inativo',
}
