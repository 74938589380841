import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import styled from 'styled-components'
import { AlertPatientExamsForMedicalReportCP } from 'modules/exams/components/medical-report/alert-patient-exams-for-medical-report/AlertPatientExamsForMedicalReportCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamResponseDTO
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function AlertsMedicalReportCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<AlertPatientExamsForMedicalReportCP exam={props.exam} searchExamDto={props.searchExamDto} />
			{props.exam.status === ExamStatusEnum.SIGNED && (
				<AlertCP message={'O laudo deste exame já foi realizado, favor proceder com a Revisão.'} type={'warning'} />
			)}
			{props.exam.status === ExamStatusEnum.RECTIFICATION && (
				<AlertCP message={'O laudo deste exame já foi realizado, favor proceder com a Retificação.'} type={'error'} />
			)}
			{!StringUtils.isEmpty(props.exam.clinic.doctorMessage) && <AlertCP message={props.exam.clinic.doctorMessage} type={'error'} />}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	.ant-alert {
		margin-bottom: 10px;
	}
`
