import { useLocation, useHistory } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

type QueryParams<T = Record<string, unknown>> = Partial<T>

type QueryParamValue<T extends Record<string, unknown>> = T[keyof T]

type UseQueryParamsHook<T extends Record<string, unknown>> = [QueryParams<T>, (value: QueryParams<Partial<T>>) => void]

export function useQueryParams<T extends Record<string, unknown>>(): UseQueryParamsHook<T> {
	const location = useLocation()
	const history = useHistory()

	const getQueryParams = useCallback((): QueryParams<T> => {
		const searchParams = new URLSearchParams(location.search)

		const params = {} as QueryParams<T>

		searchParams.forEach((value, key) => {
			params[key as keyof T] = decodeURI(value) as QueryParamValue<T>
		})

		return params
	}, [location.search])

	const setQueryParam = useCallback(
		(queryParam: QueryParams<T> | undefined) => {
			const searchParams = new URLSearchParams(location.search)

			if (!queryParam) {
				history.replace({ search: '' })
				return
			}

			Object.entries(queryParam).forEach(([key, value]) => {
				if (value === null || value === undefined) {
					searchParams.delete(key)
				} else {
					searchParams.set(key, encodeURI(typeof value === 'object' ? JSON.stringify(value) : value))
				}
			})

			history.replace({ search: searchParams.toString() })
		},
		[location.search, history],
	)

	const queryParams = useMemo(() => getQueryParams(), [getQueryParams])

	return [queryParams, setQueryParam]
}
