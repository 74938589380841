export enum ExamHistoryEnum {
	CREATED_EXAM = 'CREATED_EXAM',
	PENDING_EXAM = 'PENDING_EXAM',
	UPDATE_URGENCY = 'UPDATE_URGENCY',
	ALLOCATED_DOCTOR = 'ALLOCATED_DOCTOR',
	FINISHED_REPORT = 'FINISHED_REPORT', // Se laudos foram assinados
	SIGN_REPORT = 'SIGN_REPORT', // Um médico assinou um exame.
	VIEWED_REPORT = 'VIEWED_REPORT',
	DOWNLOADED_EXAM = 'DOWNLOADED_EXAM',
	DUPLICATED_EXAM = 'DUPLICATED_EXAM',
	STARTED_REPORT = 'STARTED_REPORT',

	SEND_REPORTS_TO_INTEGRATION = 'SEND_REPORTS_TO_INTEGRATION', // Quando iniciamos o pedido de um laudo para integracao com parceiro.
	SEND_REPORTS_TO_INTEGRATION_QUIT = 'SEND_REPORTS_TO_INTEGRATION_QUIT', // Durante o tempo de seguranca(1,5 min) se o exame voltar para status diferente do grupo DONE nao podemos mais integrar.
	SEND_REPORTS_TO_INTEGRATION_ERROR = 'SEND_REPORTS_TO_INTEGRATION_ERROR', // Erro na integracao com o parceiro.
	SEND_REPORTS_TO_INTEGRATION_SUCCESSFULL = 'SEND_REPORTS_TO_INTEGRATION_SUCCESSFULL', // Sucesso na integracao com o parceiro.

	SEND_TO_ADMIN_ANALYSIS = 'SEND_TO_ADMIN_ANALYSIS', //Medico envia o exame para o grupo reclamando de algo do exame.
	SEND_ADMIN_ANALYSIS_TO_DOCTOR_DUE_TO_DISAGREEMENT = 'SEND_ADMIN_ANALYSIS_TO_DOCTOR_DUE_TO_DISAGREEMENT', // Analise NAO concordou com o medico e envia para algum medico.
	SEND_ADMIN_ANALYSIS_TO_CLINIC = 'SEND_ADMIN_ANALYSIS_TO_CLINIC', // Analise concordou com o médico, enviamos para a clínica.

	SEND_TO_RECTIFICATION_ANALYSIS = 'SEND_TO_RECTIFICATION_ANALYSIS', // Alguem envia o exame para um pedido de retificacao.
	SEND_RECTIFICATION_TO_CLINIC_DUE_TO_DISAGREEMENT = 'SEND_RECTIFICATION_TO_CLINIC_DUE_TO_DISAGREEMENT', // Pessoal Administrativo do grupo nao concorda com retificacao e devolve pra clinica.
	SEND_RECTIFICATION_TO_DOCTOR = 'SEND_RECTIFICATION_TO_DOCTOR', // Pessoal Administrativo do grupo CONCORDA com retificacao e envia para um medico retificar.

	REPORT_DIGITALLY_SIGNED = 'REPORT_DIGITALLY_SIGNED', // Laudos do exame foram assinados digitalmente
	RETURNED_EXAM = 'RETURNED_EXAM', // O exame voltou para clinica depois de uma analise administrativa.
	EDITED_EXAM = 'EDITED_EXAM',
	ADDED_NOTE = 'ADDED_NOTE',
	REMOVED_NOTE = 'REMOVED_NOTE',
	ADDED_ATTACHMENT = 'ADDED_ATTACHMENT',
	REMOVED_ATTACHMENT = 'REMOVED_ATTACHMENT',
	ARCHIVED = 'ARCHIVED',
	UNARCHIVE = 'UNARCHIVE',

	DIGITAL_CERTIFICATE_REQUIREMENT_REMOVED = 'DIGITAL_CERTIFICATE_REQUIREMENT_REMOVED',
	DIGITAL_CERTIFICATE_REQUIREMENT_ADDED = 'DIGITAL_CERTIFICATE_REQUIREMENT_ADDED',

	//DEPRECADO
	REPORT_SENT_BY_WHATSAPP = 'REPORT_SENT_BY_WHATSAPP',

	BYPASS_DIGITAL_SIGN = 'BYPASS_DIGITAL_SIGN',
}

export enum ExamHistoryLabelEnum {
	CREATED_EXAM = 'Criou Exame',
	PENDING_EXAM = 'Exame Pendente',
	UPDATE_URGENCY = 'Atualizou Prioridade',
	ALLOCATED_DOCTOR = 'Alocou Médico',
	FINISHED_REPORT = 'Finalizou Laudo', // Se laudos foram assinados
	SIGN_REPORT = 'Assinou Laudo', // Um médico assinou um exame.
	VIEWED_REPORT = 'Visualizou Laudo',
	DOWNLOADED_EXAM = 'Download de Exame',
	DUPLICATED_EXAM = 'Duplicou Exame',

	SEND_REPORTS_TO_INTEGRATION = 'Enviou laudo para integração',
	SEND_REPORTS_TO_INTEGRATION_QUIT = 'Cancelou envio de laudo para integração',
	SEND_REPORTS_TO_INTEGRATION_ERROR = 'Erro ao enviar laudo para integração',
	SEND_REPORTS_TO_INTEGRATION_SUCCESSFULL = 'Laudo integrado com sucesso',

	SEND_TO_ADMIN_ANALYSIS = 'Devolvido para Análise Administrativa',
	SEND_ADMIN_ANALYSIS_TO_DOCTOR_DUE_TO_DISAGREEMENT = 'Devolvido para Médico',
	SEND_ADMIN_ANALYSIS_TO_CLINIC = 'Devolvido para Clínica',

	SEND_TO_RECTIFICATION_ANALYSIS = 'Laudo Enviado para Análise de Retificação',
	SEND_RECTIFICATION_TO_CLINIC_DUE_TO_DISAGREEMENT = 'Laudo Devolvido para Clínica',
	SEND_RECTIFICATION_TO_DOCTOR = 'Laudo Enviado para ser Retificado',

	REPORT_DIGITALLY_SIGNED = 'Assinatura Digital Realizada',
	RETURNED_EXAM = 'Exame Devolvido',
	EDITED_EXAM = 'Exame Editado',
	ADDED_NOTE = 'Nota Adicionada',
	REMOVED_NOTE = 'Nota Removida',
	ADDED_ATTACHMENT = 'Anexo Adicionado',
	REMOVED_ATTACHMENT = 'Anexo Removido',
	ARCHIVED = 'Exame Arquivado',
	UNARCHIVE = 'Exame Desarquivado',

	//DEPRECADO
	REPORT_SENT_BY_WHATSAPP = 'REPORT_SENT_BY_WHATSAPP',

	BYPASS_DIGITAL_SIGN = 'Exame Passou Sem Certificado Digital',
}
