import React, { useEffect, useState } from 'react'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonGroupUrgencyTypeCP } from 'modules/exams/components/exams/button-group-urgency-type/ButtonGroupUrgencyTypeCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import styled from 'styled-components'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ExamsSetUrgencyRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamsSetUrgencyRequestDTO'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

interface ICPProps {
	exam: {
		code: number
		urgencyType: ExamUrgencyTypeEnum
		status: ExamStatusEnum
		clinicCode: number
		doctorGroupCode?: number
	}
	onChangeUrgency?: () => void
	appearance?: {
		size?: ButtonSizeTP
		showBorder?: boolean
	}
}
/**
 * Elemento clicavel para sinalizar / alterar status de urgencia de 01 exame.
 */
export function ButtonPopoverExamUrgencyCP(props: ICPProps): JSX.Element {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>()

	const setUrgencyRequest = useRequest<void>('none')
	useEffect(onSetUrgencyRequestChange, [setUrgencyRequest.isAwaiting])

	/**
	 * Muda a urgencia
	 */
	function saveUrgency(urgencyType: ExamUrgencyTypeEnum): void {
		const dto: ExamsSetUrgencyRequestDTO = {
			examCodes: [props.exam.code],
			urgencyType,
		}
		setUrgencyRequest.runRequest(ExamsRequests.setUrgency(dto))
	}

	/**
	 * Ao definir urgency
	 */
	function onSetUrgencyRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				setUrgencyRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		setIsPopoverVisible(false)
		if (!!props.onChangeUrgency) props.onChangeUrgency()
	}

	if (!props.exam.urgencyType) return <></>

	return (
		<PopOverCP
			trigger={'click'}
			visible={isPopoverVisible}
			title={'Selecione o tipo de urgência'}
			onClose={() => setIsPopoverVisible(false)}
			placement={'topLeft'}
			content={
				<ButtonGroupUrgencyTypeCP
					type={'clinic'}
					onSelect={saveUrgency}
					loading={setUrgencyRequest.isAwaiting}
					value={props.exam.urgencyType}
					clinicCode={props.exam.clinicCode}
					doctorGroupCode={props.exam.doctorGroupCode}
				/>
			}
		>
			<WrapperSCP showBorder={props.appearance?.showBorder}>
				<ButtonCP
					size={props.appearance?.size}
					onClick={() => setIsPopoverVisible(true)}
					type={'ghost'}
					icon={ThemeProjectCommon.icons[props.exam.urgencyType]}
					loading={setUrgencyRequest.isAwaiting}
					disabled={![ExamStatusEnum.AWAITING].includes(props.exam.status)}
				/>
			</WrapperSCP>
		</PopOverCP>
	)
}

const WrapperSCP = styled.div<{ showBorder?: boolean }>`
	button {
		border: ${(props) => (props.showBorder ? undefined : 'none')};
	}
`
