import React from 'react'
import styled from 'styled-components'

interface ISCPProps {
	disabled?: boolean
	color: string
	isSelected: boolean
	onClick: () => void
}

interface IButtonScoreCPProps extends ISCPProps {
	scoreLabel: number
}

/**
 * Botao de Nota para uma pesquisa.
 */
export function ButtonScoreCP(props: IButtonScoreCPProps): JSX.Element {
	return (
		<ScoreButtonSCP color={props.color} isSelected={props.isSelected} onClick={props.onClick} disabled={props.disabled}>
			{props.scoreLabel}
		</ScoreButtonSCP>
	)
}

const ScoreButtonSCP = styled.div<ISCPProps>`
	padding: 10px 0;
	border-radius: 5px;
	border: solid 1px ${(props) => (props.isSelected ? props.color : '#FCDCE6')};
	background: ${(props) => (props.isSelected ? props.color : '#FCDCE6')};
	margin: 0 5px;
	cursor: ${(props) => (props.disabled ? undefined : 'pointer')};
	text-align: center;
	flex-grow: 1;

	&:hover {
		background: ${(props) => props.color};
		border: solid 1px ${(props) => props.color};
	}
`
