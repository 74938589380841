import React from 'react'
import { EditorState } from 'draft-js'

import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'

interface ITableButtonICPProps {
	showAddTableModal: () => void
	editorState: EditorState
	onEditorStateChange: (editorState: EditorState) => void
	innerContent: JSX.Element
}

/**
 * COMPONENTE
 * Botao de insercao de tabela para o editor wysiwyg /
 * Draft JS customizado.
 *
 * @author hjcostabr
 */
export const TableButtonICP = (props: ITableButtonICPProps): JSX.Element => {
	return (
		<ToolbarButtonICP
			editorState={props.editorState}
			onEditorStateChange={props.onEditorStateChange}
			innerContent={props.innerContent}
			onClick={() => props.showAddTableModal()}
		/>
	)
}
