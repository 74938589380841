import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { ExamDescriptionGroupResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/exam-group/dtos/responses/ExamGroupResponseDTO'
import { ExamDescriptionGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exam-group/dtos/requests/ExamDescriptionGroupSaveRequestDTO'
import { ExamDescriptionGroupSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exam-group/dtos/responses/ExamDescriptionGroupSearchRequestDTO'

export class ExamDescriptionGroupRequests {
	public static CONTROLLER_ROOT = 'exam-groups'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static DELETE_EP = 'delete/:code'
	public static SEARCH_EP = 'search'

	static create = (dto: ExamDescriptionGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamDescriptionGroupRequests.CONTROLLER_ROOT}/${ExamDescriptionGroupRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: ExamDescriptionGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamDescriptionGroupRequests.CONTROLLER_ROOT}/${ExamDescriptionGroupRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamDescriptionGroupRequests.CONTROLLER_ROOT}/${ExamDescriptionGroupRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static search = (dto: ExamDescriptionGroupSearchRequestDTO): RequestConfigTP<ExamDescriptionGroupResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamDescriptionGroupRequests.CONTROLLER_ROOT}/${ExamDescriptionGroupRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
