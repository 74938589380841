import React from 'react'
import { EditorState } from 'draft-js'
import styled from 'styled-components'

import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'
import { TextEditorConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorConfig'

interface ITitleToggleButtonICPProps {
	editorState: EditorState
	onEditorStateChange: (editorState: EditorState) => void
}

/**
 * COMPONENTE
 * Botao para ativacao/desativacao de estilo 'de titulo' do editor
 * wysiwyg / Draft JS customizado.
 *
 * @author hjcostabr
 */
export const TitleToggleButtonICP = (props: ITitleToggleButtonICPProps): JSX.Element | null => {
	function isTitleStyleActive(editorState?: EditorState): boolean {
		return !!editorState ? TextEditorUtils.getSelectionFontSize(editorState) === TextEditorConfig.FONT_SIZE_LARGE : false
	}

	function toggleIsTitle(editorState: EditorState): EditorState {
		const newFontSize = isTitleStyleActive(editorState) ? TextEditorConfig.FONT_SIZE_SMALL : TextEditorConfig.FONT_SIZE_LARGE

		return TextEditorUtils.getStateWithSetFontSize(editorState, newFontSize)
	}

	return (
		<ToolbarButtonICP
			editorState={props.editorState}
			onEditorStateChange={props.onEditorStateChange}
			innerContent={<IconWrapperSCP>T</IconWrapperSCP>}
			onClick={toggleIsTitle}
			isActive={isTitleStyleActive}
		/>
	)
}

const IconWrapperSCP = styled.span`
	font-size: 25px;
	line-height: 0;
`
