import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'

export class PendingRectificationAnalysisExamFormModel extends FormModel {
	@IsOptional()
	doctorCode: number

	@IsRequired()
	comments: string

	constructor(initialData?: any) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
