import { useQueryParams } from 'submodules/nerit-framework-ui/common/hooks/useQueryParam'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ForgotPasswordStepsTP } from 'modules/auth/screens/screen-recover-password/inner/ForgotPasswordStepsCP'
import { ResetPasswordQueryParamsTP } from 'modules/auth/screens/screen-recover-password/ScreenForgotPassword'
import React, { useEffect } from 'react'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ValidationCodeInputCP } from 'submodules/nerit-framework-ui/common/components/validation-code-input/ValidationCodeInputCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { THEME_LOCAL_STORAGE_KEY, ThemeTP } from 'submodules/nerit-framework-ui/features/common/components/button-switch-theme/inner/ThemeTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
	onPasswordResetStepChange: (step: ForgotPasswordStepsTP) => void
	onPasswordTokenChange: (values: string) => void
	passwordToken: string
}

export function ValidateVerificationCodeCP(props: ICPProps): JSX.Element {
	const [queryParams, setQueryParams] = useQueryParams<ResetPasswordQueryParamsTP>()

	const currentTheme = (localStorage.getItem(THEME_LOCAL_STORAGE_KEY) as ThemeTP) ?? 'light'

	const resendVerificationCodeEmailRequest = useRequest('none')
	const validateVerificationCodeRequest = useRequest('none')

	const shouldBlockVerifyButton = props.passwordToken.length !== 6 || validateVerificationCodeRequest.isAwaiting

	useEffect(onResendVerificationCodeEmailRequestChange, [resendVerificationCodeEmailRequest.isAwaiting])

	useEffect(onValidateVerificationCodeRequestChange, [validateVerificationCodeRequest.isAwaiting])

	function onResendVerificationCodeEmailRequestChange(): void {
		RequestUtils.isValidRequestReturn(
			resendVerificationCodeEmailRequest,
			resendVerificationCodeEmailRequest.error?.data?.message,
			'Código de verificação reenviado com sucesso!',
		)

		if (RequestUtils.isRequestError(resendVerificationCodeEmailRequest)) {
			handleGoBack()
		}
	}

	function onValidateVerificationCodeRequestChange(): void {
		if (RequestUtils.isRequestError(validateVerificationCodeRequest)) {
			props.onPasswordTokenChange('')

			if (validateVerificationCodeRequest.error.data.status === 400) {
				NotificationHelper.error('Erro ao validar código de verificação.', 'Código de verificação inválido ou expirado! Tente novamente.')
				handleGoBack()
			}

			if (validateVerificationCodeRequest.error.data.status === 404) {
				NotificationHelper.error('Erro ao validar código de verificação.', 'Código de verificação não encontrado! Tente novamente.')
			}
			return
		}

		if (
			!RequestUtils.isValidRequestReturn(
				validateVerificationCodeRequest,
				validateVerificationCodeRequest.error?.data?.message,
				'Código de verificação válido, você pode alterar sua senha agora!',
			)
		) {
			return
		}

		setQueryParams({
			...queryParams,
			step: ForgotPasswordStepsTP.RECOVER_PASSWORD,
		})
		props.onPasswordResetStepChange(ForgotPasswordStepsTP.RECOVER_PASSWORD)
	}

	function handleResendVerificationCodeEmail(): void {
		resendVerificationCodeEmailRequest.runRequest(AuthRequests.resendVerificationCodeEmail({ email: queryParams.email! }))
	}

	function handleVerificationCodeValidate(token?: string): void {
		validateVerificationCodeRequest.runRequest(
			AuthRequests.validateVerificationCode({
				email: queryParams.email!,
				passwordToken: token ?? props.passwordToken,
			}),
		)
	}

	function handleGoBack(): void {
		props.onPasswordResetStepChange(ForgotPasswordStepsTP.EMAIL_INPUT)
		setQueryParams({
			step: ForgotPasswordStepsTP.EMAIL_INPUT,
			email: undefined,
		})
	}

	return (
		<FlexCP flexDirection={'column'} justifyContent={'center'}>
			<TitleCP underLine center marginBottom={24}>
				Código de verificação
			</TitleCP>

			<TextCP
				color={ThemeProject.gray500}
				marginBottom={24}
				text={'Por favor, insira o código de verificação que enviamos para você em seu e-mail'}
			/>

			<FlexCP alignItems={'center'} justifyContent={'center'}>
				<ValidationCodeInputCP
					onChange={(value) => props.onPasswordTokenChange(value)}
					value={props.passwordToken}
					validChars={'0-9'}
					inputProps={{ inputMode: 'numeric', autoComplete: 'one-time-code' }}
					placeholder={'-'}
					autoFocus
					onComplete={(token) => handleVerificationCodeValidate(token)}
				/>
			</FlexCP>

			<FlexCP alignItems={'center'} justifyContent={'space-between'}>
				<ButtonCP type={'default'} marginTop={24} onClick={handleGoBack}>
					Voltar
				</ButtonCP>

				<ButtonCP
					icon={'lock'}
					type={shouldBlockVerifyButton ? 'ghost' : 'primary'}
					marginTop={24}
					disabled={shouldBlockVerifyButton}
					loading={validateVerificationCodeRequest.isAwaiting}
					onClick={() => handleVerificationCodeValidate()}
				>
					Verificar
				</ButtonCP>
			</FlexCP>

			<FlexCP alignItems={'center'} justifyContent={'center'} margin={{ top: 24 }}>
				Não recebeu o código?
				<ButtonCP
					horizontalPadding={8}
					type={'link'}
					color={currentTheme === 'dark' ? ThemeProject.primary200 : ThemeProject.primary}
					onClick={handleResendVerificationCodeEmail}
					loading={resendVerificationCodeEmailRequest.isAwaiting}
				>
					Solicitar novamente
				</ButtonCP>
			</FlexCP>
		</FlexCP>
	)
}
