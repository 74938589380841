import { NavCollorType } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/inner/NavCollorType'
import { PDFPageIndicatorICP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/inner/PDFPageIndicatorICP'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface IPDFViewerICPProps {
	pdfUrl: string
	navColor?: string
}

/**
 * PDF viewer para o visualizador de arquivos
 */
export function PDFViewerICP(props: IPDFViewerICPProps): JSX.Element {
	const [totalPages, setTotalPages] = useState<number>(0)
	const [page, setPage] = useState<number>(1)
	const [showPageIndicator, setShowPageIndicator] = useState<boolean>(false)

	return (
		<ViewerWrapperSCP>
			<DocumentSCP
				file={props.pdfUrl}
				onLoadSuccess={(pdf: pdfjs.PDFDocumentProxy) => {
					setTotalPages(pdf.numPages)
					setShowPageIndicator(true)
				}}
				loading={
					<LoadingSCP navCollor={props.navColor}>
						<LoadingIconCP style={{ margin: 5 }} />
						Carregando arquivo...
					</LoadingSCP>
				}
			>
				<Page pageNumber={page} />
			</DocumentSCP>
			{showPageIndicator && <PDFPageIndicatorICP page={page} total={totalPages} onChangePage={setPage} />}
		</ViewerWrapperSCP>
	)
}

const ViewerWrapperSCP = styled.div`
	background: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const LoadingSCP = styled.span<NavCollorType>`
	color: ${(props) => props.navCollor ?? `${ThemeFrameworkCommon.browserDefaultColorLight}`};
`
const DocumentSCP = styled(Document)`
	canvas {
		height: 80vh !important;
		width: auto !important;
	}
`
