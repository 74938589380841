import { UserMainMenuCP } from 'app/components/user-main-menu/UserMainMenuCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import React, { useEffect, useState } from 'react'
import { IntercomCP } from 'app/components/intercom/IntercomCP'
import { LayoutUserMainCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-user-main/LayoutUserMainCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ModalVxPromoteCP } from 'app/components/modal-vx-promote/ModalVxPromoteCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { TopBarHomEnvironmentCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-hom-environment/TopBarHomEnvironmentCP'
import { TopBarPasswordExpirationCP } from 'submodules/nerit-framework-ui/common/components/system/top-bar-password-expiration/TopBarPasswordExpirationCP'

/**
 */
export function ScreenPrivateUser(): JSX.Element {
	const [refreshSiderCollapsed, setRefreshSiderCollapsed] = useState<number>(0)

	const [shouldReload, setShouldReload] = useState<boolean>(false)
	useEffect(onReload, [shouldReload])

	/**
	 */
	function onReload(): void {
		if (!shouldReload) return

		// Faz uma pausa para recarregar a tela pois alterou a variavel para true <-> false
		SystemUtils.sleep(500).then(() => {
			setShouldReload(false)
		})
	}

	if (shouldReload) return <LoadingOverlayCP show={true} />

	return (
		<LayoutUserMainCP
			refreshMenuCollapsed={refreshSiderCollapsed}
			menu={
				<UserMainMenuCP
					onSelectMenuItem={() => setRefreshSiderCollapsed(TableUtils.getReloadNumber())}
					onChangeCurrentDoctorGroup={() => setShouldReload(true)}
				/>
			}
			privateRouter={
				<>
					<TopBarHomEnvironmentCP />
					<TopBarPasswordExpirationCP />
					<PrivateUserRouter />
				</>
			}
			extraContent={
				<>
					<IntercomCP />
					<ModalVxPromoteCP />
				</>
			}
		/>
	)
}
