import React from 'react'

import { UploaderImageFromApiCP } from 'submodules/nerit-framework-ui/common/components/uploader/uploader-image-from-api/UploaderImageFromApiCP'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { CardProfileChangeSignatureCP } from 'modules/person/components/doctor/card-profile-change-signature/CardProfileChangeSignatureCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import styled from 'styled-components'

interface ICPProps {
	visible: boolean
	onClose: () => void
	user: PersonResponseDTO
}

/**
 */
export function ModalUserChangeImagesCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP title={'Alterar images'} onClose={props.onClose} noFooter={true} visible={props.visible}>
			<CardCP title={'Foto de Perfil'}>
				<ImgWrapperSCP>
					<UploaderImageFromApiCP
						currentData={{
							code: props.user.code,
							imgUrl: props.user.profilePicture,
						}}
						type={'avatar'}
						requestApiConfig={{
							requestApi: PeopleRequests.changeProfilePicture,
						}}
					/>
				</ImgWrapperSCP>
			</CardCP>

			{props.user.type === UserTypeEnum.DOCTOR && (
				<CardProfileChangeSignatureCP personCode={props.user.code} signatureUrl={props.user.doctorData?.signature} />
			)}
		</ModalCP>
	)
}

const ImgWrapperSCP = styled.div`
	display: flex;
	justify-content: center;

	.hZeLyJ .avatar-uploader > .ant-upload {
		margin: 0 !important;
	}
`
