import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { DownloadMedicalReportsRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/DownloadMedicalReportsRequestDTO'
import { MedicalReportFinalizeRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportFinalizeRequestDTO'
import { MedicalReportPreviewRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportPreviewRequestDTO'
import { MedicalReportSaveAsDraftRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportSaveAsDraftRequestDTO'
import { MedicalReportSignRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportSignRequestDTO'
import { StartMedicalReportResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/StartMedicalReportResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { MedicalReportPreviewResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/MedicalReportPreviewResponseDTO'
import { MedicalReportHtmlResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/MedicalReportHtmlResponseDTO'

export class MedicalReportRequests {
	public static CONTROLLER_ROOT = 'medical-reports'
	public static START_EP = 'start/:examId'
	public static ADD_EP = 'add/:examCode'
	public static DELETE_EP = 'delete/:reportCode'
	public static DISCARD_EP = 'discard/:examCode'
	public static FINALIZE_EP = 'finalize'
	public static SIGN_EP = 'sign'
	public static SAVE_AS_DRAFT_EP = 'save-as-draft'
	public static PREVIEW_EP = 'preview'
	public static DOWNLOAD_MEDICAL_REPORTS_EP = 'download-medical-reports'
	public static REGENERATE_MEDICAL_REPORT_PDF_EP = 'regenerate-medical-report-pdf/:examCode'
	public static GET_HTMLS_EP = 'get-htmls/:examCode'
	public static INTEGRATE_MEDICAL_REPORTS_EP = 'integrate-medical-reports/:examCode'
	public static GENERATE_INTEGRATION_XML = 'generate-integration-xml/:examCode'
	public static PRINT_EP = 'print/:examId'
	public static COUNT_MEDICAL_REPORTS_IN_GROUP_EP = 'count-medical-reports-in-group'

	static start = (examId: string): RequestConfigTP<StartMedicalReportResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.START_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.PUT,
	})

	static addMedicalReportToExam = (examCode: number): RequestConfigTP<number> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.ADD_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.POST,
	})

	static deleteMedicalReportFromExam = (reportCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.DELETE_EP.replace(':reportCode', reportCode.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static discard = (examCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.DISCARD_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static regenerateMedicalReportPdf = (examCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.REGENERATE_MEDICAL_REPORT_PDF_EP.replace(
			':examCode',
			examCode.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
	})

	static sign = (dto: MedicalReportSignRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.SIGN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static finalize = (dto: MedicalReportFinalizeRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.FINALIZE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static saveAsDraft = (dto: MedicalReportSaveAsDraftRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.SAVE_AS_DRAFT_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static preview = (dto: MedicalReportPreviewRequestDTO): RequestConfigTP<MedicalReportPreviewResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.PREVIEW_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static getHtmls = (examCode: number): RequestConfigTP<MedicalReportHtmlResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.GET_HTMLS_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static downloadMedicalReports = (dto: DownloadMedicalReportsRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.DOWNLOAD_MEDICAL_REPORTS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static integrateMedicalReports = (examCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.INTEGRATE_MEDICAL_REPORTS_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static generateIntegrationMedicalReportsXml = (examCode: number): RequestConfigTP<string> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.GENERATE_INTEGRATION_XML.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static print = (examId: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportRequests.CONTROLLER_ROOT}/${MedicalReportRequests.PRINT_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})
}
