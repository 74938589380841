import React from 'react'
import styled from 'styled-components'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'

interface ScpProps {
	color?: string
	backgroundExtra?: string
	borderColor?: string
	canWrap?: boolean
}

interface ICPProps extends ScpProps {
	text: string
	icon?: JSX.Element
}

/**
 */
export function SubtitleTableItemICP(props: ICPProps): JSX.Element {
	return (
		<FlexCP alignItems={'center'} margin={{ bottom: 5 }}>
			<RoundedWrapperCP color={props.color ?? 'transparent'} borderColor={props.borderColor} text={props.icon} />
			<TextSCP canWrap={props.canWrap}>{props.text}</TextSCP>
		</FlexCP>
	)
}

const TextSCP = styled.i<{ canWrap?: boolean }>`
	white-space: ${(props) => (props.canWrap ? undefined : 'nowrap')};
	margin-right: 15px;
`
