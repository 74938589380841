import React from 'react'
import { Icon } from 'antd'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * ICONE: Italico
 *
 * @author hjcostabr
 */
export const ItalicIconCP = (props: IIconProps): JSX.Element => {
	const DEFAULT_COLOR = `${ThemeFrameworkCommon.browserDefaultColorLight}`

	return (
		<Icon
			type={'italic'}
			style={{
				color: props.color ?? DEFAULT_COLOR,
				fontSize: props.size,
				...props.style,
			}}
		/>
	)
}
