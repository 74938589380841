import { NavCollorType } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/inner/NavCollorType'

import React from 'react'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IPDFPageIndicatorICPProps {
	navColor?: string
	page: number
	total: number
	onChangePage: (value: number) => void
}

/**
 * Indicador de paginação
 */
export function PDFPageIndicatorICP(props: IPDFPageIndicatorICPProps): JSX.Element {
	function getArrow(direction: 'left' | 'right', newPage: number): JSX.Element {
		return (
			<ArrowSCP onClick={() => props.onChangePage(newPage)}>
				<IconICP iconName={`arrow-${direction}`} />
			</ArrowSCP>
		)
	}
	return (
		<NavWrapperSCP navCollor={props.navColor}>
			{props.page > 1 && getArrow('left', props.page - 1)}
			{`Página ${props.page} de ${props.total}`}
			{props.page < props.total && getArrow('right', props.page + 1)}
		</NavWrapperSCP>
	)
}

const NavWrapperSCP = styled.div<NavCollorType>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	color: ${ThemeFrameworkCommon.browserDefaultColorLight};
`

const ArrowSCP = styled.div`
	margin: 4px;
	padding: 4px;
	cursor: pointer;
`
