import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ConfigProvider as AntDProvider } from 'antd'
import { AppRouter } from 'app/routers/AppRouter'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { BeeRadsTitleBrowserCP } from 'app/components/beerads-title-browser/BeeRadsTitleBrowserCP'

/**
 * Componente inicial do sistema.
 */
export function App(): JSX.Element {
	return (
		<ReduxProvider store={ReduxHelper.getInstance().store}>
			<PersistGate loading={<LoadingOverlayCP show={true} />} persistor={ReduxHelper.getInstance().persistor}>
				<AntDProvider renderEmpty={() => <EmptyCP />}>
					<BrowserRouter>
						<BeeRadsTitleBrowserCP />
						<AppRouter />
					</BrowserRouter>
				</AntDProvider>
			</PersistGate>
		</ReduxProvider>
	)
}
