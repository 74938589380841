import React, { useEffect, useState } from 'react'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PersonClinicsRequests } from 'submodules/beerads-sdk/services/clinics/person-clinic/PersonClinicsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ToogleShouldNotifyRequestDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/requests/ToogleShouldNotifyRequestDTO'
import { NotificationTypeEnum } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/NotificationTypeEnum'
import styled from 'styled-components'

interface ICPProps {
	personClinicCode: number
	shouldNotify: boolean
	notificationType: NotificationTypeEnum
	helpText: string
}

/**
 */
export function SwitchClinicNotificationCP(props: ICPProps): JSX.Element {
	const [shouldNotify, setShouldNotify] = useState<boolean>(props.shouldNotify)
	useEffect(() => setShouldNotify(props.shouldNotify), [props.shouldNotify])

	const toogleShouldNotifyRequest = useRequest<void>('none')
	useEffect(onToogleShouldNotifyRequestChange, [toogleShouldNotifyRequest.isAwaiting])

	/**
	 */
	function toogleShouldNotify(): void {
		const dto: ToogleShouldNotifyRequestDTO = {
			personClinicCode: props.personClinicCode,
			shouldNotify: !shouldNotify,
			notificationType: props.notificationType,
		}
		toogleShouldNotifyRequest.runRequest(PersonClinicsRequests.toogleShouldNotify(dto))
	}

	/**
	 * Retorno ao tornar pessoa admin.
	 */
	function onToogleShouldNotifyRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				toogleShouldNotifyRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		setShouldNotify(!shouldNotify)
	}

	return (
		<WrapperSCP>
			<SwitchCP value={shouldNotify} onChange={toogleShouldNotify} loading={toogleShouldNotifyRequest.isAwaiting} />
			<HelpCP text={props.helpText} type={'text'} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	margin-right: 15px;
`
