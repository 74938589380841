import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * CONFIG
 * Determina parametros de configuracao para editor de texto
 * wysiwyg / Draft JS customizado.
 *
 * @author hjcostabr
 */
export class TextEditorConfig {
	static readonly FONT_SIZE_SMALL = 14
	static readonly FONT_SIZE_LARGE = 16
	static readonly DEFAULT_STYLES_KEY = 'default-styles-custom'

	/**
	 * @todo: Melhorar isso pra padronizar estilos obtidos na exportacao
	 * Estilos genericos a serem aplicados a qualquer conteudo gerado pelo editor.
	 */
	static readonly DEFAULT_STYLES = {
		'font-size': `${TextEditorConfig.FONT_SIZE_SMALL}px`,
		'font-family': 'Verdana, Geneva, sans-serif',
		'letter-spacing': '-.037rem',
		'line-height': '1.2rem',
		color: `${ThemeFrameworkCommon.browserDefaultColorDark}`,
	}

	/** Estilos a serem aplicados no html exportado de 01 <table> gerada via editor. */
	static readonly TABLE_HTML_STYLES = {
		'border-collapse': 'collapse',
		'min-width': '600px',
		'max-width': '1000px',
		margin: '0 auto',
		'table-layout': 'fixed',
		...TextEditorConfig.DEFAULT_STYLES,
	}

	private constructor() {
		/** Construtor privado impede instanciacao. */
	}
}
