import React from 'react'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'

interface ICPProps {
	reportType: ReportTypeEnum
	formStateManager: IFormStateManager<CommonReportsFilterFormModel>
	shouldLoad: number
}

/**
 */
export function WidgetMedicalReportSlaByUrgencyCP(props: ICPProps): JSX.Element {
	return (
		<>
			{Object.values(ExamUrgencyTypeEnum).map((urgency) => (
				<CardWidgetOnlyValuesShouldLoadCP
					margin={{ top: 20 }}
					main={{
						title: `Entregue no prazo - ${ExamUrgencyTypeLabelEnum[urgency]}`,
						valueMask: '%',
						icon: (
							<RoundedWrapperCP
								size={32}
								color={ThemeProjectCommon.examUrgentTypeColor[urgency]}
								text={<IconICP iconName={ThemeProjectCommon.icons[urgency]} />}
							/>
						),
						secondaryValueMask: 'h',
					}}
					requestConfigGetter={WidgetsRequests.medicalReportSlaByUrgency(
						urgency,
						CommonReportsFilterFormModelConverter.formatDto(props.reportType, props.formStateManager.getFormValues()),
					)}
					shouldLoad={props.shouldLoad}
				/>
			))}
		</>
	)
}
