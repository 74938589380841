import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { GenderLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ButtonEditExamDataCP } from 'modules/exams/components/exams/button-edit-exam-data/ButtonEditExamDataCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	appearance?: {
		showTitle?: boolean
		showName?: boolean
	}
	editExamOption?: {
		onEditData: () => void
	}
}

/**
 * Componente para exibir dados do paciente na aba de exames do drawer.
 */
export function PatientDataCP(props: ICPProps): JSX.Element | null {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			{props.appearance?.showTitle && (
				<FlexCP justifyContent={'space-between'} alignItems={'center'}>
					<TitleCP textSize={'normal'} underLine={true}>
						Dados do paciente
					</TitleCP>
					{!!props.editExamOption && <ButtonEditExamDataCP exam={props.exam} onEditData={() => props.editExamOption!.onEditData()} />}
				</FlexCP>
			)}

			{props.appearance?.showName && <TextAndTitleCP title={'Nome:'} text={props.exam.patient.name} />}

			<TextAndTitleCP title={'ID:'} text={props.exam.patient.id} />

			<TextAndTitleCP title={'Nascimento:'} text={DateUtils.formatDate(props.exam.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME)} />
			{!!props.exam.patient.birthday && <TextAndTitleCP title={'Idade:'} text={DateUtils.ageCalculate(new Date(props.exam.patient.birthday))} />}
			<TextAndTitleCP title={'Sexo:'} text={!!props.exam.patient.gender ? GenderLabelEnum[props.exam.patient.gender] : undefined} />
		</BasicStyleWrapperCP>
	)
}
