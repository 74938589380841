import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'

/**
 */
export function OitSection1ICP(props: IOitSectionProps): JSX.Element {
	return (
		<RowCP>
			<OitSectionContentICP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: '1', value: '1' },
							{ content: '2', value: '2' },
							{ content: '3', value: '3' },
							{ content: '4', value: '4' },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer1A'}
						label={'1A - Qualidade Técnica'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: 'Sim', value: true },
							{ content: 'Não', value: false },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer1B'}
						label={'1B - Radiografia Normal'}
					/>
				</ColumnCP>
				{!!props.formStateManager.getFieldValue('answer1A') && props.formStateManager.getFieldValue('answer1A') !== '1' && (
					<ColumnCP size={12}>
						<TextAreaCP label={'1C - Motivo da qualidade técnica'} formStateManager={props.formStateManager} fieldName={'answer1C'} />
					</ColumnCP>
				)}
			</OitSectionContentICP>
		</RowCP>
	)
}
