import React from 'react'
import { TagsCP } from 'submodules/nerit-framework-ui/common/components/tag/TagsCP'
import { ExamModalityEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	modalities?: ExamModalityEnum[]
}

/**
 * Tags com modalidades.
 */
export function TagsExamModalitiesCP(props: ICPProps): JSX.Element {
	if (ArrayUtils.isEmpty(props.modalities)) return <></>

	return <TagsCP color={'darkOrange'} texts={props.modalities!.map((modality) => ExamModalityLabelEnum[modality])} />
}
