import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ClinicDoctorGroupGetLearnedDescriptionRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupGetLearnedDescriptionRequestDTO'
import { ClinicDoctorGroupLearnedDescriptionResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupLearnedDescriptionResponseDTO'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

interface ICPProps {
	exam: {
		doctorGroupCode: number
		modality: ExamModalityEnum
		description: string
		clinicCode: number
	}
	doctorGroupCode: number
	onClick: (suggestedDescriptionAI: ClinicDoctorGroupLearnedDescriptionResponseDTO) => void
}

/**
 * Select com os medicos ou usuarios de um grupo.
 */
export function LinkExamDescriptionSuggestionAiCP(props: ICPProps): JSX.Element {
	const [suggestion, setSuggestion] = useState<ClinicDoctorGroupLearnedDescriptionResponseDTO>()
	const getClinicExamDescriptionSuggestionRequest = useRequest<ClinicDoctorGroupLearnedDescriptionResponseDTO>()
	useEffect(onGetClinicExamDescriptionSuggestionRequestChange, [getClinicExamDescriptionSuggestionRequest.isAwaiting])

	useEffect(init, [props.doctorGroupCode])

	/**
	 * Carrega todos.
	 */
	function init(): void {
		if (!props.doctorGroupCode) return

		const dto: ClinicDoctorGroupGetLearnedDescriptionRequestDTO = {
			originalDescription: props.exam.description,
			originalModality: props.exam.modality,
			doctorGroupCode: props.doctorGroupCode,
			clinicCode: props.exam.clinicCode,
		}
		getClinicExamDescriptionSuggestionRequest.runRequest(ClinicDoctorGroupRequests.getLearnedExamDescriptions(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicExamDescriptionSuggestionRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicExamDescriptionSuggestionRequest)) return

		setSuggestion(getClinicExamDescriptionSuggestionRequest.responseData)
	}

	if (!suggestion) return <></>

	return <a onClick={() => props.onClick(suggestion)}>{`Sugestão: ${suggestion.description}`}</a>
}
