export enum RectificationReasonsEnum {
	EXAM_DATA_CORRECTION = 'EXAM_DATA_CORRECTION',
	PATIENT_DATA_CORRECTION = 'PATIENT_DATA_CORRECTION',
	CORRECT_LATERALIZATION = 'CORRECT_LATERALIZATION',
	CORRECT_CONTRAST_TECHNIQUE = 'CORRECT_CONTRAST_TECHNIQUE',
	SPLIT_OR_MERGE_REPORT = 'SPLIT_OR_MERGE_REPORT',
	GRAMMATICAL_OR_SPELLING_ERROR = 'GRAMMATICAL_OR_SPELLING_ERROR',
	PERFORM_COMPARATIVE_STUDY_REPORT = 'PERFORM_COMPARATIVE_STUDY_REPORT',
	DIAGNOSIS_REVIEW = 'DIAGNOSIS_REVIEW',
}

export enum RectificationReasonsLabelEnum {
	EXAM_DATA_CORRECTION = 'Correção de dados do exame',
	PATIENT_DATA_CORRECTION = 'Correção de dados do paciente',
	CORRECT_LATERALIZATION = 'Corrigir lateralidade',
	CORRECT_CONTRAST_TECHNIQUE = 'Corrigir técnica de contraste',
	SPLIT_OR_MERGE_REPORT = 'Desmembrar ou unificar laudo',
	GRAMMATICAL_OR_SPELLING_ERROR = 'Erro gramatical ou ortográfico',
	PERFORM_COMPARATIVE_STUDY_REPORT = 'Realizar laudo de estudo comparativo',
	DIAGNOSIS_REVIEW = 'Revisão de diagnóstico',
}
