import { TableDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-people/TableDoctorGroupPeopleCP'
import React from 'react'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'

interface ICPProps {
	doctorGroupCode: number
	role: UserTypeEnum
}

export function ScreenContentDoctorGroupPeopleUsers(props: ICPProps): JSX.Element {
	return (
		<LayoutSubmenuContentCP>
			<TableDoctorGroupPeopleCP doctorGroupCode={props.doctorGroupCode} role={UserTypeEnum.USER} />
		</LayoutSubmenuContentCP>
	)
}
