import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { DrawerDoctorGroupDataCP } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/DrawerDoctorGroupDataCP'

/**
 */
export function ButtonAddDoctorGroupCP(): JSX.Element {
	const [isDoctorGroupModalVisible, setIsDoctorGroupModalVisible] = useState<boolean>(false)

	if (!AppStateUtils.getLoggedUser()?.isBeeradsAdmin) return <></>

	return (
		<>
			<ButtonCP onClick={() => setIsDoctorGroupModalVisible(true)} marginRight={10} icon={'plus'}>
				Novo Grupo de Médicos
			</ButtonCP>

			<DrawerDoctorGroupDataCP
				visible={isDoctorGroupModalVisible}
				onCancel={() => setIsDoctorGroupModalVisible(false)}
				onSave={() => setIsDoctorGroupModalVisible(false)}
			/>
		</>
	)
}
