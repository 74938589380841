import React, { useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { ScreenContentHomeClinicUser } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeClinicUser'
import { ScreenContentHomeDoctorGroupAdmin } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctorGroupAdmin'
import { ScreenContentHomeDoctor } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctor'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ScreenContentHomeDoctorGroupAssignAnalysis } from 'modules/reports/screens/screen-home/contents/ScreenContentHomeDoctorGroupAssignAnalysis'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ScreenHome(): JSX.Element {
	const screenSize = useScreenSize()
	const [clinicCode, setClinicCode] = useState<number>()
	const [reload, setReload] = useState<number>()

	return (
		<LayoutCP
			titleBrowser={'Página Inicial'}
			header={
				<HeaderCP title={'Página Inicial'}>
					{!screenSize.xs && (
						<SelectClinicFromLoggedUserCP
							value={{
								byOnChange: {
									value: clinicCode,
									onChange: setClinicCode,
								},
							}}
							width={300}
							allowClear={true}
						/>
					)}
				</HeaderCP>
			}
			content={
				<ContentCP overflowVertical={true}>
					<TabsCP
						margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
						padding={{ bottom: screenSize.xs ? 100 : undefined }}
						extraContent={<ButtonCP icon={'reload'} onClick={() => setReload(TableUtils.getReloadNumber())} />}
						tabs={[
							{
								key: 'doctor',
								title: 'Médico',
								hide: !!clinicCode
									? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isDoctor'])
									: !CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR),
								content: <ScreenContentHomeDoctor clinicCode={clinicCode} />,
							},
							{
								key: 'clinicUser',
								hide: !!clinicCode
									? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isUser', 'isAdmin'])
									: !ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isUser', 'isAdmin']),
								title: 'Unidade Hospitalar',
								content: <ScreenContentHomeClinicUser clinicCode={clinicCode} />,
							},
							{
								key: 'doctorGroupAdmin',
								title: 'Indicadores do Grupo de Médicos',
								hide: !!clinicCode
									? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser'])
									: !DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin', 'isDoctorGroupUser']),
								content: <ScreenContentHomeDoctorGroupAdmin clinicCode={clinicCode} />,
							},
							{
								key: 'doctorGroupAssignAnalysis',
								title: 'Análise de Atribuições',
								hide: !!clinicCode
									? !ClinicPermissionUtils.hasAnyPermissionsInClinic(clinicCode, ['isDoctorGroupAdmin', 'isDoctorGroupUser'])
									: !DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin', 'isDoctorGroupUser']),
								content: <ScreenContentHomeDoctorGroupAssignAnalysis clinicCode={clinicCode} reload={reload} />,
							},
						]}
					/>
				</ContentCP>
			}
		/>
	)
}
