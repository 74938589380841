import React, { useEffect, useState } from 'react'
import { PdfViewerCP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/PdfViewerCP'
import styled from 'styled-components'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { MedicalReportPreviewRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportPreviewRequestDTO'
import { MedicalReportPreviewResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/MedicalReportPreviewResponseDTO'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	examCode: number
	medicalReportCotents: MedicalReportContentTP[]
}

/**
 */
export function TabsPreviewMedicalReportsCP(props: ICPProps): JSX.Element {
	const [loadedMedicalReportPreview, setLoadedMedicalReportPreview] = useState<MedicalReportPreviewResponseDTO>()
	const getMedicalReportPreviewRequest = useRequest<MedicalReportPreviewResponseDTO>()
	useEffect(onGetMedicalReportPreviewRequestChange, [getMedicalReportPreviewRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		const dto: MedicalReportPreviewRequestDTO = {
			examCode: props.examCode,
			medicalReportsContents: props.medicalReportCotents,
		}
		getMedicalReportPreviewRequest.runRequest(MedicalReportRequests.preview(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetMedicalReportPreviewRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalReportPreviewRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getMedicalReportPreviewRequest.responseData!
		setLoadedMedicalReportPreview(result)
	}

	if (!loadedMedicalReportPreview) return <LoadingOverlayCP show={true} appearance={{ top: 200 }} />

	return (
		<TabsCP
			tabs={loadedMedicalReportPreview.medicalReportPdfs.map((medicalReportUrl, index) => ({
				key: `report-${index + 1}`,
				title: `Laudo ${index + 1}`,
				content: (
					<FileWrapperSCP>
						<PdfViewerCP file={medicalReportUrl} paginationType={'scroll'} viewType={'inline'} width={'100%'} />
					</FileWrapperSCP>
				),
			}))}
		/>
	)
}

const FileWrapperSCP = styled.div`
	overflow-y: scroll;
	border: dashed 1px ${ThemeProject.gray300};
	border-radius: 5px;
	height: 560px;

	.nerit-viewer-wrapper {
		height: auto;
	}
`
