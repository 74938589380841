import React from 'react'
import { ClinicDoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/form-clinic-doctor-group/form-models/ClinicDoctorGroupFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ClinicSLAConfig } from 'submodules/beerads-sdk/services/clinics/clinics/types/ClinicSLAConfig'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<ClinicDoctorGroupFormModel>
	urgencyType: ExamUrgencyTypeEnum
}

/**
 * Formulario com configuracoes de um grupo em uma clinica
 */
export function FormClinicDoctorGroupInputSlaConfigICP(props: ICPProps): JSX.Element {
	/**
	 * Obtem o slaConfig de acordo com a urgencia.
	 */
	function getSlaConfig(): ClinicSLAConfig | undefined {
		return props.formStateManager.getFieldValue('slaConfigs')?.find((slaConfig: ClinicSLAConfig) => slaConfig.urgencyType === props.urgencyType)
	}

	/**
	 * Ao mudar o valor do campo
	 */
	function onChange(enteredVal: string): void {
		const slaConfigs = !!props.formStateManager.getFieldValue('slaConfigs') ? [...props.formStateManager.getFieldValue('slaConfigs')] : []
		const slaConfig = getSlaConfig()

		if (!slaConfig) {
			slaConfigs.push({ urgencyType: props.urgencyType, hours: enteredVal })
		} else slaConfig.hours = +enteredVal

		props.formStateManager.changeFieldValue('slaConfigs', slaConfigs)
	}

	return <InputCP label={`SLA ${ExamUrgencyTypeLabelEnum[props.urgencyType]}`} type={'number'} value={getSlaConfig()?.hours} onChange={onChange} />
}
