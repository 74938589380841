import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import moment from 'moment'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps {
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	onChangeFilters: () => void
	appearance?: {
		hideTimeFrame?: boolean
		hideWeekRangeSelection?: boolean
		disableWeekRangeNavigation?: boolean
	}
	leftContent?: JSX.Element
}

/**
 */
export function TitleMedicalTimetableCP(props: ICPProps): JSX.Element {
	/**
	 */
	function onChangeWeek(type: 'previous' | 'next'): void {
		const addOrSub = type === 'next' ? 1 : -1
		const newBeginDate = moment(props.filterFormStateManager.getFieldValue('beginDate')).add('week', addOrSub).toDate()
		const newEndDate = moment(props.filterFormStateManager.getFieldValue('endDate')).add('week', addOrSub).toDate()

		props.filterFormStateManager.changeFieldValue('beginDate', newBeginDate)
		props.filterFormStateManager.changeFieldValue('endDate', newEndDate)

		props.onChangeFilters()
	}

	return (
		<BasicStyleWrapperCP margin={{ top: 20, left: 20, right: 20 }}>
			<RowCP>
				<ColumnCP size={8}>{props.leftContent}</ColumnCP>
				<ColumnCP size={8}>
					{!props.appearance?.hideWeekRangeSelection && (
						<FlexCP justifyContent={'center'}>
							<ButtonCP
								icon={'arrow-left'}
								onClick={() => onChangeWeek('previous')}
								disabled={props.appearance?.disableWeekRangeNavigation}
							/>

							<TitleCP marginLeft={10} marginRight={10} marginBottom={0}>
								{`Semana ${DateUtils.getFormatted(
									props.filterFormStateManager.getFieldValue('beginDate'),
									DateFormatEnum.BR_DAY_MONTH,
								)} à ${DateUtils.getFormatted(
									DateUtils.getFirstAndLastDayOfWeek(props.filterFormStateManager.getFieldValue('endDate')).endDate,
									DateFormatEnum.BR_DAY_MONTH,
								)}`}
							</TitleCP>
							<ButtonCP icon={'arrow-right'} onClick={() => onChangeWeek('next')} disabled={props.appearance?.disableWeekRangeNavigation} />
						</FlexCP>
					)}
				</ColumnCP>
				<ColumnCP size={8}>
					{!props.appearance?.hideTimeFrame && (
						<FlexCP justifyContent={'flex-end'}>
							<RadioGroupCP
								disabled={true}
								type={'button'}
								paddingTop={0}
								buttonWidth={100}
								selected={2}
								options={[
									{
										value: 1,
										content: 'Mensal',
									},
									{
										value: 2,
										content: 'Semanal',
									},
									{
										value: 3,
										content: 'Diário',
									},
								]}
							/>
						</FlexCP>
					)}
				</ColumnCP>
			</RowCP>
		</BasicStyleWrapperCP>
	)
}
