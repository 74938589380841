import React from 'react'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCreateAuditsCP } from 'modules/quality-assurance/components/button-create-audits/ButtonCreateAuditsCP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	doctorGroupCode: number
	selectedExamCodes?: number[]
	onSave: () => void
}

/**
 */
export function ExamsToAuditTableBulkActionsBarICP(props: ICPProps): JSX.Element {
	if (!props.selectedExamCodes || ArrayUtils.isEmpty(props.selectedExamCodes)) {
		return <></>
	}

	return (
		<BulkActionBtnsContainerSCP>
			<ButtonCreateAuditsCP doctorGroupCode={props.doctorGroupCode} selectedExamCodes={props.selectedExamCodes} onSave={props.onSave} />

			<TextCP
				text={`${props.selectedExamCodes.length} exames selecionado(s)`}
				size={'small'}
				fontStyle={'italic'}
				color={ThemeProject.gray300}
				marginLeft={10}
			/>
		</BulkActionBtnsContainerSCP>
	)
}

const BulkActionBtnsContainerSCP = styled.div`
	display: flex;
	position: absolute;
	left: 20px;
`
