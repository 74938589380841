import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { CategoryResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/categories/responses/CategoryResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { CategorySaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/categories/requests/CategorySaveRequestDTO'

export class CategoriesRequests {
	public static CONTROLLER_ROOT = `${DoctorGroupsRequests.CONTROLLER_ROOT}/categories`
	public static SEARCH_EP = 'search/:doctorGroupCode'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'

	static create = (dto: CategorySaveRequestDTO): RequestConfigTP<CategoryResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${CategoriesRequests.CONTROLLER_ROOT}/${CategoriesRequests.CREATE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static update = (code: number, dto: CategorySaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${CategoriesRequests.CONTROLLER_ROOT}/${CategoriesRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static search = (doctorGroupCode: number): RequestConfigTP<CategoryResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${CategoriesRequests.CONTROLLER_ROOT}/${CategoriesRequests.SEARCH_EP.replace(':doctorGroupCode', doctorGroupCode.toString())}`,
		method: HttpMethodEnum.GET,
	})
}
