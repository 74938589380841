import React, { useEffect, useState } from 'react'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { TableTotalExamsAssignedByDoctorCP } from 'modules/exams/components/exams/table-total-exams-assigned-by-doctor/TableTotalExamsAssignedByDoctorCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { DrawerExamsCP } from 'modules/exams/components/exams/drawer-exams/DrawerExamsCP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { WidgetCountExamsAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-awaiting/WidgetCountExamsAwaitingCP'
import { WidgetCountExamsBySlaCP } from 'modules/reports/components/widgets/widget-count-exams-by-sla/WidgetCountExamsBySlaCP'
import { CountExamsWidgetEnum } from 'submodules/beerads-sdk/services/dashboard/widgets/enums/CountExamsWidgetEnum'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { WidgetCountExamsRectificationAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-awaiting/WidgetCountExamsRectificationAwaitingCP'
import { WidgetCountExamsAdminAnalysisCP } from 'modules/reports/components/widgets/widget-count-exams-admin-analysis/WidgetCountExamsAdminAnalysisCP'
import { WidgetCountExamsRectificationAnalysisCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-analysis/WidgetCountExamsRectificationAnalysisCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

type AnalyseTypeTP = 'all' | 'bySpecialty'

interface ICPProps {
	clinicCode?: number
	reload?: number
}

/**
 */
export function ScreenContentHomeDoctorGroupAssignAnalysis(props: ICPProps): JSX.Element {
	const [isExamsDrawerVisible, setIsExamsDrawerVisible] = useState<boolean>(false)
	const [selectedDoctor, setSelectedDoctor] = useState<NameAndCodeResponseDTO>()

	const [reload, setReload] = useState<number>()
	useEffect(() => setReload(props.reload), [props.reload])

	const [analyseType, setAnalyseType] = useState<AnalyseTypeTP>('bySpecialty')

	/**
	 * Ao selecionar um medico na tabela.
	 */
	function onSelectDoctor(doctor: NameAndCodeResponseDTO): void {
		setSelectedDoctor(doctor)
		setIsExamsDrawerVisible(true)
	}

	return (
		<>
			<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.BY_SLA_DELAYED}
					viewAsDoctor={false}
					assignedType={ExamAssignedFilterEnum.ALL}
				/>
				<WidgetCountExamsAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={false} />
				<WidgetCountExamsRectificationAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={false} />
				<WidgetCountExamsAdminAnalysisCP clinicCode={props.clinicCode} />
				<WidgetCountExamsRectificationAnalysisCP clinicCode={props.clinicCode} />
			</WidgetsRowCP>

			<FlexCP margin={{ left: 20, right: 20 }} justifyContent={'center'}>
				<RadioGroupCP<AnalyseTypeTP>
					type={'button'}
					selected={analyseType}
					onChange={setAnalyseType}
					buttonWidth={160}
					options={[
						{ content: 'Geral', value: 'all' },
						{ content: 'Por especialidade', value: 'bySpecialty' },
					]}
				/>
			</FlexCP>

			{analyseType === 'all' && (
				<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
					<TableTotalExamsAssignedByDoctorCP onRowClick={onSelectDoctor} clinicCode={props.clinicCode} reload={reload} />
				</WidgetsRowCP>
			)}
			{analyseType === 'bySpecialty' && (
				<>
					<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
						<TableTotalExamsAssignedByDoctorCP
							onRowClick={onSelectDoctor}
							specialty={ExamSpecialtyEnum.CARDIOLOGY}
							clinicCode={props.clinicCode}
							reload={reload}
						/>
						<TableTotalExamsAssignedByDoctorCP
							onRowClick={onSelectDoctor}
							specialty={ExamSpecialtyEnum.INTERN_MEDICINE}
							clinicCode={props.clinicCode}
							reload={reload}
						/>
						<TableTotalExamsAssignedByDoctorCP
							onRowClick={onSelectDoctor}
							specialty={ExamSpecialtyEnum.MAMMA}
							clinicCode={props.clinicCode}
							reload={reload}
						/>
					</WidgetsRowCP>

					<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
						<TableTotalExamsAssignedByDoctorCP
							onRowClick={onSelectDoctor}
							specialty={ExamSpecialtyEnum.MUSCLE}
							clinicCode={props.clinicCode}
							reload={reload}
						/>
						<TableTotalExamsAssignedByDoctorCP
							onRowClick={onSelectDoctor}
							specialty={ExamSpecialtyEnum.NEURO}
							clinicCode={props.clinicCode}
							reload={reload}
						/>
					</WidgetsRowCP>
				</>
			)}

			<DrawerExamsCP
				visible={isExamsDrawerVisible}
				onClose={(dataChanged) => {
					setIsExamsDrawerVisible(false)
					if (dataChanged) setReload(TableUtils.getReloadNumber())
				}}
				doctor={selectedDoctor}
			/>
		</>
	)
}
