import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { PendingAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/model/PendingAnalysisExamFormModel'
import styled from 'styled-components'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface ICPProps {
	examCode: number
	doctorGroupCode: number
	formStateManager: IFormStateManager<PendingAnalysisExamFormModel>
	onSave: (sendTo: ExamStatusEnum.RECTIFICATION | ExamStatusEnum.DONE_RECTIFICATION, doMedicalReport?: boolean) => void
}

/**
 * Drawer para fazer analise administrativa de um exame.
 */
export function ExamPendingRectificationAnalysisFooterICP(props: ICPProps): JSX.Element {
	const [isDoctorPopoverVisible, setIsDoctorPopoverVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP
				size={'small'}
				icon={'arrow-left'}
				onClick={() => props.onSave(ExamStatusEnum.DONE_RECTIFICATION)}
				tooltip={'Negando o pedido, é declarado que o laudo atual e o exame irão para Retificação Concluída'}
			>
				Negar Pedido
			</ButtonCP>

			<FlexCP>
				<PopOverCP
					visible={isDoctorPopoverVisible}
					onClose={() => setIsDoctorPopoverVisible(false)}
					trigger={'click'}
					title={'Atribuição para médico'}
					content={
						<PopoverContentSCP>
							<SelectDoctorGroupPeopleCP
								doctorGroupCode={props.doctorGroupCode}
								formStateManager={props.formStateManager}
								formField={'doctorCode'}
								role={UserTypeEnum.DOCTOR}
								filters={{
									status: [UserDoctorGroupStatusEnum.ACTIVE],
								}}
							/>
							<HelpCP text={'Selecione um médico para atribuir ou deixe em branco para ir para fila'} type={'text'} />

							<FlexCP justifyContent={'space-between'} margin={{ top: 20 }}>
								<ButtonCP onClick={() => setIsDoctorPopoverVisible(false)}>Cancelar</ButtonCP>
								<ButtonCP
									type={'primary'}
									onClick={() => {
										setIsDoctorPopoverVisible(false)
										props.onSave(ExamStatusEnum.RECTIFICATION)
									}}
								>
									Salvar
								</ButtonCP>
							</FlexCP>
						</PopoverContentSCP>
					}
				>
					<ButtonCP size={'small'} icon={'arrow-right'} onClick={() => setIsDoctorPopoverVisible(true)}>
						Enviar para Médico Retificar
					</ButtonCP>
				</PopOverCP>

				<AccessControlCP permission={{ byUserType: UserTypeEnum.DOCTOR }}>
					<ButtonCP
						type={'primary'}
						size={'small'}
						onClick={() => props.onSave(ExamStatusEnum.RECTIFICATION, true)}
						icon={'edit'}
						marginLeft={5}
					>
						Laudar
					</ButtonCP>
				</AccessControlCP>
			</FlexCP>
		</>
	)
}

const PopoverContentSCP = styled.div`
	width: 300px;
`
