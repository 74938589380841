import React from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { useFormSetPasswordContext } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'

interface ICPPros {
	currentPassword?: boolean
}

export function FormSetPasswordInputsCP(props: ICPPros): JSX.Element {
	const { handlePasswordDataChange, passwordData, passwordErrors } = useFormSetPasswordContext()

	return (
		<>
			{props.currentPassword && (
				<InputCP label="Informe a senha atual" required type="password" onChange={(value) => handlePasswordDataChange('currentPassword', value)} />
			)}

			<InputCP
				label="Informe a nova senha"
				required
				type="password"
				onChange={(value) => handlePasswordDataChange('password', value)}
				errorMessage={passwordErrors.length > 0 ? 'A senha deve atender aos requisitos de segurança' : ''}
			/>

			<InputCP
				label="Informe a nova senha novamente"
				required
				type="password"
				onChange={(value) => handlePasswordDataChange('confirmPassword', value)}
				errorMessage={passwordData.password !== passwordData.confirmPassword ? 'As senhas não coincidem' : ''}
			/>
		</>
	)
}
