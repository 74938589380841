import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

/**
 */
export class AvcFormModel extends FormModel {
	@IsRequired()
	q1HemoSuba: boolean

	@IsRequired()
	q1HemoSubd: boolean

	@IsRequired()
	q1HemoExtr: boolean

	@IsRequired()
	q1HemoIntra: boolean

	constructor(initialData?: ObjectPropsTP<AvcFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
