import React, { useState } from 'react'
import { ModalNewClinicCP } from 'modules/clinic/components/modal-new-clinic/ModalNewClinicCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
	doctorGroupCode?: number
	onSave(): void
	appearance?: {
		buttonSize: ButtonSizeTP
	}
}

export function ButtonNewClinicCP(props: ICPProps): JSX.Element {
	const [isClinicModalWizardVisible, setIsClinicModalWizardVisible] = useState(false)

	return (
		<>
			<ButtonCP
				onClick={() => {
					setIsClinicModalWizardVisible(true)
				}}
				size={props.appearance?.buttonSize}
				icon={'plus'}
			>
				Nova Unidade Hospitalar
			</ButtonCP>

			<ModalNewClinicCP
				doctorGroupCode={props.doctorGroupCode}
				visible={isClinicModalWizardVisible}
				onCancel={() => setIsClinicModalWizardVisible(false)}
				onSave={() => {
					setIsClinicModalWizardVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
