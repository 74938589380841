import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'

export class AuditFilterFormModel extends FormModel {
	@IsOptional()
	status?: AuditStatusEnum[]

	@IsOptional()
	doctorExamCode?: number

	@IsOptional()
	doctorAuditorCode?: number

	@IsOptional()
	dateRange?: DateRangeSearchRequestDTO

	@IsOptional()
	clinicCodes?: number

	constructor(initialData?: ObjectPropsTP<AuditFilterFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
