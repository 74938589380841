import { CreateExamFormModel } from 'modules/exams/components/exams/wizard-create-exam/form-model/CreateExamFormModel'
import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { ButtonGroupUrgencyTypeCP } from 'modules/exams/components/exams/button-group-urgency-type/ButtonGroupUrgencyTypeCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import styled from 'styled-components'
import { SelectExamReasonCP } from 'modules/exams/components/exams/select-exam-reason/SelectExamReasonCP'
import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

interface ICPProps {
	clinicCode: number
	clinicType: ClinicTypeEnum
	doctorGroup: DoctorGroupNamesFromClinicResponseDTO
	formStateManager: IFormStateManager<CreateExamFormModel>
}

/**
 * Drawer para exibir em abas de detalhes de um exame.
 */
export function CreateExamExamDataICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP underLine={true} textSize={'normal'} marginBottom={15} marginTop={30}>
				Dados do Exame
			</TitleCP>

			<RowCP>
				<ColumnCP size={14}>
					<ButtonGroupUrgencyTypeCP
						doctorGroupCode={props.doctorGroup.code}
						clinicCode={props.clinicCode}
						type={'clinic'}
						formStateManager={props.formStateManager}
						fieldName={'urgencyType'}
					/>
				</ColumnCP>
				<ColumnCP size={10}>
					<BasicStyleWrapperCP margin={{ top: -20 }}>
						{ClinicTypeEnum.OCUP === props.clinicType && (
							<InputCP
								label={'Função Pretendida'}
								fieldName={'patientFunction'}
								type={'text'}
								formStateManager={props.formStateManager}
								required={true}
							/>
						)}
					</BasicStyleWrapperCP>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={12}>
					<InputCP
						label={'Médico/Empresa Solicitante'}
						formStateManager={props.formStateManager}
						fieldName={'requestingDoctor'}
						required={true}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<SelectExamReasonCP
						type={props.clinicType}
						label={'Motivo'}
						formStateManager={props.formStateManager}
						fieldName={'reason'}
						required={true}
					/>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={6}>
					<InputCP label={'Data do Exame'} fieldName={'examDate'} type={'date'} formStateManager={props.formStateManager} required={false} />
				</ColumnCP>
				<ColumnCP size={4}>
					<InputCP
						label={'Hora'}
						fieldName={'examTime'}
						type={'text'}
						formStateManager={props.formStateManager}
						mask={InputMaskTypeEnum.HOUR}
						required={true}
					/>
				</ColumnCP>
				<ColumnCP size={4}>
					<SelectExamModalityCP label={'Modalidade'} formStateManager={props.formStateManager} fieldName={'modality'} required={true} />
				</ColumnCP>
				<ColumnCP size={10}>
					{props.doctorGroup.config?.shouldValidateExamDescription ? (
						<SelectDoctorGroupExamDescriptionsCP
							doctorGroupCode={props.doctorGroup.code}
							selectedModality={props.formStateManager.getFieldValue('modality')}
							onChange={(selected) => {
								props.formStateManager.changeFieldValue('description', selected)
							}}
							required={true}
							fieldName={'description'}
						/>
					) : (
						<InputCP label={'Exame'} fieldName={'description'} type={'text'} formStateManager={props.formStateManager} required={true} />
					)}
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={24}>
					<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
						História Clínica (Anamnese)
					</TitleCP>

					<TextAreaWrapperSCP>
						<TextAreaCP
							placeholder={'Registre as informações mais relevantes da história clínica do paciente'}
							minRows={5}
							formStateManager={props.formStateManager}
							fieldName={'anamnesis'}
							required={true}
						/>
					</TextAreaWrapperSCP>
				</ColumnCP>
			</RowCP>
		</>
	)
}

const TextAreaWrapperSCP = styled.div`
	textarea {
		margin-top: 10px !important;
		padding: 5px;
		border: 1px solid #bbb !important;
		border-radius: 3px !important;
	}
`
