import React from 'react'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'
import chromeIcon from 'submodules/nerit-framework-ui/common/components/browser-warning/inner/chrome_icon.png'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
	title: string
}

/**
 */
export function ScreenResultBrowerWarning(props: ICPProps): JSX.Element {
	return (
		<ScreenResultCP
			icon={<img src={chromeIcon} width={100} />}
			title={props.title}
			message={'Para fazer o download do chrome, clique no botão abaixo'}
			bottomContent={
				<FlexCP justifyContent={'center'}>
					<ButtonCP
						type={'primary'}
						size={'large'}
						onClick={() => NeritFrameworkRoutingHelper.openInNewTab('https://www.google.com.br/chrome/next-steps.html')}
						icon={'download'}
					>
						Download Chrome
					</ButtonCP>
				</FlexCP>
			}
		/>
	)
}
