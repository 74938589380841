import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import {
	ClinicDoctorGroupStatusEnum,
	ClinicDoctorGroupStatusLabelEnum,
} from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'

interface ICPProps {
	status: ClinicDoctorGroupStatusEnum
}

/**
 */
export function TagClinicDoctorGroupStatusCP(props: ICPProps): JSX.Element {
	return <TagCP color={ThemeProjectCommon.clinicDoctorGroupStatusColor[props.status]} content={ClinicDoctorGroupStatusLabelEnum[props.status]} />
}
