export enum ExamSearchOrderingEnum {
	DATE = 'examDate',
	PATIENT = 'patientName',
	EXAM = 'description',
	CLINIC = 'clinicName',
	STATUS = 'status',
	SLA = 'currentSlaFromNow',
	URGENCY = 'urgencyType',
	MEDICAL_REPORT_DATE = 'medicalReportDate',
}
