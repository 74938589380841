import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalTimetablePeriodLabelEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'

export class MedicalTimetableFilterFormModel extends FormModel {
	beginDate: Date
	endDate: Date

	@IsOptional()
	id: MedicalTimetableIdEnum

	@IsOptional()
	modality: ExamModalityEnum

	@IsOptional()
	period: MedicalTimetablePeriodLabelEnum

	@IsOptional()
	groupBy: ExamUrgencyTypeEnum | ExamSpecialtyEnum

	constructor(initialData?: ObjectPropsTP<MedicalTimetableFilterFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
