import { DoctorIdTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorIdTypeEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { UfEnum } from 'submodules/nerit-framework-utils/utils/enums/UfEnum'
import { IsOptional } from 'class-validator'

export class DoctorIdFormModel extends FormModel {
	@IsRequired()
	id: string

	@IsRequired()
	type: DoctorIdTypeEnum

	@IsRequired()
	uf: UfEnum

	@IsOptional()
	specialtyTitle: string

	constructor(dto?: any) {
		super(dto)
		Object.assign(this, dto)
	}
}
