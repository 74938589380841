import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { GenderEnum, GenderLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import React from 'react'

/**
 * Seletor unitario de genero.
 */
export function SelectGenderCP<FModelTP extends FormModel = any>(props: IFormItemCommonProps<FModelTP>): JSX.Element {
	return (
		<SelectCP<FModelTP>
			label={'Sexo'}
			options={Object.values(GenderEnum).map((gender, i) => ({
				key: `key-gender-${i}`,
				label: GenderLabelEnum[gender],
				value: gender,
			}))}
			onChange={props.onChange}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			required={props.required}
			disabled={props.disabled}
		/>
	)
}
