import React from 'react'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { RuleDescriptionICP } from 'modules/doctor-groups/components/teams/team-rule-description/inner/RuleDescriptionICP'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	rules?: TeamRuleTP[]
	onEdit?: (rule: TeamRuleTP, index: number) => void
	onDelete?: (index: number) => void
}

/**
 * Mostra regras de um time.
 */
export function TeamRulesDescriptionCP(props: ICPProps): JSX.Element {
	return (
		<>
			{ArrayUtils.isEmpty(props.rules) ? (
				<AlertCP showIcon={true} message={'Os médicos desse time podem ver todos os exames'} type={'success'} />
			) : (
				<>
					<HelpCP text={'Para o médico deste time NÃO SERÁ PERMITIDA visualização de exames que:'} type={'text'} />

					{props.rules!.map((rule, index) => (
						<RuleDescriptionICP
							rule={rule}
							onEdit={!!props.onEdit ? () => props.onEdit?.(rule, index) : undefined}
							onRemove={!!props.onDelete ? () => props.onDelete?.(index) : undefined}
							showDivider={index < props.rules!.length - 1}
						/>
					))}
				</>
			)}
		</>
	)
}
