import { ChartExamsCP } from 'modules/reports/components/chart-exams/ChartExamsCP'
import React, { useEffect, useState } from 'react'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import moment from 'moment/moment'
import { CardsExamsSummaryCP } from 'modules/reports/components/cards-exams-summary/CardsExamsSummaryCP'
import { FiltersReportCommonCP } from 'modules/reports/components/filters-report-common/FiltersReportCommonCP'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	reportType: ReportTypeEnum
}

/**
 */
export function ScreenContentMainReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<CommonReportsFilterFormModel>()
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const [shouldLoad, setShouldLoad] = useState<number>(0)

	useEffect(init, [props.reportType])

	/**
	 */
	function init(): void {
		setFormValidator(
			new CommonReportsFilterFormModel({
				dateRange: {
					beginDate: moment().startOf('M').toDate(),
					endDate: new Date(),
				},
				shouldConsiderMedicalReportDate: true,
				doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
			}),
		)
		setShouldLoad(TableUtils.getReloadNumber())
	}

	return (
		<>
			<HeaderCP title={'Visão Geral'} />
			<FiltersReportCommonCP
				reportType={props.reportType}
				filterFormStateManager={formStateManager}
				onFilter={() => setShouldLoad(TableUtils.getReloadNumber)}
				fieldsConfig={{
					hideDoctorFilter: true,
					showConsiderArchived: true,
				}}
			/>

			<LayoutSubmenuContentCP>
				{!formStateManager.getFormValues() ? (
					<LoadingOverlayCP show={true} />
				) : (
					<>
						<ChartExamsCP filterStateManager={formStateManager} reload={shouldLoad} reportType={props.reportType} />

						<CardsExamsSummaryCP reportType={props.reportType} filterStateManager={formStateManager} reload={shouldLoad} />
					</>
				)}
			</LayoutSubmenuContentCP>
		</>
	)
}
