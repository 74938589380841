import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { UserProfileFormModel } from 'modules/person/components/user/modal-user-data/inner/UserProfileFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

interface ICPProps {
	formStateManager: IFormStateManager<UserProfileFormModel>
}

/**
 * Modal com o log de uma operacao em massa.
 */
export function FormUserProfileDataICP(props: ICPProps): JSX.Element {
	return (
		<>
			<RowCP>
				<ColumnCP size={8}>
					<InputCP required={true} mask={InputMaskTypeEnum.CPF} label={'CPF'} formStateManager={props.formStateManager} fieldName={'cpf'} />
				</ColumnCP>
				<ColumnCP size={16}>
					<InputCP label={'Nome'} required={true} formStateManager={props.formStateManager} fieldName={'name'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={12}>
					<InputCP type={'email'} required={true} label={'E-mail'} formStateManager={props.formStateManager} fieldName={'email'} />
				</ColumnCP>
				<ColumnCP size={7}>
					<InputCP
						required={true}
						mask={InputMaskTypeEnum.PHONE}
						label={'Celular'}
						formStateManager={props.formStateManager}
						fieldName={'phone'}
					/>
				</ColumnCP>
				<ColumnCP size={5}>
					<InputCP
						label={'Nascimento'}
						mask={InputMaskTypeEnum.DATE}
						keepMask={true}
						formStateManager={props.formStateManager}
						fieldName={'birthDate'}
					/>
				</ColumnCP>
			</RowCP>
		</>
	)
}
