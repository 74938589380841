import { App } from 'app/App'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from 'submodules/nerit-framework-ui/common/global/serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register({
	onUpdate: (registration) => {
		const waitingServiceWorker = registration.waiting

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				// @ts-ignore
				if (event.target && event.target.state === 'activated') {
					serviceWorker.displayUpdateNotification()
				}
			})
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
		}
	},
})
