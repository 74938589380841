import React from 'react'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { ColumnMedicalTimetableWeekDayDoctorCP } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-doctor/inner/ColumnMedicalTimetableWeekDayDoctorCP'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { IMedicalTimetableStatistics } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableStatistics'
import { TablesMedicalTimetableUtils } from 'modules/medical-timetable/components/tables-medical-timetable/utils/TablesMedicalTimetableUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'

export class TableMedicalTimetableDoctorUtils {
	/**
	 */
	static getColumns(
		allRecords: IMedicalTimetableRecord[],
		dataStatistics: IMedicalTimetableStatistics[],
		medicalTimetableStructure: MedicalTimetableStructureResponseDTO[],
		doctorsOnMedicalTimetable: DoctorOnMedicalTimetableResponseDTO[],
		beginWeekDate: Date,
		onAssing: () => void,
	): Array<ITableColumn<IMedicalTimetableRecord>> {
		const columns: ITableColumn<IMedicalTimetableRecord>[] = [
			{
				title: 'Escala',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderIdColumn(record, index, allRecords),
			},
			{
				title: 'Período',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderPeriodColumn(record, index, allRecords),
			},
			{
				title: 'Modalidade',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderModalityColumn(record, index, allRecords),
			},
			{
				title: 'Urg./Esp.',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderUrgencyColumn(record, index, allRecords),
			},
		]

		// Para cada dia da semana, adiciona uma coluna
		for (let weekDay: WeekDaysEnum = WeekDaysEnum.SUNDAY; weekDay <= WeekDaysEnum.SATURDAY; weekDay++) {
			const weekDayName = momentPtBrLocale.weekdaysFull[weekDay]
			const formattedDate = DateUtils.formatDate(DateUtils.add(beginWeekDate, weekDay, TimeBaseEnum.DAY), DateFormatEnum.BR_DAY_MONTH)

			columns.push({
				title: `${weekDayName} (${formattedDate})`,
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayDoctorCP
						dayOfWeek={weekDay}
						record={record}
						doctors={doctorsOnMedicalTimetable}
						structure={medicalTimetableStructure}
						statistics={dataStatistics}
						beginWeekDate={beginWeekDate}
						onAssign={onAssing}
					/>
				),
			})
		}

		return columns
	}
}
