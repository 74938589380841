/**
 */
export class UiUtils {
	static testOS(os: 'windows' | 'mac' | 'linux'): boolean {
		if (os === 'windows') return /win/i.test(navigator.userAgent)

		if (os === 'mac') return /mac/i.test(navigator.userAgent)

		if (os === 'linux') return /linux/i.test(navigator.userAgent)

		return false
	}
}
