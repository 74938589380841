import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { PreviewMedicalReportConfigCP } from 'modules/clinic/components/preview-medical-report-config/PreviewMedicalReportConfigCP'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { MedicalReportConfigRequests } from 'submodules/beerads-sdk/services/clinics/medical-report-config/MedicalReportConfigRequests'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonPreviewClinicReportTemplateCP } from 'modules/clinic/components/button-preview-clinic-report-template/ButtonPreviewClinicReportTemplateCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { MedicalReportConfigPreviewRequestDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/request/MedicalReportConfigPreviewRequestDTO'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { ButtonEditMedicalReportTemplateCP } from 'modules/clinic/components/button-edit-medical-report-template/ButtonEditMedicalReportTemplateCP'

interface ICPProps {
	clinicCode: number
}

/**
 */
export function ScreenContentClinicsAdminReportPreviewConfig(props: ICPProps): JSX.Element {
	const [mode, setMode] = useState<'default' | 'oit'>('default')
	const [shouldHighlightContent, setShouldHighlightContent] = useState<boolean>(NeritFrameworkProjectConfig.isDev())

	const [loadedMedicalReportConfig, setLoadedMedicalReportConfig] = useState<MedicalReportConfigHtmlsResponseDTO>()
	const getMedicalReportConfigRequest = useRequest<MedicalReportConfigHtmlsResponseDTO>()
	useEffect(onGetMedicalReportConfigRequestChange, [getMedicalReportConfigRequest.isAwaiting])

	useEffect(init, [props.clinicCode])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		setLoadedMedicalReportConfig(undefined)
		const dto: MedicalReportConfigPreviewRequestDTO = {
			type: mode,
		}
		getMedicalReportConfigRequest.runRequest(MedicalReportConfigRequests.previewHtml(props.clinicCode, dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetMedicalReportConfigRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalReportConfigRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLoadedMedicalReportConfig(getMedicalReportConfigRequest.responseData)
	}

	return (
		<>
			<LoadingOverlayCP show={getMedicalReportConfigRequest.isAwaiting} />

			{!loadedMedicalReportConfig ? (
				<EmptyCP description={'Configuração de Laudo não encontrada.'} />
			) : (
				<>
					<FlexCP justifyContent={'center'} margin={{ bottom: 10 }} alignItems={'center'}>
						<SwitchCP label={'Destacar conteúdo?'} value={shouldHighlightContent} onChange={setShouldHighlightContent} />

						<RadioGroupCP
							selected={mode}
							onChange={setMode}
							margin={{ right: 100 }}
							paddingTop={0}
							type={'button'}
							options={[
								{
									value: 'default',
									content: 'Padrão',
								},
								{
									value: 'oit',
									content: 'OIT',
								},
							]}
						/>

						<ButtonPreviewClinicReportTemplateCP mode={mode} clinicCode={props.clinicCode} />

						<ButtonEditMedicalReportTemplateCP clinicCode={props.clinicCode} onSave={init} />

						<ButtonCP marginLeft={10} icon={'reload'} onClick={init} />
					</FlexCP>

					<PreviewMedicalReportConfigCP shouldHighlightContent={shouldHighlightContent} medicalReportHtmls={loadedMedicalReportConfig} />
				</>
			)}
		</>
	)
}
