import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { DicomViewerEmbeddedCP } from 'modules/exams/components/exams/dicom-viewer-embedded/DicomViewerEmbeddedCP'
import { TablePatientExamsCP } from 'modules/person/components/patient/table-patient-exams/TablePatientExamsCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { TableTemplatesForMedicalReportCP } from 'modules/template/components/table-templates-for-medical-report/TableTemplatesForMedicalReportCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import styled from 'styled-components'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	embeddedViewer: {
		isViewerEmbedded: boolean
		onEmbedViewer: (embed: boolean) => void
	}
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function SiderRightMedicalReport(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	// Para OIT nao mostra a barra lateral
	if (props.exam.modality === ExamModalityEnum.CR_OIT) return <div style={{ margin: 10 }}></div>

	// Mostra o viewer quando
	if (props.embeddedViewer.isViewerEmbedded) {
		return (
			<SiderCP width={'100%'} bgColor={'transparent'}>
				<DicomViewerEmbeddedCP exam={props.exam} onUnEmbedViewer={() => props.embeddedViewer.onEmbedViewer(false)} />
			</SiderCP>
		)
	}

	// Para telas pequenas nao mostra a barra
	if (!screenSize.xl) return <div style={{ margin: 10 }}></div>

	// Caso chegou aqui mostra a barra
	return (
		<SiderCP width={'100%'} bgColor={'transparent'}>
			<BasicStyleWrapperCP margin={{ right: 40 }}>
				<CardCP title={'Histórico do Paciente'} innerSpacing={'none'}>
					<TablePatientExamsCP
						exam={props.exam}
						onCopyMedicalReport={(jsonContent) => props.formStateManager.changeFieldValue('selectedJsonContentToReplace', jsonContent)}
						searchExamDto={props.searchExamDto}
					/>
				</CardCP>

				<CardCP title={'Templates de Laudo'} innerSpacing={'none'}>
					<TemplateWrapperSCP>
						<TableTemplatesForMedicalReportCP
							examCode={props.exam.code}
							onRowClick={(template) => props.formStateManager.changeFieldValue('selectedJsonContentToReplace', template.content)}
						/>
					</TemplateWrapperSCP>
				</CardCP>
			</BasicStyleWrapperCP>
		</SiderCP>
	)
}

const TemplateWrapperSCP = styled.div`
	height: 700px;
	overflow-y: scroll;
`
