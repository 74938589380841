import { ExamStatusEnum, ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

interface ICPProps {
	examStatus: ExamStatusEnum
}

/**
 * Renderiza componente para exibir status do exame.
 */
export function TagExamStatusCP(props: ICPProps): JSX.Element {
	return <TagCP color={ThemeProjectCommon.examStatusColor[props.examStatus]} content={ExamStatusLabelEnum[props.examStatus]} />
}
