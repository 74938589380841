import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '_old/main/common/validation/ValidationTypes'

/**
 * DECORATOR de VALIDACAO
 * Valida se eh 01 string com valor numerico.
 *
 * @author hjcostabr
 */
export function IsNumericString(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsStringConstraint,
		})
	}
}

@ValidatorConstraint({ name: ValidationTypes.IS_NUMBER_STRING })
class IsStringConstraint implements ValidatorConstraintInterface {
	validate(value: string, args: ValidationArguments): boolean {
		const validator = new Validator()
		return validator.isNumberString(value)
	}

	defaultMessage(args: ValidationArguments): string {
		return 'Deve conter apenas números'
	}
}
