import { Switch } from 'antd'
import React from 'react'

interface ISwitchCPProps {
	value?: boolean
	onChange?: (value: boolean) => void
	checkedValue?: string
	uncheckedValue?: string
	disabled?: boolean
}

/**
 *
 * @param props
 * @author Lucas Rosa
 * @author Victor
 */
export function SwitchCP(props: ISwitchCPProps): JSX.Element {
	function onChange(checked: boolean): void {
		if (props.onChange) props.onChange(checked)
	}
	return (
		<Switch
			checked={props.value}
			onChange={onChange}
			checkedChildren={props.checkedValue ?? 'Sim'}
			unCheckedChildren={props.uncheckedValue ?? 'Não'}
			disabled={props.disabled}
		/>
	)
}
