import { useQueryParams } from 'submodules/nerit-framework-ui/common/hooks/useQueryParam'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ResetPasswordQueryParamsTP } from 'modules/auth/screens/screen-recover-password/ScreenForgotPassword'
import React, { useEffect } from 'react'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { FormSetPasswordCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/FormSetPasswordCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { PasswordDataTP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'

interface ICPProps {
	passwordToken: string
}

export function RecoverPasswordCP(props: ICPProps): JSX.Element {
	const [queryParams] = useQueryParams<ResetPasswordQueryParamsTP>()

	const resetUserPasswordRequest = useRequest('none')

	useEffect(onResetUserPasswordRequestChange, [resetUserPasswordRequest.isAwaiting])

	function onResetUserPasswordRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(resetUserPasswordRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, 'Senha alterada com sucesso!')) {
			return
		}

		NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
	}

	function handleResendVerificationCodeEmail(values: Omit<PasswordDataTP, 'currentPassword'>): void {
		const isQueryParamsValid = !!queryParams.email && !!props.passwordToken

		if (!isQueryParamsValid) {
			NotificationHelper.error('Erro ao redefinir senha', 'E-mail ou token de validação de senha inválido!')
			NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
			return
		}

		resetUserPasswordRequest.runRequest(AuthRequests.resetPassword(props.passwordToken, { ...values, email: queryParams.email! }))
	}

	return (
		<>
			<TitleCP underLine marginBottom={50}>
				Redefinir senha
			</TitleCP>

			<FormSetPasswordCP.Wrapper>
				<FormSetPasswordCP.Inputs />
				<FormSetPasswordCP.Actions
					cancelProps={{
						title: 'Voltar ao login',
						onClick: () => NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN),
					}}
					confirmProps={{
						title: 'Redefinir senha',
						onClick: (values) =>
							handleResendVerificationCodeEmail({
								password: values.password,
								confirmPassword: values.confirmPassword,
							}),
					}}
				/>
				<FormSetPasswordCP.Validation />
			</FormSetPasswordCP.Wrapper>
		</>
	)
}
