import React, { useEffect, useState } from 'react'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	borderType?: 'rounded' | 'square'
}

/**
 * Alerta informando que a clinica ja mandou o numero maximo de exames para trial.
 */
export function AlertTrialClinicExamsCP(props: ICPProps): JSX.Element {
	const [message, setMessage] = useState<string>()

	const searchClinicsExceedExamsRequest = useRequest<ListResponseDTO<NameAndCodeResponseDTO>>()
	useEffect(onSearchClinicsExceedExamsRequestRequestChange, [searchClinicsExceedExamsRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		searchClinicsExceedExamsRequest.runRequest(ClinicsRequests.searchClinicsExceedExams())
	}

	/**
	 * Retorno da requisicao.
	 */
	function onSearchClinicsExceedExamsRequestRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchClinicsExceedExamsRequest, 'Não foi possível obter dados')) return

		const result = searchClinicsExceedExamsRequest.responseData!.list

		if (ArrayUtils.isEmpty(result)) return

		// Verifica se possui uma ou mais clinicas para criar a msg correta
		let initialMessage: string

		if (result.length === 1) initialMessage = `A unidade hospitalar ${result[0].name} está em período de TRIAL e já ultrapassou`
		else initialMessage = `As unidades hospitalares ${result.map((clinic) => clinic.name).join(', ')} estão em período de TRIAL e já ultrapassaram`

		setMessage(`${initialMessage} o limite de exames disponíveis. Caso deseje enviar mais exames, entre em contato com nossa equipe`)
	}

	if (!message) return <></>

	return <AlertCP showIcon={true} message={message} type={'warning'} margin={props.margin} borderType={props.borderType} />
}
