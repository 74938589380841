import { PDFViewerICP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/inner/PDFViewerICP'
import { AttachmentUtils } from '_old/main/common/utils/AttachmentUtils'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamAttachmentSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/request/ExamAttachmentSearchRequestDTO'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	examId: string
}

/**
 */
export function NonDicomViewerICP(props: ICPProps): JSX.Element {
	const [examFile, setExamFile] = useState<FileDataTP>()
	const viewerRequest = useRequest<ListResponseDTO<FileDataTP>>()
	useEffect(onRequestChange, [viewerRequest.isAwaiting])

	useEffect(init, [])

	/**
	 */
	function init(): void {
		const dto: ExamAttachmentSearchRequestDTO = {
			isExam: true,
		}
		viewerRequest.runRequest(ExamAttachmentsRequests.searchAttachments(props.examId, dto))
	}

	/**
	 */
	function onRequestChange(): void {
		if (RequestUtils.isValidRequestReturn(viewerRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = viewerRequest.responseData!.list
		setExamFile(result[0])
	}

	if (viewerRequest.isAwaiting || !viewerRequest.wasTried) {
		return (
			<ImageWrapperSCP>
				<LoadingCP />
			</ImageWrapperSCP>
		)
	}

	if (!examFile) return <div />

	if (AttachmentUtils.getFileExtension(examFile.fileName) === 'pdf') {
		return (
			<ImageWrapperSCP>
				<PDFViewerICP navColor={`${ThemeFrameworkCommon.browserDefaultColorDark}`} pdfUrl={examFile.fileUrl} />
			</ImageWrapperSCP>
		)
	}

	return (
		<ImageWrapperSCP>
			<ImageSCP src={examFile.fileUrl} />
		</ImageWrapperSCP>
	)
}

const ImageSCP = styled.img`
	max-width: 100%;
	max-height: 100%;
`
const ImageWrapperSCP = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
