import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import styled from 'styled-components'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AuthActions } from 'app/utils/AuthActions'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	onSuccess: () => void
	onCancel: () => void
	visible: boolean
}

export function ModalAuthDigitalCertificateCP(props: ICPProps): JSX.Element {
	const [otp, setOtp] = useState<string>('')
	const [loadingRefreshData, setLoadingRefreshData] = useState<boolean>(true)

	const digitalCertificateAuthRequest = useRequest<boolean>()

	useEffect(onDigitalCertificateAuthRequestChange, [digitalCertificateAuthRequest.isAwaiting])

	useEffect(() => {
		if (otp?.length === 6 && !digitalCertificateAuthRequest.isAwaiting) {
			authenticate()
		}
	}, [otp])

	/**
	 */
	async function authenticate(): Promise<void> {
		if (!otp || otp?.length < 6) {
			NotificationHelper.error('Ops', 'Preencha o código de 6 dígitos gerado no BirdID')
			return
		}
		setLoadingRefreshData(true)
		digitalCertificateAuthRequest.runRequest(AuthRequests.createDigitalCertificateToken({ otp }))
	}

	/**
	 */
	function onDigitalCertificateAuthRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(digitalCertificateAuthRequest, 'Erro ao autenticar', 'Autenticado com sucesso')) {
			setLoadingRefreshData(false)
			return
		}

		const success = digitalCertificateAuthRequest.responseData
		if (success) {
			AuthActions.refreshLoggedUserData().then(() => {
				props.onSuccess()
				setLoadingRefreshData(false)
			})
		}
	}

	if (!AppStateUtils.getLoggedUser()?.isDoctor) return <></>

	return (
		<ModalCP
			title={'Autenticação do Certificado Digital'}
			visible={props.visible}
			onCancel={props.onCancel}
			onOk={authenticate}
			width={450}
			okText={'Autenticar'}
			actionLoading={digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
			disableOutsideClickClosing={digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
			closable={!digitalCertificateAuthRequest.isAwaiting || loadingRefreshData}
		>
			<AlertCP
				message={'Você já está autenticado e com uma sessão ativa no BirdID. Só informe novamente o código caso deseje atualizar a sessão'}
				type={'warning'}
				show={AppStateUtils.getAuthData()?.isLoggedWithDigitalCertificate}
				margin={{ bottom: 10 }}
			/>

			<HelpCP
				text={
					digitalCertificateAuthRequest.isAwaiting || loadingRefreshData
						? 'Conectando com a certificadora, aguarde alguns segundos'
						: 'Acesse o aplicativo do BirdID e informe o código de autenticação'
				}
				type={'text'}
			/>

			<FlexCP justifyContent={'center'}>
				<WrapperSCP>
					<InputCP onChange={setOtp} required={true} maxlength={6} width={450} onFormSubmit={authenticate} />

					<InputBottomWrapperSCP>
						<InputBottomSCP />
					</InputBottomWrapperSCP>
				</WrapperSCP>
			</FlexCP>
		</ModalCP>
	)
}

const WrapperSCP = styled.div`
	width: 250px;
	margin-top: 20px;

	input {
		padding: 0;
		letter-spacing: 16px;
		font-size: 40px !important;
		border: none !important;
	}

	.ant-form-item-control {
		line-height: 0 !important;
	}
`

const InputBottomWrapperSCP = styled.div`
	height: 5px;
	overflow: hidden;
`

const InputBottomSCP = styled.div`
	border: 16px dashed ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
`
