import React from 'react'
import { RawHtmlCP } from 'submodules/nerit-framework-ui/common/components/raw-html/RawHtmlCP'
import styled from 'styled-components'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { IPaddingReportConfig } from 'submodules/beerads-sdk/services/clinics/medical-report-config/interfaces/IPaddingReportConfig'
import { IMarginConfig } from 'submodules/nerit-framework-utils/utils/interfaces/IMarginConfig'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO
	shouldHighlightContent: boolean
	margin?: IMarginConfig
}

/**
 */
export function PreviewMedicalReportConfigCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<PapperWrapperSCP>
				<SectionWrapperSCP
					margin={{
						top: props.margin?.top ?? 0,
						right: props.margin?.right ?? 0,
						left: props.margin?.left ?? 0,
						bottom: 0,
					}}
				>
					<RawHtmlCP html={props.medicalReportHtmls.headerHtml} />
				</SectionWrapperSCP>
				<SectionWrapperSCP
					margin={{
						bottom: props.margin?.bottom ?? 0,
						right: props.margin?.right ?? 0,
						left: props.margin?.left ?? 0,
						top: 0,
					}}
				>
					<RawHtmlCP html={props.medicalReportHtmls.footerHtml} />
				</SectionWrapperSCP>
			</PapperWrapperSCP>

			<MedicalReportContentSCP
				margin={props.medicalReportHtmls.margin}
				reportPadding={props.medicalReportHtmls.padding}
				shouldHighlightContent={props.shouldHighlightContent}
			>
				<SectionWrapperSCP margin={{ bottom: 0, right: props.margin?.right ?? 0, left: props.margin?.left ?? 0, top: 0 }}>
					<RawHtmlCP html={props.medicalReportHtmls.contentHtml} />
				</SectionWrapperSCP>
			</MedicalReportContentSCP>
		</WrapperSCP>
	)
}

const SectionWrapperSCP = styled.div<{ margin?: IMarginConfig }>`
	margin-left: ${(props) => props.margin?.left ?? 0}in;
	margin-right: ${(props) => props.margin?.right ?? 0}in;
	margin-bottom: ${(props) => props.margin?.bottom ?? 0}in;
	margin-top: ${(props) => props.margin?.top ?? 0}in;
`

const WrapperSCP = styled.div`
	color: ${ThemeFrameworkCommon.black};
	display: flex;
	justify-content: center;
	position: relative;
`

const PapperWrapperSCP = styled.div`
	width: 21cm;
	height: 29.7cm;

	padding: 0.2in 0; // Margem fixa superior e inferior do PDF

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background: ${ThemeFrameworkCommon.white};
	color: ${ThemeFrameworkCommon.black};
	border: 1px solid ${ThemeFrameworkCommon.black};
`

const MedicalReportContentSCP = styled.div<{
	reportPadding: IPaddingReportConfig
	margin: IMarginConfig
	shouldHighlightContent: boolean
}>`
	position: absolute;
	background: ${(props) => (props.shouldHighlightContent ? 'red' : undefined)};
	width: calc(21cm - 0.2in);
	height: calc(
		11.69in - ${(props) => props.reportPadding.header + props.reportPadding.footer + props.margin.bottom + props.margin.top - 0.8}in
	); // Altura desconsidera o padding superior e o inferior + o padrao do PDF
	margin-top: calc(
		${(props) => props.reportPadding.header + props.margin.top}in - 0.4in
	); // Margin para ser considerada do footer para o content (0.2 * 2 = margin superior padrao do PDF)

	font-size: 13px;
	line-height: 21px;

	padding: 0 0.1in; // Margem fixa lateral do PDF
`
