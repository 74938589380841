import { Upload } from 'antd'
import React, { useEffect, useState } from 'react'

import { RcFile } from 'antd/lib/upload/interface'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import profileImgDefault from 'submodules/nerit-framework-ui/common/assets/profile.png'
import styled from 'styled-components'

interface ICPProps {
	type: 'avatar' | 'orginal'
	currentData: {
		code: number
		imgUrl?: string
	}
	onSuccess?: () => void
	requestApiConfig: {
		requestApi: (code: number, formData: FormData) => RequestConfigTP
	}
	appearance?: {
		previewWidth?: string
		previewHeight?: string
	}
}

/**
 * Uploader de uma imagem
 */
export function UploaderImageFromApiCP(props: ICPProps): JSX.Element {
	const [imgFile, setImageFile] = useState<RcFile>()
	const [imgUrl, setImgUrl] = useState<string>(props.currentData.imgUrl ?? profileImgDefault)

	const uploadRequest = useRequest<void>(undefined, 'none')
	useEffect(onUploadRequestChange, [uploadRequest.isAwaiting])

	/**
	 * Handler antes ao receber imagem
	 */
	function beforeUpload(file: any) {
		const isImage = file.type.includes('image/')
		if (!isImage) {
			NotificationHelper.error('É aceito apenas arquivo de imagem para envio')
		}

		const isLt2M = file.size / 1024 / 1024 < 2
		if (!isLt2M) {
			NotificationHelper.error(`A Imagem deve ser menor que ${2}MB!`)
		}

		getBase64(file, (imageUrl) => {
			setImgUrl(imageUrl)
		})
		setImageFile(file)

		return isImage && isLt2M
	}

	/**
	 * Obtem bsae64 da imagem
	 */
	function getBase64(img: RcFile, callback: (result: any) => void): void {
		const reader = new FileReader()
		reader.addEventListener('load', () => callback(reader.result))
		reader.readAsDataURL(img)
	}

	/**
	 * Chama API para mudar imagem
	 */
	function changeImage() {
		if (!imgFile) return NotificationHelper.error('Ops', 'Não encontramos a imagem')

		const formData = new FormData()
		formData.append('file', imgFile)

		const requestConfigTP = props.requestApiConfig.requestApi(props.currentData.code, formData)
		requestConfigTP.avoidParamTransformer = true

		uploadRequest.runRequest(requestConfigTP)
	}

	/**
	 * Retorno ao fazer upload da imagem
	 */
	function onUploadRequestChange() {
		if (!RequestUtils.isValidRequestReturn(uploadRequest, 'Erro ao fazer upload', 'Upload realizado com sucesso')) return

		if (!!props.onSuccess) props.onSuccess()
	}

	return (
		<WrapperSCP previewWidth={props.appearance?.previewWidth} previewHeight={props.appearance?.previewHeight}>
			<Upload
				accept={'.jpg, .jpeg, .png'}
				name={'avatar'}
				listType={'picture-card'}
				className={props.type === 'avatar' ? 'avatar-uploader' : 'image-uploader'}
				showUploadList={false}
				beforeUpload={beforeUpload}
				disabled={uploadRequest.isAwaiting}
				customRequest={changeImage}
			>
				{uploadRequest.isAwaiting ? <LoadingCP /> : <div className={'avatar-profile'} style={{ backgroundImage: `url(${imgUrl})` }} />}
			</Upload>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ previewWidth?: string; previewHeight?: string }>`
	.avatar-uploader > .ant-upload {
		width: 120px !important;
		height: 120px !important;
		border-radius: 150px !important;
		margin-top: 30px !important;
		border: 4px solid #dddddd;
		transition: border-color 0.4s;

		.avatar-profile {
			width: 100%;
			height: 100%;
			border-radius: 100%;
			background-repeat: no-repeat;
			background-size: cover;
		}

		img {
			width: 100%;
			border-radius: 3.5rem !important;
		}
	}

	.image-uploader > .ant-upload {
		width: ${(props) => props.previewWidth ?? '100%'} !important;
		height: ${(props) => props.previewHeight ?? '300px'} !important;
		transition: border-color 0.4s;

		.avatar-profile {
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-size: contain;
		}

		img {
			width: 100%;
		}
	}

	.avatar-uploader {
		.ant-upload.ant-upload-select-picture-card:hover {
			border-color: var(--secondary-color) !important;
			//box-shadow: 0px 0px 23px 0px var(--secondary-color);
		}
	}
`
