import moment, { Moment } from 'moment'
import { ITotalByPeriod } from 'modules/reports/_old/interfaces/ITotalByPeriod'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

/**
 */
export class DashboardGraphUtils {
	private constructor() {}

	/** Gera & retorna lista de datas a serem usadas como labels do 'eixo x' do grafico. */
	static getXAxisDates(range: DateRangeSearchRequestDTO, timeBase: TimeBaseEnum): Moment[] {
		const dates: Moment[] = []
		const endDate = moment(range.endDate).endOf(timeBase)

		let nextDate = moment(range.beginDate).startOf(timeBase)

		while (nextDate < endDate) {
			dates.push(moment(nextDate))
			nextDate = moment(nextDate.add(1, timeBase))
		}

		return dates
	}

	/** Gera & retorna vetor de dados para 'eixo y' (serie) do grafico. */
	static getYAxisExamCounts(dates: Moment[], byPeriodList: ITotalByPeriod[]): number[] {
		return dates.map((date) => {
			const totalByPeriod = byPeriodList.find((period: ITotalByPeriod) => {
				const labelDateTime = date.startOf(TimeBaseEnum.DAY).toDate().getTime()
				const periodDateTime = moment(period.periodStart).startOf(TimeBaseEnum.DAY).toDate().getTime()
				return labelDateTime === periodDateTime
			})
			return !!totalByPeriod ? totalByPeriod.total : 0
		})
	}
}
