import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalMedicalReportTemplateCP } from 'modules/clinic/components/modal-medical-report-template/ModalMedicalReportTemplateCP'

interface ICPProps {
	clinicCode: number
	onSave: () => void
}

export function ButtonEditMedicalReportTemplateCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP marginLeft={10} onClick={() => setIsModalVisible(true)}>
				Editar
			</ButtonCP>

			<ModalMedicalReportTemplateCP
				clinicCode={props.clinicCode}
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				onSave={() => {
					setIsModalVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
