import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { MedicalReportConfigResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { MedicalReportConfigPreviewRequestDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/request/MedicalReportConfigPreviewRequestDTO'
import { MedicalReportConfigSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/request/MedicalReportConfigSaveRequestDTO'

export class MedicalReportConfigRequests {
	public static CONTROLLER_ROOT = 'medical-report-config'
	public static GET_EP = 'get/:clinicCode'
	public static PREVIEW_PDF_EP = 'preview-pdf/:clinicCode'
	public static PREVIEW_HTML_EP = 'preview-html/:clinicCode'
	public static UPDATE = 'update/:clinicCode'

	static get = (clinicCode: number): RequestConfigTP<MedicalReportConfigResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportConfigRequests.CONTROLLER_ROOT}/${MedicalReportConfigRequests.GET_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static previewPdf = (clinicCode: number, dto: MedicalReportConfigPreviewRequestDTO): RequestConfigTP<string> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportConfigRequests.CONTROLLER_ROOT}/${MedicalReportConfigRequests.PREVIEW_PDF_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static previewHtml = (clinicCode: number, dto: MedicalReportConfigPreviewRequestDTO): RequestConfigTP<MedicalReportConfigHtmlsResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportConfigRequests.CONTROLLER_ROOT}/${MedicalReportConfigRequests.PREVIEW_HTML_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static update = (clinicCode: number, dto: MedicalReportConfigSaveRequestDTO): RequestConfigTP<MedicalReportConfigHtmlsResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportConfigRequests.CONTROLLER_ROOT}/${MedicalReportConfigRequests.UPDATE.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})
}
