import React from 'react'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonMedicalTimetableUnassignCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-unassign/ButtonMedicalTimetableUnassignCP'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { AlertExtraDoctorOnMedicalTimetableCP } from 'modules/medical-timetable/components/alert-extra-doctor-on-medical-timetable/AlertExtraDoctorOnMedicalTimetableCP'
import { ButtonMedicalTimetableEditRecurrencyCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-edit-recurrency/ButtonMedicalTimetableEditRecurrencyCP'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonMedicalTimetableEditSpotCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-edit-spot/ButtonMedicalTimetableEditSpotCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

interface ICPProps {
	record: IMedicalTimetableRecord
	dayOfWeek: WeekDaysEnum
	beginWeekDate: Date
	recordIndex: number
	doctors: DoctorOnMedicalTimetableResponseDTO[]
	numberOfRows: number
	onAssign: () => void
}

/**
 */
export function DoctorOnMedicalTimetableCP(props: ICPProps): JSX.Element {
	const doctorOnMedicalTimetable = props.doctors[props.recordIndex]

	return (
		<>
			<FlexCP alignItems={'center'} justifyContent={'space-between'}>
				<div>
					{doctorOnMedicalTimetable.doctor.name}
					{!!doctorOnMedicalTimetable.hourRange && (
						<p>
							<i>{`${doctorOnMedicalTimetable.hourRange.beginHour} às ${doctorOnMedicalTimetable.hourRange.endHour}`}</i>
						</p>
					)}
					{!!doctorOnMedicalTimetable.extraValue && (
						<ValueWapperSCP>
							{MaskUtils.applyMoneyMask(doctorOnMedicalTimetable.extraValue)}
							{!!doctorOnMedicalTimetable.shouldKeepDynamicValue && ' + variável'}
						</ValueWapperSCP>
					)}
				</div>

				<WrapperSCP>
					{!!doctorOnMedicalTimetable.description && (
						<CommentButtonWrapperSCP>
							<ButtonCP icon={'message'} tooltip={doctorOnMedicalTimetable.description} size={'small'} marginBottom={5} />
						</CommentButtonWrapperSCP>
					)}

					<ButtonMedicalTimetableEditSpotCP
						dayOfWeek={props.dayOfWeek}
						doctorCode={doctorOnMedicalTimetable.doctor.code}
						doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
						groupBy={props.record.groupBy}
						id={props.record.id}
						modality={props.record.modality}
						period={props.record.period}
						onSave={props.onAssign}
						date={DateUtils.add(props.beginWeekDate, props.dayOfWeek, TimeBaseEnum.DAY)}
						isRecurrent={props.doctors[props.recordIndex]?.isRecurrent}
					/>

					<ButtonMedicalTimetableEditRecurrencyCP
						dayOfWeek={props.dayOfWeek}
						doctorCode={doctorOnMedicalTimetable.doctor.code}
						doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
						groupBy={props.record.groupBy}
						id={props.record.id}
						modality={props.record.modality}
						period={props.record.period}
						onSave={props.onAssign}
						date={DateUtils.add(props.beginWeekDate, props.dayOfWeek, TimeBaseEnum.DAY)}
						isRecurrent={props.doctors[props.recordIndex]?.isRecurrent}
					/>
					<ButtonMedicalTimetableUnassignCP
						dayOfWeek={props.dayOfWeek}
						doctorCode={doctorOnMedicalTimetable.doctor.code}
						doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
						groupBy={props.record.groupBy}
						id={props.record.id}
						modality={props.record.modality}
						period={props.record.period}
						onSave={props.onAssign}
						isRecurrent={props.doctors[props.recordIndex]?.isRecurrent}
						date={DateUtils.add(props.beginWeekDate, props.dayOfWeek, TimeBaseEnum.DAY)}
					/>
				</WrapperSCP>
			</FlexCP>
			{
				// Caso seja a ultima linha e possua mais medicos do que ja exibido, mostra o alerta
				props.recordIndex + 1 === props.numberOfRows && props.doctors.length > props.numberOfRows && (
					<AlertExtraDoctorOnMedicalTimetableCP doctors={props.doctors} numberOfRows={props.numberOfRows} />
				)
			}
		</>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 5px;
`

const CommentButtonWrapperSCP = styled.div`
	button {
		cursor: help;
	}
`

const ValueWapperSCP = styled.i`
	color: #008204;
	font-weight: 500;
`
