import React from 'react'

import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'

interface IFullscreenControlButtonICPProps {
	setFullScreenMode: (isFullScreen: boolean) => void
	isFullScreen: boolean
	iconColor: string
	iconSize: number
}

/**
 * Botao de ativacao/desativacao do modo 'tela cheia' do editor wysiwyg / Draft JS customizado.
 */
export const FullscreenControlButtonICP = (props: IFullscreenControlButtonICPProps): JSX.Element => {
	return (
		<ToolbarButtonICP
			innerContent={<IconICP iconName={props.isFullScreen ? 'fullscreen-exit' : 'fullscreen'} size={props.iconSize} color={props.iconColor} />}
			onClick={() => props.setFullScreenMode(!props.isFullScreen)}
			isActive={() => props.isFullScreen}
		/>
	)
}
