import React from 'react'
import { TemplateTypeEnum, TemplateTypeLabelEnum } from 'submodules/beerads-sdk/services/templates/enums/TemplateTypeEnum'
import { PhraseIconCP } from '_old/main/common/components/icons/PhraseIconCP'
import { TemplateIconCP } from '_old/main/common/components/icons/TemplateIconCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import styled from 'styled-components'

interface ICPProps {
	type: TemplateTypeEnum
	onChangeType: (selectedType: TemplateTypeEnum) => void
	onAdd: () => void
}

/**
 * Header da tela de templates.
 */
export function HeaderTemplateCP(props: ICPProps): JSX.Element {
	return (
		<HeaderCP title={`${props.type === TemplateTypeEnum.TEMPLATE ? 'Meus Templates' : 'Minhas Frases de Laudo'}`}>
			<FlexCP alignItems={'center'}>
				<RadioGroupCP<TemplateTypeEnum>
					onChange={props.onChangeType}
					selected={props.type}
					type={'button'}
					paddingTop={0}
					options={[
						{
							value: TemplateTypeEnum.PHRASE,
							content: (
								<RadioItemContentSCP>
									<PhraseIconCP />
									Frases de Laudo
								</RadioItemContentSCP>
							),
						},
						{
							value: TemplateTypeEnum.TEMPLATE,
							content: (
								<RadioItemContentSCP>
									<TemplateIconCP />
									Templates
								</RadioItemContentSCP>
							),
						},
					]}
				/>

				<ButtonCP marginLeft={15} icon={'plus'} onClick={props.onAdd}>
					{`Criar ${TemplateTypeLabelEnum[props.type]}`}
				</ButtonCP>
			</FlexCP>
		</HeaderCP>
	)
}

const RadioItemContentSCP = styled.span`
	display: flex;
	align-items: center;
	i {
		font-size: 18px;
		margin-right: 5px;
	}
`
