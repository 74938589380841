import { EditorState, Modifier, SelectionState } from 'draft-js'
import getSearchText from 'draft-js-autocomplete-plugin-creator/src/utils/getSearchText'
import findWithRegex from 'find-with-regex'
import { IAutocompleteOption } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/inner/IAutocompleteOption'

/**
 * UTILS
 * Métodos auxiliares para a geração do plugin autocomplete
 * @author Lucas Rosa
 */
export class AutocompletePluginUtils {
	/** Caractere que dispara o autocomplete. */
	static TRIGGER_KEY = '@'

	/** Gera o regex do decorator do plugin. */
	static getStrategy() {
		const regex = new RegExp(`(\\s|^)${this.TRIGGER_KEY}[^\\s]*`, 'g')
		return (contentBlock, callBack) => findWithRegex(regex, contentBlock, callBack)
	}

	/**
	 * Modifica texto do editor quando a frase é selecionada.
	 * Valor da chave acompanhado por '@' é substituído pelo seu valor
	 * @param editorState
	 * @param updateValue
	 */
	static getAutocompleteReplacer(editorState: EditorState, updateValue: IAutocompleteOption) {
		const currentSelection = editorState.getSelection()
		const inlineStyle = editorState.getCurrentInlineStyle()
		const { begin, end } = getSearchText(editorState, currentSelection)
		const issueTextSelection = currentSelection.merge({
			anchorOffset: begin,
			focusOffset: end,
		}) as SelectionState
		const issueReplacedContent = Modifier.replaceText(editorState.getCurrentContent(), issueTextSelection, `${updateValue.content}`, inlineStyle)
		const newEditorState = EditorState.push(editorState, issueReplacedContent, 'insert-fragment')

		return EditorState.forceSelection(newEditorState, issueReplacedContent.getSelectionAfter())
	}
}
