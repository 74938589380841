import React from 'react'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { RoutingHelper } from 'config/RoutingHelper'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	viewAsDoctor: boolean
	clinicCode?: number
}

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function WidgetCountExamsAssignedCP(props: ICPProps): JSX.Element {
	const filtersDto: ExamSearchRequestDTO = {
		viewAsDoctor: props.viewAsDoctor,
		assigned: ExamAssignedFilterEnum.YES,
		clinicCodes: !!props.clinicCode ? [props.clinicCode] : undefined,
		statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.AWAITING),
		doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
	}

	return (
		<CardWidgetOnlyValuesCP
			onClick={() => RoutingHelper.goToExams(ExamStatusGroupEnum.AWAITING, filtersDto)}
			requestConfigGetter={WidgetsRequests.countExams(filtersDto)}
			main={{
				title: 'Exames Atribuídos',
				help: 'Número de exames atribuídos diretamente para um médico',
				isZeroTarget: true,
			}}
		/>
	)
}
