export enum RISPartnersEnum {
	SPDATA = 'SPDATA',
	CLINUX = 'CLINUX',
	SMARTRIS = 'SMARTRIS',
	TASY = 'TASY',
	MPS = 'MPS',
	CFAZ = 'CFAZ',
	FUJI = 'FUJI',
	XCLINIC = 'XCLINIC', //Pixeon
	ANIMATI_NETPACS = 'ANIMATI_NETPACS',
	ORIZONTI_CUSTOM = 'ORIZONTI_CUSTOM', // Integracao customizada Hosp. Orizonti.
	ADVENTISTA_SILVESTRE_CUSTOM = 'ADVENTISTA_SILVESTRE_CUSTOM', // Integracao customizada Adv Silvestre. API foi criada pelo Juliano - 21 98214-9719: (Ti do Hosptal)
	YGIA = 'YGIA',
	PIXEON = 'PIXEON',
	VIVACE_MV = 'VIVACE_MV',
	WTT = 'WTT',
	FSFX_CUSTOM = 'FSFX_CUSTOM',
	PIXEON_SMART_AURORA = 'PIXEON_SMART_AURORA',
	MEDCLOUD = 'MEDCLOUD',
	MOBILEMED = 'MOBILEMED',
	ANIMATI_PACS = 'ANIMATI_PACS',
	RAIOSS = 'RAIOSS',
	POLYGON = 'POLYGON',
}

export enum RISPartnersEnumLabel {
	SPDATA = 'SPDATA',
	CLINUX = 'CLINUX',
	SMARTRIS = 'SMARTRIS',
	TASY = 'TASY',
	MPS = 'MPS',
	CFAZ = 'CFAZ',
	FUJI = 'FUJI',
	XCLINIC = 'XCLINIC',
	NETPACS = 'NETPACS',
	ORIZONTI_CUSTOM = 'ORIZONTI_CUSTOM',
	ADVENTISTA_SILVESTRE_CUSTOM = 'ADVENTISTA_SILVESTRE_CUSTOM',
	YGIA = 'YGIA',
	PIXEON = 'PIXEON',
	VIVACE_MV = 'VIVACE_MV',
	WTT = 'WTT',
	FSFX_CUSTOM = 'FSFX_CUSTOM',
	PIXEON_SMART_AURORA = 'PIXEON_SMART_AURORA',
	MEDCLOUD = 'MEDCLOUD',
	MOBILEMED = 'MOBILEMED',
	ANIMATI = 'ANIMATI',
	RAIOSS = 'RAIOSS',
	POLYGON = 'POLYGON',
}
