import React from 'react'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'

interface ICPProps {
	reportType: ReportTypeEnum
	shouldLoad: number
	formStateManager: IFormStateManager<CommonReportsFilterFormModel>
}

/**
 */
export function WidgetRectificationAnalysisCP(props: ICPProps): JSX.Element {
	return (
		<WidgetsRowCP margin={{ top: 20, right: 10, left: 10 }}>
			<CardWidgetOnlyValuesShouldLoadCP
				main={{
					title: 'Laudos Conformes',
					valueMask: '%',
					help: 'Percentual de laudos retificados no mês pelo volume de laudos emitidos no mês',
				}}
				shouldLoad={props.shouldLoad}
				style={{ maxWidth: '300px' }}
				requestConfigGetter={WidgetsRequests.medicalReportRectificationSummary(
					CommonReportsFilterFormModelConverter.formatRectificationDto(props.reportType, props.formStateManager.getFormValues()),
				)}
			/>

			<CardWidgetOnlyValuesShouldLoadCP
				main={{
					title: 'Taxa de Impacto Clínico',
					valueMask: '%',
					help: 'Percentual de retificações em relação ao total de laudos.',
				}}
				shouldLoad={props.shouldLoad}
				style={{ maxWidth: '300px' }}
				requestConfigGetter={WidgetsRequests.medicalReportRectificationClinicalImpact(
					CommonReportsFilterFormModelConverter.formatRectificationDto(props.reportType, props.formStateManager.getFormValues()),
				)}
			/>
		</WidgetsRowCP>
	)
}
