import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { FormExamNoteCP } from 'modules/exams/components/exam-attachments/form-exam-note/FormExamNoteCP'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ExamNoteResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/ExamNoteResponseDTO'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { DividerCP } from 'submodules/nerit-framework-ui/common/components/divider/DividerCP'
import { CommentCP } from 'submodules/nerit-framework-ui/common/components/comment/CommentCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import styled from 'styled-components'

interface ICPProps {
	examCode: number
	permissions?: {
		canAdd: boolean
	}
}

/**
 * Notas de um Exame
 */
export function ExamNotesCP(props: ICPProps): JSX.Element {
	const [notes, setNotes] = useState<ExamNoteResponseDTO[]>()
	const searchNotesRequest = useRequest<ListResponseDTO<ExamNoteResponseDTO>>()
	useEffect(onSearchNotesRequestChange, [searchNotesRequest.isAwaiting])

	const deleteNote = useRequest<void>('none')
	useEffect(onDeleteNoteRequestChange, [deleteNote.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa
	 */
	function init(): void {
		searchNotesRequest.runRequest(ExamAttachmentsRequests.searchNotes(props.examCode))
	}

	/**
	 * Retorno da busca.
	 */
	function onSearchNotesRequestChange() {
		if (!RequestUtils.isValidRequestReturn(searchNotesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setNotes(searchNotesRequest.responseData?.list)
	}

	/**
	 * Retorno de remocao.
	 */
	function onDeleteNoteRequestChange() {
		if (
			!RequestUtils.isValidRequestReturn(
				deleteNote,
				NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE,
				true,
			)
		)
			return

		init()
	}

	return (
		<NotesDrawerContentSCP>
			<LoadingOverlayCP show={searchNotesRequest.isAwaiting} />

			{ArrayUtils.isEmpty(notes) ? (
				<EmptyCP description={'Nenhuma nota encontrada'} />
			) : (
				notes?.map((note) => (
					<CommentCP
						key={note.code}
						comment={{
							text: note.text,
							user: note.user,
							date: note.createdAt,
						}}
						deleteComment={{
							onDelete: () => deleteNote.runRequest(ExamAttachmentsRequests.deleteNote(note.code)),
							showDeleteButton: AppStateUtils.getLoggedUser()!.code === note.user.code,
						}}
					/>
				))
			)}

			{(!props.permissions || props.permissions.canAdd) && (
				<>
					<DividerCP text={'Insira uma nova nota no exame'} placement={'left'} margin={{ top: 30 }} />
					<FormExamNoteCP examCode={props.examCode} onAddNote={init} />
				</>
			)}
		</NotesDrawerContentSCP>
	)
}

const NotesDrawerContentSCP = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
`
