import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'

interface ICPProps {
	pdfSrc: string
	zoomOption?: 'page-width' | 'page-fit'
	height?: string
}

export function PdfNativeViewer(props: ICPProps): JSX.Element {
	return <object data={props.pdfSrc} type={MimeTypeEnum.PDF} width={'100%'} height={props.height ?? '800px'} />
}
