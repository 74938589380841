import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { AddDoctorGroupToClinicRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/AddDoctorGroupToClinicRequestDTO'
import { AddUserToClinicRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/AddUserToClinicRequestDTO'
import { ClinicNamesRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicNamesRequestDTO'
import { ClinicNotificationConfigSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicNotificationConfigSaveRequestDTO'
import { ClinicSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicSaveRequestDTO'
import { ClinicsExceededExamResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicsExceededExamResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ClinicNamesResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicNamesResponseDTO'
import { ClinicDoctorGroupsNamesResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicDoctorGroupsNamesResponseDTO'
import { ClinicNotificationConfigResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicNotificationConfigResponseDTO'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ClinicUpdateTechConfigRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicUpdateTechConfigRequestDTO'

export class ClinicsRequests {
	public static CONTROLLER_ROOT = 'clinics'
	public static SEARCH_EP = 'search'
	public static COUNT_CLINICS_IN_GROUP_EP = 'count-clinics-in-group'
	public static NAMES_EP = 'names'
	public static NAMES_CLINIC_DOCTOR_GROUPS_EP = 'names-clinic-doctorgroups'
	public static NAMES_DOCTOR_GROUPS_EP = 'names-doctorgroups/:clinicCode'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static GET_EP = 'get/:code'
	public static GET_BY_CNPJ_EP = 'get-by-cnpj/:cnpj'
	public static SAVE_LOGO_EP = 'save-logo/:code'
	public static SAVE_SYSTEM_LOGO_EP = 'save-system-logo/:code'
	public static LINK_CLINIC_TO_DOCTOR_GROUP_EP = 'link-clinic-to-doctorgroup'
	public static LINK_CLINIC_TO_USER_EP = 'link-clinic-to-user'
	public static UNLINK_CLINIC_AND_PERSON_EP = 'unlink-clinic-and-person/:code'
	public static SEARCH_CLINICS_EXCEED_EXAMS_EP = 'search-clinics-exceed-exams'
	public static GET_NOTIFICATION_CONFIG_EP = 'notification-config/:code'
	public static SAVE_NOTIFICATION_CONFIG_EP = 'medical-report-config/:code'
	public static NAMES_REQUESTING_DOCTOR_EP = 'names-requesting-doctor/:clinicCode'
	public static NAMES_EXTRA_INFO_EP = 'names-extra-info/:clinicCode'
	public static NAMES_INTERNAL_CLINIC_EP = 'names-internal-clinic/:clinicCode'
	public static UPDATE_TECH_CONFIG_EP = 'update/tech-config/:clinicCode'

	static search = (): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
	})

	static searchClinicsExceedExams = (): RequestConfigTP<ClinicsExceededExamResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.SEARCH_CLINICS_EXCEED_EXAMS_EP}`,
		method: HttpMethodEnum.GET,
	})

	static names = (dto: ClinicNamesRequestDTO): RequestConfigTP<ClinicNamesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static namesDoctorGroups = (clinicCode: number): RequestConfigTP<DoctorGroupNamesFromClinicResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_DOCTOR_GROUPS_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static namesClinicDoctorGroups = (): RequestConfigTP<ClinicDoctorGroupsNamesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_CLINIC_DOCTOR_GROUPS_EP}`,
		method: HttpMethodEnum.GET,
	})

	static create = (dto: ClinicSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: ClinicSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static updateTechConfig = (clinicCode: number, dto: ClinicUpdateTechConfigRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.UPDATE_TECH_CONFIG_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static get = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.GET_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static getByCnpj = (cnpj: string): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.GET_BY_CNPJ_EP.replace(':cnpj', cnpj.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static linkClinicToDoctorGroup = (dto: AddDoctorGroupToClinicRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.LINK_CLINIC_TO_DOCTOR_GROUP_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static linkClinicToUser = (dto: AddUserToClinicRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.LINK_CLINIC_TO_USER_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static getNotificationConfig = (clinicCode: number): RequestConfigTP<ClinicNotificationConfigResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.GET_NOTIFICATION_CONFIG_EP.replace(':code', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static saveNotificationConfig = (clinicCode: number, dto: ClinicNotificationConfigSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.SAVE_NOTIFICATION_CONFIG_EP.replace(':code', clinicCode.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static saveLogo = (code: number, formData: any): RequestConfigTP => {
		return {
			baseUrlType: BeeRadsApiEnum.BEERADS_API,
			url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.SAVE_LOGO_EP.replace(':code', code.toString())}`,
			method: HttpMethodEnum.PUT,
			params: formData,
			headers: [
				{
					headerName: 'Content-Type',
					headerValue: 'multipart/form-data',
				},
			],
		}
	}

	static saveSystemLogo = (code: number, formData: any): RequestConfigTP => {
		return {
			baseUrlType: BeeRadsApiEnum.BEERADS_API,
			url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.SAVE_SYSTEM_LOGO_EP.replace(':code', code.toString())}`,
			method: HttpMethodEnum.PUT,
			params: formData,
			headers: [
				{
					headerName: 'Content-Type',
					headerValue: 'multipart/form-data',
				},
			],
		}
	}

	static namesRequestingDoctor = (clinicCode: number): RequestConfigTP<ListResponseDTO<string>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_REQUESTING_DOCTOR_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static namesExtraInfo = (clinicCode: number): RequestConfigTP<ListResponseDTO<string>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_EXTRA_INFO_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static namesInternalClinic = (clinicCode: number): RequestConfigTP<ListResponseDTO<string>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicsRequests.CONTROLLER_ROOT}/${ClinicsRequests.NAMES_INTERNAL_CLINIC_EP.replace(':clinicCode', clinicCode.toString())}`,
		method: HttpMethodEnum.GET,
	})
}
