import React, { useEffect, useState } from 'react'
import { ContentCP } from '_old/main/common/components/screen-layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ScreenContentProfileDoctor } from 'modules/person/screens/screen-profile/content/ScreenContentProfileDoctor'
import { ScreenContentProfileUser } from 'modules/person/screens/screen-profile/content/ScreenContentProfileUser'
import { ModalChangePasswordCP } from 'submodules/nerit-framework-ui/features/user/components/change-password-modal/ModalChangePasswordCP'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 * Tela de perfil para os diferentes tipos de usuário.
 */
export function ScreenProfile(): JSX.Element {
	const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState<boolean>(false)

	const [person, setPerson] = useState<PersonResponseDTO>()
	const getPersonRequest = useRequest<PersonResponseDTO>()

	useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		getPersonRequest.runRequest(PeopleRequests.getOne(AppStateUtils.getLoggedUser()!.code))
	}

	/**
	 * Retorno da requisicao para pegar agenda passada como parametro
	 */
	function onGetPersonRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getPersonRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setPerson(getPersonRequest.responseData)
	}

	if (!person || getPersonRequest.isAwaiting) {
		return <LoadingOverlayCP show={true} />
	}

	return (
		<LayoutCP
			titleBrowser={`Perfil - ${StringUtils.getFirstName(person.name)}`}
			content={
				<ContentCP style={{ flexShrink: 0 }}>
					{AppStateUtils.getLoggedUser()!.isDoctor ? (
						<ScreenContentProfileDoctor
							person={person}
							onClickChangePassword={() => setIsModalChangePasswordVisible(true)}
							onChangeData={init}
						/>
					) : (
						<ScreenContentProfileUser
							person={person}
							onClickChangePassword={() => setIsModalChangePasswordVisible(true)}
							onChangeData={init}
						/>
					)}

					<ModalChangePasswordCP
						visible={isModalChangePasswordVisible}
						onClose={() => setIsModalChangePasswordVisible(false)}
						userCode={AppStateUtils.getLoggedUser()!.code}
						requestConfig={AuthRequests.changePassword}
					/>
				</ContentCP>
			}
		/>
	)
}
