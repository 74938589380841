import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { UserSetPasswordResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/user/dtos/response/UserSetPasswordResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

interface ICPProps {
	visible: boolean
	onClose: () => void
	requestConfig: () => RequestConfigTP
}

/**
 */
export function ModalSetPasswordCP(props: ICPProps): JSX.Element {
	const [generatedPassword, setGeneratedPassword] = useState<string>()

	const setPasswordRequest = useRequest<UserSetPasswordResponseDTO>()
	useEffect(onSetPasswordRequestChange, [setPasswordRequest.isAwaiting])

	/**
	 */
	function onSetPasswordRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(setPasswordRequest, 'Não foi possível redefinir a senha do usuário', 'Senha redefinida com sucesso!'))
			return

		setGeneratedPassword(setPasswordRequest.responseData?.newPassword)
	}

	return (
		<ModalCP
			title={'Redefinir senha do usuário'}
			visible={props.visible}
			noFooter={true}
			onCancel={props.onClose}
			actionLoading={setPasswordRequest.isAwaiting}
			width={400}
		>
			{!generatedPassword ? (
				<AlertCP message={'Atenção! Ao redefinir a senha do usuário, a senha atual será invalidada!'} type={'warning'} />
			) : (
				<AlertCP message={'Pronto! Senha redefinida com sucesso, clique para copiar a senha!'} type={'info'} />
			)}

			<FlexCP justifyContent={'center'} margin={{ top: 30 }}>
				{!!generatedPassword ? (
					<TextCP text={generatedPassword} copyable={true} size={'extraLarge'} />
				) : (
					<ButtonCP
						type={'primary'}
						confirmMsg={'Você tem certeza que deseja redefinir a senha do usuário? Essa ação não pode ser desfeita!'}
						onClick={() => setPasswordRequest.runRequest(props.requestConfig())}
						loading={setPasswordRequest.isAwaiting}
					>
						Redefinir senha do usuário
					</ButtonCP>
				)}
			</FlexCP>
		</ModalCP>
	)
}
