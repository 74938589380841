import React, { useEffect } from 'react'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'

interface ICPProps {
	exam: ExamResponseDTO
	onSave: () => void
}

export function ButtonSendToAwaitingCP(props: ICPProps): JSX.Element {
	const sendToAwaitingRequest = useRequest<void>('none')
	useEffect(onSendToAwaiting, [sendToAwaitingRequest.isAwaiting])

	function sendToAwaiting(): void {
		sendToAwaitingRequest.runRequest(ExamsAnalysisRequests.sendToAwaiting(props.exam.code))
	}

	function onSendToAwaiting(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				sendToAwaitingRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ButtonCP type={'default'} size={'small'} onClick={sendToAwaiting}>
			Devolver para fila de laudos
		</ButtonCP>
	)
}
