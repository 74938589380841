import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IDrawerFooterICPProps {
	footerSpaced?: boolean
	children: JSX.Element | JSX.Element[]
}

/**
 * COMPONENTE para rodape de drawer
 */
export function DrawerFooterICP(props: IDrawerFooterICPProps): JSX.Element {
	return (
		<>
			<FooterDividerSCP />
			<FooterSCP footerSpaced={props.footerSpaced}>{props.children}</FooterSCP>
		</>
	)
}

const FooterDividerSCP = styled.div`
	height: 30px;
`

const FooterSCP = styled.div<{ footerSpaced?: boolean }>`
	border-top: solid 2px #eeeeee;
	display: flex;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 15px;
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	justify-content: ${(props) => (props.footerSpaced ? 'space-between' : 'flex-end')};
	align-items: center;
`
