import React, { useEffect, useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ChartApexCP } from 'submodules/nerit-framework-ui/common/components/chart/ChartApexCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { ChartSeriesTP } from 'submodules/nerit-framework-ui/common/components/chart/ChartLineOrBarCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamMonthlyGrowthComparativeResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamMonthlyGrowthComparativeResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { ExamMonthlyGrowthComparativeRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamMonthlyGrowthComparativeRequestDTO'

interface ICPProps {
	reportType: ReportTypeEnum
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
	load: number
}

/**
 */
export function ChartTrendGrowthAnalysisCP(props: ICPProps): JSX.Element {
	const [series, setSeries] = useState<ChartSeriesTP[]>()
	const [categories, setCategories] = useState<string[]>()

	const [loadedData, setLoadedData] = useState<ExamMonthlyGrowthComparativeResponseDTO[]>()
	const graphDataRequest = useRequest<ListResponseDTO<ExamMonthlyGrowthComparativeResponseDTO>>()
	useEffect(onGraphDataRequestChange, [graphDataRequest.isAwaiting])

	useEffect(requestGraphData, [props.load])

	/**
	 */
	function requestGraphData(): void {
		if (!props.load) return

		const formValues = props.filterStateManager.getFormValues()!
		const filters: ExamMonthlyGrowthComparativeRequestDTO = {
			reportType: props.reportType,
			dateRange: formValues.dateRange,
			clinicCodes: formValues.clinicCodes,
		}
		graphDataRequest.runRequest(DashboardRequests.monthlyGrowthComparative(filters))
	}

	/**
	 */
	function onGraphDataRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(graphDataRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = graphDataRequest.responseData!.list
		setLoadedData(result)
		setSeries([
			{
				name: 'Até ontem (realizado)',
				data: result.map((mock) => mock.valueUntilYesterday),
			},
			{
				name: 'Até o final do mês (projetado)',
				data: result.map((mock) => mock.valueFromToday),
			},
		])
		setCategories(result.map((mock) => DateUtils.getFormatted(mock.period, DateFormatEnum.BR_MONTH_YEAR)))
	}

	/**
	 */
	function getSerieData(opts: any): ExamMonthlyGrowthComparativeResponseDTO | undefined {
		const dateStrXAxis = opts.w.globals.labels[opts.dataPointIndex]

		// Obtem a serie nos dados retornados
		return loadedData?.find((f) => dateStrXAxis === DateUtils.formatDate(f.period, DateFormatEnum.BR_MONTH_YEAR))
	}

	if (!series || !loadedData || !categories) return <LoadingOverlayCP show={true} />

	return (
		<CardCP>
			<ChartApexCP
				options={{
					series: series,
					xaxis: {
						categories: categories,
					},
					chart: {
						height: 350,
						type: 'bar',
						stacked: true,
						toolbar: {
							tools: {
								zoom: false,
								reset: false,
								pan: false,
							},
						},
					},
					title: {
						text: 'Comparativo Mensal de Exames',
					},
					dataLabels: {
						formatter: (value, opts) => {
							// Obtem a serie nos dados retornados
							const foundData = getSerieData(opts)
							if (!foundData) return 'Erro valor!'

							let growthRate
							if (opts.seriesIndex === 0) growthRate = foundData.growthRateUntilYesterday
							else growthRate = foundData.growthRateFromToday

							return `${MaskUtils.applyNumberMask(Number(value), 0)} (${MaskUtils.applyNumberMask(growthRate * 100, 2)}%)`
						},
					},
					plotOptions: {
						bar: {
							horizontal: false,
							borderRadius: 10,
							dataLabels: {
								total: {
									formatter: (value, opts) => {
										// Obtem a serie nos dados retornados
										const foundData = getSerieData(opts)
										if (!foundData) return 'Erro valor!'

										return `${MaskUtils.applyNumberMask(Number(value), 0)} (${MaskUtils.applyNumberMask(
											foundData.monthGrowthRate * 100,
											2,
										)}%)`
									},
									enabled: true,
									style: { fontSize: '13px', fontWeight: 900 },
								},
							},
						},
					},
					yaxis: [
						{
							title: {
								text: 'Nº de Exames',
							},
						},
					],
				}}
			/>
		</CardCP>
	)
}
