import React, { useEffect } from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalReportSaveAsDraftRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportSaveAsDraftRequestDTO'
import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	onSave: () => void
	text?: string
}

/**
 */
export function ButtonSaveMedicalReportAsDraftCP(props: ICPProps): JSX.Element {
	const saveAsDraftReportRequest = useRequest<'void'>('none')
	useEffect(onSaveAsDraftRequestChange, [saveAsDraftReportRequest.isAwaiting])

	/**
	 * Salva o laudo
	 */
	function saveAsDraft(): void {
		if (!FormStateManagerUtils.validateRequiredFields(props.formStateManager)) return

		const formValues = props.formStateManager.getFormValues()!
		const dto: MedicalReportSaveAsDraftRequestDTO = {
			examCode: props.exam.code,
			medicalReportsContents: formValues.medicalReports,
		}
		saveAsDraftReportRequest.runRequest(MedicalReportRequests.saveAsDraft(dto))
	}

	/**
	 * Retorno api.
	 */
	function onSaveAsDraftRequestChange(): void {
		// Avalia retorno da ultima requisicao
		if (
			!RequestUtils.isValidRequestReturn(
				saveAsDraftReportRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		MedicalReportLocalStorageUtil.discardMedicalReportLocalStorage(props.exam.code)
		props.onSave()
	}

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<ButtonCP onClick={saveAsDraft} type={'primary'} loading={saveAsDraftReportRequest.isAwaiting} icon={'save'}>
				{props.text ?? 'Salvar Rascunho'}
			</ButtonCP>
		</BasicStyleWrapperCP>
	)
}
