import React, { useState } from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import 'photoviewer/dist/photoviewer.min.css'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ModalRecordedVideoCP } from 'modules/quality-assurance/components/modal-recorded-video/ModalRecordedVideoCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { MedicalReportVideoFileResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/MedicalReportVideoFileResponseDTO'

interface ICPProps {
	medicalReportVideo: MedicalReportVideoFileResponseDTO
	onFileDeleted: () => void
	doctorGroupCode: number
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ListItemMedicalReportVideoICP(props: ICPProps): JSX.Element {
	const [showVideoModal, setShowVideoModal] = useState(false)

	const formattedCreationDate = DateUtils.getFormatted(props.medicalReportVideo.creationDate, DateFormatEnum.BR_WITHOUT_TIME)
	const userFirstName = StringUtils.getFirstName(props.medicalReportVideo.userCreator.name)

	return (
		<>
			<FlexCP justifyContent={'space-between'} alignItems={'center'} margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>
				<FlexCP alignItems={'center'} onClick={() => setShowVideoModal(true)}>
					<IconICP iconName={'play-circle'} style={{ marginRight: '10px' }} size={24} />
					<div>
						<TextCP text={StringUtils.limitString(props.medicalReportVideo.fileName, 25)} />
						<TextCP
							text={`enviado em ${formattedCreationDate} por ${userFirstName}`}
							marginRight={10}
							fontStyle={'italic'}
							size={'extraSmall'}
							color={ThemeProject.gray300}
						/>
					</div>
				</FlexCP>

				<div>
					<ButtonCP size={'small'} type={'primary'} icon={'eye'} onClick={() => setShowVideoModal(true)} />

					<AccessControlCP
						permission={{
							byHasAnyPermissionInDoctorGroup: {
								doctorGroupCode: props.doctorGroupCode,
								permissions: ['isAuditor'],
							},
						}}
					>
						<ButtonDeleteRecordCP
							onDelete={props.onFileDeleted}
							requestConfigTP={() => ExamAttachmentsRequests.deleteMedicalReportVideo(props.medicalReportVideo.fileCode)}
						/>
					</AccessControlCP>
				</div>
			</FlexCP>

			<ModalRecordedVideoCP
				visible={showVideoModal}
				onClose={() => setShowVideoModal(false)}
				examId={props.medicalReportVideo.fileCode.toString() ?? ''}
				medicalReportVideo={props.medicalReportVideo}
			/>
		</>
	)
}
