import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'

export type RadioGroupMedicalTimetableConfigTP = 'week' | 'default'

interface ICPProps {
	selected: RadioGroupMedicalTimetableConfigTP
	onChange: (selected: RadioGroupMedicalTimetableConfigTP) => void
}

/**
 */
export function RadioGroupMedicalTimetableConfigTypeCP(props: ICPProps): JSX.Element {
	return (
		<RadioGroupCP<RadioGroupMedicalTimetableConfigTP>
			type={'button'}
			paddingTop={0}
			buttonWidth={200}
			selected={props.selected}
			onChange={props.onChange}
			options={[
				{
					value: 'week',
					content: 'Ajustar uma semana',
				},
				{
					value: 'default',
					content: 'Ajustar escala padrão',
				},
			]}
		/>
	)
}
