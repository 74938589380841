import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { SelectMultipleDoctorViewCP } from 'modules/person/components/doctor/select-multiple-doctor-view/SelectMultipleDoctorViewCP'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ButtonCreateExam } from 'modules/exams/components/exams/button-create-exam/ButtonCreateExam'

interface ICPProps {
	examStatusGroup?: ExamStatusGroupEnum
	filterStateManager: IFormStateManager<ExamFilterFormModel>
	onRequestToReloadList: () => void
}

/**
 */
export function HeaderScreenExamsCP(props: ICPProps): JSX.Element {
	return (
		<HeaderCP title={'Lista de Exames'}>
			{props.examStatusGroup === ExamStatusGroupEnum.PENDING && <ButtonCreateExam onCreateExam={props.onRequestToReloadList} />}

			<SelectMultipleDoctorViewCP
				value={props.filterStateManager.getFieldValue('viewAsDoctor') ? UserTypeEnum.DOCTOR : UserTypeEnum.USER}
				onSelect={(selected) => {
					props.filterStateManager.changeFieldValue('viewAsDoctor', selected === UserTypeEnum.DOCTOR)
					props.onRequestToReloadList()
				}}
			/>
		</HeaderCP>
	)
}
