import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import styled from 'styled-components'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { DateUtils } from '_old/main/common/utils/DateUtils'
import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { TagAuditStatusCP } from 'modules/quality-assurance/components/tag-audit-status/TagAuditStatusCP'
import { AuditScoreEnum, AuditScoreLabelEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditScoreEnum'
import { ColumnActionsTableAuditsCP } from 'modules/quality-assurance/components/table-audits/inner/ColumnActionsTableAuditsCP'
import { PopoverAuditCP } from 'modules/quality-assurance/components/popover-audit/PopoverAuditCP'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { AuditListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/AuditListItemResponseDTO'
import { ToolbarTableAuditsICP } from 'modules/quality-assurance/components/table-audits/inner/ToolbarTableAuditsICP'

interface ICPProps {
	reloadTable: number
	onLoading?: (isLoading: boolean) => void
	filters: AuditSearchRequestDTO
}

interface IAuditCodeExamId {
	auditCode: number
	examId: string
}

/**
 */
export function TableAuditsCP(props: ICPProps): JSX.Element {
	const [selectedAuditCodes, setSelectedAuditCodes] = useState<number[]>([])
	const [selectedExamIds, setSelectedExamIds] = useState<string[]>([])

	const [allAuditCodeExamId, setAllAuditCodeExamId] = useState<IAuditCodeExamId[]>([])

	const [reload, setReload] = useState<number>(props.reloadTable)
	useEffect(onReload, [props.reloadTable])

	/**
	 */
	function onReload(): void {
		setSelectedAuditCodes([])
		setSelectedExamIds([])
		setReload(props.reloadTable)
	}

	return (
		<WrapperSCP>
			<TableFromApiCP<AuditListItemResponseDTO, AuditSearchRequestDTO>
				onLoading={props.onLoading}
				appearance={{
					showReloadButton: true,
					wrappedOnCard: true,
					showTotalOnHeader: true,
					recordLabel: 'auditorias',
					topBarLeftContent: (
						<ToolbarTableAuditsICP
							auditCodes={selectedAuditCodes}
							examIds={selectedExamIds}
							onChangeAnyData={() => setReload(TableUtils.getReloadNumber())}
							filterFormStateManager={props.filters}
						/>
					),
				}}
				onLoad={(loadedData) => {
					setAllAuditCodeExamId(
						loadedData.map((audit) => ({
							auditCode: audit.code,
							examId: audit.examId,
						})),
					)
				}}
				shouldLoadData={reload}
				apiConfig={{
					hasPagination: true,
					filters: props.filters,
					requestConfigTP: (filtersDto) => AuditsRequests.search(filtersDto!),
				}}
				row={{
					setClass: (row) => row.audit?.score ?? '',
					onSelect: {
						rowKey: 'code',
						selectedCodes: selectedAuditCodes,
						onSelectRow: (codes) => {
							setSelectedAuditCodes(codes)

							const selectedCodeIds = allAuditCodeExamId.filter((auditCodeExamId) => codes.includes(auditCodeExamId.auditCode))
							setSelectedExamIds(selectedCodeIds.map((codeId) => codeId.examId))
						},
					},
				}}
				columns={[
					{
						key: 'score',
						title: 'Score',
						className: 'audit-class',
						render: (text, record) =>
							record.audit.status === AuditStatusEnum.DONE && !!record.audit.score ? (
								<FlexCP justifyContent={'space-between'}>
									<IconICP iconName={ThemeProjectCommon.icons[record.audit.score]} />
									<TextCP text={AuditScoreLabelEnum[record.audit.score]} marginLeft={5} />
								</FlexCP>
							) : (
								'-'
							),
					},
					{
						key: 'auditedBy',
						title: 'Auditor',
						className: 'audit-class',
						render: (text, record) => record.audit?.doctor.name,
					},
					{
						key: 'doctor',
						title: 'Médico',
						className: 'audit-class',
						render: (text, record) => (record.audit.status === AuditStatusEnum.DONE ? record.doctor.name : '-'),
					},
					{
						key: ExamSearchOrderingEnum.DATE,
						title: 'Data do Exame',
						render: (text, record) => DateUtils.formatDate(record.examDate, DateTimeFormatEnum.BR_WITHOUT_TIME),
					},
					{
						key: ExamSearchOrderingEnum.PATIENT,
						title: 'Paciente',
						render: (text, record) => record.patient.name,
					},
					{
						key: ExamSearchOrderingEnum.EXAM,
						title: 'Exame',
						render: (text, record) => (
							<AvatarExamModalityCP modality={record.modality} description={record.description} size={28} fontSize={'small'} />
						),
					},
					{
						key: 'imgs',
						title: 'Imgs',
						align: 'center',
						render: (text, record) => record.imageData.totalImages ?? '-',
					},
					{
						key: ExamSearchOrderingEnum.CLINIC,
						title: 'Unidade Hospitalar',
						render: (text, record) => record.clinic.name,
					},
					{
						key: 'status',
						render: (text, record) => !!record.audit && <TagAuditStatusCP auditStatus={record.audit.status} />,
					},
					{
						key: 'comments',
						render: (text, record) => !!record.audit && <PopoverAuditCP audit={record.audit} />,
					},
					{
						key: 'actions',
						render: (text, record) => (
							<ColumnActionsTableAuditsCP record={record} onChangeAnyData={() => setReload(TableUtils.getReloadNumber())} />
						),
					},
				]}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	position: relative;

	.${AuditScoreEnum.SCORE_10} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_10]};
	}
	.${AuditScoreEnum.SCORE_8} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_8]};
	}
	.${AuditScoreEnum.SCORE_6} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_6]};
	}
	.${AuditScoreEnum.SCORE_4} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_4]};
	}
	.${AuditScoreEnum.SCORE_2} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_2]};
	}
	.${AuditScoreEnum.SCORE_0} > .audit-class {
		background: ${ThemeProjectCommon.auditScoreColor[AuditScoreEnum.SCORE_0]};
	}
`
