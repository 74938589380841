import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { AuditFormModel } from 'modules/quality-assurance/screens/screen-audit/inner/AuditFormModel'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { MentionAuditPhrasesCP } from 'modules/quality-assurance/components/metion-audit-phrases/MentionAuditPhrasesCP'
import { SelectAuditScoreCP } from 'modules/quality-assurance/components/select-audit-score/SelectAuditScoreCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<AuditFormModel>
}

/**
 */
export function ScreenContentAuditCP(props: ICPProps): JSX.Element | null {
	return (
		<CardCP title={'Avaliação'} width={'50%'} margin={'20px 40px 20px 0'}>
			<SelectAuditScoreCP formStateManager={props.formStateManager} fieldName={'score'} />

			<BasicStyleWrapperCP margin={{ top: 20 }}>
				<MentionAuditPhrasesCP formStateManager={props.formStateManager} />
			</BasicStyleWrapperCP>
		</CardCP>
	)
}
