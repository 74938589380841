import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'

interface ICPProps {
	pdfSrc: string
	zoomOption?: 'page-width' | 'page-fit'
	height?: string
}

export function PdfMozillaViewer(props: ICPProps): JSX.Element {
	let zoomOption = ''
	if (!!props.zoomOption) zoomOption = `&zoom=${props.zoomOption}`

	return (
		<iframe
			src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(props.pdfSrc)}${zoomOption}`}
			width={'100%'}
			height={props.height ?? '800px'}
			frameBorder={0}
		/>
	)
}
