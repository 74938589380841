export enum MedicalReportHistoryEnum {
	CREATED = 'CREATED',
	FINALIZE = 'FINALIZE',
	SIGN = 'SIGN',
	SAVE_AS_DRAFT = 'SAVE_AS_DRAFT',
	DISCARD = 'DISCARD',
}

export enum MedicalReportHistoryLabelEnum {
	CREATED = 'Criado',
	FINALIZE = 'Concluído',
	SIGN = 'Assinado',
	SAVE_AS_DRAFT = 'Rascunho',
	DISCARD = 'Descartado',
}
