import React from 'react'
import { EditorState } from 'draft-js'

import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { UndoIconCP } from '_old/main/common/components/icons/UndoIconCP'
import { RedoIconCP } from '_old/main/common/components/icons/RedoIconCP'

interface IHistoryControlButtonICPProps {
	type: 'undo' | 'redo'
	editorState: EditorState
	onEditorStateChange: (editorState: EditorState) => void
	iconColor: string
	iconSize: number
}

/**
 * COMPONENTE
 * Botao de controle de historico de estado (desfazer / refazer)
 * do editor wysiwyg / Draft JS customizado.
 *
 * @author hjcostabr
 */
export const HistoryControlButtonICP = (props: IHistoryControlButtonICPProps): JSX.Element | null => {
	function changeStateHistory(editorState: EditorState): EditorState {
		return props.type === 'undo' ? EditorState.undo(editorState) : EditorState.redo(editorState)
	}

	function renderIcon(): JSX.Element {
		return props.type === 'undo' ? (
			<UndoIconCP color={props.iconColor} size={props.iconSize} />
		) : (
			<RedoIconCP color={props.iconColor} size={props.iconSize} />
		)
	}

	function isDisabled(): boolean {
		const historyStack = props.type === 'undo' ? props.editorState.getUndoStack() : props.editorState.getRedoStack()
		return historyStack.isEmpty()
	}

	return (
		<ToolbarButtonICP
			editorState={props.editorState}
			onEditorStateChange={props.onEditorStateChange}
			innerContent={renderIcon()}
			onClick={changeStateHistory}
			isActive={() => false}
			isDisabled={isDisabled}
		/>
	)
}
