import { isValid as isValidCnpj } from '@fnando/cnpj'
import { registerDecorator, ValidationArguments, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida Cpf
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_VALID_CNPJ })
class IsValidCnpjConstraint implements ValidatorConstraintInterface {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	validate(value: string, args: ValidationArguments): boolean {
		return isValidCnpj(value)
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	defaultMessage(args: ValidationArguments): string {
		return 'Cnpj Inválido!'
	}
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function IsValidCnpj(validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: [],
			validator: IsValidCnpjConstraint,
		})
	}
}
