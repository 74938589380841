import { ButtonDownloadMedicalReportCP } from 'modules/exams/components/medical-report/button-download-medical-report/ButtonDownloadMedicalReportCP'
import React from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ExamFileNamesUtils } from 'modules/exams/utils/ExamFileNamesUtils'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TagExamStatusCP } from 'modules/exams/components/exams/tag-exam-status/TagExamStatusCP'
import { PatientExamResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PatientExamResponseDTO'

interface ICPProps {
	exam: PatientExamResponseDTO
	onChangeData: () => void
}

/**
 * Conteudo principal da tela de resultado de exame de paciente.
 */
export function CardPatientExamCP(props: ICPProps): JSX.Element {
	return (
		<CardCP
			innerSpacing={'none'}
			title={<AvatarExamModalityCP modality={props.exam.modality} description={props.exam.description} patientName={props.exam.patient.name} />}
			titleRight={<TagExamStatusCP examStatus={props.exam.status} />}
		>
			<DescriptionCP
				style={{ padding: '10px' }}
				items={[
					{
						label: 'Data de Nascimento',
						text: DateUtils.formatDate(props.exam.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME),
					},
					{
						label: 'Idade',
						text: DateUtils.ageCalculate(new Date(props.exam.patient.birthday)),
					},
					{
						label: 'Data do Exame',
						text: DateUtils.formatDate(props.exam.examDate, DateFormatEnum.BR_WITH_TIME_H_M),
					},
					{
						label: 'Laudo',
						text: (
							<FlexCP justifyContent={'center'}>
								{ExamStatusUtils.examStatusGroupOfExamStatusMap.get(props.exam.status) === ExamStatusGroupEnum.DONE ? (
									<ButtonDownloadMedicalReportCP
										fileName={ExamFileNamesUtils.getMedicalReportFileName([props.exam.code], props.exam)}
										size={'small'}
										onDownloadDone={props.onChangeData}
										digitalCertification={props.exam.digitalCertification}
										downloadedByConfig={{
											patient: {
												examId: props.exam.id,
											},
										}}
										showPrinter={true}
									/>
								) : (
									<ButtonCP size={'small'} disabled={true}>
										Aguardando emissão de laudo
									</ButtonCP>
								)}
							</FlexCP>
						),
					},
					{
						label: 'Exame',
						text: (
							<FlexCP justifyContent={'center'}>
								<ButtonDownloadExamCP
									size={'small'}
									downloadBy={'patient'}
									exam={{
										id: props.exam.id,
										imageData: props.exam.imageData,
										examDescription: props.exam.description,
										patientName: props.exam.patient.name,
									}}
								/>
							</FlexCP>
						),
					},
				]}
			/>
		</CardCP>
	)
}
