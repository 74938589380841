import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import React from 'react'

/**
 * Icon: Exam
 * @author Stella
 */
export const OsirixIconCP = (props: IIconProps): JSX.Element => (
	<svg
		style={{
			width: props.size || '22',
			height: props.size || '22',
			fill: props.color || 'inherit',
			color: props.color || 'inherit',
			...props.style,
		}}
		width={props.size || '22'}
		height={props.size || '22'}
		fill={props.color || 'inherit'}
		color={props.color || 'inherit'}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 289 224"
	>
		{/* tslint:disable-next-line: max-line-length*/}
		<path
			id="icon_interior"
			d="M145.555,7.87l55.875,84.919c5.885,4.24,10.557,7.768,14.646,10.853
            c13.508,10.198,20.363,15.377,32.667,17.625c4.065,0.742,7.903,0.778,13.979,0.836c2.403,0.021,5.133,0.049,8.152,0.112
            c4.092,0.079,7.343,3.461,7.264,7.554c-0.064,3.42-2.441,6.256-5.609,7.045l0.004,0.016c-4.221,1.061-9.158,1.963-14.182,2.49
            c-4.509,0.476-9.062,0.646-13.195,0.361c-4.865-0.336-10.084-1.765-15.415-3.869l54.116,82.246H145.555H7.254L53.286,148.1
            c-1.084,0.354-2.197,0.707-3.343,1.061l-0.409,0.111l0.001,0.004c-3.737,0.916-7.505,0.228-10.571-1.631
            c-3.069-1.858-5.427-4.877-6.344-8.608c-0.036-0.146-0.067-0.292-0.094-0.438c-0.772-3.608-0.077-7.207,1.697-10.137
            c0.157-0.262,0.327-0.508,0.51-0.742c1.877-2.703,4.7-4.756,8.126-5.6l0.03-0.008c7.806-2.188,13.507-4.363,18.349-7.451
            c4.844-3.089,9.161-7.313,14.259-13.631c6.592-8.167,13.853-14.614,21.578-19.481L145.555,7.87L145.555,7.87z M117.656,90.552
            c6.434-2.223,14.04-3.129,24.045-3.05c16.226,0.128,33.014,6.509,48.173,16.318c14.604,9.448,38.681,27.274,55.772,28.454
            c7.922,0.545,17.615-0.771,25.082-2.647c-11.684-0.251-16.971,0.099-23.322-1.065c-18.749-3.429-25.518-12.119-53.152-31.788
            c-36.927-26.282-82.575-28.758-112.974,8.905c-11.208,13.89-19.409,18.841-36.648,23.646c-3.52,0.861-5.672,4.416-4.808,7.938
            c0.865,3.521,4.419,5.672,7.939,4.809c9.237-2.84,16.307-5.688,22.473-9.53c0,0.228,0.01,0.451,0.035,0.678
            c-2.931,10.117-7.046,28.748-28.9,47.118c-2.829,2.27-3.278,6.398-1.01,9.229c2.27,2.822,6.4,3.276,9.228,1.008
            c14.52-13.032,23.545-26.258,28.656-43.379c3.622,4.611,7.82,8.098,14.512,12.613c29.254,19.741,101.869,39.342,133.071,18.77
            c26.59-17.529-10.003-50.238-21.27-26.426c-7.631,16.127,20.504,21.627,18.535,6.861c-0.295-2.199-1.171-3.515-3.242-4.197
            l-0.002,0.002c0.435,0.813,1.004,1.709,1.229,2.588c2.758,10.709-16.078,8.099-12.827-1.536c5.706-16.91,31.956,5.172,13.44,16.174
            c-23.707,14.091-75.608,0.554-99.178-9.771c-12.644-5.539-25.101-12.162-33.641-22.688c35.4,6.892,89.006,7.669,117.999-3.411
            l8.302-3.939l-9.004,1.271c-38.812,5.902-79.421,5.389-117.642-4.436c-1.87-0.48-6.077-2.242-9.699-3.03
            c4.006-3.625,7.732-8.113,12.055-13.513c3.107-3.881,5.991-7.232,8.84-10.109c-0.877,2.181-1.362,4.563-1.362,7.058
            c0,10.452,8.472,18.924,18.924,18.924c10.452,0,18.924-8.474,18.924-18.924C136.21,99.144,127.937,90.75,117.656,90.552z"
		/>
	</svg>
)
