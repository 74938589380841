import React from 'react'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

interface ICPProps {
	record: MedicalTimetableStructureResponseDTO
	weekDay: WeekDaysEnum
	onChangeTotalDoctors: (total: number) => void
}

/**
 */
export function ColumnMedicalTimetableWeekDayConfigCP(props: ICPProps): JSX.Element {
	return (
		<InputCP
			placeholder={'Total de Médicos'}
			value={props.record.schedules.find((scheduleConfig) => scheduleConfig.dayOfWeek === props.weekDay)?.numberOfRows}
			onChange={props.onChangeTotalDoctors}
			type={'number'}
		/>
	)
}
