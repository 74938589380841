import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React, { useState } from 'react'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonAnalyseReturnedExamCP } from 'modules/exams/components/exam-analysis/button-analyse-returned-exam/ButtonAnalyseReturnedExamCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerExamPendingCP } from 'modules/exams/components/exams/drawer-exam-pending/DrawerExamPendingCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'

interface ICPProps {
	viewAsDoctor: boolean
	exam: ExamListItemResponseDTO
	onSave: () => void
}

/**
 * Coluna de Acoes de triagem.
 */
export function ColumnExamPendingActionsICP(props: ICPProps): JSX.Element {
	const [isExamPendingDrawerVisible, setIsExamPendingDrawerVisible] = useState<boolean>(false)

	const canSeeExamData = DoctorGroupPermissionUtils.canSeeExamData(props.viewAsDoctor, props.exam.clinic.code, props.exam.doctorGroupCode)

	return (
		<>
			<ButtonGroupCP mode={'separeted'}>
				<AccessControlCP
					permission={{
						byHasAnyPermissionInClinic: {
							clinicCode: props.exam.clinic.code,
							permissions: ['isUser', 'isAdmin'],
						},
					}}
				>
					{props.exam.status === ExamStatusEnum.RETURNED ? (
						<ButtonAnalyseReturnedExamCP examCode={props.exam.code} examId={props.exam.id} onSave={props.onSave} />
					) : (
						<ButtonCP type={'primary'} size={'small'} onClick={() => setIsExamPendingDrawerVisible(true)} icon={'edit'}>
							Editar Exame
						</ButtonCP>
					)}
				</AccessControlCP>
				{canSeeExamData && (
					<>
						<BasicStyleWrapperCP margin={{ left: 5 }}>
							<ButtonDownloadExamCP
								size={'small'}
								iconOnly={true}
								exam={{
									id: props.exam.id,
									imageData: props.exam.imageData,
									examDescription: props.exam.description,
									patientName: props.exam.patient.name,
								}}
								downloadBy={'user'}
							/>
						</BasicStyleWrapperCP>

						<ButtonExamDetailsCP examId={props.exam.id} onSave={props.onSave} />
					</>
				)}
			</ButtonGroupCP>

			<DrawerExamPendingCP
				clinicCode={props.exam.clinic.code}
				examCode={props.exam.code}
				examId={props.exam.id}
				visible={isExamPendingDrawerVisible}
				onCancel={() => setIsExamPendingDrawerVisible(false)}
				onSave={() => {
					setIsExamPendingDrawerVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
