import React, { useEffect, useState } from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { ClinicsAdminViewTP } from 'modules/clinic/screens/screen-clinics-admin/inner/ClinicsAdminViewTP'
import {
	CLINICS_ADMIN_SUB_MENU_BILLING_KEY,
	CLINICS_ADMIN_SUB_MENU_CONFIG_KEY,
	CLINICS_ADMIN_SUB_MENU_USERS_KEY,
	ClinicsAdminScreenUtils,
} from 'modules/clinic/screens/screen-clinics-admin/inner/ClinicsAdminScreenUtils'
import { useLocation } from 'react-router-dom'
import { HospitalIconCP } from '_old/main/common/components/icons/HospitalIconCP'
import { ClinicsAdminScreenHeaderCP } from 'modules/clinic/screens/screen-clinics-admin/header/ClinicsAdminScreenHeaderCP'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { BeeRadsTitleBrowserCP } from 'app/components/beerads-title-browser/BeeRadsTitleBrowserCP'

export const CLINIC_CODE_PARAM = 'clinicCode'

/**
 * Tela para gerenciamento da clínica
 */
export function ScreenClinicsAdmin(): JSX.Element {
	const routeLocation = useLocation()

	const [clinicCode, setClinicCode] = useState<number>()
	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const params = new URLSearchParams(routeLocation.search)
		const clinicCodeParam = params.get(CLINIC_CODE_PARAM)

		if (!!clinicCodeParam) setClinicCode(+clinicCodeParam)
	}

	return (
		<AccessControlCP permission={{ byHasAnyClinicRelationPermission: ['isAdmin', 'isDoctorGroupAdmin'] }}>
			<BeeRadsTitleBrowserCP pageName={'Configuração da Clínica'} />
			{!clinicCode ? (
				<WelcomeContentCP
					icon={<HospitalIconCP size={50} />}
					message={'Selecione uma unidade hospitalar para ver as opções'}
					extra={<ClinicsAdminScreenHeaderCP clinicCode={clinicCode} onChangeClinic={setClinicCode} />}
				/>
			) : (
				<AccessControlCP
					permission={{
						byHasAnyPermissionInClinic: {
							clinicCode: clinicCode,
							permissions: ['isAdmin', 'isDoctorGroupAdmin'],
						},
					}}
					redirect={{
						shouldRedirect: true,
						redirectTo: PrivateUserRouter.CLINIC,
					}}
				>
					<LayoutSubmenuCP<ClinicsAdminViewTP>
						loggedDomain={AppStateUtils.getDomain()}
						menuTitle={'Administração'}
						defaultSubmenu={'clinicDataView'}
						defaultOpenKeys={[CLINICS_ADMIN_SUB_MENU_CONFIG_KEY, CLINICS_ADMIN_SUB_MENU_BILLING_KEY, CLINICS_ADMIN_SUB_MENU_USERS_KEY]}
						submenuConfigs={ClinicsAdminScreenUtils.getSubMenuConfigs(setClinicCode, clinicCode)}
					/>
				</AccessControlCP>
			)}
		</AccessControlCP>
	)
}
