import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import React from 'react'

/**
 * Icon: Logo Beerads
 * @author Victor
 */
export const BeeRadsIconCP = (props: IIconProps): JSX.Element => {
	return (
		<svg
			style={{
				width: props.size ?? '22',
				height: props.size ?? '22',
				fill: props.color ?? 'inherit',
				...props.style,
			}}
			width={props.size ?? '22'}
			height={props.size ?? '22'}
			fill={props.color ?? 'inherit'}
			xmlns={'http://www.w3.org/2000/svg'}
			x={'0px'}
			y={'0px'}
			viewBox={'0 0 100 100'}
			enableBackground={'new 0 0 100 100'}
		>
			<g>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={'M46,45.5h7.9c-1.5-1.8-2.9-3.8-3.9-6C48.8,41.6,47.5,43.6,46,45.5z'}
				/>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={
						'M45.9,37.3c0.4-0.7,0.7-1.5,1-2.2c0.3-0.8,0.5-1.6,0.7-2.5c-2.9-0.9-14.2-0.8-18.2-0.8c-11.1,0-20.5,5-20.5,9.1c0,3,5,6.5,12.1,8.1c0.7,0.2,1.4,0.3,2.1,0.4c2.1,0.4,4.2,0.6,6.3,0.5C36.6,50,43,43.1,45.9,37.3z'
					}
				/>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={
						'M38.9,72.8h12c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3H40.6c1.4,3.8,4.3,7,8,8.7v7.3c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3v-7.3c3.7-1.7,6.6-4.9,8-8.7H60c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3l0,0h2.8c0.4-1.5,0.8-3,1-4.6h-26C38.1,69.8,38.5,71.3,38.9,72.8z'
					}
				/>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={
						'M70.5,31.8c-5.5,0-15-0.1-18.2,0.9c0.1,0.8,0.3,1.7,0.6,2.5c0.3,0.8,0.6,1.5,1,2.2c3,6.1,9.8,12.7,16.6,12.7c2.1,0,4.2-0.2,6.3-0.5c0.7-0.1,1.4-0.3,2.1-0.4c7-1.7,12.1-5.1,12.1-8.1C91,36.8,81.5,31.8,70.5,31.8z'
					}
				/>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={
						'M48.6,59.2c-1.3,0-2.3-1.1-2.2-2.3c0-1.2,1-2.2,2.2-2.2v0h15.6c-0.1-0.6-0.2-1.2-0.2-1.8c-1.6-0.8-3.1-1.7-4.5-2.8H42.2c-1.4,1.1-2.9,2-4.4,2.8c-0.1,0.6-0.2,1.2-0.2,1.8h1.9c1.3,0,2.3,1.1,2.2,2.3c0,1.2-1,2.2-2.2,2.2h-2.2c-0.1,1.5-0.1,3,0.1,4.6h27.1c0.1-1.5,0.2-3,0.1-4.6H48.6z'
					}
				/>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					fill={props.color ?? '#040505'}
					d={
						'M38.2,6.6c0.4,0.2,0.8,0.5,1.1,0.7c6.7,5.2-1.2,9-0.4,14.7c0.6,4.5,6.3,7.3,9.8,9.2c1.2,0.6,2.3,0.4,3.4-0.2c5.6-3.2,13-7.5,7.3-14.5c-1.8-2.2-2-4.2-0.7-6.7c0.8-1.5,2-2.4,3.2-3.3C62.2,6,62.5,5.6,62.5,5C61.7,4,61,4.8,60.4,5.2c-2.2,1.3-3.8,3.6-4.3,6.1c-0.4,0.6,0.1,1.6-0.9,1.9c-3-1.1-6.1-1.5-9.2-0.3c-1.5,0.6-1.8,0.3-2-1.2c-0.4-3.1-2.2-5.3-4.9-6.8c-0.7-0.4-1.3-0.5-1.7,0.3C37.1,5.9,37.6,6.3,38.2,6.6z'
					}
				/>
			</g>
		</svg>
	)
}
