import React, { useState } from 'react'
import { ForgotPasswordFormCP } from 'modules/auth/components/recover-password-form/ForgotPasswordFormCP'
import { ValidateVerificationCodeCP } from 'modules/auth/screens/screen-recover-password/inner/ValidateVerificationCode'
import { RecoverPasswordCP } from 'modules/auth/screens/screen-recover-password/inner/RecoverPasswordCP'

export enum ForgotPasswordStepsTP {
	EMAIL_INPUT = 'email-input',
	VERIFICATION_CODE = 'verification-code',
	RECOVER_PASSWORD = 'recover-password',
}

interface IForgotPasswordStepsCPProps {
	onPasswordResetStepChange: (step: ForgotPasswordStepsTP) => void
	passwordResetStep: ForgotPasswordStepsTP
}

export function ForgotPasswordStepsCP(props: IForgotPasswordStepsCPProps): JSX.Element {
	const [passwordToken, setPasswordToken] = useState('')

	function handlePasswordTokenChange(value: string): void {
		setPasswordToken(value)
	}

	const steps: Record<ForgotPasswordStepsTP, JSX.Element> = {
		[ForgotPasswordStepsTP.EMAIL_INPUT]: <ForgotPasswordFormCP onPasswordResetStepChange={props.onPasswordResetStepChange} />,
		[ForgotPasswordStepsTP.VERIFICATION_CODE]: (
			<ValidateVerificationCodeCP
				onPasswordResetStepChange={props.onPasswordResetStepChange}
				onPasswordTokenChange={handlePasswordTokenChange}
				passwordToken={passwordToken}
			/>
		),
		[ForgotPasswordStepsTP.RECOVER_PASSWORD]: <RecoverPasswordCP passwordToken={passwordToken} />,
	}

	return steps[props.passwordResetStep]
}
