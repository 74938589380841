import React from 'react'
import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP } from 'modules/medical-timetable/components/box-medical-timetable-doctor-week-recurrency-config/inner/BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAssignFormModel>
	period: MedicalTimetablePeriodEnum
}
/**
 */
export function BoxMedicalTimetableDoctorWeekRecurrencyConfigCP(props: ICPProps): JSX.Element {
	if (props.formStateManager.getFieldValue('type') !== 'recurrent') return <></>

	return (
		<>
			<TitleCP underLine={true} textSize={'normal'} marginTop={30}>
				Recorrência por dia da semana
			</TitleCP>

			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.SUNDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.MONDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.TUESDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.WEDNESDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.THURSDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.FRIDAY}
			/>
			<BoxMedicalTimetableDoctorWeekRecurrencyConfigItemICP
				formStateManager={props.formStateManager}
				period={props.period}
				weekDay={WeekDaysEnum.SATURDAY}
			/>

			<InputCP
				label={'Fim da recorrência'}
				value={props.formStateManager.getFieldValue('endDate')}
				onChange={(value) => props.formStateManager.changeFieldValue('endDate', value)}
				hint={{
					type: 'text',
					text: 'Caso não tenha data fim para vigência, deixar essa data em branco',
				}}
				type={'date'}
				width={200}
			/>
		</>
	)
}
