import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import styled from 'styled-components'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { IDateRangeFilter } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/inner/IDateRangeFilter'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

type _ViewTP = 'month' | 'year' | 'decade' | 'century'

interface ICalendarCPProps {
	currentDate?: IDateRangeFilter
	onChangeSelectedDate: (selectedDate: Date) => void
}

/**
 * Calendario para selecao de data.
 */
export function CalendarCP(props: ICalendarCPProps): JSX.Element {
	const [calendarDate, setCalendarDate] = useState<Date[]>([new Date()])
	useEffect(init, [props.currentDate])

	/**
	 * Inicializa.
	 */
	function init(): void {
		if (!props.currentDate?.beginDate) return

		const dates = [props.currentDate.beginDate]
		if (!!props.currentDate.endDate) dates.push(props.currentDate.endDate)

		setCalendarDate(dates)
	}

	/**
	 * Ao escolher uma data.
	 */
	function onChangeCalendarValue(selectedDate: Date): void {
		props.onChangeSelectedDate(selectedDate)
		setCalendarDate(DateUtils.getAllDatesInInterval(selectedDate))
	}

	/**
	 *  Funcao para mudar o label exibido pelo componente (Nome do mes, etc.)
	 */
	function changeLabel(date: Date, view: _ViewTP, label: string): string {
		if (view === 'month') {
			const monthLabel = label.split(' ')[0]
			return _.capitalize(monthLabel)
		}

		return label
	}

	return (
		<CalendarWrapperSCP>
			<Calendar
				value={calendarDate}
				locale={'pt-BR'}
				calendarType={'US'}
				navigationLabel={({ date, view, label }) => changeLabel(date, view, label)}
				prevLabel={<IconCP antIcon={'arrow-left'} />}
				nextLabel={<IconCP antIcon={'arrow-right'} />}
				onChange={(value) => onChangeCalendarValue(value as Date)}
			/>
		</CalendarWrapperSCP>
	)
}

const CalendarWrapperSCP = styled.div`
	.react-calendar {
		border: none;
		padding: 10px;
		background-color: transparent;
	}

	.react-calendar__navigation {
		margin-bottom: 0;
	}

	.react-calendar__navigation__label {
		font-weight: 700;
		font-size: 1.15rem;
	}

	.react-calendar__month-view {
		padding-top: 1.2rem;
		border-top: 1px solid;
	}

	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		background-color: ${ThemeProject.primary};
	}

	.react-calendar__navigation__next2-button,
	.react-calendar__navigation__prev2-button {
		display: none !important;
	}

	.react-calendar__navigation button:enabled:hover,
	.react-calendar__navigation button:enabled:focus {
		background-color: transparent;
	}

	.react-calendar__tile .react-calendar__month-view__days__day {
		font-weight: 600 !important;
	}

	.react-calendar__month-view__days__day--neighboringMonth {
		color: #8e8e8e !important;
	}

	.react-calendar__month-view__weekdays__weekday {
		text-transform: capitalize;
		abbr {
			text-decoration: none;
			font-size: 12px;
			font-weight: 900;
		}
	}

	.react-calendar__month-view__days button {
		font-weight: 600;
	}

	.react-calendar__tile,
	.react-calendar__month-view__days__day {
		position: relative;
	}

	.react-calendar__tile--active {
		color: ${ThemeFrameworkCommon.browserDefaultColorLight};
		background: ${ThemeProject.primary};
	}

	.react-calendar__tile--now:not(.react-calendar__tile--active)::after {
		content: '';
		position: absolute;
		height: 2px;
		width: 19px;
		background-color: ${ThemeProject.primary};
		bottom: 7px;
		margin: 0 auto;
		right: 0;
		left: 0;
	}
`
