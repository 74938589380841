import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { AuditScoreEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditScoreEnum'
import { ClinicDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'

const isDark = localStorage.getItem('theme') === 'dark'

export const ThemeProjectCommon = {
	examStatusColor: {
		[ExamStatusEnum.PENDING]: '#da5252',
		[ExamStatusEnum.RETURNED]: '#bd3131',
		[ExamStatusEnum.AWAITING]: '#e3c61d',
		[ExamStatusEnum.SIGNED]: '#c4ab1a',
		[ExamStatusEnum.IN_PROGRESS]: '#747474',
		[ExamStatusEnum.DONE_RECTIFICATION]: '#6f9696',
		[ExamStatusEnum.DONE]: '#3C9AA2',
		[ExamStatusEnum.PRINTED]: '#52c41a',
		[ExamStatusEnum.RECTIFICATION]: '#c0392b',
		[ExamStatusEnum.PENDING_RECTIFICATION_ANALYSIS]: '#af1200',
		[ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS]: '#c4b65c',
	},

	auditStatusColor: {
		[AuditStatusEnum.AWAITING]: '#e3c61d',
		[AuditStatusEnum.IN_PROGRESS]: '#747474',
		[AuditStatusEnum.DONE]: '#3C9AA2',
	},

	clinicDoctorGroupStatusColor: {
		[ClinicDoctorGroupStatusEnum.AWAITING_DOCTOR_GROUP]: '#e3c61d',
		[ClinicDoctorGroupStatusEnum.AWAITING_CLINIC]: '#e3c61d',
		[ClinicDoctorGroupStatusEnum.REFUSED]: '#c0392b',
		[ClinicDoctorGroupStatusEnum.ACTIVE]: '#3C9AA2',
	},

	auditScoreColor: {
		[AuditScoreEnum.SCORE_10]: '#0984e3',
		[AuditScoreEnum.SCORE_8]: '#74b9ff',
		[AuditScoreEnum.SCORE_6]: '#f1c40f',
		[AuditScoreEnum.SCORE_4]: '#f1c40f',
		[AuditScoreEnum.SCORE_2]: '#e74c3c',
		[AuditScoreEnum.SCORE_0]: '#c0392b',
	},

	examUrgentTypeColor: {
		[ExamUrgencyTypeEnum.AVC]: '#f6c810',
		[ExamUrgencyTypeEnum.URGENT]: '#da5252',
		[ExamUrgencyTypeEnum.HOSPITALIZATION]: '#949494',
		[ExamUrgencyTypeEnum.REGULAR]: isDark ? '#181818' : '#fff',
	},

	examSlaColor: {
		sla0h: '#c0392b',
		sla2h: '#fc5e5e',
		sla6h: '#f6c810',
		sla24h: '#cecece',
	},

	icons: {
		unAssignExam: 'user-delete',
		assignExam: 'solution',
		showDetails: 'search',
		audit: 'audit',
		digitalCertificate: 'safety',
		doctorGroup: 'team',

		[ExamUrgencyTypeEnum.AVC]: 'sound',
		[ExamUrgencyTypeEnum.URGENT]: 'alert',
		[ExamUrgencyTypeEnum.HOSPITALIZATION]: 'bank',
		[ExamUrgencyTypeEnum.REGULAR]: 'thunderbolt',

		[AuditScoreEnum.SCORE_10]: 'smile',
		[AuditScoreEnum.SCORE_8]: 'smile',
		[AuditScoreEnum.SCORE_6]: 'meh',
		[AuditScoreEnum.SCORE_4]: 'meh',
		[AuditScoreEnum.SCORE_2]: 'frown',
		[AuditScoreEnum.SCORE_0]: 'frown',
	},
}
