import { OitContentReportTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/OitContentReportTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

/**
 */
export class OitFormModel extends FormModel {
	answer1A?: string
	answer1B?: boolean
	answer1C?: string
	answer2A?: boolean

	answer2BPrimaryPS?: string
	answer2BPrimaryQT?: string
	answer2BPrimaryRU?: string
	answer2BSecondaryPS?: string
	answer2BSecondaryQT?: string
	answer2BSecondaryRU?: string
	answer2BZone1?: string[]
	answer2BZone2?: string[]
	answer2BZone3?: string[]
	answer2BProfusion?: string

	answer2C?: string
	answer3A?: boolean

	answer3B?: boolean
	answer3BProfileFirst?: string[]
	answer3BFrontFirst?: string[]
	answer3BDiafragmaFirst?: string[]
	answer3BOtherFirst?: string[]
	answer3BProfileSecond?: string[]
	answer3BFrontSecond?: string[]
	answer3BDiafragmaSecond?: string[]
	answer3BOtherSecond?: string[]
	answer3BExtension0D?: string
	answer3BExtension0E?: string
	answer3BExtensionD123?: string
	answer3BExtensionE123?: string
	answer3BWidth0D?: string
	answer3BWidth0E?: string
	answer3BWidthDabc?: string
	answer3BWidthEabc?: string

	answer3C?: string[]

	answer3D?: boolean
	answer3DProfileFirst?: string[]
	answer3DFrontFirst?: string[]
	answer3DProfileSecond?: string[]
	answer3DFrontSecond?: string[]
	answer3DExtension0D?: string
	answer3DExtension0E?: string
	answer3DExtensionD123?: string
	answer3DExtensionE123?: string
	answer3DWidth0D?: string
	answer3DWidth0E?: string
	answer3DWidthDabc?: string
	answer3DWidthEabc?: string

	answer4A?: boolean
	answer4B?: string[]
	answer4C?: string

	constructor(initialData?: OitContentReportTP) {
		super()
		if (!!initialData) {
			this.answer1A = initialData.question1?.A
			this.answer1B = initialData.question1?.B
			this.answer1C = initialData.question1?.C
			this.answer2A = initialData.question2?.A
			this.answer2BPrimaryPS = initialData.question2?.B?.primary?.PS
			this.answer2BPrimaryQT = initialData.question2?.B?.primary?.QT
			this.answer2BPrimaryRU = initialData.question2?.B?.primary?.RU
			this.answer2BSecondaryPS = initialData.question2?.B?.secondary?.PS
			this.answer2BSecondaryQT = initialData.question2?.B?.secondary?.QT
			this.answer2BSecondaryRU = initialData.question2?.B?.secondary?.RU
			if (!!initialData.question2?.B?.zone) {
				this.answer2BZone1 = initialData.question2.B.zone[0]
				this.answer2BZone2 = initialData.question2.B.zone[1]
				this.answer2BZone3 = initialData.question2.B.zone[2]
			}

			this.answer2BProfusion = initialData.question2?.B?.profusion
			this.answer2C = initialData.question2?.C
			this.answer3A = initialData.question3?.A

			if (!!initialData.question3?.B) {
				this.answer3B = initialData.question3.B[0]
				this.answer3BProfileFirst = initialData.question3.B[1].first?.profile
				this.answer3BFrontFirst = initialData.question3.B[1].first?.front
				this.answer3BDiafragmaFirst = initialData.question3.B[1].first?.diafragma
				this.answer3BOtherFirst = initialData.question3.B[1].first?.other
				this.answer3BProfileSecond = initialData.question3.B[1].second?.profile
				this.answer3BFrontSecond = initialData.question3.B[1].second?.front
				this.answer3BDiafragmaSecond = initialData.question3.B[1].second?.diafragma
				this.answer3BOtherSecond = initialData.question3.B[1].second?.other
				this.answer3BExtension0D = initialData.question3.B[1].extension?.OD
				this.answer3BExtension0E = initialData.question3.B[1].extension?.OE
				this.answer3BExtensionD123 = initialData.question3.B[1].extension?.D123
				this.answer3BExtensionE123 = initialData.question3.B[1].extension?.E123
				this.answer3BWidth0D = initialData.question3.B[1].width?.OD
				this.answer3BWidth0E = initialData.question3.B[1].width?.OE
				this.answer3BWidthDabc = initialData.question3.B[1].width?.Dabc
				this.answer3BWidthEabc = initialData.question3.B[1].width?.Eabc
			}

			this.answer3C = initialData.question3?.C

			if (!!initialData.question3?.D) {
				this.answer3D = initialData.question3.D[0]
				this.answer3DProfileFirst = initialData.question3.D[1].first?.profile
				this.answer3DFrontFirst = initialData.question3.D[1].first?.front
				this.answer3DProfileSecond = initialData.question3.D[1].second?.profile
				this.answer3DFrontSecond = initialData.question3.D[1].second?.front
				this.answer3DExtension0D = initialData.question3.D[1].extension?.OD
				this.answer3DExtension0E = initialData.question3.D[1].extension?.OE
				this.answer3DExtensionD123 = initialData.question3.D[1].extension?.D123
				this.answer3DExtensionE123 = initialData.question3.D[1].extension?.E123
				this.answer3DWidth0D = initialData.question3.D[1].width?.OD
				this.answer3DWidth0E = initialData.question3.D[1].width?.OE
				this.answer3DWidthDabc = initialData.question3.D[1].width?.Dabc
				this.answer3DWidthEabc = initialData.question3.D[1].width?.Eabc
			}

			this.answer4A = initialData.question4?.A
			this.answer4B = initialData.question4?.B
			this.answer4C = initialData.question4?.C
		}
	}
}
