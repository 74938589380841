import React, { useEffect, useState } from 'react'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import moment from 'moment/moment'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { ChartTrendGrowthAnalysisCP } from 'modules/reports/components/charts/chart-trend-growth-analysis/ChartTrendGrowthAnalysisCP'
import { FiltersReportCommonCP } from 'modules/reports/components/filters-report-common/FiltersReportCommonCP'

interface ICPProps {
	reportType: ReportTypeEnum
}

/**
 */
export function ScreenContentGrowthAnalysisReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<CommonReportsFilterFormModel>()
	const formStateManager = useFormStateManager<CommonReportsFilterFormModel>(formValidator)

	const [shouldLoad, setShouldLoad] = useState<number>(0)

	useEffect(init, [props.reportType])

	/**
	 */
	function init(): void {
		setFormValidator(
			new CommonReportsFilterFormModel({
				dateRange: {
					beginDate: moment().startOf('month').add(-11, 'month').toDate(),
					endDate: moment().endOf('month').toDate(),
				},
			}),
		)
		setShouldLoad(TableUtils.getReloadNumber())
	}

	if (!formStateManager.getFormValues()) return <></>

	return (
		<>
			<HeaderCP title={'Análise de Crescimento'} />
			<FiltersReportCommonCP
				reportType={props.reportType}
				filterFormStateManager={formStateManager}
				onFilter={init}
				fieldsConfig={{
					hideDoctorFilter: props.reportType !== ReportTypeEnum.DOCTOR_GROUP_REPORT,
					dateRangeMode: 'month',
				}}
			/>

			<LayoutSubmenuContentCP>
				<ChartTrendGrowthAnalysisCP reportType={props.reportType} filterStateManager={formStateManager} load={shouldLoad} />
			</LayoutSubmenuContentCP>
		</>
	)
}
