import { FinishIconCP } from '_old/main/common/components/icons/FinishIconCP'
import React, { useEffect, useState } from 'react'
import { ButtonMultiActionCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonMultiActionCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ModalSendExamToPendingAnalysisCP } from 'modules/exams/components/exams/modal-send-exam-to-pending-analysis/ModalSendExamToPendingAnalysisCP'
import { ModalFinalizeMedicalReportCP } from 'modules/exams/components/medical-report/modal-finalize-medical-report/ModalFinalizeMedicalReportCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalReportSaveAsDraftRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportSaveAsDraftRequestDTO'
import { RoutingHelper } from 'config/RoutingHelper'
import { ModalDiscardMedicalReportCP } from 'modules/exams/components/medical-report/modal-discard-medical-report/ModalDiscardMedicalReportCP'
import { MedicalReportLocalStorageUtil } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/MedicalReportLocalStorageUtil'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	searchExamDto?: ExamSearchRequestDTO
}

/**
 * Botao com acoes principais relativas da tela de LAUDOS de 01 EXAME.
 */
export function ButtonMultiActionMedicalReportCP(props: ICPProps): JSX.Element {
	const [isDiscardMedicalReportModalVisible, setIsDiscardMedicalReportModalVisible] = useState<boolean>(false)
	const [isSendToPendingAnalysisModalVisible, setIsSendToPendingAnalysisModalVisible] = useState<boolean>(false)
	const [isFinishExamModalVisible, setIsFinishExamModalVisible] = useState<boolean>(false)

	const saveAsDraftReportRequest = useRequest<'void'>('none')
	useEffect(onSaveAsDraftRequestChange, [saveAsDraftReportRequest.isAwaiting])

	/**
	 * Salva o laudo
	 */
	function saveAsDraft(): void {
		if (!FormStateManagerUtils.validateRequiredFields(props.formStateManager)) return

		const formValues = props.formStateManager.getFormValues()!
		const dto: MedicalReportSaveAsDraftRequestDTO = {
			examCode: props.exam.code,
			medicalReportsContents: formValues.medicalReports,
		}
		saveAsDraftReportRequest.runRequest(MedicalReportRequests.saveAsDraft(dto))
	}

	/**
	 * Retorno api.
	 */
	function onSaveAsDraftRequestChange(): void {
		// Avalia retorno da ultima requisicao
		if (
			!RequestUtils.isValidRequestReturn(
				saveAsDraftReportRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		MedicalReportLocalStorageUtil.discardMedicalReportLocalStorage(props.exam.code)
		RoutingHelper.goToExams(undefined, props.searchExamDto, true)
	}

	return (
		<WrapperSCP>
			<ButtonMultiActionCP
				options={[
					{
						icon: <FinishIconCP size={18} color={ThemeFrameworkCommon.browserDefaultColorDark} />,
						label: 'Finalizar Laudo(s)',
						onClick: () => setIsFinishExamModalVisible(true),
					},
					{
						icon: <IconICP iconName={'edit'} size={12} />,
						label: 'Salvar Rascunho',
						onClick: saveAsDraft,
					},
					{
						icon: <IconICP iconName={'rollback'} size={12} />,
						label: 'Desistir do Laudo',
						onClick: () => setIsDiscardMedicalReportModalVisible(true),
						hide: props.exam.doctorGroup?.config?.hasAdministrativeAnalysis,
					},
					{
						icon: <IconICP iconName={'file-search'} size={12} />,
						label: 'Devolver Exame para Análise',
						onClick: () => setIsSendToPendingAnalysisModalVisible(true),
					},
				]}
				type={'primary'}
			/>

			<ModalSendExamToPendingAnalysisCP
				exam={props.exam}
				visible={isSendToPendingAnalysisModalVisible}
				onCancel={() => setIsSendToPendingAnalysisModalVisible(false)}
				onSave={() => {
					setIsSendToPendingAnalysisModalVisible(false)
					RoutingHelper.goToExams(undefined, props.searchExamDto, true)
				}}
			/>

			<ModalDiscardMedicalReportCP
				examCode={props.exam.code}
				onCancel={() => setIsDiscardMedicalReportModalVisible(false)}
				onSave={() => {
					setIsDiscardMedicalReportModalVisible(false)
					RoutingHelper.goToExams(undefined, props.searchExamDto, true)
				}}
				visible={isDiscardMedicalReportModalVisible}
			/>

			<ModalFinalizeMedicalReportCP
				exam={props.exam}
				formStateManager={props.formStateManager}
				visible={isFinishExamModalVisible}
				onCancel={() => setIsFinishExamModalVisible(false)}
				searchExamDto={props.searchExamDto}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	.ant-btn-group {
		align-items: center;
	}
`
