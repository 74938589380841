import {
	ContentBlock,
	ContentState,
	convertToRaw,
	DraftStyleMap,
	EditorState,
	genKey,
	RawDraftContentBlock,
	RawDraftContentState,
	RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { getSelectionCustomInlineStyle, toggleCustomInlineStyle } from 'draftjs-utils'
import * as _ from 'lodash'
import { TextEditorConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorConfig'
import { StringUtils } from '_old/main/common/utils/StringUtils'

/**
 * Encapsula definicao de metodos uteis ao uso do editor de textos wysiwyg / Draft JS customizado.
 */
export class TextEditorUtils {
	/**
	 * Determina & retorna valor para propriedade 'customStyleMap' para componente do editor.
	 */
	static getCustomStyleMapProp(): DraftStyleMap {
		const customStylesMap = {
			[TextEditorConfig.DEFAULT_STYLES_KEY]: this.getParsedCustomStyles(),
		}

		customStylesMap['center'] = { textAlign: 'center' }
		customStylesMap['left'] = { textAlign: 'left' }
		customStylesMap['right'] = { textAlign: 'right' }
		customStylesMap['justify'] = { textAlign: 'justify' }

		const configFontSizes = [TextEditorConfig.FONT_SIZE_SMALL, TextEditorConfig.FONT_SIZE_LARGE]

		configFontSizes.forEach((fontSize: number) => (customStylesMap[`fontsize-${fontSize}`] = { fontSize }))

		return customStylesMap
	}

	/**
	 * Gera & retorna objeto devidamente formatado contendo mapa de estilos padrao customizados do editor (para aplicaco inline no formato JSX).
	 */
	static getParsedCustomStyles(): React.CSSProperties {
		const defaultStyleKeys = Object.keys(TextEditorConfig.DEFAULT_STYLES)
		const defaultStyles: DraftStyleMap = {}

		defaultStyleKeys.forEach((styleKey) => {
			const fixedStyleName = StringUtils.toCamelCase(styleKey)
			defaultStyles[fixedStyleName] = TextEditorConfig.DEFAULT_STYLES[styleKey]
		})

		return defaultStyles
	}

	/** Garante a aplicacao de estilos padrao fixos a todos os trechos de texto do editor. */
	public static getStateWithDefaultStyles(nextState: EditorState): EditorState {
		if (!nextState.getCurrentInlineStyle().has(TextEditorConfig.DEFAULT_STYLES_KEY))
			nextState = RichUtils.toggleInlineStyle(nextState, TextEditorConfig.DEFAULT_STYLES_KEY)
		return nextState
	}

	/** Identifica & retorna qual tamanho de fonte da selecao atual. */
	public static getSelectionFontSize(editorState: EditorState): number | undefined {
		const styleState = getSelectionCustomInlineStyle(editorState, ['fontSize'])
		const fontSizeStateStr = _.get(styleState, 'fontSize', '')
		const fontSizeStr = fontSizeStateStr.replace(/\D/g, '')
		return !!fontSizeStr ? +fontSizeStr : undefined
	}

	/** Aplica 01 determinado tamanho de fonte a selecao atual. */
	public static getStateWithSetFontSize(editorState: EditorState, fontSize = TextEditorConfig.FONT_SIZE_SMALL): EditorState {
		return toggleCustomInlineStyle(editorState, 'fontSize', fontSize)
	}

	/** Gera & retorna novo objeto bloco de conteudo generico (padrao draft js). */
	public static getNewBlock(props: { [key in keyof RawDraftContentBlock]?: any }): RawDraftContentBlock {
		const defaultBlock = {
			key: genKey(),
			type: 'unstyled',
			text: '',
			depth: 0,
			inlineStyleRanges: [],
			entityRanges: [],
			data: {},
		}

		return Object.assign(defaultBlock, props)
	}

	/** Gera & retorna conetudo HTML a partir do estado do editor. */
	public static getHtmlFromDraftJSState(content: ContentState, tableBlockRenderer?: (block: ContentBlock) => string): string {
		if (!tableBlockRenderer) return stateToHTML(content)

		const options = {
			inlineStyles: {
				'fontsize-16': { style: { fontSize: TextEditorConfig.FONT_SIZE_LARGE } },
				center: { style: { textAlign: 'center', display: 'block' } },
				right: { style: { textAlign: 'right', display: 'block' } },
				justify: { style: { textAlign: 'justify', display: 'block' } },
				left: { style: { textAlign: 'left', display: 'block' } },
			},
			blockRenderers: {
				atomic: tableBlockRenderer,
			},
		}

		return stateToHTML(content, options)
	}

	/** Captura & retorna o texto contido na selecao atual do editor. */
	static getSelectionText(editorState: EditorState): string {
		const selection = editorState.getSelection()
		if (selection.isCollapsed()) return ''

		const startKey = selection.getStartKey()
		const endKey = selection.getEndKey()

		const blocks = editorState.getCurrentContent().getBlocksAsArray()
		const selectedBlocks: ContentBlock[] = []

		for (const block of blocks) {
			const hasStarted = !!selectedBlocks.length
			if (!hasStarted && block.getKey() !== startKey) continue

			selectedBlocks.push(block)

			if (block.getKey() === endKey) break
		}

		return selectedBlocks
			.map((block: ContentBlock) => {
				const key = block.getKey()
				const text = block.getText()

				const start = key === startKey ? selection.getStartOffset() : 0
				const end = key === endKey ? selection.getEndOffset() : text.length

				return text.slice(start, end)
			})
			.join('\n')
	}

	static getEmptyContent(): RawDraftContentState {
		return convertToRaw(EditorState.createEmpty().getCurrentContent())
	}
}
