import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { DoctorLevelEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/DoctorLevelEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	isMultiple?: boolean
	selectedLevel?: DoctorLevelEnum
	required?: boolean
}

/**
 * Select de nível de especialidade do médico
 */
export function SelectDoctorLevelCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label ?? 'Nível'}
			value={props.selectedLevel}
			fieldName={props.fieldName}
			isMultiple={props.isMultiple}
			formStateManager={props.formStateManager}
			required={props.required}
			allowClear={true}
			options={Object.values(DoctorLevelEnum).map((level) => ({
				value: level,
				label: DoctorLevelEnum[level],
			}))}
		/>
	)
}
