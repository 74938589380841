import React, { SyntheticEvent } from 'react'
import { EditorState, RichUtils } from 'draft-js'
import { IToolbarButtonProps } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/IToolbarButtonProps'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

const CLASS_BTN_ACTIVE = 'style-active'
const CLASS_BTN_DISABLED = 'style-disabled'

/**
 * Componente de botao generico para barra de ferramentas do editor wysiwyg / Draft JS customizado.
 */
export function ToolbarButtonICP(props: IToolbarButtonProps): JSX.Element | null {
	function isActive(): boolean {
		if (isDisabled()) return false

		if (!!props.isActive) return props.isActive(props.editorState)

		if (!!props.inlineStyle) return isInlineStyleActive()

		if (!!props.blockType) return isBlockStyleActive()

		return false
	}

	function isInlineStyleActive(): boolean {
		if (!props.editorState || !props.inlineStyle) return false
		return props.editorState.getCurrentInlineStyle().has(props.inlineStyle)
	}

	function isBlockStyleActive(): boolean {
		if (!props.editorState || !props.blockType) return false

		const type = props.editorState.getCurrentContent().getBlockForKey(props.editorState.getSelection().getStartKey()).getType()

		return type === props.blockType
	}

	function onClick(event: SyntheticEvent): void {
		event.preventDefault()

		if (isDisabled()) return

		const editorState = props.editorState!
		let nextState: EditorState | void

		if (!!props.onClick) nextState = props.onClick(editorState)
		else {
			const isBlockStyle = !!props.blockType
			nextState = isBlockStyle
				? RichUtils.toggleBlockType(editorState, props.blockType!)
				: RichUtils.toggleInlineStyle(editorState, props.inlineStyle!)
		}

		if (!!nextState && !!props.onEditorStateChange) props.onEditorStateChange(nextState)
	}

	function isDisabled(): boolean {
		return !!props.isDisabled ? props.isDisabled() : false
	}

	function getClassName(): string {
		let className = isActive() ? CLASS_BTN_ACTIVE : ''
		className += ' '
		className += isDisabled() ? CLASS_BTN_DISABLED : ''
		return className.trim()
	}

	if (!props.inlineStyle && !props.blockType && !props.onClick) return null

	return (
		<div onMouseDown={(event) => event.preventDefault()}>
			<ButtonSCP onClick={onClick} className={getClassName()} disabled={isDisabled()}>
				{props.innerContent}
			</ButtonSCP>
		</div>
	)
}

const ButtonSCP = styled.button`
	border: 1px solid ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	border-radius: 4px;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: border 0.3s, box-shadow 0.5s, background-color 0.5s;
	outline: none;
	margin: 0 0.3vw;
	cursor: pointer;
	opacity: 1;
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};

	&:not(.${CLASS_BTN_DISABLED}):hover,
	&.${CLASS_BTN_ACTIVE} {
		border: 1px solid ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		background-color: #ffd71e55;
	}

	&.${CLASS_BTN_DISABLED} {
		cursor: not-allowed;
		opacity: 0.3;
	}
`
