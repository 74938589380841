import React, { useState } from 'react'
import { TableClinicAdminCP } from 'modules/admin-beerads/components/table-clinics-admin/TableClinicAdminCP'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'
import { DrawerClinicCP } from 'modules/clinic/components/drawer-clinic-data/DrawerClinicCP'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ModalClinicLogoCP } from 'modules/clinic/components/modal-clinic-logo/ModalClinicLogoCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ButtonNewClinicCP } from 'modules/clinic/components/button-new-clinic/ButtonNewClinicCP'

interface ICPProps {
	title: string
}

export function ScreenContentAdminClinicsCP(props: ICPProps): JSX.Element {
	const [isClinicDrawerVisible, setIsClinicDrawerVisible] = useState(false)
	const [isLogoModalVisible, setIsLogoModalVisible] = useState(false)

	const [selectedClinic, setSelectedClinic] = useState<ClinicResponseDTO>()
	const [reloadData, setReloadData] = useState<number>(new Date().getTime())

	return (
		<>
			<HeaderCP title={props.title}>
				<FlexCP>
					<ButtonNewClinicCP onSave={() => setReloadData(TableUtils.getReloadNumber())} />
				</FlexCP>
			</HeaderCP>

			<LayoutSubmenuContentCP>
				<TableClinicAdminCP
					reloadData={reloadData}
					onEdit={(clinic) => {
						setSelectedClinic(clinic)
						setIsClinicDrawerVisible(true)
					}}
					onUploadAction={(clinic) => {
						setSelectedClinic(clinic)
						setIsLogoModalVisible(true)
					}}
				/>
			</LayoutSubmenuContentCP>

			<DrawerClinicCP
				onCancel={() => setIsClinicDrawerVisible(false)}
				onSave={() => {
					setIsClinicDrawerVisible(false)
					setReloadData(new Date().getTime())
				}}
				visible={isClinicDrawerVisible}
			/>

			{!!selectedClinic && (
				<ModalClinicLogoCP
					visible={isLogoModalVisible}
					onCancel={() => setIsLogoModalVisible(false)}
					onSave={() => {
						setIsLogoModalVisible(false)
						setReloadData(TableUtils.getReloadNumber())
					}}
					clinicCode={selectedClinic.code}
					clinicLogoUrl={selectedClinic.imageUrl}
				/>
			)}
		</>
	)
}
