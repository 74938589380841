import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectGenderCP } from 'submodules/nerit-framework-ui/features/people/components/select-gender/SelectGenderCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { ExamDataFormModel } from 'modules/exams/components/exams/modal-exam-data/inner/form-model/ExamDataFormModel'

interface ICPProps {
	formStateManager: IFormStateManager<ExamDataFormModel>
	shouldDisableEditSomeData: boolean
}

/**
 */
export function FormExamPatientDataICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP textSize={'normal'} underLine={true}>
				Dados do Paciente
			</TitleCP>
			<RowCP>
				<ColumnCP size={24}>
					<InputCP
						label={'Nome'}
						formStateManager={props.formStateManager}
						fieldName={'patientName'}
						required={true}
						disabled={props.shouldDisableEditSomeData}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={10}>
					<InputCP label={'ID'} formStateManager={props.formStateManager} fieldName={'patientId'} />
				</ColumnCP>
				<ColumnCP size={8}>
					<InputCP
						label={'Data de Nascimento'}
						mask={InputMaskTypeEnum.DATE}
						formStateManager={props.formStateManager}
						fieldName={'patientBirthday'}
						required={true}
						disabled={props.shouldDisableEditSomeData}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<SelectGenderCP
						required={true}
						formStateManager={props.formStateManager}
						fieldName={'patientGender'}
						disabled={props.shouldDisableEditSomeData}
					/>
				</ColumnCP>
			</RowCP>
		</>
	)
}
