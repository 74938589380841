import { FormSetPasswordWrapperCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/FormSetPasswordWrapperCP'
import { FormSetPasswordInputsCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/FormSetPasswordInputsCP'
import { FormSetPasswordActionsCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/FormSetPasswordActionsCP'
import { FormSetPasswordValidationCP } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/FormSetPasswordValidationCP'

export const FormSetPasswordCP = {
	Wrapper: FormSetPasswordWrapperCP,
	Inputs: FormSetPasswordInputsCP,
	Actions: FormSetPasswordActionsCP,
	Validation: FormSetPasswordValidationCP,
}
