import { DatePicker, Input } from 'antd'
import dateInputLocalePtBR from 'submodules/nerit-framework-ui/common/components/form-fields/input/inner/DateInputLocalePtBR.json'
import React from 'react'
import styled from 'styled-components'
import { FormItemICP } from '../inner/FormItemICP'
import { IFormItemInputCommonProps } from '../inner/interfaces/IFormItemInputCommonProps'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { SearchIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/SearchIconCP'
import { FontSizeTP } from 'submodules/nerit-framework-ui/theme/_old/types/ThemeTypes'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export type InputTypeTP = 'text' | 'email' | 'number' | 'password' | 'search' | 'date' | 'tel'

export interface IInputCPProps<FModelTP extends FormModel> extends IFormItemInputCommonProps<FModelTP> {
	type?: InputTypeTP
	icon?: JSX.Element
	min?: number
	max?: number
	loading?: boolean
	showTime?: boolean
	autoFocus?: boolean
	placeholder?: string
	prefix?: boolean
	allowClear?: boolean
	disabled?: boolean
	maxlength?: number
	step?: number
}

interface IFormattedInputProps<FModelTP extends FormModel> extends Omit<IInputCPProps<FModelTP>, 'prefix' | 'suffix'> {
	suffix?: JSX.Element
	prefix?: JSX.Element
	maxLength?: number
}

const inputTypes = {
	date: (props: IInputCPProps<any>, suffixIcon?: JSX.Element) => (
		<DatePicker
			locale={dateInputLocalePtBR}
			suffixIcon={suffixIcon}
			placeholder={props.placeholder}
			showTime={props.showTime}
			allowClear={props.allowClear}
			disabled={props.disabled}
		/>
	),
	password: (props: IInputCPProps<any>) => <Input.Password {...props} />,
}

/**
 * Input generico para formularios.
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 */
export function InputCP<FModelTP extends FormModel = any>(props: IInputCPProps<FModelTP>): JSX.Element {
	function renderInput(): JSX.Element {
		let suffixIcon: JSX.Element | undefined

		const inputProps: IFormattedInputProps<FModelTP> = {
			...props,
			type: props.type ?? 'text',
			suffix: !props.prefix ? suffixIcon : undefined,
			prefix: props.prefix ? suffixIcon : undefined,
			maxLength: props.maxlength ?? (props.mask === InputMaskTypeEnum.PHONE ? 15 : undefined),
		}

		if (!!props.loading) suffixIcon = <LoadingIconCP size={14} />
		else if (!!props.icon) suffixIcon = props.icon
		else if (props.type === 'search') suffixIcon = <SearchIconCP size={14} />

		return props.type && inputTypes[props.type] ? inputTypes[props.type](inputProps, suffixIcon) : <Input {...inputProps} />
	}

	return (
		<InputWrapperSCP fontSize={props.fontSize ?? 'normal'}>
			<FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
				label={props.label}
				onChange={props.onChange}
				onBlur={props.onBlur}
				fieldName={props.fieldName}
				formStateManager={props.formStateManager}
				required={props.required}
				width={props.width}
				onFormSubmit={props.onFormSubmit}
				value={props.value}
				mask={props.mask}
				fontSize={props.fontSize}
				errorMessage={props.errorMessage}
				noValidation={props.noValidation}
				debug={props.debug}
				reloadField={props.reloadField}
				marginTop={props.marginTop}
				marginRight={props.marginRight}
				marginBottom={props.marginBottom}
				marginLeft={props.marginLeft}
				hint={props.hint}
				keepMask={props.keepMask}
			>
				{renderInput()}
			</FormItemICP>
		</InputWrapperSCP>
	)
}

const InputWrapperSCP = styled.div<{ fontSize: FontSizeTP }>`
	width: 100%;

	.ant-input[disabled] {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark}80;
	}

	svg {
		fill: ${ThemeFrameworkCommon.browserDefaultColorDark}80;
	}
`
