import React from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps {
	clinicFormStateManager: IFormStateManager<ClinicFormModel>
}

/**
 * Formulário das informações da clínica
 */
export function FormClinicResponsibleDataCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
				Contato Principal
			</TitleCP>

			<RowCP>
				<ColumnCP size={12}>
					<InputCP label={'Nome'} formStateManager={props.clinicFormStateManager} fieldName={'responsibleName'} required={true} />
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP label={'E-mail'} fieldName={'responsibleEmail'} formStateManager={props.clinicFormStateManager} required={true} />
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<InputCP
						label={'Telefone 1'}
						fieldName={'responsiblePhoneOne'}
						mask={InputMaskTypeEnum.PHONE}
						formStateManager={props.clinicFormStateManager}
						required={true}
					/>
				</ColumnCP>
			</RowCP>
		</>
	)
}
