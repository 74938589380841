import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PersonClinicsRequests } from 'submodules/beerads-sdk/services/clinics/person-clinic/PersonClinicsRequests'
import { PersonClinicTooglePersonAsAdminRequestDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/requests/PersonClinicTooglePersonAsAdminRequestDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
	role: UserTypeEnum
	personCode: number
	doctorGroupCode?: number
	clinicCode: number
	isAdmin: boolean
	onSave?: () => void
	size?: ButtonSizeTP
}

/**
 * Botao que colocoa uma pessoa do grupo como admin ou tira o admin dela.
 */
export function ButtonToggleClinicPersonAdminCP(props: ICPProps): JSX.Element {
	const [isAdmin, setIsAdmin] = useState<boolean>(props.isAdmin)

	const updateIsAdminRequest = useRequest<void>('none')
	useEffect(onUpdateIsAdminRequestChange, [updateIsAdminRequest.isAwaiting])

	/**
	 * Muda ou nao pra admin.
	 */
	function toogleAdmin(): void {
		const dto: PersonClinicTooglePersonAsAdminRequestDTO = {
			personCode: props.personCode,
			role: props.role,
			isAdmin: !isAdmin,
			doctorGroupCode: props.doctorGroupCode,
			clinicCode: props.clinicCode,
		}
		updateIsAdminRequest.runRequest(PersonClinicsRequests.toogleAsAdmin(dto))
	}

	/**
	 * Retorno ao tornar pessoa admin.
	 */
	function onUpdateIsAdminRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateIsAdminRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				'Privilégios atualizados com sucesso!',
				true,
			)
		)
			return

		setIsAdmin(!isAdmin)
		if (props.onSave) props.onSave()
	}

	return (
		<ButtonCP
			disabled={AppStateUtils.getLoggedUser()!.code === props.personCode}
			type={isAdmin ? 'primary' : undefined}
			tooltip={isAdmin ? 'Retirar permissão de administrador' : 'Conceder permissão de administrador'}
			icon={'crown'}
			confirmMsg={`Você tem certeza que deseja ${isAdmin ? 'tirar permissão de administrador?' : 'tornar administrador?'}`}
			onClick={toogleAdmin}
			size={props.size}
			loading={updateIsAdminRequest.isAwaiting}
		/>
	)
}
