import React, { useEffect, useState } from 'react'
import { SelectTeamCP } from 'modules/doctor-groups/components/teams/select-team/SelectTeamCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ClinicDoctorGroupAddTeamsRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAddTeamsRequestDTO'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'

interface ICPProps {
	doctorGroupCode: number
	clinicDoctorGroupCode: number
	onChangeData: () => void
}

/**
 * Aba com os medicos alocados na clinica pelo grupo
 */
export function SelectClinicDoctorGroupTeamsCP(props: ICPProps): JSX.Element {
	const [teamCodes, setTeamCodes] = useState<number[]>([])
	const getClinicDoctorGroupTeamsRequest = useRequest<ListResponseDTO<NameAndCodeResponseDTO>>()
	useEffect(onGetClinicDoctorGroupTeamsRequestChange, [getClinicDoctorGroupTeamsRequest.isAwaiting])

	const linkToTeamRequest = useRequest<ListResponseDTO<NameAndCodeResponseDTO>>()
	useEffect(onLinkToTeamRequestChange, [linkToTeamRequest.isAwaiting])

	useEffect(init, [props.clinicDoctorGroupCode])

	/**
	 * Atualiza os dados
	 */
	function init(): void {
		getClinicDoctorGroupTeamsRequest.runRequest(ClinicDoctorGroupRequests.getTeams(props.clinicDoctorGroupCode))
	}

	/**
	 * Retorno ao obter times
	 */
	function onGetClinicDoctorGroupTeamsRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicDoctorGroupTeamsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setTeamCodes(getClinicDoctorGroupTeamsRequest.responseData?.list?.map((team) => team.code) ?? [])
	}

	/**
	 * Atualiza os dados
	 */
	async function linkToTeam(teamCodes: number[]): Promise<void> {
		setTeamCodes(teamCodes)

		const dto: ClinicDoctorGroupAddTeamsRequestDTO = {
			teamsCodes: teamCodes,
		}
		linkToTeamRequest.runRequest(ClinicDoctorGroupRequests.addTeams(props.clinicDoctorGroupCode, dto))
	}

	/**
	 * Retorno do link
	 */
	function onLinkToTeamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				linkToTeamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		setTeamCodes(linkToTeamRequest.responseData?.list?.map((team) => team.code) ?? [])
		props.onChangeData()
	}

	return (
		<SelectTeamCP
			label={'Times que poderão ver exames desta unidade hospitalar'}
			isMultiple={true}
			doctorGroupCode={props.doctorGroupCode}
			value={teamCodes}
			onChange={linkToTeam}
			loading={getClinicDoctorGroupTeamsRequest.isAwaiting || linkToTeamRequest.isAwaiting}
		/>
	)
}
