import { Button } from 'antd'
import React from 'react'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { ButtonContentICP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonContentICP'
import { ColorUtils } from 'submodules/nerit-framework-ui/common/utils/ColorUtils'
import { TooltipPlacementTP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

type WrapperScpPropsTP = {
	marginRight?: number
	marginLeft?: number
	marginBottom?: number
	marginTop?: number
	color?: string
	horizontalPadding?: number
	bgColor?: string
	borderColor?: string
}

type CustomPropsTP = {
	onClick?: () => void
	type?: ButtonTypeTP
	shape?: 'circle' | 'circle-outline' | 'round'
	size?: ButtonSizeTP
	ghost?: boolean

	tooltip?: string
	tooltipPlacement?: TooltipPlacementTP
	confirmMsg?: string

	loading?: boolean
	disabled?: boolean
	isSubmit?: boolean

	value?: string | number

	icon?: string
	style?: React.CSSProperties
}

const CLASS_HORIZONTAL_PADDING = 'button_horizontal_padding'

export interface IButtonCPProps extends React.PropsWithChildren<CustomPropsTP & WrapperScpPropsTP> {}

/**
 * COMPONENTE
 * Botao generico customizavel.
 */
export function ButtonCP(props: IButtonCPProps): JSX.Element {
	return (
		<WrapperSCP
			marginLeft={props.marginLeft}
			marginRight={props.marginRight}
			marginBottom={props.marginBottom}
			marginTop={props.marginTop}
			color={props.color}
			bgColor={props.bgColor}
			borderColor={props.borderColor}
			horizontalPadding={props.horizontalPadding}
			className={!!props.horizontalPadding ? CLASS_HORIZONTAL_PADDING : ''}
			onlyIcon={!!props.icon && !props.children}
		>
			<ButtonContentICP
				onClick={props.onClick}
				tooltip={props.tooltip}
				tooltipPlacement={props.tooltipPlacement}
				confirmMsg={props.disabled ? undefined : props.confirmMsg}
			>
				<Button
					htmlType={!!props.isSubmit ? 'submit' : 'button'}
					onClick={!props.confirmMsg ? props.onClick : undefined}
					disabled={props.disabled}
					type={props.type ?? 'default'}
					size={props.size}
					ghost={props.ghost ?? false}
					shape={props.shape}
					icon={props.icon}
					loading={props.loading}
					style={props.style}
					value={props.value}
				>
					{props.children}
				</Button>
			</ButtonContentICP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<WrapperScpPropsTP & { onlyIcon: boolean }>`
	margin-left: ${(props) => props.marginLeft ?? 0}px;
	margin-right: ${(props) => props.marginRight ?? 0}px;
	margin-bottom: ${(props) => props.marginBottom ?? 0}px;
	margin-top: ${(props) => props.marginTop ?? 0}px;
	color: ${(props) => props.color ?? 'inherit'};

	.ant-btn-sm {
		.anticon {
			margin-right: ${(props) => (!props.onlyIcon ? '-2px !important' : undefined)};
		}

		span {
			font-size: 0.7rem;
		}
	}

	&.${CLASS_HORIZONTAL_PADDING} {
		.ant-btn {
			padding: 0 ${(props) => props.horizontalPadding}px;
		}
	}

	.ant-btn {
		display: flex;
		flex-direction: row;
		align-items: center;
		transition: background-color 0.2s ease-in;

		&.ant-btn-primary {
			background-color: ${ThemeProject.primary};
			border-color: ${ThemeProject.primary};
			color: ${ThemeProject.menuItemColor};

			:disabled {
				border: none;
			}

			&:hover:not([disabled]) {
				//background: ${ColorUtils.lightenColor(ThemeProject.primary, 65)};
			}

			&.ant-btn-background-ghost > i {
				color: ${ThemeProject.primary};
			}
		}

		&.ant-btn[disabled] {
			opacity: 0.7;
		}

		&.ant-btn-ghost {
			&:not(:disabled) {
				background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight}40;
				color: ${ThemeProject.colorText} !important;
			}
		}

		&.ant-btn-icon-only {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&.ant-btn-link {
			height: unset;
			width: unset;
			color: ${(props) => props.color ?? 'inherit'};

			&::before {
				background-color: transparent;
			}
		}

		&.ant-btn-loading {
			display: flex;
			align-items: center;

			.anticon-loading {
				margin-right: 7px;
			}
		}

		.anticon {
			display: flex;
			align-items: center;
		}

		.ant-typography {
			margin-left: 4px;
		}
	}
`
