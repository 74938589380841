import React from 'react'
import { PatientLoginFormModel } from 'modules/patient-portal/components/form-patient-login/inner/PatientLoginFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import styled from 'styled-components'

interface ICPProps {
	formStateManager: IFormStateManager<PatientLoginFormModel>
	onDoLogin: () => void
	loading?: boolean
}

/**
 * Formulario de login de paciente.
 */
export function FormPatientLoginCP(props: ICPProps): JSX.Element {
	return (
		<FormContainerSCP>
			<FormTitleSCP>LAUDO ONLINE</FormTitleSCP>

			<InputCP
				label={'Código do Exame'}
				fieldName={'examCode'}
				formStateManager={props.formStateManager}
				type={'number'}
				required={true}
				icon={<IconICP iconName={'lock'} />}
			/>

			<InputCP
				label={'Data de Nascimento'}
				fieldName={'patientBirthday'}
				mask={InputMaskTypeEnum.DATE}
				keepMask={true}
				formStateManager={props.formStateManager}
				required={true}
				icon={<IconICP iconName={'calendar'} />}
			/>

			<InputCP
				label={'Nome Completo'}
				fieldName={'patientName'}
				formStateManager={props.formStateManager}
				required={true}
				icon={<IconICP iconName={'user'} />}
			/>

			<ButtonCP size={'large'} type={'primary'} onClick={props.onDoLogin} style={{ marginTop: 20 }} loading={props.loading}>
				Entrar
			</ButtonCP>
		</FormContainerSCP>
	)
}

const FormContainerSCP = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 365px;
	height: 100%;
	margin-top: 30px;
`

const FormTitleSCP = styled.span`
	margin-bottom: 20px;
	font-weight: bold;
`
