import { ScreenContentUserLoginCP } from 'modules/auth/screens/screen-user-login/content/ScreenContentUserLoginCP'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { LayoutImageSiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-image-sider/LayoutImageSiderCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'
import { IntercomCP } from 'app/components/intercom/IntercomCP'

/**
 * Login de usuario do sistema (medico / funcionario de clinica).
 */
export function ScreenUserLogin(): JSX.Element {
	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)
	const forceLoginParam = Boolean(params.get('forceLogin'))

	if (!!AppStateUtils.getLoggedUser() && !forceLoginParam) return <RedirectCP path={PrivateUserRouter.HOME} />

	if (!!AppStateUtils.getLoggedUser() && !forceLoginParam) return <RedirectCP path={PrivateUserRouter.HOME} />

	return (
		<LayoutImageSiderCP
			sider={{
				width: '60%',
				imageUrl: `${process.env.PUBLIC_URL}/images/brCover4.png`,
			}}
			content={{
				logoUrl: ThemeProject.logoLoginUrl,
				logoWidth: 300,
			}}
		>
			<ScreenContentUserLoginCP />

			<IntercomCP />
		</LayoutImageSiderCP>
	)
}
