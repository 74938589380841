import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class PatientLoginFormModel extends FormModel {
	@IsRequired()
	examCode: string

	@IsRequired()
	patientBirthday: string

	@IsRequired()
	patientName: string

	constructor(initialData?: ObjectPropsTP<PatientLoginFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
