export enum ExamStatusGroupEnum {
	PENDING = 'PENDING',
	AWAITING = 'AWAITING',
	DONE = 'DONE',
	PENDING_ANALYSIS = 'PENDING_ANALYSIS',
	ARCHIVED = 'ARCHIVED',
	RUNNING_SLA_STATUS = 'RUNNING_SLA_STATUS',
}

export enum ExamStatusGroupLabelEnum {
	PENDING = 'Exames Pendentes',
	AWAITING = 'Exames Aguardando Laudo',
	DONE = 'Exames com Laudo',
	PENDING_ANALYSIS = 'Exames Em Análise',
	ARCHIVED = 'Exames Arquivado',
	RUNNING_SLA_STATUS = 'Status do sla em execução',
}
