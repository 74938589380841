import React, { useEffect, useState } from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { ExamUrgencyTypeEnum, ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { RadioOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/inner/RadioOptionTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ClinicDoctorGroupGetUrgencyTypesRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupGetUrgencyTypesRequestDTO'

interface ICPProps {
	type: 'all' | 'clinic' // Se mostra todas as opcoes disponiveis ou apenas as que a clinica tem contratada
	clinicCode?: number
	doctorGroupCode?: number
	disabled?: boolean
	onSelect?: (type: ExamUrgencyTypeEnum) => void
	formStateManager?: IFormStateManager<any>
	fieldName?: string
	label?: string
	loading?: boolean
	value?: ExamUrgencyTypeEnum
}

/**
 * Grupo de botoes de selecao unica do tipo de urgencia.
 */
export function ButtonGroupUrgencyTypeCP(props: ICPProps): JSX.Element {
	const allOptions: RadioOptionTP<ExamUrgencyTypeEnum>[] = Object.values(ExamUrgencyTypeEnum).map((urgency) => ({
		content: ExamUrgencyTypeLabelEnum[urgency],
		value: urgency,
	}))
	const [options, setOptions] = useState<RadioOptionTP<ExamUrgencyTypeEnum>[]>()

	const getClinicExamUrgencyTypesRequest = useRequest<ListResponseDTO<ExamUrgencyTypeEnum>>()
	useEffect(onGetClinicExamUrgencyTypesRequestChange, [getClinicExamUrgencyTypesRequest.isAwaiting])

	useEffect(init, [props.doctorGroupCode])

	/**
	 * Init.
	 */
	function init(): void {
		if (props.type === 'all') {
			setOptions(allOptions)
			return
		}

		if (!props.doctorGroupCode || !props.clinicCode) {
			setOptions(allOptions)
			return
		}

		// Se tiver passado pela clinica, obtem os que a clinica tem vinculo para desabilitar as opcoes
		const dto: ClinicDoctorGroupGetUrgencyTypesRequestDTO = {
			clinicCode: props.clinicCode,
			doctorGroupCode: props.doctorGroupCode,
		}
		getClinicExamUrgencyTypesRequest.runRequest(ClinicDoctorGroupRequests.searchUrgencyTypes(dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicExamUrgencyTypesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicExamUrgencyTypesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getClinicExamUrgencyTypesRequest.responseData!.list

		const updatedOptions = [...allOptions]
		// Itera nas opcoes e desabilita as nao retornadas
		updatedOptions.forEach((option) => {
			if (!result.find((urgencyType) => urgencyType === option.value)) option.hide = true
		})
		// Atualiza a lista
		setOptions(updatedOptions)
	}

	return (
		<RadioGroupCP<ExamUrgencyTypeEnum>
			label={props.label}
			loading={getClinicExamUrgencyTypesRequest.isAwaiting || props.loading}
			disabled={props.disabled}
			margin={{ bottom: 20 }}
			type={'button'}
			buttonWidth={110}
			selected={props.value}
			onChange={props.onSelect}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			options={options ?? []}
		/>
	)
}
