import React from 'react'
import styled from 'styled-components'
import { IMedicalReportContent } from 'modules/exams/components/medical-report/editor-medical-report/inner/IMedicalReportContent'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	height?: number
	editorContent?: IMedicalReportContent
}

/**
 * Exibe pre-visualizacao do conteudo a ser exportado pelo editor nos formatos json (padrao draft js) & html.
 */
export function EditorDebugPanelCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<PreviewAreaSCP>
				<OutputPreviewSCP disabled value={props.editorContent?.html} height={props.height} />
			</PreviewAreaSCP>

			<PreviewAreaSCP>
				<OutputPreviewSCP disabled value={JSON.stringify(props.editorContent?.json ?? {}, null, 4)} height={props.height} />
			</PreviewAreaSCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}80;
	border: 1px solid #b7b7b7;
	padding-top: 9px;
	box-shadow: 0 0 13px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}33;
`

const PreviewAreaSCP = styled.div`
	display: flex;
	justify-content: center;
	width: 50%;
`

const OutputPreviewSCP = styled.textarea<{ height?: number }>`
	min-height: ${(props) => props.height ?? 300}px;
	margin-bottom: 10px;
	width: 97%;
`
