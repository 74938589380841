import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { DoctorGroupTooglePersonAsAdminRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupTooglePersonAsAdminRequestDTO'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	personCode: number
	doctorGroupCode: number
	role: UserTypeEnum
	isAdmin: boolean
	onSave?: () => void
}

/**
 * Botao que colocoa uma pessoa do grupo como admin ou tira o admin dela.
 */
export function ButtonToggleDoctorGroupPersonAdminCP(props: ICPProps): JSX.Element {
	const [isAdmin, setIsAdmin] = useState<boolean>(props.isAdmin)
	useEffect(() => setIsAdmin(props.isAdmin), [props.isAdmin])

	const updateIsAdminRequest = useRequest<void>('none')
	useEffect(onUpdateIsAdminRequestChange, [updateIsAdminRequest.isAwaiting])

	/**
	 * Muda ou nao pra admin.
	 */
	function toogleAdmin(): void {
		const dto: DoctorGroupTooglePersonAsAdminRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			personCode: props.personCode,
			role: props.role,
			isAdmin: !isAdmin,
		}
		updateIsAdminRequest.runRequest(DoctorGroupsRequests.toogleAsAdmin(dto))
	}

	/**
	 * Retorno ao tornar pessoa admin.
	 */
	function onUpdateIsAdminRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateIsAdminRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				'Privilégios atualizados com sucesso!',
				true,
			)
		)
			return

		setIsAdmin(!isAdmin)
		if (props.onSave) props.onSave()
	}

	return (
		<ButtonCP
			disabled={AppStateUtils.getLoggedUser()!.code === props.personCode}
			type={isAdmin ? 'primary' : undefined}
			icon={'crown'}
			confirmMsg={`Você tem certeza que deseja ${isAdmin ? 'tirar permissão de administrador?' : 'tornar administrador?'}`}
			onClick={toogleAdmin}
			loading={updateIsAdminRequest.isAwaiting}
			size={'small'}
		/>
	)
}
