import React from 'react'
import { EditorState } from 'draft-js'
import { Tooltip } from 'antd'
import styled from 'styled-components'

import { VoiceIptPluginBtnPropsTP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceInputPluginTypes'
import { MicrophoneIconCP } from '_old/main/common/components/icons/MicrophoneIconCP'
import { StopIconCP } from '_old/main/common/components/icons/StopIconCP'
import { LoaderThreeDotsCP } from '_old/main/common/components/loader-three-dots/LoaderThreeDotsCP'
import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'

const MSG_UNAVAILABLE = 'Disponível apenas no Google Chrome'

interface IVoiceInputButtonICPProps extends VoiceIptPluginBtnPropsTP {
	onEditorChange: (editorState: EditorState) => void
	editorState: EditorState
}

/**
 * COMPONENTE
 * Botao controlador do componente de captura de texto por voz.
 *
 * @author hjcostabr
 */
export const VoiceInputButtonICP = (props: IVoiceInputButtonICPProps): JSX.Element => {
	function renderInnerContent(): JSX.Element {
		return (
			<Tooltip title={props.isUnavailable && MSG_UNAVAILABLE}>
				<InnerWrapperSCP isColumn={props.isRecording}>
					{!props.isRecording || props.isUnavailable ? (
						<MicrophoneIconCP size={20} />
					) : (
						<>
							<StopIconCP size={13} color={'#555'} style={{ margin: '4px 0px' }} />
							<LoaderThreeDotsCP width={32} color={'#888'} />
						</>
					)}
				</InnerWrapperSCP>
			</Tooltip>
		)
	}

	return (
		<ToolbarButtonICP
			editorState={props.editorState}
			onEditorStateChange={props.onEditorChange}
			innerContent={renderInnerContent()}
			onClick={() => props.onToggleIsRecording()}
			isActive={() => props.isRecording && !props.isUnavailable}
			isDisabled={() => props.isUnavailable}
		/>
	)
}

const InnerWrapperSCP = styled.div<{ isColumn: boolean }>`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	flex-direction: ${(props) => (props.isColumn ? 'column' : 'row')};
`
