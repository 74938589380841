import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'

export class ExamDescriptionFormModel extends FormModel {
	@IsRequired()
	modality: ExamModalityEnum

	@IsRequired()
	description: string

	@IsOptional()
	specialty?: ExamSpecialtyEnum

	@IsRequired()
	totalSegments: number

	@IsRequired()
	examGroupClinicBillingCode: string

	@IsRequired()
	examGroupDoctorRevenueCode: string

	constructor(initialData?: ObjectPropsTP<ExamDescriptionFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
