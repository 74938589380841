import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { IsValidCpf } from 'submodules/nerit-framework-utils/validators/decorators/IsValidCpf'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'

export class PersonFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsEmail()
	@IsRequired()
	email: string

	@IsValidCpf()
	@IsRequired()
	cpf: string

	@IsPhoneBR()
	@IsRequired()
	phoneOne: string

	constructor(initialData?: ObjectPropsTP<PersonFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
