import { IsOptional } from 'class-validator'
import { IsNotEmpty } from '_old/main/common/validation/decorators/IsNotEmpty'
import { IsString } from '_old/main/common/validation/decorators/IsString'
import { IsValidCnpj } from '_old/main/common/validation/decorators/IsValidCnpj'
import { NotContainsSpace } from '_old/main/common/validation/decorators/NotContainsSpace'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ClinicBilledByEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicBilledByEnum'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class ClinicFormModel extends FormModel {
	@IsRequired()
	active: boolean

	@IsNotEmpty()
	type: ClinicTypeEnum

	@NotContainsSpace()
	@IsOptional()
	slug?: string

	@NotContainsSpace()
	@IsValidCnpj()
	@IsString()
	@IsNotEmpty()
	cnpj: string

	@IsString()
	@IsNotEmpty()
	socialName: string

	@IsString()
	@IsNotEmpty()
	fantasyName: string

	@IsOptional()
	billedBy?: ClinicBilledByEnum

	@IsOptional()
	billingPageUrl?: string

	@IsOptional()
	hasWhatsapp?: boolean

	@IsOptional()
	notes?: string

	@IsNotEmpty()
	responsibleName: string

	@IsNotEmpty()
	responsibleEmail: string

	@IsNotEmpty()
	responsiblePhoneOne: string

	@IsOptional()
	responsiblePhoneTwo: string

	@IsOptional()
	responsibleTechName: string

	@IsOptional()
	responsibleTechSpecialty: string

	@IsOptional()
	responsibleTechId: string

	@IsOptional()
	aeTitle: string

	@IsOptional()
	ip: string

	@IsOptional()
	port: string

	@IsOptional()
	bucketId: string

	@IsOptional()
	bucketKey: string

	constructor(initialData?: any) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
