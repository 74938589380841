import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { FormExamPendingExamDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingExamDataCP'
import { FormExamPendingPatientDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingPatientDataCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FormExamPendingAnamnesisDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AlertTrialClinicExamsCP } from 'modules/clinic/components/alert-trial-clinic-exams/AlertTrialClinicExamsCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ExamPendingResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingResponseDTO'
import { PendingExamFormModelConverter } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModelConverter'
import { AlertExamCountImagesCP } from 'modules/exams/components/exams/alert-exam-count-images/AlertExamCountImagesCP'
import { FormExamPendingDoctorGroupDataCP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingDoctorGroupDataCP'

interface ICPProps {
	examCode: number
	examId: string
	clinicCode: number
	visible: boolean
	onCancel: () => void
	onSave: () => void
}

/**
 * Drawer para triar um exam.
 */
export function DrawerExamPendingCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<PendingExamFormModel>(new PendingExamFormModel())
	const formStateManager = useFormStateManager<PendingExamFormModel>(formValidator)

	const [loadedExam, setLoadedExam] = useState<ExamPendingResponseDTO>()
	const getExamRequest = useRequest<ExamPendingResponseDTO>()
	useEffect(onGetExamRequestChange, [getExamRequest.isAwaiting])

	const saveExamRequest = useRequest<void>('none')
	useEffect(onSaveExamRequestChange, [saveExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setFormValidator(new PendingExamFormModel({}))
		setLoadedExam(undefined)
		if (!props.visible) return

		getExamRequest.runRequest(ExamsRequests.getExamPending(props.examCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetExamRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getExamRequest.responseData!
		setLoadedExam(result)
		setFormValidator(PendingExamFormModelConverter.getFormModel(result))
	}

	/**
	 * Salva o exame
	 */
	async function save(isPastExam?: boolean): Promise<void> {
		if (!(await PendingExamFormModelConverter.validateFormModel(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto = PendingExamFormModelConverter.getDto(formValues)

		if (isPastExam === true) saveExamRequest.runRequest(ExamsRequests.savePastExam(props.examCode, dto))
		else saveExamRequest.runRequest(ExamsRequests.savePendingExam(props.examCode, dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onSaveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<DrawerCP
			visible={props.visible}
			title={'Editar exame pendente'}
			onClose={props.onCancel}
			width={900}
			loading={getExamRequest.isAwaiting}
			footerSpaced={true}
			footer={
				<>
					<ButtonCP onClick={() => save(true)} loading={saveExamRequest.isAwaiting}>
						Salvar como exame anterior
					</ButtonCP>
					<ButtonCP type={'primary'} onClick={save} loading={saveExamRequest.isAwaiting}>
						Enviar para fila de laudos
					</ButtonCP>
				</>
			}
		>
			{!!loadedExam && (
				<>
					<AlertTrialClinicExamsCP margin={{ bottom: 10 }} />
					<AlertExamCountImagesCP
						examCode={props.examCode}
						imageData={loadedExam.imageData}
						examDescription={loadedExam.description}
						patientName={loadedExam.patientName}
						examId={props.examId}
					/>

					<FormExamPendingDoctorGroupDataCP formStateManager={formStateManager} clinicCode={props.clinicCode} />
					{!!formStateManager.getFieldValue('doctorGroup') && (
						<>
							<FormExamPendingPatientDataCP formStateManager={formStateManager} />
							<FormExamPendingExamDataCP formStateManager={formStateManager} loadedExam={loadedExam} />
							<FormExamPendingAnamnesisDataCP
								temporaryToken={loadedExam.temporaryToken}
								formStateManager={formStateManager}
								examCode={props.examCode}
								examId={props.examId}
								clinicCode={props.clinicCode}
							/>
						</>
					)}
				</>
			)}
		</DrawerCP>
	)
}
