import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RadioOptionsYesOrNo } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/inner/RadioOptionTP'
import { AvcSectionContentICP, IAvcSectionProps } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/AvcSectionContentICP'

/**
 */
export function AvcQuestion3ICP(props: IAvcSectionProps): JSX.Element {
	return (
		<AvcSectionContentICP title={'3. ASPECTS (Território da ACM) TOTAL: 10'}>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSuba'}
						label={'Território - M1:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSubd'}
						label={'Território - M2:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território - M3:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={'Território - M4:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território - M5:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={'Território - M6:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território – Ínsula:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território – Caudado:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território - Cáps. Int.:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território – Lentiforme:'}
					/>
				</ColumnCP>
			</RowCP>
		</AvcSectionContentICP>
	)
}
