import React, { useEffect, useState } from 'react'
import { WizardCreateClinicCP } from 'modules/clinic/components/wizard-create-clinic/WizardCreateClinicCP'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { FindClinicBoxCP } from 'modules/clinic/components/find-clinic-box/FindClinicBoxCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onSave: () => void
	doctorGroupCode?: number
}

/**
 * Modal com processo de criar uma nova clinica
 */
export function ModalNewClinicCP(props: ICPProps): JSX.Element {
	const [showWizard, setShowWizard] = useState(false)
	const [foundClinic, setFoundClinic] = useState<ClinicResponseDTO>()
	const [cnpjEntered, setCnpjEntered] = useState<string>()

	useEffect(init, [props.visible])

	/**
	 * Inicia
	 */
	function init(): void {
		setShowWizard(false)
		setFoundClinic(undefined)
		setCnpjEntered(undefined)
	}

	return (
		<ModalCP
			visible={props.visible}
			noFooter={true}
			onCancel={props.onCancel}
			title={showWizard ? 'Passo-a-passo para criar nova unidade hospitalar' : 'Pesquise pela Unidade Hospitalar'}
			width={showWizard ? 800 : 400}
			top={10}
			closable={!showWizard}
			disableOutsideClickClosing={showWizard}
		>
			{showWizard ? (
				<WizardCreateClinicCP
					initialData={{
						cnpj: cnpjEntered,
						clinic: foundClinic,
					}}
					doctorGroupCode={props.doctorGroupCode}
					onFinish={props.onSave}
				/>
			) : (
				<FindClinicBoxCP
					doctorGroupCode={props.doctorGroupCode}
					onClickToCreateClinic={(cnpj) => {
						setCnpjEntered(cnpj)
						setShowWizard(true)
					}}
					onInviteToDoctorGroup={props.onSave}
					onCancel={props.onCancel}
				/>
			)}
		</ModalCP>
	)
}
