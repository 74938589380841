import { AddressResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/address/dtos/response/AddressResponseDTO'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { AddressSaveRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/address/dtos/request/AddressSaveRequestDTO'

export class AddressFormUtils {
	static formatFormModel(dto?: AddressResponseDTO): AddressFormModel {
		return new AddressFormModel({
			street: dto?.street,
			neighborhood: dto?.neighborhood,
			complement: dto?.complement,
			number: dto?.number,
			zipCode: dto?.zipCode,
			city: dto?.city,
			state: dto?.state,
		})
	}

	static formatDto(model?: AddressFormModel): AddressSaveRequestDTO {
		return {
			street: model?.street,
			neighborhood: model?.neighborhood,
			complement: model?.complement,
			number: model?.number,
			zipCode: model?.zipCode,
			city: model?.city,
			state: model?.state,
		}
	}
}
