import { useState, useRef, useEffect, ChangeEvent, KeyboardEvent, MutableRefObject, Dispatch, SetStateAction } from 'react'
import { IValidationCodeInputCPProps } from 'submodules/nerit-framework-ui/common/components/validation-code-input/ValidationCodeInputCP'

interface IUseValidationCodeInputProps extends IValidationCodeInputCPProps {}

interface IUseValidationCodeInputReturn {
	inputRef: MutableRefObject<HTMLInputElement | null>
	setActive: Dispatch<SetStateAction<boolean>>
	handleClick: () => void
	handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
	handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
	getValue: () => string
	isCharacterSelected: (index: number) => boolean
	isCharacterInactive: (index: number) => boolean
}

export function useValidationCodeInput(props: IUseValidationCodeInputProps): IUseValidationCodeInputReturn {
	const [localValue, setLocalValue] = useState('')
	const [isActive, setActive] = useState(false)

	const inputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		if (props.autoFocus && inputRef.current) {
			inputRef.current.focus()
		}
	}, [props.autoFocus])

	useEffect(() => {
		if (props.inputProps?.disabled) {
			setActive(false)
		}
	}, [props.inputProps?.disabled])

	function handleClick(): void {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}

	function handleKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
		if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
			// do not allow to change cursor position
			event.preventDefault()
		}
	}

	function handleInputChange(event: ChangeEvent<HTMLInputElement>): void {
		const newInputVal = event.target.value.replace(/\s/g, '')
		const validChars = props.validChars ?? 'A-Za-z0-9'

		if (RegExp(`^[${validChars}]{0,${props.length}}$`).test(newInputVal)) {
			if (props.onChange) {
				props.onChange(newInputVal)
			}

			setLocalValue(newInputVal)

			if (newInputVal.length === props.length && props.onComplete) {
				props.onComplete(newInputVal)
			}
		}
	}

	function getValue(): string {
		return props.value ?? localValue
	}

	function isCharacterSelected(index: number): boolean {
		const value = getValue()
		return (value.length === index || (value.length === index + 1 && props.length === index + 1)) && isActive
	}

	function isCharacterInactive(index: number): boolean {
		return getValue().length < index
	}

	return {
		inputRef,
		setActive,
		handleClick,
		handleKeyDown,
		handleInputChange,
		getValue,
		isCharacterSelected,
		isCharacterInactive,
	}
}
