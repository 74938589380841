import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'

interface ICPProps {
	team: TeamResponseDTO
	onClick?: () => void
}

/**
 * Time em forma de tag
 */
export function TagTeamCP(props: ICPProps): JSX.Element {
	return <TagCP color={props.team.color} content={props.team.name} onClick={props.onClick} />
}
