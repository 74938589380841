import React from 'react'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'
import { TeamRulesDescriptionCP } from 'modules/doctor-groups/components/teams/team-rule-description/TeamRulesDescriptionCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import styled from 'styled-components'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	rules?: TeamRuleTP[]
}

/**
 * Popover com as regras de um time.
 */
export function PopoverTeamRulesCP(props: ICPProps): JSX.Element {
	if (ArrayUtils.isEmpty(props.rules)) return <>Não há regras. Os médicos desse time podem ver todos os exames</>

	return (
		<PopOverCP
			content={
				<ContentWrapperSCP>
					<TeamRulesDescriptionCP rules={props.rules} />
				</ContentWrapperSCP>
			}
			trigger={'hover'}
			title={'Regras'}
		>
			Ver regras
		</PopOverCP>
	)
}

const ContentWrapperSCP = styled.div`
	width: 600px;
`
