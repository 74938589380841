import React, { useEffect, useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { isValid as isValidCnpj } from '@fnando/cnpj/dist/node'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { HttpStatusEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpStatusEnum'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'

interface ICPProps {
	onResult: (cnpjEntered: string, clinic?: ClinicResponseDTO) => void
}

/**
 * Link do grupo de medico a uma clinica
 */
export function InputSearchClinicByCnpjCP(props: ICPProps): JSX.Element {
	const [cnpj, setCnpj] = useState<string>()

	const searchByCnpjRequest = useRequest<ClinicResponseDTO>()
	useEffect(onSearchByCnpjRequestChange, [searchByCnpjRequest.isAwaiting])

	/**
	 * Busca clinica pelo CNPJ.
	 */
	function search(): void {
		if (!isValidCnpj(cnpj)) return NotificationHelper.error('Ops', 'Digite um CNPJ válido')

		searchByCnpjRequest.runRequest(ClinicsRequests.getByCnpj(MaskUtils.removeMask(cnpj)!))
	}

	/**
	 * Retorno da requisicao
	 */
	function onSearchByCnpjRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(searchByCnpjRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE) &&
			searchByCnpjRequest.responseStatus !== HttpStatusEnum.NOT_FOUND
		)
			return

		props.onResult(cnpj!, searchByCnpjRequest.responseData)
	}

	return (
		<FlexCP alignItems={'center'}>
			<InputCP label={'CNPJ'} mask={InputMaskTypeEnum.CNPJ} value={cnpj} onChange={setCnpj} />
			<ButtonCP onClick={search} icon={'search'} type={'ghost'} loading={searchByCnpjRequest.isAwaiting} marginLeft={20} marginTop={16} />
		</FlexCP>
	)
}
