import React from 'react'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { PopoverExamDateLogCP } from 'modules/exams/components/exams/popover-exam-date-log/PopoverExamDateLogCP'
import { IconSlaCP } from 'modules/exams/components/exams/icons/IconSlaCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	exam: ExamListItemResponseDTO
}

/**
 */
export function ColumnSlaICP(props: ICPProps): JSX.Element {
	return (
		<>
			{!!props.exam.clinic.sla ? (
				<PopoverExamDateLogCP exam={props.exam} considerDivOnElement={true}>
					<IconSlaCP slaNowInMinutes={props.exam.clinic.sla.currentSlaFromNow} />
				</PopoverExamDateLogCP>
			) : (
				<ButtonCP size={'small'} disabled={true}>
					Sem SLA
				</ButtonCP>
			)}
		</>
	)
}
