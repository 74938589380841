import React from 'react'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'
import { ExamHistoryEnum } from 'submodules/beerads-sdk/services/exams/history/types/ExamHistoryEnum'
import { CommentCP } from 'submodules/nerit-framework-ui/common/components/comment/CommentCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { IExamHistoryContent } from 'submodules/beerads-sdk/services/exams/history/interfaces/IExamHistoryContent'
import { RectificationReasonsLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/RectificationReasonsEnum'

interface ICPProps {
	histories: ExamHistoryResponseDTO[]
}

/**
 * Drawer para fazer analise administrativa de um exame.
 */
export function ExamHistoryComments(props: ICPProps): JSX.Element {
	function getRetificationReason(content: IExamHistoryContent): string | undefined {
		return !!content?.rectificationReason ? RectificationReasonsLabelEnum[content?.rectificationReason] : undefined
	}

	return (
		<>
			{props.histories.map((history, key) => (
				<FlexCP key={key} flexDirection="column">
					{history.event === ExamHistoryEnum.SEND_TO_RECTIFICATION_ANALYSIS && (
						<TextCP text={getRetificationReason(history.content)} fontStyle="italic" strong={true} />
					)}
					<CommentCP
						comment={{
							text: history.comments ?? '',
							user: history.user,
							date: history.date,
						}}
					/>
				</FlexCP>
			))}
		</>
	)
}
