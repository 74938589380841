import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { GenderEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import { IsOptional } from 'class-validator'
import { ExamContrastEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamContrastEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

export class PendingExamFormModel extends FormModel {
	@IsRequired()
	doctorGroupCode: number

	@IsOptional()
	patientId: string

	@IsRequired()
	patientName: string

	@IsRequired()
	patientBirthday: string

	@IsRequired()
	patientGender: GenderEnum

	@IsRequired()
	exams: IPendingExamDescription[]

	@IsRequired()
	urgencyType: ExamUrgencyTypeEnum

	@IsOptional()
	contrasts: ExamContrastEnum[]

	@IsOptional()
	requestingDoctor: string

	@IsOptional()
	extraInfo: string

	@IsOptional()
	internalClinic: string

	@IsRequired()
	anamnesis: string

	/**
	 * Auxiliares
	 */
	doctorGroup: DoctorGroupNamesFromClinicResponseDTO

	constructor(initialData?: any) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
