import { ClinicMedicalReportConfigFormModel } from 'modules/clinic/components/form-clinic-report-config-template/inner/ClinicMedicalReportConfigFormModel'
import React, { useEffect, useState } from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ClinicNotificationConfigSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicNotificationConfigSaveRequestDTO'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ClinicNotificationConfigResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicNotificationConfigResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	clinicCode: number
	onSave?: () => void
}

/**
 * Componente para personalização das informações do laudo
 */
export function FormClinicReportTemplateConfigCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<ClinicMedicalReportConfigFormModel>(new ClinicMedicalReportConfigFormModel())
	const formStateManager = useFormStateManager(formValidator)

	const getClinicMedicalReportRequest = useRequest<ClinicNotificationConfigResponseDTO>()
	useEffect(onGetClinicMedicalReportRequestChange, [getClinicMedicalReportRequest.isAwaiting])

	const updateReportConfigRequest = useRequest<void>('none')
	useEffect(onUpdateReportConfigRequestChange, [updateReportConfigRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		setFormValidator(new ClinicMedicalReportConfigFormModel({}))
		getClinicMedicalReportRequest.runRequest(ClinicsRequests.getNotificationConfig(props.clinicCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicMedicalReportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicMedicalReportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getClinicMedicalReportRequest.responseData!
		setFormValidator(
			new ClinicMedicalReportConfigFormModel({
				doctorMessage: result.medicalReportDoctorMessage,
			}),
		)
	}

	/**
	 * Salva configuracoes
	 */
	async function saveReportConfig(clinicCode: number): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		const dto: ClinicNotificationConfigSaveRequestDTO = {
			doctorMessage: formValues.doctorMessage,
		}
		updateReportConfigRequest.runRequest(ClinicsRequests.saveNotificationConfig(clinicCode, dto))
	}

	/**
	 * Retorno ao salvar.
	 */
	function onUpdateReportConfigRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateReportConfigRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		if (!!props.onSave) props.onSave()
		else init()
	}

	return (
		<>
			<LoadingOverlayCP show={getClinicMedicalReportRequest.isAwaiting} />

			<TitleCP underLine={true} textSize={'normal'} marginTop={30}>
				Mensagem para o Médico
			</TitleCP>
			<TextAreaCP
				formStateManager={formStateManager}
				fieldName={'doctorMessage'}
				placeholder={'Caso deseje, informe uma mensagem que será exibida sempre que um médico laudar um exame da sua unidade hospitalar'}
				minRows={3}
				marginBottom={30}
			/>

			<FlexCP margin={{ top: 50 }} justifyContent={'flex-end'}>
				<ButtonCP type={'primary'} onClick={() => saveReportConfig(props.clinicCode)} loading={updateReportConfigRequest.isAwaiting}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
