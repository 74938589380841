import React, { useState } from 'react'
import { CountMedicalReportsDoneByClinicResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/CountMedicalReportsDoneByClinicResponseDTO'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TablePaginationTP } from 'submodules/nerit-framework-ui/common/components/table/types/TablePaginationTP'

interface ICPProps {
	loading: boolean
	data: CountMedicalReportsDoneByClinicResponseDTO[]
}

/**
 */
export function TableMedicalReportCounterICP(props: ICPProps): JSX.Element {
	const [pagination, setPagination] = useState<TablePaginationTP>(TableUtils.getDefaultPagination(10))

	return (
		<TableCP
			loading={props.loading}
			emptyText={'Nenhum laudo emitido hoje'}
			data={props.data}
			sortAndPage={{ pagination }}
			onSortOrChangePagination={(sortAndPage) => {
				if (!!sortAndPage?.pagination) setPagination(sortAndPage.pagination)
			}}
			columns={[
				{
					title: 'Unidade Hospitalar',
					render: (text, record) => record.clinic.name,
				},
				{
					title: 'Laudos',
					align: 'right',
					render: (text, record) => record.totalMedicalReportDone,
				},
			]}
		/>
	)
}
