import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsValidCpf } from 'submodules/nerit-framework-utils/validators/decorators/IsValidCpf'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { IsOptional } from 'class-validator'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

export class UserProfileFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsValidCpf()
	@IsRequired()
	cpf: string

	@IsEmail()
	@IsRequired()
	email?: string

	@IsRequired()
	phone?: string

	@IsOptional()
	birthDate?: string

	constructor(dto?: any) {
		super(dto)
		Object.assign(this, dto)
	}
}
