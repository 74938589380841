import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class UserLoginFormValidator extends FormModel {
	@IsRequired()
	password: string

	@IsRequired()
	email: string

	constructor() {
		super()
	}
}
