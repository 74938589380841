import { ClinicRelationPermissionTP } from 'modules/auth/permissions/ClinicPermissionUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'

/**
 */
export const ScreenExamsUtils = {
	getExamScreenPermission(examStatusGroup?: ExamStatusGroupEnum): ClinicRelationPermissionTP[] | undefined {
		switch (examStatusGroup) {
			case ExamStatusGroupEnum.PENDING:
				return ['isUser', 'isAdmin', 'isDoctorGroupAdmin']

			case ExamStatusGroupEnum.PENDING_ANALYSIS:
				return ['isDoctorGroupAdmin']

			case ExamStatusGroupEnum.ARCHIVED:
				return ['isUser', 'isAdmin', 'isDoctorGroupUser', 'isDoctorGroupAdmin']
		}

		return
	},
}
