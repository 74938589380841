import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ClinicDoctorGroupListItemResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupListItemResponseDTO'
import { TagClinicDoctorGroupStatusCP } from 'modules/doctor-groups/components/clinic-doctor-groups/tag-clinic-doctor-group-status/TagClinicDoctorGroupStatusCP'
import { ButtonClinicDoctorGroupAnswerInviteCP } from 'modules/doctor-groups/components/clinic-doctor-groups/button-clinic-doctor-group-answer-invite/ButtonClinicDoctorGroupAnswerInviteCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ClinicDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'

interface ICPProps {
	clinicCode: number
	reloadTable: number
}

/**
 * Listagem de grupos de médicos de uma clinica.
 */
export function TableClinicDoctorGroupsCP(props: ICPProps): JSX.Element {
	const [reload, setReload] = useState<number>()
	useEffect(() => setReload(props.reloadTable), [props.reloadTable])

	return (
		<TableFromApiCP<ClinicDoctorGroupListItemResponseDTO>
			appearance={{
				wrappedOnCard: true,
			}}
			shouldLoadData={reload}
			apiConfig={{
				requestConfigTP: () =>
					ClinicDoctorGroupRequests.search({
						clinicCode: props.clinicCode,
					}),
			}}
			columns={[
				{
					render: (text, item) => <TagClinicDoctorGroupStatusCP status={item.status} />,
				},
				{
					title: 'Nome do grupo',
					render: (text, record) => record.doctorGroup.name,
				},
				{
					title: 'Modalidades',
					render: (text, record) => <TagsExamModalitiesCP modalities={record.modalities} />,
				},
				{
					render: (text, record) => (
						<>
							{record.status === ClinicDoctorGroupStatusEnum.AWAITING_CLINIC && (
								<ButtonClinicDoctorGroupAnswerInviteCP
									clinicDoctorGroupCode={record.code}
									onSave={() => setReload(TableUtils.getReloadNumber())}
								/>
							)}
						</>
					),
				},
			]}
		/>
	)
}
