import React, { useEffect, useState } from 'react'
import { CreateExamFormModel } from 'modules/exams/components/exams/wizard-create-exam/form-model/CreateExamFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { WizardCP } from 'submodules/nerit-framework-ui/common/components/wizard/WizardCP'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { StepExamDataCreateExamICP } from 'modules/exams/components/exams/wizard-create-exam/steps/step-exam-data/StepExamDataCreateExamICP'
import { StepExamImagesCreateExamCP } from 'modules/exams/components/exams/wizard-create-exam/steps/step-exam-images-create-exam/StepExamImagesCreateExamCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'
import { UploadUrlTP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'

interface ICPProps {
	clinicCode: number
	clinicType: ClinicTypeEnum
	doctorGroup: DoctorGroupNamesFromClinicResponseDTO
	onSave: (type: 'save' | 'saveAndNew' | 'saveAndNewKeepData') => void
}

/**
 */
export function WizardCreateExamCP(props: ICPProps): JSX.Element | null {
	const [dataLoaded, setDataLoaded] = useState<boolean>(false)
	const [formValidator, setFormValidator] = useState<CreateExamFormModel>(new CreateExamFormModel())
	const formStateManager = useFormStateManager(formValidator)

	const [currentStep, setCurrentStep] = useState<number>(0)
	const [examCode, setExamCode] = useState<number>()
	const [examId, setExamId] = useState<string>()
	const [uploadUrl, setUploadUrl] = useState<UploadUrlTP>()

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setFormValidator(
			new CreateExamFormModel({
				examTime: DateUtils.getHourAndMinutesAsString(new Date()),
				examDate: new Date() as any,
			}),
		)
		setDataLoaded(true)
	}

	/**
	 */
	function onSave(saveType: 'save' | 'saveAndNew' | 'saveAndNewKeepData'): void {
		setDataLoaded(false)
		props.onSave(saveType)
		setExamCode(undefined)
		setCurrentStep(0)

		if (saveType === 'save') return

		if (saveType === 'saveAndNew') {
			setFormValidator(
				new CreateExamFormModel({
					examTime: DateUtils.getHourAndMinutesAsString(new Date()),
					examDate: new Date() as any,
				}),
			)
		}

		if (saveType === 'saveAndNewKeepData') {
			formStateManager.changeFieldValue('examTime', DateUtils.getHourAndMinutesAsString(new Date()))
			formStateManager.changeFieldValue('examDate', new Date())
			formStateManager.changeFieldValue('modality', undefined)
			formStateManager.changeFieldValue('description', undefined)
		}

		// Espera para nao ter problema de sincronizacao na tela
		SystemUtils.sleep(100)
		setDataLoaded(true)
	}

	return (
		<WizardCP
			hideButtons={true}
			currentStep={currentStep}
			onChangeStep={setCurrentStep}
			loading={!dataLoaded}
			steps={[
				{
					key: 'data',
					title: 'Dados do Exame',
					content: (
						<>
							{dataLoaded && (
								<StepExamDataCreateExamICP
									formStateManager={formStateManager}
									clinicType={props.clinicType}
									clinicCode={props.clinicCode}
									doctorGroup={props.doctorGroup}
									onCreate={(examCode, examId, uploadUrlResponse) => {
										setExamCode(examCode)
										setExamId(examId)
										setUploadUrl(uploadUrlResponse)
										setCurrentStep(1)
									}}
								/>
							)}
						</>
					),
				},
				{
					key: 'upload',
					title: 'Imagens e Anexo',
					content: (
						<>
							{examCode && examId && uploadUrl && (
								<StepExamImagesCreateExamCP examId={examId} examCode={examCode} onSave={onSave} uploadUrl={uploadUrl} />
							)}
						</>
					),
				},
			]}
		/>
	)
}
