export enum ExamSpecialtyEnum {
	MAMMA = 'MAMMA',
	NEURO = 'NEURO',
	INTERN_MEDICINE = 'INTERN_MEDICINE',
	MUSCLE = 'MUSCLE',
	CARDIOLOGY = 'CARDIOLOGY',
}

export enum ExamSpecialtyLabelEnum {
	MAMMA = 'Mama',
	NEURO = 'Neuro',
	INTERN_MEDICINE = 'Medicina Interna',
	MUSCLE = 'Músculo Esquelético',
	CARDIOLOGY = 'Cardíaco',
}
