import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'
import { AuditScoreEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditScoreEnum'

export class AuditFormModel extends FormModel {
	@IsRequired()
	score: AuditScoreEnum

	@IsOptional()
	comments: string

	constructor(initialData?: ObjectPropsTP<AuditFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
