import React from 'react'
import {
	FormSetPasswordContextProvider,
	PasswordDataTP,
} from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'

interface ICPProps {
	children: React.ReactNode
	/**
	 * This prop should be used when you need to control the value of fields outside
	 */
	onPasswordDataChange?: (passwordData: PasswordDataTP) => void
}

export function FormSetPasswordWrapperCP(props: ICPProps): JSX.Element {
	return <FormSetPasswordContextProvider onPasswordDataChange={props.onPasswordDataChange}>{props.children}</FormSetPasswordContextProvider>
}
