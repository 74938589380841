import { Radio } from 'antd'
import Tooltip from 'antd/lib/tooltip'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export type RadioOptionTP<OptionTP = string> = {
	value: OptionTP
	content: JSX.Element | string
	tooltip?: string
	disabled?: boolean
}

interface IRadioGroupCPProps<OptionTP = string> {
	options: Array<RadioOptionTP<OptionTP>>
	value?: OptionTP
	onChange?: (value: OptionTP) => void
	buttonWidth?: number
}

/**
 * COMPONENTE
 * Grupo de radio-butons (combo de selecao unica).
 *
 * @todo: Retirar 'important' do css (styled componente)
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function RadioGroupCP<OptionTP = string>(props: IRadioGroupCPProps<OptionTP>): JSX.Element {
	return (
		<RadioGroupSCP value={props.value} onChange={(e) => props.onChange!(e.target.value)}>
			{props.options.map((opt, index) => (
				<Tooltip key={`opt-${index}`} title={opt.tooltip}>
					<RadioButtonSCP
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						key={`opt-radio-group-${opt.value as any}`}
						value={opt.value}
						disabled={opt.disabled}
						buttonWidth={props.buttonWidth}
					>
						{opt.content}
					</RadioButtonSCP>
				</Tooltip>
			))}
		</RadioGroupSCP>
	)
}

const RadioGroupSCP = styled(Radio.Group)`
	&.ant-radio-group {
		display: flex;
	}
`

const RadioButtonSCP = styled(Radio.Button)<{ buttonWidth?: number }>`
	&.ant-radio-button-wrapper {
		display: flex;
		justify-content: center;
		width: ${(props) => (!!props.buttonWidth ? `${props.buttonWidth}px` : 'unset')};
	}

	&.ant-radio-button-wrapper-checked,
	&:not(.ant-radio-button-wrapper-checked),
	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
		border-color: ${ThemeFrameworkCommon.browserDefaultBackgroundDark} !important;
		box-shadow: unset;
	}

	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		background: ${ThemeProject.secondary};
		color: ${ThemeFrameworkCommon.browserDefaultColorLight};
	}
	&:not(.ant-radio-button-wrapper-checked) {
		background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
	&:not(.ant-radio-button-wrapper-checked):hover {
		background: ${ThemeProject.gray100};
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
	&.ant-radio-button-wrapper {
		text-align: center;
	}
`
