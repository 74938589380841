import React, { useEffect, useState } from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { TitleMedicalTimetableCP } from 'modules/medical-timetable/components/title-medical-timetable/TitleMedicalTimetableCP'
import { RadioGroupMedicalTimetableConfigTP } from 'modules/medical-timetable/components/radio-group-medical-timetable-config-type/RadioGroupMedicalTimetableConfigTypeCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { TableMedicalTimetableConfigCP } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-config/TableMedicalTimetableConfigCP'
import { ButtonSaveMedicalTimetableStructure } from 'modules/medical-timetable/components/buttons/button-save-medical-timetable-structure/ButtonSaveMedicalTimetableStructure'
import { IMedicalTimeTableStructure } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/interfaces/IMedicalTimeTableStructure'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	selectedConfigType: RadioGroupMedicalTimetableConfigTP
	reload: number
}

/**
 */
export function ScreenContentMedicalTimetableConfig(props: ICPProps): JSX.Element {
	const [medicalTimetableStructureEdit, setMedicalTimetableStructureEdit] = useState<IMedicalTimeTableStructure[]>([])
	const [isEditing, setIsEditing] = useState<boolean>(false)

	const [reload, setReload] = useState<number>(props.reload)
	useEffect(() => setReload(props.reload), [props.reload])

	return (
		<ContentCP overflowVertical={true}>
			{props.selectedConfigType === 'default' && (
				<AlertCP
					message={'ATENÇÃO: Você está alterando a ESCALA PADRÃO, todas as semanas não customizadas serão alteradas'}
					type={'error'}
					margin={{ left: 20, top: 20, right: 20 }}
				/>
			)}
			{isEditing && (
				<AlertCP
					message={
						'Você começou a editar a escala, desabilitamos as opções de navegação até que seja salvo para que não seja perdido o seu trabalho ;)'
					}
					type={'warning'}
					margin={{ left: 20, top: 20, right: 20 }}
				/>
			)}
			<TitleMedicalTimetableCP
				filterFormStateManager={props.filterFormStateManager}
				onChangeFilters={() => setReload(TableUtils.getReloadNumber())}
				appearance={{
					hideTimeFrame: true,
					hideWeekRangeSelection: props.selectedConfigType === 'default',
					disableWeekRangeNavigation: isEditing,
				}}
				leftContent={
					<ButtonSaveMedicalTimetableStructure
						selectedConfigType={props.selectedConfigType}
						isEditing={isEditing}
						onSaved={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.MEDICAL_TIMETABLE)}
						date={props.selectedConfigType === 'week' ? props.filterFormStateManager.getFieldValue('beginDate') : undefined}
						medicalTimetableStructureEdit={medicalTimetableStructureEdit}
					/>
				}
			/>
			<TableMedicalTimetableConfigCP
				selectedConfigType={props.selectedConfigType}
				medicalTimetableStructureEdit={medicalTimetableStructureEdit}
				onChangeMedicalTimetableStructure={setMedicalTimetableStructureEdit}
				filterFormStateManager={props.filterFormStateManager}
				onStartEditing={() => setIsEditing(true)}
				reload={reload}
			/>
		</ContentCP>
	)
}
