import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SelectWeekOrWeekendCP } from 'submodules/nerit-framework-ui/features/people/components/select-week-or-weekend/SelectWeekOrWeekendCP'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onSave: (updatedTeam: TeamRuleTP) => void
	rule?: TeamRuleTP
}

/**
 * Modal com regra de um time.
 */
export function ModalTeamRuleCP(props: ICPProps): JSX.Element {
	const [rule, setRule] = useState<TeamRuleTP>({})
	useEffect(() => setRule(props.rule ?? {}), [props.rule, props.visible])

	/**
	 * Ao salvar
	 */
	function onSave(): void {
		// Verifica se pelo menos uma regra foi preenchida
		if (ArrayUtils.isEmpty(rule.urgencies) && ArrayUtils.isEmpty(rule.modalities) && !rule.interval && !rule.weekOrWeekend)
			return NotificationHelper.error('Ops', 'Selecione ao menos uma regra de bloqueio')

		if (!!rule.interval && (!rule.interval.beginHour || !rule.interval.endHour))
			return NotificationHelper.error('Ops', 'É obrigatório preencher o horário inicial e final')

		props.onSave({
			urgencies: ArrayUtils.isEmpty(rule.urgencies) ? undefined : rule.urgencies,
			modalities: ArrayUtils.isEmpty(rule.modalities) ? undefined : rule.modalities,
			interval: rule.interval,
			weekOrWeekend: rule.weekOrWeekend,
		})
	}

	return (
		<ModalCP title={'Regras de visualização dos exames'} visible={props.visible} width={450} onOk={onSave} onCancel={props.onCancel}>
			<HelpCP
				text={
					'Escolha quais serão as RESTRIÇÕES. O conjunto de regras selecionado abaixo será aplicado em totalidade para RESTRIGIR a visualização desses exames'
				}
				type={'text'}
			/>

			<SelectExamUrgencyTypeCP
				label={'Escolha as urgências não permitidas'}
				value={props.rule?.urgencies}
				onChange={(selecteds) => (rule.urgencies = [...selecteds])}
			/>

			<SelectExamModalityCP
				isMultiple={true}
				label={'Escolha as modalidades não permitidas'}
				value={props.rule?.modalities}
				onChange={(selecteds) => (rule.modalities = [...selecteds])}
			/>

			<SelectWeekOrWeekendCP label={'Quando aplicar?'} value={props.rule?.weekOrWeekend} onChange={(selected) => (rule.weekOrWeekend = selected)} />

			<RowCP>
				<ColumnCP size={12}>
					<InputCP
						label={'Hora Inicial'}
						mask={InputMaskTypeEnum.HOUR}
						value={rule.interval?.beginHour}
						keepMask={true}
						onChange={(hour) => {
							if (!rule.interval) rule.interval = {}
							rule.interval.beginHour = hour
						}}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP
						label={'Hora Final'}
						mask={InputMaskTypeEnum.HOUR}
						value={rule.interval?.endHour}
						keepMask={true}
						onChange={(hour) => {
							if (!rule.interval) rule.interval = {}
							rule.interval.endHour = hour
						}}
					/>
				</ColumnCP>
			</RowCP>
			<HelpCP text={'Ao escolher um horário NÃO SERÁ PERMITIDA visualização do exame no período'} type={'text'} />
		</ModalCP>
	)
}
