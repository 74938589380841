import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SelectContrastCP } from 'modules/exams/components/exams/select-contrast/SelectContrastCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { ExamDataFormModel } from 'modules/exams/components/exams/modal-exam-data/inner/form-model/ExamDataFormModel'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { SelectDoctorGroupExamDescriptionsCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-exam-descriptions/SelectDoctorGroupExamDescriptionsCP'
import { IPendingExamDescription } from 'submodules/beerads-sdk/services/exams/exams/types/IPendingExamDescription'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { SelectFullOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { ExamModalityEnum, ExamModalityLabelToEnEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

interface ICPProps {
	formStateManager: IFormStateManager<ExamDataFormModel>
	shouldDisableEditSomeData: boolean
	exam: ExamResponseDTO
}

/**
 */
export function FormExamDataICP(props: ICPProps): JSX.Element {
	/**
	 */
	function mountExamDescription(val: SelectFullOptionTP): IPendingExamDescription {
		const label = val.label as string

		// Modalidade selecionada esta antes do primeiro espaço do nome
		const selectedModalityBrLabel = label.split(' ')[0] as ExamModalityEnum

		// Monta o exame selecionado para atualizar a lista de exames
		return {
			code: +val.value,
			modality: ExamModalityLabelToEnEnum[selectedModalityBrLabel],
			description: label.slice(selectedModalityBrLabel.length),
		}
	}

	return (
		<>
			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Dados do Exame
			</TitleCP>

			<RowCP>
				<ColumnCP size={10}>
					<SelectContrastCP formStateManager={props.formStateManager} fieldName={'contrasts'} disabled={props.shouldDisableEditSomeData} />
				</ColumnCP>
				<ColumnCP size={14}>
					<InputCP label={'Médico Solicitante'} formStateManager={props.formStateManager} fieldName={'requestingDoctor'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={6}>
					<InputCP label={'Nº do pedido'} formStateManager={props.formStateManager} fieldName={'serviceOrder'} />
				</ColumnCP>
				<ColumnCP size={18}>
					<InputCP
						label={'Informações extras'}
						formStateManager={props.formStateManager}
						fieldName={'extraInfo'}
						hint={{ type: 'tooltip', text: 'Quando necessário, utilizado no cabeçalho do laudo' }}
					/>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={4}>
					<SelectExamModalityCP
						label={'Mod.'}
						value={props.formStateManager.getFieldValue('currentExam').modality}
						onChange={(modality) =>
							props.formStateManager.changeFieldValue('currentExam', {
								code: props.formStateManager.getFieldValue('currentExam').code,
								modality: ExamModalityLabelToEnEnum[modality],
								description: props.formStateManager.getFieldValue('currentExam').description,
							})
						}
						required={true}
						disabled={props.shouldDisableEditSomeData}
					/>
				</ColumnCP>
				<ColumnCP size={20}>
					{props.exam.doctorGroup?.config?.shouldValidateExamDescription === true ? (
						<SelectDoctorGroupExamDescriptionsCP
							required={true}
							selectedDescription={props.formStateManager.getFieldValue('currentExam')}
							onChange={(selected) => props.formStateManager.changeFieldValue('currentExam', mountExamDescription(selected))}
							doctorGroupCode={props.exam.doctorGroup.code}
							selectedModality={props.formStateManager.getFieldValue('currentExam').modality}
							exam={{
								doctorGroupCode: props.exam.doctorGroup.code,
								description: props.exam.description,
								modality: props.exam.modality,
								clinicCode: props.exam.clinic.code,
							}}
							disabled={props.shouldDisableEditSomeData}
						/>
					) : (
						<InputCP
							label={'Nome do Exame'}
							required={true}
							formStateManager={props.formStateManager}
							value={props.formStateManager.getFieldValue('currentExam').description}
							onChange={(val) => {
								const currentExam = props.formStateManager.getFieldValue('currentExam') as IPendingExamDescription
								currentExam.description = val
								props.formStateManager.changeFieldValue('currentExam', currentExam)
							}}
							disabled={props.shouldDisableEditSomeData}
						/>
					)}
				</ColumnCP>
			</RowCP>

			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				História Clínica (Anamnese)
			</TitleCP>

			<TextAreaCP
				placeholder={'Registre as informações mais relevantes da história clínica do paciente'}
				minRows={8}
				formStateManager={props.formStateManager}
				fieldName={'anamnesis'}
				required={true}
				disabled={props.shouldDisableEditSomeData}
			/>
		</>
	)
}
