import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { GetLastPersonAnswerRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/GetLastPersonAnswerRequestDTO'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { SurveyAnswerResponseDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/response/SurveyAnswerResponseDTO'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { SurveyAnswersRequests } from 'submodules/space4leads-sdk/services/surveys/survey-answers/SurveyAnswersRequests'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { PersonResponseDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/response/PersonResponseDTO'

export const LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY = 'LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY'
export const LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY = 'LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY'

export class ModalBoxCustomerSurveyUtils {
	/**
	 * Quando o usuario nao responde, a pesquisa nao aparece por um tempo. Verifica se o tempo expirou, caso ele tenha cancelado.
	 */
	static isCancellationExpired(daysToReopenOnCancel: number): boolean {
		const dateCancelledStr = localStorage.getItem(LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY)
		if (!dateCancelledStr) return true

		const dateCancelled = new Date(dateCancelledStr)
		const minDateToAnswer = DateUtils.add(dateCancelled, daysToReopenOnCancel, TimeBaseEnum.DAY)

		if (DateUtils.getDiff(TimeBaseEnum.DAY, minDateToAnswer, new Date()) < 0) return false

		return true
	}

	/**
	 * Valida se o usuario ja respondeu uma vez, se sim valida se a ultima resposta passou do tempo de responder novamente.
	 */
	static async wasLastAnswerExpired(
		surveyToken: string,
		person: PersonResponseDTO,
		daysBetweenAnswers: number,
		daysToFirstAnswer: number,
		projectCode: number,
	): Promise<boolean> {
		// Busca a ultima resposta da pessoa para este formulario
		const lastPersonAnswerDto: GetLastPersonAnswerRequestDTO = {
			personToken: person.token,
			surveyToken: surveyToken,
		}

		let canShowSurvey = true
		// Coloca dentro do try/catch pois pode retornar o 404, quando nao encontrado
		try {
			const lastAnswerResponse: IApiReturn<SurveyAnswerResponseDTO> = (
				await RequestHelper.runRequest(SurveyAnswersRequests.getLastPersonAnswer(lastPersonAnswerDto, projectCode))
			).data as any

			// Se esta aqui, eh pq tem resposta. Senao da erro 404 e sera tratado no catch
			// Se encontrou uma resposta, verifica o tempo minimo para responder
			const answerDate = DateUtils.toDate(lastAnswerResponse.data!.date, DateFormatEnum.US_WITH_TIME_H_M)
			const minDateToAnswer = DateUtils.add(answerDate, daysBetweenAnswers, TimeBaseEnum.DAY)

			if (DateUtils.getDiff(TimeBaseEnum.DAY, minDateToAnswer, new Date()) < 0) canShowSurvey = false
		} catch {
			// Se nao encontrou resposta, olha quando o usuario foi cadastrado para poder enviar a primeira pesquisa
			const creationDate = DateUtils.toDate(person.creationDate, DateFormatEnum.US_WITHOUT_TIME)
			const minDateToAnswer = DateUtils.add(creationDate, daysToFirstAnswer, TimeBaseEnum.DAY)

			if (DateUtils.getDiff(TimeBaseEnum.DAY, minDateToAnswer, new Date()) < 0) canShowSurvey = false
		} finally {
			return canShowSurvey
		}
	}
}
