import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { FullCalendarCP, IFullCalendarEvent } from 'submodules/nerit-framework-ui/common/components/full-calendar/FullCalendarCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalTimetableDoctorEventResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableDoctorEventResponseDTO'
import { MedicalTimetableDoctorEventsSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableDoctorEventsSearchRequestDTO'
import { WelcomeContentSelectDoctorGroupCP } from 'app/components/welcome-content-select-doctor-group/WelcomeContentSelectDoctorGroupCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

/**
 */
export function ScreenMedicalTimetableDoctor(): JSX.Element {
	const [selectedDateRange, setSelectedDateRange] = useState<DateRangeSearchRequestDTO>()
	const [selectedDoctorCode, setSelectedDoctorCode] = useState<number>()

	const [calendarEvents, setCalendarEvents] = useState<IFullCalendarEvent[]>([])
	const getDoctorsOnMedicalTimetableRequest = useRequest<ListResponseDTO<MedicalTimetableDoctorEventResponseDTO>>()
	useEffect(onGetDoctorsOnMedicalTimetableRequest, [getDoctorsOnMedicalTimetableRequest.isAwaiting])

	useEffect(init, [selectedDateRange, selectedDoctorCode])

	/**
	 */
	function init(): void {
		let doctorUserCode = selectedDoctorCode
		// Se nao tiver medico selecionado, por padrao vem o medico logado
		if (!selectedDoctorCode && CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR)) {
			doctorUserCode = AppStateUtils.getLoggedUser()?.code
			setSelectedDoctorCode(doctorUserCode)
		}

		if (!AppStateUtils.getCurrentDoctorGroup() || !selectedDateRange || !doctorUserCode) return

		const filters: MedicalTimetableDoctorEventsSearchRequestDTO = {
			doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()!.code,
			doctorCode: doctorUserCode,
			dateRange: selectedDateRange,
		}
		getDoctorsOnMedicalTimetableRequest.runRequest(MedicalTimetableRequests.searchDoctorEvents(filters))
	}

	/**
	 */
	function onGetDoctorsOnMedicalTimetableRequest(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorsOnMedicalTimetableRequest, 'Erro ao buscar médicos da escala')) return

		const result = getDoctorsOnMedicalTimetableRequest.responseData!.list

		const events: IFullCalendarEvent[] = []

		result.forEach((resultItem) => {
			events.push({
				id: resultItem.doctor.code,
				title: resultItem.doctor.name,
				start: resultItem.beginDate,
				end: resultItem.endDate,
			})
		})

		setCalendarEvents(events)
	}

	if (!AppStateUtils.getCurrentDoctorGroup()) return <WelcomeContentSelectDoctorGroupCP />

	if (
		!CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR) &&
		!DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(AppStateUtils.getCurrentDoctorGroup()!.code, [
			'isDoctorGroupAdmin',
			'isDoctorGroupUser',
		])
	)
		return <>Você não tem permissão</>

	return (
		<LayoutCP
			titleBrowser={'Escala do Médico'}
			header={
				<HeaderCP title={'Escala do médico'}>
					<AccessControlCP
						permission={{
							byHasAnyPermissionInDoctorGroup: {
								doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()!.code,
								permissions: ['isDoctorGroupUser', 'isDoctorGroupAdmin'],
							},
						}}
					>
						<SelectDoctorGroupPeopleCP
							doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
							role={UserTypeEnum.DOCTOR}
							value={selectedDoctorCode}
							onChange={(value) => setSelectedDoctorCode(value as number)}
							width={300}
							allowClear={false}
						/>
					</AccessControlCP>
				</HeaderCP>
			}
			sider={<></>}
			content={
				<ContentCP overflowVertical={true}>
					{!!selectedDoctorCode ? (
						<CardCP innerSpacing={'none'}>
							<FullCalendarCP events={calendarEvents} onDateRangeChange={setSelectedDateRange} />
						</CardCP>
					) : (
						<BasicStyleWrapperCP margin={{ top: 200 }}>
							<WelcomeContentCP message={'Selecione um médico primeiro'} icon={<IconICP size={50} iconName={'user'} />} />
						</BasicStyleWrapperCP>
					)}
				</ContentCP>
			}
		/>
	)
}
