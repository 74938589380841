import { GeneratedPasswordTP } from 'submodules/nerit-framework-utils/utils/types/GeneratedPasswordTP'
import { CryptoUtils } from 'submodules/nerit-framework-utils/utils/CryptoUtils'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import * as md5 from 'md5'

const PASSWORD_EXPIRES_IN_DAYS = 90

type ValidatePasswordReturnTP = { errors: string[]; messages: string[] }

type HashPasswordResponseTP = {
	hashedPassword: string
	newSalt: string
}

export abstract class PasswordUtils {
	static validatePassword(value: string): ValidatePasswordReturnTP {
		const errors: string[] = []

		const messages = [
			'A senha deve ter entre 8 e 20 caracteres.',
			'A senha deve conter pelo menos uma letra minúscula.',
			'A senha deve conter pelo menos uma letra maiúscula.',
			'A senha deve conter pelo menos um número.',
			'A senha deve conter pelo menos um caractere especial (!, @, #, $, %, ^, &, *, (, -, ), + ou =).',
		]

		if (value.length < 8 || value.length > 20) {
			errors.push(messages[0])
		}

		if (!/[a-z]/.test(value)) {
			errors.push(messages[1])
		}

		if (!/[A-Z]/.test(value)) {
			errors.push(messages[2])
		}

		if (!/\d/.test(value)) {
			errors.push(messages[3])
		}

		if (!/[!@#$%^&*()-+=]/.test(value)) {
			errors.push(messages[4])
		}

		return { errors, messages }
	}

	/**
	 * Transfoma o passsword desejado para o formato em que ele precisa ser salvo no banco de dados.
	 * Retorna também o salt, já no formato que ele precisa ser salvo no banco de dados.
	 */
	static generatePassword(md5Password: string): GeneratedPasswordTP {
		let salt = CryptoUtils.getDefaultDecryptedSalt()

		const finalPassword = CryptoUtils.encrypt(md5Password, salt)

		salt = CryptoUtils.reversibleEncrypt(salt)

		return {
			password: finalPassword,
			salt,
		}
	}

	/**
	 * Generates a random password with a specified size using lowercase, uppercase, numbers, and special characters.
	 *
	 * @return The randomly generated password.
	 */
	static generateRandomPassword(passwordSize = 10): string {
		const lowercase = 'abcdefghijklmnopqrstuvwxyz'
		const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		const numbers = '0123456789'
		const specialChars = '$*&@#'
		const allChars = lowercase + uppercase + numbers + specialChars

		function getRandomChar(str: string): string {
			return str.charAt(Math.floor(Math.random() * str.length))
		}

		// Ensure at least one character from each set
		const password = [getRandomChar(lowercase), getRandomChar(uppercase), getRandomChar(numbers), getRandomChar(specialChars)]

		// Fill the rest of the password
		for (let i = password.length; i < passwordSize; i++) {
			password.push(getRandomChar(allChars))
		}

		return ArrayUtils.shuffleArray(password).join('')
	}

	static hashPassword(newPassword: string, email: string): HashPasswordResponseTP {
		let newSalt = CryptoUtils.getDefaultDecryptedSalt(email)

		const hashedPassword = CryptoUtils.encrypt(md5(newPassword), newSalt)

		newSalt = CryptoUtils.reversibleEncrypt(newSalt)

		return {
			hashedPassword,
			newSalt,
		}
	}

	/**
	 * Generates a random password token in the form of a string.
	 *
	 * @return The randomly generated password token.
	 */
	static getRandomPasswordToken(): string {
		return String(Math.floor(100000 + Math.random() * 900000))
	}

	/**
	 * Validates a password by comparing it with a hashed password and a salt.
	 *
	 * @param hashedPassword - The hashed password stored in database to compare against.
	 * @param oldPassword - The confirm password coming from the client to validate.
	 * @param dbSalt - The salt stored in database used for hashing the password.
	 * @returns Returns true if the password is valid, false otherwise.
	 */
	static validatePasswordHash(hashedPassword: string, oldPassword: string, dbSalt: string): boolean {
		const decryptedDbSalt = CryptoUtils.decrypt(dbSalt)

		return CryptoUtils.compareHash(hashedPassword, md5(oldPassword), decryptedDbSalt)
	}

	static generatePasswordHashed(passwordPlainText: string, salt: string): string {
		return CryptoUtils.encrypt(CryptoUtils.encryptSaltless(passwordPlainText), salt)
	}

	static makePasswordExpiresAt(): Date {
		return DateUtils.add(new Date(), PASSWORD_EXPIRES_IN_DAYS, TimeBaseEnum.DAY)
	}
}
