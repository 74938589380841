import React from 'react'
import { EditorState } from 'draft-js'
import * as _ from 'lodash'

import { VoiceDetectionHelper } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceDetectionHelper'
import {
	VoiceIptPluginBtnPropsTP,
	VoiceIptInlineStyleKeyTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceInputPluginTypes'

interface IVoiceInputState {
	isRecording: boolean
	isUnavailable: boolean
	speechRecognizer?: SpeechRecognition
}

export interface IVoiceIptPluginControlCPProps {
	onVoiceDetected: (nextState: EditorState) => void
	renderButton: (buttonProps: VoiceIptPluginBtnPropsTP) => JSX.Element
	editorState: EditorState
	inlineStyle?: VoiceIptInlineStyleKeyTP
}

/**
 * COMPONENTE
 * Input de texto por voz (funciona apenas no browser Chrome).
 * Atencao: Este componente deve ser de classe porque necessita
 * de mudanca de estado sincrona.
 *
 * @todo: Checar acesso a internet (criterio de disponibilidade)
 *
 * @author hjcostabr
 */
export class VoiceIptPluginControlCP extends React.Component<IVoiceIptPluginControlCPProps, IVoiceInputState> {
	constructor(props) {
		super(props)
		this.state = {
			isRecording: false,
			isUnavailable: true,
		}
	}

	componentDidMount(): void {
		this.onInitialization()
	}

	shouldComponentUpdate(nextProps: IVoiceIptPluginControlCPProps, nextState: IVoiceInputState): boolean {
		return nextState.isRecording !== this.state.isRecording || nextState.isUnavailable !== this.state.isUnavailable
	}

	render(): JSX.Element {
		return this.props.renderButton({
			isRecording: this.state.isRecording,
			isUnavailable: this.state.isUnavailable,
			onToggleIsRecording: () => this.onToggleIsRecording(),
		})
	}

	/** Encapsula procedimento de inicializacao do componente. */
	private onInitialization(): void {
		try {
			this.configSpeechRecognizer()
			this.setState({ isUnavailable: false })
		} catch (error) {
			console.warn('VoiceIptPluginControlCP - Servico de captura de voz indisponivel: ', error)
		}
	}

	/**
	 * Instancia & parametriza api de captura de texto por voz, via browser.
	 * @throws Lanca excessao em caso de api indisponivel (funciona apenas no Chrome).
	 */
	private configSpeechRecognizer(): void {
		const SpeechRecognitionClass = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition

		const speechRecognizer = new SpeechRecognitionClass()

		speechRecognizer.continuous = false
		speechRecognizer.interimResults = false
		speechRecognizer.lang = 'pt-BR'
		speechRecognizer.maxAlternatives = 1

		this.setState({ speechRecognizer })
	}

	/** Inicia/encerra gravacao. */
	private onToggleIsRecording(): void {
		if (this.state.isUnavailable) return

		const speechRecognizer = _.get(this.state, 'speechRecognizer') as SpeechRecognition
		const start = !this.state.isRecording
		this.setState({ isRecording: start })

		if (start) {
			speechRecognizer.onresult = (event: any) => this.onSpeechRecognitionResult(event)
			speechRecognizer.onend = () => speechRecognizer.start()
			speechRecognizer.start()
		} else {
			speechRecognizer.onend = null
			speechRecognizer.stop()
		}
	}

	/** Trata conclusao de captura de 01 trecho de texto obtido por voz. */
	private onSpeechRecognitionResult(event: any): void {
		const isFinal = _.get(event, 'results[0].isFinal', false)
		if (!isFinal) return

		const caughtText = _.get(event, 'results[0][0].transcript')
		if (!caughtText) return

		const editorState = VoiceDetectionHelper.getStateWithVoiceInput(caughtText, this.props.editorState, this.props.inlineStyle)

		this.props.onVoiceDetected(editorState)
	}
}
