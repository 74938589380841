import React, { useEffect, useState } from 'react'
import { RecoverPwdFormValidator } from 'modules/auth/components/recover-password-form/inner/RecoverPwdFormValidator'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { MailIconCP } from '_old/main/common/components/icons/MailIconCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import styled from 'styled-components'
import { ForgotPasswordRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/ForgotPasswordRequestDTO'
import { ForgotPasswordStepsTP } from 'modules/auth/screens/screen-recover-password/inner/ForgotPasswordStepsCP'
import { ResetPasswordQueryParamsTP } from 'modules/auth/screens/screen-recover-password/ScreenForgotPassword'
import { useQueryParams } from 'submodules/nerit-framework-ui/common/hooks/useQueryParam'

interface IForgotPasswordFormCPProps {
	onPasswordResetStepChange: (step: ForgotPasswordStepsTP) => void
}

/**
 * Formulario de RECUPERAR SENHA.
 */
export function ForgotPasswordFormCP(props: IForgotPasswordFormCPProps): JSX.Element {
	const [, setQueryParams] = useQueryParams<ResetPasswordQueryParamsTP>()
	const [formValidator] = useState<RecoverPwdFormValidator>(new RecoverPwdFormValidator())

	const formStateManager = useFormStateManager(formValidator)
	const forgotPasswordRequest = useRequest<void>('none')

	const shouldDisableConfirmButton = !formStateManager.getFieldValue('email') || forgotPasswordRequest.isAwaiting

	useEffect(onForgotPasswordRequestChange, [forgotPasswordRequest.isAwaiting])

	async function handleFormSubmit(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) {
			return
		}

		const formValues = formStateManager.getFormValues()!
		const dto: ForgotPasswordRequestDTO = {
			email: formValues.email,
		}

		forgotPasswordRequest.runRequest(AuthRequests.forgotPassword(dto))
	}

	/**
	 */
	function onForgotPasswordRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				forgotPasswordRequest,
				'Erro ao recuperar senha',
				'Solicitação de recuperação de senha enviada com sucesso!',
			)
		) {
			return
		}

		setQueryParams({
			email: formStateManager.getFieldValue('email'),
			step: ForgotPasswordStepsTP.VERIFICATION_CODE,
		})

		props.onPasswordResetStepChange(ForgotPasswordStepsTP.VERIFICATION_CODE)
	}

	return (
		<>
			<InputCP
				label={'Informe o e-mail cadastrado'}
				fieldName={'email'}
				formStateManager={formStateManager}
				required
				onFormSubmit={handleFormSubmit}
				icon={<MailIconCP />}
			/>

			<ButtonWrapperSCP>
				<ButtonCP onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)}>Voltar para login</ButtonCP>

				<ButtonCP
					type={shouldDisableConfirmButton ? 'ghost' : 'primary'}
					disabled={shouldDisableConfirmButton}
					loading={forgotPasswordRequest.isAwaiting}
					onClick={handleFormSubmit}
				>
					Recuperar Senha
				</ButtonCP>
			</ButtonWrapperSCP>
		</>
	)
}

const ButtonWrapperSCP = styled.div`
	width: 100%;
	margin-top: 20px;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
`
