import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ISearchOrderingRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchOrderingRequestDTO'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'

/**
 */
export class ExamFiltersFormModelConverter {
	/**
	 */
	static formatDto(model?: ExamFilterFormModel, searchString?: string, sorter?: ISearchOrderingRequestDTO, patientName?: string): ExamSearchRequestDTO {
		let definedSorter = sorter

		// Se nao tiver ordenado por nada e estiver filtrando por status concluido, set ordenacao por data
		if (!!sorter) definedSorter = { orderingAscending: sorter.orderingAscending, orderingCriteria: sorter.orderingCriteria }
		else if (!!model?.sorter) definedSorter = model.sorter
		if (ArrayUtils.isEmpty(model?.status) || model?.status?.includes(ExamStatusEnum.DONE))
			definedSorter = { orderingAscending: false, orderingCriteria: ExamSearchOrderingEnum.DATE }

		return {
			statuses: model?.status,
			doctorCodes: model?.doctorCodes,
			viewAsDoctor: model?.viewAsDoctor ?? false,
			clinicCodes: model?.clinicCodes,
			modalities: model?.modalities,
			patientName: patientName ?? model?.patientName,
			searchString: searchString ?? model?.searchString,
			urgencies: model?.urgencies,
			dateRange: model?.dateRange,
			isCritical: model?.isCritical,
			assigned: model?.assigned,
			sla: model?.sla,
			doctorGroupCode: model?.doctorGroupCode,
			orderingCriteria: definedSorter?.orderingCriteria,
			orderingAscending: definedSorter?.orderingAscending,
			shouldConsiderMedicalReportDate: model?.shouldConsiderMedicalReportDate,
			clinicCategoryCode: model?.clinicCategoryCode,
			specialty: model?.specialty,
			teamCode: model?.teamCode,
			medicalReportInHourLimit: model?.medicalReportInHourLimit,
			examIds: model?.examIds,
		}
	}

	/**
	 */
	static formatFormModel(dto?: ExamSearchRequestDTO, examStatusGroup?: ExamStatusGroupEnum): ExamFilterFormModel {
		let filterStatus: ExamStatusEnum[] | undefined
		if (!!examStatusGroup) filterStatus = ExamStatusUtils.examStatusOfExamStatusGroupMap.get(examStatusGroup)

		return new ExamFilterFormModel({
			viewAsDoctor: dto?.viewAsDoctor ?? AppStateUtils.getLoggedUser()!.isDoctor,
			status: dto?.statuses ?? filterStatus,
			doctorCodes: dto?.doctorCodes,
			clinicCodes: dto?.clinicCodes,
			modalities: dto?.modalities,
			searchString: dto?.searchString,
			patientName: dto?.patientName,
			urgencies: dto?.urgencies,
			dateRange: dto?.dateRange,
			isCritical: dto?.isCritical,
			assigned: dto?.assigned ?? ExamAssignedFilterEnum.ALL,
			doctorGroupCode: dto?.doctorGroupCode ?? AppStateUtils.getCurrentDoctorGroup()?.code,
			sla: dto?.sla,
			sorter: ExamFiltersFormModelConverter.defineSorter(dto, examStatusGroup),
			shouldConsiderMedicalReportDate: dto?.shouldConsiderMedicalReportDate,
			clinicCategoryCode: dto?.clinicCategoryCode,
			specialty: dto?.specialty,
			teamCode: dto?.teamCode,
			medicalReportInHourLimit: dto?.medicalReportInHourLimit,
			examIds: dto?.examIds ?? [],
		})
	}

	/**
	 */
	static defineSorter(dto?: ExamSearchRequestDTO, examStatusGroup?: ExamStatusGroupEnum): ISearchOrderingRequestDTO | undefined {
		// Se tiver passado, pega o que esta no dto
		if (!!dto?.orderingCriteria) return { orderingCriteria: dto.orderingCriteria, orderingAscending: dto.orderingAscending }

		// Caso nao tenha passado o status group
		if (!examStatusGroup) {
			// Nao tem grupo e nao filtrou por nenhum status
			if (ArrayUtils.isEmpty(dto?.statuses)) return { orderingAscending: false, orderingCriteria: ExamSearchOrderingEnum.DATE }

			// Se no filtro de status passado tiver o status done, filtra pela data por performance
			if (dto?.statuses?.includes(ExamStatusEnum.DONE)) return { orderingAscending: false, orderingCriteria: ExamSearchOrderingEnum.DATE }

			return
		}

		// Trata pelo Grupo
		switch (examStatusGroup) {
			case ExamStatusGroupEnum.PENDING_ANALYSIS:
			case ExamStatusGroupEnum.PENDING:
			case ExamStatusGroupEnum.AWAITING:
				return

			case ExamStatusGroupEnum.ARCHIVED:
			case ExamStatusGroupEnum.DONE:
			default:
				return { orderingAscending: false, orderingCriteria: ExamSearchOrderingEnum.DATE }
		}
	}
}
