import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { TableClinicPeopleUtils } from 'modules/clinic/components/table-clinic-people/inner/TableClinicPeopleUtils'
import { PersonClinicsRequests } from 'submodules/beerads-sdk/services/clinics/person-clinic/PersonClinicsRequests'
import { PersonClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/responses/PersonClinicResponseDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'

interface ICPProps {
	clinicCode: number
	doctorGroupCode?: number
	shouldReload?: number
	role: UserTypeEnum
	relationMode: ClinicPersonRelationModeEnum
	onEditDoctor?: (doctor: PersonClinicResponseDTO) => void
	tableConfig: {
		showToogleAdminButton: boolean
		wrappedOnCard?: boolean
		hideEmptyLogo?: boolean
	}
	onLoad?: (resultData: PersonClinicResponseDTO[]) => void
	onChangeData?: () => void
}

/**
 * Lista de usuários ou medicos da clínica
 */
export function TableClinicPeopleCP(props: ICPProps): JSX.Element {
	const [reload, setReload] = useState<number>()
	useEffect(() => setReload(props.shouldReload), [props.shouldReload])

	return (
		<TableFromApiCP<PersonClinicResponseDTO>
			onLoad={props.onLoad}
			appearance={{
				wrappedOnCard: props.tableConfig.wrappedOnCard ?? true,
				hideEmptyLogo: props.tableConfig.hideEmptyLogo,
			}}
			shouldLoadData={reload}
			apiConfig={{
				requestConfigTP: () =>
					PersonClinicsRequests.search({
						clinicCode: props.clinicCode,
						doctorGroupCode: props.doctorGroupCode,
						role: props.role,
						relationMode: props.relationMode,
					}),
			}}
			columns={TableClinicPeopleUtils.getColumns(
				props.role,
				() => {
					setReload(TableUtils.getReloadNumber())
					if (!!props.onChangeData) props.onChangeData()
				},
				props.relationMode,
				props.clinicCode,
				props.doctorGroupCode,
				props.tableConfig.showToogleAdminButton,
				props.onEditDoctor,
			)}
		/>
	)
}
