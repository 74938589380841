import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { DoctorPermissionUtils } from 'modules/auth/permissions/DoctorPermissionUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: {
		id: string
		status: ExamStatusEnum
		doctorGroupCode?: number
		doctor?: NameAndCodeResponseDTO
		revisionDoctor?: NameAndCodeResponseDTO
		doctorsAssigned?: NameAndCodeResponseDTO[]
		digitalCertification?: {
			isDigitalCertificateRequired?: boolean
			isDigitallySigned?: boolean
		}
	}
	type?: ButtonTypeTP
	openInNewTab?: boolean
	forceReplaceUrl?: boolean
	searchExamDto?: ExamSearchRequestDTO
	updateMedicalReportsCounter?: boolean
}

/**
 * Renderiza coluna de ações da tabela de exames.
 */
export function ButtonDoMedicalReportCP(props: ICPProps): JSX.Element {
	// Regra pra visualização do botao de laudar
	let medicalReportButtonConfig: { visible: boolean; label?: string; disable?: boolean } = {
		visible: DoctorPermissionUtils.canDoMedicalReport(
			props.exam.status,
			props.exam.doctorGroupCode,
			props.exam.doctor?.code,
			props.exam.revisionDoctor?.code,
			props.exam.doctorsAssigned?.map((doctor) => doctor.code),
		),
		disable: props.exam.digitalCertification?.isDigitalCertificateRequired && !AppStateUtils.getAuthData()?.isLoggedWithDigitalCertificate,
	}

	switch (props.exam.status) {
		case ExamStatusEnum.IN_PROGRESS:
		case ExamStatusEnum.AWAITING:
			medicalReportButtonConfig.label = 'Laudar'
			break

		case ExamStatusEnum.RECTIFICATION:
			medicalReportButtonConfig.label = 'Retificar Laudo'
			break

		case ExamStatusEnum.SIGNED:
		case ExamStatusEnum.SIGNED_RECTIFICATION:
		case ExamStatusEnum.DONE:
			medicalReportButtonConfig.label = 'Revisar Laudo'
			break

		default:
			break
	}

	if (!medicalReportButtonConfig.visible) return <></>

	return (
		<AccessControlCP permission={{ byUserType: UserTypeEnum.DOCTOR }}>
			<ButtonCP
				type={props.type ?? 'primary'}
				size={'small'}
				onClick={() =>
					RoutingHelper.goToMedicalReport(
						props.exam.id,
						props.openInNewTab ?? false,
						props.forceReplaceUrl,
						props.searchExamDto,
						props.updateMedicalReportsCounter,
					)
				}
				icon={medicalReportButtonConfig.disable ? 'warning' : 'edit'}
				tooltip={
					medicalReportButtonConfig.disable
						? 'Esta unidade hospitalar requer autenticação por meio de certificado digital. Por favor, realize o login utilizando o certificado.'
						: undefined
				}
				disabled={medicalReportButtonConfig.disable}
			>
				{medicalReportButtonConfig.label}
			</ButtonCP>
		</AccessControlCP>
	)
}
