import React from 'react'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps {
	medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO
	onChange: (medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO) => void
}

export function TabEditMedicalReportConfigStylesICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Margin
			</TitleCP>

			<RowCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Top'}
						value={props.medicalReportHtmls?.margin.top}
						onChange={(value) => {
							props.medicalReportHtmls.margin.top = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Bottom'}
						value={props.medicalReportHtmls?.margin.bottom}
						onChange={(value) => {
							props.medicalReportHtmls.margin.bottom = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Left'}
						value={props.medicalReportHtmls?.margin.left}
						onChange={(value) => {
							props.medicalReportHtmls.margin.left = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Right'}
						value={props.medicalReportHtmls?.margin.right}
						onChange={(value) => {
							props.medicalReportHtmls.margin.right = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
			</RowCP>

			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Padding
			</TitleCP>
			<RowCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Top'}
						value={props.medicalReportHtmls?.padding.header}
						onChange={(value) => {
							props.medicalReportHtmls.padding.header = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
				<ColumnCP size={6}>
					<InputCP
						type={'number'}
						min={0}
						label={'Bottom'}
						value={props.medicalReportHtmls?.padding.footer}
						onChange={(value) => {
							props.medicalReportHtmls.padding.footer = parseFloat(value)
							props.onChange(props.medicalReportHtmls)
						}}
					/>
				</ColumnCP>
			</RowCP>
		</>
	)
}
