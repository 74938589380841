import React, { useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TableExamsSuggestionsCP } from 'modules/exams/components/exams/table-exams-suggestions/TableExamsSuggestionsCP'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'

interface ICPProps {
	visible: boolean
	exam: ExamResponseDTO
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ModalExamsSuggestionsCP(props: ICPProps): JSX.Element {
	const [hasAssignedExams, setHasAssignedExams] = useState<boolean>()

	return (
		<ModalCP
			visible={props.visible}
			top={10}
			width={700}
			onCancel={() => RoutingHelper.goToExams(undefined, props.searchExamDto, true)}
			footer={
				<FlexCP justifyContent={'space-between'}>
					<ButtonCP size={'small'} icon={'arrow-left'} onClick={() => RoutingHelper.goToExams(undefined, props.searchExamDto, true)}>
						Voltar para listagem de exames
					</ButtonCP>

					{AppStateUtils.getCurrentDoctorGroup()?.code === 142 && (
						<ButtonCP onClick={() => IntercomUtils.showNewMessage('Favor alocar mais exames pra mim')} size={'small'} icon={'plus'}>
							Solicitar mais exames
						</ButtonCP>
					)}
				</FlexCP>
			}
		>
			<FlexCP justifyContent={'center'}>
				<HelpCP text={'Escolha seu próximo exame para laudar ou clique no botão "Voltar para listagem"'} type={'text'} />
			</FlexCP>

			{!!props.exam.patient.name && !!props.exam.patient.birthday && !!props.exam.patient.gender && (
				<TableExamsSuggestionsCP
					title={'Exames do mesmo paciente'}
					filters={{
						viewAsDoctor: true,
						doctorGroupCode: props.exam.doctorGroup?.code,
						patientName: props.exam.patient.name,
						patientGender: props.exam.patient.gender,
						patientBirthday: DateUtils.toDate(props.exam.patient.birthday, DateFormatEnum.US_WITHOUT_TIME),
						clinicCodes: [props.exam.clinic.code],
						statuses: [ExamStatusEnum.AWAITING],
					}}
					previousExamListDto={props.searchExamDto}
				/>
			)}
			<TableExamsSuggestionsCP
				title={'Atribuídos para mim'}
				onLoadData={setHasAssignedExams}
				filters={{
					viewAsDoctor: true,
					doctorGroupCode: props.exam.doctorGroup?.code,
					assigned: ExamAssignedFilterEnum.YES,
					doctorCodes: [AppStateUtils.getLoggedUser()!.code],
					statuses: [ExamStatusEnum.AWAITING],
				}}
				previousExamListDto={props.searchExamDto}
			/>

			{hasAssignedExams === false && (
				<TableExamsSuggestionsCP
					title={'Próximos da fila de exames'}
					filters={{
						viewAsDoctor: true,
						...props.searchExamDto,
					}}
					previousExamListDto={props.searchExamDto}
				/>
			)}

			<HelpCP text={'Está sendo exibido os 5 primeiros exames ...'} type={'text'} />
		</ModalCP>
	)
}
