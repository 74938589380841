import { Dispatch, SetStateAction, useState } from 'react'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ExamBillingFlagsUpdateRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamBillingFlagsUpdateRequestDTO'
import { ExamExtraDataTP } from 'submodules/beerads-sdk/services/exams/exams/types/ExamExtraDataTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestTP'

interface IUseButtonPopoverExamBillingFlagsProps {
	onChangeBillingFlags?: () => void
	exam: {
		extraData?: ExamExtraDataTP
		id: string
	}
}

interface IUseButtonPopoverExamBillingFlagsReturn {
	isPopoverVisible: boolean
	flags: ExamBillingFlagsUpdateRequestDTO
	setIsPopoverVisible: Dispatch<SetStateAction<boolean>>
	handleBillingFlagsSave: () => void
	handlePopperClose: () => void
	onSetBillingFlagsRequestChange: () => void
	onBillingFlagChange: (key: keyof ExamBillingFlagsUpdateRequestDTO) => void
	setBillingFlagsRequest: RequestTP<void>
}

function setDefaultExtraData(extraData?: ExamExtraDataTP): ExamBillingFlagsUpdateRequestDTO {
	return {
		doNotBilling: extraData?.doNotBilling ?? false,
		forceDoctorRevenue: extraData?.forceDoctorRevenue ?? false,
	}
}

export function useButtonPopoverExamBillingFlags({
	exam,
	onChangeBillingFlags,
}: IUseButtonPopoverExamBillingFlagsProps): IUseButtonPopoverExamBillingFlagsReturn {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
	const [flags, setFlags] = useState<ExamBillingFlagsUpdateRequestDTO>(() => setDefaultExtraData(exam.extraData))

	const setBillingFlagsRequest = useRequest<void>('none')

	function onSetBillingFlagsRequestChange(): void {
		const isRequestValid = RequestUtils.isValidRequestReturn(
			setBillingFlagsRequest,
			NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
			NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			true,
		)

		if (!isRequestValid) return

		if (onChangeBillingFlags) {
			onChangeBillingFlags()
		}
		setIsPopoverVisible(false)
	}

	function handleBillingFlagsSave(): void {
		const dto: ExamBillingFlagsUpdateRequestDTO = {
			doNotBilling: flags.doNotBilling,
			forceDoctorRevenue: flags.forceDoctorRevenue,
		}

		setBillingFlagsRequest.runRequest(ExamsRequests.updateBillingFlags(exam.id, dto))
	}

	function onBillingFlagChange(key: keyof ExamBillingFlagsUpdateRequestDTO): void {
		setFlags((prev) => ({ ...prev, [key]: !prev[key] }))
	}

	function handlePopperClose(): void {
		setIsPopoverVisible(false)
		setFlags(setDefaultExtraData(exam.extraData))
	}

	return {
		flags,
		isPopoverVisible,
		setBillingFlagsRequest,
		handlePopperClose,
		onBillingFlagChange,
		setIsPopoverVisible,
		handleBillingFlagsSave,
		onSetBillingFlagsRequestChange,
	}
}
