import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { DoctorGroupExamDescriptionSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/request/DoctorGroupExamDescriptionSearchRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DoctorGroupExamDescriptionResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/response/DoctorGroupExamDescriptionResponseDTO'
import { ExamDescriptionSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/exams-description/dtos/request/ExamDescriptionSaveRequestDTO'

export class DoctorGroupExamDescriptionsRequests {
	public static CONTROLLER_ROOT = 'doctor-groups/exam-decriptions'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static DELETE_EP = 'delete/:code'
	public static SEARCH_EP = 'search'

	static create = (dto: ExamDescriptionSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupExamDescriptionsRequests.CONTROLLER_ROOT}/${DoctorGroupExamDescriptionsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: ExamDescriptionSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupExamDescriptionsRequests.CONTROLLER_ROOT}/${DoctorGroupExamDescriptionsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupExamDescriptionsRequests.CONTROLLER_ROOT}/${DoctorGroupExamDescriptionsRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static search = (dto: DoctorGroupExamDescriptionSearchRequestDTO): RequestConfigTP<DoctorGroupExamDescriptionResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupExamDescriptionsRequests.CONTROLLER_ROOT}/${DoctorGroupExamDescriptionsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
