import React from 'react'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { BlinkCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BlinkCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

interface ICPProps {
	urgencyType: ExamUrgencyTypeEnum
	size?: number
}

/**
 * Retonar o icone.
 */
export function IconByUrgencyTypeCP(props: ICPProps): JSX.Element {
	if (props.urgencyType === ExamUrgencyTypeEnum.AVC) {
		return (
			<BlinkCP>
				<IconICP iconName={ThemeProjectCommon.icons[props.urgencyType]} size={props.size} />
			</BlinkCP>
		)
	}

	return <IconICP iconName={ThemeProjectCommon.icons[props.urgencyType]} size={props.size} />
}
