import React from 'react'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

/**
 */
export function WelcomeContentSelectDoctorGroupCP(): JSX.Element {
	return (
		<WelcomeContentCP icon={<IconICP iconName={ThemeProjectCommon.icons.doctorGroup} size={50} />} message={'Selecione um grupo de médicos no menu'} />
	)
}
