import {
	convertFromRaw,
	convertToRaw,
	EditorState,
	genKey,
	Modifier,
	RawDraftContentBlock,
	RawDraftContentState,
	RichUtils,
	SelectionState,
} from 'draft-js'
import { VoiceIptInlineStyleKeyTP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/voice-input-plugin/inner/VoiceInputPluginTypes'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'

type SelectionMapTP = {
	anchorKey: string
	focusKey: string
	anchorOffset: number
	focusOffset: number
}

/**
 * HELPER
 * Encapsula logica envolvida no processo de introduzir 01 texto obtido
 * via captura de voz no corpo do conteudo do editor.
 *
 * @fixme: Perda de estilos inline em texto subsequente ao incluido no meio de 01 bloco
 *
 * @author hjcostabr
 * @author Giovanni
 */
export class VoiceDetectionHelper {
	/** Estado do conteudo do editor (onde se insere texto capturado). */
	private static contentState: RawDraftContentState
	/** Nova posicao do cursor apos insercao do texto capturado por voz. */
	private static nextCursorOffset: number
	/** Parametros para selecao do texto modificado devido a insercao do conteudo capturado por voz. */
	private static insertionSelectionMap: SelectionMapTP

	private constructor() {
		/** Construtor privado impede instanciacao. */
	}

	/** Define & retorna novo estado do editor contendo o texto capturado por voz. */
	public static getStateWithVoiceInput(caughtText: string, editorState: EditorState, customInlineStyle?: VoiceIptInlineStyleKeyTP): EditorState {
		this.contentState = convertToRaw(editorState.getCurrentContent())

		const selectionState = editorState.getSelection()
		const currentCursorOffset = selectionState.getEndOffset()
		const currentBlock = this.contentState.blocks.find((block) => block.key === selectionState.getAnchorKey()) as RawDraftContentBlock

		const { text, newLine } = this.parseVoiceInput(caughtText)

		if (newLine) this.addTextInSubsequentBlock(currentBlock, currentCursorOffset, text)
		else this.addTextInCurrentBlock(currentBlock, currentCursorOffset, text)

		return this.getNextEditorState(editorState, customInlineStyle)
	}

	/**
	 * Avalia & retorna texto capturado assegurando a inclusao de 'quebra de linha',
	 * 'ponto final' & 'virgula'.
	 */
	private static parseVoiceInput(text: string): { text: string; newLine: boolean } {
		text = text.replace(/v[iíÍ]rgula/gi, ',')
		text = text.replace(/ponto final/gi, '.')

		const newLineRegex = new RegExp('nova linha', 'gi')
		const newLine = !!text.match(newLineRegex)

		if (newLine) text = text.replace(newLineRegex, '')

		// Capitaliza a primeira letra da frase.
		const startsWithSign = !!text.match(/^[.,](.*)/)
		if (!startsWithSign) text = `${text.charAt(0).toUpperCase()}${text.substring(1, text.length)}`

		return { text, newLine }
	}

	/** Insere novo texto no mesmo bloco atualmente selecionado. */
	private static addTextInCurrentBlock(currentBlock: RawDraftContentBlock, currentOffset: number, text: string): void {
		const currentText = currentBlock.text + ''

		currentBlock.text = currentText.slice(0, currentOffset) + text + currentText.slice(currentOffset)

		this.nextCursorOffset = currentOffset + text.length

		this.insertionSelectionMap = {
			anchorKey: currentBlock.key,
			focusKey: currentBlock.key,
			anchorOffset: currentOffset,
			focusOffset: currentBlock.text.length,
		}
	}

	/** Adiciona novo bloco subsequente ao atual & insere novo texto nele. */
	private static addTextInSubsequentBlock(currentBlock: RawDraftContentBlock, currentCursorOffset: number, text: string): void {
		// Atualizar texto do bloco atual
		const prevTxtFirstHalf = currentBlock.text.slice(0, currentCursorOffset)
		const prevTxtLastHalf = currentBlock.text.slice(currentCursorOffset)
		currentBlock.text = prevTxtFirstHalf

		// Inserir texto no novo bloco
		let prevBlockIdx = 0

		for (let i = 0; i < this.contentState.blocks.length; i++) {
			const block = this.contentState.blocks[i]
			if (block.key === currentBlock.key) {
				prevBlockIdx = i + 1
				break
			}
		}

		const currentBlocks = this.contentState.blocks

		const newBlockData = {
			key: genKey(),
			text: text + prevTxtLastHalf,
		}

		this.contentState.blocks = [
			...currentBlocks.slice(0, prevBlockIdx),
			TextEditorUtils.getNewBlock(newBlockData),
			...currentBlocks.slice(prevBlockIdx),
		] as RawDraftContentBlock[]

		this.nextCursorOffset = text.length

		this.insertionSelectionMap = {
			anchorKey: currentBlock.key,
			focusKey: newBlockData.key,
			anchorOffset: 0,
			focusOffset: newBlockData.text.length,
		}
	}

	/** Gera & retorna novo estado do editor incluido novo texto capturado por voz. */
	private static getNextEditorState(prevState: EditorState, customInlineStyle?: VoiceIptInlineStyleKeyTP): EditorState {
		// Aplica estilos ao texto inserido
		let contentState = convertFromRaw(this.contentState)
		const enteredTextSelection = new SelectionState(this.insertionSelectionMap)

		if (!!customInlineStyle) contentState = Modifier.applyInlineStyle(contentState, enteredTextSelection, customInlineStyle)

		let nextState = EditorState.createWithContent(contentState)
		nextState = EditorState.forceSelection(nextState, enteredTextSelection)
		nextState = this.getStateWithAppliedInlineStyles(prevState, nextState)

		// Retorna estado com cursor posicionado no fim da insercao
		const nextSelection = new SelectionState({
			anchorKey: this.insertionSelectionMap.focusKey,
			focusKey: this.insertionSelectionMap.focusKey,
			anchorOffset: this.nextCursorOffset,
			focusOffset: this.nextCursorOffset,
		})

		nextState = EditorState.forceSelection(nextState, nextSelection)
		return nextState
	}

	/**
	 * Avalia estado do bloco no qual texto capturado por voz sera incluido e
	 * garante que estilo do texto inserido implementa estilo previamente contido no
	 * local aonde ele vai entrar.
	 */
	private static getStateWithAppliedInlineStyles(prevState: EditorState, nextState: EditorState): EditorState {
		const fontSize = TextEditorUtils.getSelectionFontSize(prevState)
		if (!!fontSize) nextState = TextEditorUtils.getStateWithSetFontSize(nextState, fontSize)

		const prevStyleMap = prevState.getCurrentInlineStyle()
		const nextStyleMap = nextState.getCurrentInlineStyle()

		if (prevStyleMap.has('BOLD') !== nextStyleMap.has('BOLD')) nextState = RichUtils.toggleInlineStyle(nextState, 'BOLD')

		if (prevStyleMap.has('ITALIC') !== nextStyleMap.has('ITALIC')) nextState = RichUtils.toggleInlineStyle(nextState, 'ITALIC')

		return nextState
	}
}
