import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

interface IContentCPProps
	extends React.PropsWithChildren<{
		style?: {}
	}> {}

/**
 * COMPONENTE
 * Area de conteudo principal dentro de 01 layout.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function ContentCP(props: IContentCPProps): JSX.Element {
	return <ContentSCP style={props.style}>{props.children}</ContentSCP>
}

const ContentSCP = styled(Layout.Content)`
	display: flex;
	height: 100%;
	flex-direction: column;
	overflow: hidden;
`
