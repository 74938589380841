import { IsIn } from '_old/main/common/validation/decorators/IsIn'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'

export class ClinicDoctorGroupDoctorFormModel extends FormModel {
	@IsRequired()
	doctorCode: number

	@IsOptional()
	@IsIn(Object.values(ExamModalityEnum), { each: true })
	modalities: ExamModalityEnum[]

	constructor(initialData?: ObjectPropsTP<ClinicDoctorGroupDoctorFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
