import React from 'react'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { RoutingHelper } from 'config/RoutingHelper'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	clinicCode?: number
}

/**
 */
export function WidgetCountExamsRectificationDoneCP(props: ICPProps): JSX.Element {
	const filtersDto: ExamSearchRequestDTO = {
		viewAsDoctor: false,
		statuses: [ExamStatusEnum.DONE_RECTIFICATION],
		clinicCodes: !!props.clinicCode ? [props.clinicCode] : undefined,
		doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()?.code,
	}

	return (
		<CardWidgetOnlyValuesCP
			onClick={() => RoutingHelper.goToExams(undefined, filtersDto)}
			requestConfigGetter={WidgetsRequests.countExams(filtersDto)}
			main={{
				title: 'Retificação Concluída',
				help: 'Número de laudos já retificados que não foram entregues ainda ao paciente',
				isZeroTarget: true,
			}}
		/>
	)
}
