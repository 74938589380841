import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import styled from 'styled-components'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { SelectExamInternalClinicCP } from 'modules/clinic/components/selects/select-exam-internal-clinic/SelectExamInternalClinicCP'

export type UploadUrlTP = {
	token: string
	expirationDate: string
}
interface ICPProps {
	temporaryToken?: UploadUrlTP
	formStateManager: IFormStateManager<PendingExamFormModel>
	examCode: number
	examId: string
	clinicCode: number
}

/**
 * Dados do pedido medico.
 */
export function FormExamPendingAnamnesisDataCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<RowCP>
				<ColumnCP size={12}>
					<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
						História Clínica (Anamnese)
					</TitleCP>

					<TextAreaCP
						placeholder={'Registre as informações mais relevantes da história clínica do paciente'}
						minRows={15}
						formStateManager={props.formStateManager}
						fieldName={'anamnesis'}
						required={true}
					/>

					<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
						Clínica Interna
					</TitleCP>
					<SelectExamInternalClinicCP formStateManager={props.formStateManager} fieldName={'internalClinic'} clinicCode={props.clinicCode} />
				</ColumnCP>

				<ColumnCP size={12}>
					<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
						Pedido Médico e Anexos
					</TitleCP>

					<UploadExamAttachmentsCP
						temporaryToken={props.temporaryToken}
						examId={props.examId}
						type={ExamUploadFileTypeEnum.ATTACHMENT}
						showQrCode={true}
					/>
				</ColumnCP>
			</RowCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	margin-bottom: 20px;

	textarea {
		margin-top: 20px !important;
		padding: 5px;
		border: 1px solid #bbb !important;
		border-radius: 3px !important;
	}
`
