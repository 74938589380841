export enum ProjectWhiteLabelsEnum {
	BEERADS = 'br',
	VX = 'vx',
}

export enum ProjectWhiteLabelDomainsEnum {
	BEERADS = 'https://sistema.beerads.app',
	VX = 'https://app.vx.med.br',
}

export enum ProjectWhiteLabelPatientPortalDomainsEnum {
	BEERADS = 'https://laudo.beerads.app',
	VX = 'https://laudo.vx.med.br',
}
