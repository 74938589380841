import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { AddressFormValidator } from '_old/main/common/validators/AddressFormValidator'
import { FormClinicCP } from 'modules/clinic/components/form-clinic/FormClinicCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicFormModelConverter } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModelConverter'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'

interface ICPProps {
	onCancel: () => void
	onSave: () => void
	visible: boolean
	code?: number
}

/**
 * Drawer para criar um exame
 */
export function DrawerClinicCP(props: ICPProps): JSX.Element {
	const [clinicFormModel, setClinicFormModel] = useState<ClinicFormModel>(new ClinicFormModel())
	const clinicFormStateManager = useFormStateManager<ClinicFormModel>(clinicFormModel)

	const [addressFormModel, setAddressFormModel] = useState<AddressFormValidator>(new AddressFormValidator())
	const addressFormStateManager = useFormStateManager<AddressFormValidator>(addressFormModel)

	const getClinicRequest = useRequest<ClinicResponseDTO>()
	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		if (!props.visible) return

		clinicFormStateManager.reset(new ClinicFormModel({}))

		if (props.code) getClinicRequest.runRequest(ClinicsRequests.get(props.code))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, 'Erro obter unidade hospitalar para atualização')) return

		const clinicResult = getClinicRequest.responseData!

		setClinicFormModel(ClinicFormModelConverter.formatFormModel(clinicResult))
		setAddressFormModel(AddressFormUtils.formatFormModel(clinicResult.address))
	}

	return (
		<DrawerCP width={800} onClose={props.onCancel} visible={props.visible} title={'Unidade Hospitalar'} loading={getClinicRequest.isAwaiting}>
			<FormClinicCP
				code={props.code}
				clinicFormStateManager={clinicFormStateManager}
				addressFormStateManager={addressFormStateManager}
				onSave={props.onSave}
			/>
		</DrawerCP>
	)
}
