import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { PopoverTeamRulesCP } from 'modules/doctor-groups/components/doctor-groups/popover-team-rules/PopoverTeamRulesCP'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { TeamsRequests } from 'submodules/beerads-sdk/services/doctor-groups/teams/TeamsRequests'

export const TableDoctorGroupTeamsUtils = {
	getColumns(onEdit: (team: TeamResponseDTO) => void, onDelete: () => void): Array<ITableColumn<TeamResponseDTO>> {
		return [
			{
				width: 20,
				render: (text, record) => <RoundedWrapperCP color={record.color} />,
			},
			{
				title: 'Nome',
				render: (text, record, index) => record.name,
			},
			{
				title: 'Regras',
				render: (text, record, index) => <PopoverTeamRulesCP rules={record.rules} />,
			},
			{
				render: (text, record) => (
					<FlexCP justifyContent={'flex-end'}>
						<ButtonCP icon={'edit'} onClick={() => onEdit(record)} size={'small'} marginRight={5} />
						<ButtonDeleteRecordCP requestConfigTP={() => TeamsRequests.delete(record.code)} onDelete={onDelete} />
					</FlexCP>
				),
			},
		]
	},
}
