import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ClinicSaveRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/ClinicSaveRequestDTO'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'

export class ClinicFormModelConverter {
	static formatFormModel(dto: ClinicResponseDTO): ClinicFormModel {
		return new ClinicFormModel({
			active: dto.active,
			type: dto.type,
			slug: dto.slug,
			cnpj: dto.cnpj,
			socialName: dto.socialName,
			fantasyName: dto.fantasyName,
			billedBy: dto.billingConfig?.billedBy,
			billingPageUrl: dto.billingConfig?.billingPageUrl,
			hasWhatsapp: dto.billingConfig?.hasWhatsappIntegration,
			notes: dto.notes,
			responsibleName: dto.responsible.name,
			responsibleEmail: dto.responsible.email,
			responsiblePhoneOne: dto.responsible.phoneOne,
			responsibleTechName: dto.technicalResponsible?.name,
			responsibleTechSpecialty: dto.technicalResponsible?.specialty,
			responsibleTechId: dto.technicalResponsible?.id,
			aeTitle: dto.clinicTechConfigTP?.beeradsBox?.aeTitle,
			ip: dto.clinicTechConfigTP?.beeradsBox?.ip,
			port: dto.clinicTechConfigTP?.beeradsBox?.port,
		})
	}

	static formatDto(model: ClinicFormModel, addressModel: AddressFormModel): ClinicSaveRequestDTO {
		return {
			cnpj: model.cnpj,
			socialName: model.socialName,
			fantasyName: model.fantasyName,
			active: model.active ?? true,
			responsible: {
				name: model.responsibleName,
				email: model.responsibleEmail,
				phoneOne: model.responsiblePhoneOne,
			},
			technicalResponsible: {
				name: model.responsibleTechName,
				specialty: model.responsibleTechSpecialty,
				id: model.responsibleTechId,
			},
			address: AddressFormUtils.formatDto(addressModel),
			slug: model.slug,
			type: model.type,
			notes: model.notes,
			billingConfig: {
				billedBy: model.billedBy,
				hasWhatsappIntegration: model.hasWhatsapp,
				billingPageUrl: model.billingPageUrl,
			},
		}
	}
}
