import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { SelectDoctorIdTypeCP } from 'modules/person/components/doctor/select-doctor-id-type/SelectDoctorIdTypeCP'
import { SelectDoctorTreatmentCP } from 'modules/person/components/doctor/select-doctor-treatment/SelectDoctorTreatmentCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { DoctorRegisterFormModel } from 'modules/person/components/doctor/form-doctor-register/inner/DoctorRegisterFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<DoctorRegisterFormModel>
}

/**
 * Form de cadastro de um medico.
 */
export function FormDoctorRegisterCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP underLine={true} textSize={'normal'} marginTop={40}>
				Seja bem-vindo, informe os seus dados para se cadastrar:
			</TitleCP>

			<RowCP>
				<ColumnCP size={6}>
					<SelectDoctorTreatmentCP required={true} formStateManager={props.formStateManager as any} fieldName={'treatment'} />
				</ColumnCP>
				<ColumnCP size={18}>
					<InputCP label={'Nome'} required={true} formStateManager={props.formStateManager} fieldName={'name'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={12}>
					<SelectDoctorIdTypeCP required={true} formStateManager={props.formStateManager as any} fieldName={'idType'} />
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP label={'Nº do Registro'} required={true} formStateManager={props.formStateManager} fieldName={'crm'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={24}>
					<InputCP label={'Especialidade'} formStateManager={props.formStateManager} fieldName={'specialty'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={12}>
					<InputCP required={true} mask={InputMaskTypeEnum.CPF} label={'CPF'} formStateManager={props.formStateManager} fieldName={'cpf'} />
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP
						label={'Data de Nascimento'}
						mask={InputMaskTypeEnum.DATE}
						formStateManager={props.formStateManager}
						fieldName={'birthDate'}
					/>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={14}>
					<InputCP type={'email'} required={true} label={'E-mail'} formStateManager={props.formStateManager} fieldName={'email'} />
				</ColumnCP>

				<ColumnCP size={10}>
					<InputCP
						required={true}
						mask={InputMaskTypeEnum.PHONE}
						label={'Celular'}
						formStateManager={props.formStateManager}
						fieldName={'phoneOne'}
					/>
				</ColumnCP>
			</RowCP>

			<TitleCP underLine={true} textSize={'normal'} marginTop={40}>
				Defina sua senha de Acesso
			</TitleCP>
			<RowCP>
				<ColumnCP size={12}>
					<InputCP type={'password'} label={'Nova Senha'} formStateManager={props.formStateManager} fieldName={'password'} />
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP type={'password'} label={'Confirma a nova senha'} formStateManager={props.formStateManager} fieldName={'confirmPassword'} />
				</ColumnCP>
			</RowCP>
		</>
	)
}
