import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { SurveyCustomerViewCP } from 'submodules/space4leads-components-ui/survey/components/survey-customer-view/SurveyCustomerViewCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import { PeopleRequests } from 'submodules/space4leads-sdk/services/people/person/PeopleRequests'
import { PersonSaveRequestDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/request/PersonSaveRequestDTO'
import { PersonResponseDTO } from 'submodules/space4leads-sdk/services/people/person/dtos/response/PersonResponseDTO'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import { SurveysRequests } from 'submodules/space4leads-sdk/services/surveys/surveys/SurveysRequests'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import {
	LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY,
	LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY,
	ModalBoxCustomerSurveyUtils,
} from 'submodules/space4leads-components-ui/survey/components/box-customer-survey/inner/ModalBoxCustomerSurveyUtils'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'

interface ICPProps {
	boxType: 'center' | 'bottom' | 'inline'
	surveyToken: string
	projectCode: number
	person: PersonSaveRequestDTO
	forceOpen?: boolean
	tags?: string[]
	timeConfig: {
		daysBetweenAnswers: number
		daysToFirstAnswer: number
		daysToReopenOnCancel: number
	}
}

/**
 * Modal ou Drawer com a pesquisa para ser respondida
 *
 * Para forcar a abertura da pesquisa, executar no console: localStorage.setItem('LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY', true)
 */
export function ModalBoxCustomerSurveyCP(props: ICPProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>()

	const [personToken, setPersonToken] = useState<string>()

	const [survey, setSurvey] = useState<SurveyResponseDTO>()
	const getSurveyRequest = useRequest<SurveyResponseDTO>()
	useEffect(() => {
		onGetSurveyRequestChange()
	}, [getSurveyRequest.isAwaiting])

	useEffect(() => {
		init()
	}, [])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		// Se tiver marcada para forcar a abertura, ignora todos os testas
		const forceOpen = props.forceOpen ?? localStorage.getItem(LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY)
		if (!forceOpen) {
			// Se for DEV não faz nada
			if (NeritFrameworkProjectConfig.isDev()) return

			// Verifica se o usuario cancelou a pesquisa, se sim valida se ja expirou para mostrar novamente
			if (!ModalBoxCustomerSurveyUtils.isCancellationExpired(props.timeConfig.daysToReopenOnCancel)) return
		}

		getSurveyRequest.runRequest(SurveysRequests.findByToken(props.surveyToken, props.projectCode))
	}

	/**
	 * Retorno dos detalhes da pesquisa
	 */
	async function onGetSurveyRequestChange(): Promise<void> {
		if (!RequestUtils.isValidRequestReturn(getSurveyRequest)) return

		// Busca a pessoa com os dados informados (provavelmente logado)
		const personResponse: IApiReturn<PersonResponseDTO> = (
			await RequestHelper.runRequest(PeopleRequests.createOrUpdate(props.person, props.projectCode))
		).data as any
		if (!personResponse || !personResponse.data) return

		setPersonToken(personResponse.data.token)
		setSurvey(getSurveyRequest.responseData!)

		const canShowBasedOnLastAnswer = await ModalBoxCustomerSurveyUtils.wasLastAnswerExpired(
			props.surveyToken,
			personResponse.data,
			props.timeConfig.daysBetweenAnswers,
			props.timeConfig.daysToFirstAnswer,
			props.projectCode,
		)

		// Se tiver marcada para forcar a abertura, ignora todos os testas
		const forceOpen = localStorage.getItem(LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY)
		setIsVisible(canShowBasedOnLastAnswer || !!forceOpen)
	}

	/**
	 * Retorno ao salvar uma resposta
	 */
	function onSaveSurvey(): void {
		// Limpa o locastorage, sera considerado entao a data da ultima resposta posteriormente
		localStorage.removeItem(LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY)
		localStorage.removeItem(LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY)
		setIsVisible(false)
	}

	/**
	 * Ao cancelar a pesquisa, guarda no storage a data que isso aconteceu para mostrar novamente depois de um tempo
	 */
	function onCancelSurvey(): void {
		localStorage.setItem(LOCAL_STORAGE_SURVEY_CANCELATION_DATE_KEY, new Date().toString())
		localStorage.removeItem(LOCAL_STORAGE_SURVEY_FORCE_OPEN_KEY)
		setIsVisible(false)
	}

	if (!survey) return <></>

	return (
		<>
			{props.boxType === 'bottom' && (
				<DrawerCP placement={'bottom'} loading={getSurveyRequest.isAwaiting} visible={isVisible} onClose={onCancelSurvey}>
					<SurveyCustomerViewCP
						personToken={personToken}
						projectCode={props.projectCode}
						surveyToken={props.surveyToken}
						survey={survey}
						onSaveAnswer={onSaveSurvey}
						viewMode={'widget'}
						tags={props.tags}
					/>
				</DrawerCP>
			)}
			{props.boxType === 'center' && (
				<ModalCP width={'95%'} loading={getSurveyRequest.isAwaiting} visible={isVisible} onClose={onCancelSurvey} noFooter={true}>
					<SurveyCustomerViewCP
						personToken={personToken}
						projectCode={props.projectCode}
						surveyToken={props.surveyToken}
						survey={survey}
						onSaveAnswer={onSaveSurvey}
						viewMode={'widget'}
						tags={props.tags}
					/>
				</ModalCP>
			)}
			{props.boxType === 'inline' && (
				<CardCP>
					<SurveyCustomerViewCP
						personToken={personToken}
						projectCode={props.projectCode}
						surveyToken={props.surveyToken}
						survey={survey}
						onSaveAnswer={onSaveSurvey}
						viewMode={'widget'}
						tags={props.tags}
					/>
				</CardCP>
			)}
		</>
	)
}
