import React, { useEffect, useState } from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { PatientPortalExamsSearchResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalExamsSearchResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PatientPortalRequests } from 'submodules/beerads-sdk/services/patient-portal/PatientPortalRequests'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import styled from 'styled-components'
import { AuthActions } from 'app/utils/AuthActions'
import { IsValidReqReturnConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IsInvalidReqReturnConfigTP'
import { PatientExamCP } from 'modules/patient-portal/components/patient-exams/PatientExamCP'

/**
 * Tela de visualização do resultado do exame pelo paciente.
 */
export function ScreenPatientPortalHome(): JSX.Element {
	const [patientExams, setPatientExams] = useState<PatientPortalExamsSearchResponseDTO>()
	const searchPatientExamsRequest = useRequest<PatientPortalExamsSearchResponseDTO>()
	useEffect(onSearchPatientExamsRequestChange, [searchPatientExamsRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		searchPatientExamsRequest.runRequest(PatientPortalRequests.searchPatientExams(AppStateUtils.getLoggedPatientPortal()!.currentExamId))
	}

	/**
	 * Retorno Requisicao
	 */
	function onSearchPatientExamsRequestChange(): void {
		const validRequestConfig: IsValidReqReturnConfigTP = {
			request: searchPatientExamsRequest,
			onUnauthorized: AuthActions.onUnauthorizedPatientPortalRequestResponse,
		}
		if (!RequestUtils.isValidRequestReturn(validRequestConfig, 'Dados inválidos')) return

		setPatientExams(searchPatientExamsRequest.responseData)
	}

	if (!patientExams) return <LoadingOverlayCP show={true} />

	return (
		<LayoutCP
			mainLayout={true}
			titleBrowser={'Portal do Paciente'}
			content={
				<ContentCP overflowVertical={true}>
					<LoadingOverlayCP show={searchPatientExamsRequest.isAwaiting} />

					{patientExams.currentExam.clinic.logoUrl && (
						<ClinicLogoWrapperSCP>
							<img src={patientExams.currentExam.clinic.logoUrl} alt={patientExams.currentExam.clinic.name} />
						</ClinicLogoWrapperSCP>
					)}

					<PatientExamCP onChangeData={init} patientPortalData={patientExams} />
				</ContentCP>
			}
		/>
	)
}

const ClinicLogoWrapperSCP = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		width: 200px;
		margin-top: 20px;
		border-radius: 5px;
	}
`
