import React from 'react'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { ExamSpecialtyEnum, ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ColumnMedicalTimetableWeekDayConfigCP } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-config/inner/ColumnMedicalTimetableWeekDayConfigCP'
import { TablesMedicalTimetableUtils } from 'modules/medical-timetable/components/tables-medical-timetable/utils/TablesMedicalTimetableUtils'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

export class TableMedicalTimetableConfigUtils {
	/**
	 */
	static getColumns(
		allRecords: MedicalTimetableStructureResponseDTO[],
		onChangeTotalDoctors: (record: MedicalTimetableStructureResponseDTO, weekDay: WeekDaysEnum, total: number) => void,
	): Array<ITableColumn<MedicalTimetableStructureResponseDTO>> {
		return [
			{
				title: 'Escala',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderIdColumn(record, index, allRecords),
			},
			{
				title: 'Período',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderPeriodColumn(record, index, allRecords),
			},
			{
				title: 'Modalidade',
				align: 'center',
				render: (text, record, index) => TablesMedicalTimetableUtils.renderModalityColumn(record, index, allRecords),
			},
			{
				title: 'Urg./Esp.',
				align: 'center',
				render: (text, record) => (
					<>{!!ExamSpecialtyEnum[record.groupBy] ? ExamSpecialtyLabelEnum[record.groupBy] : ExamUrgencyTypeLabelEnum[record.groupBy]}</>
				),
			},
			{
				title: `Segunda-Feira`,
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.MONDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.MONDAY, total)}
					/>
				),
			},
			{
				title: 'Terça-Feira',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.TUESDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.TUESDAY, total)}
					/>
				),
			},
			{
				title: 'Quarta-Feira',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.WEDNESDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.WEDNESDAY, total)}
					/>
				),
			},
			{
				title: 'Quinta-Feira',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.THURSDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.THURSDAY, total)}
					/>
				),
			},
			{
				title: 'Sexta-Feira',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.FRIDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.FRIDAY, total)}
					/>
				),
			},
			{
				title: 'Sábado',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.SATURDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.SATURDAY, total)}
					/>
				),
			},
			{
				title: 'Domingo',
				render: (text, record) => (
					<ColumnMedicalTimetableWeekDayConfigCP
						record={record}
						weekDay={WeekDaysEnum.SUNDAY}
						onChangeTotalDoctors={(total) => onChangeTotalDoctors(record, WeekDaysEnum.SUNDAY, total)}
					/>
				),
			},
		]
	}
}
