import { TableDoctorGroupTeamsCP } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-teams/TableDoctorGroupTeamsCP'
import React from 'react'
import { LayoutSubmenuContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/content/LayoutSubmenuContentCP'

interface ICPProps {
	doctorGroupCode: number
}

export function ScreenContentDoctorGroupTeams(props: ICPProps): JSX.Element {
	return (
		<LayoutSubmenuContentCP>
			<TableDoctorGroupTeamsCP doctorGroupCode={props.doctorGroupCode} />
		</LayoutSubmenuContentCP>
	)
}
