import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	children: React.ReactNode
	appearance?: {
		noBorder?: boolean
	}
}

/**
 */
export function WrapperMedicalReportEditorCP(props: ICPProps): JSX.Element {
	return <EditorWrapperSCP noBorder={props.appearance?.noBorder}>{props.children}</EditorWrapperSCP>
}

const EditorWrapperSCP = styled.div<{ noBorder }>`
	border: ${(props) => (!props.noBorder ? '1px solid ' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '33' : '0 none')};
	box-shadow: ${(props) => (!props.noBorder ? '0px 2px 5px' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '0D' : 'none')};

	border-radius: 5px;
	background-color: #e5e5e5;
	overflow: hidden;
	height: 100%;
	position: relative;

	&.editor-fullscreen {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		box-shadow: 0 2px 12px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}4D;
		right: 0;
		margin: 0 auto;
		z-index: 9;
	}
`
