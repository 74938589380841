import FileSaver from 'file-saver'
import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
	requestConfigTP: () => RequestConfigTP
	fileName: string
	buttonSize?: ButtonSizeTP
}

/**
 */
export function ButtonExportTableFromApiCP(props: ICPProps): JSX.Element {
	const exportRequest = useRequest<Blob>('none')
	useEffect(onExportRequestChange, [exportRequest.isAwaiting])

	/**
	 */
	function onExportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(exportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		FileSaver.saveAs(exportRequest.responseData!, `${props.fileName}_${new Date().getTime()}.csv`)
	}

	return (
		<ButtonCP
			onClick={() => exportRequest.runRequest(props.requestConfigTP())}
			icon={'file-excel'}
			loading={exportRequest.isAwaiting}
			tooltip={'Exportar resultados para excel'}
			size={props.buttonSize ?? 'small'}
		>
			Exportar
		</ButtonCP>
	)
}
