import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ListCP } from 'submodules/nerit-framework-ui/common/components/list/ListCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import 'photoviewer/dist/photoviewer.min.css'
import { ExamAttachmentsRequests } from 'submodules/beerads-sdk/services/exams/attachments/ExamAttachmentsRequests'
import { ListItemMedicalReportVideoICP } from 'modules/quality-assurance/components/list-medical-report-video/inner/ListItemMedicalReportVideoICP'
import { MedicalReportVideoFileResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/MedicalReportVideoFileResponseDTO'

interface ICPProps extends BasicStyleWrapperCPProps {
	examCode: number
	doctorGroupCode: number
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ListMedicalReportVideoCP(props: ICPProps): JSX.Element {
	const getMedicalReportVideoRequest = useRequest<MedicalReportVideoFileResponseDTO[]>()

	useEffect(init, [props.examCode])

	/**
	 * Busca arquivos.
	 */
	function init(): void {
		getMedicalReportVideoRequest.runRequest(ExamAttachmentsRequests.searchMedicalReportVideo(props.examCode))
	}

	return (
		<BasicStyleWrapperCP margin={props.margin ?? { top: 20 }}>
			{
				<>
					<FlexCP justifyContent={'flex-end'} margin={{ bottom: 10 }}>
						<ButtonCP size={'small'} type={'ghost'} icon={'reload'} onClick={init} loading={getMedicalReportVideoRequest.isAwaiting} />
					</FlexCP>

					<ListCP<MedicalReportVideoFileResponseDTO>
						loading={getMedicalReportVideoRequest.isAwaiting}
						dataSource={getMedicalReportVideoRequest.responseData}
						renderItem={(item) => (
							<ListItemMedicalReportVideoICP medicalReportVideo={item} onFileDeleted={init} doctorGroupCode={props.doctorGroupCode} />
						)}
					/>
				</>
			}
		</BasicStyleWrapperCP>
	)
}
