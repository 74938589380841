import { CreateExamFormModel } from 'modules/exams/components/exams/wizard-create-exam/form-model/CreateExamFormModel'
import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { SelectGenderCP } from 'submodules/nerit-framework-ui/features/people/components/select-gender/SelectGenderCP'

interface ICPProps {
	clinicType: ClinicTypeEnum
	formStateManager: IFormStateManager<CreateExamFormModel>
}

/**
 */
export function CreateExamPatientDataICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP underLine={true} textSize={'normal'} marginBottom={15}>
				Dados do Paciente
			</TitleCP>

			<RowCP>
				<ColumnCP size={3}>
					<InputCP label={'ID'} formStateManager={props.formStateManager} fieldName={'patientId'} />
				</ColumnCP>
				<ColumnCP size={8}>
					<InputCP
						label={'Nome do Paciente'}
						fieldName={'patientName'}
						type={'text'}
						formStateManager={props.formStateManager}
						required={true}
					/>
				</ColumnCP>
				<ColumnCP size={4}>
					<SelectGenderCP fieldName={'patientGender'} formStateManager={props.formStateManager} required={true} />
				</ColumnCP>
				<ColumnCP size={4}>
					<InputCP
						label={'Nascimento'}
						fieldName={'patientBirthDate'}
						type={'text'}
						formStateManager={props.formStateManager}
						mask={InputMaskTypeEnum.DATE}
					/>
				</ColumnCP>
				<ColumnCP size={5}>
					<InputCP label={'Telefone'} fieldName={'patientPhone'} mask={InputMaskTypeEnum.PHONE} formStateManager={props.formStateManager} />
				</ColumnCP>
			</RowCP>
		</>
	)
}
