import { TextCP } from '_old/main/common/components/text/TextCP'
import { DateUtils } from '_old/main/common/utils/DateUtils'

import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { PopoverExamDateLogCP } from 'modules/exams/components/exams/popover-exam-date-log/PopoverExamDateLogCP'
import styled from 'styled-components'

interface IDatesColumnICPProps {
	exam: ExamListItemResponseDTO
	dateType: 'medicalReportDate' | 'examDate'
}

/**
 * Renderriza coluna de datas na tabela de exames.
 */
export function ColumnDatesICP(props: IDatesColumnICPProps): JSX.Element {
	if (props.dateType === 'medicalReportDate' && !props.exam.medicalReportDate) return <></>

	let formmatedDate
	if (props.dateType === 'examDate') formmatedDate = DateUtils.formatDate(props.exam.examDate, DateTimeFormatEnum.BR_WITHOUT_TIME)
	else if (props.dateType === 'medicalReportDate')
		formmatedDate = DateUtils.formatDate(props.exam.medicalReportDate!, DateTimeFormatEnum.BR_WITH_TIME_H_M)

	return (
		<PopoverExamDateLogCP exam={props.exam}>
			<DateColumnSCP>
				<TextCP text={formmatedDate} size={'small'} />
			</DateColumnSCP>
		</PopoverExamDateLogCP>
	)
}

const DateColumnSCP = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
