export enum ExamAssignedFilterEnum {
	ALL = 'ALL',
	YES = 'YES',
	NO = 'NO',
}

export enum ExamAssignedFilterLabelEnum {
	ALL = 'Todos',
	YES = 'Sim',
	NO = 'Não',
}
