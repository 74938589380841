import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { IsArray } from 'submodules/nerit-framework-utils/validators/decorators/IsArray'
import { IsIn } from 'submodules/nerit-framework-utils/validators/decorators/IsIn'
import { IsNotEmptyArray } from 'submodules/nerit-framework-utils/validators/decorators/IsNotEmptyArray'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import type { ICategoryDetails } from 'submodules/beerads-sdk/services/doctor-groups/categories/types/ICategoryDetails'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ClinicSLAConfig } from 'submodules/beerads-sdk/services/clinics/clinics/types/ClinicSLAConfig'

export class ClinicDoctorGroupFormModel extends FormModel {
	@IsArray()
	@IsIn(Object.values(ExamModalityEnum), { each: true })
	@IsNotEmptyArray()
	modalities: ExamModalityEnum[]

	@IsOptional()
	categoryCode: number

	@IsOptional()
	categoryDetails: ICategoryDetails

	@IsRequired()
	urgencyTypes: ExamUrgencyTypeEnum[]

	@IsOptional()
	slaConfigs: ClinicSLAConfig[]

	@IsOptional()
	examsToBypassPending: string[]

	@IsOptional()
	isDigitalCertificateRequired?: boolean

	@IsRequired()
	isAttachmentsRequired: boolean

	constructor(initialData?: ObjectPropsTP<ClinicDoctorGroupFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
