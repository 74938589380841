import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TableDoctorGroupTeamsUtils } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-teams/inner/TableDoctorGroupTeamsUtils'
import { DrawerTeamCP } from 'modules/doctor-groups/components/teams/drawer-team/DrawerTeamCP'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'
import { TeamsRequests } from 'submodules/beerads-sdk/services/doctor-groups/teams/TeamsRequests'

interface ICPProps {
	doctorGroupCode: number
}

/**
 * Tabela de listagem das clinicas de um group
 */
export function TableDoctorGroupTeamsCP(props: ICPProps): JSX.Element {
	const [selectedTeam, setSelectedTeam] = useState<TeamResponseDTO>()
	const [isTeamDrawerVisible, setIsTeamDrawerVisible] = useState(false)

	const [reloadTable, setReloadTable] = useState<number>()
	useEffect(() => setReloadTable(TableUtils.getReloadNumber()), [props.doctorGroupCode])

	return (
		<>
			<FlexCP justifyContent={'flex-end'} margin={{ right: 20 }}>
				<ButtonCP
					icon={'plus'}
					onClick={() => {
						setSelectedTeam(undefined)
						setIsTeamDrawerVisible(true)
					}}
				>
					Novo Time
				</ButtonCP>
			</FlexCP>

			<TableFromApiCP<TeamResponseDTO>
				shouldLoadData={reloadTable}
				appearance={{
					wrappedOnCard: true,
					showTotalOnHeader: true,
					recordLabel: 'times de médico',
					showReloadButton: true,
				}}
				apiConfig={{
					hasPagination: false,
					requestConfigTP: () =>
						TeamsRequests.search({
							doctorGroupCode: props.doctorGroupCode,
						}),
				}}
				columns={TableDoctorGroupTeamsUtils.getColumns(
					(team) => {
						setSelectedTeam(team)
						setIsTeamDrawerVisible(true)
					},
					() => setReloadTable(TableUtils.getReloadNumber()),
				)}
			/>

			<DrawerTeamCP
				visible={isTeamDrawerVisible}
				onCancel={() => setIsTeamDrawerVisible(false)}
				onSave={() => {
					setReloadTable(TableUtils.getReloadNumber())
					setIsTeamDrawerVisible(false)
				}}
				doctorGroupCode={props.doctorGroupCode}
				team={selectedTeam}
			/>
		</>
	)
}
