import React from 'react'
import 'photoviewer/dist/photoviewer.min.css'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { PdfMozillaViewer } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-mozilla-viewer/PdfMozillaViewer'
import { PdfNativeViewer } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-native-viewer/PdfNativeViewer'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'

interface ICPProps {
	visible: boolean
	fileName: string
	fileSrc: string
	onClose: () => void
	type?: 'mozillaViewer' | 'nativeViewer'
}

/**
 * Componente com listagem e upload de arquivos, generico.
 */
export function ModalPdfViewerCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP title={props.fileName} onCancel={props.onClose} noFooter={true} visible={props.visible} width={1000} top={10} padding={0}>
			{props.type === 'mozillaViewer' ? (
				<PdfMozillaViewer pdfSrc={props.fileSrc} zoomOption={'page-fit'} />
			) : (
				<PdfNativeViewer pdfSrc={`${props.fileSrc}?v=${TableUtils.getReloadNumber()}`} />
			)}
		</ModalCP>
	)
}
