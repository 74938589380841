import React, { useEffect, useState } from 'react'
import { Avatar } from 'antd'
import { TextCP } from '../text/TextCP'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import profileImgDefault from 'submodules/nerit-framework-ui/common/assets/profile.png'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

export interface IAvatarCPProps {
	size: 'large' | 'small' | 'default' | number
	src?: string
	name?: string | number
	backgroundColor?: string
	border?: string
	textTooltip?: React.ReactNode
}

export function AvatarCP(props: IAvatarCPProps): JSX.Element {
	const [isValidSrc, setIsValidSrc] = useState<boolean>(!!props.src)
	const [initials, setInitials] = useState<string | number>('')

	function _setInitials(): void {
		setInitials((typeof props.name === 'string' ? StringUtils.getFirstAndLastInitials(props.name) : props.name) ?? '')
	}
	useEffect(_setInitials, [props.name])

	/** Evento o link da imagem seja invalido. */

	function onError(): true {
		setIsValidSrc(false)
		_setInitials()
		return true
	}

	return (
		<>
			{isValidSrc && (
				<TooltipCP text={props.textTooltip} showSpan={true}>
					<Avatar src={props.src} size={props.size} shape={'circle'} onError={onError} />
				</TooltipCP>
			)}

			{!isValidSrc && props.name && (
				<TooltipCP text={props.textTooltip} showSpan={true}>
					<Avatar size={props.size} style={{ backgroundColor: props.backgroundColor, border: props.border }} shape={'circle'}>
						<TextCP text={initials} />
					</Avatar>
				</TooltipCP>
			)}

			{!isValidSrc && !props.name && (
				<TooltipCP text={props.textTooltip} showSpan={true}>
					<Avatar src={profileImgDefault} size={props.size} shape={'circle'} />
				</TooltipCP>
			)}
		</>
	)
}
