import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { FormClinicReportTemplateConfigCP } from 'modules/clinic/components/form-clinic-report-config-template/FormClinicReportTemplateConfigCP'
import { UploadClinicLogoCP } from 'modules/clinic/components/upload-clinic-logo/UploadClinicLogoCP'

interface ICPProps {
	clinicCode: number
	onSave: () => void
}

/**
 * Processo de criar uma nova clinica
 */
export function CreateClinicReportConfigStepICP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP textSize={'normal'} underLine={true}>
				Logo da Unidade Hospitalar
			</TitleCP>
			<UploadClinicLogoCP clinicCode={props.clinicCode} />

			<FormClinicReportTemplateConfigCP clinicCode={props.clinicCode} onSave={props.onSave} />
		</>
	)
}
