import { MedicalReportFinalizeRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportFinalizeRequestDTO'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { GenderEnum } from 'submodules/nerit-framework-utils/utils/enums/GenderEnum'
import {
	MEDICAL_REPORT_FEMALE_WORDS,
	MEDICAL_REPORT_MALE_WORDS,
} from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportGenderWords'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { MedicalReportSignRequestDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/request/MedicalReportSignRequestDTO'

/**
 */
export class MedicalReportFormModelConverter {
	/**
	 */
	static formatFinalizeDto(
		exam: ExamResponseDTO,
		formStateManager: IFormStateManager<MedicalReportFormModel>,
	): MedicalReportFinalizeRequestDTO | undefined {
		if (!FormStateManagerUtils.validateRequiredFields(formStateManager)) return

		// Validacoes do laudo
		if (!MedicalReportFormModelConverter.validateContrast(exam, formStateManager)) return

		const formValues = formStateManager.getFormValues()!
		return {
			examCode: exam.code,
			medicalReportsContents: formValues.medicalReports,
			isCritical: formValues.isCritical,
			rectificationClinicalImpact: formValues.rectificationClinicalImpact,
		}
	}

	/**
	 */
	static formatSignDto(exam: ExamResponseDTO, formStateManager: IFormStateManager<MedicalReportFormModel>): MedicalReportSignRequestDTO | undefined {
		if (!FormStateManagerUtils.validateRequiredFields(formStateManager)) return

		const formValues = formStateManager.getFormValues()!
		if (!formValues.revisionDoctorCode) {
			NotificationHelper.error('Ops!', 'Selecione o médico que será responsável pela revisão do laudo')
			return
		}

		// Validacoes do laudo
		if (!MedicalReportFormModelConverter.validateContrast(exam, formStateManager)) return

		return {
			examCode: exam.code,
			medicalReportsContents: formValues.medicalReports,
			revisionDoctorCode: formValues.revisionDoctorCode,
			isCritical: formValues.isCritical,
		}
	}

	/**
	 * Se o exame do paciente tem contraste, verifica se o medico marcou que considerou contraste no laudo
	 */
	static validateContrast(exam: ExamResponseDTO, formStateManager: IFormStateManager<MedicalReportFormModel>): boolean {
		if (ArrayUtils.isEmpty(exam.contrasts)) return true

		const wasContrastConsideredOnMedicalReport = formStateManager.getFieldValue('wasConsideredContrast')
		if (!wasContrastConsideredOnMedicalReport)
			NotificationHelper.error('O exame possui contraste!', 'Marque que você considerou contraste no laudo para finalizar')

		return wasContrastConsideredOnMedicalReport
	}

	/**
	 * Verifica se medico utilizou palavras de sexo trocado
	 */
	static findGenderBlockedWords(exam: ExamResponseDTO, formStateManager: IFormStateManager<MedicalReportFormModel>): string[] {
		if (!exam.patient.gender) return []

		// Obtem as palavras bloqueados do sexo oporto
		const wordsBlocked = exam.patient.gender === GenderEnum.FEMALE ? MEDICAL_REPORT_MALE_WORDS : MEDICAL_REPORT_FEMALE_WORDS

		const medicalReports: MedicalReportContentTP[] = formStateManager.getFieldValue('medicalReports') ?? []
		const blockWords: string[] = []

		wordsBlocked.forEach((blockedWord) => {
			medicalReports.forEach((medicalReportContent) => {
				if (medicalReportContent.html?.toLowerCase().includes(blockedWord.toLowerCase())) blockWords.push(blockedWord)
			})
		})
		return blockWords
	}
}
