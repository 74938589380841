import React from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { ColumnDatesICP } from 'modules/exams/components/exams/table-exams/columns/ColumnDatesICP'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { IconByUrgencyTypeCP } from 'modules/exams/components/exams/icons/IconByUrgencyTypeCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ButtonDoMedicalReportCP } from 'modules/exams/components/medical-report/button-do-medical-report/ButtonDoMedicalReportCP'
import { ColumnSlaICP } from 'modules/exams/components/exams/table-exams/columns/ColumnSlaICP'

interface ICPProps {
	filters: ExamSearchRequestDTO
	title: string
	onLoadData?: (hasData: boolean) => void
	previousExamListDto?: ExamSearchRequestDTO
}

/**
 */
export function TableExamsSuggestionsCP(props: ICPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={{ bottom: 20 }}>
			<TableFromApiCP<ExamListItemResponseDTO, ExamSearchRequestDTO>
				onLoad={(result) => {
					if (!!props.onLoadData) props.onLoadData(!ArrayUtils.isEmpty(result))
				}}
				appearance={{
					title: props.title,
					showHeader: true,
					recordLabel: 'exames',
					hasPagination: false,
					hideOnEmpty: true,
				}}
				shouldLoadData={1}
				apiConfig={{
					hasPagination: true,
					pageSize: 5,
					filters: props.filters,
					requestConfigTP: (filters) => ExamsRequests.search(filters!),
				}}
				row={{
					setClass: (row) => row.urgentType ?? '',
				}}
				columns={[
					{
						render: (text, record) => <IconByUrgencyTypeCP urgencyType={record.urgentType} size={16} />,
					},
					{
						width: 100,
						render: (text, record) => <ColumnSlaICP exam={record} />,
					},
					{
						title: 'Data',
						render: (text, record) => <ColumnDatesICP exam={record} dateType={'examDate'} />,
					},
					{
						title: 'Exame',
						render: (text, record) => (
							<AvatarExamModalityCP modality={record.modality} description={record.description} size={28} fontSize={'small'} />
						),
					},
					{
						title: 'Paciente',
						render: (text, record) => record.patient.name,
					},
					{
						render: (text, record) => (
							<ButtonDoMedicalReportCP exam={record} searchExamDto={props.previousExamListDto} updateMedicalReportsCounter={true} />
						),
					},
				]}
			/>
		</BasicStyleWrapperCP>
	)
}
