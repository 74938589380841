import React from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'
import { ExamStatusGroupEnum, ExamStatusGroupLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	examStatusGroup: ExamStatusGroupEnum
}

/**
 */
export function SubtitleExamGroupStatusCP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<FlexCP flexWrap={'wrap'}>
				<i>{`Status de ${ExamStatusGroupLabelEnum[props.examStatusGroup]}:`}</i>
				<span>
					{ExamStatusUtils.examStatusOfExamStatusGroupMap
						.get(props.examStatusGroup)
						?.map((status) => ExamStatusLabelEnum[status])
						.join(', ')}
				</span>
			</FlexCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	padding: 0 20px;
	margin-top: 10px;

	i {
		margin-right: 5px;
	}
	span {
		color: ${ThemeProject.gray300};
	}
`
