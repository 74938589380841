import React, { useEffect, useState } from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { CategoryResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/categories/responses/CategoryResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CategoriesRequests } from 'submodules/beerads-sdk/services/doctor-groups/categories/CategoriesRequests'
import { ICategoryDetails } from 'submodules/beerads-sdk/services/doctor-groups/categories/types/ICategoryDetails'

interface IICPProps {
	doctorGroupCode: number
	formStateManager: IFormStateManager<any>
	originalCategoryCode?: number
	originalCategoryDetails?: ICategoryDetails // Detalhes da categoria originais do BD
	categoryFieldName: string
	categoryDataFieldName: string
}

/**
 * Select de categorias de um grupo
 */
export function SelectClinicCategoryWithDetailsCP(props: IICPProps): JSX.Element {
	const [categories, setCategories] = useState<CategoryResponseDTO[]>([])
	useEffect(() => onSelectCategory(props.formStateManager.getFieldValue(props.categoryFieldName)), [categories])

	const getCategoriesRequest = useRequest<ListResponseDTO<CategoryResponseDTO>>()
	useEffect(onGetClinicCategoriesRequestChange, [getCategoriesRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Carrega todos.
	 */
	function init(): void {
		getCategoriesRequest.runRequest(CategoriesRequests.search(props.doctorGroupCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicCategoriesRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getCategoriesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setCategories(getCategoriesRequest.responseData!.list)
	}

	/**
	 * Ao selecionar uma categoria
	 */
	function onSelectCategory(categoryCode?: number): void {
		if (!categoryCode) {
			props.formStateManager.changeFieldValue(props.categoryFieldName, null)
			props.formStateManager.changeFieldValue(props.categoryDataFieldName, null)
			return
		}

		// Se mudou para categoria original, seta o valor original
		if (props.originalCategoryCode === categoryCode && props.originalCategoryDetails)
			return props.formStateManager.changeFieldValue(props.categoryDataFieldName, props.originalCategoryDetails)

		// Busca o objeto da categoria selecionada
		const category = categories.find((category) => category.code === categoryCode)
		if (!category) return

		if (!category.details) return props.formStateManager.changeFieldValue(props.categoryDataFieldName, null)

		props.formStateManager.changeFieldValue(props.categoryDataFieldName, category.details)
	}

	/**
	 * Verifica se a categoria seleciona possui detalhes
	 */
	function checkSelectedCategoryHasDetails(): boolean {
		const categoryCode = props.formStateManager.getFieldValue(props.categoryFieldName)
		if (!categoryCode) return false

		const category = categories.find((category) => category.code === categoryCode)
		return !!category?.details
	}

	return (
		<RowCP>
			<ColumnCP size={12}>
				<SelectCP
					label={'Categoria'}
					formStateManager={props.formStateManager}
					fieldName={props.categoryFieldName}
					options={categories.map((category) => ({
						value: category.code,
						label: <TagCP content={category.name} color={category.color} />,
					}))}
					onChange={onSelectCategory}
					allowClear={true}
				/>
			</ColumnCP>
			{checkSelectedCategoryHasDetails() && (
				<ColumnCP size={12}>
					<InputCP
						label={'Nº máximo de exames'}
						type={'number'}
						required={true}
						value={props.formStateManager.getFieldValue(props.categoryDataFieldName)?.examsQuantity}
						onChange={(val: number) => {
							let details: ICategoryDetails = props.formStateManager.getFieldValue(props.categoryDataFieldName)
							if (!details) details = { examsQuantity: val }
							else details.examsQuantity = val

							props.formStateManager.changeFieldValue(props.categoryDataFieldName, details)
						}}
					/>
				</ColumnCP>
			)}
		</RowCP>
	)
}
