import React, { useEffect, useState } from 'react'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { FormUserProfileDataICP } from 'modules/person/components/user/modal-user-data/inner/FormUserProfileDataICP'
import { UserProfileFormModel } from 'modules/person/components/user/modal-user-data/inner/UserProfileFormModel'
import { FormAddressCP } from 'submodules/nerit-framework-ui/features/address/components/form-address/FormAddressCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'
import { ProfileUserUpdateRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/ProfileUserUpdateRequestDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'

interface ICPProps {
	visible: boolean
	onClose: (dataChanged?: boolean) => void
	person: PersonResponseDTO
}

/**
 * Modal com o log de uma operacao em massa.
 */
export function ModalUserProfileDataCP(props: ICPProps): JSX.Element {
	const [addressFormValidator, setAddressFormValidator] = useState<AddressFormModel>(new AddressFormModel())
	const addressFormStateManager = useFormStateManager<AddressFormModel>(addressFormValidator)

	const [formValidator, setFormValidator] = useState<UserProfileFormModel>(new UserProfileFormModel())
	const formStateManager = useFormStateManager<UserProfileFormModel>(formValidator)

	const savePersonRequest = useRequest<void>('none')
	useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa.
	 */
	function init(): void {
		if (!props.visible || !props.person) return

		setFormValidator(
			new UserProfileFormModel({
				name: props.person.name,
				cpf: props.person.cpf,
				email: props.person.email,
				phone: props.person.phone,
				birthDate: !!props.person.birthDate ? DateUtils.formatDate(props.person.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
			}),
		)

		setAddressFormValidator(AddressFormUtils.formatFormModel(props.person.address))
	}

	/**
	 * Salva pessoa
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!

		const dto: ProfileUserUpdateRequestDTO = {
			name: formValues.name,
			cpf: formValues.cpf,
			email: formValues.email,
			phone: formValues.phone,
			birthDate: !!formValues.birthDate ? DateUtils.toDate(formValues.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
			address: AddressFormUtils.formatDto(addressFormStateManager.getFormValues()),
		}
		savePersonRequest.runRequest(PeopleRequests.updateUserProfile(props.person.code, dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSavePersonRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				savePersonRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		formStateManager.reset(undefined)
		props.onClose(true)
	}

	return (
		<ModalCP
			title={'Editar Perfil'}
			visible={props.visible}
			onCancel={props.onClose}
			onOk={save}
			actionLoading={savePersonRequest.isAwaiting}
			top={10}
			width={700}
		>
			<FormUserProfileDataICP formStateManager={formStateManager} />
			<FormAddressCP formStateManager={addressFormStateManager} />
		</ModalCP>
	)
}
