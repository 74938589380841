import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { MedicalReportHistorySearchRequestDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/request/MedicalReportHistorySearchRequestDTO'
import { MedicalReportHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/MedicalReportHistoryResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class MedicalReportHistoryRequests {
	public static CONTROLLER_ROOT = 'medical-report-history'
	public static SEARCH_EP = 'search'

	static search = (dto: MedicalReportHistorySearchRequestDTO): RequestConfigTP<ListResponseDTO<MedicalReportHistoryResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalReportHistoryRequests.CONTROLLER_ROOT}/${MedicalReportHistoryRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
