import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonMultiActionCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonMultiActionCP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { UploadUrlTP } from 'modules/exams/components/exams/drawer-exam-pending/inner/FormExamPendingAnamnesisDataCP'

interface ICPProps {
	examCode: number
	examId: string
	uploadUrl: UploadUrlTP
	onSave: (type: 'save' | 'saveAndNew' | 'saveAndNewKeepData') => void
}

/**
 */
export function StepExamImagesCreateExamCP(props: ICPProps): JSX.Element {
	return (
		<>
			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Imagens do Exame
			</TitleCP>
			<UploadExamAttachmentsCP
				examId={props.examId}
				showQrCode={true}
				type={ExamUploadFileTypeEnum.EXAM_IMAGE}
				maxMbSize={30}
				temporaryToken={props.uploadUrl}
			/>

			<TitleCP textSize={'normal'} underLine={true} marginTop={30}>
				Pedido Médico e Anexos
			</TitleCP>
			<UploadExamAttachmentsCP examId={props.examId} showQrCode={true} type={ExamUploadFileTypeEnum.ATTACHMENT} temporaryToken={props.uploadUrl} />

			<FlexCP margin={{ top: 30 }} justifyContent={'flex-end'}>
				<ButtonMultiActionCP
					options={[
						{
							label: 'Salvar e criar novo Exame',
							onClick: () => props.onSave('saveAndNew'),
						},
						{
							label: 'Salvar e criar novo Exame (preservando dados)',
							onClick: () => props.onSave('saveAndNewKeepData'),
						},
					]}
				/>
				<ButtonCP marginLeft={10} type={'primary'} onClick={() => props.onSave('save')}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
