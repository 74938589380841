import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { ProjectSearchRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/ProjectSearchRequestDTO'
import { AddUserToProjectSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/AddUserToProjectSaveRequestDTO'
import { ProjectSaveRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/ProjectSaveRequestDTO'

export class ProjectsRequests {
	public static CONTROLLER_ROOT = 'projects'
	public static SEARCH_EP = 'search'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static GET_EP = 'get/:code'
	public static SEARCH_USERS_EP = 'users/:code'
	public static ADD_USER_EP = 'add-user'
	public static REMOVE_USER_EP = 'remove-user/:code'

	static get = (code: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.GET_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static search = (dto: ProjectSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static create = (dto: ProjectSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: ProjectSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static searchUsers = (projectCode: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.SEARCH_USERS_EP.replace(':code', projectCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static addUser = (dto: AddUserToProjectSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.ADD_USER_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static removeUser = (projectUserCode: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${ProjectsRequests.CONTROLLER_ROOT}/${ProjectsRequests.REMOVE_USER_EP.replace(':code', projectUserCode.toString())}`,
		method: HttpMethodEnum.DELETE,
	})
}
