import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import React from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamContrastLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamContrastEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { PopoverExamImageDetailsCP } from 'modules/exams/components/exams/popover-exam-image-details/PopoverExamImageDetailsCP'
import { ButtonDuplicateExamCP } from 'modules/exams/components/exams/button-duplicate-exam/ButtonDuplicateExamCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonToggleExamDigitalCertificateCP } from '../button-toggle-exam-digital-certificate/ButtonToggleExamDigitalCertificateCP'

interface IExamDataCPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	duplicateExamOption?: {
		onDuplicateExam: (createdExamIds: string[]) => void
	}
}

/**
 * Componente para exibir dados do exame na aba de exames do drawer.
 */
export function ExamDataCP(props: IExamDataCPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<FlexCP justifyContent={'space-between'}>
				<TitleCP textSize={'normal'} underLine={true}>
					Dados do exame
				</TitleCP>

				<ButtonGroupCP mode={'separeted'}>
					<PopoverExamImageDetailsCP exam={props.exam} />
					{!!props.duplicateExamOption && <ButtonDuplicateExamCP exam={props.exam} onDuplicate={props.duplicateExamOption.onDuplicateExam} />}

					<ButtonToggleExamDigitalCertificateCP
						examStatus={props.exam.status}
						examCode={props.exam.code}
						doctorGroupCode={props.exam.doctorGroup?.code}
						isDigitalCertificateRequired={props.exam.digitalCertification?.isDigitalCertificateRequired ?? false}
					/>
				</ButtonGroupCP>
			</FlexCP>

			<TextAndTitleCP title={'Cód. Exame:'} text={props.exam.code.toString()} />
			<TextAndTitleCP title={'Status:'} text={ExamStatusLabelEnum[props.exam.status]} />
			<TextAndTitleCP title={'Unidade Hospitalar:'} text={props.exam.clinic.name} />
			<TextAndTitleCP title={'Exame:'} text={`${ExamModalityLabelEnum[props.exam.modality]} de ${props.exam.description}`} />
			<TextAndTitleCP title={'Total de Imagens:'} text={`${props.exam.imageData.totalImages ?? '-'}`} />
			<TextAndTitleCP title={'Data de realização:'} text={DateUtils.formatDate(props.exam.examDate, DateFormatEnum.BR_WITH_TIME_H_M)} />
			<TextAndTitleCP
				title={'Entrada para fila:'}
				text={props.exam.pedingEndDate ? DateUtils.formatDate(props.exam.pedingEndDate, DateFormatEnum.BR_WITH_TIME_H_M) : undefined}
			/>
			<TextAndTitleCP title={'Contraste:'} text={props.exam.contrasts?.map((contrast) => ExamContrastLabelEnum[contrast]).join(', ') ?? '-'} />
			<TextAndTitleCP title={'Grupo de Médicos:'} text={props.exam.doctorGroup?.name ?? '-'} />
			<TextAndTitleCP title={'Número do Pedido:'} text={props.exam.extraData?.serviceOrder ?? props.exam.extraData?.accessionNumber ?? '-'} />

			<TextAndTitleCP title={'Anamnese:'} text={!props.exam.extraData?.anamnesis ? '-' : ''} />
			{!!props.exam.extraData?.anamnesis && <TextCP text={props.exam.extraData.anamnesis} />}
		</BasicStyleWrapperCP>
	)
}
