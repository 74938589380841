import React from 'react'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { ColumnDatesICP } from 'modules/exams/components/exams/table-exams/columns/ColumnDatesICP'
import { TagExamStatusCP } from 'modules/exams/components/exams/tag-exam-status/TagExamStatusCP'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { ButtonPopoverExamUrgencyCP } from 'modules/exams/components/exams/button-popover-exam-urgency/ButtonPopoverExamUrgencyCP'
import { TagClinicCategoryCP } from 'modules/clinic/components/tag-clinic-category/TagClinicCategoryCP'
import { IconCriticalExamCP } from 'modules/exams/components/exams/icons/IconCriticalExamCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { IconByUrgencyTypeCP } from 'modules/exams/components/exams/icons/IconByUrgencyTypeCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { BlinkCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BlinkCP'
import { DoctorPermissionUtils } from 'modules/auth/permissions/DoctorPermissionUtils'
import { RoutingHelper } from 'config/RoutingHelper'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ColumnExamActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/ColumnExamActionsICP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ColumnSlaICP } from 'modules/exams/components/exams/table-exams/columns/ColumnSlaICP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { ColumnDoctorAvatarICP } from 'modules/exams/components/exams/table-exams/columns/ColumnDoctorAvatarICP'

export const TableExamsUtils = {
	getColumns(
		onSave: () => void,
		examStatusGroup?: ExamStatusGroupEnum,
		filterFormStateManager?: IFormStateManager<ExamFilterFormModel>,
	): Array<ITableColumn<ExamListItemResponseDTO>> {
		const viewAsDoctor: boolean = filterFormStateManager?.getFieldValue('viewAsDoctor') ?? false
		let searchDto: ExamSearchRequestDTO | undefined
		if (!!filterFormStateManager) searchDto = ExamFiltersFormModelConverter.formatDto(filterFormStateManager?.getFormValues())

		return [
			{
				key: ExamSearchOrderingEnum.URGENCY,
				sorter: true,
				render: (text, record) => (
					<FlexCP alignItems={'center'}>
						{record.isCritical && (
							<BlinkCP>
								<RoundedWrapperCP color={ThemeProjectVX.warning} text={<IconCriticalExamCP hideIcon={!record.isCritical} />} size={26} />
							</BlinkCP>
						)}
						{viewAsDoctor ? (
							<IconByUrgencyTypeCP urgencyType={record.urgentType} size={16} />
						) : (
							<ButtonPopoverExamUrgencyCP
								exam={{
									code: record.code,
									urgencyType: record.urgentType,
									status: record.status,
									clinicCode: record.clinic.code,
									doctorGroupCode: record.doctorGroupCode,
								}}
								appearance={{ size: 'small' }}
								onChangeUrgency={onSave}
							/>
						)}
					</FlexCP>
				),
				hide: examStatusGroup === ExamStatusGroupEnum.PENDING,
			},
			{
				key: ExamSearchOrderingEnum.SLA,
				sorter: true,
				width: 100,
				render: (text, record) => <ColumnSlaICP exam={record} />,
				hide: examStatusGroup === ExamStatusGroupEnum.PENDING,
			},
			{
				key: ExamSearchOrderingEnum.DATE,
				sorter: true,
				title: 'Data Exame',
				render: (text, record) => <ColumnDatesICP exam={record} dateType={'examDate'} />,
			},
			{
				key: ExamSearchOrderingEnum.PATIENT,
				sorter: true,
				title: 'Paciente',
				render: (text, record) => (
					<TextCP
						text={record.patient.name}
						onClick={
							viewAsDoctor &&
							DoctorPermissionUtils.canDoMedicalReport(
								record.status,
								record.doctorGroupCode,
								record.doctor?.code,
								record.revisionDoctor?.code,
								record.doctorsAssigned?.map((doctor) => doctor.code),
							)
								? () => RoutingHelper.goToMedicalReport(record.id, false, undefined, searchDto)
								: undefined
						}
						size={'small'}
					/>
				),
			},
			{
				key: ExamSearchOrderingEnum.EXAM,
				sorter: true,
				title: 'Exame',
				render: (text, record) => (
					<AvatarExamModalityCP
						onClick={
							viewAsDoctor &&
							DoctorPermissionUtils.canDoMedicalReport(
								record.status,
								record.doctorGroupCode,
								record.doctor?.code,
								record.revisionDoctor?.code,
								record.doctorsAssigned?.map((doctor) => doctor.code),
							)
								? () => RoutingHelper.goToMedicalReport(record.id, false, undefined, searchDto)
								: undefined
						}
						modality={record.modality}
						description={record.description}
						size={28}
						fontSize={'small'}
					/>
				),
			},
			{
				key: 'imgs',
				title: 'Imgs',
				align: 'center',
				render: (text, record) =>
					ExamStatusUtils.examStatusGroupOfExamStatusMap.get(record.status) === ExamStatusGroupEnum.PENDING ? (
						<TooltipCP
							text={'Verificar na categorização ou nos detalhes do exame, lembre-se de atualizar o total de imagens.'}
							showSpan={true}
						>
							<IconICP iconName={'cloud-upload'} size={14} />
						</TooltipCP>
					) : (
						record.imageData?.totalImages ?? '-'
					),
				hide: examStatusGroup === ExamStatusGroupEnum.PENDING,
			},
			{
				key: ExamSearchOrderingEnum.CLINIC,
				sorter: true,
				title: 'Unidade Hospitalar',
				render: (text, record) => (
					<>
						<FlexCP alignItems={'center'}>
							{record.digitalCertification?.isDigitalCertificateRequired && (
								<TooltipCP showSpan={true} text={'Essa clínica exige assinatura com certificado digital no laudo'}>
									<IconICP
										iconName={ThemeProjectCommon.icons.digitalCertificate}
										color={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
										size={16}
										style={{ marginRight: 5 }}
									/>
								</TooltipCP>
							)}
							<span>{record.clinic.name}</span>
						</FlexCP>
						{!!record.doctorGroupCode &&
							DoctorGroupPermissionUtils.hasAnyPermissionsInDoctorGroup(record.doctorGroupCode, [
								'isDoctorGroupAdmin',
								'isDoctorGroupUser',
								'isDoctorAdmin',
							]) && <TagClinicCategoryCP category={record.clinic.category} small={true} />}
					</>
				),
			},
			{
				title: 'Solicitante',
				render: (text, record) => record.extraData?.requestingDoctor,
			},
			{
				key: ExamSearchOrderingEnum.STATUS,
				sorter: true,
				title: 'Status',
				render: (text, record) => <TagExamStatusCP examStatus={record.status} />,
			},
			{
				render: (text, record) =>
					ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE)?.includes(record.status) &&
					!!record.doctor &&
					!!record.medicalReportDate && (
						<>
							<TextCP text={`Liberado por ${StringUtils.getFirstName(record.doctor.name)}`} />
							<TextCP
								fontStyle={'italic'}
								text={`em ${DateUtils.formatDate(record.medicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M)}`}
								wrap={false}
							/>
						</>
					),
				hide: !!examStatusGroup && examStatusGroup !== ExamStatusGroupEnum.DONE,
			},
			{
				key: 'doctor',
				hide: examStatusGroup === ExamStatusGroupEnum.PENDING,
				render: (text, record) => <ColumnDoctorAvatarICP record={record} />,
			},
			{
				key: 'actions',
				render: (text, record) => (
					<ColumnExamActionsICP
						onSave={onSave}
						examStatusGroup={ExamStatusUtils.examStatusGroupOfExamStatusMap.get(record.status)!}
						exam={record}
						viewAsDoctor={viewAsDoctor}
						searchExamDto={searchDto}
					/>
				),
			},
		]
	},
}
