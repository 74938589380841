import React from 'react'
import styled from 'styled-components'
import { DoctorIdTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorIdTypeEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { DoctorTreatmentEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorTreatmentEnum'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'

interface ICPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP, DoctorIdTypeEnum> {
	allowClear?: boolean
}

/**
 * Componente para select de Origem do Pagamento.
 */
export function SelectDoctorTreatmentCP(props: ICPProps): JSX.Element {
	const OPTIONS: SelectOptionTP[] = Object.values(DoctorTreatmentEnum).map((treatment, i) => ({
		key: `key-${i}`,
		label: treatment,
		value: treatment,
	}))

	return (
		<SelectWrapperSCP>
			<SelectCP
				placeholder={props.placeholder}
				label={'Tratamento'}
				value={props.value}
				disabled={props.disabled}
				required={props.required}
				formStateManager={props.formStateManager}
				selectedOptions={props.value ? [props.value] : undefined}
				fieldName={props.fieldName}
				fontSize={props.fontSize}
				isMultiple={false}
				allowClear={props.allowClear}
				options={OPTIONS}
				defaultOpen={false}
				onChange={props.onChange}
				width={props.width}
			/>
		</SelectWrapperSCP>
	)
}

const SelectWrapperSCP = styled.div`
	width: 100%;
`
