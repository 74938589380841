import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { AuditListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/AuditListItemResponseDTO'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'
import { StartAuditResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/StartAuditResponseDTO'
import { AuditFinalizeRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditFinalizeRequestDTO'
import { AuditSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSaveRequestDTO'
import { AuditReassignRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditReassignRequestDTO'

export class AuditsRequests {
	public static CONTROLLER_ROOT = 'audits'
	public static SEARCH_EP = 'search'
	public static SEARCH_EXPORT_EP = `${AuditsRequests.SEARCH_EP}/export`
	public static START_EP = 'start/:code'
	public static FINALIZE_EP = 'finalize'
	public static CREATE_EP = 'create'
	public static DELETE_EP = 'delete'
	public static REASSIGN_EP = 'reassign'

	static create = (dto: AuditSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static reassign = (dto: AuditReassignRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.REASSIGN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (codes: number[]): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.DELETE_EP}`,
		method: HttpMethodEnum.DELETE,
		params: codes,
	})

	static search = (dto: AuditSearchRequestDTO): RequestConfigTP<ListResponseDTO<AuditListItemResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static start = (auditCode: number): RequestConfigTP<StartAuditResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.START_EP.replace(':code', auditCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static finalize = (dto: AuditFinalizeRequestDTO): RequestConfigTP<AuditFinalizeRequestDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.FINALIZE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static export = (dto: AuditSearchRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuditsRequests.CONTROLLER_ROOT}/${AuditsRequests.SEARCH_EXPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})
}
