import { TitleExamCP } from 'modules/exams/components/exams/title-exam/TitleExamCP'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamActionButtonsICP } from 'modules/exams/components/exams/drawer-exam-details/inner/ExamActionButtonsICP'
import { ExamsDrawerTabsTP, TabsExamDetailsCP } from 'modules/exams/components/exams/tabs-exam-details/TabsExamDetailsCP'

interface ICPProps {
	onClose: (dataChanged?: boolean) => void
	visible: boolean
	examId: string
	selectedTab?: ExamsDrawerTabsTP
}

/**
 * Drawer para exibir em abas de detalhes de um exame.
 */
export function DrawerExamDetailsCP(props: ICPProps): JSX.Element | null {
	const [dataChanged, setDataChanged] = useState<boolean>(false)
	const [loadedExam, setLoadedExam] = useState<ExamResponseDTO>()
	const getExamRequest = useRequest<ExamResponseDTO>()
	useEffect(onGetExamRequestChange, [getExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setLoadedExam(undefined)
		if (!props.visible) return

		getExamRequest.runRequest(ExamsRequests.getExam(props.examId))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetExamRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getExamRequest.responseData!
		setLoadedExam(result)
	}

	if (!props.visible) return <></>

	if (!loadedExam) return <LoadingOverlayCP show={true} />

	return (
		<DrawerCP
			width={800}
			onClose={() => props.onClose(dataChanged)}
			visible={props.visible}
			loading={getExamRequest.isAwaiting}
			title={
				<TitleExamCP
					modality={loadedExam.modality}
					patientName={loadedExam.patient.name}
					examTitle={loadedExam.description}
					examStatus={loadedExam.status}
					extraData={loadedExam.extraData}
				/>
			}
		>
			<ExamActionButtonsICP
				exam={loadedExam}
				onChangeExam={() => getExamRequest.runRequest(ExamsRequests.getExam(props.examId))}
				onChangeData={() => props.onClose(true)}
			/>

			<TabsExamDetailsCP
				exam={loadedExam}
				onChangeData={(action) => {
					if (action === 'closeDrawer') props.onClose(true)
					else {
						setDataChanged(true)
						init()
					}
				}}
				selectedTab={props.selectedTab}
				onRegenerateMedicalReportPdf={() => {
					getExamRequest.runRequest(ExamsRequests.getExam(props.examId))
				}}
			/>
		</DrawerCP>
	)
}
