import React, { useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { SelectCascaderClinicDoctorGroupsCP } from 'modules/clinic/components/select-cascader-clinic-doctorgroups/SelectCascaderClinicDoctorGroupsCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import styled from 'styled-components'
import { WizardCreateExamCP } from 'modules/exams/components/exams/wizard-create-exam/WizardCreateExamCP'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { HospitalIconCP } from '_old/main/common/components/icons/HospitalIconCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

interface ICPProps {
	onClose: (dataChanged?: boolean) => void
	visible: boolean
}

/**
 * Drawer para criar um exame
 */
export function DrawerCreateExamCP(props: ICPProps): JSX.Element {
	const [hasCreated, setHasCreated] = useState<boolean>(false)

	const [clinicCode, setClinicCode] = useState<number>()
	const [clinicType, setClinicType] = useState<ClinicTypeEnum>()
	const [doctorGroup, setDoctorGroup] = useState<DoctorGroupNamesFromClinicResponseDTO>()

	/**
	 */
	function onSave(saveType: 'save' | 'saveAndNew' | 'saveAndNewKeepData'): void {
		if (saveType === 'save') {
			setClinicCode(undefined)
			setDoctorGroup(undefined)
			setClinicType(undefined)
			props.onClose(true)
			return
		}

		setHasCreated(true)
	}

	return (
		<DrawerCP width={900} onClose={() => props.onClose(hasCreated)} visible={props.visible} title={'Criar Exame'} footerSpaced={true}>
			<WrapperSCP>
				<FlexCP justifyContent={'center'} margin={{ bottom: 30 }}>
					<SelectCascaderClinicDoctorGroupsCP
						onChange={(selectedClinicCode, selectClinicType, selectedDoctorGroup) => {
							setClinicCode(selectedClinicCode)
							setClinicType(selectClinicType)
							setDoctorGroup(selectedDoctorGroup)
						}}
						disable={!!clinicCode && !!doctorGroup && !!clinicType}
					/>
				</FlexCP>

				{!clinicCode || !clinicType || !doctorGroup ? (
					<BasicStyleWrapperCP margin={{ top: 100 }}>
						<WelcomeContentCP icon={<HospitalIconCP size={40} />} message={'Primeiro selecione o Grupo e Clínica do Exame'} />
					</BasicStyleWrapperCP>
				) : (
					<WizardCreateExamCP clinicCode={clinicCode} clinicType={clinicType} doctorGroup={doctorGroup} onSave={onSave} />
				)}
			</WrapperSCP>
		</DrawerCP>
	)
}

const WrapperSCP = styled.div`
	margin-top: -10px;
`
