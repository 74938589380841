import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { TabsMedicalReportEditorCP } from 'modules/exams/components/medical-report/tabs-medical-report-editor/TabsMedicalReportEditorCP'
import styled from 'styled-components'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { EditorOitMedicalReportCP } from 'modules/exams/components/medical-report/editor-oit-medical-report/EditorOitMedicalReportCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { AlertsMedicalReportCP } from 'modules/exams/components/medical-report/alerts-medical-report/AlertsMedicalReportCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ScreenContentMedicalReportCP(props: ICPProps): JSX.Element | null {
	return (
		<FlexCP justifyContent={'center'}>
			<ContentWrapperSCP isOit={props.exam.modality === ExamModalityEnum.CR_OIT}>
				<AlertsMedicalReportCP exam={props.exam} searchExamDto={props.searchExamDto} />

				{props.exam.modality === ExamModalityEnum.CR_OIT ? (
					<EditorOitMedicalReportCP formStateManager={props.formStateManager} />
				) : (
					<TabsMedicalReportEditorCP exam={props.exam} formStateManager={props.formStateManager} />
				)}
			</ContentWrapperSCP>
		</FlexCP>
	)
}

const ContentWrapperSCP = styled.div<{ isOit: boolean }>`
	margin: 20px 0;
	max-width: ${(props) => (props.isOit ? undefined : '830px')};
`
