import React from 'react'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { TabEditMedicalReportConfigHtmlICP } from 'modules/clinic/components/tabs-edit-medical-report-config/tabs/TabEditMedicalReportConfigHtmlICP'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { TabEditMedicalReportConfigStylesICP } from 'modules/clinic/components/tabs-edit-medical-report-config/tabs/TabEditMedicalReportConfigStylesICP'

interface ICPProps {
	medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO
	shouldHighlightContent: boolean
	onChange: (medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO) => void
}

export function TabsEditMedicalReportConfigCP(props: ICPProps): JSX.Element {
	return (
		<TabsCP
			tabs={[
				{
					title: 'Blocos',
					key: 'blocks',
					content: <TabEditMedicalReportConfigHtmlICP medicalReportHtmls={props.medicalReportHtmls} onChange={props.onChange} />,
				},
				{
					title: 'Estilos',
					key: 'styles',
					content: <TabEditMedicalReportConfigStylesICP medicalReportHtmls={props.medicalReportHtmls} onChange={props.onChange} />,
				},
			]}
		/>
	)
}
