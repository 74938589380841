import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class DoctorGroupDigitalCertificateDataFormModel extends FormModel {
	@IsRequired()
	partner: string

	@IsRequired()
	clientId: string

	@IsRequired()
	clientSecret: string

	@IsRequired()
	endpoint: string

	constructor(initialData?: ObjectPropsTP<DoctorGroupDigitalCertificateDataFormModel>) {
		super()
		Object.assign(this, initialData)
	}
}
