import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { RISPartnersEnum } from 'submodules/beerads-sdk/services/integrations/enums/RISPartnersEnum'

interface ICPProps extends BasicStyleWrapperCPProps {
	examCode: number
	label?: string
	risIntegrationPartner: RISPartnersEnum
}

/**
 */
export function ButtonIntegrateMedicalReportXmlCP(props: ICPProps): JSX.Element {
	const integrateMedicalReportRequest = useRequest<string>()
	useEffect(onIntegrateMedicalReportRequestChange, [integrateMedicalReportRequest.isAwaiting])

	/**
	 */
	function onIntegrateMedicalReportRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				integrateMedicalReportRequest,
				'Erro ao chamar integração',
				'Laudo enviado para sistema parceiro com sucesso.',
				true,
			)
		)
			return

		window.open(integrateMedicalReportRequest.responseData!, '_blank')
	}

	if (props.risIntegrationPartner !== RISPartnersEnum.FUJI) return <></>

	return (
		<ButtonCP
			size={'small'}
			loading={integrateMedicalReportRequest.isAwaiting}
			onClick={() => integrateMedicalReportRequest.runRequest(MedicalReportRequests.generateIntegrationMedicalReportsXml(props.examCode))}
			marginTop={props.margin?.top}
			marginBottom={props.margin?.bottom}
			marginLeft={props.margin?.left}
			marginRight={props.margin?.right}
		>
			{props.label ?? 'XML de Integração'}
		</ButtonCP>
	)
}
