import React from 'react'
import { TableClinicsUtils } from 'modules/admin-beerads/components/table-clinics-admin/inner/TableClinicsUtils'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'

interface ICPProps {
	reloadData: number
	onEdit: (item: ClinicResponseDTO) => void
	onUploadAction: (item: ClinicResponseDTO) => void
}

/**
 * Tabela de listagem das clinicas
 */
export function TableClinicAdminCP(props: ICPProps): JSX.Element {
	return (
		<TableFromApiCP<ClinicResponseDTO>
			appearance={{
				showReloadButton: true,
				wrappedOnCard: true,
			}}
			shouldLoadData={props.reloadData}
			apiConfig={{
				hasPagination: false,
				requestConfigTP: ClinicsRequests.search,
			}}
			row={{
				setClass: (record) => (!record.active ? 'row-nerit-error' : ''),
			}}
			columns={TableClinicsUtils.getColumns(props.onEdit, props.onUploadAction)}
		/>
	)
}
