import { IsOptional } from 'class-validator/decorator/decorators'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsString } from 'submodules/nerit-framework-utils/validators'

export class AddressFormModel extends FormModel {
	@IsOptional()
	@IsString()
	zipCode: string

	@IsOptional()
	@IsString()
	street: string

	@IsOptional()
	number: string

	@IsOptional()
	@IsString()
	complement: string

	@IsOptional()
	@IsString()
	neighborhood: string

	@IsOptional()
	@IsString()
	city: string

	@IsOptional()
	@IsString()
	state: string

	constructor(initialData?: Partial<AddressFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
