import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { CategoryResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/categories/responses/CategoryResponseDTO'

interface ICPProps {
	category?: CategoryResponseDTO
	small?: boolean
}

/**
 */
export function TagClinicCategoryCP(props: ICPProps): JSX.Element {
	if (!props.category || !props.category.code) return <></>

	return <TagCP color={props.category.color} content={props.category.name} small={props.small} />
}
