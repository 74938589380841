import React, { useState } from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AuthActions } from 'app/utils/AuthActions'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * Dados do usuario logado
 *
 * Acessar o dev-test passando ?loggedUser = true
 */
export function ScreenContentDevTestLoggedUser(): JSX.Element {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	return (
		<>
			<LoadingOverlayCP show={isLoading} />
			<BasicStyleWrapperCP margin={{ top: 20, left: 20, bottom: 20 }}>
				<ButtonCP
					onClick={() => {
						setIsLoading(true)
						AuthActions.refreshLoggedUserData().then(() => setIsLoading(false))
					}}
				>
					Atualizar dados usuário logado
				</ButtonCP>
			</BasicStyleWrapperCP>

			<WrapperSCP>
				<PreviewAreaSCP>
					<OutputPreviewSCP disabled value={JSON.stringify(AppStateUtils.getAuthData() ?? {}, null, 4)} height={'98vh'} />
				</PreviewAreaSCP>

				<PreviewAreaSCP>
					<OutputPreviewSCP disabled value={JSON.stringify(AppStateUtils.getLoggedUserClinicPermissions() ?? {}, null, 4)} />
				</PreviewAreaSCP>
			</WrapperSCP>
		</>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundDark}80;
	border: 1px solid #b7b7b7;
	padding-top: 9px;
	box-shadow: 0 0 13px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}33;
`

const PreviewAreaSCP = styled.div`
	display: flex;
	justify-content: center;
	width: 50%;
`

const OutputPreviewSCP = styled.textarea<{ height?: string }>`
	min-height: ${(props) => props.height ?? '300px'};
	margin-bottom: 10px;
	width: 97%;
`
