import { Select } from 'antd'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import React from 'react'
import styled from 'styled-components'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {}

/**
 */
export function SelectSimpleAutocompleteCP<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {
	return (
		<SelectWrapperSCP>
			<FormItemICP<FModelTP, IFormItemCommonProps>
				label={props.label}
				fieldName={props.fieldName}
				formStateManager={props.formStateManager}
				required={props.required}
				width={props.width}
				errorMessage={props.errorMessage}
				noValidation={props.noValidation}
				placeholder={props.placeholder}
				onBlur={props.onBlur}
				hint={props.hint}
			>
				<Select mode={'tags'} />
			</FormItemICP>
		</SelectWrapperSCP>
	)
}

const SelectWrapperSCP = styled.div`
	width: 100%;
`
