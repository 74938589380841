import React from 'react'
import styled from 'styled-components'
import {
	NpsResponderTypeEnum,
	NpsResponderTypePluralLabelEnum,
} from 'submodules/space4leads-sdk/services/surveys/survey-answers/enums/NpsResponderTypeEnum'
import { IconNpsResponderCP } from 'submodules/space4leads-components-ui/survey/components/icon-nps-responder/IconNpsResponderCP'
import { NpsUtils } from 'submodules/space4leads-components-ui/survey/utils/NpsUtils'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface INpsTotalResponderByTypeCPProps {
	type: NpsResponderTypeEnum
	value: number
	total: number
}

/**
 * TELA de listagem de Disparos
 */
export function NpsTotalResponderByTypeCP(props: INpsTotalResponderByTypeCPProps): JSX.Element {
	const calculatedWidth: number = (props.value / props.total) * 100
	const color: string = NpsUtils.getColorByReponderType(props.type)

	return (
		<AnswerSCP calculatedWidth={calculatedWidth} bgColor={color}>
			<TextCP text={MaskUtils.applyNumberMask(props.value, 0)} size={'extraLarge'} />
			<FlexCP>
				<IconNpsResponderCP type={props.type} />
				<TextCP text={NpsResponderTypePluralLabelEnum[props.type]} marginLeft={5} />
			</FlexCP>
		</AnswerSCP>
	)
}

const AnswerSCP = styled.div<{ calculatedWidth: number; bgColor: string }>`
	min-width: 100px;
	width: ${(props) => props.calculatedWidth}%;
	padding: 10px;
	margin: 10px 5px;
	background-color: ${ThemeProject.gray};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100px;
	background-color: ${(props) => props.bgColor};
`
