import React, { useEffect, useState } from 'react'
import { ContentBlock, ContentState, SelectionState } from 'draft-js'
import styled from 'styled-components'
import * as _ from 'lodash'

import { TPluginTableCellICP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/components/table-component/inner/TPluginTableCellICP'
import { TablePluginConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginConfig'
import {
	OnCellBlurCallbackTP,
	PluginTableRowTP,
	PluginTableCellTP,
	PluginTableDataTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginTypes'

export interface ITablePluginBlockProps {
	tableData: PluginTableDataTP
	setReadOnly: (readOnly: boolean) => void
	onCellBlur: OnCellBlurCallbackTP
	customStyles?: React.CSSProperties
}

/**
 * @todo: Entender restante das propriedades
 */
interface ITPluginTableCPProps {
	blockProps: ITablePluginBlockProps
	block: ContentBlock
	contentState: ContentState
	selection: SelectionState

	direction: 'LTR' | 'RTL'
	blockStyleFn: Function
	customStylesFn: Function
	customStylesMap: { [styleName: string]: React.CSSProperties }
	forceSelection: boolean
	decorator: any
	offsetKey: string
	tree: any
}

/**
 * COMPONENTE
 * Reenderiza 01 tabela parametrizavel dentro do editor wysiwyg (plugin Table).
 *
 * @author hjcostabr
 */
export const TPluginTableCP = (props: ITPluginTableCPProps): JSX.Element | null => {
	const [cellWidth, setCellWidth] = useState<number>()
	const [tableWidth, setTableWidth] = useState<number>()

	useEffect(setTableDimensions, [])

	function setTableDimensions(): void {
		const cellsPerRow = _.get(props, 'blockProps.tableData.rows[0].cells.length', 1)

		let _tableWidth: number

		if (cellsPerRow > 0.67 * TablePluginConfig.MAX_COLUMNS) _tableWidth = TablePluginConfig.TABLE_MAX_WIDTH
		else if (cellsPerRow > 0.33 * TablePluginConfig.MAX_COLUMNS) {
			const widthRange = TablePluginConfig.TABLE_MAX_WIDTH - TablePluginConfig.TABLE_MIN_WIDTH
			_tableWidth = Math.ceil(TablePluginConfig.TABLE_MIN_WIDTH + 0.5 * widthRange)
		} else _tableWidth = TablePluginConfig.TABLE_MIN_WIDTH

		const cellWidthNumber = _tableWidth / cellsPerRow
		const cellWidthPercentage = 100 * (cellWidthNumber / _tableWidth)

		setCellWidth(cellWidthPercentage)
		setTableWidth(_tableWidth)
	}

	if (!cellWidth || !tableWidth) return null

	const blockKey = props.block.getKey()
	const tableRows: PluginTableRowTP[] = _.get(props, 'blockProps.tableData.rows', [])

	return (
		<TableSCP width={tableWidth}>
			<tbody>
				{tableRows.map((row: PluginTableRowTP, rowNumber: number) => {
					const cells: PluginTableCellTP[] = _.get(row, 'cells', [])

					return (
						<tr key={`${blockKey}_${rowNumber}`}>
							{cells.map((cell: PluginTableCellTP, cellNumber: number) => {
								return (
									<TPluginTableCellICP
										key={`${blockKey}_${rowNumber}_${cellNumber}`}
										blockKey={blockKey}
										rowNumber={rowNumber}
										cellNumber={cellNumber}
										text={cell.text}
										width={cellWidth}
										contentState={props.contentState}
										setReadOnly={props.blockProps.setReadOnly}
										onCellEditEnd={props.blockProps.onCellBlur}
										customStyles={props.blockProps.customStyles}
									/>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		</TableSCP>
	)
}

const TableSCP = styled.table<{ width: number }>`
	margin: 0 auto;
`
