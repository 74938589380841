import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { TeamSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/requests/TeamSearchRequestDTO'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'

export class TeamsRequests {
	public static CONTROLLER_ROOT = `${DoctorGroupsRequests.CONTROLLER_ROOT}/teams`
	public static SEARCH_EP = 'search'
	public static GET_EP = 'get/:code'
	public static NAMES_EP = 'names'
	public static SAVE_EP = 'save'
	public static UPDATE_EP = 'update/:code'
	public static DELETE_EP = 'delete/:code'

	static getOne = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${TeamsRequests.GET_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static search = (dto: TeamSearchRequestDTO): RequestConfigTP<TeamResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TeamsRequests.CONTROLLER_ROOT}/${TeamsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static names = (dto: TeamSearchRequestDTO): RequestConfigTP<NameAndCodeResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TeamsRequests.CONTROLLER_ROOT}/${TeamsRequests.NAMES_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static create = (dto: TeamSearchRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TeamsRequests.CONTROLLER_ROOT}/${TeamsRequests.SAVE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: TeamSearchRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TeamsRequests.CONTROLLER_ROOT}/${TeamsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TeamsRequests.CONTROLLER_ROOT}/${TeamsRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})
}
