import React from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'

interface ICPProps {
	formStateManager: IFormStateManager<ClinicFormModel>
}

/**
 * Formulário das informações de instalação da clínica
 */
export function FormClinicTechnicalDataICP(props: ICPProps): JSX.Element {
	return (
		<AccessControlCP permission={{ isBeeRadsAdmin: true }}>
			<>
				<TitleCP marginBottom={10} textSize={'normal'} underLine={true}>
					Instalação do BeeRads BOX
				</TitleCP>
				<RowCP>
					<ColumnCP size={12}>
						<InputCP label={'AE TITLE'} formStateManager={props.formStateManager} fieldName={'aeTitle'} />
					</ColumnCP>
					<ColumnCP size={8}>
						<InputCP label={'IP'} formStateManager={props.formStateManager} fieldName={'ip'} />
					</ColumnCP>
					<ColumnCP size={4}>
						<InputCP label={'Porta'} formStateManager={props.formStateManager} fieldName={'port'} />
					</ColumnCP>
				</RowCP>
				<RowCP>
					<ColumnCP size={12}>
						<InputCP label={'Bucket ID'} formStateManager={props.formStateManager} fieldName={'bucketId'} />
					</ColumnCP>
					<ColumnCP size={12}>
						<InputCP label={'Bucket Key'} formStateManager={props.formStateManager} fieldName={'bucketKey'} />
					</ColumnCP>
				</RowCP>

				<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
					Integração com outros Sistemas
				</TitleCP>
				<RowCP>
					<ColumnCP size={12}>
						<SelectCP
							label={'Parceiro'}
							options={[
								{
									label: 'Pixeon',
									value: 'Pixeon',
								},
							]}
						/>
					</ColumnCP>
					<ColumnCP size={12}>
						<InputCP label={'Chave de Integração'} />
					</ColumnCP>
				</RowCP>
			</>
		</AccessControlCP>
	)
}
