import React from 'react'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import styled from 'styled-components'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'

interface ICPProps {
	slaNowInMinutes: number
}

/**
 * Coluna com indicações de urgencia e de pertencimento ao médico logado.
 */
export function IconSlaCP(props: ICPProps): JSX.Element {
	const expldedTime = DateUtils.convertMinutes(props.slaNowInMinutes)
	const isSlaInDanger = expldedTime.hour < 0 || expldedTime.hour === 0

	let text: string
	// Se estiver em menos de 48 horas mostra no formato hroa:minuto
	if (Math.abs(expldedTime.hour) <= 48) {
		// Adiciona zero antes
		const hour = `${props.slaNowInMinutes < 0 ? '-' : ''}${Math.abs(expldedTime.hour) < 10 ? '0' : ''}${Math.abs(expldedTime.hour)}`
		const minute = `${expldedTime.minute < 10 ? '0' : ''}${expldedTime.minute}`

		text = `${hour}:${minute}`
	} else text = `${expldedTime.day} dias`

	// Cor de acordo com o tempo de SLA
	let slaColor
	if (expldedTime.hour <= 2) slaColor = ThemeProjectCommon.examSlaColor.sla2h
	else if (expldedTime.hour <= 6) slaColor = ThemeProjectCommon.examSlaColor.sla6h
	else if (expldedTime.hour <= 24) slaColor = ThemeProjectCommon.examSlaColor.sla24h

	return (
		<WrapperSCP slaColor={slaColor}>
			<IconICP
				size={16}
				iconName={props.slaNowInMinutes > 0 ? 'clock-circle' : 'fire'}
				theme={'twoTone'}
				color={isSlaInDanger ? ThemeProjectVX.error : undefined}
			/>
			<TextCP text={text} marginLeft={5} size={'small'} />
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ slaColor?: string }>`
	display: flex;
	background-color: ${(props) => props.slaColor};
	padding: 5px;
	border-radius: 5px;
`
