import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { TemplateSaveRequestDTO } from 'submodules/beerads-sdk/services/templates/dtos/request/TemplateSaveRequestDTO'
import { TemplateSearchRequestDTO } from 'submodules/beerads-sdk/services/templates/dtos/request/TemplateSearchRequestDTO'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/dtos/response/TemplateResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { TemplateForMedicalReportSearchRequestDTO } from 'submodules/beerads-sdk/services/templates/dtos/request/TemplateForMedicalReportSearchRequestDTO'

export class TemplatesRequests {
	public static CONTROLLER_ROOT = 'templates'
	public static SEARCH_EP = 'search'
	public static SEARCH_FOR_MEDICAL_REPORT_EP = 'search-for-medical-report'
	public static DELETE_EP = 'delete/:code'
	public static CREATE_EP = 'create'
	public static GET_EP = 'get/:code'
	public static UPDATE_EP = 'update/:code'

	static search = (dto: TemplateSearchRequestDTO): RequestConfigTP<ListResponseDTO<TemplateResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static searchForMedicalReport = (dto: TemplateForMedicalReportSearchRequestDTO): RequestConfigTP<ListResponseDTO<TemplateResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.SEARCH_FOR_MEDICAL_REPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static create = (dto: TemplateSaveRequestDTO): RequestConfigTP<TemplateResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: TemplateSaveRequestDTO): RequestConfigTP<TemplateResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.DELETE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static get = (code: number): RequestConfigTP<TemplateResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${TemplatesRequests.CONTROLLER_ROOT}/${TemplatesRequests.GET_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})
}
