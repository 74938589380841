import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { AuditStatusEnum, AuditStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	isMultiple?: boolean
	required?: boolean
}

/**
 */
export function SelectAuditStatusCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label ?? 'Status da Auditoria'}
			fieldName={props.fieldName}
			isMultiple={props.isMultiple}
			formStateManager={props.formStateManager}
			required={props.required}
			options={Object.values(AuditStatusEnum).map((audit) => ({
				value: audit,
				label: AuditStatusLabelEnum[audit],
			}))}
		/>
	)
}
