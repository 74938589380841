import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	label?: string
}

/**
 */
export function ButtonCloseWindow(props: ICPProps): JSX.Element {
	return (
		<ButtonCP type={'danger'} onClick={() => window.close()}>
			{props.label ?? 'Fechar'}
		</ButtonCP>
	)
}
