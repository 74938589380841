import React, { useEffect } from 'react'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
	onDelete: () => void
	auditCodes: number[]
	appearance?: {
		showLabel?: boolean
	}
}

/**
 */
export function ButtonDeleteAuditsCP(props: ICPProps): JSX.Element {
	const deleteRequest = useRequest<void>('none')
	useEffect(onDeleteRequestChange, [deleteRequest.isAwaiting])

	/**
	 */
	function remove(): void {
		deleteRequest.runRequest(AuditsRequests.delete(props.auditCodes))
	}

	/**
	 */
	function onDeleteRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				deleteRequest,
				NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE,
				true,
			)
		)
			return

		props.onDelete()
	}

	return (
		<ButtonCP
			icon={'delete'}
			type={'danger'}
			size={'small'}
			confirmMsg={'Tem certeza que deseja remover a(s) audotira(s) selecionada(s)? Essa ação não pode ser desfeita'}
			onClick={remove}
			loading={deleteRequest.isAwaiting}
		>
			{props.appearance?.showLabel ? 'Remover auditorias' : undefined}
		</ButtonCP>
	)
}
