import React from 'react'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

/**
 * Icon: Máscaras de laudo:
 * Pode representar itens da entidade TEMPLATE de quaisquer tipos.
 * e.g: template, frase de laudo, etc.
 *
 * @author Stella
 * @author hjcostabr
 */
export const ReportMaskIconCP = (props: IIconProps): JSX.Element => {
	return (
		<svg
			style={{
				width: props.size || '22',
				height: props.size || '22',
				fill: props.color || 'inherit',
			}}
			width={props.size || '22'}
			height={props.size || '22'}
			fill={props.color || 'inherit'}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path d="M96 362.67h42.667v21.333H96zM96 405.33h64v21.333H96z" />
			{/* tslint:disable-next-line: max-line-length*/}
			<path d="M502.402 456.139l-118.4-118.414V74.667c0-17.673-14.327-32-32-32H137.6C132.658 18.323 111.137 0 85.335 0S38.013 18.323 33.071 42.667h-1.069c-17.673 0-32 14.327-32 32V480c0 17.673 14.327 32 32 32h320c17.673 0 32-14.327 32-32v-49.73l72.139 72.13a32.597 32.597 0 0 0 23.147 9.6 32.711 32.711 0 0 0 23.142-9.596c12.768-12.783 12.756-33.497-.028-46.265zM85.335 21.333c13.932 0 25.778 8.906 30.172 21.333H55.164c4.393-12.427 16.239-21.333 30.171-21.333zM362.669 480c0 5.891-4.776 10.667-10.667 10.667h-320c-5.891 0-10.667-4.776-10.667-10.667V74.667C21.335 68.776 26.111 64 32.002 64h85.333v117.333c0 5.891-4.776 10.667-10.667 10.667s-10.667-4.776-10.667-10.667v-96H74.669v96c0 17.673 14.327 32 32 32s32-14.327 32-32V64h213.333c5.891 0 10.667 4.776 10.667 10.667V316.39l-32-32.004V96c0-5.891-4.776-10.667-10.667-10.667h-160v21.333h149.333V265.38l-59.232-29.55a10.664 10.664 0 0 0-9.556 0c-.572.286-1.099.625-1.595.994l-.017-.023-30.272 22.677-17.067-59.733a10.667 10.667 0 0 0-2.718-4.622c-4.167-4.165-10.92-4.163-15.085.003L144.919 224H64.002c-5.891 0-10.667 4.776-10.667 10.667v224c0 5.891 4.776 10.667 10.667 10.667h256c5.891 0 10.667-4.776 10.667-10.667v-81.724l32 31.996V480zm-83.222-190.282l-10.282-20.545 20.551 10.276-10.269 10.269zm29.888 65.893V448H74.669V245.333h74.667a10.67 10.67 0 0 0 7.531-3.104l19.2-19.2 16.341 57.227a10.667 10.667 0 0 0 16.661 5.61l32.422-24.316 25.487 50.983.027-.013a10.643 10.643 0 0 0 1.978 2.744l40.352 40.347zm-17.728-47.888l16.117-16.117 140.908 140.907-16.117 16.117-140.908-140.907zm195.734 179.616c-4.451 4.451-11.667 4.451-16.117 0L447.6 463.715l16.117-16.117 23.623 23.623c4.452 4.451 4.452 11.667.001 16.118z" />
		</svg>
	)
}
