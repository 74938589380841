import React from 'react'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	comment: {
		text: string
		user: NameAndCodeResponseDTO
		date: Date
	}
	deleteComment?: {
		showDeleteButton: boolean
		onDelete: () => void
	}
}

/**
 */
export function CommentCP(props: ICPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={{ bottom: 15 }}>
			<FlexCP>
				<FontAwsomeIconCP type={faQuoteLeft} color={ThemeProject.secondary} />
				<DescriptionSCP>{props.comment.text}</DescriptionSCP>
			</FlexCP>

			<FlexCP justifyContent={'flex-end'}>
				<TextCP
					text={`por ${props.comment.user.name} em ${DateUtils.formatDate(props.comment.date, DateFormatEnum.BR_WITH_TIME_H_M)}`}
					color={ThemeProject.gray300}
					wrap={true}
				/>

				{!!props.deleteComment?.showDeleteButton && (
					<ButtonCP
						marginLeft={5}
						icon={'delete'}
						size={'small'}
						type={'link'}
						confirmMsg={NotificationHelper.DEFAULT_CONFIRM_DELETE_MESSAGE}
						onClick={props.deleteComment.onDelete}
					/>
				)}
			</FlexCP>
		</BasicStyleWrapperCP>
	)
}

const DescriptionSCP = styled.p`
	font-style: italic;
	margin-left: 10px;
	margin-top: 2px;
`
