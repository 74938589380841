import { Dropdown } from 'antd'
import { ButtonSize } from 'antd/es/button/button'
import React from 'react'
import { MultiActionButtonItemICP } from 'submodules/nerit-framework-ui/common/components/button/inner/MultiActionButtonItemICP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { IMultiActionButtonOption } from 'submodules/nerit-framework-ui/common/components/button/inner/IMultiActionButtonOption'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps extends BasicStyleWrapperCPProps {
	options: IMultiActionButtonOption[]
	disabled?: boolean
	size?: ButtonSize
	loading?: boolean
	loadingProgress?: number
	type?: 'primary' | 'ghost' | 'dashed'
	tooltip?: string // Por enquanto so esta funcionando quando tem apenas uma opcao
	appearance?: {
		onlyIcon?: boolean // Se deve mostrar apenas icone no primeiro elemento
	}
}

/**
 * Botão com opções múltiplas de ação: A opcao 01 eh considerada a acao principal.
 */
export function ButtonMultiActionCP(props: ICPProps): JSX.Element | null {
	// Obtem apenas os que devem ser utilizados
	const options = props.options.filter((option) => !option.hide)

	// Extrai a primeira opcao que sera o botao principal
	const mainItem = options[0]
	const dropdownItems = options.length > 1 ? options.slice(1) : []

	if (ArrayUtils.isEmpty(dropdownItems)) {
		return (
			<ButtonCP
				type={props.type ?? 'primary'}
				loading={props.loading}
				size={props.size}
				onClick={mainItem.onClick}
				marginTop={props.margin?.top}
				marginBottom={props.margin?.bottom}
				marginLeft={props.margin?.left}
				marginRight={props.margin?.right}
				tooltip={props.tooltip}
				disabled={props.disabled}
			>
				{mainItem.label}
			</ButtonCP>
		)
	}

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<WrapperSCP>
				<Dropdown.Button
					size={props.size}
					type={props.type}
					onClick={mainItem.onClick}
					disabled={props.disabled}
					overlay={<MultiActionButtonItemICP items={dropdownItems} />}
				>
					<MainContentSCP>
						<IconWrapperSCP>{props.loading ? <LoadingIconCP size={props.size === 'small' ? 16 : undefined} /> : mainItem.icon}</IconWrapperSCP>

						{props.loadingProgress !== undefined && <TextSCP btnSize={props.size ?? 'default'}>{`(${props.loadingProgress}%) `}</TextSCP>}

						{!props.appearance?.onlyIcon && <TextSCP btnSize={props.size ?? 'default'}>{mainItem.label}</TextSCP>}
					</MainContentSCP>
				</Dropdown.Button>
			</WrapperSCP>
		</BasicStyleWrapperCP>
	)
}

const TextSCP = styled.span<{ btnSize: ButtonSizeTP }>`
	font-size: ${(props) => ThemeFrameworkCommon.fontSizes[props.btnSize]};
`

const MainContentSCP = styled.div`
	margin: 0 8px;
	display: flex;
	align-items: center;
`

const IconWrapperSCP = styled.div`
	margin-right: 5px;
	display: flex;
`

const WrapperSCP = styled.div`
	.ant-dropdown-button {
		&.ant-btn-group {
			display: flex;
		}

		.ant-btn:not(.ant-dropdown-trigger) {
			padding: 0;
		}

		.ant-btn.ant-dropdown-trigger {
			padding: 0;
		}

		.ant-btn[disabled] {
			opacity: 0.6;
		}
	}
`
