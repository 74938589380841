import React from 'react'
import { ButtonDownloadMedicalReportCP } from 'modules/exams/components/medical-report/button-download-medical-report/ButtonDownloadMedicalReportCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonAssignExamCP } from 'modules/exams/components/exams/button-assign-exam/ButtonAssignExamCP'
import { ExamFileNamesUtils } from 'modules/exams/utils/ExamFileNamesUtils'
import { ButtonMultiActionDownloadExamDicomCP } from 'modules/exams/components/exams/button-download-exam/button-multi-action-dicom-download/ButtonMultiActionDownloadExamDicomCP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ButtonUnAssignExamCP } from 'modules/exams/components/exams/button-unassign-exam/ButtonUnAssignExamCP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { ButtonShareCopyUrlToClipboardCP } from 'modules/exams/components/exams/button-share-copy-url-to-clipboard/ButtonShareCopyUrlToClipboardCP'

interface ICPProps {
	selectedExamCodes?: number[]
	selectedExamIds?: string[]
	doctorGroupCode: number
	viewAsDoctor: boolean
	onSave: () => void
}

/**
 * Barra com botoes de acao em massa para listagem de exame.
 */
export function BulkActionsBarICP(props: ICPProps): JSX.Element {
	if (ArrayUtils.isEmpty(props.selectedExamCodes) || ArrayUtils.isEmpty(props.selectedExamIds)) {
		return <></>
	}

	return (
		<BulkActionBtnsContainerSCP>
			<ButtonAssignExamCP doctorGroupCode={props.doctorGroupCode} selectedExamCodes={props.selectedExamCodes!} onSave={props.onSave} />

			<ButtonUnAssignExamCP selectedExamCodes={props.selectedExamCodes!} onSave={props.onSave} />

			<ButtonDownloadMedicalReportCP
				fileName={ExamFileNamesUtils.getMedicalReportFileName(props.selectedExamCodes!)}
				margin={{ right: 5 }}
				onDownloadDone={props.onSave}
				downloadedByConfig={{
					user: {
						examIds: props.selectedExamIds!,
					},
				}}
			/>
			{props.selectedExamIds && (
				<ButtonShareCopyUrlToClipboardCP
					text={'Compartilhar'}
					icon={'share-alt'}
					margin={{ right: 5 }}
					selectedExamIds={props.selectedExamIds}
					viewAsDoctor={props.viewAsDoctor}
				/>
			)}

			{DoctorGroupPermissionUtils.canSeeBulkExamData() && (
				<ButtonMultiActionDownloadExamDicomCP
					downloadConfig={{
						multiple: {
							examIds: props.selectedExamIds!,
						},
						downloadBy: 'user',
					}}
				/>
			)}

			<TextCP
				text={`${props.selectedExamCodes!.length} exames selecionado(s)`}
				size={'small'}
				fontStyle={'italic'}
				color={ThemeProject.gray300}
				marginLeft={10}
			/>
		</BulkActionBtnsContainerSCP>
	)
}

const BulkActionBtnsContainerSCP = styled.div`
	display: flex;
	position: absolute;
	left: 20px;
`
