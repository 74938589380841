import React from 'react'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RadioOptionsYesOrNo } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/inner/RadioOptionTP'
import { AvcSectionContentICP, IAvcSectionProps } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/AvcSectionContentICP'

/**
 */
export function AvcQuestion4ICP(props: IAvcSectionProps): JSX.Element {
	return (
		<AvcSectionContentICP title={'4. SINAIS DE ISQUEMIA ANTIGA'}>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSuba'}
						label={'Território – ACM:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoSubd'}
						label={'Território – ACA:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território – ACP:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={'Território – Tálamos:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={'Território – Núcleos da base:'}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoExtr'}
						label={'Território – Tronco encefálico:'}
					/>
				</ColumnCP>
			</RowCP>
			<RowCP>
				<ColumnCP size={12}>
					<RadioGroupCP
						type={'button'}
						options={RadioOptionsYesOrNo}
						formStateManager={props.formStateManager}
						fieldName={'q1HemoIntra'}
						label={'Território – Cerebelo:'}
					/>
				</ColumnCP>
			</RowCP>
		</AvcSectionContentICP>
	)
}
