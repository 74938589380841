import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { AuditFormModel } from 'modules/quality-assurance/screens/screen-audit/inner/AuditFormModel'
import { MentionCP } from 'submodules/nerit-framework-ui/common/components/form-fields/mention/MentionCP'

interface ICPProps {
	formStateManager: IFormStateManager<AuditFormModel>
}

/**
 */
export function MentionAuditPhrasesCP(props: ICPProps): JSX.Element | null {
	return (
		<MentionCP
			formStateManager={props.formStateManager}
			placeholder={'Descreva sua avaliação sobre o Laudo'}
			fieldName={'comments'}
			prefix={'@'}
			appearance={{
				bordered: true,
			}}
			options={[
				{ label: 'Nada digno de nota.', value: 'Nada digno de nota.' },
				{ label: 'Laudo muito bem elaborado.', value: 'Laudo muito bem elaborado.' },
				{ label: 'Layout inadequado.', value: 'Layout inadequado.' },
				{ label: 'Erro no diagnóstico principal.', value: 'Erro no diagnóstico principal.' },
				{ label: 'Erro de lateralidade.', value: 'Erro de lateralidade.' },
				{ label: 'Erros de ortografia.', value: 'Erros de ortografia.' },
				{
					label: 'Descrição rasa, sem aprofundamento em relação ao diagnóstico principal.',
					value: 'Descrição rasa, sem aprofundamento em relação ao diagnóstico principal.',
				},
				{ label: 'Faltou impressão.', value: 'Faltou impressão.' },
				{ label: 'Usou frases desnecessárias.', value: 'Usou frases desnecessárias.' },
				{
					label: 'Não apagou as frases normais da máscara.',
					value: 'Não apagou as frases normais da máscara.',
				},
			]}
			rows={20}
			hint={{
				text: 'Você pode usar o @ para utilizar frases prontas. Digite @ no campo acima para ver as frases.',
				type: 'text',
			}}
		/>
	)
}
