import React, { useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TagExamStatusCP } from 'modules/exams/components/exams/tag-exam-status/TagExamStatusCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonShowMedicalReportContentCP } from 'modules/exams/components/medical-report/button-show-medical-report-content/ButtonShowMedicalReportContentCP'
import { ButtonDoMedicalReportCP } from 'modules/exams/components/medical-report/button-do-medical-report/ButtonDoMedicalReportCP'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { PatientExamResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PatientExamResponseDTO'
import { PatientExamsSearchRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PatientExamsSearchRequestDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'

interface ICPProps {
	exam: ExamResponseDTO
	onCopyMedicalReport?: (jsonContent: any) => void
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function TablePatientExamsCP(props: ICPProps): JSX.Element {
	const [reload, setReload] = useState<number>(1)

	const patientDataExists = !!props.exam.patient.name && !!props.exam.patient.birthday && !!props.exam.patient.gender

	if (!patientDataExists) {
		return (
			<div>
				Identificamos que os dados do paciente estão incompletos. Por favor, verifique se o nome, data de nascimento e sexo foram preenchidos
				corretamente.
			</div>
		)
	}

	return (
		<TableFromApiCP<PatientExamResponseDTO, PatientExamsSearchRequestDTO>
			shouldLoadData={reload}
			apiConfig={{
				hasPagination: false,
				filters: {
					clinicCode: props.exam.clinic.code,
					examCodeToExcludeFromResult: props.exam.code,
					searchByPatientId: props.exam.patient.id,
					searchByPatientData: {
						name: props.exam.patient.name,
						birthday: new Date(props.exam.patient.birthday),
						gender: props.exam.patient.gender,
					},
				},
				requestConfigTP: (filters) => PeopleRequests.patientExams(filters!),
			}}
			columns={[
				{
					render: (text, record) => (
						<ButtonDoMedicalReportCP exam={record} type={'default'} openInNewTab={true} searchExamDto={props.searchExamDto} />
					),
				},
				{
					title: 'Exame',
					render: (text, record) => `${ExamModalityLabelEnum[record.modality]} ${record.description}`,
				},
				{
					title: 'Data',
					render: (text, record) => DateUtils.formatDate(record.examDate, DateFormatEnum.BR_WITHOUT_TIME),
				},
				{
					render: (text, record) => <TagExamStatusCP examStatus={record.status} />,
				},
				{
					render: (text, record) => (
						<ButtonGroupCP mode={'separeted'}>
							{record.medicalReports?.map((medicalReport, index) => (
								<ButtonShowMedicalReportContentCP
									key={index}
									medicalReportContent={medicalReport}
									onCopy={!props.onCopyMedicalReport ? undefined : () => props.onCopyMedicalReport!(medicalReport.json)}
								/>
							))}
							<ButtonExamDetailsCP examId={record.id} onSave={() => setReload(TableUtils.getReloadNumber())} />
						</ButtonGroupCP>
					),
				},
			]}
		/>
	)
}
