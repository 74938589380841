import React, { useState, createContext, useMemo, useContext, useCallback } from 'react'
import { PasswordUtils } from 'submodules/nerit-framework-utils/utils/PasswordUtils'

export type PasswordDataTP = {
	password: string
	currentPassword: string
	confirmPassword: string
}

interface IFormSetPasswordContext {
	passwordData: PasswordDataTP
	passwordErrors: string[]
	handlePasswordDataChange: (field: keyof PasswordDataTP, value: string) => void
}

interface IFormSetPasswordContextProviderProps {
	children: React.ReactNode
	onPasswordDataChange?: (passwordData: PasswordDataTP) => void
}

export const FormSetPasswordContext = createContext<IFormSetPasswordContext | null>(null)

export function FormSetPasswordContextProvider(props: IFormSetPasswordContextProviderProps): JSX.Element {
	const [passwordData, setPasswordData] = useState<PasswordDataTP>({
		password: '',
		currentPassword: '',
		confirmPassword: '',
	})
	const [passwordErrors, setPasswordErrors] = useState<string[]>([])

	const handlePasswordDataChange = useCallback((field: keyof PasswordDataTP, value: string): void => {
		setPasswordData((prevState) => {
			const newPasswordData = {
				...prevState,
				[field]: value,
			}

			if (props.onPasswordDataChange) props.onPasswordDataChange(newPasswordData)

			return newPasswordData
		})

		if (field !== 'currentPassword') {
			const { errors } = PasswordUtils.validatePassword(value)
			setPasswordErrors(errors)
		}
	}, [])

	const values = useMemo<IFormSetPasswordContext>(
		() => ({
			passwordData,
			passwordErrors,
			handlePasswordDataChange,
		}),
		[passwordErrors, passwordData, handlePasswordDataChange],
	)

	return <FormSetPasswordContext.Provider value={values}>{props.children}</FormSetPasswordContext.Provider>
}

export function useFormSetPasswordContext(): IFormSetPasswordContext {
	const values = useContext(FormSetPasswordContext)

	if (!values) throw new Error('FormSetPasswordContext must be consumed inside the FormSetPasswordContextProvider.')

	return values
}
