import { LinkCP } from '_old/main/common/components/link/LinkCP'
import React, { useRef, useState } from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PatientPortalDataPdfCP } from 'modules/patient-portal/components/patient-portal-data-pdf/PatientPortalDataPdfCP'
import { useReactToPrint } from 'react-to-print'
import { PatientPortalAccessUtils } from 'submodules/beerads-sdk/services/patient-portal/utils/PatientPortalAccessUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onEditData?: (closeDrawer?: boolean) => void
}

/**
 * Componente para exibir detalhes do exame.
 */
export function ExamPatientPortalDataCP(props: ICPProps): JSX.Element {
	const componentRef = useRef()
	const handlePrint = useReactToPrint({
		documentTitle: `br-laudos-exam_${props.exam.code}`,
		content: () => componentRef.current as any,
	})

	const [isPrinterReady, setIsPrinterReady] = useState<boolean>()

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<FlexCP justifyContent={'space-between'}>
				<TitleCP textSize={'normal'} underLine={true}>
					Acesse o resultado:
				</TitleCP>

				<ButtonCP icon={'printer'} size={'small'} onClick={handlePrint} loading={!isPrinterReady} />
			</FlexCP>

			<TextAndTitleCP
				title={'URL:'}
				text={
					<LinkCP
						onClick={() =>
							NeritFrameworkRoutingHelper.openInNewTab(PatientPortalAccessUtils.getBeeRadsLaudoUrl(true, AppStateUtils.getDomain()))
						}
						text={PatientPortalAccessUtils.getBeeRadsLaudoUrl(false)}
						textSize={'normal'}
						color={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
					/>
				}
			/>

			<TextAndTitleCP title={'Código do Exame:'} text={props.exam.code.toString()} />
			<TextAndTitleCP title={'Data de Nascimento:'} text={DateUtils.formatDate(props.exam.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME)} />
			<TextAndTitleCP title={'Nome Completo:'} text={props.exam.patient.name} />

			<PatientPortalDataPdfCP exam={props.exam} ref={componentRef} onPrinterReady={() => setIsPrinterReady(true)} />
		</BasicStyleWrapperCP>
	)
}
