import React, { useEffect, useState } from 'react'
import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import styled from 'styled-components'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'
import { TimeRangeMedicalTimetableAssignCP } from 'modules/medical-timetable/components/time-range-medical-timetable-assign/TimeRangeMedicalTimetableAssignCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import {
	MedicalTimetablePeriodEnum,
	MedicalTimetablePeriodLabelEnum,
} from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAssignFormModel>
	weekDay: WeekDaysEnum
	period: MedicalTimetablePeriodEnum
}

/**
 */
export function BoxMedicalTimetableDoctorWeekSpotConfigItemICP(props: ICPProps): JSX.Element {
	const [start, end] = MedicalTimetablePeriodLabelEnum[props.period].split('~')

	// Caso não haja registro, seta o período como horário default
	const [beginHour, setBeginHour] = useState<string>(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour ?? start)
	const [endHour, setEndHour] = useState<string>(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour ?? end)

	const [showExtraValue, setShowExtraValue] = useState<boolean>(false)

	const [onEdit, setOnEdit] = useState<boolean>(false)

	useEffect(init, [
		props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour,
		props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour,
		props.formStateManager.getFieldValue('extraValue'),
	])

	function init(): void {
		setBeginHour(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.beginHour ?? start)
		setEndHour(props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.endHour ?? end)
		setShowExtraValue(props.formStateManager.getFieldValue('extraValue') ?? false)
	}

	//se nao vier nenhum valor do response, cria um map já setando o horário do período
	useEffect(createSpotMap, [!!props.formStateManager.getFieldValue('spot')])

	function createSpotMap(): void {
		let map: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = props.formStateManager.getFieldValue('spot')

		if (!map) map = new Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP>()

		map.set(props.weekDay, {
			selected: true,
			beginHour: beginHour,
			endHour: endHour,
		})
		props.formStateManager.changeFieldValue('spot', map)
	}

	return (
		<WrapperSCP>
			<RowCP>
				<FlexCP alignItems={'center'}>
					<ColumnCP size={11}>
						<SwitchWrapperSCP>
							<SwitchCP label={momentPtBrLocale.weekdaysFull[props.weekDay]} value={true} disabled={true} />
						</SwitchWrapperSCP>
					</ColumnCP>

					<ColumnCP size={11}>
						<TextCP text={`${beginHour}~${endHour}`} fontStyle="italic" size="small" />
					</ColumnCP>

					<ColumnCP size={2}>
						<ButtonCP
							size="small"
							type={'default'}
							onClick={() => setOnEdit(!onEdit)}
							disabled={!props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.selected}
						>
							<IconCP antIcon="edit" />
						</ButtonCP>
					</ColumnCP>
				</FlexCP>
			</RowCP>

			{props.formStateManager.getFieldValue('spot')?.get(props.weekDay)?.selected && onEdit && (
				<RowCP>
					<ColumnCP size={9}>
						<TimeRangeMedicalTimetableAssignCP
							formStateManager={props.formStateManager}
							weekDay={props.weekDay}
							assignType={'spot'}
							beginHour={beginHour}
							endHour={endHour}
							setBeginHour={setBeginHour}
							setEndHour={setEndHour}
						/>
					</ColumnCP>
				</RowCP>
			)}

			{(props.period === MedicalTimetablePeriodEnum.THIRD_SHIFT ||
				props.period === MedicalTimetablePeriodEnum.DAYTIME ||
				props.period === MedicalTimetablePeriodEnum.DAWN ||
				props.period === MedicalTimetablePeriodEnum.NIGHT) && (
				<ExtraValueWrapperSCP>
					<SwitchWrapperSCP>
						<SwitchCP label={'Valor extra acordado?'} value={showExtraValue} onChange={() => setShowExtraValue(!showExtraValue)} />
					</SwitchWrapperSCP>

					{showExtraValue && (
						<RowCP flex={true} align="middle">
							<ColumnCP size={12}>
								<InputCP
									label={'Valor extra R$'}
									hint={{
										type: 'text',
										text: 'Preencha caso tenha sido acordado um valor extra com o médico',
									}}
									formStateManager={props.formStateManager}
									fieldName="extraValue"
									mask={InputMaskTypeEnum.MONEY}
								/>
							</ColumnCP>

							{/* TODO: Deixar comentado até avaliar o impacto se será necessária a funcionalidade
                                
                                {
                                !!props.formStateManager.getFieldValue('extraValue') &&
                                <ColumnCP size={12}>
                                    <SwitchWrapperSCP>
                                        <SwitchCP
                                            label={'Adicionar valor variável?'}
                                            formStateManager={props.formStateManager}
                                            fieldName='shouldKeepDynamicValue'
                                            hint={{ type: 'text', text: 'ATENÇÃO, ao marcar como sim será pago o valor extra e o valor variável.' }}
                                        />
                                    </SwitchWrapperSCP>
                                </ColumnCP>
                                } 
                                
                            */}
						</RowCP>
					)}
				</ExtraValueWrapperSCP>
			)}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	display: flex;
	gap: 5px;
	margin-bottom: 10px;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	justify-content: center;
`
const SwitchWrapperSCP = styled.div`
	.ant-typography {
		width: full;
	}

	span {
		line-height: normal;
	}
`

const ExtraValueWrapperSCP = styled.div`
	margin-top: 35px;
`
