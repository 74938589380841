export enum ExamStatusEnum {
	PENDING = 'PENDING',
	RETURNED = 'RETURNED', // Retornado para a clinica. Sai de PENDING_ADMINISTRATIVE_ANALYSIS e manda ele de volta para a clinica.

	AWAITING = 'AWAITING',

	IN_PROGRESS = 'IN_PROGRESS',
	RECTIFICATION = 'RECTIFICATION',

	SIGNED = 'SIGNED', // Assinado por um médico apenas. Passa agora a ficar aguardando assinatura de outro.
	SIGNED_RECTIFICATION = 'SIGNED_RECTIFICATION', // Médico pediu segunda opniao em um exame que estava em retificacao.

	PENDING_ADMINISTRATIVE_ANALYSIS = 'PENDING_ADMINISTRATIVE_ANALYSIS',
	PENDING_RECTIFICATION_ANALYSIS = 'PENDING_RECTIFICATION_ANALYSIS',

	DONE = 'DONE', // Médico concluiu o trabalho nesse laudo. O serviço dele acabou aqui.
	DONE_RECTIFICATION = 'DONE_RECTIFICATION',
	PRINTED = 'PRINTED',

	ARCHIVED = 'ARCHIVED',
}

export enum ExamStatusLabelEnum {
	PENDING = 'Pendente',
	RETURNED = 'Devolvido',

	AWAITING = 'Aguardando',

	IN_PROGRESS = 'Em progresso',
	RECTIFICATION = 'Aguardando retificação',

	SIGNED = 'Aguardando 2ª assinatura',
	SIGNED_RECTIFICATION = 'Aguardando 2ª assinatura de retificação',

	PENDING_ADMINISTRATIVE_ANALYSIS = 'Análise administrativa',
	PENDING_RECTIFICATION_ANALYSIS = 'Análise de retificação',

	DONE = 'Concluído',
	DONE_RECTIFICATION = 'Retificação concluída',
	PRINTED = 'Entregue',

	ARCHIVED = 'Arquivado',
}
