import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'

interface ICPProps {
	visible: boolean
	onClose: () => void
}

/**
 */
export function ModalSpeedTest(props: ICPProps): JSX.Element {
	return (
		<ModalCP visible={props.visible} noFooter={true} title={'Teste a velocidade da sua internet'} width={800} onCancel={props.onClose} padding={0}>
			<iframe height={500} width={'100%'} frameBorder={0} src={'//openspeedtest.com/speedtest'} />
			<AlertCP message={'Uma velocidade de donwload acima de 25mb é recomendada para o uso do sistema.'} type={'warning'} />
		</ModalCP>
	)
}
