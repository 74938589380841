import React from 'react'
import { SelectExamSpecialtyCP } from 'modules/exams/components/exams/select-exam-specialty/SelectExamSpecialtyCP'
import { SelectTeamCP } from 'modules/doctor-groups/components/teams/select-team/SelectTeamCP'
import { DoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/people/form-doctor-group-doctor/inner/DoctorGroupDoctorFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { SelectDoctorLevelCP } from 'modules/exams/components/exams/select-doctor-level/SelectDoctorLevelCP'

interface IUserSelectedInfoICPProps {
	doctorGroupCode: number
	formStateManager: IFormStateManager<DoctorGroupDoctorFormModel>
}

/**
 * Formulario com dados do medico no grupo de medicos.
 */
export function FormDoctorGroupDoctorCP(props: IUserSelectedInfoICPProps): JSX.Element {
	const isSpecialtyFilled = !!props.formStateManager.getFieldValue('specialty')

	return (
		<>
			<RowCP>
				<ColumnCP size={isSpecialtyFilled ? 12 : 24}>
					<SelectExamSpecialtyCP label={'Especialidade'} isMultiple={false} formStateManager={props.formStateManager} fieldName={'specialty'} />
				</ColumnCP>

				{isSpecialtyFilled && (
					<ColumnCP size={12}>
						<SelectDoctorLevelCP
							label="Nível"
							isMultiple={false}
							formStateManager={props.formStateManager}
							fieldName={'doctorLevel'}
							required={true}
						/>
					</ColumnCP>
				)}
			</RowCP>

			<SelectTeamCP doctorGroupCode={props.doctorGroupCode} isMultiple={true} formStateManager={props.formStateManager} fieldName={'teamCodes'} />

			<SwitchCP formStateManager={props.formStateManager} fieldName={'isAuditor'} label={'É auditor?'} />
		</>
	)
}
