/**
 * ENUM
 * Determina formatos de exibicao de data/hora utilizados no sistema.
 *
 * @author hjcostabr
 */
export enum DateTimeFormatEnum {
	BR_WITH_TIME_H_M = 'DD/MM/YYYY - HH:mm',
	BR_WITH_TIME_H_M_S = 'DD/MM/YYYY [às] HH:mm:ss',
	BR_WITHOUT_TIME = 'DD/MM/YYYY',
	BR_MONTH_YEAR = 'MM/YYYY',
	EUA_WITHOUT_TIME = 'YYYY-MM-DD',
}
