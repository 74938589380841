export enum AuditStatusEnum {
	AWAITING = 'AWAITING',
	IN_PROGRESS = 'IN_PROGRESS',
	DONE = 'DONE',
}

export enum AuditStatusLabelEnum {
	AWAITING = 'Aguardando',
	IN_PROGRESS = 'Em andamento',
	DONE = 'Auditado',
}
