import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { PersonDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PersonDoctorSaveRequestDTO'
import { PersonDoctorSearchRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PersonDoctorSearchRequestDTO'
import { PersonUserSaveRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PersonUserSaveRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ProfileUserUpdateRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/ProfileUserUpdateRequestDTO'
import { ProfileDoctorUpdateRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/ProfileDoctorUpdateRequestDTO'
import { PatientExamsSearchRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PatientExamsSearchRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { PatientExamResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PatientExamResponseDTO'

export class PeopleRequests {
	public static CONTROLLER_ROOT = 'people'
	public static GET_ONE_EP = 'get/:code'
	public static COUNT_USERS_IN_GROUP_EP = 'count-users-in-group'
	public static CREATE_USER_EP = 'create-user'
	public static CREATE_DOCTOR_EP = 'create-doctor'
	public static SEARCH_DOCTORS_EP = 'search-doctors'
	public static FIND_BY_CPF_EP = 'find-by-cpf/:cpf'
	public static UPDATE_USER_PROFILE_EP = 'update-user-profile/:code'
	public static UPDATE_DOCTOR_PROFILE_EP = 'update-doctor-profile/:code'
	public static UPLOAD_USER_PHOTO_EP = 'change-profile-photo/:code'
	public static UPLOAD_DOCTOR_SIGNATURE_EP = 'change-doctor-signature/:code'
	public static PATIENT_EXAMS_EP = 'exams'

	static getOne = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.GET_ONE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static createUser = (dto: PersonUserSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.CREATE_USER_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static createDoctor = (dto: PersonDoctorSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.CREATE_DOCTOR_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static updateUserProfile = (code: number, dto: ProfileUserUpdateRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPDATE_USER_PROFILE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static updateDoctorProfile = (code: number, dto: ProfileDoctorUpdateRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPDATE_DOCTOR_PROFILE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static searchDoctors = (dto?: PersonDoctorSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.SEARCH_DOCTORS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static findByCpf = (cpf: string): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.FIND_BY_CPF_EP.replace(':cpf', cpf)}`,
		method: HttpMethodEnum.GET,
	})

	static changeProfilePicture = (code: number, formData: any): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPLOAD_USER_PHOTO_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: formData,
	})

	static changeSignature = (code: number, formData: any): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.UPLOAD_DOCTOR_SIGNATURE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: formData,
	})

	static patientExams = (dto: PatientExamsSearchRequestDTO): RequestConfigTP<ListResponseDTO<PatientExamResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PeopleRequests.CONTROLLER_ROOT}/${PeopleRequests.PATIENT_EXAMS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
