import React from 'react'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { WidgetCountExamsPendingCP } from 'modules/reports/components/widgets/widget-count-exams-pending/WidgetCountExamsPendingCP'
import { WidgetCountExamsReturnedCP } from 'modules/reports/components/widgets/widget-count-exams-returned/WidgetCountExamsReturnedCP'
import { WidgetCountExamsAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-awaiting/WidgetCountExamsAwaitingCP'
import { WidgetCountExamsMedicalReportDoneCP } from 'modules/reports/components/widgets/widget-count-exams-medical-report-done/WidgetCountExamsMedicalReportDoneCP'
import { WidgetCountExamsRectificationDoneCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-done/WidgetCountExamsRectificationDoneCP'
import { WidgetCountExamsRectificationAnalysisOrAwatingCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-analysis-or-awaiting/WidgetCountExamsRectificationAnalysisOrAwatingCP'
import { TableCriticalExamCP } from 'modules/reports/components/table-critical-exam/TableCriticalExamCP'

interface ICPProps {
	clinicCode?: number
}

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ScreenContentHomeClinicUser(props: ICPProps): JSX.Element {
	return (
		<>
			<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
				<WidgetCountExamsPendingCP clinicCode={props.clinicCode} />
				<WidgetCountExamsReturnedCP clinicCode={props.clinicCode} />
				<WidgetCountExamsAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={false} />
				<WidgetCountExamsMedicalReportDoneCP clinicCode={props.clinicCode} />
			</WidgetsRowCP>

			<WidgetsRowCP margin={{ top: 30, bottom: 30 }}>
				<WidgetCountExamsRectificationAnalysisOrAwatingCP clinicCode={props.clinicCode} />
				<WidgetCountExamsRectificationDoneCP clinicCode={props.clinicCode} />
			</WidgetsRowCP>

			<TableCriticalExamCP clinicCode={props.clinicCode} />
		</>
	)
}
