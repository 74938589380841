import { List } from 'antd'
import { ListProps } from 'antd/lib/list'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

type _ScpPropsTP = {
	paddingRight?: number
	paddingLeft?: number
	paddingTop?: number
	paddingBottom?: number
}

interface IListCPProps<ListItemTP> extends ListProps<ListItemTP>, _ScpPropsTP {}

/**
 * Componente de Lista
 */
export function ListCP<ListItemTP>(props: IListCPProps<ListItemTP>): JSX.Element {
	return (
		<WrapperSCP paddingTop={props.paddingTop} paddingRight={props.paddingRight} paddingBottom={props.paddingBottom} paddingLeft={props.paddingLeft}>
			<List<ListItemTP>
				bordered={true}
				{...props} // eslint-disable-line react/jsx-props-no-spreading
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<_ScpPropsTP>`
	height: 100%;

	.ant-list-item {
		padding: ${(props) => `${props.paddingTop!}px ${props.paddingRight!}px ${props.paddingBottom!}px ${props.paddingLeft!}px`} !important;
	}

	.ant-list {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		height: 100%;

		.ant-spin-nested-loading {
			flex: 1;
		}

		.ant-list-pagination {
			padding: 5px 0;
			margin: 16px 0;
			display: flex;
			justify-content: center;
		}
		.ant-pagination-prev .ant-pagination-item-link,
		.ant-pagination-disabled {
			border-color: ${ThemeProject.gray100};
		}
		.ant-pagination-next .ant-pagination-item-link,
		.ant-pagination-disabled {
			border-color: ${ThemeProject.gray100};
		}

		.ant-list-empty-text {
			color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		}
	}
	.ant-list-bordered {
		border-color: ${ThemeProject.gray100};
	}
`
