import React, { useEffect } from 'react'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface ICPProps {
	visible: boolean
	onDelete: () => void
	onCancel: () => void
	medicalReportTabKeyCode: number
	medicalReportCode: number
}

/**
 */
export function ModalRemoveMedicalReportCP(props: ICPProps): JSX.Element {
	const removeMedicalReportRequest = useRequest<void>('none')
	useEffect(onRemoveMedicalReportRequestChange, [removeMedicalReportRequest.isAwaiting])

	/**
	 * Ao remover um laudo
	 */
	function onRemoveMedicalReportRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				removeMedicalReportRequest,
				NotificationHelper.DEFAULT_ERROR_DELETE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_DELETE_MESSAGE,
				true,
			)
		)
			return

		props.onDelete()
	}

	return (
		<ModalCP
			title={`Remover Laudo ${props.medicalReportTabKeyCode + 1}`}
			visible={props.visible}
			actionLoading={removeMedicalReportRequest.isAwaiting}
			okText={'Remover'}
			onOk={() => removeMedicalReportRequest.runRequest(MedicalReportRequests.deleteMedicalReportFromExam(props.medicalReportCode))}
			onCancel={props.onCancel}
		>
			Você tem certeza que deseja remover o laudo e descartar o conteúdo? Essa ação não pode ser desfeita!
		</ModalCP>
	)
}
