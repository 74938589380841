import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import type { MedicalTimetableAssignType } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/ModalMedicalTimetableAssignCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'
import type { Moment } from 'moment'
import type { MedicalTimetableWeekDaySpotTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDaySpotTP'
import { HourRangeRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/HourRangeRequestDTO'

export interface IDoctorTimeTableHour {
	dayOfWeek: number
	hourRange?: HourRangeRequestDTO
}

export class MedicalTimetableAssignFormModel extends FormModel {
	@IsOptional()
	code?: number

	@IsRequired()
	doctorCode: number

	@IsOptional()
	endDate: Moment

	@IsRequired()
	type: MedicalTimetableAssignType

	@IsOptional()
	description: string

	@IsOptional()
	recurrency: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP>

	@IsOptional()
	spot: Map<WeekDaysEnum, MedicalTimetableWeekDaySpotTP>

	@IsOptional()
	weekDayHourRanges?: IDoctorTimeTableHour

	@IsOptional()
	extraValue?: number

	@IsOptional()
	shouldKeepDynamicValue?: boolean

	constructor(initialData?: ObjectPropsTP<MedicalTimetableAssignFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
