import { Icon } from 'antd'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import React from 'react'

/**
 * ICONE: e-mail
 *
 * @author hjcostabr
 * @author Stella
 */
export const MailIconCP = (props: IIconProps): JSX.Element => {
	return (
		<Icon
			type={'mail'}
			style={{
				fill: props.color || 'inherit',
				color: props.color || 'inherit',
				fontSize: props.size || '20px',
				...props.style,
			}}
		/>
	)
}
