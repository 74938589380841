import React from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { DoctorTableUtils } from 'modules/admin-beerads/components/table-doctors/inner/DoctorTableUtils'
import { PersonDoctorListItemResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonDoctorListItemResponseDTO'
import { DoctorIdTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorIdTypeEnum'

interface ICPProps {
	type?: DoctorIdTypeEnum
}

/**
 * Tabela de medicos.
 */
export function TableDoctorsCP(props: ICPProps): JSX.Element {
	return (
		<TableFromApiCP<PersonDoctorListItemResponseDTO>
			appearance={{
				showReloadButton: true,
				wrappedOnCard: true,
			}}
			shouldLoadData={1}
			apiConfig={{
				hasPagination: true,
				requestConfigTP: PeopleRequests.searchDoctors,
			}}
			columns={DoctorTableUtils.getColumns()}
			row={{
				setClass: (record) => (!record.active ? 'row-nerit-error' : ''),
			}}
		/>
	)
}
