export enum MedicalTimetablePeriodEnum {
	MORNING = 'MORNING',
	AFTERNOON = 'AFTERNOON',
	NIGHT = 'NIGHT',

	THIRD_SHIFT = 'THIRD_SHIFT',
	DAWN = 'DAWN',
	DAYTIME = 'DAYTIME',
}

export enum MedicalTimetablePeriodLabelEnum {
	MORNING = '07:00~13:00',
	AFTERNOON = '13:00~19:00',
	NIGHT = '19:00~07:00',

	THIRD_SHIFT = '19:00~23:00',
	DAWN = '02:00~09:00',
	DAYTIME = '07:00~19:00',
}
