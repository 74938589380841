import React from 'react'
import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalTimetableAssignFormModel>
	weekDay: WeekDaysEnum
	assignType: 'spot' | 'recurrency'
	beginHour: string
	endHour: string
	setBeginHour: React.Dispatch<React.SetStateAction<string>>
	setEndHour: React.Dispatch<React.SetStateAction<string>>
}

/**
 */
export function TimeRangeMedicalTimetableAssignCP(props: ICPProps): JSX.Element {
	const map: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = props.formStateManager.getFieldValue(props.assignType)
	const configOnDay = map?.get(props.weekDay)

	function onChangeTimeRange(value: string, fieldName: string): void {
		if (!configOnDay) {
			console.log('ERRO: Indice no mapa dos valores nao encontrado')
			return
		}

		if (fieldName === 'beginHour') {
			configOnDay.beginHour = value
			props.setBeginHour(configOnDay.beginHour)
			return
		}

		if (fieldName === 'endHour') {
			configOnDay.endHour = value
			props.setEndHour(configOnDay.endHour)
			return
		}
	}

	return (
		<RowCP>
			<ColumnCP size={12}>
				<InputCP
					label={'Hora Inicial'}
					mask={InputMaskTypeEnum.HOUR}
					keepMask={true}
					value={props.beginHour}
					onChange={(value) => onChangeTimeRange(value, 'beginHour')}
				/>
			</ColumnCP>
			<ColumnCP size={12}>
				<InputCP
					label={'Hora Final'}
					mask={InputMaskTypeEnum.HOUR}
					keepMask={true}
					value={props.endHour}
					onChange={(value) => onChangeTimeRange(value, 'endHour')}
				/>
			</ColumnCP>
		</RowCP>
	)
}
