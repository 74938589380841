import React, { useEffect, useState } from 'react'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import { HeaderScreenExamsCP } from 'modules/exams/screens/screen-exams/header/HeaderScreenExamsCP'
import * as _ from 'lodash'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { ScreenExamsUtils } from 'modules/exams/screens/screen-exams/utils/ScreenExamsUtils'
import { ScreenContentExams } from 'modules/exams/screens/screen-exams/content/ScreenContentExams'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

export const EXAM_SCREEN_FILTERS_PARAM = 'filters'

/**
 */
export function ScreenExams(props: RouteComponentProps): JSX.Element {
	// Parametros da ROTA '/'
	const routeParams = _.get(props, 'match.params') as { examStatusGroup: ExamStatusGroupEnum }
	const routeLocation = useLocation()

	const queryFiltersStr = new URLSearchParams(routeLocation.search).get(EXAM_SCREEN_FILTERS_PARAM)
	let queryFilters: ExamSearchRequestDTO | undefined
	if (!!queryFiltersStr) queryFilters = JSON.parse(queryFiltersStr)

	const [formValidator, setFormValidator] = useState<ExamFilterFormModel>()
	const formStateManager = useFormStateManager<ExamFilterFormModel>(formValidator)

	const [isScreenInitialized, setIsScreenInitialized] = useState<boolean>(false)
	const [loadList, setLoadList] = useState<number>(0)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	useEffect(init, [routeParams.examStatusGroup, queryFiltersStr])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setIsScreenInitialized(false)

		// Monta os filtros
		const examFilterFormModel = ExamFiltersFormModelConverter.formatFormModel(queryFilters, routeParams.examStatusGroup)
		setFormValidator(examFilterFormModel)

		// Para garantir que ira resetar os campos do filtro
		SystemUtils.sleep(100).then(() => {
			setLoadList(TableUtils.getReloadNumber())
			setIsScreenInitialized(true)
		})
	}

	if (!formStateManager.getFormValues()) return <LoadingOverlayCP show={true} />

	return (
		<AccessControlCP
			permission={{
				byHasAnyClinicRelationPermission: ScreenExamsUtils.getExamScreenPermission(routeParams.examStatusGroup),
			}}
			redirect={{ shouldRedirect: true, redirectTo: PrivateUserRouter.HOME }}
		>
			<LayoutCP
				titleBrowser={'Exames'}
				header={
					<HeaderScreenExamsCP
						examStatusGroup={routeParams.examStatusGroup}
						filterStateManager={formStateManager}
						onRequestToReloadList={() => setLoadList(TableUtils.getReloadNumber())}
					/>
				}
				content={
					<ScreenContentExams
						isScreenInitialized={isScreenInitialized}
						examStatusGroup={routeParams.examStatusGroup}
						filterStateManager={formStateManager}
						examsTable={{
							onLoading: setIsLoadingList,
							isLoading: isLoadingList,
							reloadTable: loadList,
							onRequestToReloadTable: () => setLoadList(TableUtils.getReloadNumber()),
						}}
						originalExamFilters={queryFilters}
					/>
				}
			/>
		</AccessControlCP>
	)
}
