import { InputMaskTypeEnum } from '_old/main/common/enums/InputMaskTypeEnum'
import { MaskUtils } from '_old/main/common/utils/MaskUtils'

import React from 'react'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import styled from 'styled-components'

interface IClinicInfoICPProps {
	clinic: ClinicResponseDTO
}

/**
 * Informações da clínica
 */
export function ClinicInfoICP(props: IClinicInfoICPProps): JSX.Element {
	return (
		<GroupInfoSCP>
			<TextAndTitleCP title={'Responsável:'} text={props.clinic.responsible.name} />
			<TextAndTitleCP title={'Email:'} text={props.clinic.responsible.email} />
			<TextAndTitleCP title={'Telefone:'} text={`${MaskUtils.applyMask(props.clinic.responsible.phoneOne, InputMaskTypeEnum.PHONE) ?? '-'}`} />
			<TextAndTitleCP title={'Cidade/UF:'} text={`${props.clinic.address?.city ?? '-'} / ${props.clinic.address?.state ?? '-'}`} />
		</GroupInfoSCP>
	)
}

const GroupInfoSCP = styled.div`
	margin-bottom: 20px;
`
