import React from 'react'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/enums/TemplateTypeEnum'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/dtos/response/TemplateResponseDTO'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/TemplatesRequests'
import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { MentionFromApiCP } from 'submodules/nerit-framework-ui/common/components/form-fields/mention/MentionFromApiCP'
import styled from 'styled-components'

export function OitSection4CICP(props: IOitSectionProps): JSX.Element {
	/**
	 * Ao mudar o valor
	 */
	function onChange(option: string): void {
		if (!option) return

		props.formStateManager.changeFieldValue('answer4C', option.replace('@', ''))
	}

	return (
		<RowCP>
			<OitSectionContentICP title={'4C - Comentários'}>
				<ColumnCP size={24}>
					<MentionWrapperSCP>
						<MentionFromApiCP<TemplateResponseDTO>
							placeholder={'Digite @ para autocompletar as frases de laudo'}
							requestConfigTP={() =>
								TemplatesRequests.search({
									type: TemplateTypeEnum.PHRASE,
								})
							}
							renderMentionOption={(row) => ({
								label: row.name,
								value: row.content,
							})}
							prefix={'@'}
							value={props.formStateManager.getFieldValue('answer4C')}
							onChange={onChange}
						/>
					</MentionWrapperSCP>
				</ColumnCP>
			</OitSectionContentICP>
		</RowCP>
	)
}

const MentionWrapperSCP = styled.div`
	height: 100px;
`
