import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonDownloadMedicalReportCP } from 'modules/exams/components/medical-report/button-download-medical-report/ButtonDownloadMedicalReportCP'
import { ExamFileNamesUtils } from 'modules/exams/utils/ExamFileNamesUtils'
import { ButtonDoMedicalReportCP } from 'modules/exams/components/medical-report/button-do-medical-report/ButtonDoMedicalReportCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamListItemResponseDTO
	onChangeData: () => void
	searchExamDto?: ExamSearchRequestDTO
}

/**
 */
export function ColumnExamDoneActionsICP(props: ICPProps): JSX.Element {
	return (
		<ButtonGroupCP mode={'separeted'}>
			<ButtonDownloadMedicalReportCP
				fileName={ExamFileNamesUtils.getMedicalReportFileName([props.exam.code], props.exam)}
				size={'small'}
				digitalCertification={props.exam.digitalCertification}
				downloadedByConfig={{
					user: {
						examIds: [props.exam.id],
					},
				}}
				type={'primary'}
				showPrinter={true}
			/>

			<ButtonDoMedicalReportCP exam={props.exam} type={'default'} searchExamDto={props.searchExamDto} />

			<BasicStyleWrapperCP margin={{ left: 5 }}>
				<ButtonDownloadExamCP
					size={'small'}
					iconOnly={true}
					exam={{
						id: props.exam.id,
						imageData: props.exam.imageData,
						examDescription: props.exam.description,
						patientName: props.exam.patient.name,
					}}
					downloadBy={'user'}
				/>
			</BasicStyleWrapperCP>

			<ButtonExamDetailsCP examId={props.exam.id} onSave={props.onChangeData} />
		</ButtonGroupCP>
	)
}
