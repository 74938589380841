import { ColumnProps } from 'antd/lib/table'
import { PersonDoctorListItemResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonDoctorListItemResponseDTO'
import { TagCP } from '_old/main/common/components/tag/TagCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import React from 'react'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export const DoctorTableUtils = {
	getColumns(): Array<ColumnProps<PersonDoctorListItemResponseDTO>> {
		return [
			{
				title: 'Ativo?',
				render: (text, item) => <TagCP content={item.active ? 'Ativo' : 'Desativado'} color={item.active ? 'green' : 'red'} />,
			},
			{
				title: 'Nome',
				render: (text, item) => (
					<>
						<TextCP text={`${item.treatment ?? ''} ${item.name}`} />
						<TextCP text={item.specialty} color={ThemeProject.gray300} />
					</>
				),
			},
			{
				title: 'Telefone',
				render: (text, item) => (
					<>
						<TextCP text={MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, item.phone)} />
						<TextCP text={item.email} size={'small'} color={ThemeProject.gray300} />
					</>
				),
			},
			{
				title: 'Registro',
				render: (text, item) => `${item.idType ?? ''}: ${item.id}`,
			},
		]
	},
}
