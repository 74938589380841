import React, { useState } from 'react'
import { ModalExamAssignCP } from 'modules/exams/components/exams/modal-exam-assign/ModalExamAssignCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { ExamAssignResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamsAssignResponseDTO'
import { ModalExamAssignFailedCP } from 'modules/exams/components/exams/modal-exam-assign-failed/ModalExamAssignFailedCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	selectedExamCodes: number[]
	doctorGroupCode: number
	assignedDoctorCodes?: number[]
	onSave: (response?: ExamAssignResponseDTO) => void
	appearance?: {
		size?: ButtonSizeTP
		onlyIcon?: boolean
		buttonType?: ButtonTypeTP
	}
}

/**
 * Barra com botoes de acao em massa para listagem de exame.
 */
export function ButtonAssignExamCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	const [examAssignFailed, setExamAssignFailed] = useState<ExamAssignResponseDTO>()
	const [isModalExamAssignFailedVisible, setIsModalExamAssignFailedVisible] = useState<boolean>()

	// Tem que estar atrelado em algum grupo para exibir esse botao
	if (!DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin', 'isDoctorAdmin', 'isDoctorGroupUser'])) return <></>

	let tooltip = props.appearance?.onlyIcon ? 'Atribuir' : undefined
	if (!props.doctorGroupCode) tooltip = 'Só é possível atribuir quando selecionado um grupo de médicos'

	return (
		<>
			<ButtonCP
				icon={ThemeProjectCommon.icons.assignExam}
				onClick={() => setIsModalVisible(true)}
				marginRight={5}
				type={props.appearance?.buttonType ?? 'primary'}
				size={props.appearance?.size}
				tooltip={tooltip}
				disabled={!props.doctorGroupCode}
			>
				{props.appearance?.onlyIcon ? undefined : 'Atribuir'}
			</ButtonCP>

			<ModalExamAssignCP
				isVisible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				examCodes={props.selectedExamCodes}
				doctorGroupCode={props.doctorGroupCode}
				assignedDoctorCodes={props.assignedDoctorCodes}
				onSave={(examAssignFailed) => {
					setIsModalVisible(false)
					setExamAssignFailed(examAssignFailed)
					//se retornou algo, abre o ModalExamAssignFailed, se não, executa props.onSavel()
					ArrayUtils.isEmpty(examAssignFailed?.failedAttribuitions) ? props.onSave() : setIsModalExamAssignFailedVisible(true)
				}}
			/>

			<ModalExamAssignFailedCP
				examAssignFailed={examAssignFailed}
				isVisible={isModalExamAssignFailedVisible}
				onClose={() => {
					setIsModalExamAssignFailedVisible(false)
					props.onSave()
				}}
			/>
		</>
	)
}
