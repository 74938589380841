import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { AssignExamsRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/AssignExamsRequestDTO'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { SelectTeamCP } from 'modules/doctor-groups/components/teams/select-team/SelectTeamCP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'
import { ExamAssignResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamsAssignResponseDTO'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface ICPProps {
	isVisible: boolean
	onCancel: () => void
	onSave: (response?: ExamAssignResponseDTO) => void
	examCodes: number[]
	assignedDoctorCodes?: number[]
	doctorGroupCode: number
	teamCode?: number
}

/**
 * Modal para alocar múltiplos exames para um médico.
 */
export function ModalExamAssignCP(props: ICPProps): JSX.Element {
	const [selectedDoctorCodes, setSelectedDoctorCodes] = useState<number[]>()
	const [selectedTeamCode, setSelectedTeamCode] = useState<number>()

	const assignRequest = useRequest<ExamAssignResponseDTO>()
	useEffect(onAssingRequestChange, [assignRequest.isAwaiting])

	useEffect(init, [props.isVisible])

	/**
	 */
	function init(): void {
		if (!props.isVisible) return

		if (!!props.assignedDoctorCodes) setSelectedDoctorCodes([...props.assignedDoctorCodes])

		setSelectedTeamCode(props.teamCode)
	}

	/**
	 * Atribui os exames.
	 */
	function assignExams(): void {
		const dto: AssignExamsRequestDTO = {
			examCodes: props.examCodes,
			doctorCodes: selectedDoctorCodes,
		}
		assignRequest.runRequest(ExamsRequests.assign(dto))
	}

	/**
	 * Retorno da requisicao de atribuir.
	 */
	function onAssingRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(assignRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE)) return

		//Se a requisição for válida, mas o countSuccessAttribuitions for 0, retorna mensagem de error
		if (assignRequest.responseData?.countSuccessAttribuitions === 0)
			RequestUtils.handleError(assignRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE)
		/**
		 * Se o count success for diferente de 0, significa que pelo menos um exame foi atribuído e exibe a mensagem de salvo com sucesso,
		 * E apresenta os erros caso haja algum
		 */ else NotificationHelper.success('Pronto!', NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE)

		props.onSave(assignRequest.responseData)
	}

	return (
		<>
			<ModalCP
				visible={props.isVisible}
				onCancel={props.onCancel}
				title={'Alocar exames'}
				onOk={assignExams}
				actionLoading={assignRequest.isAwaiting}
				width={450}
			>
				<TooltipCP text={!!props.teamCode ? 'Desabilitado, pois o time foi selecionado nos filtros do exame' : undefined} showSpan={true}>
					<SelectTeamCP
						doctorGroupCode={props.doctorGroupCode}
						value={selectedTeamCode}
						onChange={(value) => {
							setSelectedTeamCode(value)
							setSelectedDoctorCodes([])
						}}
						label={'Time'}
						disabled={!!props.teamCode}
						allowClear={true}
						hint={{ type: 'text', text: 'Filtre os médicos por um time' }}
					/>
				</TooltipCP>

				<SelectDoctorGroupPeopleCP
					role={UserTypeEnum.DOCTOR}
					namesConfig={{
						showSpecialty: true,
						showTeam: true,
					}}
					onChange={(codes) => setSelectedDoctorCodes(codes as number[])}
					isMultiple={true}
					value={selectedDoctorCodes}
					doctorGroupCode={props.doctorGroupCode}
					filters={{
						teamCode: selectedTeamCode,
						status: [UserDoctorGroupStatusEnum.ACTIVE],
					}}
					hint={{
						text: 'Para deixar sem atribuição, basta remover os médicos e salvar com o campo vazio.',
						type: 'text',
					}}
				/>
			</ModalCP>
		</>
	)
}
