import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalDoctorProfileDataCP } from 'modules/person/components/doctor/modal-doctor-data/ModalDoctorProfileDataCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'

interface ICPProps {
	doctor: PersonResponseDTO
	onChangeData: () => void
	appearance?: {
		size?: ButtonSizeTP
		hideText?: boolean
		type?: ButtonTypeTP
		icon?: string
	}
	fieldConfig?: {
		showHasDigitalCertificate?: boolean
	}
}

/**
 */
export function ButtonEditDoctorDataCP(props: ICPProps): JSX.Element {
	const [isModalPersonDataVisible, setIsModalPersonDataVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP
				onClick={() => setIsModalPersonDataVisible(true)}
				icon={props.appearance?.icon ?? 'edit'}
				type={props.appearance?.type ?? 'primary'}
				size={props.appearance?.size}
			>
				{props.appearance?.hideText ? undefined : 'Editar'}
			</ButtonCP>

			<ModalDoctorProfileDataCP
				visible={isModalPersonDataVisible}
				onClose={(dataChanged) => {
					setIsModalPersonDataVisible(false)
					if (dataChanged) props.onChangeData()
				}}
				person={props.doctor}
				fieldConfig={props.fieldConfig}
			/>
		</>
	)
}
