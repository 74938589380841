export enum UserDoctorGroupStatusEnum {
	AWAITING = 'AWAITING',
	REFUSED = 'REFUSED',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum UserDoctorGroupStatusLabelEnum {
	AWAITING = 'AGUARDANDO',
	REFUSED = 'RECUSADO',
	ACTIVE = 'ATIVO',
	INACTIVE = 'INATIVO',
}
