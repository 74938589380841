import React, { useEffect } from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormClinicMainDataCP } from 'modules/clinic/components/form-clinic/inner/FormClinicMainDataCP'
import { FormClinicAdminDataICP } from 'modules/clinic/components/form-clinic/inner/FormClinicAdminDataICP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicFormModelConverter } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModelConverter'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { AddressFormModel } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormModel'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { FormClinicTechnicalDataICP } from 'modules/clinic/components/form-clinic/inner/FormClinicTechnicalDataICP'

interface ICPProps {
	code?: number
	clinicFormStateManager: IFormStateManager<ClinicFormModel>
	addressFormStateManager: IFormStateManager<AddressFormModel>
	onSave: (clinicCode: number) => void
}

/**
 * Formulário das informações da clínica
 */
export function FormClinicCP(props: ICPProps): JSX.Element {
	const loggedUser = AppStateUtils.getLoggedUser()!

	const saveClinicRequest = useRequest<ClinicResponseDTO>()
	useEffect(onSaveClinicRequestChange, [saveClinicRequest.isAwaiting])

	/**
	 * Salva clinica
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(props.clinicFormStateManager))) return

		const clinicFormValues = props.clinicFormStateManager.getFormValues()!
		const addressFormValues = props.addressFormStateManager.getFormValues()!

		const dto = ClinicFormModelConverter.formatDto(clinicFormValues, addressFormValues)

		if (!!props.code) saveClinicRequest.runRequest(ClinicsRequests.update(props.code, dto))
		else saveClinicRequest.runRequest(ClinicsRequests.create(dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveClinicRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveClinicRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		props.onSave(saveClinicRequest.responseData!.code)
	}

	return (
		<>
			{loggedUser?.isBeeradsAdmin ? (
				<TabsCP
					tabs={[
						{
							title: 'Dados Básicos',
							key: 'basic',
							content: (
								<FormClinicMainDataCP
									clinicFormStateManager={props.clinicFormStateManager}
									addressFormStateManager={props.addressFormStateManager}
									canEditSlug={true}
									showSlug={!!props.code}
								/>
							),
						},
						{
							title: 'Dados Avançados',
							key: 'advanced',
							content: <FormClinicAdminDataICP clinicFormStateManager={props.clinicFormStateManager} />,
						},
						{
							title: 'Dados da Instalação (TI)',
							key: 'tech',
							disabled: true,
							content: <FormClinicTechnicalDataICP formStateManager={props.clinicFormStateManager} />,
						},
					]}
				/>
			) : (
				<FormClinicMainDataCP
					clinicFormStateManager={props.clinicFormStateManager}
					addressFormStateManager={props.addressFormStateManager}
					canEditSlug={!props.code}
					showSlug={!!props.code}
				/>
			)}

			<FlexCP margin={{ top: 30 }} justifyContent={'flex-end'}>
				<ButtonCP type={'primary'} onClick={save} loading={saveClinicRequest.isAwaiting}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
