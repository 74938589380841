import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { ClinicDoctorGroupGetLearnedDescriptionRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupGetLearnedDescriptionRequestDTO'
import { ClinicDoctorGroupGetUrgencyTypesRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupGetUrgencyTypesRequestDTO'
import { ClinicDoctorGroupAddTeamsRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAddTeamsRequestDTO'
import { ClinicDoctorGroupLearnedDescriptionResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupLearnedDescriptionResponseDTO'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ClinicDoctorGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupSaveRequestDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { ClinicDoctorGroupAddDoctorRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAddDoctorRequestDTO'
import { ClinicDoctorGroupSearchRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupSearchRequestDTO'
import { ClinicDoctorGroupListItemResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupListItemResponseDTO'
import { ClinicDoctorGroupInviteRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupInviteRequestDTO'
import { ClinicDoctorGroupAnswerInviteRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAnswerInviteRequestDTO'

export class ClinicDoctorGroupRequests {
	public static CONTROLLER_ROOT = 'clinic-doctor-group'
	public static SEARCH_EP = 'search'
	public static CREATE_EP = 'create/:clinicCode/:doctorGroupCode'
	public static UPDATE_EP = 'update/:code'
	public static GET_ONE_EP = 'get/:clinicCode/:doctorGroupCode'
	public static SEARCH_EXAM_URGENCY_TYPES_EP = 'search-urgency-types'
	public static GET_LEARNED_EXAM_DESCRIPTION_EP = 'get-learned-exam-description'
	public static GET_TEAMS_EP = 'get-teams/:code'
	public static ADD_TEAMS_EP = 'add-teams/:code'
	public static ADD_DOCTOR_EP = 'add-doctor/:code'
	public static INVITE_EP = 'invite'
	public static ANSWER_INVITE_EP = 'answer-invite'

	static search = (dto: ClinicDoctorGroupSearchRequestDTO): RequestConfigTP<ListResponseDTO<ClinicDoctorGroupListItemResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static searchUrgencyTypes = (dto: ClinicDoctorGroupGetUrgencyTypesRequestDTO): RequestConfigTP<ListResponseDTO<ExamUrgencyTypeEnum>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.SEARCH_EXAM_URGENCY_TYPES_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static create = (clinicCode: number, doctorGroupCode: number, dto: ClinicDoctorGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.CREATE_EP.replace(':clinicCode', clinicCode.toString()).replace(
			':doctorGroupCode',
			doctorGroupCode.toString(),
		)}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: ClinicDoctorGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static getLearnedExamDescriptions = (
		dto: ClinicDoctorGroupGetLearnedDescriptionRequestDTO,
	): RequestConfigTP<ClinicDoctorGroupLearnedDescriptionResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.GET_LEARNED_EXAM_DESCRIPTION_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static getOne = (clinicCode: number, doctorGroupCode: number): RequestConfigTP<any> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.GET_ONE_EP.replace(':clinicCode', clinicCode.toString()).replace(
			':doctorGroupCode',
			doctorGroupCode.toString(),
		)}`,
		method: HttpMethodEnum.GET,
	})

	static getTeams = (code: number): RequestConfigTP<ListResponseDTO<NameAndCodeResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.GET_TEAMS_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static addTeams = (code: number, dto: ClinicDoctorGroupAddTeamsRequestDTO): RequestConfigTP<ListResponseDTO<NameAndCodeResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.ADD_TEAMS_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static addDoctor = (code: number, dto: ClinicDoctorGroupAddDoctorRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.ADD_DOCTOR_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static invite = (dto: ClinicDoctorGroupInviteRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.INVITE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static answerInvite = (dto: ClinicDoctorGroupAnswerInviteRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ClinicDoctorGroupRequests.CONTROLLER_ROOT}/${ClinicDoctorGroupRequests.ANSWER_INVITE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})
}
