import React from 'react'
import { MedicalReportToolbarUtils } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/MedicalReportToolbarUtils'
import { IToolbarDefaultBtnProps } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/IToolbarDefaultBtnProps'
import { RenderButtonFunctionTP } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorTypes'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps extends IToolbarDefaultBtnProps {
	examModality?: ExamModalityEnum
	hideAddPhraseButton?: boolean
}

/**
 * Barra de ferramentas superior fixa do editor de texto wysiwyg customizada.
 */
export function ToolbarICP(props: ICPProps): JSX.Element {
	return (
		<ContainerSCP>
			{MedicalReportToolbarUtils.getButtons(props.examModality, props.hideAddPhraseButton)
				.filter((buttonGroup) => !buttonGroup.hide)
				.map((buttonsGroup, index) => (
					<ButtonGroupSCP key={index}>
						{buttonsGroup.buttons.map((renderButton: RenderButtonFunctionTP<IToolbarDefaultBtnProps>) => renderButton(props))}
					</ButtonGroupSCP>
				))}
		</ContainerSCP>
	)
}

const ContainerSCP = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	height: 50px;
	width: 100%;
	border-bottom: 1px solid #e0e0e0;
	box-shadow: 0 1px 7px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}80;
	position: absolute;
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	@media screen and (max-width: 549px) {
		height: 100px;
	}
`

const ButtonGroupSCP = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	height: 65%;

	&:not(:last-of-type) {
		@media screen and (min-width: 549px) {
			border-right: 1px solid #cac9c9;
		}
	}

	@media screen and (max-width: 549px) {
		height: 50%;
	}
`
