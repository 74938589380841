export const MEDICAL_REPORT_MALE_WORDS = ['prostata', 'próstata', 'seminais', 'prostática', 'prostatica', 'periprostática', 'periprostatica', 'seminal']

export const MEDICAL_REPORT_FEMALE_WORDS = [
	'ovario',
	'ovário',
	'útero',
	'utero',
	'uterino',
	'uterina',
	'periuterina',
	'periuterino',
	'parauterino',
	'parauterina',
	'perituterinas',
	'ovariana',
	'ovarianas',
	'ovários',
	'ovarios',
	'ovariano',
	'periovariano',
]
