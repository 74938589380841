import { Statistic } from 'antd'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { LoadingCP } from 'submodules/nerit-framework-ui/common/components/loading/basic/LoadingCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IStatisticCardCPProps {
	title?: string
	value?: number | string
	precision?: number
	valueStyle?: CSSProperties
	prefix?: JSX.Element
	suffix?: JSX.Element
	fontSize?: string
	fontWeight?: number
	color?: string
	loading?: boolean
}

/**
 * Card para exibição de statisticas
 */
export function CardNpsCP(props: IStatisticCardCPProps): JSX.Element {
	return (
		<StatisticsWrapperSCP style={{ color: props.color }}>
			{props.prefix && <PrefixIconWrapper>{props.prefix}</PrefixIconWrapper>}
			{props.loading ? (
				<LoadingCP />
			) : (
				<InfoWrapperSCP>
					<Statistic
						title={props.title}
						value={props.value}
						precision={props.precision}
						valueStyle={{ ...props.valueStyle }}
						suffix={props.suffix}
					/>
				</InfoWrapperSCP>
			)}
		</StatisticsWrapperSCP>
	)
}

const StatisticsWrapperSCP = styled.div<{ style: CSSProperties }>`
	display: flex;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
	border-radius: 8px;
	margin: 0 20px;
	height: 100px;
	position: relative;
	&::before {
		content: '';
		width: 5px;
		height: 100%;
		border-radius: 8px 0 0 8px;
		top: 0;
		left: 0;
		position: absolute;
		background: ${(props): string | undefined => props.style.color};
	}

	.ant-statistic-content-value-int {
		font-weight: 600;
	}
`

const PrefixIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 35px 0 15px;

	&::after {
		content: '';
		width: 2px;
		height: 46%;
		top: 0;
		border-radius: 2px;
		bottom: 0;
		margin: auto 0;
		left: 6em;
		position: absolute;
		background: ${ThemeProject.gray100};
	}
`

const InfoWrapperSCP = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	padding-right: 15px;

	.ant-statistic {
		line-height: 1.2;

		.ant-statistic-content {
			font-weight: 600;
			font-size: 18px;
			color: ${ThemeProject.gray200};
		}

		.ant-statistic-title {
			font-weight: 500;
			font-size: 13px;
			color: ${ThemeProject.primary};
		}
	}
`
