import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import {
	ExamReasonOccupationalEnum,
	ExamReasonOccupationalLabelEnum,
	ExamReasonVeterinaryEnum,
	ExamReasonVeterinaryLabelEnum,
} from 'submodules/beerads-sdk/services/exams/exams/enums/ExamReasonOccupationalEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps<FModelTP extends FormModel> extends IFormItemCommonProps<FModelTP> {
	type: ClinicTypeEnum
}

/**
 * COMPONENTE Seletor de Motivo de realizar o exame
 */
export function SelectExamReasonCP<FModelTP extends FormModel>(props: ICPProps<FModelTP>): JSX.Element {
	let options: SelectOptionTP[] = []

	if (props.type === ClinicTypeEnum.OCUP) {
		options = Object.values(ExamReasonOccupationalEnum).map((reason) => ({
			value: reason,
			label: ExamReasonOccupationalLabelEnum[reason],
		}))
	}

	if (props.type === ClinicTypeEnum.VET) {
		options = Object.values(ExamReasonVeterinaryEnum).map((reason) => ({
			value: reason,
			label: ExamReasonVeterinaryLabelEnum[reason],
		}))
	}

	if (ArrayUtils.isEmpty(options)) return <></>

	return <SelectCP options={options} label={'Motivo'} formStateManager={props.formStateManager} fieldName={props.fieldName} required={props.required} />
}
