import React, { useEffect, useState } from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { AddressFormValidator } from '_old/main/common/validators/AddressFormValidator'
import { FormClinicCP } from 'modules/clinic/components/form-clinic/FormClinicCP'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ClinicFormModelConverter } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModelConverter'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'

interface ICPProps {
	onSave: (clinicCode: number) => void
	initialData?: {
		cnpj?: string
		clinic?: ClinicResponseDTO
	}
}

/**
 * Etapa de criacao da clinica
 */
export function CreateClinicStepICP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<ClinicFormModel>(new ClinicFormModel())
	const clinicFormStateManager = useFormStateManager<ClinicFormModel>(formValidator)

	const [addressFormValidator, setAddressFormModel] = useState<AddressFormValidator>(new AddressFormValidator())
	const addressFormStateManager = useFormStateManager<AddressFormValidator>(addressFormValidator)

	useEffect(init, [])

	/**
	 * Ao inicia.
	 */
	function init(): void {
		if (!!props.initialData?.clinic) {
			setFormValidator(ClinicFormModelConverter.formatFormModel(props.initialData.clinic))
			setAddressFormModel(AddressFormUtils.formatFormModel(props.initialData.clinic.address))
			return
		}

		setFormValidator(
			new ClinicFormModel({
				active: true,
				type: ClinicTypeEnum.TRAD,
				cnpj: props.initialData?.cnpj,
			}),
		)
	}

	return (
		<FormClinicCP
			code={props.initialData?.clinic?.code}
			clinicFormStateManager={clinicFormStateManager}
			addressFormStateManager={addressFormStateManager}
			onSave={props.onSave}
		/>
	)
}
