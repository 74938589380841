import React from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { RawHtmlCP } from 'submodules/nerit-framework-ui/common/components/raw-html/RawHtmlCP'
import { WrapperMedicalReportEditorCP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorCP'
import { WrapperMedicalReportEditorContentAreaICP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorContentAreaICP'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onCopy?: () => void
	medicalReportContent?: MedicalReportContentTP
}

/**
 */
export function ModalMedicalReportContentCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP visible={props.visible} onCancel={props.onCancel} title={'Conteúdo do Laudo'} width={900} top={10} noFooter={true}>
			{!!props.medicalReportContent ? (
				<>
					<WrapperMedicalReportEditorCP>
						{!!props.onCopy && (
							<ButtonWrapper>
								<ButtonCP
									icon={'copy'}
									onClick={() => {
										NotificationHelper.success('Yes', 'Copiado')
										props.onCopy!()
									}}
								>
									Copiar e Colar laudo
								</ButtonCP>
							</ButtonWrapper>
						)}
						<WrapperMedicalReportEditorContentAreaICP>
							<RawHtmlCP html={props.medicalReportContent.html} />
						</WrapperMedicalReportEditorContentAreaICP>
					</WrapperMedicalReportEditorCP>
				</>
			) : (
				'Laudo sem conteúdo'
			)}
		</ModalCP>
	)
}

const ButtonWrapper = styled.div`
	position: absolute;
	width: 100%;
	margin-top: 10px;
	justify-content: center;
	display: flex;
`
