import { IsOptional } from 'class-validator'
import { DoctorIdTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorIdTypeEnum'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsValidCpf } from 'submodules/nerit-framework-utils/validators/decorators/IsValidCpf'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'

export class DoctorRegisterFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsValidCpf()
	@IsRequired()
	cpf: string

	@IsRequired()
	@IsEmail()
	email: string

	@IsRequired()
	@IsPhoneBR()
	phoneOne?: string

	@IsOptional()
	specialty?: string

	@IsOptional()
	birthDate?: string

	@IsRequired()
	crm: string

	@IsRequired()
	idType: DoctorIdTypeEnum

	@IsRequired()
	treatment: string

	@IsRequired()
	password: string

	@IsRequired()
	confirmPassword: string

	constructor(dto?: any) {
		super(dto)
		Object.assign(this, dto)
	}
}
