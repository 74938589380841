import React, { useState } from 'react'
import { ScreenWrapperQualityAssurance } from 'modules/quality-assurance/components/screen-wrapper-quality-assurance/ScreenWrapperQualityAssurance'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { WidgetCountAuditsOpenCP } from 'modules/reports/components/widgets/widget-count-audits-open/WidgetCountAuditsOpenCP'
import { TableAuditsCP } from 'modules/quality-assurance/components/table-audits/TableAuditsCP'
import { WidgetsRequests } from 'submodules/beerads-sdk/services/dashboard/widgets/WidgetsRequests'
import { FiltersAuditsCP } from 'modules/quality-assurance/components/filters-audits/FiltersAuditsCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { AuditFilterFormModel } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModel'
import { CardWidgetOnlyValuesShouldLoadCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values-should-load/CardWidgetOnlyValuesShouldLoadCP'
import { AuditFilterFormModelConverter } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModelConverter'
import { AuditStatusEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

/**
 */
export function ScreenQualityAssuranceAudits(): JSX.Element {
	const [formValidator] = useState<AuditFilterFormModel>(new AuditFilterFormModel())
	const formStateManager = useFormStateManager<AuditFilterFormModel>(formValidator)

	const [loadList, setLoadList] = useState<number>(1)
	const [isLoadingList, setIsLoadingList] = useState<boolean>(false)

	return (
		<ScreenWrapperQualityAssurance headerTitle={'Central de Qualidade - Auditorias'} appearance={{ hideMargin: true }}>
			{!!AppStateUtils.getCurrentDoctorGroup() && (
				<>
					<FiltersAuditsCP
						doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
						filterFormStateManager={formStateManager}
						isLoading={isLoadingList}
						onFilter={() => {
							setLoadList(TableUtils.getReloadNumber())
							setIsLoadingList(true)
						}}
					/>

					<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
						<WidgetCountAuditsOpenCP
							doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
							shouldLoad={loadList}
							filters={AuditFilterFormModelConverter.convertToDto(formStateManager, AppStateUtils.getCurrentDoctorGroup()!.code)}
						/>
						<CardWidgetOnlyValuesShouldLoadCP
							main={{
								title: 'Auditorias Realizadas',
								help: 'Número total de auditorias realizadas',
							}}
							shouldLoad={loadList}
							requestConfigGetter={WidgetsRequests.countAudits({
								...AuditFilterFormModelConverter.convertToDto(formStateManager, AppStateUtils.getCurrentDoctorGroup()!.code),
								statuses: [AuditStatusEnum.DONE],
							})}
						/>
						<CardWidgetOnlyValuesShouldLoadCP
							main={{
								title: 'Score Médio',
								help: 'Média das notas das auditorias',
							}}
							shouldLoad={loadList}
							requestConfigGetter={WidgetsRequests.averageAuditsScore({
								...AuditFilterFormModelConverter.convertToDto(formStateManager, AppStateUtils.getCurrentDoctorGroup()!.code),
								statuses: [AuditStatusEnum.DONE],
							})}
						/>
					</WidgetsRowCP>

					<TableAuditsCP
						reloadTable={loadList}
						onLoading={setIsLoadingList}
						filters={AuditFilterFormModelConverter.convertToDto(formStateManager, AppStateUtils.getCurrentDoctorGroup()!.code)}
					/>
				</>
			)}
		</ScreenWrapperQualityAssurance>
	)
}
