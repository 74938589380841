import React from 'react'
import { SelectOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/inner/types/SelectOptionTP'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { UfEnum } from 'submodules/nerit-framework-utils/utils/enums/UfEnum'

const OPTIONS: SelectOptionTP[] = Object.values(UfEnum).map((uf, i) => ({
	key: `key-uf-${i}`,
	label: uf,
	value: uf,
}))

interface IUfSelectCPProps extends IFormItemCommonProps {
	loading?: boolean
}

/**
 * Select para estado brasileiros
 *
 * @author renatofs
 */
export function UfSelectCP(props: IUfSelectCPProps): JSX.Element {
	return (
		<SelectCP
			options={OPTIONS}
			label={'UF'}
			required={props.required}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			errorMessage={props.errorMessage}
			loading={props.loading}
		/>
	)
}
