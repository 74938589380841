import React from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { TitleBrowserCP } from 'submodules/nerit-framework-ui/common/components/title-browser/TitleBrowserCP'

interface ICPProps {
	pageName?: string
}

/**
 * Title da página no Browser
 */
export function BeeRadsTitleBrowserCP(props: ICPProps): JSX.Element {
	let pageName
	if (!!props.pageName) pageName = `${props.pageName} | ${AppStateUtils.getDomainName()}`
	else pageName = AppStateUtils.getDomainName(true)

	return <TitleBrowserCP pageName={pageName} />
}
