import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'

interface ICPProps {
	exam: ExamListItemResponseDTO
	onChangeData: () => void
}

/**
 */
export function ColumnExamArchivedActionsICP(props: ICPProps): JSX.Element {
	return (
		<ButtonGroupCP mode={'separeted'}>
			<ButtonExamDetailsCP examId={props.exam.id} onSave={props.onChangeData} />
		</ButtonGroupCP>
	)
}
