import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { WidgetsNpsScore } from 'submodules/space4leads-components-ui/report/components/widgets-nps-score/WidgetsNpsScore'
import { ProjectResponseDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/responses/ProjectResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ProjectsRequests } from 'submodules/space4leads-sdk/services/accounts/ProjectsRequests'
import { ProjectSearchRequestDTO } from 'submodules/space4leads-sdk/services/accounts/dtos/requests/ProjectSearchRequestDTO'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { EmptyCP } from 'submodules/nerit-framework-ui/common/components/empty/EmptyCP'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	accountCode: number
	dateRange?: DateRangeSearchRequestDTO
	reload?: number
}

/**
 */
export function CardsProjectsNpsCP(props: ICPProps): JSX.Element {
	const [projects, setProjects] = useState<ProjectResponseDTO[]>()

	const searchProjectsRequest = useRequest<ListResponseDTO<ProjectResponseDTO>>()
	useEffect(onSearchProjectsRequestChange, [searchProjectsRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const filters: ProjectSearchRequestDTO = {
			accountCode: props.accountCode,
		}
		searchProjectsRequest.runRequest(ProjectsRequests.search(filters))
	}

	/**
	 * Retorno ao buscar campanhas de CSAT.
	 */
	function onSearchProjectsRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchProjectsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setProjects(searchProjectsRequest.responseData?.list ?? [])
	}

	if (!projects) return <LoadingOverlayCP show={true} />

	if (ArrayUtils.isEmpty(projects)) return <EmptyCP description={'Nenhum projeto encontrado na conta informada'} />

	return (
		<WrapperSCP>
			<MainBoxScp>
				<TitleCP marginBottom={0}>Resumo de todos projetos</TitleCP>
				<WidgetsNpsScore
					reload={props.reload ?? 1}
					type={'account'}
					filters={{
						dateRange: props.dateRange,
						projectCode: projects[0].code, // Vai obter o resumo da conta, entao passando qqer projeto da conta está ok
					}}
				/>
			</MainBoxScp>

			{projects.map((project) => (
				<BasicStyleWrapperCP margin={{ bottom: 30 }} key={project.name}>
					<TitleCP marginBottom={0}>{project.name}</TitleCP>
					<WidgetsNpsScore
						reload={props.reload ?? 1}
						type={'project'}
						filters={{
							dateRange: props.dateRange,
							projectCode: project.code,
						}}
					/>
				</BasicStyleWrapperCP>
			))}
		</WrapperSCP>
	)
}

const MainBoxScp = styled.div`
	border: dashed 3px ${ThemeProject.gray300};
	padding: 10px;
	border-radius: 10px;
	background: #ffffa1;
	margin-bottom: 30px;
`

const WrapperSCP = styled.div`
	padding: 20px;
`
