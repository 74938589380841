import React from 'react'
import Intercom from 'react-intercom'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { AppConfig } from 'config/AppConfig'
import { EnvironmentEnum } from '_old/main/common/enums/EnvironmentEnum'

interface ICPProps {
	forceShow?: boolean
}

/**
 */
export function IntercomCP(props: ICPProps): JSX.Element {
	const loggedUser = AppStateUtils.getLoggedUser()
	const sistema = AppStateUtils.getDomain() === ProjectWhiteLabelsEnum.VX ? 'VX PACS' : 'VX PACS - BeeRads'

	if (AppConfig.getInstance().environment === EnvironmentEnum.DEV && !props.forceShow) return <></>

	return (
		<Intercom
			appID={'qxkjlonm'}
			user_id={loggedUser?.code}
			name={loggedUser?.name}
			email={loggedUser?.email}
			phone={`+55${loggedUser?.phone}`}
			job_title={loggedUser?.isDoctor ? 'Médico' : 'Usuário'}
			produto_vx={sistema}
			company={
				!!loggedUser
					? {
							id: loggedUser.isDoctor ? loggedUser.doctorGroups?.[0]?.code : loggedUser.clinics?.[0]?.code,
							name: loggedUser.isDoctor ? loggedUser.doctorGroups?.[0]?.name : loggedUser.clinics?.[0]?.name,
					  }
					: undefined
			}
		/>
	)
}
