import { AuthActions } from 'app/utils/AuthActions'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import React, { useEffect, useState } from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { ModalAuthDigitalCertificateCP } from 'modules/person/components/doctor/modal-auth-digital-certificate/ModalAuthDigitalCertificateCP'
import { IconProfileMenuItemICP } from 'app/components/menu-item-profile/inner/IconProfileMenuItemICP'
import { useLocation } from 'react-router-dom'
import { TransformUtils } from 'submodules/nerit-framework-utils/utils/TransformUtils'
import { ModalSpeedTest } from 'submodules/nerit-framework-ui/features/common/components/modal-speed-test/ModalSpeedTest'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

export const OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM = 'open-digital-certificate-auth'

/**
 */
export function MenuItemProfileCP(): JSX.Element {
	const [isDigitalCertificateModalVisible, setIsDigitalCertificateModalVisible] = useState<boolean>(false)
	const [isSpeedTestModalVisible, setIsSpeedTestModalVisible] = useState<boolean>(false)
	const [isRedirectModal, setIsRedirectModel] = useState<boolean>(false)

	const routeLocation = useLocation()
	const params = new URLSearchParams(routeLocation.search)
	useEffect(init, [params.get(OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM)])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const shouldOpenDigitalCerticate = TransformUtils.toBoolean({
			value: params.get(OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM),
		} as any)
		if (!shouldOpenDigitalCerticate) return

		if (
			!AppStateUtils.getLoggedUser()?.isDoctor ||
			!AppStateUtils.getLoggedUser()?.hasDigitalCertificate ||
			AppStateUtils.getAuthData()?.isLoggedWithDigitalCertificate
		)
			return

		setIsDigitalCertificateModalVisible(true)
	}

	return (
		<>
			<ProfileMenuItemCP
				profileImage={AppStateUtils.getLoggedUser()!.photoUrl}
				optionTitle={AppStateUtils.getLoggedUser()!.name}
				profileImageIcon={<IconProfileMenuItemICP />}
				profileOptions={[
					{
						text: 'Autenticar com Certificado Digital',
						action: () => setIsDigitalCertificateModalVisible(true),
						hidden: !AppStateUtils.getLoggedUser()?.isDoctor || !AppStateUtils.getAuthData()?.user?.hasDigitalCertificate,
					},
					{
						text: 'Sugerir melhorias no sistema',
						action: () => setIsRedirectModel(true),
					},
					{
						text: 'Teste de Velocidade',
						action: () => setIsSpeedTestModalVisible(true),
					},
					{
						text: 'Meu Perfil',
						action: () => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.PROFILE),
					},
					{
						text: 'Sair',
						action: () => {
							AuthActions.logout()
							NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
						},
					},
				]}
			/>

			<ModalSpeedTest visible={isSpeedTestModalVisible} onClose={() => setIsSpeedTestModalVisible(false)} />

			<ModalAuthDigitalCertificateCP
				onCancel={() => setIsDigitalCertificateModalVisible(false)}
				onSuccess={() => setIsDigitalCertificateModalVisible(false)}
				visible={isDigitalCertificateModalVisible}
			/>

			<ModalCP
				title={'Você será redirecionado a uma nova guia.'}
				visible={isRedirectModal}
				okText={'Continuar'}
				onClose={() => setIsRedirectModel(false)}
				onOk={() =>
					NeritFrameworkRoutingHelper.openInNewTab(
						'https://forms.office.com/pages/responsepage.aspx?id=Nb28VnWiLEiNTlWJecFNA64b3EjwcGdLvkqmR-MJ2vZURDJKV0NWV1JIUTAxNDZXUjNDNk5DSFNJOS4u',
						false,
					)
				}
			>
				<TextCP strong={true} text={'Deseja continuar?'} />
			</ModalCP>
		</>
	)
}
