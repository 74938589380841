import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'

export class MedicalReportFormModel extends FormModel {
	@IsRequired()
	medicalReports: MedicalReportContentTP[]

	@IsOptional()
	isCritical: boolean

	@IsOptional()
	wasConsideredContrast: boolean

	@IsOptional()
	rectificationClinicalImpact: boolean

	@IsOptional()
	revisionDoctorCode?: number

	/** Auxiliares. */
	selectedJsonContentToReplace: any

	constructor(initialData?: ObjectPropsTP<MedicalReportFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
