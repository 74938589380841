import React, { useEffect, useState } from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { TableMedicalTimetableDoctorUtils } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-doctor/inner/TableMedicalTimetableDoctorUtils'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { MedicalTimetableStructureSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSearchRequestDTO'
import { IMedicalTimetableStatistics } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableStatistics'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'

interface ICPProps {
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	reload?: number
}

/**
 */
export function TableMedicalTimetableDoctorCP(props: ICPProps): JSX.Element {
	const dataStatistics: IMedicalTimetableStatistics[] = []
	const [filteredStructure, setFilteredStructure] = useState<IMedicalTimetableRecord[]>([])
	const [isMounting, setIsMounting] = useState<boolean>(false)

	const [medicalTimetableStructure, setMedicalTimetableStructure] = useState<MedicalTimetableStructureResponseDTO[]>([])
	const getMedicalTimetableStructureRequest = useRequest<ListResponseDTO<MedicalTimetableStructureResponseDTO>>()
	useEffect(onGetMedicalTimetableStructureRequestChange, [getMedicalTimetableStructureRequest.isAwaiting])

	const [doctorsOnMedicalTimetable, setDoctorsOnMedicalTimetable] = useState<DoctorOnMedicalTimetableResponseDTO[]>([])
	const getDoctorsOnMedicalTimetableRequest = useRequest<ListResponseDTO<DoctorOnMedicalTimetableResponseDTO>>()
	useEffect(onGetDoctorsOnMedicalTimetableRequest, [getDoctorsOnMedicalTimetableRequest.isAwaiting])

	useEffect(mountTableRecordsFromStructure, [
		props.filterFormStateManager.getFieldValue('id'),
		props.filterFormStateManager.getFieldValue('period'),
		props.filterFormStateManager.getFieldValue('modality'),
		props.filterFormStateManager.getFieldValue('groupBy'),
	])
	useEffect(init, [props.reload])

	/**
	 */
	function init(): void {
		if (!AppStateUtils.getCurrentDoctorGroup()) {
			NotificationHelper.error('Ops', 'Selecione um grupo de médicos primeiro')
			return
		}

		const filters: MedicalTimetableStructureSearchRequestDTO = {
			doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()!.code,
			date: props.filterFormStateManager.getFieldValue('beginDate'),
		}
		getMedicalTimetableStructureRequest.runRequest(MedicalTimetableRequests.getStructure(filters))
		getDoctorsOnMedicalTimetableRequest.runRequest(MedicalTimetableRequests.searchDoctorsOnMedicalTimetable(filters))
	}

	/**
	 */
	function onGetMedicalTimetableStructureRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalTimetableStructureRequest, 'Erro ao buscar estrutura da escala')) return

		const result = getMedicalTimetableStructureRequest.responseData!.list
		setMedicalTimetableStructure(result)
		mountTableRecordsFromStructure(result)
	}

	/**
	 */
	function onGetDoctorsOnMedicalTimetableRequest(): void {
		if (!RequestUtils.isValidRequestReturn(getDoctorsOnMedicalTimetableRequest, 'Erro ao buscar médicos da escala')) return

		setDoctorsOnMedicalTimetable(getDoctorsOnMedicalTimetableRequest.responseData!.list)
	}

	/**
	 */
	function mountTableRecordsFromStructure(structureData?: MedicalTimetableStructureResponseDTO[]): void {
		setIsMounting(true)

		const filteredIds: MedicalTimetableIdEnum[] = props.filterFormStateManager.getFieldValue('id') ?? []
		const filteredPeriods: MedicalTimetablePeriodEnum[] = props.filterFormStateManager.getFieldValue('period') ?? []
		const filteredModalities = props.filterFormStateManager.getFieldValue('modality') ?? []
		const filteredGroupBy = props.filterFormStateManager.getFieldValue('groupBy') ?? []

		// Se não tiver enviado do parametro utiliza a do escopo, ja carregada anteriormente
		if (!structureData) structureData = medicalTimetableStructure

		const generatedTableRecords: IMedicalTimetableRecord[] = []
		structureData.forEach((structure) => {
			// Skipa em casos de filtros
			if (
				(!ArrayUtils.isEmpty(filteredIds) && !filteredIds.includes(structure.id)) ||
				(!ArrayUtils.isEmpty(filteredPeriods) && !filteredPeriods.includes(structure.period)) ||
				(!ArrayUtils.isEmpty(filteredModalities) && !filteredModalities.includes(structure.modality)) ||
				(!ArrayUtils.isEmpty(filteredGroupBy) && !filteredGroupBy.includes(structure.groupBy))
			)
				return

			let maxNumberOfRows = 0
			structure.schedules.forEach((dayStructure) => {
				if (dayStructure.numberOfRows > maxNumberOfRows) maxNumberOfRows = dayStructure.numberOfRows
			})

			for (let i = 0; i < maxNumberOfRows; i++) {
				generatedTableRecords.push({
					id: structure.id,
					period: structure.period,
					modality: structure.modality,
					groupBy: structure.groupBy,
				})
			}
		})

		// Precisa esperar para recarregar a tabela
		SystemUtils.sleep(10).then(() => {
			setIsMounting(false)
		})
		setFilteredStructure(generatedTableRecords)
	}

	if (isMounting) return <LoadingOverlayCP show={true} />

	return (
		<TableCP<IMedicalTimetableRecord>
			data={filteredStructure}
			wrappedOnCard={true}
			bordered={true}
			loading={getMedicalTimetableStructureRequest.isAwaiting || getDoctorsOnMedicalTimetableRequest.isAwaiting || isMounting}
			columns={TableMedicalTimetableDoctorUtils.getColumns(
				filteredStructure,
				dataStatistics,
				medicalTimetableStructure,
				doctorsOnMedicalTimetable,
				props.filterFormStateManager.getFieldValue('beginDate'),
				init,
			)}
		/>
	)
}
