import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { ButtonAnalysePendingExamCP } from 'modules/exams/components/exam-analysis/button-analyse-pending-exam/ButtonAnalysePendingExamCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonAnalysePendingRectificationExamCP } from 'modules/exams/components/exam-analysis/button-analyse-pending-rectification-exam/ButtonAnalysePendingRectificationExamCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

interface ICPProps {
	viewAsDoctor: boolean
	exam: ExamListItemResponseDTO
	onSave: () => void
}

/**
 * Coluna de Acoes de triagem.
 */
export function ColumnExamPendingAnalysisActionsICP(props: ICPProps): JSX.Element {
	const canSeeExamData = DoctorGroupPermissionUtils.canSeeExamData(props.viewAsDoctor, props.exam.clinic.code, props.exam.doctorGroupCode)

	return (
		<ButtonGroupCP mode={'separeted'}>
			{!!props.exam.doctorGroupCode && (
				<AccessControlCP
					permission={{
						byHasAnyPermissionInDoctorGroup: {
							doctorGroupCode: props.exam.doctorGroupCode,
							permissions: ['isDoctorGroupAdmin', 'isDoctorGroupUser'],
						},
					}}
				>
					{props.exam.status === ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS && (
						<ButtonAnalysePendingExamCP examId={props.exam.id} examCode={props.exam.code} onSave={props.onSave} />
					)}

					{props.exam.status === ExamStatusEnum.PENDING_RECTIFICATION_ANALYSIS && (
						<ButtonAnalysePendingRectificationExamCP examCode={props.exam.code} examId={props.exam.id} onSave={props.onSave} />
					)}
				</AccessControlCP>
			)}

			{canSeeExamData && (
				<ButtonDownloadExamCP
					size={'small'}
					iconOnly={true}
					exam={{
						id: props.exam.id,
						imageData: props.exam.imageData,
						examDescription: props.exam.description,
						patientName: props.exam.patient.name,
					}}
					downloadBy={'user'}
				/>
			)}

			{canSeeExamData && <ButtonExamDetailsCP examId={props.exam.id} onSave={props.onSave} />}
		</ButtonGroupCP>
	)
}
