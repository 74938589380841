import React from 'react'

interface ICPProps {
	html: string
}

/**
 * Area com as regras do programa de indicacao.
 */
export function RawHtmlCP(props: ICPProps): JSX.Element {
	return (
		<>
			{/* eslint-disable-next-line @typescript-eslint/naming-convention */}
			<div dangerouslySetInnerHTML={{ __html: props.html }} />
		</>
	)
}
