import React from 'react'
import { TagCPColorTP } from '_old/main/common/components/tag/inner/TagCPColorTP'
import { ClinicTypeEnum, ClinicTypeLabelEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ITagCPProps {
	type: ClinicTypeEnum
}

/**
 * Tag com o tipo da clinica.
 */
export function TagClinicTypeCP(props: ITagCPProps): JSX.Element {
	let color: TagCPColorTP
	switch (props.type) {
		case ClinicTypeEnum.OCUP:
			color = 'red'
			break

		case ClinicTypeEnum.TRAD:
			color = 'blue'
			break

		case ClinicTypeEnum.VET:
			color = 'darkOrange'
			break

		case ClinicTypeEnum.ODONTO:
			color = 'green'
			break

		default:
			color = 'pink'
			break
	}

	return <TagCP content={ClinicTypeLabelEnum[props.type]} color={color} />
}
