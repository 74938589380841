export enum ExamUrgencyTypeEnum {
	AVC = 'AVC',
	URGENT = 'URGENT',
	HOSPITALIZATION = 'HOSPITALIZATION',
	REGULAR = 'REGULAR',
}

export enum ExamUrgencyTypeLabelEnum {
	AVC = 'AVC',
	URGENT = 'Urgente',
	HOSPITALIZATION = 'Internado',
	REGULAR = 'Eletivo',
}
