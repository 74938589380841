import { TPluginAddTableModalCP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/components/add-table-modal/TPluginAddTableModalCP'
import { TablePluginUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginUtils'
import {
	TablePluginConfigTP,
	TablePluginTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginTypes'

/**
 * PLUGIN
 * Implementa funcionalidade de tabelas dentro do editor wysiwyg
 * customizado montado em cima do framework Draft JS.
 *
 * @author hjcostabr
 */
export const CreateTablePlugin = (config: TablePluginConfigTP): TablePluginTP => {
	return {
		AddTableModal: TPluginAddTableModalCP,
		addTable: TablePluginUtils.addTable,
		blockRendererFn: TablePluginUtils.getTableContentBlockRendererFn(config),
		getHtmlBlockRendererFn: TablePluginUtils.getTableHtmlBlockRendererFn,
	}
}
