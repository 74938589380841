import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsArray } from 'submodules/nerit-framework-utils/validators/decorators/IsArray'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { DoctorLevelEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/DoctorLevelEnum'

export class DoctorGroupDoctorFormModel extends FormModel {
	@IsOptional()
	specialty?: ExamSpecialtyEnum

	@IsOptional()
	doctorLevel?: DoctorLevelEnum

	@IsArray()
	@IsOptional()
	teamCodes?: number[]

	@IsOptional()
	isAuditor?: boolean

	constructor(initialData?: ObjectPropsTP<DoctorGroupDoctorFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
