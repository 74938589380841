import { ContentState, convertFromRaw, convertToRaw, EditorState, RawDraftContentState, SelectionState } from 'draft-js'
import { EditorDebugPanelCP } from 'modules/exams/components/medical-report/editor-medical-report/debug-panel/EditorDebugPanelCP'
import { ToolbarICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/ToolbarICP'
import { CreateTablePlugin } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/CreateTablePlugin'
import {
	TablePluginConfigTP,
	TablePluginTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginTypes'
import { TextEditorConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorConfig'
import { TextEditorUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorUtils'
import React, { useEffect, useState } from 'react'
import { ContentAreaICP } from 'modules/exams/components/medical-report/editor-medical-report/content-area/ContentAreaICP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { IMedicalReportContent } from 'modules/exams/components/medical-report/editor-medical-report/inner/IMedicalReportContent'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	examModality?: ExamModalityEnum
	initialContent?: RawDraftContentState
	onContentChange: (json: RawDraftContentState, html: string) => void
	appearance?: {
		showDebugPanel?: boolean
		noBorder?: boolean
		hideAddPhraseButton?: boolean
	}
	forceReloadContent?: number
}

/**
 * Editor de texto do tipo WYSIWYG.
 */
export function EditorMedicalReportCP(props: ICPProps): JSX.Element {
	const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
	const [readOnly, setReadOnly] = useState<boolean>(false)

	const [editorContent, setEditorContent] = useState<IMedicalReportContent>()
	const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
	useEffect(onEditorStateChange, [editorState])

	const [showAddTableModal, setShowAddTableModal] = useState<boolean>(false)
	const [tablePlugin, setTablePlugin] = useState<TablePluginTP>()
	const [plugins, setPlugins] = useState<any[]>([])
	const [showEditor, setShowEditor] = useState(false)

	useEffect(constructPlugins, [tablePlugin])

	useEffect(init, [])
	useEffect(onChangeInitialContent, [props.initialContent, props.forceReloadContent])

	/**
	 * Inicializa
	 */
	function init(): void {
		const pluginConfig: TablePluginConfigTP = {
			setReadOnly: setReadOnly,
			onTableChange: (nextContent: ContentState) => setEditorState(EditorState.createWithContent(nextContent)),
			customStyles: TextEditorUtils.getParsedCustomStyles(),
		}

		setTablePlugin(CreateTablePlugin(pluginConfig))
	}

	/**
	 * Inicializa plugins.
	 */
	function constructPlugins(): void {
		const editorPlugins: any[] = []
		if (!!tablePlugin) editorPlugins.push(tablePlugin)
		setPlugins(editorPlugins)
	}

	/**
	 * Ao mudar o valor inicial informado
	 */
	function onChangeInitialContent(): void {
		if (!props.initialContent) {
			setEditorState(EditorState.createEmpty())
			return
		}

		try {
			let content = props.initialContent
			// Se veio como string por algum motivo, faz o parse
			if (typeof props.initialContent === 'string') content = JSON.parse(props.initialContent)

			const nextContent: ContentState = convertFromRaw(content)

			// Forca aplicacao de estilos padrao
			const contentBlocks = convertToRaw(nextContent).blocks
			const lastBlock = contentBlocks[contentBlocks.length - 1]

			let nextState = EditorState.forceSelection(
				EditorState.createWithContent(nextContent),
				new SelectionState({
					anchorKey: contentBlocks[0]?.key,
					anchorOffset: 0,
					focusKey: lastBlock.key,
					focusOffset: lastBlock.text.length,
				}),
			)
			nextState = TextEditorUtils.getStateWithDefaultStyles(nextState)

			// Incorpora texto inserido ao editor
			setEditorState(EditorState.moveSelectionToEnd(nextState))
		} catch (e) {
			console.log('Editor Carregado com ERROR', e)
			NotificationHelper.error('Ops', 'Formato inválido')
		}
	}

	/**
	 * Ao mudar conteudo
	 */
	function onEditorStateChange(): void {
		if (!showEditor) {
			setShowEditor(true)
			return
		}

		const content = editorState.getCurrentContent()
		const tableBlockRenderer = !!tablePlugin ? tablePlugin.getHtmlBlockRendererFn(content, TextEditorConfig.TABLE_HTML_STYLES) : undefined

		const outputHtml = TextEditorUtils.getHtmlFromDraftJSState(content, tableBlockRenderer)
		const outputJson = convertToRaw(content)
		setEditorContent({ json: outputJson, html: outputHtml })

		props.onContentChange(outputJson, outputHtml)
	}

	if (!showEditor) return <></>

	return (
		<EditorWrapperSCP
			noBorder={props.appearance?.noBorder}
			className={isFullScreen ? 'editor-fullscreen' : ''}
			onKeyDown={(keyboardEvent) => {
				if (['Escape', 'Esc'].includes(keyboardEvent.key))
					// ESC
					setIsFullScreen(false)
			}}
		>
			<ToolbarICP
				examModality={props.examModality}
				editorState={editorState}
				onEditorStateChange={(changedState) => setEditorState(TextEditorUtils.getStateWithDefaultStyles(changedState))}
				showAddTableModal={() => setShowAddTableModal(true)}
				voiceInputInlineStyle={TextEditorConfig.DEFAULT_STYLES_KEY}
				hideAddPhraseButton={props.appearance?.hideAddPhraseButton}
				setFullScreenMode={setIsFullScreen}
				isFullScreen={isFullScreen}
			/>

			<ContentAreaICP
				examModality={props.examModality}
				value={editorState}
				onChange={(changedState) => setEditorState(TextEditorUtils.getStateWithDefaultStyles(changedState))}
				plugins={plugins}
				forceReloadContent={props.forceReloadContent}
				appearance={{
					readonly: readOnly,
				}}
			/>

			{!!tablePlugin && (
				<tablePlugin.AddTableModal
					show={showAddTableModal}
					onCancel={() => setShowAddTableModal(false)}
					onCreateTable={(rows: number, columns: number) => {
						setEditorState(tablePlugin.addTable(editorState, rows, columns))
						setShowAddTableModal(false)
					}}
				/>
			)}

			{props.appearance?.showDebugPanel && <EditorDebugPanelCP editorContent={editorContent} />}
		</EditorWrapperSCP>
	)
}

const EditorWrapperSCP = styled.div<{ noBorder }>`
	border: ${(props) => (!props.noBorder ? '1px solid ' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '33' : '0 none')};
	box-shadow: ${(props) => (!props.noBorder ? '0px 2px 5px' + ThemeFrameworkCommon.browserDefaultBackgroundDark + '0D' : 'none')};
	color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	border-radius: 0px 0px 5px 5px;
	background-color: #e5e5e5;
	overflow: hidden;
	height: calc(100vh - 160px);
	position: relative;

	&.editor-fullscreen {
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;
		box-shadow: 0 2px 12px ${ThemeFrameworkCommon.browserDefaultBackgroundDark}4D;
		right: 0;
		margin: 0 auto;
		z-index: 9;
	}
`
