import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { TemplateTypeEnum } from 'submodules/beerads-sdk/services/templates/enums/TemplateTypeEnum'
import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TemplateResponseDTO } from 'submodules/beerads-sdk/services/templates/dtos/response/TemplateResponseDTO'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { TemplatesRequests } from 'submodules/beerads-sdk/services/templates/TemplatesRequests'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { AvatarMultipleExamModalityCP } from 'modules/exams/components/exams/avatar-multiple-exam-modality/AvatarMultipleExamModalityCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	onSelectTemplate: (template: TemplateResponseDTO) => void
	onDelete?: () => void
	filters: {
		type: TemplateTypeEnum
		modalities?: ExamModalityEnum[]
		clinicCode?: number
		doctorGroupCode?: number
	}
	appearance?: {
		showEditButton?: boolean
		showDeleteButton?: boolean
		wrappedOnCard?: boolean
		canClickOnRow?: boolean
	}
	loadList: number
}

/**
 * Lista de templates.
 */
export function TableTemplatesCP(props: ICPProps): JSX.Element {
	const [selectedCode, setSelectedCode] = useState<number>()

	const [shouldLoadData, setShouldLoadData] = useState<number>(props.loadList)
	useEffect(() => setShouldLoadData(props.loadList), [props.loadList])

	return (
		<>
			<TableFromApiCP<TemplateResponseDTO>
				appearance={{
					wrappedOnCard: props.appearance?.wrappedOnCard,
				}}
				shouldLoadData={shouldLoadData}
				row={{
					onClick: !props.appearance?.canClickOnRow
						? undefined
						: (template) => {
								props.onSelectTemplate(template)
								setSelectedCode(template.code)
						  },
					setClass: (record) => (record.code === selectedCode ? 'n-selected-row' : ''),
				}}
				apiConfig={{
					requestConfigTP: () =>
						TemplatesRequests.search({
							type: props.filters.type,
							modalities: props.filters.modalities,
							doctorGroupCode: props.filters.doctorGroupCode,
							clinicCode: props.filters.clinicCode,
						}),
				}}
				filter={{ loadedListFilterColumnName: 'name', position: 'center' }}
				columns={[
					{
						title: 'Nome / Modalidade(s)',
						hide: props.filters.type === TemplateTypeEnum.TEMPLATE,
						render: (text, record) => (
							<>
								{record.name}
								<TagsExamModalitiesCP modalities={record.modalities} />
							</>
						),
					},
					{
						hide: props.filters.type === TemplateTypeEnum.PHRASE,
						render: (text, record) => <AvatarMultipleExamModalityCP modalities={record.modalities} />,
					},
					{
						title: 'Nome',
						hide: props.filters.type === TemplateTypeEnum.PHRASE,
						render: (text, record) => record.name,
					},
					{
						title: 'Exame',
						hide: props.filters.type === TemplateTypeEnum.PHRASE,
						render: (text, record) => record.examDescription ?? '-',
					},
					{
						render: (text, record) => (
							<ButtonGroupCP mode={'separeted'}>
								{props.appearance?.showEditButton && (
									<ButtonCP icon={'edit'} onClick={() => props.onSelectTemplate(record)} size={'small'} />
								)}
								{!!props.appearance?.showDeleteButton && (
									<ButtonDeleteRecordCP
										requestConfigTP={() => TemplatesRequests.delete(record.code)}
										onDelete={() => {
											if (!!props.onDelete) props.onDelete()
											setShouldLoadData(TableUtils.getReloadNumber())
										}}
									/>
								)}
							</ButtonGroupCP>
						),
					},
				]}
			/>
		</>
	)
}
