import { OrUndefinedTP } from 'submodules/nerit-framework-utils/utils/types/OrUndefinedTP'
import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { AuthUserResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/inner/AuthUserResponseDTO'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { OrNullTP } from 'submodules/nerit-framework-utils/utils/types/OrNullTP'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

/**
 */
export const AppStateUtils = {
	getLoggedUser(): OrUndefinedTP<AuthUserResponseDTO> {
		return AppStateUtils.getAuthData()?.user
	},

	getLoggedPatientPortal(): OrUndefinedTP<PatientPortalLoginResponseDTO> {
		return ReduxHelper.getInstance().store.getState().authLoggedPatient
	},

	getAuthData(): OrUndefinedTP<AuthResponseDTO> {
		return ReduxHelper.getInstance().store.getState().auth as AuthResponseDTO
	},

	getCurrentDoctorGroup(): OrNullTP<NameAndCodeResponseDTO> {
		return ReduxHelper.getInstance().store.getState().currentDoctorGroup
	},

	getLoggedUserClinicPermissions(): OrUndefinedTP<PersonClinicAccessTP> {
		return ReduxHelper.getInstance().store.getState().loggedUserClinicPermissions as PersonClinicAccessTP
	},

	getLoggedUserDoctorGroupPermissions(): OrUndefinedTP<UserDoctorGroupAccessTP> {
		return ReduxHelper.getInstance().store.getState().loggedUserDoctorGroupPermissions as UserDoctorGroupAccessTP
	},

	getDomain(): OrUndefinedTP<ProjectWhiteLabelsEnum> {
		const domain = window.location.pathname.split('/')[1]
		if (!domain) return

		return domain as ProjectWhiteLabelsEnum
	},

	getDomainName(fullName?: boolean): OrUndefinedTP<string> {
		if (AppStateUtils.getDomain() === ProjectWhiteLabelsEnum.VX) {
			const name = 'VX'
			return fullName ? `${name} - Medical Innovation` : name
		}

		return 'VX PACS'
	},
}
