import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ClinicBilledByEnum, ClinicBilledByLabelEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicBilledByEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName: keyof FModelTP
	formStateManager: IFormStateManager<FModelTP>
}

/**
 * Select de modalidades de exame
 */
export function SelectBilledByCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<>
			<SelectCP
				label={props.label ?? 'Faturado por'}
				fieldName={'billedBy'}
				formStateManager={props.formStateManager}
				options={Object.values(ClinicBilledByEnum).map((billedBy) => ({
					value: billedBy,
					label: ClinicBilledByLabelEnum[billedBy],
				}))}
			/>
		</>
	)
}
