import React from 'react'
import styled from 'styled-components'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { useFormSetPasswordContext } from 'submodules/nerit-framework-ui/common/components/form-set-password/inner/context/useFormSetPasswordContext'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { PasswordUtils } from 'submodules/nerit-framework-utils/utils/PasswordUtils'

export function FormSetPasswordValidationCP(): JSX.Element {
	const { passwordData } = useFormSetPasswordContext()
	const { errors, messages } = PasswordUtils.validatePassword(passwordData.password)

	return (
		<CardCP margin="0">
			{messages.map((message, index) => {
				const isValid = !errors.includes(message)

				return (
					<ValidationMessageSCP key={index} isValid={isValid}>
						<IconCP antIcon={isValid ? 'check' : 'close'} /> {message}
					</ValidationMessageSCP>
				)
			})}
		</CardCP>
	)
}

const ValidationMessageSCP = styled.div<{ isValid: boolean }>`
	display: flex;
	align-items: flex-start;
	color: ${(props) => (props.isValid ? 'green' : 'red')};
	gap: 7px;
	margin-bottom: 5px;
	font-size: 12px;
`
