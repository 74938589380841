import React from 'react'
import { CirclePicker, ColorChangeHandler } from 'react-color'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface IColorPickerCPProps extends BasicStyleWrapperCPProps {
	onSelectColor: ColorChangeHandler
	initialHexColor?: string
	width?: number | string
}

/**
 * Componente Color Picker
 */
export function ColorPickerCP(props: IColorPickerCPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<CirclePicker onChangeComplete={props.onSelectColor} color={props.initialHexColor} width={props.width?.toString()} />
		</BasicStyleWrapperCP>
	)
}
