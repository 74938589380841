import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ExamsImagesBufferRequestDTO } from 'submodules/beerads-sdk/services/exams/exams-downloads/dtos/request/ExamsImagesBufferRequestDTO'
import { ExamImagesLinkRequestDTO } from './dtos/request/ExamImagesLinkRequestDTO'

export class ExamsDownloadsRequests {
	public static CONTROLLER_ROOT = 'exams-downloads'
	public static EXAM_IMAGES_BUFFER_EP = 'exam-images-buffer'
	public static EXAM_IMAGES_LINK_EP = 'exam-images-link'
	public static PREPARE_VIEWER_EP = 'prepare-viewer/:examId'

	static getExamImagesBuffer = (dto: ExamsImagesBufferRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsDownloadsRequests.CONTROLLER_ROOT}/${ExamsDownloadsRequests.EXAM_IMAGES_BUFFER_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static getExamImagesLink = (dto: ExamImagesLinkRequestDTO, forcedBaseUrl: string): RequestConfigTP<string> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		baseUrl: forcedBaseUrl,
		url: `${ExamsDownloadsRequests.CONTROLLER_ROOT}/${ExamsDownloadsRequests.EXAM_IMAGES_LINK_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static prepareViewer = (examId: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsDownloadsRequests.CONTROLLER_ROOT}/${ExamsDownloadsRequests.PREPARE_VIEWER_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})
}
