import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ExamSpecialtyEnum, ExamSpecialtyLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	isMultiple?: boolean
	selectedModality?: ExamSpecialtyEnum
	required?: boolean
	disabled?: boolean
}

/**
 * Select de modalidades de exame
 */
export function SelectExamSpecialtyCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label ?? 'Especialidades'}
			value={props.selectedModality}
			fieldName={props.fieldName}
			isMultiple={props.isMultiple}
			formStateManager={props.formStateManager}
			required={props.required}
			disabled={props.disabled}
			allowClear={true}
			options={Object.values(ExamSpecialtyEnum).map((specialty) => ({
				value: specialty,
				label: ExamSpecialtyLabelEnum[specialty],
			}))}
		/>
	)
}
