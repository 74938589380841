import { AppStateUtils } from 'app/redux/AppStateUtils'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'

/**
 */
export class CommonPermissionUtils {
	/**
	 * Se usuario logado tem perfil de BeeRads Admin.
	 */
	static isBeeRadsAdmin(): boolean {
		return AppStateUtils.getLoggedUser()!.isBeeradsAdmin
	}

	/**
	 * Retorna se usuario eh do tipo desejado
	 */
	static canAccessByType(type: UserTypeEnum): boolean {
		if (type === UserTypeEnum.USER) return !AppStateUtils.getLoggedUser()!.isDoctor

		return AppStateUtils.getLoggedUser()!.isDoctor
	}
}
