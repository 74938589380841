import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ClinicDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/enums/ClinicDoctorGroupStatusEnum'
import { ClinicDoctorGroupAnswerInviteRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAnswerInviteRequestDTO'

interface ICPProps {
	clinicDoctorGroupCode: number
	onSave: () => void
}

/**
 */
export function ButtonClinicDoctorGroupAnswerInviteCP(props: ICPProps): JSX.Element {
	const acceptInviteRequest = useRequest<void>('none')
	useEffect(onAcceptInviteRequestChange, [acceptInviteRequest.isAwaiting])

	/**
	 */
	function acceptInvite(answer: ClinicDoctorGroupStatusEnum.ACTIVE | ClinicDoctorGroupStatusEnum.REFUSED): void {
		const dto: ClinicDoctorGroupAnswerInviteRequestDTO = {
			clinicDoctorGroupCode: props.clinicDoctorGroupCode,
			answer: answer,
		}
		acceptInviteRequest.runRequest(ClinicDoctorGroupRequests.answerInvite(dto))
	}

	/**
	 */
	function onAcceptInviteRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				acceptInviteRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ButtonGroupCP mode={'separeted'}>
			<ButtonCP
				size={'small'}
				type={'primary'}
				icon={'check-circle'}
				onClick={() => acceptInvite(ClinicDoctorGroupStatusEnum.ACTIVE)}
				confirmMsg={'Você tem certeza que deseja ACEITAR o convite?'}
				loading={acceptInviteRequest.isAwaiting}
			/>
			<ButtonCP
				size={'small'}
				type={'danger'}
				icon={'close-circle'}
				confirmMsg={'Você tem certeza que deseja RECUSAR o convite?'}
				onClick={() => acceptInvite(ClinicDoctorGroupStatusEnum.REFUSED)}
				loading={acceptInviteRequest.isAwaiting}
			/>
		</ButtonGroupCP>
	)
}
