import moment from 'moment'
import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { AppConfig } from 'config/AppConfig'
import { momentPtBrLocale } from '_old/main/common/utils/moment-pt-br-locale'

/**
 * Reune metodos genericos uteis para manipulacao de datas.
 */
export class DateUtils {
	static setTimeZone(date: Date | string, format: DateTimeFormatEnum = DateTimeFormatEnum.BR_WITH_TIME_H_M): string | undefined {
		return moment.tz(date, AppConfig.getInstance().defaultTimeZone).format(format)
	}

	static formatDate = (date: string | Date, format?: string): string => {
		return moment(date).format(format)
	}

	/**
	 * Formata & retorna string contendo data no formato adequado para
	 * enviar para a api.
	 */
	static getDateFormattedToApi(date?: Date): string | undefined {
		if (!date) return undefined

		return this.setTimeZone(date, DateTimeFormatEnum.EUA_WITHOUT_TIME) as string
	}

	static getHourAndMinutesAsString(date?: Date): string | undefined {
		if (!date) return undefined

		const minute = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
		const hour = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}`

		return `${hour}:${minute}`
	}

	static getFormattedTimeDiffDuration(before: Date, after: Date): string {
		const duration = moment.duration(moment(after).diff(before))
		return `${duration.days()} dias, ${duration.hours()} horas, ${duration.minutes()} minutos`
	}

	/**
	 * Transforma uma string em uma data.
	 * @param {string} dateStr Data em string
	 * @param dateStrFormat Formato em que esta a string data
	 * @returns {Date} Data.
	 */
	static toDate(dateStr: string, dateStrFormat): Date {
		return moment(dateStr, dateStrFormat).toDate()
	}

	static getFormatted(date: string | Date | undefined, format: DateTimeFormatEnum): string {
		if (!date) return ''
		moment.defineLocale('pt_BR', momentPtBrLocale)
		return moment(date).format(format)
	}

	/** Transforma uma data, string, de um formato para outro. Ex.: 22/04/1987 para 1987-04-22. */
	static transformDateStrFormat(dateStr: string, originalFormat: DateTimeFormatEnum, finalFormat: DateTimeFormatEnum): string | undefined {
		if (!moment(dateStr, originalFormat).isValid()) return

		return DateUtils.getFormatted(moment(dateStr, originalFormat).toDate(), finalFormat)
	}
}
