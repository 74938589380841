import React from 'react'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

interface ICPProps {
	hideIcon?: boolean
	size?: number
}

/**
 * Icone de achado critico no exame.
 */
export function IconCriticalExamCP(props: ICPProps): JSX.Element {
	if (props.hideIcon) return <></>

	return (
		<TooltipCP text={'Achado crítico!'} showSpan={true}>
			<IconICP size={props.size ?? 16} iconName={'warning'} />
		</TooltipCP>
	)
}
