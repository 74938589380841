import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ExamsByPeriodResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamsByPeriodResponseDTO'
import { ExamsByPeriodRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamsByPeriodRequestDTO'
import { ExamsSummaryResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamsSummaryResponseDTO'
import { ExamsSummaryRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamsSummaryRequestDTO'
import { MedicalReportsDoneRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsDoneRequestDTO'
import { MedicalReportsDoneListItemResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/MedicalReportsDoneListItemResponseDTO'
import { CountMedicalReportsDoneByClinicRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/CountMedicalReportsDoneByClinicRequestDTO'
import { CountMedicalReportsDoneByClinicResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/CountMedicalReportsDoneByClinicResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ExamMonthlyGrowthComparativeRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamMonthlyGrowthComparativeRequestDTO'
import { ExamMonthlyGrowthComparativeResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamMonthlyGrowthComparativeResponseDTO'
import { MedicalReportsSlaAnalysisRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsSlaAnalysisRequestDTO'
import { MedicalReportsSlaAnalysisListItemResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/MedicalReportsSlaAnalysisListItemResponseDTO'
import { RectificationReportRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/RectificationReportRequestDTO'

export class DashboardRequests {
	static CONTROLLER_ROOT = 'dashboard'
	static MONTHLY_GROWTH_COMPARATIVE_EP = 'monthly-growth-comparative'
	static EXAMS_BY_PERIOD_EP = 'exams-by-period'
	static EXAMS_SUMMARY_EP = 'exams-summary'
	static COUNT_MEDICAL_REPORTS_DONE_BY_CLINIC_EP = 'count-medical-reports-done-by-clinic'
	static MEDICAL_REPORTS_DONE_EP = 'medical-reports-done'
	static MEDICAL_REPORTS_DONE_EXPORT_EP = `${DashboardRequests.MEDICAL_REPORTS_DONE_EP}/export`
	static MEDICAL_REPORTS_SLA_ANALYSIS_EP = 'medical-reports-sla-analysis'
	static MEDICAL_REPORTS_SLA_ANALYSIS_EXPORT_EP = `${DashboardRequests.MEDICAL_REPORTS_SLA_ANALYSIS_EP}/export`
	static MEDICAL_REPORTS_RECTIFICATION_ANALYSIS_EP = 'medical-reports-rectification-analysis'
	static MEDICAL_REPORTS_RECTIFICATION_ANALYSIS_EXPORT_EP = `${DashboardRequests.MEDICAL_REPORTS_RECTIFICATION_ANALYSIS_EP}/export`

	static monthlyGrowthComparative = (
		dto: ExamMonthlyGrowthComparativeRequestDTO,
	): RequestConfigTP<ListResponseDTO<ExamMonthlyGrowthComparativeResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MONTHLY_GROWTH_COMPARATIVE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static examsByPeriod = (dto: ExamsByPeriodRequestDTO): RequestConfigTP<ExamsByPeriodResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.EXAMS_BY_PERIOD_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static examsSummary = (dto: ExamsSummaryRequestDTO): RequestConfigTP<ExamsSummaryResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.EXAMS_SUMMARY_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportsDone = (dto: MedicalReportsDoneRequestDTO): RequestConfigTP<MedicalReportsDoneListItemResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_DONE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportsDoneExport = (dto: MedicalReportsDoneRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_DONE_EXPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static medicalReportsSlaAnalysis = (dto: MedicalReportsSlaAnalysisRequestDTO): RequestConfigTP<MedicalReportsSlaAnalysisListItemResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_SLA_ANALYSIS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportsSlaAnalysisExport = (dto: MedicalReportsSlaAnalysisRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_SLA_ANALYSIS_EXPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static medicalReportsRectificationAnalysis = (dto: RectificationReportRequestDTO): RequestConfigTP<MedicalReportsSlaAnalysisListItemResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_RECTIFICATION_ANALYSIS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static medicalReportsRectificationAnalysisExport = (dto: RectificationReportRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.MEDICAL_REPORTS_RECTIFICATION_ANALYSIS_EXPORT_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static countMedicalReportsDoneByClinic = (
		dto: CountMedicalReportsDoneByClinicRequestDTO,
	): RequestConfigTP<ListResponseDTO<CountMedicalReportsDoneByClinicResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DashboardRequests.CONTROLLER_ROOT}/${DashboardRequests.COUNT_MEDICAL_REPORTS_DONE_BY_CLINIC_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
