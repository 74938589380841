import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { AuditStatusEnum, AuditStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'

interface ICPProps {
	status: AuditStatusEnum
	onChangeAuditStatus: (status: AuditStatusEnum) => void
}

/**
 * Header da tela de gerenciamento de clínica.
 */
export function HeaderScreenDoctorAuditsCP(props: ICPProps): JSX.Element {
	return (
		<HeaderCP title={'Minhas Auditorias'}>
			<RadioGroupCP<AuditStatusEnum>
				type={'button'}
				selected={props.status}
				onChange={props.onChangeAuditStatus}
				buttonWidth={160}
				options={Object.values(AuditStatusEnum).map((status) => ({
					content: AuditStatusLabelEnum[status],
					value: status,
				}))}
			/>
		</HeaderCP>
	)
}
