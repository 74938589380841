export enum ExamModalityEnum {
	MG = 'MG', // Mamografia
	CR = 'CR', // Raio X
	DX = 'DX', // Raio X Digital
	MR = 'MR', // Ressonancia Magnetica
	CT = 'CT', // Tomografia
	CR_OIT = 'CR_OIT', // Raio X OIT
	ECO = 'ECO', // Ecocardiograma
	ECG = 'ECG', // Eletrocardiograma
	EEG = 'EEG', // Eletroencefalograma
	EDA = 'EDA', // Endoscopia
	ESP = 'ESP', // Espirometria
	OFT = 'OFT', // Oftalmologia
	USG = 'USG', // Ultrasonografia
	BMD = 'BMD', // Densitometria Óssea
	OT = 'OT', // Outros
}

export enum ExamModalityLabelEnum {
	MG = 'MG', // Mamografia
	CR = 'RX', // Raio X
	DX = 'RX_D', // Raio X Digital
	MR = 'RM', // Ressonancia Magnetica
	CT = 'TC', // Tomografia
	CR_OIT = 'OIT', // Raio X OIT
	ECO = 'ECO', // Ecocardiograma
	ECG = 'ECG', // Eletrocardiograma
	EEG = 'EEG', // Eletroencefalograma
	EDA = 'EDA', // Endoscopia
	ESP = 'ESP', // Espirometria
	OFT = 'OFT', // Oftalmologia
	USG = 'USG', // Ultrasonografia
	BMD = 'DO', // Densitometria Óssea
	OT = 'OT', // Outros
}

/** Label de PT para EN. */
export enum ExamModalityLabelToEnEnum {
	RX_D = 'DX',
	DO = 'BMD',
	ECO = 'ECO',
	ECG = 'ECG',
	EEG = 'EEG',
	EDA = 'EDA',
	ESP = 'ESP',
	MG = 'MG',
	OFT = 'OFT',
	RX = 'CR',
	OIT = 'CR_OIT',
	RM = 'MR',
	TC = 'CT',
	USG = 'USG',
	OT = 'OT',
}

export enum ExamModalityFullLabelEnum {
	CR = 'Radiografia Computadorizada (RX)',
	CT = 'Tomografia Computadorizada (TC)',
	MR = 'Ressonância Magnética (RM)',
	DX = 'Radiografia Digital (RX_D)',
	MG = 'Mamografia (MG)',
	DR = 'Radiografia Digital (DR)',
	RF = 'Fluoroscopia (RF)',
	RG = 'Imagem Radiográfica (RG)',
	PT = 'PET Scan (PT)',
	US = 'Ultrassom (US)',
	OFT = 'Oftalmologia (OFT)',
	ESP = 'Espirometria (ESP)',
	ECG = 'Eletrocardiograma (ECG)',
	EEG = 'Eletroencefalograma (EEG)',
	CR_OIT = 'Raio X OIT (RX OIT)',
	BMD = 'Densitometria Óssea',
	OT = 'Outros (OT)',
}
