import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 * Objeto de parametrizacao para componente de grafico de barras generico.
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
export const BAR_GRAPH_CONFIG_OPTIONS = {
	chart: {
		type: 'bar',
		height: 350,
		width: '100%',
		stacked: false,
		toolbar: {
			show: true,
			tools: {
				download: true,
				selection: false,
				zoom: false,
				zoomin: false,
				zoomout: false,
				pan: false,
				reset: false,
				customIcons: [],
			},
		},
	},
	plotOptions: {
		bar: {
			endingShape: 'flat',
			columnWidth: '90%',
			dataLabels: {
				position: 'center',
				maxItems: 100,
				hideOverflowingLabels: true,
			},
		},
	},
	dataLabels: {
		enabled: false,
	},
	stroke: {
		width: 2,
		show: false,
	},
	series: [],
	colors: [ThemeProject.primary],
	xaxis: {
		type: 'category',
		axisBorder: {
			show: true,
		},
		lines: {
			show: true,
		},
		labels: {
			rotate: -45,
			rotateAlways: false,
			minHeight: 60,
		},
	},
	yaxis: {
		min: 0,
		max: (max) => max,
		forceNiceScale: true,
		labels: {
			offsetY: -5,
		},
	},
}
