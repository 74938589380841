import React, { useEffect, useState } from 'react'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamTotalImagesResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamTotalImagesResponseDTO'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { LoadingIconCP } from 'submodules/nerit-framework-ui/common/components/icon/icons/LoadingIconCP'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { ExamImageDataResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/inner/ExamImageDataResponseDTO'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	examId: string
	examCode: number
	examDescription: string
	patientName: string
	imageData: ExamImageDataResponseDTO
	buttonsConfig?: {
		hideDownload?: boolean
		hideExamDetails?: boolean
	}
}

/**
 * Alerta informando que o total de imagens do exame
 */
export function AlertExamCountImagesCP(props: ICPProps): JSX.Element {
	const [lodadedTotalImages, setLodadedTotalImages] = useState<ExamTotalImagesResponseDTO>()
	const getTotalImagesRequest = useRequest<ExamTotalImagesResponseDTO>()
	useEffect(onGetTotalImagesRequestRequestChange, [getTotalImagesRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Inicializa.
	 */
	function init(): void {
		getTotalImagesRequest.runRequest(ExamsRequests.getTotalImages(props.examCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetTotalImagesRequestRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getTotalImagesRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLodadedTotalImages(getTotalImagesRequest.responseData)
	}

	if (!lodadedTotalImages) return <></>

	return (
		<AlertCP
			message={
				<FlexCP alignItems={'center'} justifyContent={'space-between'}>
					<TextAndTitleCP
						title={'Total de imagens do exame recebidas até o momento:'}
						text={getTotalImagesRequest.isAwaiting ? <LoadingIconCP /> : MaskUtils.applyNumberMask(lodadedTotalImages.totalImages, 0)}
						invertBold={true}
						margin={{ bottom: 0 }}
					/>
					<ButtonGroupCP mode={'separeted'}>
						<ButtonCP size={'small'} icon={'reload'} onClick={init} type={'primary'} />
						{!props.buttonsConfig?.hideDownload && (
							<BasicStyleWrapperCP margin={{ left: 10 }}>
								<ButtonDownloadExamCP
									size={'small'}
									exam={{
										id: props.examId,
										examDescription: props.examDescription,
										patientName: props.patientName,
										imageData: props.imageData,
									}}
									downloadBy={'user'}
								/>
							</BasicStyleWrapperCP>
						)}
						{!props.buttonsConfig?.hideExamDetails && <ButtonExamDetailsCP margin={{ left: 5 }} examId={props.examId} />}
					</ButtonGroupCP>
				</FlexCP>
			}
			type={'warning'}
			margin={{ bottom: 20 }}
		/>
	)
}
