import React from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/ext-language_tools'

interface ICPProps {
	height?: string
	value?: string
	onChange: (content: string) => void
	mode?: 'html' | 'javascript' // Obs: Existem outros tipos na documentação do ace editor, caso seja necessário implementar tem que fazer o import também;
}

export function EditorCodeCP(props: ICPProps): JSX.Element {
	function handleChange(newValue: string): void {
		if (!!props.onChange) props.onChange(newValue)
	}

	return (
		<AceEditor
			theme={'tomorrow'}
			onChange={handleChange}
			fontSize={14}
			height={props.height}
			width={'100%'}
			mode={props.mode}
			showPrintMargin={false}
			showGutter={true}
			highlightActiveLine={false}
			value={props.value ?? ''}
			wrapEnabled={true}
			setOptions={{
				enableBasicAutocompletion: false,
				enableLiveAutocompletion: false,
				enableSnippets: false,
				showLineNumbers: true,
				tabSize: 4,
			}}
		/>
	)
}
