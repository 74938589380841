import { OitFormModel } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/form-model/OitFormModel'
import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import { OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'

interface IOitSection2AICPProps {
	formStateManager: IFormStateManager<OitFormModel>
}

export function OitSection2AICP(props: IOitSection2AICPProps): JSX.Element {
	return (
		<RowCP>
			<OitSectionContentICP title={'2A - Alguma anormalidade de parênquima consistente com pneumoconiose'}>
				<ColumnCP size={24}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: 'Sim', value: true },
							{ content: 'Não', value: false },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer2A'}
					/>
				</ColumnCP>
			</OitSectionContentICP>
		</RowCP>
	)
}
