import React from 'react'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IAutocompleteCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/inner/IAutocompleteCommonProps'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { TeamsRequests } from 'submodules/beerads-sdk/services/doctor-groups/teams/TeamsRequests'

interface ICPProps<FModelTP extends FormModel = any> extends IAutocompleteCommonProps<NameAndCodeResponseDTO, FModelTP> {
	label?: string
	doctorGroupCode: number
	loading?: boolean
}

/**
 * Select de times
 */
export function SelectTeamCP<FModelTP extends FormModel = any>(props: ICPProps<FModelTP>): JSX.Element {
	return (
		<AutocompletePickerCP<NameAndCodeResponseDTO, FModelTP>
			label={props.label ?? 'Times'}
			requestConfigGetter={TeamsRequests.names}
			customFilters={{ doctorGroupCode: props.doctorGroupCode }}
			filterOption={true}
			showOptionsOnLoad={true}
			loading={props.loading}
			allowClear={props.allowClear}
			// NOTE: Usando operador 'spread' excepcionalmente para garantir compatibilidade com props extendidas de outro componente
			{
				...props /* eslint-disable-line react/jsx-props-no-spreading */
			}
		/>
	)
}
