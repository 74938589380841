import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalUserProfileDataCP } from 'modules/person/components/user/modal-user-data/ModalUserProfileDataCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'

interface ICPProps {
	person: PersonResponseDTO
	onChangeData: () => void
	appearance?: {
		showLabel?: boolean
		type?: ButtonTypeTP
		size?: ButtonSizeTP
	}
}

/**
 */
export function ButtonEditUserDataCP(props: ICPProps): JSX.Element {
	const [isModalPersonDataVisible, setIsModalPersonDataVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP
				onClick={() => setIsModalPersonDataVisible(true)}
				icon={'edit'}
				type={props.appearance?.type}
				size={props.appearance?.size}
				tooltip={'Editar dados do usuário'}
			>
				{props.appearance?.showLabel ? 'Editar' : undefined}
			</ButtonCP>

			<ModalUserProfileDataCP
				visible={isModalPersonDataVisible}
				onClose={(dataChanged) => {
					setIsModalPersonDataVisible(false)
					if (dataChanged) props.onChangeData()
				}}
				person={props.person}
			/>
		</>
	)
}
