import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import styled from 'styled-components'

interface ICPProps {
	options: ApexOptions
	onMount?: (chartElement: any) => void
}

/**
 */
export function ChartApexCP(props: ICPProps): JSX.Element {
	const chartRef = useRef<any>(null)
	const chartElement = useRef<any>(null)

	useEffect(onMount, [])

	function onMount(): void {
		chartElement.current = new ApexCharts(chartRef.current, props.options)

		// chartElement.current.updateSeries(props.options.series, true)
		// chartElement.current.updateOptions({
		//     xaxis: { categories: props.options.labels }
		// }, undefined, true, true)

		if (!!props.onMount) props.onMount(chartElement)

		chartElement.current.render()
	}

	return (
		<ChartWrapperSCP>
			<div ref={chartRef} />
		</ChartWrapperSCP>
	)
}

const ChartWrapperSCP = styled.div`
	height: 100%;
	width: 100%;
`
