import { Icon } from 'antd'
import React from 'react'

import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'

/**
 * ICONE: Configurações
 *
 * @author Stella
 */
export const SettingIconCP = (props: IIconProps): JSX.Element => {
	return (
		<Icon
			type={'setting'}
			style={{
				color: props.color || 'inherit',
				fontSize: props.size || '20px',
				...props.style,
			}}
		/>
	)
}
