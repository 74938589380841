import React from 'react'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { SelectBilledByCP } from 'modules/admin-beerads/components/select-billed-by/SelectBilledByCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'

interface ICPProps {
	clinicFormStateManager: IFormStateManager<ClinicFormModel>
}

/**
 * Formulário das informações da clínica
 */
export function FormClinicAdminDataICP(props: ICPProps): JSX.Element {
	return (
		<AccessControlCP permission={{ isBeeRadsAdmin: true }}>
			<>
				<SwitchCP label={'Ativa?'} fieldName={'active'} formStateManager={props.clinicFormStateManager} />

				<TitleCP marginTop={30} marginBottom={10} textSize={'normal'} underLine={true}>
					Financeiro
				</TitleCP>
				<RowCP>
					<ColumnCP size={12}>
						<SelectBilledByCP fieldName={'billedBy'} formStateManager={props.clinicFormStateManager} />
					</ColumnCP>
					<ColumnCP size={12}>
						<InputCP label={'URL do Portal Financeiro'} fieldName={'billingPageUrl'} formStateManager={props.clinicFormStateManager} />
					</ColumnCP>
				</RowCP>

				<TextAreaCP label={'Observações'} fieldName={'notes'} formStateManager={props.clinicFormStateManager} />
			</>
		</AccessControlCP>
	)
}
