import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { WrapperMedicalReportEditorCP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorCP'
import { WrapperMedicalReportEditorContentAreaICP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorContentAreaICP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { MedicalReportHtmlResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/MedicalReportHtmlResponseDTO'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'

interface ICPProps {
	examCode: number
	examModality: ExamModalityEnum
}

/**
 */
export function HtmlMedicalReportsCP(props: ICPProps): JSX.Element {
	const divRef = useRef(null)

	const [showHeader, setShowHeader] = useState<boolean>(false)

	const [medicalReportHtmls, setMedicalReportHtmls] = useState<MedicalReportHtmlResponseDTO[]>()
	const getMedicalReportHtmlsRequest = useRequest<ListResponseDTO<MedicalReportHtmlResponseDTO>>()
	useEffect(onGetMedicalReportHtmlsRequestChange, [getMedicalReportHtmlsRequest.isAwaiting])

	useEffect(init, [])

	/**
	 */
	function init(): void {
		getMedicalReportHtmlsRequest.runRequest(MedicalReportRequests.getHtmls(props.examCode))
	}

	/**
	 */
	function onGetMedicalReportHtmlsRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalReportHtmlsRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setMedicalReportHtmls(getMedicalReportHtmlsRequest.responseData?.list)
	}

	/**
	 */
	function onCopy(): void {
		const range = document.createRange()
		range.selectNode(divRef.current as any)

		const selection = window.getSelection()!
		selection.removeAllRanges()
		selection.addRange(range)
		;(divRef.current! as any).focus()

		const success = document.execCommand('copy')
		if (success) NotificationHelper.success('Yes!', 'Texto copiado com sucesso')
		else NotificationHelper.error('Ops!', 'Ocorreu algum erro ao copiar o texto')

		window.getSelection()!.removeAllRanges()
	}

	return (
		<WrapperSCP>
			<LoadingOverlayCP show={getMedicalReportHtmlsRequest.isAwaiting} />

			{medicalReportHtmls?.map((medicalReport) => {
				const isCopyDisabled = !!medicalReport.watermark || props.examModality === ExamModalityEnum.CR_OIT
				return (
					<WrapperMedicalReportEditorCP key={medicalReport.signature}>
						<ButtonWrapper>
							<ButtonCP icon={'copy'} onClick={onCopy} disabled={isCopyDisabled}>
								Copiar conteúdo
							</ButtonCP>
							<div>
								<SwitchCP label={'Mostrar cabeçalho'} value={showHeader} onChange={setShowHeader} />
							</div>
						</ButtonWrapper>

						<WrapperMedicalReportEditorContentAreaICP>
							<div
								className={isCopyDisabled ? 'content-copy-disabled' : undefined}
								dangerouslySetInnerHTML={{
									__html: `
                                        ${
											medicalReport.watermark
												? `<div style="text-align: center; margin-bottom: 20px; font-size: 20px; color: red;"><b>${medicalReport.watermark}</b></div>`
												: ''
										}
                                        ${showHeader ? `${medicalReport.header}<br/>` : ''}
                                        ${medicalReport.content}
                                        <div style="text-align: center"> 
                                            ${medicalReport.signature}
                                        </div>
                                        `,
								}}
								ref={divRef}
							/>
						</WrapperMedicalReportEditorContentAreaICP>
					</WrapperMedicalReportEditorCP>
				)
			})}
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	margin-bottom: 30px;
	position: relative;

	.content-copy-disabled {
		user-select: none;
		pointer-events: none;
	}
`

const ButtonWrapper = styled.div`
	position: absolute;
	width: 100%;
	margin-top: 2px;
	padding: 0 10px;
	justify-content: space-between;
	align-items: center;
	display: flex;
`
