import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsArray, IsOptional } from 'class-validator'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'
import { ExamSpecialtyEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamSpecialtyEnum'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { ISearchOrderingRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/interfaces/ISearchOrderingRequestDTO'

export class ExamFilterFormModel extends FormModel {
	@IsRequired()
	viewAsDoctor: boolean

	@IsOptional()
	searchString?: string

	@IsOptional()
	patientName?: string

	@IsOptional()
	doctorCodes?: number[]

	@IsOptional()
	status?: ExamStatusEnum[]

	@IsOptional()
	clinicCodes?: number[]

	@IsOptional()
	modalities?: ExamModalityEnum[]

	@IsOptional()
	urgencies?: ExamUrgencyTypeEnum[]

	@IsOptional()
	isCritical?: boolean

	@IsOptional()
	assigned?: ExamAssignedFilterEnum

	@IsOptional()
	sla?: number

	@IsOptional()
	doctorGroupCode?: number

	@IsOptional()
	clinicCategoryCode?: number

	@IsOptional()
	teamCode?: number

	@IsOptional()
	specialty?: ExamSpecialtyEnum

	@IsOptional()
	dateRange: DateRangeSearchRequestDTO

	@IsOptional()
	shouldConsiderMedicalReportDate?: boolean

	@IsOptional()
	sorter: ISearchOrderingRequestDTO<ExamSearchOrderingEnum>

	@IsOptional()
	medicalReportInHourLimit: number

	@IsArray()
	examIds: string[]

	constructor(initialData?: ObjectPropsTP<ExamFilterFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
