import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { AssignExamsRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/AssignExamsRequestDTO'
import { CreateExamRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/CreateExamRequestDTO'
import { DuplicateExamRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/DuplicateExamRequestDTO'
import { ExamDataSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamDataSaveRequestDTO'
import { ExamPendingSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingSaveRequestDTO'
import { ExamSearchPendingDigitalSignatureRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchPendingDigitalSignatureRequestDTO'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamsSetUrgencyRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamsSetUrgencyRequestDTO'
import { DuplicateExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/DuplicateExamResponseDTO'
import { ExamPendingResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingResponseDTO'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ExamTotalImagesResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamTotalImagesResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamBillingFlagsUpdateRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamBillingFlagsUpdateRequestDTO'
import { ExamAssignResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamsAssignResponseDTO'

export class ExamsRequests {
	public static CONTROLLER_ROOT = 'exams'
	public static CREATE_EXAM_FROM_LAMBDA_EP = 'create-from-lambda'
	public static CREATE_EXAM_EP = 'create-exam'
	public static CREATE_EXAM_METADATA = 'create-exam-metadata/:code'
	public static SEARCH_EP = 'search'
	public static GET_EXAM_EP = 'get/:id'
	public static GET_STATUS_EXAM_EP = 'get-status/:id'
	public static DISCARD_MEDICAL_REPORT_EXAM_EP = 'discard-medical-report/:examCode'
	public static GET_EXAM_PENDING_EP = 'get-pending/:code'
	public static SAVE_EXAM_PENDING_EP = 'save-pending/:code'
	public static SAVE_PAST_EXAM_EP = 'save-past-exam/:code'
	public static GET_TOTAL_IMAGES_EP = 'total-images/:code'
	public static ARCHIVE_EXAM_EP = 'archive/:code'
	public static UNARCHIVE_EXAM_EP = 'unarchive/:code'
	public static SET_URGENCY_EP = 'set-urgency'
	public static ASSIGN_EP = 'assign'
	public static DUPLICATE_EXAM_EP = 'duplicate-exam/:code'
	public static UPDATE_EXAM_DATA_EP = 'update-exam-data'
	public static LOAD_DATA_FROM_INTEGRATION_EP = 'load-data-from-integration/:code'
	public static SEARCH_PENDING_DIGITAL_SIGNATURE_EP = 'search-pending-digital-signature'
	public static TOGGLE_DIGITAL_CERTIFICATE_EP = 'toggle-digital-certificate/:examCode'
	public static GET_CONCLUDED_EXAMS_BETWEEN_DATES_EP = 'get-concluded-exams-between-dates'
	public static UPDATE_BILLING_FLAGS_EP = 'update-billing-flags/:examId'
	public static FORCE_METADATA_OHIF_VIEWER_GENERATION_EP = 'force-metadata-ohif-viewer-generation/:examId'

	static getExam = (examId: string): RequestConfigTP<ExamResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.GET_EXAM_EP.replace(':id', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static getExamStatus = (examId: string): RequestConfigTP<ExamStatusEnum> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.GET_STATUS_EXAM_EP.replace(':id', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static getExamPending = (examCode: number): RequestConfigTP<ExamPendingResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.GET_EXAM_PENDING_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static loadDataFromIntegration = (examCode: number): RequestConfigTP<ExamPendingResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.LOAD_DATA_FROM_INTEGRATION_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static search = (dto: ExamSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static searchPendingDigitalSignature = (dto: ExamSearchPendingDigitalSignatureRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.SEARCH_PENDING_DIGITAL_SIGNATURE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static savePendingExam = (code: number, dto: ExamPendingSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.SAVE_EXAM_PENDING_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static savePastExam = (code: number, dto: ExamPendingSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.SAVE_PAST_EXAM_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static archiveExam = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.ARCHIVE_EXAM_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static unarchiveExam = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.UNARCHIVE_EXAM_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static createExam = (dto: CreateExamRequestDTO): RequestConfigTP<ExamResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.CREATE_EXAM_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static createExamMetadata = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.CREATE_EXAM_METADATA.replace(':code', code.toString())}`,
		method: HttpMethodEnum.POST,
	})

	static getTotalImages = (examCode: number): RequestConfigTP<ExamTotalImagesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.GET_TOTAL_IMAGES_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static cancelExam = (examCode: number): RequestConfigTP<boolean> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.ARCHIVE_EXAM_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.DELETE,
	})

	static setUrgency = (dto: ExamsSetUrgencyRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.SET_URGENCY_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static assign = (dto: AssignExamsRequestDTO): RequestConfigTP<ExamAssignResponseDTO | void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.ASSIGN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static discardMedicalReport = (examCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.DISCARD_MEDICAL_REPORT_EXAM_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static duplicateExam = (examCode: number, dto: DuplicateExamRequestDTO): RequestConfigTP<DuplicateExamResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.DUPLICATE_EXAM_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static updateExamData = (dto: ExamDataSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.UPDATE_EXAM_DATA_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static toggleDigitalCertificate = (examCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.TOGGLE_DIGITAL_CERTIFICATE_EP.replace(':examCode', examCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static updateBillingFlags = (examId: string, dto: ExamBillingFlagsUpdateRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsRequests.CONTROLLER_ROOT}/${ExamsRequests.UPDATE_BILLING_FLAGS_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})
}
