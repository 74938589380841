import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'

export class ExamStatusUtils {
	// Mapa de Status de cada Grupo
	static examStatusOfExamStatusGroupMap = new Map<ExamStatusGroupEnum, ExamStatusEnum[]>([
		[ExamStatusGroupEnum.PENDING, [ExamStatusEnum.PENDING, ExamStatusEnum.RETURNED]],
		[
			ExamStatusGroupEnum.AWAITING,
			[
				ExamStatusEnum.AWAITING,
				ExamStatusEnum.RECTIFICATION,
				ExamStatusEnum.IN_PROGRESS,
				ExamStatusEnum.SIGNED,
				ExamStatusEnum.SIGNED_RECTIFICATION,
			],
		],
		[ExamStatusGroupEnum.PENDING_ANALYSIS, [ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS, ExamStatusEnum.PENDING_RECTIFICATION_ANALYSIS]],
		[ExamStatusGroupEnum.DONE, [ExamStatusEnum.DONE, ExamStatusEnum.PRINTED, ExamStatusEnum.DONE_RECTIFICATION]],
		[ExamStatusGroupEnum.ARCHIVED, [ExamStatusEnum.ARCHIVED]],
		[
			ExamStatusGroupEnum.RUNNING_SLA_STATUS,
			[
				ExamStatusEnum.RETURNED,
				ExamStatusEnum.AWAITING,
				ExamStatusEnum.IN_PROGRESS,
				ExamStatusEnum.SIGNED,
				ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS,
			],
		],
	])

	// Mapa do Grupo para cada Status
	static examStatusGroupOfExamStatusMap = new Map<ExamStatusEnum, ExamStatusGroupEnum>([
		[ExamStatusEnum.PENDING, ExamStatusGroupEnum.PENDING],
		[ExamStatusEnum.RETURNED, ExamStatusGroupEnum.PENDING],
		[ExamStatusEnum.AWAITING, ExamStatusGroupEnum.AWAITING],
		[ExamStatusEnum.RECTIFICATION, ExamStatusGroupEnum.AWAITING],
		[ExamStatusEnum.IN_PROGRESS, ExamStatusGroupEnum.AWAITING],
		[ExamStatusEnum.SIGNED, ExamStatusGroupEnum.AWAITING],
		[ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS, ExamStatusGroupEnum.PENDING_ANALYSIS],
		[ExamStatusEnum.PENDING_RECTIFICATION_ANALYSIS, ExamStatusGroupEnum.PENDING_ANALYSIS],
		[ExamStatusEnum.DONE, ExamStatusGroupEnum.DONE],
		[ExamStatusEnum.DONE_RECTIFICATION, ExamStatusGroupEnum.DONE],
		[ExamStatusEnum.PRINTED, ExamStatusGroupEnum.DONE],
		[ExamStatusEnum.ARCHIVED, ExamStatusGroupEnum.ARCHIVED],
	])

	static statusToBeArchived: ExamStatusEnum[] = [
		ExamStatusEnum.PENDING,
		ExamStatusEnum.RETURNED,
		ExamStatusEnum.AWAITING,
		ExamStatusEnum.PENDING_ADMINISTRATIVE_ANALYSIS,
	]
}
