import React, { useState } from 'react'
import { MedicalReportConfigHtmlsResponseDTO } from 'submodules/beerads-sdk/services/clinics/medical-report-config/dtos/response/MedicalReportConfigHtmlsResponseDTO'
import { EditorCodeCP } from 'submodules/nerit-framework-ui/common/components/editor-code/EditorCodeCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'

type ContentViewModeTP = 'header' | 'content' | 'footer'

interface ICPProps {
	medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO
	onChange: (medicalReportHtmls: MedicalReportConfigHtmlsResponseDTO) => void
}

export function TabEditMedicalReportConfigHtmlICP(props: ICPProps): JSX.Element {
	const [view, setView] = useState<ContentViewModeTP>('header')

	return (
		<>
			<FlexCP justifyContent={'center'} margin={{ bottom: 20 }}>
				<RadioGroupCP<ContentViewModeTP>
					type={'button'}
					options={[
						{ value: 'header', content: 'Cabeçalho' },
						{ value: 'content', content: 'Conteúdo' },
						{ value: 'footer', content: 'Rodapé' },
					]}
					onChange={setView}
					selected={view}
				/>
			</FlexCP>

			{view === 'header' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.headerHtml}
					onChange={(content: string) => {
						props.medicalReportHtmls.headerHtml = content
						props.onChange(props.medicalReportHtmls)
					}}
					height={'1000px'}
					mode={'html'}
				/>
			)}

			{view === 'content' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.contentHtml}
					onChange={(content: string) => {
						props.medicalReportHtmls.contentHtml = content
						props.onChange(props.medicalReportHtmls)
					}}
					height={'1000px'}
					mode={'html'}
				/>
			)}

			{view === 'footer' && (
				<EditorCodeCP
					value={props.medicalReportHtmls?.footerHtml}
					onChange={(content: string) => {
						props.medicalReportHtmls.footerHtml = content
						props.onChange(props.medicalReportHtmls)
					}}
					height={'1000px'}
					mode={'html'}
				/>
			)}
		</>
	)
}
