import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { UserLoginRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/UserLoginRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { UserChangePasswordRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/user/dtos/request/UserChangePasswordRequestDTO'
import { LoginBeeRadsBoxRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/LoginBeeRadsBoxRequestDTO'
import { CreateDigitalCertificateTokenRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/CreateDigitalCertificateTokenRequestDTO'
import { ForgotPasswordRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/ForgotPasswordRequestDTO'
import { ResetPasswordRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/ResetPasswordRequestDTO'
import { ValidatePasswordTokenRequestDTO as ValidateVerificationCodeRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/ValidatePasswordTokenRequestDTO'

export class AuthRequests {
	public static CONTROLLER_ROOT = 'auth'
	public static LOGIN_EP = 'login'
	public static REFRESH_LOGIN_EP = 'refresh-login'
	public static CREATE_API_TOKEN = 'create-api-token'
	public static CHANGE_PASSWORD_EP = 'change-password'
	public static SET_PASSWORD_EP = 'set-password/:userCode'
	public static LOGIN_BEERADS_BOX_EP = 'login-beerads-box'
	public static RESET_PASSWORD_EP = 'reset-password/:token'
	public static CREATE_DIGITAL_CERTIFICATE_TOKEN_EP = 'create-digital-certificate-token'
	public static FORGOT_PASSWORD_EP = 'forgot-password'
	public static RESEND_VERIFICATION_CODE_EMAIL_EP = 'resend-verification-code-email'
	public static VALIDATE_VERIFICATION_CODE_EP = 'validate-verification-code'

	static login = (dto: UserLoginRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.LOGIN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static createDigitalCertificateToken = (dto: CreateDigitalCertificateTokenRequestDTO): RequestConfigTP<boolean> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.CREATE_DIGITAL_CERTIFICATE_TOKEN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static refreshLogin = (): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.REFRESH_LOGIN_EP}`,
		method: HttpMethodEnum.GET,
	})

	static loginBeeRadsBox = (dto: LoginBeeRadsBoxRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.LOGIN_BEERADS_BOX_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static createApiToken = (): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.CREATE_API_TOKEN}`,
		method: HttpMethodEnum.POST,
	})

	static changePassword = (dto: UserChangePasswordRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.CHANGE_PASSWORD_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static setPassword = (userCode: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.SET_PASSWORD_EP.replace(':userCode', userCode.toString())}`,
		method: HttpMethodEnum.PUT,
	})

	static resetPassword = (passwordValidationToken: string, dto: ResetPasswordRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.RESET_PASSWORD_EP.replace(':token', passwordValidationToken)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		noAuth: true,
	})

	static forgotPassword = (dto: ForgotPasswordRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.FORGOT_PASSWORD_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		noAuth: true,
	})

	static resendVerificationCodeEmail = (dto: ForgotPasswordRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.RESEND_VERIFICATION_CODE_EMAIL_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		noAuth: true,
	})

	static validateVerificationCode = (dto: ValidateVerificationCodeRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${AuthRequests.CONTROLLER_ROOT}/${AuthRequests.VALIDATE_VERIFICATION_CODE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		noAuth: true,
	})
}
