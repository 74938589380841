import React, { useEffect, useState } from 'react'
import { FormClinicCP } from 'modules/clinic/components/form-clinic/FormClinicCP'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { ClinicFormModel } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { AddressFormValidator } from '_old/main/common/validators/AddressFormValidator'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicFormModelConverter } from 'modules/clinic/components/form-clinic/form-models/ClinicFormModelConverter'
import { AddressFormUtils } from 'submodules/nerit-framework-ui/features/address/components/form-address/inner/AddressFormUtils'

interface ICPProps {
	clinicCode: number
}

/**
 * Dados cadastrais da clinica
 */
export function ScreenContentClinicsAdminClinicData(props: ICPProps): JSX.Element {
	const [clinicFormModel, setClinicFormModel] = useState<ClinicFormModel>(new ClinicFormModel())
	const clinicFormStateManager = useFormStateManager<ClinicFormModel>(clinicFormModel)

	const [addressFormModel, setAddressFormModel] = useState<AddressFormValidator>(new AddressFormValidator())
	const addressFormStateManager = useFormStateManager<AddressFormValidator>(addressFormModel)

	const getClinicRequest = useRequest<ClinicResponseDTO>()
	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.clinicCode])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		setClinicFormModel(new ClinicFormModel())
		setAddressFormModel(new AddressFormValidator())
		getClinicRequest.runRequest(ClinicsRequests.get(props.clinicCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, 'Erro obter clínica para atualização')) return

		const clinicResult = getClinicRequest.responseData!

		setClinicFormModel(ClinicFormModelConverter.formatFormModel(clinicResult))
		setAddressFormModel(AddressFormUtils.formatFormModel(clinicResult.address))
	}

	if (getClinicRequest.isAwaiting) return <></>

	return (
		<CardCP isLoading={getClinicRequest.isAwaiting}>
			<FormClinicCP
				onSave={init}
				clinicFormStateManager={clinicFormStateManager}
				addressFormStateManager={addressFormStateManager}
				code={props.clinicCode}
			/>
		</CardCP>
	)
}
