import Editor, { Monaco } from '@monaco-editor/react'
import { defaultConfig, monacoJsonSchema } from 'modules/clinic/screens/screen-clinics-admin/inner/monaco-json-editor-modal/constants'
import React, { useEffect, useState } from 'react'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicTechConfigTP } from 'submodules/beerads-sdk/services/clinics/clinics/types/ClinicTechConfigTP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'

interface ICPProps {
	onCancel: () => void
	onSave: () => void
	clinicTechConfigTP: ClinicTechConfigTP | undefined
	clinicCode: number
}

export function MonacoJsonEditorModalCP(props: ICPProps): JSX.Element {
	const [configValue, setConfigValue] = useState(
		props.clinicTechConfigTP ? JSON.stringify(props.clinicTechConfigTP, null, 2) : JSON.stringify(defaultConfig, null, 2),
	)
	const [isJsonValid, setIsJsonValid] = useState(true)

	const isDarkTheme = localStorage.getItem('theme') === 'dark'
	const isSameConfig = configValue === JSON.stringify(props.clinicTechConfigTP ?? {}, null, 2)

	const updateClinicTechConfigRequest = useRequest<void>('none')

	useEffect(onUpdateClinicTechConfigRequestChange, [updateClinicTechConfigRequest.isAwaiting])

	function onUpdateClinicTechConfigRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				updateClinicTechConfigRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		) {
			return
		}

		props.onSave()
	}

	function handleTechConfigUpdate(): void {
		try {
			const parsedValue = JSON.parse(configValue || '{}') as ClinicTechConfigTP

			if (Object.keys(parsedValue).length === 0) {
				NotificationHelper.error('Configuração inválida', 'Por favor, verifique se o JSON é valido.')
				return
			}

			updateClinicTechConfigRequest.runRequest(
				ClinicsRequests.updateTechConfig(props.clinicCode, {
					techConfig: parsedValue,
				}),
			)
		} catch (error) {
			NotificationHelper.error('Configuração inválida', 'Por favor, verifique se o JSON é valido.')
		}
	}

	function handleMonacoMount(monaco: Monaco): void {
		monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
			validate: true,
			schemas: [
				{
					fileMatch: ['*'],
					schema: monacoJsonSchema,
				},
			],
		})
	}

	return (
		<ModalCP
			visible
			onCancel={props.onCancel}
			title={'Configurações avançadas'}
			okButtonProps={{ disabled: !isJsonValid || isSameConfig }}
			onOk={handleTechConfigUpdate}
			actionLoading={updateClinicTechConfigRequest.isAwaiting}
			width={800}
		>
			<Editor
				language="json"
				height={400}
				value={configValue}
				onChange={(value) => setConfigValue(value ?? '')}
				theme={isDarkTheme ? 'vs-dark' : 'light'}
				onMount={(_, monaco) => handleMonacoMount(monaco)}
				onValidate={(markers) => setIsJsonValid(markers.length === 0 && !!configValue)}
				options={{ minimap: { enabled: false, showSlider: 'hover' } }}
			/>

			<HelpCP text="O atalho Ctrl+Espaço pode ser usado para exibir as opções a serem editadas." type="text" />
		</ModalCP>
	)
}
