import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { PeopleFromClinicSearchRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/PeopleFromClinicSearchRequestDTO'
import { PersonClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/responses/PersonClinicResponseDTO'
import { PersonClinicTooglePersonAsAdminRequestDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/requests/PersonClinicTooglePersonAsAdminRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ToogleShouldNotifyRequestDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/requests/ToogleShouldNotifyRequestDTO'

export class PersonClinicsRequests {
	public static CONTROLLER_ROOT = 'person-clinics'
	public static TOOGLE_PERSON_AS_ADMIN_EP = 'toogle-person-as-admin'
	public static TOOGLE_SHOULD_NOTIFY_EP = 'toogle-should-notify'
	public static SEARCH_PEOPLE_EP = 'search-people'
	public static DELETE_USER_EP = 'delete/:code'

	static toogleAsAdmin = (dto: PersonClinicTooglePersonAsAdminRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PersonClinicsRequests.CONTROLLER_ROOT}/${PersonClinicsRequests.TOOGLE_PERSON_AS_ADMIN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static toogleShouldNotify = (dto: ToogleShouldNotifyRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PersonClinicsRequests.CONTROLLER_ROOT}/${PersonClinicsRequests.TOOGLE_SHOULD_NOTIFY_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static search = (dto: PeopleFromClinicSearchRequestDTO): RequestConfigTP<ListResponseDTO<PersonClinicResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PersonClinicsRequests.CONTROLLER_ROOT}/${PersonClinicsRequests.SEARCH_PEOPLE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PersonClinicsRequests.CONTROLLER_ROOT}/${PersonClinicsRequests.DELETE_USER_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.DELETE,
	})
}
