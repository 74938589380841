import { PersonFormModel } from 'modules/person/components/user/form-user/inner/PersonFormModel'
import React, { useEffect } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { PersonResponseDTO } from 'submodules/beerads-sdk/services/people/people/dtos/response/PersonResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { PersonUserSaveRequestDTO } from 'submodules/beerads-sdk/services/people/people/dtos/request/PersonUserSaveRequestDTO'
import { PeopleRequests } from 'submodules/beerads-sdk/services/people/people/PeopleRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'

interface ICPProps {
	onSave: (person: PersonResponseDTO) => void
	formStateManager: IFormStateManager<PersonFormModel>
	loading: boolean
	type: UserTypeEnum
}

/**
 * Formulario para cadastrar usuario
 */
export function FormPersonCP(props: ICPProps): JSX.Element {
	const createUserRequest = useRequest<PersonResponseDTO>()
	useEffect(onCreateUserRequestChange, [createUserRequest.isAwaiting])

	/**
	 * Cria usuario.
	 */
	async function submitForm(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(props.formStateManager))) return

		const formValues = props.formStateManager.getFormValues()!
		const dto: PersonUserSaveRequestDTO = {
			name: formValues.name,
			cpf: StringUtils.stripNonNumericChars(formValues.cpf),
			email: formValues.email,
			phone: formValues.phoneOne,
			type: props.type,
		}
		createUserRequest.runRequest(PeopleRequests.createUser(dto))
	}

	/**
	 * Retorno ao criar usuario.
	 */
	function onCreateUserRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				createUserRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
			)
		)
			return

		props.onSave(createUserRequest.responseData!)
	}

	return (
		<>
			<InputCP
				label={'CPF'}
				fieldName={'cpf'}
				required={true}
				formStateManager={props.formStateManager}
				mask={InputMaskTypeEnum.CPF}
				disabled={true}
			/>
			<InputCP label={'Nome'} fieldName={'name'} required={true} formStateManager={props.formStateManager} />
			<InputCP label={'Email'} fieldName={'email'} required={true} formStateManager={props.formStateManager} />
			<InputCP label={'Telefone'} fieldName={'phoneOne'} mask={InputMaskTypeEnum.PHONE} formStateManager={props.formStateManager} required={true} />

			<FlexCP margin={{ top: 20 }} justifyContent={'flex-end'}>
				<ButtonCP onClick={submitForm} type={'primary'} loading={props.loading || createUserRequest.isAwaiting}>
					Adicionar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
