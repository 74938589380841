import React from 'react'
import { UploadFileFromApiCP } from 'submodules/nerit-framework-ui/common/components/file/upload-file-from-api/UploadFileFromApiCP'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'

interface ICPProps {
	clinicCode: number
	clinicLogoUrl?: string
	onSave?: () => void
}

/**
 * Upload para alterar a logo da clinica.
 */
export function UploadClinicSystemLogoCP(props: ICPProps): JSX.Element | null {
	return (
		<>
			<UploadFileFromApiCP
				entityCode={props.clinicCode}
				imgUrl={props.clinicLogoUrl}
				requestsConfig={{
					uploadRequestConfigTP: ClinicsRequests.saveSystemLogo,
				}}
				styled={{
					width: '300px',
					height: '100px',
				}}
				onUploadDone={props.onSave}
				fileTypes={[MimeTypeEnum.PNG, MimeTypeEnum.JPEG]}
			/>

			<FlexCP justifyContent={'center'} margin={{ top: 20 }}>
				<HelpCP text={'Clique na imagem para editar a logo (300x100)'} type={'text'} />
			</FlexCP>
		</>
	)
}
