import { RISPartnersEnum } from 'submodules/beerads-sdk/services/integrations/enums/RISPartnersEnum'
import { ClinicTechConfigTP } from 'submodules/beerads-sdk/services/clinics/clinics/types/ClinicTechConfigTP'
import { ExamImageBucketEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamImageBucketEnum'

export const monacoJsonSchema = {
	$schema: 'http://json-schema.org/draft-07/schema#',
	type: 'object',
	properties: {
		beeradsBox: {
			type: 'object',
			properties: {
				isWeb: {
					type: 'boolean',
					description: 'Indica se o Beerads Box é web',
				},
				aeTitle: {
					type: 'string',
					description: 'AE Title do BeeradsBox',
				},
				ip: {
					type: 'string',
					description: 'Endereço IP do BeeradsBox',
				},
				port: {
					type: 'string',
					description: 'Número da porta do BeeradsBox',
				},
				token: {
					type: 'string',
					description: 'Token de autenticação para o BeeradsBox',
				},
			},
			required: ['aeTitle', 'ip', 'port', 'token'],
			description: 'Configuração para o BeeradsBox',
		},
		buckets: {
			type: 'array',
			items: {
				type: 'object',
				properties: {
					type: {
						type: 'string',
						enum: [...Object.values(ExamImageBucketEnum)],
						description: 'Tipo do bucket de imagem de exame',
					},
					bucketName: {
						type: 'string',
						description: 'Nome do bucket',
					},
					bucketRegion: {
						type: 'string',
						description: 'Região do bucket',
					},
					credentials: {
						type: 'object',
						properties: {
							accessKeyId: {
								type: 'string',
								description: 'ID da chave de acesso para o bucket',
							},
							secretAccessKey: {
								type: 'string',
								description: 'Chave de acesso secreta para o bucket',
							},
						},
						required: ['accessKeyId', 'secretAccessKey'],
						description: 'Credenciais para acessar o bucket',
					},
				},
				required: ['type', 'bucketName', 'bucketRegion', 'credentials'],
				description: 'Configuração do bucket',
			},
			description: 'Lista de buckets',
		},
		notes: {
			type: 'string',
			description: 'Notas adicionais',
		},
		risIntegrationData: {
			type: 'object',
			properties: {
				partner: {
					type: 'string',
					enum: [...Object.values(RISPartnersEnum)],
					description: 'Parceiro para integração RIS',
				},
				hasIntegrationWithAttachments: {
					type: 'boolean',
					description: 'Indica se a integração inclui anexos',
				},
				integrationTimeoutInSeconds: {
					type: 'integer',
					description: 'Tempo limite para a integração em segundos',
				},
			},
			required: ['partner'],
			description: 'Dados de integração RIS',
		},
		vxPacsAccessToken: {
			type: 'string',
			description: 'Token de acesso à API para o VX PACS',
		},
	},
	description: 'Configuração para ClinicTech',
}

export const defaultConfig: ClinicTechConfigTP = {
	beeradsBox: {
		isWeb: false,
		aeTitle: '',
		ip: '',
		port: '',
		token: '',
	},
	buckets: [
		{
			type: '' as ExamImageBucketEnum,
			bucketName: '',
			bucketRegion: '',
			credentials: {
				accessKeyId: '',
				secretAccessKey: '',
			},
		},
	],
	notes: '',
	risIntegrationData: {
		partner: '' as RISPartnersEnum,
		hasIntegrationWithAttachments: false,
		integrationTimeoutInSeconds: 0,
	},
	vxPacsAccessToken: '',
}
