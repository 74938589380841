import React, { useEffect, useState } from 'react'
import { ContentState, convertFromHTML, EditorState } from 'draft-js'
import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { AppConfig } from 'config/AppConfig'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { LeoRadUtils } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/buttons/leoRad/inner/LeoRadUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { IntercomEventEnum } from 'app/components/intercom/inner/IntercomEventEnum'

interface ICPProps {
	editorState: EditorState
	onEditorStateChange: (editorState: EditorState) => void
	examModality?: ExamModalityEnum
}

/**
 */
export function LEORadButtonICP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	useEffect(captureEvent, [])

	/**
	 * Escuta mensagens enviadas pelo iframe
	 */
	function captureEvent(): void {
		window.addEventListener(
			'message',
			function (event) {
				// Certifique-se de que a mensagem é da plataforma Leorad
				if (event.origin !== AppConfig.getInstance().leoRadUrl) return

				const contentFromLeoRAD = event.data
				const blocksFromHTML = convertFromHTML(contentFromLeoRAD)

				if (!blocksFromHTML.contentBlocks) {
					NotificationHelper.warning('Ops', 'Conteúdo selecionado está vazio, nada foi inserido no laudo.')
					return
				}

				const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
				const newEditorState = EditorState.push(props.editorState, state, 'insert-fragment')

				props.onEditorStateChange(newEditorState)
				setIsModalVisible(false)

				IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_EXPORT_MEDICAL_REPORT)
			},
			false,
		)
	}

	const leoRadToken = AppStateUtils.getLoggedUser()?.preferences?.leoRadToken

	let leoExamModality
	if (!!props.examModality) leoExamModality = LeoRadUtils.modalityVxToLeoRad.get(props.examModality)

	if (!leoRadToken) return <></>

	return (
		<>
			<ToolbarButtonICP
				editorState={props.editorState}
				onEditorStateChange={props.onEditorStateChange}
				innerContent={<IconICP iconName={'build'} />}
				onClick={() => {
					setIsModalVisible(true)
					IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_OPEN)
				}}
			/>

			<ModalCP
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				width={'95vw'}
				height={700}
				top={20}
				noFooter={true}
				title={'Laudo estruturado / Reconhecimento de voz'}
			>
				<iframe
					src={`${AppConfig.getInstance().leoRadUrl}/${leoRadToken}/?modalities=${leoExamModality}`}
					width={'100%'}
					height={'650'}
					frameBorder={'0'}
					allow={'clipboard-write; microphone; fullscreen; encrypted-media'}
					allowFullScreen={true}
				/>
			</ModalCP>
		</>
	)
}
