import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { ModalDuplicateExamCP } from 'modules/exams/components/exams/modal-duplicate-exam/ModalDuplicateExamCP'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'

interface ICPProps extends BasicStyleWrapperCPProps {
	exam: ExamResponseDTO
	onDuplicate: (createdExamIds: string[]) => void
}

/**
 */
export function ButtonDuplicateExamCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

	if (!ClinicPermissionUtils.hasAnyPermissionsInClinic(props.exam.clinic.code, ['isAdmin', 'isUser', 'isDoctorGroupAdmin', 'isDoctorGroupUser']))
		return <></>

	return (
		<>
			<ButtonCP size={'small'} onClick={() => setIsModalVisible(true)} icon={'diff'} tooltip={'Duplicar Exame'} tooltipPlacement={'topRight'} />

			<ModalDuplicateExamCP
				exam={props.exam}
				onCancel={() => setIsModalVisible(false)}
				onSave={(createdExamIds) => {
					setIsModalVisible(false)
					props.onDuplicate(createdExamIds)
				}}
				visible={isModalVisible}
			/>
		</>
	)
}
