import { IsOptional } from 'class-validator'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsNotEmpty } from '_old/main/common/validation/decorators/IsNotEmpty'
import { IsNumericString } from '_old/main/common/validation/decorators/IsNumericString'
import { IsString } from '_old/main/common/validation/decorators/IsString'
import { NotContainsSpace } from '_old/main/common/validation/decorators/NotContainsSpace'
import { AddressSaveRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/features/address/dtos/request/AddressSaveRequestDTO'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

/**
 * Determina campos & regras de validacao para fomulario de endereco
 */
export class AddressFormValidator extends FormModel implements AddressSaveRequestDTO {
	@NotContainsSpace()
	@IsString()
	@IsNotEmpty()
	zipCode: string

	@IsString()
	@IsNotEmpty()
	street: string

	@IsNumericString()
	@IsNotEmpty()
	number: string

	@IsOptional()
	@IsString()
	complement: string

	@IsString()
	@IsNotEmpty()
	neighborhood: string

	@IsString()
	@IsNotEmpty()
	city: string

	@IsString()
	@IsNotEmpty()
	state: string

	constructor(initialData?: ObjectPropsTP<AddressFormValidator>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
