import React from 'react'
import { ClinicDoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupResponseDTO'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import styled from 'styled-components'
import { ClinicDoctorGroupDoctorsConfigCP } from 'modules/doctor-groups/components/clinic-doctor-groups/clinic-doctor-group-doctors-config/ClinicDoctorGroupDoctorsConfigCP'
import { SelectClinicDoctorGroupTeamsCP } from 'modules/doctor-groups/components/clinic-doctor-groups/select-clinic-doctor-group-teams/SelectClinicDoctorGroupTeamsCP'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'

interface ICPProps {
	clinicDoctorGroup: ClinicDoctorGroupResponseDTO
	onChangeData: () => void
}

/**
 * Aba com os medicos alocados na clinica pelo grupo
 */
export function DoctorGroupClinicDoctorsTabICP(props: ICPProps): JSX.Element {
	return (
		<WrapperSCP>
			<HelpCP
				text={
					'O acesso aos exames para os médicos pode ser dados de 3 maneiras. Através do time, permitindo um médico específico ou proibindo um médico específico.'
				}
				type={'text'}
			/>

			<TitleCP underLine={true} textSize={'normal'} marginTop={20} marginBottom={30}>
				Time(s)
			</TitleCP>
			<SelectClinicDoctorGroupTeamsCP
				doctorGroupCode={props.clinicDoctorGroup.doctorGroup.code}
				clinicDoctorGroupCode={props.clinicDoctorGroup.code}
				onChangeData={props.onChangeData}
			/>

			<ClinicDoctorGroupDoctorsConfigCP
				clinicDoctorGroup={props.clinicDoctorGroup}
				type={ClinicPersonRelationModeEnum.IN}
				onChangeData={props.onChangeData}
			/>

			<ClinicDoctorGroupDoctorsConfigCP
				clinicDoctorGroup={props.clinicDoctorGroup}
				type={ClinicPersonRelationModeEnum.NOT_IN}
				onChangeData={props.onChangeData}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	padding: 0 10px;
`
