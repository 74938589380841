import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { ExamPendingAnalysisResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingAnalysisResolveRequestDTO'
import { ExamReturnedResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamReturnedResolveRequestDTO'
import { SendExamToPendingAnalysisRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/SendExamToPendingAnalysisRequestDTO'
import { ExamPendingAnalysisResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingAnalysisResponseDTO'
import { ExamReturnedResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamReturnedResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { SendExamToRectificationAnalysisRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/SendExamToRectificationAnalysisRequestDTO'
import { ExamPendingRectificationAnalysisResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingRectificationAnalysisResponseDTO'
import { ExamPendingRectificationAnalysisResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingRectificationAnalysisResolveRequestDTO'
import { SendExamToReturnedRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/SendExamToReturnedRequestDTO'

export class ExamsAnalysisRequests {
	public static CONTROLLER_ROOT = 'exams-analysis'

	// Analises Administrativas.
	public static SEND_EXAM_TO_PENDING_ADMINISTRATIVE_EP = 'send-exam-to-pending-administrative'
	public static GET_EXAM_PENDING_ADMINISTRATIVE_EP = 'get-pending-administrative/:code'
	public static RESOLVE_EXAM_PENDING_ADMINISTRATIVE_EP = 'resolve-pending-administrative/:code'

	public static SEND_EXAM_TO_RETURNED_EP = 'send-to-returned'
	public static GET_EXAM_RETURNED_EP = 'get-returned/:code'
	public static RESOLVE_EXAM_RETURNED_EP = 'resolve-returned/:code'

	// Analises Retificacao.
	public static SEND_EXAM_TO_RECTIFICATION_ANALYSIS_EP = 'send-exam-to-rectification-analysis'
	public static GET_EXAM_PENDING_RECTIFICATION_ANALYSES_EP = 'get-pending-rectification-analysis/:code'
	public static RESOLVE_EXAM_PENDING_RECTIFICATION_ANALYSIS_EP = 'resolve-pending-rectification-analysis/:code'

	public static SEND_TO_AWAITING = 'send-to-awaiting/:code'

	static getExamPendingRectificationAnalysis = (examCode: number): RequestConfigTP<ExamPendingRectificationAnalysisResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.GET_EXAM_PENDING_RECTIFICATION_ANALYSES_EP.replace(
			':code',
			examCode.toString(),
		)}`,
		method: HttpMethodEnum.GET,
	})

	static getExamPendingAnalysis = (examCode: number): RequestConfigTP<ExamPendingAnalysisResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.GET_EXAM_PENDING_ADMINISTRATIVE_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static getExamReturned = (examCode: number): RequestConfigTP<ExamReturnedResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.GET_EXAM_RETURNED_EP.replace(':code', examCode.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static sendExamToPendingAnalysis = (dto: SendExamToPendingAnalysisRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.SEND_EXAM_TO_PENDING_ADMINISTRATIVE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static sendExamToRectificationAnalysis = (dto: SendExamToRectificationAnalysisRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.SEND_EXAM_TO_RECTIFICATION_ANALYSIS_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static sendExamToReturned = (dto: SendExamToReturnedRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.SEND_EXAM_TO_RETURNED_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static resolvePendingAnalysisExam = (code: number, dto: ExamPendingAnalysisResolveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.RESOLVE_EXAM_PENDING_ADMINISTRATIVE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static resolvePendingRectificationAnalysisExam = (code: number, dto: ExamPendingRectificationAnalysisResolveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.RESOLVE_EXAM_PENDING_RECTIFICATION_ANALYSIS_EP.replace(
			':code',
			code.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static resolveReturnedExam = (code: number, dto: ExamReturnedResolveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.RESOLVE_EXAM_RETURNED_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static sendToAwaiting = (code: number): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamsAnalysisRequests.CONTROLLER_ROOT}/${ExamsAnalysisRequests.SEND_TO_AWAITING.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
	})
}
