import React, { useEffect } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { AddUserToClinicRequestDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/request/AddUserToClinicRequestDTO'
import { FindPersonBoxCP } from 'modules/person/components/common/find-person-box/FindPersonBoxCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface IAddUserModalCPProps {
	visible: boolean
	clinicCode: number
	onCancel: () => void
	onSave: () => void
}

/**
 * Adicionar usuario em uma clinica.
 */
export function ModalAddUserToClinicCP(props: IAddUserModalCPProps): JSX.Element {
	const doLinkRequest = useRequest<void>('none')
	useEffect(onDoLinkReturn, [doLinkRequest.isAwaiting])

	/**
	 * Linka com a clinica
	 */
	function doLink(personCode: number): void {
		const dto: AddUserToClinicRequestDTO = {
			clinicCode: props.clinicCode,
			role: UserTypeEnum.USER,
			personCode: personCode,
		}
		doLinkRequest.runRequest(ClinicsRequests.linkClinicToUser(dto))
		return
	}

	/**
	 * Retorno do link
	 */
	function onDoLinkReturn(): void {
		if (!RequestUtils.isValidRequestReturn(doLinkRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, 'Vinculado com sucesso', true)) return

		props.onSave()
	}

	return (
		<ModalCP
			visible={props.visible}
			actionLoading={doLinkRequest.isAwaiting}
			noFooter={true}
			onCancel={props.onCancel}
			title={'Adicionar usuário'}
			width={400}
		>
			<FindPersonBoxCP type={UserTypeEnum.USER} onClickToAdd={doLink} isAdding={doLinkRequest.isAwaiting} />
		</ModalCP>
	)
}
