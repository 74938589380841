import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectDoctorIdTypeCP } from 'modules/person/components/doctor/select-doctor-id-type/SelectDoctorIdTypeCP'
import { UfSelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/uf-select/UfSelectCP'
import { DoctorIdFormModel } from 'modules/person/components/doctor/modal-doctor-id/inner/DoctorIdFormModel'
import { DoctorIdTP } from 'submodules/beerads-sdk/services/people/people/types/DoctorIdTP'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onSave: (idData: DoctorIdTP) => void
	userCode: number
	doctorId?: DoctorIdTP
}

/**
 */
export function ModalDoctorIdCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<DoctorIdFormModel>(new DoctorIdFormModel())
	const formStateManager = useFormStateManager<DoctorIdFormModel>(formValidator)

	useEffect(init, [props.visible])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setFormValidator(new DoctorIdFormModel())
		if (!props.visible || !props.doctorId) return

		setFormValidator(
			new DoctorIdFormModel({
				type: props.doctorId.type,
				uf: props.doctorId.uf,
				id: props.doctorId.id,
				specialtyTitle: props.doctorId.specialtyTitle,
			}),
		)
	}

	/**
	 * Salva pessoa
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!
		props.onSave({
			type: formValues.type,
			id: formValues.id,
			specialtyTitle: formValues.specialtyTitle,
			uf: formValues.uf,
		})
	}

	return (
		<ModalCP title={'Nº de Registro'} visible={props.visible} onCancel={props.onCancel} onOk={save} width={300}>
			<SelectDoctorIdTypeCP required={true} formStateManager={formStateManager} fieldName={'type'} />

			<UfSelectCP formStateManager={formStateManager} fieldName={'uf'} required={true} />

			<InputCP label={'Nº do Registro'} required={true} formStateManager={formStateManager} fieldName={'id'} />

			<InputCP label={'Especialização'} formStateManager={formStateManager} fieldName={'specialtyTitle'} />
		</ModalCP>
	)
}
