import React from 'react'
import { ThemeProjectVX } from 'config/theme/project/white-labels/ThemeProjectVX'
import { ExamHistoryComments } from 'modules/exams/components/exam-history/exam-history-comments/ExamHistoryComments'
import styled from 'styled-components'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'
import { ExamHistoryEnum } from 'submodules/beerads-sdk/services/exams/history/types/ExamHistoryEnum'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'

interface ICPProps {
	pendingAnalysisHistories: ExamHistoryResponseDTO[]
}

export function CardAnalysisHistICP(props: ICPProps): JSX.Element {
	const isEventAdminAnalysis = props.pendingAnalysisHistories[0]?.event === ExamHistoryEnum.SEND_ADMIN_ANALYSIS_TO_DOCTOR_DUE_TO_DISAGREEMENT

	return (
		<AnalysisHistSCP isEventAdminAnalysis={isEventAdminAnalysis}>
			<CardCP title={isEventAdminAnalysis ? 'Análise Administrativa' : 'Análise de Retificação'}>
				<ExamHistoryComments histories={props.pendingAnalysisHistories} />
			</CardCP>
		</AnalysisHistSCP>
	)
}

const AnalysisHistSCP = styled.div<{ isEventAdminAnalysis: boolean }>`
	.ant-card {
		border: solid 2px ${(props) => (props.isEventAdminAnalysis ? ThemeProjectVX.warning : ThemeProjectVX.error)};
	}
`
