import React from 'react'
import { TagCPColorTP } from 'submodules/nerit-framework-ui/common/components/tag/inner/TagCPColorTP'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'

interface ITagCPProps {
	isActive: boolean
}

/**
 * Tag para mostrar se está ativo ou não.
 */
export function TagActiveCP(props: ITagCPProps): JSX.Element {
	let color: TagCPColorTP = 'red'
	if (props.isActive) color = 'green'

	return <TagCP color={color} content={props.isActive ? 'Ativo' : 'Desativado'} />
}
