import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import React from 'react'

/**
 * Icon: Hospital
 * @author Lucas Rosa
 */

export const HospitalIconCP = (props: IIconProps): JSX.Element => {
	return (
		<svg
			style={{
				width: props.size || '22',
				height: props.size || '22',
				fill: props.color || 'inherit',
			}}
			width={props.size || '22'}
			height={props.size || '22'}
			fill={props.color || 'inherit'}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 422 422"
		>
			{/* tslint:disable-next-line: max-line-length*/}
			<path d="M173.946 74.015c0-5.523 4.477-10 10-10h17.312V46.703c0-5.523 4.477-10 10-10s10 4.477 10 10v17.312h17.312c5.523 0 10 4.477 10 10s-4.477 10-10 10h-17.312v17.313c0 5.523-4.477 10-10 10s-10-4.477-10-10V84.015h-17.312c-5.523 0-10-4.477-10-10zm240.723 338.49c0 5.523-4.477 10-10 10H17.835c-5.523 0-10-4.477-10-10s4.477-10 10-10H56.19V74.014c0-5.523 4.477-10 10-10h71.725C142.812 27.913 173.836 0 211.258 0c37.421 0 68.445 27.914 73.341 64.015l71.725-.001a10.001 10.001 0 0 1 10 10v328.491h38.345c5.523 0 10 4.477 10 10zM157.242 74.015c0 29.784 24.231 54.015 54.016 54.015 29.784 0 54.014-24.231 54.014-54.015S241.042 20 211.258 20c-29.784 0-54.016 24.231-54.016 54.015zm44.016 256.461h-51.581v72.029h51.576l.005-72.029zm71.581 0h-51.582l-.005 72.029h51.586v-72.029zm73.486-246.462H284.6c-4.896 36.101-35.92 64.016-73.341 64.016-37.422 0-68.447-27.915-73.343-64.016H76.19v318.491h53.487v-82.029c0-5.523 4.477-10 10-10h143.162c5.523 0 10 4.477 10 10v82.029h53.485V84.014zm-66.339 88.155h34.076c5.523 0 10 4.477 10 10v89.148c0 5.523-4.477 10-10 10h-34.076c-5.523 0-10-4.477-10-10v-89.148c0-5.523 4.477-10 10-10zm10 89.148h14.076v-69.148h-14.076v69.148zm-51.692-79.148v89.148c0 5.523-4.477 10-10 10H194.22c-5.523 0-10-4.477-10-10v-89.148c0-5.523 4.477-10 10-10h34.074c5.523 0 10 4.477 10 10zm-20 10H204.22v69.148h14.074v-69.148zm-65.764-10v89.148c0 5.523-4.477 10-10 10h-34.075c-5.523 0-10-4.477-10-10v-89.148c0-5.523 4.477-10 10-10h34.075c5.523 0 10 4.477 10 10zm-20 10h-14.075v69.148h14.075v-69.148z" />
		</svg>
	)
}
