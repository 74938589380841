import React from 'react'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import styled from 'styled-components'

interface LogoCPProps {
	height?: number
}

/**
 * Logo do sistema.
 */
export function LogoLoginCP(props: LogoCPProps): JSX.Element {
	return <ImgSCP height={props.height} src={ThemeProject.logoLoginUrl} alt={'logo'} />
}

const ImgSCP = styled.img<{ height?: number }>`
	height: ${(props) => (props.height ? `${props.height}px` : '25%')};
	margin: 20px 0;
`
