import React, { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as _ from 'lodash'

import { ContentState } from 'draft-js'
import {
	PluginTableCellTP,
	CellLocationTP,
	OnCellBlurCallbackTP,
} from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginTypes'

interface ITPluginTableCellICPProps extends PluginTableCellTP {
	blockKey: string
	rowNumber: number
	cellNumber: number
	width: number
	contentState: ContentState
	setReadOnly: (readOnly: boolean) => void
	onCellEditEnd: OnCellBlurCallbackTP
	customStyles?: React.CSSProperties
}

/**
 * COMPONENTE
 * Celula de 01 tabela gerada pelo plugin table para editor
 * wysiwyg customizado DraftJS.
 *
 * @author hjcostabr
 */
export const TPluginTableCellICP = (props: ITPluginTableCellICPProps): JSX.Element => {
	const [cellValue, setCellValue] = useState<string>('')

	useEffect(init, [])

	function init(): void {
		setCellValue(props.text || '')
	}

	function onChange(event: SyntheticEvent): void {
		const value: string = _.get(event, 'target.value')
		setCellValue(value)
	}

	function onInputFocus(): void {
		props.setReadOnly(true)
	}

	function onInputBlur(): void {
		const cellLocation: CellLocationTP = {
			blockKey: props.blockKey,
			rowNumber: props.rowNumber,
			cellNumber: props.cellNumber,
		}

		const cellData: PluginTableCellTP = {
			text: cellValue,
		}

		props.onCellEditEnd(props.contentState, cellLocation, cellData)
		props.setReadOnly(false)
	}

	return (
		<TdSCP width={props.width}>
			<InputSCP type={'text'} onChange={onChange} value={cellValue} onFocus={onInputFocus} onBlur={onInputBlur} style={props.customStyles} />
		</TdSCP>
	)
}

const TdSCP = styled.td<{ width: number }>`
	width: ${(props) => props.width};
	border: 1px solid;
`

const InputSCP = styled.input`
	width: 100%;
	border: 0 none;
`
