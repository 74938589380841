import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { UserTypeEnum, UserTypeLabelEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { DoctorGroupPersonResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPersonResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalAddPersonToDoctorGroupCP } from 'modules/doctor-groups/components/doctor-groups/modal-add-person-doctor-group/ModalAddPersonToDoctorGroupCP'
import { TableDoctorGroupPeopleUtils } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-people/inner/TableDoctorGroupPeopleUtils'
import { ModalDoctorGroupDoctorCP } from 'modules/doctor-groups/components/people/modal-doctor-group-doctor/ModalDoctorGroupDoctorCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { SubtitleTableDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/table-doctor-group-people/inner/SubtitleTableDoctorGroupPeopleCP'
import { ButtonExportTableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/inner/ButtonExportTableFromApiCP'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface ICPProps {
	doctorGroupCode: number
	role: UserTypeEnum
}

/**
 * Lista de pessoas de um grupo de medicos (medicos ou usuarios)
 */
export function TableDoctorGroupPeopleCP(props: ICPProps): JSX.Element {
	const [selectedDoctorGroupDoctor, setSelectedDoctorGroupDoctor] = useState<DoctorGroupPersonResponseDTO>()
	const [isDoctorGroupDoctorModalVisible, setIsDoctorGroupDoctorModalVisible] = useState<boolean>(false)

	const [isNewPersonModalVisible, setIsNewPersonModalVisible] = useState<boolean>(false)

	const [reloadTable, setReloadTable] = useState<number>()
	useEffect(() => setReloadTable(TableUtils.getReloadNumber()), [props.doctorGroupCode])

	return (
		<>
			<AccessControlCP
				permission={{
					byHasAnyPermissionInDoctorGroup: {
						doctorGroupCode: props.doctorGroupCode,
						permissions: ['isDoctorGroupAdmin'],
					},
				}}
			>
				<FlexCP justifyContent={'space-between'} margin={{ bottom: 10, right: 20, left: 20 }}>
					<ButtonExportTableFromApiCP
						requestConfigTP={() =>
							DoctorGroupsRequests.exportPeople({
								doctorGroupCode: props.doctorGroupCode,
								role: props.role,
							})
						}
						fileName={`${UserTypeLabelEnum[props.role].toLowerCase()}_grupo`}
						buttonSize={'default'}
					/>
					<ButtonCP onClick={() => setIsNewPersonModalVisible(true)}>Novo {UserTypeLabelEnum[props.role]}</ButtonCP>
				</FlexCP>
			</AccessControlCP>

			<TableFromApiCP<DoctorGroupPersonResponseDTO>
				appearance={{
					reloadButtonSize: 'small',
					wrappedOnCard: true,
					showReloadButton: true,
					recordLabel: `${UserTypeLabelEnum[props.role].toLowerCase()}`,
					showTotalOnHeader: true,
				}}
				row={{
					setClass: (record) => (record.status !== UserDoctorGroupStatusEnum.ACTIVE ? 'row-disabled' : ''),
				}}
				filter={{
					position: 'left',
					onFilterLoadedList: (list, searchTxt) => list?.filter((record) => TableUtils.containsTxt(record.person.name, searchTxt)),
				}}
				shouldLoadData={reloadTable}
				apiConfig={{
					requestConfigTP: () =>
						DoctorGroupsRequests.searchPeople({
							doctorGroupCode: props.doctorGroupCode,
							role: props.role,
						}),
				}}
				columns={TableDoctorGroupPeopleUtils.getColumns(
					props.role,
					props.doctorGroupCode,
					(doctorGroupDoctor) => {
						setSelectedDoctorGroupDoctor(doctorGroupDoctor)
						setIsDoctorGroupDoctorModalVisible(true)
					},
					() => setReloadTable(TableUtils.getReloadNumber()),
				)}
			/>

			<SubtitleTableDoctorGroupPeopleCP role={props.role} />

			<ModalAddPersonToDoctorGroupCP
				role={props.role}
				doctorGroupCode={props.doctorGroupCode}
				visible={isNewPersonModalVisible}
				onCancel={() => setIsNewPersonModalVisible(false)}
				onSave={() => {
					setIsNewPersonModalVisible(false)
					setReloadTable(TableUtils.getReloadNumber())
				}}
			/>

			{!!selectedDoctorGroupDoctor && (
				<ModalDoctorGroupDoctorCP
					visible={isDoctorGroupDoctorModalVisible}
					onCancel={() => setIsDoctorGroupDoctorModalVisible(false)}
					onSave={() => {
						setIsDoctorGroupDoctorModalVisible(false)
						setReloadTable(TableUtils.getReloadNumber())
					}}
					doctorGroupDoctor={selectedDoctorGroupDoctor}
				/>
			)}
		</>
	)
}
