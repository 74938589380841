import { DoctorGroupClinicDoctorsTabICP } from 'modules/doctor-groups/components/clinic-doctor-groups/drawer-clinic-doctor-group/inner/DoctorGroupClinicDoctorsTabICP'
import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { ClinicInfoICP } from 'modules/doctor-groups/components/clinic-doctor-groups/drawer-clinic-doctor-group/inner/ClinicInfoICP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { FormClinicDoctorGroupCP } from 'modules/doctor-groups/components/doctor-groups/form-clinic-doctor-group/FormClinicDoctorGroupCP'
import { ClinicDoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TagClinicCategoryCP } from 'modules/clinic/components/tag-clinic-category/TagClinicCategoryCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'

interface ICPProps {
	visible: boolean
	clinicCode: number
	doctorGroupCode: number
	onClose: (dataChanged?: boolean) => void
}

/**
 * Drawer de detalhes do relacionamento de uma clínica com o grupo de médicos.
 */
export function DrawerClinicDoctorGroupCP(props: ICPProps): JSX.Element {
	const [dataChanged, setDataChanged] = useState<boolean>(false)

	const [loadedClinicDoctorGroup, setLoadedClinicDoctorGroup] = useState<ClinicDoctorGroupResponseDTO>()
	const getClinicDoctorGroupRequest = useRequest<ClinicDoctorGroupResponseDTO>()
	useEffect(onGetClinicDoctorGroupRequestChange, [getClinicDoctorGroupRequest.isAwaiting])

	useEffect(init, [props.doctorGroupCode, props.clinicCode, props.visible])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		if (!props.visible) return

		getClinicDoctorGroupRequest.runRequest(ClinicDoctorGroupRequests.getOne(props.clinicCode, props.doctorGroupCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicDoctorGroupRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicDoctorGroupRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setLoadedClinicDoctorGroup(getClinicDoctorGroupRequest.responseData!)
	}

	return (
		<DrawerCP
			visible={props.visible}
			onClose={() => {
				props.onClose(dataChanged)
				setLoadedClinicDoctorGroup(undefined)
			}}
			title={
				!loadedClinicDoctorGroup ? (
					'... Carregando ...'
				) : (
					<FlexCP>
						<TextCP text={loadedClinicDoctorGroup.clinic.fantasyName} marginRight={10} />
						<TagClinicCategoryCP category={loadedClinicDoctorGroup.category} />
					</FlexCP>
				)
			}
			width={600}
			loading={getClinicDoctorGroupRequest.isAwaiting}
		>
			{!!loadedClinicDoctorGroup && (
				<>
					<ClinicInfoICP clinic={loadedClinicDoctorGroup.clinic} />

					<TabsCP
						defaultActiveKey={'config'}
						tabs={[
							{
								key: 'config',
								title: 'Informações',
								content: (
									<FormClinicDoctorGroupCP
										doctorGroupCode={props.doctorGroupCode}
										clinicCode={props.clinicCode}
										loadedClinicDoctorGroup={loadedClinicDoctorGroup}
										onSave={() => {
											init()
											setDataChanged(true)
										}}
									/>
								),
							},
							{
								key: 'doctors',
								title: 'Médicos',
								content: (
									<DoctorGroupClinicDoctorsTabICP
										clinicDoctorGroup={loadedClinicDoctorGroup}
										onChangeData={() => setDataChanged(true)}
									/>
								),
							},
						]}
					/>
				</>
			)}
		</DrawerCP>
	)
}
