import { IntercomEventEnum } from 'app/components/intercom/inner/IntercomEventEnum'

/**
 * Classe para auxiliar na manipulacao do intercom
 */
export class IntercomUtils {
	/**
	 */
	static trackEvent(event: IntercomEventEnum): void {
		const intercom = IntercomUtils.getIntercom()
		if (!intercom) return

		intercom('trackEvent', event)
	}

	/**
	 */
	static showNewMessage(message: string): void {
		const intercom = IntercomUtils.getIntercom()
		if (!intercom) return

		intercom('showNewMessage', message)
	}

	/**
	 */
	static setIntercomVisibility(show: boolean): void {
		const intercom = IntercomUtils.getIntercom()
		if (!intercom) return

		intercom('update', { hide_default_launcher: !show })
	}

	/**
	 */
	private static getIntercom(): any {
		return (window as any).Intercom
	}
}
