import React from 'react'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ProfileMenuItemCP } from 'submodules/nerit-framework-ui/common/components/menu/menu-item-profile/ProfileMenuItemCP'
import { PrivatePatientPortalRouter } from 'app/routers/private/PrivatePatientPortalRouter'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AuthActions } from 'app/utils/AuthActions'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { HeaderVxCP } from 'app/components/header-vx/HeaderVxCP'

/**
 */
export function ScreenPrivatePatientPortal(): JSX.Element {
	return (
		<LayoutCP
			mainLayout={true}
			header={
				<HeaderVxCP title={'Laudo Online'}>
					<FlexCP justifyContent={'flex-end'} alignItems={'center'}>
						<ProfileMenuItemCP
							isTopMenu={true}
							optionTitle={AppStateUtils.getLoggedPatientPortal()!.patientName}
							profileOptions={[
								{
									text: 'Sair',
									action: () => {
										AuthActions.logoutPatientPortal()
										NeritFrameworkRoutingHelper.historyPush(PublicRouter.PATIENT_PORTAL_LOGIN)
									},
								},
							]}
						/>
					</FlexCP>
				</HeaderVxCP>
			}
			content={
				<ContentCP overflowVertical={true}>
					<PrivatePatientPortalRouter />
				</ContentCP>
			}
		/>
	)
}
