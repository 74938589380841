/**
 * UTILS
 * Encapsula definicao de metodos genericos uteis para manipulacao
 * de strings.
 *
 * @author hjcostabr
 */
export class StringUtils {
	/** Altera & retorna 01 string na forma 'camel-case'. */
	static toCamelCase(str: string): string {
		if (!str.match(/-/)) return str

		return str.split('-').reduce((camelCaseStr: string, strToken: string) => {
			if (!!camelCaseStr) strToken = strToken.charAt(0).toUpperCase() + strToken.slice(1)
			return camelCaseStr + strToken
		}, '')
	}

	/** Transforma & retorna string da forma 'camelCase' na forma 'KebabCase'. */
	public static camelCaseToKebabCase(str: string): string {
		return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
	}

	public static stripNonNumericChars(str: string): string {
		return str.replace(/\D/g, '')
	}
}
