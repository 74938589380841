import React from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { UploadClinicLogoCP } from 'modules/clinic/components/upload-clinic-logo/UploadClinicLogoCP'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	clinicCode: number
	clinicLogoUrl?: string
	onSave: () => void
}

/**
 * Modal para alterar a logo da clinica.
 */
export function ModalClinicLogoCP(props: ICPProps): JSX.Element | null {
	return (
		<ModalCP title={'Logo da Unidade Hospitalar'} visible={props.visible} onCancel={props.onCancel} noFooter={true} width={350}>
			<UploadClinicLogoCP clinicCode={props.clinicCode} clinicLogoUrl={props.clinicLogoUrl} onSave={props.onSave} />
		</ModalCP>
	)
}
