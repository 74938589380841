import { ButtonSize, ButtonType } from 'antd/es/button'
import React from 'react'
import { ExamImageDataResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/inner/ExamImageDataResponseDTO'
import { ButtonMultiActionDownloadExamDicomCP } from 'modules/exams/components/exams/button-download-exam/button-multi-action-dicom-download/ButtonMultiActionDownloadExamDicomCP'
import { ButtonDownloadNonDicomExamCP } from 'modules/exams/components/exams/button-download-exam/exam-non-dicom-download/ButtonDownloadNonDicomExamCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'

interface ICPProps {
	exam: {
		id: string
		examDescription: string
		patientName: string
		imageData?: ExamImageDataResponseDTO
	}
	iconOnly?: boolean
	size?: ButtonSize
	type?: ButtonType
	downloadBy: 'patient' | 'user'
}

/**
 */
export function ButtonDownloadExamCP(props: ICPProps): JSX.Element {
	if (!props.exam.imageData) {
		return (
			<ButtonCP disabled={true} size={props.size}>
				Imagem não disponível
			</ButtonCP>
		)
	}

	return (
		<>
			{props.exam.imageData?.isDicom === true && (
				<ButtonMultiActionDownloadExamDicomCP
					downloadConfig={{
						single: {
							examId: props.exam.id,
							patientName: props.exam.patientName,
							examDescription: props.exam.examDescription,
							imageData: props.exam.imageData,
						},
						downloadBy: props.downloadBy,
					}}
					size={props.size}
					iconOnly={props.iconOnly}
					type={props.type}
				/>
			)}
			{props.exam.imageData?.isDicom === false && (
				<ButtonDownloadNonDicomExamCP
					exam={props.exam}
					appearance={{
						size: props.size,
						iconOnly: props.iconOnly,
						type: props.type,
					}}
				/>
			)}
		</>
	)
}
