import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'
import { SelectExamSlaCP } from 'modules/exams/components/exams/select-exam-sla/SelectExamSlaCP'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	onFilter: () => void
	filterFormStateManager: IFormStateManager<ExamFilterFormModel>
}

/**
 */
export function FiltersDrawerExamsICP(props: ICPProps): JSX.Element {
	return (
		<ContentSCP>
			<FlexCP alignItems={'center'}>
				<FlexOneICP>
					<RowCP>
						<ColumnCP size={6}>
							<SelectExamModalityCP
								label={'Modalidades'}
								isMultiple={true}
								formStateManager={props.filterFormStateManager}
								fieldName={'modalities'}
							/>
						</ColumnCP>
						<ColumnCP size={6}>
							<SelectClinicFromLoggedUserCP
								permissions={
									props.filterFormStateManager.getFieldValue('viewAsDoctor') === true
										? ['isDoctor']
										: ['isAdmin', 'isDoctorGroupAdmin', 'isUser']
								}
								value={{
									byFormStateManager: {
										formStateManager: props.filterFormStateManager,
										fieldName: 'clinicCodes',
									},
								}}
								multiple={{
									isMultiple: true,
									maxTagCount: 1,
									clearSearchTextOnSelect: false,
								}}
							/>
						</ColumnCP>
						<ColumnCP size={4}>
							<SelectExamUrgencyTypeCP label={'Urgência'} formStateManager={props.filterFormStateManager} fieldName={'urgencies'} />
						</ColumnCP>
						<ColumnCP size={2}>
							<SelectExamSlaCP formStateManager={props.filterFormStateManager} fieldName={'sla'} />
						</ColumnCP>
						<ColumnCP size={6}>
							<SelectDoctorGroupFromLoggedUserCP formStateManager={props.filterFormStateManager} fieldName={'doctorGroupCode'} />
						</ColumnCP>
					</RowCP>
				</FlexOneICP>

				<ButtonSmallScreeSCP>
					<ButtonCP icon={'search'} type={'primary'} onClick={props.onFilter}>
						Filtrar
					</ButtonCP>
				</ButtonSmallScreeSCP>
			</FlexCP>
		</ContentSCP>
	)
}

const ContentSCP = styled.div`
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	padding: 10px;
`

const ButtonSmallScreeSCP = styled.div`
	margin-top: 17px;
	margin-left: 10px;

	> div {
		width: 100%;

		button {
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
`
