import React from 'react'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import styled from 'styled-components'
import { TooltipCP } from 'submodules/nerit-framework-ui/common/components/tooltip/TooltipCP'

interface ICPProps {
	doctors: DoctorOnMedicalTimetableResponseDTO[]
	numberOfRows: number
}

/**
 */
export function AlertExtraDoctorOnMedicalTimetableCP(props: ICPProps): JSX.Element {
	return (
		<TooltipCP
			text={
				<>
					Médicos:
					<ul>
						{props.doctors
							.filter((doctorOnMedicalTimetable, index) => index >= props.numberOfRows)
							.map((doctorOnMedicalTimetable) => (
								<li style={{ whiteSpace: 'nowrap', marginLeft: -20 }}>{doctorOnMedicalTimetable.doctor.name}</li>
							))}
					</ul>
				</>
			}
		>
			<AlertWrapperSCP>
				<AlertCP margin={{ top: 5 }} message={`+ ${props.doctors.length - props.numberOfRows}`} type={'warning'} />
			</AlertWrapperSCP>
		</TooltipCP>
	)
}

const AlertWrapperSCP = styled.div`
	.ant-alert {
		text-align: center;
		font-size: 11px;
		padding: 5px !important;
	}
`
