import React from 'react'
import { Descriptions } from 'antd'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export type DescriptionItemTP = {
	label?: string
	text?: React.ReactNode
	hide?: boolean
}

interface ScpProps {
	padding?: string
}

interface IDescriptionCPProps {
	items: DescriptionItemTP[]
	title?: string
	bordered?: boolean
	numberOfColumns?: number
	style?: ScpProps
}

/**
 */
export function DescriptionCP(props: IDescriptionCPProps): JSX.Element {
	return (
		<WrapperSCP scp={props.style}>
			<Descriptions title={props.title} column={props.numberOfColumns} bordered={props.bordered ?? true}>
				{props.items
					.filter((item) => !item.hide)
					.map((item) => (
						<Descriptions.Item label={item.label} key={item.label}>
							{item.text}
						</Descriptions.Item>
					))}
			</Descriptions>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div<{ scp?: ScpProps }>`
	.ant-descriptions-item-label,
	.ant-descriptions-item-content {
		padding: ${(props) => props.scp?.padding ?? undefined} !important;
	}
	.ant-descriptions-item-colon {
		background-color: ${ThemeProject.backgroundMain} !important;
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
	.ant-descriptions-item-content {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
	}
`
