import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { PatientPortalLoginRequestDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/request/PatientPortalLoginRequestDTO'
import { PatientPortalExamsSearchResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalExamsSearchResponseDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

export class PatientPortalRequests {
	public static CONTROLLER_ROOT = 'patient-portal'
	public static PATIENT_LOGIN_EP = 'login'
	public static SEARCH_PATIENT_EXAMS_EP = 'search-patient-exams/:currentExamId'
	public static DOWNLOAD_MEDICAL_REPORT_EP = 'download-medical-report/:examId'
	public static PRINT_MEDICAL_REPORT_EP = 'print/:examId'
	public static EXAM_IMAGES_LINK_EP = 'exam-images-link/:examId'
	public static SEARCH_ATTACHMENTS_EP = 'search-attachments/:examId'
	public static PREPARE_VIEWER_EP = 'prepare-viewer/:examId'

	static login = (dto: PatientPortalLoginRequestDTO): RequestConfigTP<PatientPortalLoginResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.PATIENT_LOGIN_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static searchPatientExams = (examId: string): RequestConfigTP<PatientPortalExamsSearchResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.SEARCH_PATIENT_EXAMS_EP.replace(':currentExamId', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static downloadMedicalReport = (examId: string): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.DOWNLOAD_MEDICAL_REPORT_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
		responseType: 'blob',
	})

	static getExamImagesLink = (examId: string, forcedBaseUrl: string): RequestConfigTP<string> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		baseUrl: forcedBaseUrl,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.EXAM_IMAGES_LINK_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static searchAttachments = (examId: string): RequestConfigTP<FileDataTP> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.SEARCH_ATTACHMENTS_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static printMedicalReport = (examId: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.PRINT_MEDICAL_REPORT_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})

	static prepareViewer = (examId: string): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${PatientPortalRequests.CONTROLLER_ROOT}/${PatientPortalRequests.PREPARE_VIEWER_EP.replace(':examId', examId)}`,
		method: HttpMethodEnum.GET,
	})
}
