export enum ClinicTypeEnum {
	TRAD = 'TRAD',
	OCUP = 'OCUP',
	ODONTO = 'ODONTO',
	VET = 'VET',
}

export enum ClinicTypeLabelEnum {
	TRAD = 'Radiologia',
	OCUP = 'Ocupacional',
	ODONTO = 'Odontológica',
	VET = 'Veterinária',
}
