/**
 * @author Stella
 * @author Lucas Rosa
 * @author Victor
 */
export enum InputMaskTypeEnum {
	DATE = 'date',
	CPF = 'cpf',
	PHONE = 'phone',
	CEP = 'cep',
	CNPJ = 'cnpj',
	TIME = 'time',
	ADDRESS_NUMBER = 'addressNumber',
}
