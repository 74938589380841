import React from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { MedicalReportHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/MedicalReportHistoryResponseDTO'
import { MedicalReportHistoryRequests } from 'submodules/beerads-sdk/services/exams/history/MedicalReportHistoryRequests'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { MedicalReportHistoryEnum, MedicalReportHistoryLabelEnum } from 'submodules/beerads-sdk/services/exams/history/types/MedicalReportHistoryEnum'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { ButtonShowMedicalReportContentCP } from 'modules/exams/components/medical-report/button-show-medical-report-content/ButtonShowMedicalReportContentCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { WrapperMedicalReportEditorCP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorCP'
import { WrapperMedicalReportEditorContentAreaICP } from 'modules/exams/components/medical-report/editor-medical-report/wrappers/WrapperMedicalReportEditorContentAreaICP'

interface ICPProps {
	examCode: number
	examModality: ExamModalityEnum
	onCopy?: (jsonContent: any) => void
}

/**
 */
export function TableMedicalReportHistoryCP(props: ICPProps): JSX.Element {
	return (
		<TableFromApiCP<MedicalReportHistoryResponseDTO>
			shouldLoadData={1}
			apiConfig={{
				requestConfigTP: () =>
					MedicalReportHistoryRequests.search({
						examCode: props.examCode,
					}),
			}}
			expand={{
				expandedRowRender: (record) => (
					<>
						{record.event === MedicalReportHistoryEnum.CREATED ? (
							<AlertCP message={'Laudo sem conteúdo, apenas criação'} type={'success'} />
						) : (
							<WrapperMedicalReportEditorCP>
								<WrapperMedicalReportEditorContentAreaICP hideToolbar={true}>
									{/* eslint-disable-next-line @typescript-eslint/naming-convention */}
									<div dangerouslySetInnerHTML={{ __html: `${record.htmlContent ?? ''}` }} />
								</WrapperMedicalReportEditorContentAreaICP>
							</WrapperMedicalReportEditorCP>
						)}
					</>
				),
			}}
			columns={[
				{
					title: 'Data',
					render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITH_TIME_H_M),
				},
				{
					title: 'Médico',
					render: (text, record) => record.user.name,
				},
				{
					render: (text, record) => <TagCP content={MedicalReportHistoryLabelEnum[record.event]} />,
				},
				{
					render: (text, record) => (
						<>
							{record.event !== MedicalReportHistoryEnum.CREATED && !!props.onCopy && (
								<ButtonShowMedicalReportContentCP
									medicalReportContent={{
										reportCode: record.medicalReportCode,
										html: record.htmlContent ?? '',
										json: record.jsonContent ?? {},
									}}
									onCopy={() => props.onCopy!(record?.jsonContent)}
								/>
							)}
						</>
					),
				},
			]}
		/>
	)
}
