import React, { useEffect } from 'react'
import { CascaderValueTP, SelectCascaderCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select-cascader/SelectCascaderCP'
import { HospitalIconCP } from '_old/main/common/components/icons/HospitalIconCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ClinicDoctorGroupsNamesResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicDoctorGroupsNamesResponseDTO'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { ClinicTypeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicTypeEnum'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

interface ICPProps<FormModelTP extends FormModel> {
	formStateManagerConfig?: {
		formStateManager: IFormStateManager<FormModelTP>
		formFieldClinic: keyof FormModelTP
		formFieldDoctorGroup: keyof FormModelTP
	}
	onChange?: (clinicCode: number, clinicType: ClinicTypeEnum, doctorGroup: DoctorGroupNamesFromClinicResponseDTO) => void
	disable?: boolean
}

/**
 */
export function SelectCascaderClinicDoctorGroupsCP<FormModelTP extends FormModel>(props: ICPProps<FormModelTP>): JSX.Element {
	const getClinicDoctorGroups = useRequest<ListResponseDTO<ClinicDoctorGroupsNamesResponseDTO>>()
	useEffect(() => getClinicDoctorGroups.runRequest(ClinicsRequests.namesClinicDoctorGroups()), [])

	/**
	 */
	function onChange(selectedValue: CascaderValueTP): void {
		if (!selectedValue[0] || !selectedValue[1]) return console.log('Valores não informados', selectedValue)

		if (!!props.formStateManagerConfig) {
			props.formStateManagerConfig.formStateManager.changeFieldValue(props.formStateManagerConfig.formFieldClinic, +selectedValue[0])
			props.formStateManagerConfig.formStateManager.changeFieldValue(props.formStateManagerConfig.formFieldDoctorGroup, +selectedValue[1])
		}

		if (!!props.onChange) {
			// Obtem o objeto completo da clinica
			const foundClinic = getClinicDoctorGroups.responseData?.list.find((clinic) => clinic.code === +selectedValue[0]!)
			if (!foundClinic) return console.log('Clinica nao encontrada', selectedValue)

			const foundDoctorGroup = foundClinic.doctorGroups.find((doctorGroup) => doctorGroup.code === +selectedValue[1]!)
			if (!foundDoctorGroup) return console.log('Grupo nao encontrado', selectedValue)

			props.onChange(+selectedValue[0], foundClinic.type, foundDoctorGroup)
		}
	}

	return (
		<SelectCascaderCP
			loading={getClinicDoctorGroups.isAwaiting}
			appearance={{
				prefixIcon: <HospitalIconCP />,
				allowClear: false,
				size: 'large',
				disabled: props.disable,
			}}
			label={'Clínica e Grupo de Médico'}
			options={
				getClinicDoctorGroups.responseData?.list?.map((clinic) => ({
					label: clinic.name,
					value: clinic.code.toString(),
					children: clinic.doctorGroups.map((doctorGroup) => ({
						label: doctorGroup.name,
						value: doctorGroup.code.toString(),
					})),
				})) ?? []
			}
			onChange={onChange}
		/>
	)
}
