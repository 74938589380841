import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'

interface ICPProps {
	examCode: number
	label?: string
	onIntegrate?: () => void
}

/**
 */
export function ButtonIntegrateMedicalReportCP(props: ICPProps): JSX.Element {
	const integrateMedicalReportRequest = useRequest<void>('none')
	useEffect(onIntegrateMedicalReportRequestChange, [integrateMedicalReportRequest.isAwaiting])

	/**
	 */
	function onIntegrateMedicalReportRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				integrateMedicalReportRequest,
				'Erro ao chamar integração',
				'Laudo enviado para sistema parceiro com sucesso.',
				true,
			)
		)
			return

		if (!!props.onIntegrate) props.onIntegrate()
	}

	return (
		<ButtonCP
			size={'small'}
			loading={integrateMedicalReportRequest.isAwaiting}
			onClick={() => integrateMedicalReportRequest.runRequest(MedicalReportRequests.integrateMedicalReports(props.examCode))}
		>
			{props.label ?? 'Reenviar laudo'}
		</ButtonCP>
	)
}
