import { SurveyAnswerSearchRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerSearchRequestDTO'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { Space4LeadsSystemApiEnum } from 'submodules/space4leads-sdk/common/Space4LeadsSystemApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { SurveyAnswerUpdateRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerUpdateRequestDTO'
import { SurveyAnswerCreateRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerCreateRequestDTO'
import { GetLastPersonAnswerRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/GetLastPersonAnswerRequestDTO'

export class SurveyAnswersRequests {
	public static CONTROLLER_ROOT = 'survey-answers'
	public static SEARCH_EP = 'search'
	public static GET_LAST_PERSON_ANSWER_EP = 'get-last-person-answer'

	static search = (dto: SurveyAnswerSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveyAnswersRequests.CONTROLLER_ROOT}/${SurveyAnswersRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static create = (surveyToken: string, dto: SurveyAnswerCreateRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveyAnswersRequests.CONTROLLER_ROOT}/${surveyToken}`,
		method: HttpMethodEnum.POST,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }],
	})

	static update = (code: number, dto: SurveyAnswerUpdateRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveyAnswersRequests.CONTROLLER_ROOT}/${code}`,
		method: HttpMethodEnum.PUT,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }],
	})

	static getLastPersonAnswer = (dto: GetLastPersonAnswerRequestDTO, projectCode?: number): RequestConfigTP => ({
		baseUrlType: Space4LeadsSystemApiEnum.SPACE4LEADS_API,
		url: `${SurveyAnswersRequests.CONTROLLER_ROOT}/${SurveyAnswersRequests.GET_LAST_PERSON_ANSWER_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		headers: !projectCode ? undefined : [{ headerName: 'on-behalf-of', headerValue: projectCode.toString() }],
	})
}
