export enum ExamReasonOccupationalEnum {
	ADM = 'ADM', // Admission - Admição
	PER = 'PER', // Periodic - Periódico
	DIS = 'DIS', // Dismissal - Demicional
	PRI = 'PRI', // Private - Particular
	FCH = 'FCH', // Function Change - Mudança de função
	CEX = 'CEX', // Complementary Exams - Exames complementares
	BTW = 'BTW', // Complementary Exams - Exames complementares
}

export enum ExamReasonVeterinaryEnum {
	PER = 'PER', // Periodic - Periódico
	PRI = 'PRI', // Private - Particular
	CEX = 'CEX', // Complementary Exams - Exames complementares
}

export enum ExamReasonOccupationalLabelEnum {
	ADM = 'Admissional',
	PER = 'Periódico',
	DIS = 'Demissional',
	PRI = 'Particular',
	FCH = 'Mudança de função',
	CEX = 'Exames complementares',
	BTW = 'Retorno ao trabalho',
}

export enum ExamReasonVeterinaryLabelEnum {
	PER = 'Periódico',
	PRI = 'Particular',
	CEX = 'Exames complementares',
}
