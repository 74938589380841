import React from 'react'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import styled from 'styled-components'
import { DividerCP } from 'submodules/nerit-framework-ui/common/components/divider/DividerCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { WeekOrWeekendLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/WeekOrWeekendEnum'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	rule: TeamRuleTP
	onEdit?: () => void
	onRemove?: () => void
	showDivider?: boolean
}

/**
 * Detalhe de uma regra.
 */
export function RuleDescriptionICP(props: ICPProps): JSX.Element {
	return (
		<>
			<WrapperSCP>
				<div>
					{!!props.rule.urgencies && (
						<TextAndTitleCP
							title={'Urgência(s):'}
							text={props.rule.urgencies.map((urgency) => ExamUrgencyTypeLabelEnum[urgency]).join(', ')}
						/>
					)}
					{!!props.rule.modalities && (
						<TextAndTitleCP
							title={'Modalidade(s):'}
							text={props.rule.modalities.map((modality) => ExamModalityLabelEnum[modality]).join(', ')}
						/>
					)}
					{!!props.rule.weekOrWeekend && <TextAndTitleCP title={'Quando:'} text={WeekOrWeekendLabelEnum[props.rule.weekOrWeekend]} />}
					{!!props.rule.interval && (
						<TextAndTitleCP title={'Horário:'} text={`${props.rule.interval.beginHour} ~ ${props.rule.interval.endHour}`} />
					)}
				</div>
				<div>
					{!!props.onEdit && <ButtonCP icon={'edit'} size={'small'} marginBottom={5} onClick={props.onEdit} />}
					{!!props.onRemove && (
						<ButtonCP
							icon={'delete'}
							type={'danger'}
							size={'small'}
							confirmMsg={NotificationHelper.DEFAULT_CONFIRM_DELETE_MESSAGE}
							onClick={props.onRemove}
						/>
					)}
				</div>
			</WrapperSCP>

			{props.showDivider && <DividerCP margin={{ left: 10, right: 10 }} text={'Ou'} placement={'left'} />}
		</>
	)
}

const WrapperSCP = styled.div`
	margin: 10px;
	padding: 10px;
	border-left: solid 3px ${ThemeProject.gray300};
	border-radius: 4px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`
