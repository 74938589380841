import React from 'react'
import { ScreenContentAdminDoctorsCP } from 'modules/admin-beerads/screens/screen-admin-beerads/content/ScreenContentAdminDoctorsCP'
import { ScreenSubMenuTP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/submenu/inner/ScreenSubMenuItemTP'
import { ScreenContentAdminClinicsCP } from 'modules/admin-beerads/screens/screen-admin-beerads/content/ScreenContentAdminClinicsCP'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'

export const ADMIN_BEERADS_SUB_MENU_DOCTORS_KEY = 'doctors'
export const ADMIN_BEERADS_SUB_MENU_CLINICS_KEY = 'clinics'

export type AdminBeeRadsViewTP = 'doctorView' | 'clinicsView' | 'reportTemplateHeader'

/**
 * Reune metodos auxiliares para gestao da tela CONFIGURACOES.
 */
export const AdminBeeRadsScreenUtils = {
	getSubMenuConfigs(): Array<ScreenSubMenuTP<AdminBeeRadsViewTP>> {
		return [
			{
				key: ADMIN_BEERADS_SUB_MENU_CLINICS_KEY,
				title: 'Unidades Hospitalares',
				items: [
					{
						name: 'Todas Unidades Hospitalares',
						view: 'clinicsView',
						route: PrivateUserRouter.ADMIN_BEERADS_CLINICS,
						content: <ScreenContentAdminClinicsCP title={'Unidades Hospitalares'} />,
					},
				],
				enabled: true,
			},
			{
				key: ADMIN_BEERADS_SUB_MENU_DOCTORS_KEY,
				title: 'Corpo Clínico',
				items: [
					{
						name: 'Médicos',
						view: 'doctorView',
						route: PrivateUserRouter.ADMIN_BEERADS_DOCTORS,
						content: <ScreenContentAdminDoctorsCP title={'Médico Radiologista'} />,
					},
				],
				enabled: true,
			},
		]
	},
}
