import * as React from 'react'
import MediaQuery from 'react-responsive'

interface ICPProps {
	children: React.ReactNode
	minWidth: number
}

/**
 */
export function ResponsiveCP(props: ICPProps): JSX.Element {
	return <MediaQuery minWidth={props.minWidth}>{props.children}</MediaQuery>
}
