import { TransformFnParams } from 'class-transformer/types/interfaces'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'

export const TransformUtils = {
	toBoolean(param: TransformFnParams): boolean | undefined {
		return param.value !== undefined ? param.value === 'true' || param.value === true : undefined
	},

	toNumber(param: TransformFnParams): number | undefined {
		return param.value ? +param.value : undefined
	},

	toDate(param: TransformFnParams, format?: DateFormatEnum): Date | undefined {
		if (!param.value) return undefined

		return DateUtils.toDate(param.value, format ?? DateFormatEnum.US_WITHOUT_TIME)
	},

	toDateRange(param: TransformFnParams, format?: DateFormatEnum): DateRangeSearchRequestDTO | undefined {
		if (!param.value) return undefined

		// Vai chegar como string
		const dateRange: { beginDate: string; endDate: string } = JSON.parse(param.value)
		return {
			beginDate: DateUtils.toDate(dateRange.beginDate, format ?? DateFormatEnum.US_WITHOUT_TIME),
			endDate: DateUtils.toDate(dateRange.endDate, format ?? DateFormatEnum.US_WITHOUT_TIME),
		}
	},

	toNumberArray(param: TransformFnParams): number[] {
		return param.value?.map((arrayValue: any) => +arrayValue)
	},

	/**
	 * Esse metodo eh chamado duas vezes no mesmo DTO de request quando tem 2 decorators: @IsOptional e @Transform,
	 * por isso na segunda chamada e ele ja vem formatado e o PARSE daria erro.
	 *
	 * Atenção:
	 * Esse metodo nao pode ser usando junto com o @ValidateNested pois nao funciona.
	 */
	toJson(param: TransformFnParams): any {
		try {
			const result = JSON.parse(param.value)
			return result
		} catch (e) {
			const result = JSON.parse(JSON.stringify(param.value))
			return result
		}
	},
}
