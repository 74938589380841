import React, { SyntheticEvent, useState } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import * as _ from 'lodash'

import { TablePluginConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/table-plugin/inner/TablePluginConfig'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'

export interface ITPluginAddTableModalCPProps {
	onCreateTable: (rows: number, columns: number) => void
	onCancel: () => void
	show: boolean
}

/**
 * Modal para parametrizar criacao de 01 elemento tabela
 * dentro do editor de texto wysiwyg customizado Draft JS.
 */
export const TPluginAddTableModalCP = (props: ITPluginAddTableModalCPProps): JSX.Element => {
	const [rows, setRows] = useState<number>(1)
	const [columns, setColumns] = useState<number>(1)

	function createTable(): void {
		props.onCreateTable(rows, columns)
	}

	function cancel(): void {
		props.onCancel()
	}

	function updateColumns(_columns: number): void {
		setColumns(_columns <= TablePluginConfig.MAX_COLUMNS ? _columns : TablePluginConfig.MAX_COLUMNS)
	}

	function renderInput(label: string, currentValue: number, onBlur: (value: number) => void, max?: number): JSX.Element {
		const onChangeHandler = (event: SyntheticEvent) => {
			const value = _.get(event, 'target.value')
			onBlur(+value)
		}

		return (
			<>
				<ColumnCP size={6}>
					<InputLabelSCP>{label}</InputLabelSCP>
				</ColumnCP>
				<ColumnCP size={6}>
					<InputSCP type={'number'} min={1} step={1} value={currentValue} onChange={onChangeHandler} max={max} />
				</ColumnCP>
			</>
		)
	}

	return (
		<ModalCP title={'Adicionar Tabela'} visible={props.show} onOk={createTable} onCancel={cancel} width={400}>
			<RowCP>
				{renderInput('Linhas', rows, setRows)}
				{renderInput('Colunas', columns, updateColumns, TablePluginConfig.MAX_COLUMNS)}
			</RowCP>
		</ModalCP>
	)
}

const InputLabelSCP = styled.label`
	min-width: unset;
	margin: 0vw 0.6vw 0vw 0vw;
`

const InputSCP = styled(Input)`
	width: 50px;
	padding: 0;
	text-align: center;
`
