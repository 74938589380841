import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { TeamRuleTP } from 'submodules/beerads-sdk/services/doctor-groups/teams/types/TeamRuleTP'

export class TeamFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsRequired()
	color: string

	@IsOptional()
	rules?: TeamRuleTP[]

	constructor(initialData?: ObjectPropsTP<TeamFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
