import { CheckBoxGroupFormCP } from '_old/main/common/components/form-fields/checkbox-group/CheckBoxGroupFormCP'
import React from 'react'
import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'

export function OitSection3CICP(props: IOitSectionProps): JSX.Element {
	return (
		<RowCP>
			<OitSectionContentICP title={'3C - Obliteração do seio costofrênico:'} appearance={{ paddingTop: 20 }}>
				<ColumnCP size={24}>
					<CheckBoxGroupFormCP options={['D', 'E']} formStateManager={props.formStateManager as any} fieldName={'answer3C'} />
				</ColumnCP>
			</OitSectionContentICP>
		</RowCP>
	)
}
