import { TextEditorConfig } from 'modules/exams/components/medical-report/editor-medical-report/inner/TextEditorConfig'
import React from 'react'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	children: React.ReactNode
	hideToolbar?: boolean
}

/**
 */
export function WrapperMedicalReportEditorContentAreaICP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	return (
		<EditorAreaSCP hideToolbar={props.hideToolbar}>
			<EditorPageSCP isSmallScreen={screenSize.smd}>{props.children}</EditorPageSCP>
		</EditorAreaSCP>
	)
}

const EditorAreaSCP = styled.div<{ hideToolbar?: boolean }>`
	overflow: auto;
	height: 100%;
	padding: ${(props) => (props.hideToolbar ? 10 : 55)}px 10px 10px 10px;
`

const EditorPageSCP = styled.div<{ isSmallScreen: boolean }>`
	box-sizing: content-box;
	cursor: text;
	min-height: 550px;
	box-shadow: 2px 5px 10px ${ThemeProject.gray100};
	padding: 10px 20px;
	margin: 0 auto;
	background-color: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};

	.DraftEditor-root {
		width: 100%;
		padding-top: 5px;
	}

	span[data-offset-key] span {
		${getDefaultStylesFixer()}
	}

	div.public-DraftStyleDefault-block {
		margin-bottom: 1em;
	}

	// O text-align sera inserido no filho como inline-text, adiciona o inline-text no pai
	div:has(> span[style*='text-align: center;']) {
		text-align: center;
	}
	div:has(> span[style*='text-align: right;']) {
		text-align: right;
	}
	div:has(> span[style*='text-align: justify;']) {
		text-align: justify;
	}
	div:has(> span[style*='text-align: left;']) {
		text-align: left;
	}

	&:focus {
		outline: none;
	}

	:global(.public-DraftEditor-content) {
		min-height: 140px;
	}

	.autocompleteSuggestions {
		background-color: ${ThemeFrameworkCommon.white};
		color: ${ThemeFrameworkCommon.black};
		display: flex;
		cursor: pointer;
		flex-direction: column;
		position: absolute;
		max-width: 440px;
		border-radius: 4px;
		padding: 2px;
		margin: 15px 0 0 15px;
		border: 1px solid ${ThemeProject.gray100};
		min-width: 220px;

		* {
			background-color: ${ThemeFrameworkCommon.white} !important;
			color: ${ThemeFrameworkCommon.black} !important;
		}
	}

	@media screen and (max-width: 640px) {
		table {
			width: 100%;
		}
	}
`

/**
 * Retorna bloco CSS para anular sobrescrita de estilos padrao
 * fixos do editor.
 */
function getDefaultStylesFixer(): string {
	const defaultStyleKeys = Object.keys(TextEditorConfig.DEFAULT_STYLES)
	let defaultStyleFixer = ''

	defaultStyleKeys.forEach((defaultStyleKey) => (defaultStyleFixer += `${defaultStyleKey}: inherit; `))

	return defaultStyleFixer
}
