import { MimeTypeUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/MimeTypeUtils'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'
import { RcFile } from 'antd/lib/upload/interface'

export const FileUtils = {
	/**
	 * Avalia metadados disponiveis para determinar se tipo de 01 arquivo eh CSV.
	 */
	isCsv(file: Blob, filename?: string): boolean {
		const fileType = file?.type
		if (!!fileType && MimeTypeUtils.isCsvMimeType(fileType)) return true

		if (!!filename) return fileType === MimeTypeEnum.TEXT && /(.*)\.csv$/.test(filename)

		return false
	},

	/**
	 * Obtem bsae64 da imagem
	 */
	getBase64(img: RcFile, callback: (result: any) => void): void {
		const reader = new FileReader()
		reader.addEventListener('load', () => callback(reader.result))
		reader.readAsDataURL(img)
	},
}
