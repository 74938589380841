import { IReduxState } from 'app/redux/interfaces/IReduxState'
import { ReduxUtils } from 'submodules/nerit-framework-ui/common/redux/ReduxUtils'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

export const REDUCER_LOGGED_USER = 'REDUCER_LOGGED_USER'
export const REDUCER_LOGGED_USER_CLINIC_PERMISSIONS = 'REDUCER_LOGGED_USER_CLINIC_PERMISSIONS'
export const REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS = 'REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS'
export const REDUCER_CURRENT_DOCTOR_GROUP = 'REDUCER_CURRENT_DOCTOR_GROUP'

export const REDUCER_LOGGED_PATIENT = 'REDUCER_LOGGED_PATIENT'

/**
 */
export const Reducers: IReduxState = {
	auth: ReduxUtils.getPayloadReplacingReducer<AuthResponseDTO>(REDUCER_LOGGED_USER, null),
	loggedUserClinicPermissions: ReduxUtils.getPayloadReplacingReducer<PersonClinicAccessTP>(REDUCER_LOGGED_USER_CLINIC_PERMISSIONS, null),
	loggedUserDoctorGroupPermissions: ReduxUtils.getPayloadReplacingReducer<UserDoctorGroupAccessTP>(REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS, null),
	currentDoctorGroup: ReduxUtils.getPayloadReplacingReducer<NameAndCodeResponseDTO>(REDUCER_CURRENT_DOCTOR_GROUP, null),

	authLoggedPatient: ReduxUtils.getPayloadReplacingReducer<PatientPortalLoginResponseDTO>(REDUCER_LOGGED_PATIENT, null),
}
