import React from 'react'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { SelectDoctorTreatmentCP } from 'modules/person/components/doctor/select-doctor-treatment/SelectDoctorTreatmentCP'
import { DoctorProfileFormModel } from 'modules/person/components/doctor/modal-doctor-data/inner/DoctorProfileFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'

interface ICPProps {
	formStateManager: IFormStateManager<DoctorProfileFormModel>
}

/**
 * Modal com o log de uma operacao em massa.
 */
export function FormDoctorProfileDataICP(props: ICPProps): JSX.Element {
	return (
		<>
			<RowCP>
				<ColumnCP size={6}>
					<InputCP required={true} mask={InputMaskTypeEnum.CPF} label={'CPF'} formStateManager={props.formStateManager} fieldName={'cpf'} />
				</ColumnCP>

				<ColumnCP size={5}>
					<SelectDoctorTreatmentCP required={true} formStateManager={props.formStateManager} fieldName={'treatment'} />
				</ColumnCP>

				<ColumnCP size={13}>
					<InputCP label={'Nome'} required={true} formStateManager={props.formStateManager} fieldName={'name'} />
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={16}>
					<InputCP label={'Especialidade'} formStateManager={props.formStateManager} fieldName={'specialty'} />
				</ColumnCP>

				<ColumnCP size={8}>
					<InputCP
						label={'Data de Nascimento'}
						mask={InputMaskTypeEnum.DATE}
						formStateManager={props.formStateManager}
						fieldName={'birthDate'}
					/>
				</ColumnCP>
			</RowCP>

			<RowCP>
				<ColumnCP size={16}>
					<InputCP type={'email'} required={true} label={'E-mail'} formStateManager={props.formStateManager} fieldName={'email'} />
				</ColumnCP>

				<ColumnCP size={8}>
					<InputCP
						required={true}
						mask={InputMaskTypeEnum.PHONE}
						label={'Celular'}
						formStateManager={props.formStateManager}
						fieldName={'phoneOne'}
					/>
				</ColumnCP>
			</RowCP>
		</>
	)
}
