import { IsOptional } from 'class-validator'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

export class ClinicMedicalReportConfigFormModel extends FormModel {
	@IsOptional()
	doctorMessage?: string

	constructor(initialData?: ObjectPropsTP<ClinicMedicalReportConfigFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
