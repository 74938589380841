import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import React from 'react'
import { WeekOrWeekendEnum, WeekOrWeekendLabelEnum } from 'submodules/nerit-framework-utils/utils/enums/WeekOrWeekendEnum'

/**
 * Seletor unitario de genero.
 */
export function SelectWeekOrWeekendCP<FModelTP extends FormModel = any>(props: IFormItemCommonProps<FModelTP>): JSX.Element {
	return (
		<SelectCP<FModelTP>
			label={props.label}
			options={Object.values(WeekOrWeekendEnum).map((it, i) => ({
				key: `key-WeekOrWeekendEnum-${i}`,
				label: WeekOrWeekendLabelEnum[it],
				value: it,
			}))}
			onChange={props.onChange}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			required={props.required}
			allowClear={true}
		/>
	)
}
