import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { TitleExamCP } from 'modules/exams/components/exams/title-exam/TitleExamCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { HelpCP } from 'submodules/nerit-framework-ui/common/components/help/HelpCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { TextAreaCP } from 'submodules/nerit-framework-ui/common/components/form-fields/text-area/TextAreaCP'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { ButtonExamDetailsCP } from 'modules/exams/components/exams/button-exam-details/ButtonExamDetailsCP'
import { ExamStatusEnum, ExamStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { PendingAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-analysis/model/PendingAnalysisExamFormModel'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { ExamsAnalysisRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsAnalysisRequests'
import { BasicModalCPProps } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { PendingRectificationAnalysisExamFormModel } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/inner/PendingRectificationAnalysisExamFormModel'
import { ExamPendingRectificationAnalysisResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamPendingRectificationAnalysisResponseDTO'
import { ExamPendingRectificationAnalysisFooterICP } from 'modules/exams/components/exam-analysis/drawer-exam-pending-rectification-analysis/inner/ExamPendingRectificationAnalysisFooterICP'
import { ExamPendingRectificationAnalysisResolveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamPendingRectificationAnalysisResolveRequestDTO'
import { ButtonDownloadExamCP } from 'modules/exams/components/exams/button-download-exam/ButtonDownloadExamCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { PdfViewerCP } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-viewer/PdfViewerCP'
import styled from 'styled-components'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { TimelineExamHistoryCP } from 'modules/exams/components/exam-history/timeline-exam-history/TimelineExamHistoryCP'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicModalCPProps {
	examId: string
	examCode: number
}

/**
 */
export function DrawerExamPendingRectificationAnalysisCP(props: ICPProps): JSX.Element {
	const [formModel, setFormModel] = useState<PendingRectificationAnalysisExamFormModel>(new PendingRectificationAnalysisExamFormModel())
	const formStateManager = useFormStateManager<PendingRectificationAnalysisExamFormModel>(formModel)

	const [loadedExam, setLoadedExam] = useState<ExamPendingRectificationAnalysisResponseDTO>()
	const getExamRequest = useRequest<ExamPendingRectificationAnalysisResponseDTO>()
	useEffect(onGetExamRequestChange, [getExamRequest.isAwaiting])

	const [shouldDoMedicalReport, setShouldDoMedicalReport] = useState<boolean>(false)

	const resolveExamRequest = useRequest<void>('none')
	useEffect(onResolveExamRequestChange, [resolveExamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		setFormModel(new PendingAnalysisExamFormModel({}))
		setLoadedExam(undefined)
		if (!props.visible) return

		getExamRequest.runRequest(ExamsAnalysisRequests.getExamPendingRectificationAnalysis(props.examCode))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetExamRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getExamRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getExamRequest.responseData!
		setFormModel(
			new PendingAnalysisExamFormModel({
				doctorCode: result.medicalReportDoctorCode,
			}),
		)
		setLoadedExam(result)
	}

	/**
	 */
	async function save(sendTo: ExamStatusEnum.RECTIFICATION | ExamStatusEnum.DONE_RECTIFICATION, doMedicalReport?: boolean): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager)))
			return NotificationHelper.error('Ops', 'Preencha a resolução para prosseguir')

		setShouldDoMedicalReport(doMedicalReport ?? false)

		const formValues = formStateManager.getFormValues()!
		const dto: ExamPendingRectificationAnalysisResolveRequestDTO = {
			sendTo,
			comments: formValues.comments,
			doctorCode: sendTo === ExamStatusEnum.RECTIFICATION ? formValues.doctorCode : undefined,
		}
		resolveExamRequest.runRequest(ExamsAnalysisRequests.resolvePendingRectificationAnalysisExam(props.examCode, dto))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onResolveExamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				resolveExamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		if (shouldDoMedicalReport) {
			RoutingHelper.goToMedicalReport(loadedExam!.id, false)
			return
		}

		props.onSave()
	}

	if (!props.visible) return <></>

	if (!loadedExam) return <LoadingOverlayCP show={true} />

	return (
		<DrawerCP
			width={1100}
			visible={props.visible}
			loading={getExamRequest.isAwaiting || resolveExamRequest.isAwaiting}
			title={
				<TitleExamCP
					modality={loadedExam.modality}
					patientName={loadedExam.patientName}
					examTitle={loadedExam.description}
					examStatus={loadedExam.status}
				/>
			}
			footerSpaced={true}
			footer={
				<ExamPendingRectificationAnalysisFooterICP
					formStateManager={formStateManager}
					examCode={loadedExam.code}
					doctorGroupCode={loadedExam.doctorGroupCode}
					onSave={save}
				/>
			}
			onClose={props.onCancel}
		>
			<RowCP>
				<ColumnCP size={14}>
					{loadedExam.medicalReports.map((medicalReport) => (
						<FileWrapperSCP key={medicalReport.fileUrl}>
							<PdfViewerCP file={medicalReport.fileUrl} paginationType={'scroll'} viewType={'inline'} />
						</FileWrapperSCP>
					))}
				</ColumnCP>
				<ColumnCP size={10}>
					<FlexCP margin={{ bottom: 15 }} justifyContent={'space-between'}>
						<ButtonDownloadExamCP
							size={'small'}
							exam={{
								id: loadedExam.id,
								examDescription: loadedExam.description,
								patientName: loadedExam.patientName,
								imageData: loadedExam.imageData,
							}}
							downloadBy={'user'}
						/>
						<ButtonExamDetailsCP showText={true} examId={props.examId} onSave={init} />
					</FlexCP>
					<TitleCP underLine={true} textSize={'normal'} marginTop={50}>
						{`Resolução da ${ExamStatusLabelEnum[loadedExam.status]}`}
					</TitleCP>
					<HelpCP
						text={`${StringUtils.getFirstName(
							AppStateUtils.getLoggedUser()!.name,
						)}, escreva uma resposta para a solicitação e devolva para a unidade hospitalar ou envie para médico retificar o laudo`}
						type={'text'}
					/>
					<TextAreaCP
						placeholder={'Escreva os detalhes da resolução do pedido de retificação'}
						minRows={4}
						formStateManager={formStateManager}
						fieldName={'comments'}
						required={true}
					/>
					<TitleCP underLine={true} textSize={'normal'} marginTop={50}>
						{'Histórico'}
					</TitleCP>
					<BasicStyleWrapperCP padding={{ top: 10, bottom: 10, left: 10, right: 10 }} height="45vh" overflow="auto">
						<TimelineExamHistoryCP examCode={props.examCode} />
					</BasicStyleWrapperCP>
				</ColumnCP>
			</RowCP>
		</DrawerCP>
	)
}

const FileWrapperSCP = styled.div`
	overflow-y: scroll;
	overflow-x: hidden;
	border: dashed 1px ${ThemeProject.gray300};
	border-radius: 5px;
	height: calc(100vh - 200px);

	.nerit-viewer-wrapper {
		height: auto;
	}
`
