import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

export class LeoRadUtils {
	static modalityVxToLeoRad = new Map<ExamModalityEnum, string>([
		[ExamModalityEnum.CR, 'RX'],
		[ExamModalityEnum.DX, 'RX'],
		[ExamModalityEnum.CT, 'TC'],
		[ExamModalityEnum.MR, 'RM'],
		[ExamModalityEnum.MG, 'MMG'],
	])
}
