import React, { useEffect, useState } from 'react'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { ClinicDoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/form-clinic-doctor-group/form-models/ClinicDoctorGroupFormModel'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ExamUrgencyTypeEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import { FormClinicDoctorGroupInputSlaConfigICP } from 'modules/doctor-groups/components/doctor-groups/form-clinic-doctor-group/inner/FormClinicDoctorGroupInputSlaConfigICP'
import { SelectExamUrgencyTypeCP } from 'modules/exams/components/exams/select-exam-urgency-type/SelectExamUrgencyTypeCP'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { ClinicDoctorGroupResponseDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/responses/ClinicDoctorGroupResponseDTO'
import { ClinicDoctorGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupSaveRequestDTO'
import { SelectSimpleAutocompleteCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectSimpleAutocompleteCP'
import { SelectClinicCategoryWithDetailsCP } from 'modules/doctor-groups/components/doctor-groups/select-clinic-category-with-details/SelectClinicCategoryWithDetailsCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'

interface ICPProps {
	clinicCode: number
	doctorGroupCode: number
	loadedClinicDoctorGroup?: ClinicDoctorGroupResponseDTO
	onSave: () => void
}

/**
 * Formulario com configuracoes de um grupo em uma clinica
 */
export function FormClinicDoctorGroupCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<ClinicDoctorGroupFormModel>(new ClinicDoctorGroupFormModel())
	const formStateManager = useFormStateManager<ClinicDoctorGroupFormModel>(formValidator)

	const saveClinicDoctorGroupRequest = useRequest<void>('none')
	useEffect(onSaveClinicDoctorGroupRequestChange, [saveClinicDoctorGroupRequest.isAwaiting])

	useEffect(init, [props.loadedClinicDoctorGroup])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		setFormValidator(
			new ClinicDoctorGroupFormModel({
				isDigitalCertificateRequired: true,
				isAttachmentsRequired: true,
			}),
		)

		if (!props.loadedClinicDoctorGroup) return

		setFormValidator(
			new ClinicDoctorGroupFormModel({
				urgencyTypes: props.loadedClinicDoctorGroup.urgencyTypes,
				slaConfigs: props.loadedClinicDoctorGroup.slaConfigs,
				categoryDetails: props.loadedClinicDoctorGroup.categoryDetails ?? props.loadedClinicDoctorGroup.category?.details,
				categoryCode: props.loadedClinicDoctorGroup.category?.code,
				modalities: props.loadedClinicDoctorGroup.modalities,
				examsToBypassPending: props.loadedClinicDoctorGroup.avcExamsToBypassPending,
				isDigitalCertificateRequired: props.loadedClinicDoctorGroup.isDigitalCertificateRequired,
				isAttachmentsRequired: props.loadedClinicDoctorGroup.isAttachmentsRequired,
			}),
		)
	}

	/**
	 * Salva relacionamento.
	 */
	async function save(): Promise<void> {
		// Se nao tiver vinculo com grupo, apenas passa para frente. Por enquanto não salva as modalidades em lugar algum, nesse caso
		if (!props.doctorGroupCode) {
			props.onSave()
			return
		}

		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!

		const dto: ClinicDoctorGroupSaveRequestDTO = {
			modalities: formValues.modalities,
			urgencyTypes: formValues.urgencyTypes,
			slaConfigs: formValues.slaConfigs,
			categoryCode: !!formValues.categoryCode ? formValues.categoryCode : null,
			categoryDetails: !!formValues.categoryCode ? formValues.categoryDetails : null,
			avcExamsToBypassPending: formValues.examsToBypassPending,
			isDigitalCertificateRequired: formValues.isDigitalCertificateRequired,
			isAttachmentsRequired: formValues.isAttachmentsRequired,
		}

		if (!props.loadedClinicDoctorGroup)
			saveClinicDoctorGroupRequest.runRequest(ClinicDoctorGroupRequests.create(props.clinicCode, props.doctorGroupCode, dto))
		else saveClinicDoctorGroupRequest.runRequest(ClinicDoctorGroupRequests.update(props.loadedClinicDoctorGroup.code, dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveClinicDoctorGroupRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveClinicDoctorGroupRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<>
			<SelectExamModalityCP
				label={'Escolha as modalidades de exame em que a unidade hospitalar irá trabalhar'}
				isMultiple={true}
				formStateManager={formStateManager}
				fieldName={'modalities'}
				required={true}
			/>

			<SelectClinicCategoryWithDetailsCP
				formStateManager={formStateManager}
				categoryDataFieldName={'categoryDetails'}
				categoryFieldName={'categoryCode'}
				doctorGroupCode={props.doctorGroupCode}
				originalCategoryCode={props.loadedClinicDoctorGroup?.category?.code}
				originalCategoryDetails={props.loadedClinicDoctorGroup?.categoryDetails}
			/>

			<SelectExamUrgencyTypeCP formStateManager={formStateManager} fieldName={'urgencyTypes'} required={true} />
			<RowCP>
				{formStateManager.getFieldValue('urgencyTypes')?.map((urgency: ExamUrgencyTypeEnum, key) => (
					<ColumnCP size={6} key={key}>
						<FormClinicDoctorGroupInputSlaConfigICP formStateManager={formStateManager} urgencyType={urgency} />
					</ColumnCP>
				))}
			</RowCP>

			{formStateManager.getFieldValue('urgencyTypes')?.includes(ExamUrgencyTypeEnum.AVC) && (
				<SelectSimpleAutocompleteCP
					label={'Exames que vão direto para fila de laudos (Urgência AVC)'}
					formStateManager={formStateManager}
					fieldName={'examsToBypassPending'}
				/>
			)}

			<SwitchCP
				formStateManager={formStateManager}
				fieldName={'isDigitalCertificateRequired'}
				label={'Obrigatório assinatura de laudo com certificado digital?'}
				hint={{
					type: 'text',
					text: 'Caso habilitado, somente médicos com certificado digital poderão assinar o laudo (ICP-Brasil)',
				}}
			/>

			<SwitchCP
				formStateManager={formStateManager}
				fieldName={'isAttachmentsRequired'}
				label={'É obrigatório a inclusão de anexo?'}
				hint={{
					type: 'text',
					text: 'Campo exclusivo para clínicas possuem integração com sistema de anexos',
				}}
			/>

			<FlexCP margin={{ top: 30 }} justifyContent={'flex-end'}>
				<ButtonCP type={'primary'} onClick={save} loading={saveClinicDoctorGroupRequest.isAwaiting}>
					Salvar
				</ButtonCP>
			</FlexCP>
		</>
	)
}
