import React from 'react'

/**
 */
export function BlockedMedicalTimetableCP(): JSX.Element {
	return (
		<div
			style={{
				height: 30,
				width: '100%',
				backgroundImage:
					'repeating-linear-gradient(120deg, transparent 5px, transparent 8px, rgba(240, 240, 240, .9) 10px, rgba(240, 240, 240, .9) 13px)', // HEX: #F0F0F0E6
			}}
		/>
	)
}
