import React from 'react'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ITotalByModality } from 'modules/reports/_old/interfaces/ITotalByModality'
import { ModalityOptionICP } from 'modules/reports/components/exam-modality-selector/inner/ModalityOptionICP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IModalitySelectorCPProps {
	byModalityLists: ITotalByModality[]
	onChange: (mod?: ExamModalityEnum) => void
	currentModality?: ExamModalityEnum
}

/**
 * COMPONENTE
 * Filtro de modalidadeS para graficos do dashboard.
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
export function ExamModalitySelectorCP(props: IModalitySelectorCPProps): JSX.Element {
	const totalCount = props.byModalityLists.reduce((acc, modality) => acc + modality.total, 0)

	return (
		<SelectorSCP>
			<ModalityOptionICP count={totalCount} onSelect={() => props.onChange()} isActive={!props.currentModality} />

			{props.byModalityLists.map((modalityList) => (
				<ModalityOptionICP
					key={`${modalityList.modality}${modalityList.total}`}
					modality={modalityList.modality}
					count={modalityList.total}
					onSelect={() => props.onChange(modalityList.modality)}
					isActive={props.currentModality === modalityList.modality}
				/>
			))}
		</SelectorSCP>
	)
}

const SelectorSCP = styled.div`
	width: 140px;
	height: fit-content;
	border-radius: 3px;
	margin: 20px 0;
	border: ${ThemeProject.primary} solid 1px;
`
