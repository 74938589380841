import React from 'react'
import { CheckBoxGroupFormCP } from '_old/main/common/components/form-fields/checkbox-group/CheckBoxGroupFormCP'
import { Col } from 'antd'
import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import styled from 'styled-components'

export function OitSection4BICP(props: IOitSectionProps): JSX.Element {
	return (
		<RowCP>
			<OitSectionContentICP title={'4B - Símbolos'} appearance={{ paddingTop: 20 }}>
				<ColSCP span={24}>
					<CheckBoxGroupFormCP
						formStateManager={props.formStateManager as any}
						fieldName={'answer4B'}
						options={[
							'aa',
							'at',
							'ax',
							'bu',
							'ca',
							'cg',
							'cn',
							'co',
							'cp',
							'cv',
							'di',
							'ef',
							'em',
							'es',
							'fr',
							'hi',
							'ho',
							'id',
							'ih',
							'kl',
							'me',
							'pa',
							'pb',
							'pi',
							'px',
							'ra',
							'rp',
							'tb',
							'od',
						]}
					/>
				</ColSCP>
			</OitSectionContentICP>
		</RowCP>
	)
}

const ColSCP = styled(Col)`
	.ant-checkbox-group {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
`
