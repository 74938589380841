import { DoctorGroupDigitalCertificateDataFormModel } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/inner/DoctorGrouṕDigitalCertificateDataFormModel'
import React from 'react'
import { DigitalCertificatePartner } from 'submodules/beerads-sdk/services/auth/enums/DigitalCertificatePartner'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	digitalCertificateStateManager: IFormStateManager<DoctorGroupDigitalCertificateDataFormModel>
}

export function FormDoctorGroupCertificateData(props: ICPProps): JSX.Element {
	return (
		<RowCP>
			<ColumnCP size={18}>
				<ColumnCP size={12}>
					<SelectCP
						label={'Partner'}
						fieldName={'partner'}
						formStateManager={props.digitalCertificateStateManager}
						required={true}
						options={Object.values(DigitalCertificatePartner).map((option) => ({
							value: option,
							label: option,
						}))}
					/>
					<InputCP
						type={'text'}
						label={'Client ID'}
						required={true}
						fieldName={'clientId'}
						formStateManager={props.digitalCertificateStateManager}
					/>
				</ColumnCP>
				<ColumnCP size={12}>
					<InputCP
						type={'text'}
						label={'Endpoint'}
						required={true}
						fieldName={'endpoint'}
						formStateManager={props.digitalCertificateStateManager}
					/>
					<InputCP
						type={'text'}
						label={'Client Secret'}
						required={true}
						fieldName={'clientSecret'}
						formStateManager={props.digitalCertificateStateManager}
					/>
				</ColumnCP>
			</ColumnCP>
		</RowCP>
	)
}
