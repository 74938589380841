import { ClinicDoctorGroupDoctorFormModel } from 'modules/doctor-groups/components/clinic-doctor-groups/modal-clinic-doctor-group-doctor/inner/ClinicDoctorGroupDoctorFormModel'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ClinicDoctorGroupAddDoctorRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupAddDoctorRequestDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { PersonClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/responses/PersonClinicResponseDTO'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'
import { SelectExamModalityCP } from 'modules/exams/components/exams/select-exam-modality/SelectExamModalityCP'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface IAddDoctorModalICPProps {
	visible: boolean
	clinicDoctorGroupCode: number
	doctorGroupCode: number
	alreadyLinkedDoctorCodes?: number[]
	clinicModalities: ExamModalityEnum[]
	onClose: (allocationSuccess: boolean) => void
	clinicPersonToEdit?: PersonClinicResponseDTO
	doctorTypeRelation: ClinicPersonRelationModeEnum
}

/**
 * Modal para edicao de vinculo de medico do grupo com clinica do grupo.
 */
export function ModalClinicDoctorGroupDoctorCP(props: IAddDoctorModalICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<ClinicDoctorGroupDoctorFormModel>()
	const formStateManager = useFormStateManager<ClinicDoctorGroupDoctorFormModel>(formValidator)

	const addDoctorRequest = useRequest<void>('none')
	useEffect(onAddDoctorRequestChange, [addDoctorRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa.
	 */
	function init(): void {
		setFormValidator(new ClinicDoctorGroupDoctorFormModel())
		if (!props.visible || !props.clinicPersonToEdit) return

		setFormValidator(
			new ClinicDoctorGroupDoctorFormModel({
				modalities: props.clinicPersonToEdit.modalities,
				doctorCode: props.clinicPersonToEdit.person.code,
			}),
		)
	}

	/**
	 * Salva a alocacao do medico para as modalidades informadas.
	 */
	async function addDoctor(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!

		const dto: ClinicDoctorGroupAddDoctorRequestDTO = {
			doctorCode: formValues.doctorCode,
			modalities: formValues.modalities,
			mode: props.doctorTypeRelation,
		}
		addDoctorRequest.runRequest(ClinicDoctorGroupRequests.addDoctor(props.clinicDoctorGroupCode, dto))
	}

	/**
	 * Retorno da requisicao para fazer alocacao
	 */
	function onAddDoctorRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				addDoctorRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onClose(true)
	}

	return (
		<ModalCP
			visible={props.visible}
			onCancel={() => props.onClose(false)}
			title={`${props.doctorTypeRelation === ClinicPersonRelationModeEnum.IN ? 'Alocar' : 'Bloquear'} Médico`}
			onOk={addDoctor}
			actionLoading={addDoctorRequest.isAwaiting}
			width={400}
		>
			{!!props.clinicPersonToEdit ? (
				<InputCP label={'Médico'} value={props.clinicPersonToEdit.person.name} disabled={true} />
			) : (
				<SelectDoctorGroupPeopleCP
					doctorGroupCode={props.doctorGroupCode}
					formStateManager={formStateManager}
					formField={'doctorCode'}
					alreadyLinkedPersonCodes={props.alreadyLinkedDoctorCodes}
					role={UserTypeEnum.DOCTOR}
					required={true}
					filters={{
						status: [UserDoctorGroupStatusEnum.ACTIVE],
					}}
				/>
			)}

			{props.doctorTypeRelation === ClinicPersonRelationModeEnum.IN && (
				<SelectExamModalityCP label={'Modalidades'} fieldName={'modalities'} formStateManager={formStateManager} isMultiple={true} />
			)}
		</ModalCP>
	)
}
