import { registerDecorator, ValidationArguments, ValidationOptions, Validator, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'
import { ValidationTypes } from '../ValidationTypes'
import * as _ from 'lodash'

/**
 * VALIDATOR
 * Valida se valor esta na lista de valores permitidos
 *
 * TODO adicionar validacao de {each: true} quando a biblioteca liberar suporte para isso
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_IN })
class IsInConstraint implements ValidatorConstraintInterface {
	validate(value: any, args: ValidationArguments): boolean {
		const validator = new Validator()

		if (value instanceof Array) return _.every(value, (val) => validator.isIn(val, args.constraints))

		return validator.isIn(value, args.constraints)
	}

	defaultMessage(args: ValidationArguments): string {
		return `Valor não permitido! (Valores permitidos: ${args.constraints.join(',')})`
	}
}

/**
 * DECORATOR
 * @param {any[]} values
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsIn(values: any[], validationOptions?: ValidationOptions) {
	return (object: {}, propertyName: string) => {
		registerDecorator({
			target: object.constructor,
			propertyName,
			options: validationOptions,
			constraints: values,
			validator: IsInConstraint,
		})
	}
}
