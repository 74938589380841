import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'

/**
 */
export const ExamFileNamesUtils = {
	/**
	 * Monta o nome do arquivo no download de exame.
	 */
	getExamFileName(examCode: number): string {
		let fileName = `${AppStateUtils.getDomainName()}`
		fileName = `${fileName}_exame_${examCode}_${new Date().getTime()}`
		return fileName
	},

	/**
	 */
	getExamsFileName(): string {
		let fileName = `${AppStateUtils.getDomainName()}`
		fileName = `${fileName}_exames_${new Date().getTime()}`
		return fileName
	},

	/**
	 * Monta o nome do arquivo quando download do laudo.
	 * Quando passado o exame, eh download unico, quando array de codigos de exame, download de multiplos.
	 */
	getMedicalReportFileName(
		examCodes: number[],
		exam?: { code: number; patient: { name: string }; examDate: string; modality: ExamModalityEnum },
	): string {
		let fileName = `${AppStateUtils.getDomainName()}`

		if (!!exam && examCodes.length === 1) {
			fileName = `${fileName}_laudo_${DateUtils.formatDate(exam.examDate, DateFormatEnum.US_FULL_WITHOUT_SPACES)}`
			fileName = `${fileName}_${StringUtils.getSlugStyleString(exam.patient.name)}_${exam.modality}_e${exam.code}`
			return fileName
		}

		fileName = `${fileName}_laudos_${DateUtils.formatDate(new Date(), DateFormatEnum.US_FULL_WITHOUT_SPACES)}`
		fileName = `${fileName}_exames_${examCodes.join('_')}`

		return fileName
	},
}
