import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'submodules/nerit-framework-ui/common/components/drawer/DrawerCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ColorPickerCP } from 'submodules/nerit-framework-ui/common/components/color-picker/ColorPickerCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'
import { TeamRulesICP } from 'modules/doctor-groups/components/teams/drawer-team/inner/TeamRulesICP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TeamFormModel } from 'modules/doctor-groups/components/teams/drawer-team/inner/TeamFormModel'
import { TeamSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/requests/TeamSaveRequestDTO'
import { TeamsRequests } from 'submodules/beerads-sdk/services/doctor-groups/teams/TeamsRequests'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	onSave: () => void
	doctorGroupCode: number
	team?: TeamResponseDTO
}

/**
 * Drawer de time de um grupo de medicos.
 */
export function DrawerTeamCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<TeamFormModel>(new TeamFormModel())
	const formStateManager = useFormStateManager<TeamFormModel>(formValidator)

	const saveTeamRequest = useRequest<void>('none')
	useEffect(onSaveTeamRequestChange, [saveTeamRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		setFormValidator(new TeamFormModel({}))
		if (!props.visible) return

		setFormValidator(
			new TeamFormModel({
				name: props.team?.name,
				color: props.team?.color,
				rules: props.team?.rules,
			}),
		)
	}

	/**
	 * Salva.
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) return

		const formValues = formStateManager.getFormValues()!

		const dto: TeamSaveRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			name: formValues.name,
			color: formValues.color,
			rules: formValues.rules,
		}

		if (!!props.team) saveTeamRequest.runRequest(TeamsRequests.update(props.team.code, dto))
		else saveTeamRequest.runRequest(TeamsRequests.create(dto))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveTeamRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				saveTeamRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<DrawerCP
			visible={props.visible}
			onClose={props.onCancel}
			title={'Configurações do Time'}
			width={600}
			footer={
				<ButtonCP type={'primary'} loading={saveTeamRequest.isAwaiting} onClick={save}>
					Salvar
				</ButtonCP>
			}
		>
			<InputCP label={'Nome do Time'} fontSize={'extraLarge'} formStateManager={formStateManager} fieldName={'name'} />

			<ColorPickerCP
				initialHexColor={formStateManager.getFieldValue('color') ?? undefined}
				onSelectColor={(color) => formStateManager.changeFieldValue('color', color?.hex)}
				margin={{ left: 5, top: 25, bottom: 50 }}
				width={'100%'}
			/>

			<TitleCP underLine={true} textSize={'normal'}>
				Regras
			</TitleCP>
			<TeamRulesICP formStateManager={formStateManager} />
		</DrawerCP>
	)
}
