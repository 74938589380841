export enum ExamContrastEnum {
	INTRAVENOUS = 'INTRAVENOUS', // Endovenoso
	ORAL = 'ORAL', // Oral
	RECTAL = 'RECTAL', // Retal
}

export enum ExamContrastLabelEnum {
	INTRAVENOUS = 'Endovenoso',
	ORAL = 'Oral',
	RECTAL = 'Retal',
}
