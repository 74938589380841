import React from 'react'
import { WidgetsRowCP } from 'submodules/nerit-framework-ui/common/components/dashboard/widgets-row/WidgetsRowCP'
import { WidgetCountExamsRectificationAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-awaiting/WidgetCountExamsRectificationAwaitingCP'
import { WidgetCountExamsReturnedCP } from 'modules/reports/components/widgets/widget-count-exams-returned/WidgetCountExamsReturnedCP'
import { WidgetCountExamsAssignedCP } from 'modules/reports/components/widgets/widget-count-exams-assigned/WidgetCountExamsAssignedCP'
import { WidgetCountExamsAdminAnalysisCP } from 'modules/reports/components/widgets/widget-count-exams-admin-analysis/WidgetCountExamsAdminAnalysisCP'
import { WidgetCountExamsAwaitingCP } from 'modules/reports/components/widgets/widget-count-exams-awaiting/WidgetCountExamsAwaitingCP'
import { WidgetCountExamsPendingCP } from 'modules/reports/components/widgets/widget-count-exams-pending/WidgetCountExamsPendingCP'
import { WidgetCountExamsRectificationAnalysisCP } from 'modules/reports/components/widgets/widget-count-exams-rectification-analysis/WidgetCountExamsRectificationAnalysisCP'
import { WidgetCountExamsBySlaCP } from 'modules/reports/components/widgets/widget-count-exams-by-sla/WidgetCountExamsBySlaCP'
import { CountExamsWidgetEnum } from 'submodules/beerads-sdk/services/dashboard/widgets/enums/CountExamsWidgetEnum'
import { ExamAssignedFilterEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamAssignedFilterEnum'

interface ICPProps {
	clinicCode?: number
}

/**
 * Mostra a Home especifica para cara tipo de usuario.
 */
export function ScreenContentHomeDoctorGroupAdmin(props: ICPProps): JSX.Element {
	return (
		<>
			<WidgetsRowCP margin={{ top: 10, bottom: 30 }}>
				<WidgetCountExamsAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={false} />
				<WidgetCountExamsAssignedCP clinicCode={props.clinicCode} viewAsDoctor={false} />
				<WidgetCountExamsBySlaCP
					clinicCode={props.clinicCode}
					widget={CountExamsWidgetEnum.BY_SLA_DELAYED}
					viewAsDoctor={false}
					assignedType={ExamAssignedFilterEnum.ALL}
				/>
				<WidgetCountExamsRectificationAwaitingCP clinicCode={props.clinicCode} viewAsDoctor={false} />
			</WidgetsRowCP>

			<WidgetsRowCP margin={{ top: 30, bottom: 30 }}>
				<WidgetCountExamsAdminAnalysisCP clinicCode={props.clinicCode} />
				<WidgetCountExamsRectificationAnalysisCP clinicCode={props.clinicCode} />
				<WidgetCountExamsReturnedCP clinicCode={props.clinicCode} />
				<WidgetCountExamsPendingCP clinicCode={props.clinicCode} />
			</WidgetsRowCP>
		</>
	)
}
