import { TimelineExamHistoryCP } from 'modules/exams/components/exam-history/timeline-exam-history/TimelineExamHistoryCP'
import { ExamNotesCP } from 'modules/exams/components/exams/exam-notes/ExamNotesCP'
import React, { useEffect, useState } from 'react'
import { TabContentExamMainDataICP } from 'modules/exams/components/exams/tabs-exam-details/tabs/TabContentExamMainDataICP'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { TabContentExamMedicalReportICP } from 'modules/exams/components/exams/tabs-exam-details/tabs/TabContentExamMedicalReportICP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { TablePatientExamsCP } from 'modules/person/components/patient/table-patient-exams/TablePatientExamsCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

export type ExamsDrawerTabsTP = 'attachment' | 'notes' | 'info' | 'history' | 'dicomInfo'

interface ICPProps {
	exam: ExamResponseDTO
	selectedTab?: ExamsDrawerTabsTP
	onChangeData: (action: 'closeDrawer' | 'reloadData') => void
	onRegenerateMedicalReportPdf: () => void
}

/**
 * Drawer para exibir em abas de detalhes de um exame.
 */
export function TabsExamDetailsCP(props: ICPProps): JSX.Element | null {
	const [currentTab, setCurrentTab] = useState<ExamsDrawerTabsTP>('info')
	useEffect(() => setCurrentTab(props.selectedTab ?? 'info'), [props.selectedTab])

	// Se tiver analise administravia, somente usuario da clinica
	let canUserUploadAndRemove = true
	if (props.exam.doctorGroup?.config?.hasAdministrativeAnalysis)
		canUserUploadAndRemove = ClinicPermissionUtils.hasAnyPermissionsInClinic(props.exam.clinic.code, ['isAdmin', 'isUser'])

	return (
		<TabsCP
			defaultActiveKey={currentTab}
			tabs={[
				{
					title: 'Detalhes',
					key: 'info',
					content: (
						<TabContentExamMainDataICP
							exam={props.exam}
							onEditData={(closeDrawer) => props.onChangeData(closeDrawer ? 'closeDrawer' : 'reloadData')}
						/>
					),
				},
				{
					title: 'Laudo(s)',
					key: 'report',
					content: <TabContentExamMedicalReportICP exam={props.exam} onRegenerateMedicalReport={props.onRegenerateMedicalReportPdf} />,
					hide: ArrayUtils.isEmpty(props.exam?.medicalReports) || props.exam.status === ExamStatusEnum.IN_PROGRESS,
				},
				{
					title: 'Anexo',
					key: 'attachment',
					content: (
						<UploadExamAttachmentsCP
							examId={props.exam.id}
							showQrCode={true}
							type={ExamUploadFileTypeEnum.ATTACHMENT}
							permissions={{
								canRemove: canUserUploadAndRemove,
								canUpload: canUserUploadAndRemove,
								canShowFileList: true,
							}}
						/>
					),
				},
				{
					title: 'Notas',
					key: 'notes',
					content: (
						<ExamNotesCP
							examCode={props.exam.code}
							permissions={{
								canAdd: canUserUploadAndRemove,
							}}
						/>
					),
				},
				{
					title: 'Exames Ant.',
					key: 'patient-hist',
					content: <TablePatientExamsCP exam={props.exam} />,
				},
				{
					title: 'Histórico',
					key: 'history',
					content: <TimelineExamHistoryCP examCode={props.exam.code} />,
					hide: !ClinicPermissionUtils.hasAnyPermissionsInClinic(props.exam.clinic.code, [
						'isAdmin',
						'isUser',
						'isDoctorGroupUser',
						'isDoctorGroupAdmin',
					]),
				},
			]}
		/>
	)
}
