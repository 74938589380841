import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { AddPersonToDoctorGroupRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/AddPersonToDoctorGroupRequestDTO'
import { DoctorGroupDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupDoctorSaveRequestDTO'
import { DoctorGroupSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupSearchRequestDTO'
import { DoctorGroupTooglePersonAsAdminRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupTooglePersonAsAdminRequestDTO'
import { DoctorGroupPeopleSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupPeopleSearchRequestDTO'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { DoctorGroupPeopleNamesRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupPeopleNamesRequestDTO'
import { NameAndCodeResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/NameAndCodeResponseDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DoctorGroupSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupSaveRequestDTO'
import { DoctorGroupPeopleNamesResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/responses/DoctorGroupPeopleNamesResponseDTO'
import { UserDoctorGroupUpdateStatusRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/UserDoctorGroupUpdateStatusRequestDTO'

export class DoctorGroupsRequests {
	public static CONTROLLER_ROOT = 'doctor-groups'
	public static GET_EP = 'get/:code'
	public static CREATE_EP = 'create'
	public static UPDATE_EP = 'update/:code'
	public static SEARCH_EP = 'search'
	public static ADD_PERSON_EP = 'add-person'
	public static UPDATE_DOCTOR_DATA_EP = 'update-doctor-data/:userDoctorGroupCode'
	public static UPDATE_USER_DOCTOR_GROUP_STATUS_EP = 'update-user-doctor-group-status/:userDoctorGroupCode'
	public static SEARCH_PEOPLE_EP = 'search-people'
	public static EXPORT_PEOPLE_EP = 'export-people'
	public static NAMES_PEOPLE_EP = 'names-people'
	public static TOOGLE_PERSON_AS_ADMIN_EP = 'toogle-person-as-admin'
	public static NAMES_EP = 'names'

	static getOne = (code: number): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.GET_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.GET,
	})

	static create = (dto: DoctorGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.CREATE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static update = (code: number, dto: DoctorGroupSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static addPerson = (dto: AddPersonToDoctorGroupRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.ADD_PERSON_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static updateDoctorData = (userDoctorGroupCode: number, dto: DoctorGroupDoctorSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.UPDATE_DOCTOR_DATA_EP.replace(
			':userDoctorGroupCode',
			userDoctorGroupCode.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static updateUserDoctorGroupStatus = (userDoctorGroupCode: number, dto: UserDoctorGroupUpdateStatusRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.UPDATE_USER_DOCTOR_GROUP_STATUS_EP.replace(
			':userDoctorGroupCode',
			userDoctorGroupCode.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static searchPeople = (dto: DoctorGroupPeopleSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.SEARCH_PEOPLE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static exportPeople = (dto: DoctorGroupPeopleSearchRequestDTO): RequestConfigTP<'blob'> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.EXPORT_PEOPLE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
		responseType: 'blob',
	})

	static namesPeople = (dto: DoctorGroupPeopleNamesRequestDTO): RequestConfigTP<DoctorGroupPeopleNamesResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.NAMES_PEOPLE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static search = (dto: DoctorGroupSearchRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static toogleAsAdmin = (dto: DoctorGroupTooglePersonAsAdminRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.TOOGLE_PERSON_AS_ADMIN_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static names = (): RequestConfigTP<ListResponseDTO<NameAndCodeResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${DoctorGroupsRequests.CONTROLLER_ROOT}/${DoctorGroupsRequests.NAMES_EP}`,
		method: HttpMethodEnum.GET,
	})
}
