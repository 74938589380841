import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { RequestHelper } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestHelper'
import {
	REDUCER_CURRENT_DOCTOR_GROUP,
	REDUCER_LOGGED_PATIENT,
	REDUCER_LOGGED_USER,
	REDUCER_LOGGED_USER_CLINIC_PERMISSIONS,
	REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS,
} from 'app/redux/Reducers'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { UserDoctorGroupAccessTP } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/types/UserDoctorGroupAccessTP'
import { PersonClinicAccessTP } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/PersonClinicAccessTP'
import { AuthUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/utils/AuthUtils'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { IApiReturn } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/IApiReturn'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { PatientPortalLoginResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalLoginResponseDTO'

/**
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas ao modulo de AUTENTICACAO.
 */
export class AuthActions {
	private static handlingUnauthorizedError = false

	/**
	 * Encapsula procedimento completo de 'logar' 01 usuario do sistama.
	 */
	static setLoggedUser(authData: AuthResponseDTO): void {
		ReduxHelper.getInstance().store.dispatch({
			type: REDUCER_LOGGED_USER,
			payload: authData,
		})

		const loggedUserClinicPermissions: PersonClinicAccessTP = {
			isUser: authData.user.clinics?.some((clinic) => clinic.loggedUserAccess.isUser) ?? false,
			isAdmin: authData.user.clinics?.some((clinic) => clinic.loggedUserAccess.isAdmin) ?? false,
			isDoctorGroupAdmin: authData.user.clinics?.some((clinic) => clinic.loggedUserAccess.isDoctorGroupAdmin) ?? false,
			isDoctorGroupUser: authData.user.clinics?.some((clinic) => clinic.loggedUserAccess.isDoctorGroupUser) ?? false,
			isDoctor: authData.user.clinics?.some((clinic) => clinic.loggedUserAccess.isDoctor) ?? false,
		}
		ReduxHelper.getInstance().store.dispatch({
			type: REDUCER_LOGGED_USER_CLINIC_PERMISSIONS,
			payload: loggedUserClinicPermissions,
		})

		const loggedUserDoctorGroupPermissions: UserDoctorGroupAccessTP = {
			isDoctorGroupAdmin: authData.user.doctorGroups?.some((doctorGroup) => doctorGroup.loggedUserAccess.isDoctorGroupAdmin) ?? false,
			isAuditor: authData.user.doctorGroups?.some((doctorGroup) => doctorGroup.loggedUserAccess.isAuditor) ?? false,
			isDoctorGroupUser: authData.user.doctorGroups?.some((doctorGroup) => doctorGroup.loggedUserAccess.isDoctorGroupUser) ?? false,
			isDoctorAdmin: authData.user.doctorGroups?.some((doctorGroup) => doctorGroup.loggedUserAccess.isDoctorAdmin) ?? false,
		}
		ReduxHelper.getInstance().store.dispatch({
			type: REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS,
			payload: loggedUserDoctorGroupPermissions,
		})

		this.refreshToken(authData.token!)
	}

	/**
	 * Encapsula procedimento completo de logar paciente
	 */
	static setLoggedPatient(authData: PatientPortalLoginResponseDTO): void {
		console.log('setLoggedPatient', authData)
		ReduxHelper.getInstance().store.dispatch({
			type: REDUCER_LOGGED_PATIENT,
			payload: authData,
		})

		this.refreshToken(authData.token!)
	}

	/**
	 */
	static async refreshLoggedUserData(): Promise<void> {
		const response = await RequestHelper.runRequest<IApiReturn<AuthResponseDTO>>(AuthRequests.refreshLogin())
		const result = response?.data?.data
		if (!result) {
			AuthActions.onUnauthorizedRequestResponse()
			return
		}

		AuthActions.setLoggedUser(result)
	}

	/**
	 * Encapsula procedimento completo de 'deslogar' 01 usuario.
	 */
	static logout(): void {
		ReduxHelper.getInstance().store.dispatch({ type: REDUCER_LOGGED_USER, payload: null })
		ReduxHelper.getInstance().store.dispatch({ type: REDUCER_LOGGED_USER_CLINIC_PERMISSIONS, payload: null })
		ReduxHelper.getInstance().store.dispatch({ type: REDUCER_LOGGED_USER_DOCTOR_GROUP_PERMISSIONS, payload: null })
		ReduxHelper.getInstance().store.dispatch({ type: REDUCER_CURRENT_DOCTOR_GROUP, payload: null })
		this.refreshToken(null)
	}

	/**
	 * Encapsula procedimento completo de 'deslogar' 01 usuario.
	 */
	static logoutPatientPortal(): void {
		ReduxHelper.getInstance().store.dispatch({ type: REDUCER_LOGGED_PATIENT, payload: null })
		this.refreshToken(null)
	}

	/**
	 */
	static onUnauthorizedRequestResponse(): void {
		if (this.handlingUnauthorizedError) return

		if (!!AppStateUtils.getAuthData()) {
			this.handlingUnauthorizedError = true
			const notificationDuration = 6
			NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', notificationDuration)
			setTimeout(() => (AuthActions.handlingUnauthorizedError = false), notificationDuration - 1)
		}

		this.logout()

		NeritFrameworkRoutingHelper.historyPush(PublicRouter.USER_LOGIN)
	}

	/**
	 */
	static onUnauthorizedPatientPortalRequestResponse(): void {
		if (this.handlingUnauthorizedError) return

		if (!!AppStateUtils.getLoggedPatientPortal()) {
			this.handlingUnauthorizedError = true
			const notificationDuration = 6
			NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Insira seus dados novamente para prosseguir', notificationDuration)
			setTimeout(() => (AuthActions.handlingUnauthorizedError = false), notificationDuration - 1)
		}

		AuthActions.logoutPatientPortal()
		NeritFrameworkRoutingHelper.historyPush(PublicRouter.PATIENT_PORTAL_LOGIN)
	}

	/**
	 * Atualiza propriedades estaticas que guardam o token de autenticacao do usuario atual sincronizando-as com o conteudo mantido no estado global da aplicacao (redux).
	 */
	static refreshToken(authToken: string | null): void {
		if (!authToken) return

		RequestHelper.addDefaultHeaderConfig(AuthUtils.getBearerAuthHeaderConfig(authToken))
		RequestHelper.addDefaultHeaderConfig(AuthUtils.getOnBehalfHeaderConfig(AppStateUtils.getDomain() ?? ProjectWhiteLabelsEnum.BEERADS))
	}
}
