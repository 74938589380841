import React, { useState } from 'react'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { PendingExamFormModel } from 'modules/exams/components/exams/drawer-exam-pending/inner/form-models/PendingExamFormModel'
import { SelectClinicDoctorGroupCP } from 'modules/clinic/components/select-clinic-doctor-group/SelectClinicDoctorGroupCP'
import styled from 'styled-components'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

interface ICPProps {
	clinicCode: number
	formStateManager: IFormStateManager<PendingExamFormModel>
}

/**
 * Parte de selecao do grupo que ira atender esse exame.
 */
export function FormExamPendingDoctorGroupDataCP(props: ICPProps): JSX.Element {
	const [shouldHide, setShouldHide] = useState<boolean>(false)
	const [loadedDoctorGroupNames, setLoadedDoctorGroupNames] = useState<DoctorGroupNamesFromClinicResponseDTO[]>()

	/**
	 * Ao carregar opcoes do grupo
	 */
	function onLoadOptions(options?: DoctorGroupNamesFromClinicResponseDTO[]): void {
		setLoadedDoctorGroupNames(options)

		if (!options || options.length === 0) return NotificationHelper.error('Ops', 'Você não tem nenhum grupo de médicos vinculado a sua clínica')

		if (options.length > 1) return

		// Se tiver apenas um grupo vinculado na clinica, esconde essa area e o deixa selecionado
		const selectedDoctorGroup = options[0]
		props.formStateManager.changeFieldValue('doctorGroupCode', selectedDoctorGroup.code)
		setShouldHide(true)

		onSelectDoctorGroup(selectedDoctorGroup)
	}

	/**
	 */
	function onSelectDoctorGroup(doctorGroupSelected: number | DoctorGroupNamesFromClinicResponseDTO): void {
		let doctorGroup: DoctorGroupNamesFromClinicResponseDTO | undefined

		if (typeof doctorGroupSelected === 'number') doctorGroup = loadedDoctorGroupNames?.find((dg) => dg.code === doctorGroupSelected)
		else doctorGroup = doctorGroupSelected

		props.formStateManager.changeFieldValue('doctorGroup', doctorGroup)
		setShouldHide(true)
	}

	if (!!props.formStateManager.getFieldValue('doctorGroupCode') && shouldHide) return <></>

	return (
		<WrapperSCP>
			<TitleCP textSize={'normal'} underLine={true}>
				Grupo de Médico que irá Laudar o exame
			</TitleCP>
			<RowCP>
				<ColumnCP size={12}>
					<SelectClinicDoctorGroupCP
						formStateManager={props.formStateManager}
						formField={'doctorGroupCode'}
						clinicCode={props.clinicCode}
						onLoadOptions={onLoadOptions}
						onChange={onSelectDoctorGroup}
					/>
				</ColumnCP>
			</RowCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	margin-bottom: 30px;
`
