import React, { useState } from 'react'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableClinicPeopleCP } from 'modules/clinic/components/table-clinic-people/TableClinicPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ModalAddUserToClinicCP } from 'modules/person/components/user/modal-add-user/ModalAddUserToClinicCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'

interface ICPProps {
	clinicCode: number
	doctorGroupCode?: number
	onSave: () => void
}

/**
 * Vincula clinica com o grupo de medicos e as modalidades selecionadas
 */
export function CreateClinicUsersStepICP(props: ICPProps): JSX.Element {
	const [isNewUserModalVisible, setIsNewUserModalVisible] = useState<boolean>(false)
	const [reloadTable, setReloadTable] = useState<number>(0)

	return (
		<>
			<FlexCP justifyContent={'flex-end'} margin={{ bottom: 10 }}>
				<ButtonCP onClick={() => setIsNewUserModalVisible(true)}>Adicionar usuário</ButtonCP>
			</FlexCP>

			<TableClinicPeopleCP
				clinicCode={props.clinicCode}
				relationMode={ClinicPersonRelationModeEnum.IN}
				shouldReload={reloadTable}
				role={UserTypeEnum.USER}
				tableConfig={{
					showToogleAdminButton: true,
				}}
			/>

			<FlexCP margin={{ top: 30 }} justifyContent={'flex-end'}>
				<ButtonCP type={'primary'} onClick={props.onSave}>
					Prosseguir
				</ButtonCP>
			</FlexCP>

			<ModalAddUserToClinicCP
				visible={isNewUserModalVisible}
				clinicCode={props.clinicCode}
				onCancel={() => setIsNewUserModalVisible(false)}
				onSave={() => {
					setIsNewUserModalVisible(false)
					setReloadTable(TableUtils.getReloadNumber())
				}}
			/>
		</>
	)
}
