import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsValidCnpj } from 'submodules/nerit-framework-utils/validators/decorators/IsValidCnpj'
import { IsOptional } from 'class-validator'

export class DoctorGroupFormModel extends FormModel {
	@IsValidCnpj()
	@IsRequired()
	cnpj: string

	@IsRequired()
	crm: string

	@IsRequired()
	name: string

	@IsRequired()
	fantasyName: string

	@IsOptional()
	hasAdministrativeAnalysis: boolean

	@IsOptional()
	maxExamsPerDoctor?: number

	@IsOptional()
	shouldValidateExamDescription?: boolean

	@IsOptional()
	isAwaitingFirstStatus?: boolean

	@IsOptional()
	blockMultipleMedicalReportsOnExam?: boolean

	@IsOptional()
	canSeeExamOutsideMedicalReport?: boolean

	@IsOptional()
	hasDigitalCertificateData?: boolean

	constructor(initialData?: ObjectPropsTP<DoctorGroupFormModel>) {
		super()
		Object.assign(this, initialData)
	}
}
