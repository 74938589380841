import { IDoctorsOnMedicalTimetableRecurrenceRules } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/interfaces/IDoctorsOnMedicalTimetableRecurrenceRules'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { MedicalTimetableWeekDayRecurrencyTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDayRecurrencyTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { momentPtBrLocale } from 'submodules/nerit-framework-utils/utils/date/moment-pt-br-locale'
import { MedicalTimetableWeekDaySpotTP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableWeekDaySpotTP'
import { DoctorTimetableHourRangeSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorTimetableHourRangeSaveRequestDTO'
import { IWeekDayHourRanges } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/interfaces/IWeekDayHourRanges'
import { MedicalTimetableAssignFormModel } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/inner/MedicalTimetableAssignFormModel'
import moment from 'moment-timezone'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { DoctorScheduleSearchResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorScheduleSearchResponseDTO'

/**
 */
export class MedicalTimetableAssignFormUtils {
	/**
	 */
	static formatRulesDto(rulesMap?: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP>): IDoctorsOnMedicalTimetableRecurrenceRules[] | undefined {
		const rules: IDoctorsOnMedicalTimetableRecurrenceRules[] = []

		if (!rulesMap) return

		rulesMap.forEach((item, weekDay) => {
			if (!item.selected) return

			if (!item.recurrencyQuantity) {
				NotificationHelper.error('Ops', `Preencha o intervalo de repetição de ${momentPtBrLocale.weekdaysFull[weekDay]}`)
				return
			}

			if (!item.beginHour || !item.endHour) {
				NotificationHelper.error('Ops', `Preencha o horário no intervalo de repetição de ${momentPtBrLocale.weekdaysFull[weekDay]}`)
				return
			}

			rules.push({
				dayOfWeek: weekDay,
				recurrence: item.recurrencyQuantity,
			})
		})

		return rules
	}
	/**
	 */
	static formatSpotDto(map?: Map<WeekDaysEnum, MedicalTimetableWeekDaySpotTP>): DoctorTimetableHourRangeSaveRequestDTO[] | undefined {
		const spot: DoctorTimetableHourRangeSaveRequestDTO[] = []

		if (!map) return

		map.forEach((item, weekDay) => {
			if (!item.selected) return

			if (!item.beginHour || !item.endHour) {
				NotificationHelper.error('Ops', `Preencha o horário no intervalo de repetição de ${momentPtBrLocale.weekdaysFull[weekDay]}`)
				return
			}

			spot.push({
				dayOfWeek: weekDay,
				hourRange: {
					beginHour: item.beginHour,
					endHour: item.endHour,
				},
			})
		})

		return spot
	}

	static formatRecurrencyDto(map?: Map<WeekDaysEnum, MedicalTimetableWeekDaySpotTP>): DoctorTimetableHourRangeSaveRequestDTO[] | undefined {
		const rules: DoctorTimetableHourRangeSaveRequestDTO[] = []

		if (!map) return

		map.forEach((item, weekDay) => {
			if (!item.selected) return

			if (!item.beginHour || !item.endHour) {
				NotificationHelper.error('Ops', `Preencha o horário no intervalo de repetição de ${momentPtBrLocale.weekdaysFull[weekDay]}`)
				return
			}

			rules.push({
				dayOfWeek: weekDay,
				hourRange: {
					beginHour: item.beginHour,
					endHour: item.endHour,
				},
			})
		})

		return rules
	}
	/**
	 */
	static formatRecurrencyMap(
		rules?: IDoctorsOnMedicalTimetableRecurrenceRules[],
		hourRange?: IWeekDayHourRanges[],
	): Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> {
		const recurrencyMap: Map<WeekDaysEnum, MedicalTimetableWeekDayRecurrencyTP> = new Map()

		if (!rules || !hourRange) return recurrencyMap

		rules.forEach((rule, index) => {
			recurrencyMap.set(rule.dayOfWeek, {
				selected: true,
				recurrencyQuantity: rule.recurrence,
				beginHour: hourRange[index].hourRange?.beginHour,
				endHour: hourRange[index].hourRange?.endHour,
			})
		})

		return recurrencyMap
	}

	static formatSpotMap(wekDayHourRange?: IWeekDayHourRanges[]): Map<WeekDaysEnum, MedicalTimetableWeekDaySpotTP> {
		const spotMap: Map<WeekDaysEnum, MedicalTimetableWeekDaySpotTP> = new Map()

		if (!wekDayHourRange) return spotMap

		spotMap.set(wekDayHourRange[0].dayOfWeek!, {
			selected: true,
			beginHour: wekDayHourRange[0].hourRange?.beginHour,
			endHour: wekDayHourRange[0].hourRange?.endHour,
		})

		return spotMap
	}

	static getFormModel(
		dto: DoctorScheduleSearchResponseDTO,
		formModel: MedicalTimetableAssignFormModel,
		isRecurrent: boolean,
		isEdit: boolean,
	): MedicalTimetableAssignFormModel {
		if (isRecurrent || !isEdit)
			return new MedicalTimetableAssignFormModel({
				...formModel,
				description: dto.description,
				type: 'recurrent',
				recurrency: MedicalTimetableAssignFormUtils.formatRecurrencyMap(dto.rules, dto.weekDayHourRanges),
				endDate: !!dto.endDate ? moment(dto.endDate) : undefined,
				doctorCode: dto.userDoctorCode,
			})

		return new MedicalTimetableAssignFormModel({
			...formModel,
			code: dto.code,
			description: dto.description,
			extraValue: dto.extraValue ? MaskUtils.applyNumberMask(dto.extraValue, 2) : undefined,
			shouldKeepDynamicValue: dto.shouldKeepDynamicValue,
			type: 'spot',
			spot: MedicalTimetableAssignFormUtils.formatSpotMap(dto.weekDayHourRanges),
			doctorCode: dto.userDoctorCode,
		} as MedicalTimetableAssignFormModel)
	}
}
