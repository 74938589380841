import React from 'react'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { AuditStatusEnum, AuditStatusLabelEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditStatusEnum'

interface ICPProps {
	auditStatus: AuditStatusEnum
}

/**
 */
export function TagAuditStatusCP(props: ICPProps): JSX.Element {
	return <TagCP color={ThemeProjectCommon.auditStatusColor[props.auditStatus]} content={AuditStatusLabelEnum[props.auditStatus]} />
}
