import { AuditFilterFormModel } from 'modules/quality-assurance/components/filters-audits/form-model/AuditFilterFormModel'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

/**
 */
export class AuditFilterFormModelConverter {
	/**
	 */
	static convertToDto(formStateManager: IFormStateManager<AuditFilterFormModel>, doctorGroupCode?: number): AuditSearchRequestDTO {
		return {
			doctorGroupCode,
			statuses: formStateManager.getFieldValue('status'),
			doctorAuditorCode: formStateManager.getFieldValue('doctorAuditorCode'),
			doctorExamCode: formStateManager.getFieldValue('doctorExamCode'),
			dateRange: formStateManager.getFieldValue('dateRange'),
			clinicCodes: formStateManager.getFieldValue('clinicCodes'),
		}
	}
}
