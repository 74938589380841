import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { AuditScoreDescriptionEnum, AuditScoreEnum } from 'submodules/beerads-sdk/services/exams/audits/enums/AuditScoreEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { RoundedWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/RoundedWrapperCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'

interface ICPProps<FModelTP extends FormModel = any> {
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
}

/**
 */
export function SelectAuditScoreCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={'Score'}
			fieldName={props.fieldName}
			formStateManager={props.formStateManager}
			required={true}
			options={Object.values(AuditScoreEnum).map((score) => ({
				value: score,
				label: (
					<FlexCP alignItems={'center'}>
						<RoundedWrapperCP color={ThemeProjectCommon.auditScoreColor[score]} />
						<TextCP marginLeft={5} text={AuditScoreDescriptionEnum[score]} />
					</FlexCP>
				),
			}))}
		/>
	)
}
