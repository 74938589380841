import React from 'react'
import { TeamResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/teams/dtos/responses/TeamResponseDTO'
import { TagTeamCP } from 'modules/doctor-groups/components/teams/tag-team/TagTeamCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps {
	teams?: TeamResponseDTO[]
	onClick?: (team: TeamResponseDTO) => void
}

/**
 * Time em forma de tag
 */
export function TagsTeamsCP(props: ICPProps): JSX.Element {
	return (
		<FlexCP>
			{props.teams?.map((team) => (
				<TagTeamCP onClick={() => props.onClick?.(team)} team={team} />
			))}
		</FlexCP>
	)
}
