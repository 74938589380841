import React from 'react'
import { Row } from 'antd'
import { TextCP } from '_old/main/common/components/text/TextCP'
import { CheckBoxGroupFormCP } from '_old/main/common/components/form-fields/checkbox-group/CheckBoxGroupFormCP'

import { IOitSectionProps, OitSectionContentICP } from 'modules/exams/components/medical-report/editor-oit-medical-report/inner/OitSectionContentICP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RadioGroupCP } from 'submodules/nerit-framework-ui/common/components/form-fields/radio-group/RadioGroupCP'
import styled from 'styled-components'

export function OitSection2BICP(props: IOitSectionProps): JSX.Element {
	return (
		<RowCP>
			<ColumnCP size={18} style={{ paddingRight: 0 }}>
				<OitSectionContentICP title={'2B - Pequenas opacidades'}>
					<ColumnCP size={8}>
						<TextCP text={'Forma/tamanho'} />
						<Row>
							<ColumnCP size={12}>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'P', value: 'P' },
										{ content: 'S', value: 'S' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BPrimaryPS'}
									label={'Primária'}
								/>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'Q', value: 'Q' },
										{ content: 'T', value: 'T' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BPrimaryQT'}
								/>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'R', value: 'R' },
										{ content: 'U', value: 'U' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BPrimaryRU'}
								/>
							</ColumnCP>
							<ColumnCP size={12}>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'P', value: 'P' },
										{ content: 'S', value: 'S' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BSecondaryPS'}
									label={'Secundária'}
								/>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'Q', value: 'Q' },
										{ content: 'T', value: 'T' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BSecondaryQT'}
								/>
								<RadioGroupCP
									type={'button'}
									options={[
										{ content: 'R', value: 'R' },
										{ content: 'U', value: 'U' },
									]}
									formStateManager={props.formStateManager}
									fieldName={'answer2BSecondaryRU'}
								/>
							</ColumnCP>
						</Row>
					</ColumnCP>
					<ColumnCP size={4}>
						<TextCP text={'Zonas'} />

						<CheckBoxGroupFormCP options={['D', 'E']} formStateManager={props.formStateManager as any} fieldName={'answer2BZone1'} />
						<CheckBoxGroupFormCP options={['D', 'E']} formStateManager={props.formStateManager as any} fieldName={'answer2BZone2'} />
						<CheckBoxGroupFormCP options={['D', 'E']} formStateManager={props.formStateManager as any} fieldName={'answer2BZone3'} />
					</ColumnCP>
					<ColumnCP size={8}>
						<TextCP text={'Profusão'} />
						<WrapperSCP>
							<RadioGroupCP
								type={'button'}
								options={[
									{ content: '0/-', value: '0/-' },
									{ content: '0/0', value: '0/0' },
									{ content: '0/1', value: '0/1' },
									{ content: '1/0', value: '1/0' },
									{ content: '1/1', value: '1/1' },
									{ content: '1/2', value: '1/2' },
									{ content: '2/1', value: '2/1' },
									{ content: '2/2', value: '2/2' },
									{ content: '2/3', value: '2/3' },
									{ content: '3/2', value: '3/2' },
									{ content: '3/3', value: '3/3' },
									{ content: '3/+', value: '3/+' },
								]}
								formStateManager={props.formStateManager}
								fieldName={'answer2BProfusion'}
							/>
						</WrapperSCP>
					</ColumnCP>
				</OitSectionContentICP>
			</ColumnCP>
			<ColumnCP size={6} style={{ paddingLeft: 0 }}>
				<OitSectionContentICP title={'2C - Grandes opacidades'}>
					<RadioGroupCP
						type={'button'}
						options={[
							{ content: '0', value: '0' },
							{ content: 'A', value: 'A' },
							{ content: 'B', value: 'B' },
							{ content: 'C', value: 'C' },
						]}
						formStateManager={props.formStateManager}
						fieldName={'answer2C'}
						label={' '}
					/>
				</OitSectionContentICP>
			</ColumnCP>
		</RowCP>
	)
}

const WrapperSCP = styled.div`
	.ant-radio-group {
		display: grid !important;
		grid-template-columns: 1fr 1fr 1fr !important;

		.ant-radio-button-wrapper {
			border-radius: 0 !important;
		}
	}
`
