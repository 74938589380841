import React, { useEffect } from 'react'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ClinicDoctorGroupInviteRequestDTO } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/dtos/requests/ClinicDoctorGroupInviteRequestDTO'
import { ClinicDoctorGroupRequests } from 'submodules/beerads-sdk/services/clinic-doctor-groups/clinic-doctor-group/ClinicDoctorGroupRequests'

interface ICPProps {
	clinic: ClinicResponseDTO
	doctorGroupCode?: number
	onInviteToDoctorGroup: () => void
	onCancel: () => void
}

/**
 * Detalhes da Clinica Encontrada
 */
export function FoundClinicResultICP(props: ICPProps): JSX.Element {
	const inviteClinicRequest = useRequest<void>('none')
	useEffect(onInviteClinicRequestChange, [inviteClinicRequest.isAwaiting])

	/**
	 */
	async function invite(): Promise<void> {
		if (!props.doctorGroupCode) {
			NotificationHelper.error('Ops', 'Grupo não informado')
			return
		}

		const dto: ClinicDoctorGroupInviteRequestDTO = {
			clinicCode: props.clinic.code,
			doctorGroupCode: props.doctorGroupCode,
			requester: 'doctorGroup',
		}

		inviteClinicRequest.runRequest(ClinicDoctorGroupRequests.invite(dto))
	}

	/**
	 */
	function onInviteClinicRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				inviteClinicRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onInviteToDoctorGroup()
	}

	return (
		<>
			<AlertCP
				message={'A unidade hospitalar com o CNPJ informado já está cadastrada. Envie o convite para seu grupo laudar'}
				type={'success'}
				margin={{ bottom: 20 }}
			/>

			<TextAndTitleCP title={'Nome:'} text={props.clinic.fantasyName} />
			<TextAndTitleCP title={'CNPJ:'} text={MaskUtils.applyMask(props.clinic.cnpj, InputMaskTypeEnum.CNPJ)} />

			<FlexCP justifyContent={'center'} margin={{ top: 20 }}>
				{!!props.doctorGroupCode ? (
					<ButtonCP type={'primary'} onClick={invite} loading={inviteClinicRequest.isAwaiting}>
						Enviar convite para Unidade Hospitalar
					</ButtonCP>
				) : (
					<ButtonCP onClick={props.onCancel}>Fechar</ButtonCP>
				)}
			</FlexCP>
		</>
	)
}
