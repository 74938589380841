import React from 'react'
import styled from 'styled-components'

interface ILoaderThreeDotsCPProps {
	width: number
	color?: string
}

/**
 * COMPONENTE
 * Loader para sinalizacao de carregamento:
 * Contem 03 pontos (reticencias) 'saltando' (animacao).
 *
 * @author hjcostabr
 */
export const LoaderThreeDotsCP = (props: ILoaderThreeDotsCPProps): JSX.Element => {
	const DEFAULT_COLOR = 'red'
	const dotSize = 0.16 * props.width

	return (
		<DotsContainerSCP width={props.width} dotSize={dotSize}>
			<DotsSCP color={props.color || DEFAULT_COLOR} containerWidth={props.width} dotSize={dotSize} />
		</DotsContainerSCP>
	)
}

const DotsContainerSCP = styled.div<{ width: number; dotSize: number }>`
	overflow: hidden;
	width: ${(props) => props.width}px;
	padding-bottom: ${(props) => props.dotSize / 2}px;
	padding-top: ${(props) => props.dotSize / 4}px;
`

const DotsSCP = styled.div<{ containerWidth: number; dotSize: number; color: string }>`
	width: ${(props) => props.dotSize}px;
	height: ${(props) => props.dotSize}px;
	animation: loaderThreeDotsANM 1.5s infinite linear;

	position: relative;
	border-radius: 50%;
	background-color: ${(props) => props.color};
	left: -${(props) => 1.92 * props.containerWidth}px;
	box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize)};

	@keyframes loaderThreeDotsANM {
		0% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize)};
		}
		16.667% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize, 1)};
		}
		33.333% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize)};
		}
		50% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize, 2)};
		}
		66.667% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize)};
		}
		83.333% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize, 3)};
		}
		100% {
			box-shadow: ${(props) => getDotsBoxShadow(props.color, props.containerWidth, props.dotSize)};
		}
	}
`

/**
 * Define & retorna o valor da propriedade 'box-shadow'
 * parametrizando para gerar a animacao dos 03 pontos 'saltando'.
 *
 * @param color Cor dos pontos.
 * @param containerWidth Largura do container que envolve os 03 pontos.
 * @param dotSize Largura de 01 ponto.
 * @param shiftedDot Indice do ponto que devera ser deslocado para a
 * animacao (se houver).
 */
function getDotsBoxShadow(color: string, containerWidth: number, dotSize: number, shiftedDot?: 1 | 2 | 3): string {
	const horizontalOffset = 2 * containerWidth

	const shiftSizes = [0, 0, 0]
	if (shiftedDot) shiftSizes[shiftedDot - 1] = dotSize / 3

	return `
        ${horizontalOffset}px                    ${shiftSizes[0]}px 0px ${color},
        ${horizontalOffset + 2 * dotSize}px    ${shiftSizes[1]}px 0px ${color},
        ${horizontalOffset + 4 * dotSize}px    ${shiftSizes[2]}px 0px ${color};
    `
}
