import React from 'react'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { TagsExamModalitiesCP } from 'modules/exams/components/exams/tags-exam-modalities/TagsExamModalitiesCP'
import { ButtonToggleClinicPersonAdminCP } from 'modules/clinic/components/button-toggle-clinic-user-admin/ButtonToggleClinicPersonAdminCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TagActiveCP } from 'submodules/nerit-framework-ui/common/components/tag/tag-active/TagActiveCP'
import { PersonClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/person-clinic/dtos/responses/PersonClinicResponseDTO'
import { ITableColumn } from 'submodules/nerit-framework-ui/common/components/table/types/ITableColumn'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { PersonClinicsRequests } from 'submodules/beerads-sdk/services/clinics/person-clinic/PersonClinicsRequests'
import { ClinicPersonRelationModeEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicPersonRelationModeEnum'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { SwitchClinicNotificationCP } from 'modules/clinic/components/switch-clinic-notification/SwitchClinicNotificationCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { NotificationTypeEnum } from 'submodules/beerads-sdk/services/clinics/person-clinic/types/NotificationTypeEnum'
import { ButtonSetPasswordCP } from 'submodules/nerit-framework-ui/features/user/components/set-password-modal/ButtonSetPasswordCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { ButtonEditUserDataCP } from 'modules/person/components/user/button-edit-user-data/ButtonEditUserDataCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ButtonCopyToClipboardCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCopyToClipboardCP'
import styled from 'styled-components'

export const TableClinicPeopleUtils = {
	getColumns(
		role: UserTypeEnum,
		onChangeData: () => void,
		relationMode: ClinicPersonRelationModeEnum,
		clinicCode: number,
		doctorGroupCode?: number,
		showToogleAdminButton?: boolean,
		onEditDoctor?: (doctor: PersonClinicResponseDTO) => void,
	): Array<ITableColumn<PersonClinicResponseDTO>> {
		return [
			{
				title: 'Nome',
				render: (_, record) => record.person.name,
			},
			{
				title: 'Dados pessoais',
				hide: role === UserTypeEnum.DOCTOR,
				render: (_, record) => (
					<WrapperSCP>
						<FlexCP gap="10px">
							<TextCP text={`CPF: ${MaskUtils.applyMask2(InputMaskTypeEnum.CPFCNPJ, record.person.cpf)}`} />
							<ButtonCopyToClipboardCP
								type={'link'}
								size={'small'}
								textToCopy={`${MaskUtils.applyMask2(InputMaskTypeEnum.CPFCNPJ, record.person.cpf)}`}
							/>
						</FlexCP>
						<FlexCP gap="10px">
							<TextCP text={`Número: ${MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, record.person.phone)}`} />
							<ButtonCopyToClipboardCP
								type={'link'}
								size={'small'}
								textToCopy={`${MaskUtils.applyMask2(InputMaskTypeEnum.PHONE, record.person.phone)}`}
							/>
						</FlexCP>
						<FlexCP gap="10px">
							<TextCP text={`E-mail: ${record.person.email}`} />
							<ButtonCopyToClipboardCP type={'link'} size={'small'} textToCopy={`${record.person.email}`} />
						</FlexCP>
					</WrapperSCP>
				),
			},
			{
				title: 'Status',
				hide: role === UserTypeEnum.DOCTOR,
				render: (_, record) => <TagActiveCP isActive={record.isActive} />,
			},
			{
				title: 'Modalidades',
				hide: role === UserTypeEnum.USER || relationMode === ClinicPersonRelationModeEnum.NOT_IN,
				render: (_, record) => <TagsExamModalitiesCP modalities={record.modalities} />,
			},
			{
				title: 'Notificações',
				hide: role === UserTypeEnum.DOCTOR,
				render: (_, record) => (
					<FlexCP>
						<SwitchClinicNotificationCP
							personClinicCode={record.code}
							shouldNotify={record.notification?.notifyCriticalExam ?? false}
							notificationType={NotificationTypeEnum.CRITICAL_EXAM}
							helpText={'Achado crítico'}
						/>
						<SwitchClinicNotificationCP
							personClinicCode={record.code}
							shouldNotify={record.notification?.notifyIntegrationFailure ?? false}
							notificationType={NotificationTypeEnum.INTEGRATION_FAILURE}
							helpText={'Falha de Integração'}
						/>
					</FlexCP>
				),
			},
			{
				align: 'right',
				render: (_, record) => (
					<ButtonGroupCP mode={'separeted'}>
						{showToogleAdminButton && (
							<ButtonToggleClinicPersonAdminCP
								size={'small'}
								role={role}
								clinicCode={record.clinic.code}
								doctorGroupCode={doctorGroupCode}
								personCode={record.person.code}
								isAdmin={record.isAdmin}
							/>
						)}
						{!!onEditDoctor && <ButtonCP icon={'edit'} size={'small'} onClick={() => onEditDoctor(record)} />}

						<ButtonSetPasswordCP
							requestConfig={() => AuthRequests.setPassword(record.person.code)}
							hasPermission={(AppStateUtils.getLoggedUser()?.isBeeradsAdmin && role === UserTypeEnum.USER) ?? false}
						/>

						{role === UserTypeEnum.USER && (
							<AccessControlCP
								permission={{ isBeeRadsAdmin: true, byHasAnyPermissionInClinic: { permissions: ['isAdmin'], clinicCode: clinicCode } }}
							>
								<ButtonEditUserDataCP person={record.person} onChangeData={onChangeData} appearance={{ size: 'small' }} />
							</AccessControlCP>
						)}

						<ButtonDeleteRecordCP onDelete={onChangeData} requestConfigTP={() => PersonClinicsRequests.delete(record.code)} />
					</ButtonGroupCP>
				),
			},
		]
	},
}

const WrapperSCP = styled.div`
	font-size: 12px;
`
