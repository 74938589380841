import { Mentions } from 'antd'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export type MentionOptionTP = {
	value: any
	label: string
}

interface IMentionCPProps extends IFormItemInputCommonProps {
	prefix: string
	options: MentionOptionTP[]
	rows?: number
	maxLength?: number
	loading?: boolean
	appearance?: {
		bordered?: boolean
	}
}

/**
 */
export function MentionCP(props: IMentionCPProps): JSX.Element {
	const [value, setValue] = useState<string>()
	useEffect(() => onChange(props.formStateManager?.getFieldValue(props.fieldName ?? '')), [props.formStateManager?.getFieldValue(props.fieldName ?? '')])
	useEffect(() => onChange(props.value), [props.value])

	/**
	 */
	function onChange(option?: string): void {
		const newValue = option?.replace(props.prefix, '')
		setValue(newValue)

		if (!!props.formStateManager && !!props.fieldName) props.formStateManager.changeFieldValue(props.fieldName, newValue)

		if (!!props.onChange) props.onChange(newValue)
	}

	return (
		<MentionSCP bordered={props.appearance?.bordered}>
			<FormItemICP<any, IFormItemInputCommonProps>
				label={props.label}
				onChange={onChange}
				// fieldName={props.fieldName}
				// formStateManager={props.formStateManager}
				required={props.required}
				width={props.width}
				onFormSubmit={props.onFormSubmit}
				value={value}
				mask={props.mask}
				fontSize={props.fontSize}
				errorMessage={props.errorMessage}
				noValidation={props.noValidation}
				hint={props.hint}
			>
				<Mentions maxLength={props.maxLength} rows={props.rows ? props.rows : 1} prefix={props.prefix} loading={props.loading}>
					{props.options.map((value) => (
						<Mentions.Option key={value.value} value={value.value}>
							{value.label}
						</Mentions.Option>
					))}
				</Mentions>
			</FormItemICP>
		</MentionSCP>
	)
}

const MentionSCP = styled.div<{ bordered?: boolean }>`
	.ant-mentions {
		border-radius: ${(props) => (!props.bordered ? 0 : undefined)};
		border: ${(props) => (!props.bordered ? '1px none' : 'solid 1px #bbb')};
		border-bottom-style: ${(props) => (!props.bordered ? 'solid' : undefined)};

		textarea {
			background: transparent;
		}
	}
`
