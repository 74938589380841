import React, { useState } from 'react'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ReduxHelper } from 'app/redux/helpers/ReduxHelper'
import { REDUCER_CURRENT_DOCTOR_GROUP } from 'app/redux/Reducers'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import styled from 'styled-components'
import { SelectDoctorGroupFromLoggedUserCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-groups/SelectDoctorGroupFromLoggedUserCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	disabled?: boolean
	onChange: () => void
}

/**
 */
export function SelectCurrentDoctorGroupCP(props: ICPProps): JSX.Element {
	const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false)
	const currentDoctorGroup = AppStateUtils.getCurrentDoctorGroup()

	/**
	 */
	function onSelectDoctorGroup(doctorGroupCode: number): void {
		let foundDoctorGroup
		if (!!doctorGroupCode) foundDoctorGroup = AppStateUtils.getLoggedUser()?.doctorGroups?.find((doctorGroup) => doctorGroup.code === doctorGroupCode)

		if (!!foundDoctorGroup) ReduxHelper.updateStoredValue(REDUCER_CURRENT_DOCTOR_GROUP, foundDoctorGroup)
		else ReduxHelper.updateStoredValue(REDUCER_CURRENT_DOCTOR_GROUP, null)

		setIsPopoverVisible(false)
		props.onChange()
	}

	if (ArrayUtils.isEmpty(AppStateUtils.getLoggedUser()?.doctorGroups)) return <></>

	return (
		<ContentWrapperSCP>
			<PopOverCP
				trigger={'click'}
				placement={'right'}
				visible={isPopoverVisible}
				onClose={() => setIsPopoverVisible(false)}
				title={'Alterar Grupo de Médico'}
				content={
					<ContainerWrapperSCP>
						<SelectDoctorGroupFromLoggedUserCP allowClear={true} value={currentDoctorGroup?.code} onChange={onSelectDoctorGroup} width={300} />
					</ContainerWrapperSCP>
				}
			>
				<ButtonCP
					tooltip={
						!!currentDoctorGroup
							? `Você está logado no grupo: ${currentDoctorGroup.name}`
							: 'Nenhum grupo selecionar, você irá visualizar exames de todos os grupos'
					}
					tooltipPlacement={'topLeft'}
					color={ThemeFrameworkCommon.white}
					onClick={() => setIsPopoverVisible(true)}
					type={'link'}
				>
					{!currentDoctorGroup?.name
						? '...'
						: currentDoctorGroup.name.length <= 3
						? currentDoctorGroup.name
						: currentDoctorGroup.name.slice(0, 3)}
				</ButtonCP>
			</PopOverCP>
		</ContentWrapperSCP>
	)
}

const ContentWrapperSCP = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  button {
    font-weight: bold;
  } 
`

const ContainerWrapperSCP = styled.div`
	.ant-form-explain {
		display: none;
	}
`
