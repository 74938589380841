import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import React, { useEffect } from 'react'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { AuditFormModel } from 'modules/quality-assurance/screens/screen-audit/inner/AuditFormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { AuditFinalizeRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditFinalizeRequestDTO'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { StartAuditResponseDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/response/StartAuditResponseDTO'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import styled from 'styled-components'

interface ICPProps {
	auditData: StartAuditResponseDTO
	formStateManager: IFormStateManager<AuditFormModel>
}

/**
 * Header da tela de edição de laudo.
 */
export function HeaderAuditScreenCP(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	const saveRequest = useRequest<'void'>('none')
	useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

	/**
	 */
	async function save(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(props.formStateManager))) return

		const formValues = props.formStateManager.getFormValues()!
		const dto: AuditFinalizeRequestDTO = {
			auditCode: props.auditData.audit.code,
			comments: formValues.comments,
			score: formValues.score,
		}
		saveRequest.runRequest(AuditsRequests.finalize(dto))
	}

	/**
	 */
	function onSaveRequestChange(): void {
		// Avalia retorno da ultima requisicao
		if (
			!RequestUtils.isValidRequestReturn(
				saveRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.DOCTOR_AUDITS)
	}

	return (
		<HeaderCP
			title={screenSize.smd ? undefined : 'Auditoria Médica'}
			arrowBack={true}
			onClickBack={() => NeritFrameworkRoutingHelper.historyPush(PrivateUserRouter.DOCTOR_AUDITS)}
			middleContent={
				<ExamInfoSCP>
					<AvatarExamModalityCP modality={props.auditData.exam.modality} fontSize={'normal'} />

					<TextWrapperSCP>
						<TextCP text={props.auditData.exam.patient.name} strong={true} size={'extraSmall'} />
						<TextCP text={props.auditData.exam.description} />
					</TextWrapperSCP>
				</ExamInfoSCP>
			}
		>
			<ButtonCP type={'primary'} icon={'audit'} onClick={save} loading={saveRequest.isAwaiting}>
				Finalizar Auditoria
			</ButtonCP>
		</HeaderCP>
	)
}

const ExamInfoSCP = styled.div`
	display: flex;

	@media (max-width: 992px) {
		display: none;
	}
`

const TextWrapperSCP = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 5px;
`
