import React, { useEffect, useState } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { TabsCP } from 'submodules/nerit-framework-ui/common/components/tabs/TabsCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalReportPrintResponseDTO } from 'submodules/beerads-sdk/services/exams/medical-reports/dtos/response/MedicalReportPrintResponseDTO'
import { PdfMozillaViewer } from 'submodules/nerit-framework-ui/common/components/file-preview/pdf-mozilla-viewer/PdfMozillaViewer'
import { MedicalReportRequests } from 'submodules/beerads-sdk/services/exams/medical-reports/MedicalReportRequests'
import { PatientPortalRequests } from 'submodules/beerads-sdk/services/patient-portal/PatientPortalRequests'

interface ICPProps {
	visible: boolean
	onCancel: () => void
	examId?: string
	downloadBy: 'patient' | 'user'
}

/**
 */
export function ModalMedicalReportPdfPrinterCP(props: ICPProps): JSX.Element {
	const [loadedMedicalReportUrls, setLoadedMedicalReportUrls] = useState<MedicalReportPrintResponseDTO>()
	const getMedicalReportPrintDataRequest = useRequest<MedicalReportPrintResponseDTO>()
	useEffect(onGetMedicalReportPrintDataRequestChange, [getMedicalReportPrintDataRequest.isAwaiting])

	useEffect(init, [props.visible])

	/**
	 * Inicializa a tela
	 */
	function init(): void {
		if (!props.visible) return

		if (!props.examId) {
			NotificationHelper.error('Ops', 'ID do exame não informado')
			return
		}

		if (props.downloadBy === 'user') getMedicalReportPrintDataRequest.runRequest(MedicalReportRequests.print(props.examId))
		else if (props.downloadBy === 'patient') getMedicalReportPrintDataRequest.runRequest(PatientPortalRequests.printMedicalReport(props.examId))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetMedicalReportPrintDataRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalReportPrintDataRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getMedicalReportPrintDataRequest.responseData!
		setLoadedMedicalReportUrls(result)
	}

	return (
		<ModalCP
			visible={props.visible}
			onCancel={props.onCancel}
			width={900}
			noFooter={true}
			title={'Imprimir Laudo(s)'}
			padding={loadedMedicalReportUrls?.pdfUrls?.length === 1 ? 0 : undefined}
		>
			{loadedMedicalReportUrls?.pdfUrls?.length === 1 ? (
				<PdfMozillaViewer pdfSrc={loadedMedicalReportUrls.pdfUrls[0]} height={'600px'} />
			) : (
				<TabsCP
					tabs={
						loadedMedicalReportUrls?.pdfUrls.map((medicalReportUrl, index) => ({
							key: `report-${index + 1}`,
							title: `Laudo ${index + 1}`,
							content: <PdfMozillaViewer pdfSrc={medicalReportUrl} height={'600px'} />,
						})) ?? []
					}
				/>
			)}
		</ModalCP>
	)
}
