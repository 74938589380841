import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { SiderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/sider/SiderCP'
import { PatientDataCP } from 'modules/exams/components/exams/patient-data/PatientDataCP'
import { ExamDataCP } from 'modules/exams/components/exams/exam-data/ExamDataCP'
import { ExamNotesCP } from 'modules/exams/components/exams/exam-notes/ExamNotesCP'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ResponsiveCP } from 'submodules/nerit-framework-ui/common/components/responsive/ResponsiveCP'
import { RoutingHelper } from 'config/RoutingHelper'
import { UploadExamAttachmentsCP } from 'modules/exams/components/exams/upload-exam-attachments/UploadExamAttachmentsCP'
import { ExamUploadFileTypeEnum } from 'modules/exams/screens/screen-exam-upload-files/inner/ExamUploadFileTypeEnum'
import { CardAnalysisHistICP } from 'modules/exams/components/medical-report/card-analysis-history-medical-report/CardAnalysisHistICP'

interface ICPProps {
	exam: ExamResponseDTO
	pendingAnalysisHistories?: ExamHistoryResponseDTO[]
}

/**
 */
export function SiderLeftMedicalReportCP(props: ICPProps): JSX.Element | null {
	/**
	 * Ao duplicar exames, abre novas abas de laudo para o medico atual
	 */
	function onDuplicateExams(createdExamIds: string[]): void {
		createdExamIds.forEach((examId) => {
			RoutingHelper.goToMedicalReport(examId, true)
		})
	}

	return (
		<ResponsiveCP minWidth={1280}>
			<SiderCP width={props.exam.modality === ExamModalityEnum.CR_OIT ? '400px' : '100%'} bgColor={'transparent'}>
				<div>
					{!ArrayUtils.isEmpty(props.pendingAnalysisHistories) && (
						<CardAnalysisHistICP pendingAnalysisHistories={props.pendingAnalysisHistories!} />
					)}

					<CardCP title={props.exam.patient.name}>
						<PatientDataCP exam={props.exam} margin={{ bottom: 30 }} />

						<ExamDataCP
							exam={props.exam}
							duplicateExamOption={{
								onDuplicateExam: onDuplicateExams,
							}}
						/>
					</CardCP>

					<CardCP title={'Anexos'}>
						<UploadExamAttachmentsCP
							examId={props.exam.id}
							type={ExamUploadFileTypeEnum.ATTACHMENT}
							permissions={{
								canUpload: false,
								canShowFileList: true,
								canRemove: false,
							}}
						/>
					</CardCP>

					<CardCP title={'Notas'}>
						<ExamNotesCP examCode={props.exam.code} />
					</CardCP>
				</div>
			</SiderCP>
		</ResponsiveCP>
	)
}
