import React from 'react'

interface ICPProps {
	url: string
}

/**
 */
export function VideoCP(props: ICPProps): JSX.Element {
	return <video src={props.url} controls={true} autoPlay={true} loop={false} width={'100%'} />
}
