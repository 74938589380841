import React from 'react'
import { ExamAssignResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamsAssignResponseDTO'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ListCP } from 'submodules/nerit-framework-ui/common/components/list/ListCP'
import { ListItemICP } from 'submodules/nerit-framework-ui/common/components/list/inner/ListItemICP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'

interface ICPProps {
	isVisible?: boolean
	examAssignFailed?: ExamAssignResponseDTO
	onClose: () => void
}

export function ModalExamAssignFailedCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP
			visible={props.isVisible}
			noFooter={true}
			onClose={props.onClose}
			title={
				!!props.examAssignFailed?.failedAttribuitions && props.examAssignFailed?.failedAttribuitions.length > 1
					? 'Não foi possível atribuir os exames das clínicas'
					: 'Não foi possível atribuir os exames da clínica'
			}
		>
			<FlexCP flexDirection="column" gap="5px" alignItems="baseline">
				<TextAndTitleCP title="Número de exames atribuídos com sucesso:" text={`${props.examAssignFailed?.countSuccessAttribuitions}`} />
				<TextAndTitleCP title="Unidades que apresentaram falhas:" />

				<ul>
					{props.examAssignFailed?.failedAttribuitions
						?.filter((item, index, self) => index === self.findIndex((t) => t.code === item.code))
						.map((item, index) => (
							<li key={index}>{item.name}</li>
						))}
				</ul>
			</FlexCP>

			<ListCP style={{ maxHeight: 400, overflow: 'auto', marginTop: 15 }}>
				{props.examAssignFailed?.failedAttribuitions.map((item, index) => (
					<ListItemICP key={index} title={item.name} content={item.string} />
				))}
			</ListCP>
		</ModalCP>
	)
}
