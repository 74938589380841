import * as React from 'react'
import styled from 'styled-components'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'

interface ILinkHtmlCPProps {
	type: ButtonTypeTP
	href: string
	children: JSX.Element | string | JSX.Element[]
	size?: 'large' | 'default' | 'small'
}

/**
 * COMPONENTE: Link.
 */
export function LinkHtmlCP(props: ILinkHtmlCPProps): JSX.Element {
	return (
		<WrapperSCP>
			<a
				className={`ant-btn ant-btn-${props.type as string} ant-btn-${props.size === 'large' ? 'lg' : props.size === 'small' ? 'sm' : ''}`}
				href={props.href}
				target={'_blank'}
				rel={'noopener noreferrer'}
			>
				{props.children}
			</a>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div``
