import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ExamsSummaryResponseDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/response/ExamsSummaryResponseDTO'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { ExamsSummaryRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/ExamsSummaryRequestDTO'
import { BasicStyleWrapperCP } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { CardWidgetOnlyValuesCP } from 'submodules/nerit-framework-ui/common/components/dashboard/card-widget-only-values/CardWidgetOnlyValuesCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'

interface ICPProps {
	reportType: ReportTypeEnum
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
	reload: number
}

/**
 */
export function CardsExamsSummaryCP(props: ICPProps): JSX.Element {
	const [summaryData, setSummaryData] = useState<ExamsSummaryResponseDTO>()
	const getReportDatarequest = useRequest<ExamsSummaryResponseDTO>()
	useEffect(onGetReportDataRequestChange, [getReportDatarequest.isAwaiting])

	useEffect(init, [props.reload])

	/**
	 */
	function init(): void {
		const formValues = props.filterStateManager.getFormValues()

		const dto: ExamsSummaryRequestDTO = {
			reportType: props.reportType,
			dateRange: formValues?.dateRange,
			clinicCodes: formValues?.clinicCodes,
			considerArchived: formValues?.considerArchived,
			doctorCode: props.reportType === ReportTypeEnum.DOCTOR_REPORT ? AppStateUtils.getLoggedUser()?.code : formValues?.doctorCode,
			doctorGroupCode: formValues?.doctorGroupCode,
			shouldConsiderMedicalReportDate: formValues?.shouldConsiderMedicalReportDate ?? false,
		}
		getReportDatarequest.runRequest(DashboardRequests.examsSummary(dto))
	}

	/**
	 */
	function onGetReportDataRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getReportDatarequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setSummaryData(getReportDatarequest.responseData)
	}

	return (
		<>
			<BasicStyleWrapperCP margin={{ left: 10, right: 10 }}>
				<RowCP>
					<ColumnCP size={6}>
						<CardWidgetOnlyValuesCP
							initialValue={{ value: summaryData?.totalExams ?? 0 }}
							main={{ title: 'Total de exames' }}
							loading={getReportDatarequest.isAwaiting}
						/>
					</ColumnCP>
					<ColumnCP size={6}>
						<CardWidgetOnlyValuesCP
							initialValue={{ value: summaryData?.totalExamsWithoutReport ?? 0 }}
							main={{ title: 'Exames sem laudo' }}
							loading={getReportDatarequest.isAwaiting}
						/>
					</ColumnCP>
					<ColumnCP size={6}>
						<CardWidgetOnlyValuesCP
							initialValue={{ value: summaryData?.totalExamsWithReport ?? 0 }}
							main={{ title: 'Exames com laudo' }}
							loading={getReportDatarequest.isAwaiting}
						/>
					</ColumnCP>
					<ColumnCP size={6}>
						<CardWidgetOnlyValuesCP
							initialValue={{ value: summaryData?.totalReports ?? 0 }}
							main={{ title: 'Total de laudos' }}
							loading={getReportDatarequest.isAwaiting}
						/>
					</ColumnCP>
				</RowCP>
			</BasicStyleWrapperCP>
		</>
	)
}
