import React from 'react'
import { Divider } from 'antd'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps extends BasicStyleWrapperCPProps {
	text?: string
	placement?: 'left' | 'right' | 'center'
}

/**
 * Divider
 */
export function DividerCP(props: ICPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<Divider orientation={props.placement} style={{ color: ThemeFrameworkCommon.browserDefaultColorDark }}>
				{props.text}
			</Divider>
		</BasicStyleWrapperCP>
	)
}
