import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	formStateManager: IFormStateManager<any>
	fieldName: string
	labelAbove?: boolean
	label?: string
}

/**
 */
export function SwitchExamCriticalCP(props: ICPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<SwitchCP
				labelAbove={props.labelAbove}
				label={props.label ?? 'Achado Crítico?'}
				formStateManager={props.formStateManager}
				fieldName={props.fieldName}
			/>
		</BasicStyleWrapperCP>
	)
}
