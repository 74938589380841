/**
 * UTILITARIOS para lidar com Mime-Types de arquivos.
 */
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'

export const MimeTypeUtils = {
	getExtensonByMimeType(mimeType?: MimeTypeEnum): string {
		switch (mimeType) {
			case MimeTypeEnum.PDF:
				return '.pdf'

			case MimeTypeEnum.ZIP:
				return '.zip'

			default:
				return ''
		}
	},

	isCsvMimeType(mimeType: string): boolean {
		return MimeTypeUtils.getCsvMimeTypes().includes(mimeType as MimeTypeEnum)
	},

	getCsvMimeTypes(): MimeTypeEnum[] {
		return [
			MimeTypeEnum.CSV_STANDARD,
			MimeTypeEnum.CSV_ALTERNATIVE_1,
			MimeTypeEnum.CSV_ALTERNATIVE_2,
			MimeTypeEnum.CSV_ALTERNATIVE_3,
			MimeTypeEnum.CSV_ALTERNATIVE_4,
			MimeTypeEnum.CSV_MICROSOFT,
		]
	},
}
