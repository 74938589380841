export enum CountExamsWidgetEnum {
	PENDING = 'PENDING',
	RETURNED = 'RETURNED',

	PENDING_ADMINISTRATIVE_ANALYSIS = 'PENDING_ADMINISTRATIVE_ANALYSIS',
	PENDING_RECTIFICATION_ANALYSIS = 'PENDING_RECTIFICATION_ANALYSIS',

	AWAITING_RECTIFICATION = 'AWAITING_RECTIFICATION',
	AWAITING = 'AWAITING',

	ASSIGNED = 'ASSIGNED',

	MEDICAL_REPORT_DONE = 'MEDICAL_REPORT_DONE',
	IS_CRITICAL = 'IS_CRITICAL',
	RECTIFICATION_DONE = 'RECTIFICATION_DONE',

	BY_SLA_DELAYED = 'BY_SLA_DELAYED',
	ASSIGNED_BY_SLA_2H = 'ASSIGNED_BY_SLA_2H',
	ASSIGNED_BY_SLA_6H = 'ASSIGNED_BY_SLA_6H',
	ASSIGNED_BY_SLA_24H = 'ASSIGNED_BY_SLA_24H',
}
