import React from 'react'
import { CustomIconContainerICP } from '_old/main/common/components/icons/inner/CustomIconContainerICP'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * ICONE
 * Parar / Stop.
 *
 * @author hjcostabr
 */
export const StopIconCP = (props: IIconProps): JSX.Element => {
	const DEFAULT_COLOR = `${ThemeFrameworkCommon.browserDefaultColorLight}`

	return <CustomIconContainerICP {...props}>{getSvg(props.color ?? DEFAULT_COLOR)}</CustomIconContainerICP>
}

const getSvg = (color: string): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="30" height="30">
			<path
				d="M35 0H1a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z"
				data-original="#000000"
				className="active-path"
				data-old_color="#ffffff"
				fill={color}
			/>
		</svg>
	)
}
