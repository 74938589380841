import createCompletionPlugin from 'draft-js-autocomplete-plugin-creator'
import { AutocompletePluginUtils } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/inner/AutocompletePluginUtils'
import { OptionEntryICP } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/inner/OptionEntryICP'

/**
 * Cria o plugin de autocomplete
 */
export const CreateAutocompletePlugin = () => {
	const theme = {
		autocompleteSuggestions: 'autocompleteSuggestions',
	}

	const completionPlugin = createCompletionPlugin(
		AutocompletePluginUtils.getStrategy(),
		AutocompletePluginUtils.getAutocompleteReplacer,
		OptionEntryICP,
		'autocompleteSuggestions',
	)

	return completionPlugin({ theme })
}
