import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { TextAndTitleCP } from 'submodules/nerit-framework-ui/common/components/text/TextAndTitleCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { AlertCP } from 'submodules/nerit-framework-ui/common/components/alert/AlertCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamUrgencyTypeLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamUrgencyTypeEnum'
import styled from 'styled-components'

interface ICPProps {
	exam: ExamListItemResponseDTO
	children: JSX.Element
	considerDivOnElement?: boolean // Tem vez que nao aparece o popover
}
/**
 * Popover com track das datas do exame.
 */
export function PopoverExamDateLogCP(props: ICPProps): JSX.Element {
	let slaText: string | undefined
	let originalSlaText: string | undefined

	// Se tiver SLA calcula
	if (!!props.exam.clinic.sla) {
		const explodedTime = DateUtils.convertMinutes(props.exam.clinic.sla.currentSlaFromNow)

		// Se estiver em menos de 48 horas mostra no formato hora:minuto
		let time: string
		if (Math.abs(explodedTime.hour) <= 48)
			time = `${MaskUtils.applyNumberMask(Math.abs(explodedTime.hour), 0)} horas ${MaskUtils.applyNumberMask(explodedTime.minute, 0)} minutos`
		else time = `${Math.abs(explodedTime.day)} dias`

		// Verifica se tá atrasado ou dentro do SLA
		if (props.exam.clinic.sla.currentSlaFromNow <= 0) slaText = `Exame atrasado em ${time}`
		else slaText = `Exame dentro do SLA, faltam ${time} para o prazo`

		// Agora define o texto do sla original configurado
		// Se for mais de uma hora, mostra em horas. Caso contrario em minutos
		if (props.exam.clinic.sla.hoursOriginalByUrgency > 60) originalSlaText = `${props.exam.clinic.sla.hoursOriginalByUrgency / 60} horas`
		else originalSlaText = `${props.exam.clinic.sla.hoursOriginalByUrgency} minutos`

		originalSlaText = `${originalSlaText} (${ExamUrgencyTypeLabelEnum[props.exam.urgentType]})`
	}

	return (
		<PopOverCP
			trigger={'hover'}
			content={
				<PopoverContentSCP>
					<AlertCP
						show={!!slaText}
						message={<TextCP size={'large'} text={slaText} />}
						type={!!props.exam.clinic.sla && props.exam.clinic.sla.currentSlaFromNow <= 0 ? 'error' : 'warning'}
						margin={{ bottom: 20 }}
					/>
					<p>
						<TextAndTitleCP
							title={'Data de realização do exame:'}
							text={DateUtils.getFormatted(props.exam.examDate, DateFormatEnum.BR_WITH_TIME_H_M)}
						/>
					</p>
					<p>
						<TextAndTitleCP
							title={'Data de entrada no sistema:'}
							text={DateUtils.formatDate(props.exam.creationDate, DateFormatEnum.BR_WITH_TIME_H_M)}
						/>
					</p>
					{!!props.exam.pedingEndDate && (
						<p>
							<TextAndTitleCP
								title={'Data de categorização da unidade hospitalar:'}
								text={DateUtils.formatDate(props.exam.pedingEndDate, DateFormatEnum.BR_WITH_TIME_H_M)}
							/>
						</p>
					)}
					{!!props.exam.medicalReportDate && (
						<p>
							<TextAndTitleCP
								title={'Data do Laudo:'}
								text={DateUtils.formatDate(props.exam.medicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M)}
							/>
						</p>
					)}
					{!!props.exam.clinic.sla && !!originalSlaText && (
						<p>
							<TextAndTitleCP title={'Prazo acordado:'} text={originalSlaText} />
						</p>
					)}
					{!!props.exam.clinic.sla && (
						<p>
							<TextAndTitleCP
								title={'Data acordada de entrega do Laudo:'}
								text={
									props.exam.clinic.sla?.slaMedicalReportDate
										? DateUtils.formatDate(props.exam.clinic.sla.slaMedicalReportDate, DateFormatEnum.BR_WITH_TIME_H_M)
										: undefined
								}
							/>
						</p>
					)}
				</PopoverContentSCP>
			}
		>
			{props.considerDivOnElement ? <div>{props.children}</div> : props.children}
		</PopOverCP>
	)
}

const PopoverContentSCP = styled.div`
	display: flex;
	flex-direction: column;
`
