import React, { useRef, useState } from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import styled from 'styled-components'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

export interface ICPProps {
	onPaste: (file: File) => void
}

/**
 */
export function CaptureFileFromClipboard(props: ICPProps): JSX.Element {
	const [showLabel, setShowLabel] = useState<boolean>(true)
	const inputRef = useRef(null)

	/**
	 */
	const handlePaste = (event: any) => {
		if (event.clipboardData && event.clipboardData.items) {
			const items = event.clipboardData.items
			for (let i = 0; i < items.length; i++) {
				const item = items[i]

				const type: string = item.type
				if (!type.includes('image') && !type.includes('pdf')) {
					NotificationHelper.error('Ops', 'Item colado não é uma imagem OU pdf')
					return
				}

				props.onPaste(item.getAsFile())
			}
		}
	}

	return (
		<AreaWrapperSCP>
			<div contentEditable={true} ref={inputRef} onPaste={handlePaste} onClick={() => setShowLabel(false)} onBlur={() => setShowLabel(true)}>
				{showLabel && (
					<TextCP icon={'snippets'} text={'Clique aqui e aperte CTRL + V para colar a imagem da área de transferência (printscreen)'} />
				)}
			</div>
		</AreaWrapperSCP>
	)
}

const AreaWrapperSCP = styled.div`
	border: #dedede dashed 3px;
	border-radius: 5px;
	padding: 5px;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
	display: flex;
	justify-content: center;
	margin-bottom: 10px;

	img {
		height: 25px;
	}
`
