import IMask from 'imask'
import { InputMaskTypeEnum } from '_old/main/common/enums/InputMaskTypeEnum'

/**
 * Classe para auxiliar a inserção de máscaras em campos de formulário
 * @author Stella
 * @author Lucas Rosa
 * @author Victor
 */
export class MaskUtils {
	static applyMask(value: string | number, maskType: InputMaskTypeEnum): string | number {
		if (!value) return value

		const valueToMask = value.toString()

		let mask
		switch (maskType) {
			case InputMaskTypeEnum.DATE:
				mask = '00/00/0000'
				break
			case InputMaskTypeEnum.CPF:
				mask = '000.000.000-00'
				break
			case InputMaskTypeEnum.PHONE:
				const phoneWithoutMask = valueToMask.length && valueToMask.replace(/\D/g, '')
				mask = phoneWithoutMask[2] === '9' ? '(00) 00000-0000' : '(00) 0000-0000'
				break
			case InputMaskTypeEnum.CEP:
				mask = '00000-000'
				break
			case InputMaskTypeEnum.CNPJ:
				mask = '00.000.000/0000-00'
				break
			case InputMaskTypeEnum.TIME:
				mask = '00:00'
				break
			case InputMaskTypeEnum.ADDRESS_NUMBER:
				mask = '0000000'
				break
			default:
				return value
		}

		return this.maskField(valueToMask, mask)
	}

	private static maskField(value: string, mask: string): string {
		const masked = IMask.createMask({ mask })
		if (value) masked.resolve(value)

		return masked.value
	}
}
