import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'
import { IsOptional } from 'class-validator'
import { DateRangeSearchRequestDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/request/DateRangeSearchRequestDTO'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { RectificationReasonsEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/RectificationReasonsEnum'

export class CommonReportsFilterFormModel extends FormModel {
	@IsOptional()
	doctorCode?: number

	@IsOptional()
	considerArchived?: number

	@IsOptional()
	clinicCodes?: number[]

	@IsOptional()
	doctorGroupCode?: number

	@IsRequired()
	dateRange: DateRangeSearchRequestDTO

	@IsOptional()
	shouldConsiderMedicalReportDate?: boolean

	@IsOptional()
	rectificationReasons?: RectificationReasonsEnum[]

	constructor(initialData?: ObjectPropsTP<CommonReportsFilterFormModel>) {
		super(initialData)
		Object.assign(this, initialData)
	}
}
