import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ExamModalityEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import { MedicalTimetableGroupByTP } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableGroupByTP'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { ModalMedicalTimetableAssignCP } from 'modules/medical-timetable/components/modals/modal-medical-timetable-assign/ModalMedicalTimetableAssignCP'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'

interface ICPProps {
	doctorGroupCode: number
	period: MedicalTimetablePeriodEnum
	modality: ExamModalityEnum
	groupBy: MedicalTimetableGroupByTP
	dayOfWeek: WeekDaysEnum
	id: MedicalTimetableIdEnum
	date: Date
	onSave: () => void
}

/**
 */
export function ButtonMedicalTimetableAssignCP(props: ICPProps): JSX.Element {
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
	return (
		<>
			<ButtonCP icon={'warning'} size={'small'} type={'primary'} onClick={() => setIsModalVisible(true)}>
				Profissional
			</ButtonCP>

			<ModalMedicalTimetableAssignCP
				dayOfWeek={props.dayOfWeek}
				doctorGroupCode={props.doctorGroupCode}
				period={props.period}
				modality={props.modality}
				groupBy={props.groupBy}
				id={props.id}
				date={props.date}
				onCancel={() => setIsModalVisible(false)}
				onSave={() => {
					setIsModalVisible(false)
					props.onSave()
				}}
				visible={isModalVisible}
			/>
		</>
	)
}
