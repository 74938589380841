import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ProjectWhiteLabelsEnum } from 'submodules/beerads-sdk/common/ProjectWhiteLabelsEnum'
import { AppConfig } from 'config/AppConfig'
import { ExamImageMetadataTP } from 'submodules/beerads-sdk/services/exams/exams/types/ExamImageMetadataTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { EXAM_SCREEN_FILTERS_PARAM } from 'modules/exams/screens/screen-exams/ScreenExams'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { MEDICAL_REPORT_COUNTER_PARAM } from 'app/components/report-counter-indicator/MedicalReportCounterCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { ExamsDownloadsRequests } from 'submodules/beerads-sdk/services/exams/exams-downloads/ExamsDownloadsRequests'
import { VxViewerParamTP } from 'submodules/beerads-sdk/services/exams/exams/types/VxViewerParamTP'
import { CryptoUtils } from 'submodules/nerit-framework-utils/utils/CryptoUtils'
import { UploadMedicalReportVideoResponseDTO } from 'submodules/beerads-sdk/services/exams/attachments/dtos/response/UploadMedicalReportVideoResponseDTO'
import { PublicRouter } from 'app/routers/public/PublicRouter'

/**
 */
export class RoutingHelper {
	/**
	 */
	static goToExams(examStatusGroup?: ExamStatusGroupEnum, dto?: ExamSearchRequestDTO, updateMedicalReportsCounter?: boolean): void {
		let path = `${PrivateUserRouter.EXAMS.replace(':examStatusGroup?', examStatusGroup ?? '')}`
		let queryParams = ''

		if (!!dto) queryParams = `${queryParams}${EXAM_SCREEN_FILTERS_PARAM}=${JSON.stringify(dto)}&`

		if (!!updateMedicalReportsCounter) queryParams = `${queryParams}${MEDICAL_REPORT_COUNTER_PARAM}=${TableUtils.getReloadNumber()}&`

		path = `${path}?${queryParams}`
		return NeritFrameworkRoutingHelper.historyPush(path)
	}

	/**
	 */
	static goToMedicalReport(
		examId: string,
		openNewTab: boolean,
		forceReplaceUrl?: boolean,
		searchExamDto?: ExamSearchRequestDTO,
		updateMedicalReportsCounter?: boolean,
	): void {
		let path = PrivateUserRouter.MEDICAL_REPORT.replace(':examId', examId)
		let queryParams = ''

		if (!!searchExamDto) queryParams = `${EXAM_SCREEN_FILTERS_PARAM}=${JSON.stringify(searchExamDto)}&`

		if (!!updateMedicalReportsCounter) queryParams = `${queryParams}${MEDICAL_REPORT_COUNTER_PARAM}=${TableUtils.getReloadNumber()}&`

		path = `${path}?${queryParams}`

		if (openNewTab) {
			NeritFrameworkRoutingHelper.openInNewTab(path, true)
			return
		}

		if (forceReplaceUrl) NeritFrameworkRoutingHelper.historyReplace(path, true)
		else NeritFrameworkRoutingHelper.historyPush(path)
	}

	/**
	 */
	static isValidSchema(schema?: string): boolean {
		if (!schema) return false
		return Object.values(ProjectWhiteLabelsEnum).includes(schema as ProjectWhiteLabelsEnum)
	}

	/**
	 */
	static getMedicalReportVideoUrl(medicalReportVideo: UploadMedicalReportVideoResponseDTO): string {
		const encryptedParams = CryptoUtils.base64UrlEncode(JSON.stringify(medicalReportVideo))
		return `${AppConfig.getInstance().uiBaseUrl}/${AppStateUtils.getDomain()}/${PublicRouter.MEDICAL_REPORT_VIDEO.replace(':token', encryptedParams)}`
	}

	/**
	 */
	static getBeeRadsViewerUrl(viewerData: VxViewerParamTP, metadata?: ExamImageMetadataTP): string | undefined {
		if (!metadata) {
			NotificationHelper.error('Ops', 'Imagem não preparada para viewer')
			return
		}

		const encryptedParams = CryptoUtils.base64UrlEncode(JSON.stringify(viewerData))
		return `${AppConfig.getInstance().beeradsViewerBaseUrl}/dicomjson?url=${metadata.url}&p=${encryptedParams}`
	}

	/**
	 */
	static getOsirixViewerUrl(examIds: string[]): string {
		const token = AppStateUtils.getAuthData()?.token

		if (token === undefined) {
			throw new Error('Token não disponível.')
		}

		// API que sera chamada no viewer para baixar as imagens
		const beeradsApiUrl = NeritFrameworkProjectConfig.formatApiBaseUrl(
			ExamsDownloadsRequests.getExamImagesBuffer({
				examIds,
				token,
			}),
			true,
		)

		// Precisa colocar os parametros na url
		const examsQueryParams = examIds.reduce((accumulator, examCode) => `${accumulator}examIds[]=${examCode}&`, '').slice(0, -1) + `&token=${token}`

		return `osirix://?methodName=downloadURL&URL='${beeradsApiUrl}?${examsQueryParams}'`
	}

	/**
	 */
	static getWeasisViewerUrl(examId: string): string {
		const token = AppStateUtils.getAuthData()?.token

		if (token === undefined) {
			throw new Error('Token não disponível.')
		}

		// API que sera chamada no viewer para baixar as imagens
		const beeradsApiUrl = NeritFrameworkProjectConfig.formatApiBaseUrl(
			ExamsDownloadsRequests.getExamImagesBuffer({
				examIds: [examId],
				token,
			}),
			true,
		)

		return `weasis://%24dicom%3Aget%20-z%20%27${beeradsApiUrl}?examIds[]=${examId}&token=${token}%27`
	}

	/**
	 * Para o radiant, irá invocar o programa procurando por um arquivo. O DOWNLOAD do exame ja deve ter sido CONCLUIDO nesse momento
	 */
	static getRadiantViewerUrl(fileName: string): string | undefined {
		const userDownloadFolder = AppStateUtils.getLoggedUser()?.preferences?.userDownloadFolderPath
		if (!userDownloadFolder) {
			NotificationHelper.error('Ops!', 'Define em preferências do usuário o caminho de download para integrar ao RADIANT')
			return
		}

		const radiantPath = `radiant://?n=f&v="${userDownloadFolder}\\${fileName}"`
		console.info(`Chamando RADIANT na url: ${radiantPath}`)

		return radiantPath
	}
}
