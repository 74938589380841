import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ExamDataSaveRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamDataSaveRequestDTO'
import { ExamDataFormModel } from 'modules/exams/components/exams/modal-exam-data/inner/form-model/ExamDataFormModel'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'

export class ExamDataFormModelConverter {
	/**
	 * Retorna o DTO de save
	 */
	static getDto(examCode: number, formModel: ExamDataFormModel): ExamDataSaveRequestDTO {
		return {
			examCode: examCode,
			patient: {
				id: formModel.patientId,
				birthday: DateUtils.toDate(formModel.patientBirthday, DateFormatEnum.BR_WITHOUT_TIME),
				gender: formModel.patientGender,
				name: formModel.patientName,
			},
			contrasts: formModel.contrasts,
			serviceOrder: formModel.serviceOrder,
			requestingDoctor: formModel.requestingDoctor,
			extraInfo: formModel.extraInfo,
			anamnesis: formModel.anamnesis,
			examDescription: formModel.currentExam,
		}
	}

	/**
	 * Retorna o MODEL a partir do ResponseDTO
	 */
	static getFormModel(dto: ExamResponseDTO): ExamDataFormModel {
		return new ExamDataFormModel({
			patientId: dto.patient.id,
			patientName: dto.patient.name,
			patientBirthday: DateUtils.formatDate(dto.patient.birthday, DateFormatEnum.BR_WITHOUT_TIME),
			patientGender: dto.patient.gender,
			contrasts: dto.contrasts,
			serviceOrder: dto.extraData?.serviceOrder,
			requestingDoctor: dto.extraData?.requestingDoctor,
			extraInfo: dto.extraData?.extraInfo,
			anamnesis: dto.extraData?.anamnesis,
			currentExam: {
				code: undefined,
				modality: dto.modality,
				description: dto.description,
			},
		})
	}
}
