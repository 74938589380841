import React, { useEffect, useState } from 'react'
import { FileDataTP } from 'submodules/nerit-framework-utils/utils/types/FileDataTP'
import { ImageTP, ViewerImageCP } from 'submodules/nerit-framework-ui/common/components/file-preview/viewer-image/ViewerImageCP'

interface IViewAttachmentGalleryCPProps {
	attachments: FileDataTP[]
	onClose: () => void
}

/**
 * Visualizador de anexos de 01 exame.
 */
export function AttachmentViewerCP(props: IViewAttachmentGalleryCPProps): JSX.Element {
	const [viewerImages, setViewerImages] = useState<ImageTP[]>()
	useEffect(setImagesDataForViewer, [props.attachments])

	/**
	 */
	function setImagesDataForViewer(): void {
		const formmated: ImageTP[] = props.attachments.map((att, index) => ({
			src: att.fileUrl,
			type: att.fileName.split('.').slice(-1)[0],
			key: index,
		}))

		setViewerImages(formmated)
	}

	/**
	 */
	function onDownload(index: number): void {
		const attachment = props.attachments[index]
		if (!!attachment) window.open(attachment.fileUrl, '_blank')
	}

	return <ViewerImageCP images={viewerImages ?? []} visible={!!props.attachments[0]} onClose={props.onClose} onDownload={onDownload} />
}
