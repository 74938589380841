import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerExamDetailsCP } from 'modules/exams/components/exams/drawer-exam-details/DrawerExamDetailsCP'
import { ButtonTypeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonTypeTP'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface ICPProps extends BasicStyleWrapperCPProps {
	examId: string
	onSave?: () => void
	type?: ButtonTypeTP
	showText?: boolean
}

/**
 * Botao para triar exame.
 */
export function ButtonExamDetailsCP(props: ICPProps): JSX.Element {
	const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<ButtonCP type={props.type} size={'small'} onClick={() => setIsDrawerVisible(true)} icon={'eye'}>
				{props.showText ? 'Detalhes do Exame' : undefined}
			</ButtonCP>

			<DrawerExamDetailsCP
				examId={props.examId}
				visible={isDrawerVisible}
				onClose={(dataChanged) => {
					setIsDrawerVisible(false)
					if (dataChanged && !!props.onSave) props.onSave()
				}}
			/>
		</BasicStyleWrapperCP>
	)
}
