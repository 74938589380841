import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import { DateUtils } from '_old/main/common/utils/DateUtils'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'

/**
 * Reune metodos genericos uteis para manipular dados da entidade EXAME.
 */
export class ExamUtils {
	/** Constroi a mensagem a ser exibida na timeline de histórico do exame. */
	static getExamHistoryMessage(hist: ExamHistoryResponseDTO): string {
		if (!!hist.comments) return `${DateUtils.formatDate(hist.date, DateTimeFormatEnum.BR_WITH_TIME_H_M)}: ${hist.comments}`

		const messages = {
			CREATED_EXAM: 'Exame subiu para o sistema',
			EXECUTED_EXAM: 'Exame realizado',
			ALLOCATED_DOCTOR: 'Exame alocado',
			TOOK_EXAM: `${hist.user.name} pegou exame para laudar`,
			STARTED_REPORT: `${hist.user.name} criou novo laudo`,
			FINISHED_REPORT: `Exame liberado por ${hist.user.name}`,
			VIEWED_REPORT: `Laudos do exame baixados por ${hist.user.name}`,
			DOWNLOADED_EXAM: `Imagens do exame baixadas por ${hist.user.name}`,
			RETURNED_EXAM: 'Exame foi devolvido',
			ADDED_NOTE: `Nova nota adicionada por ${hist.user.name}`,
			REMOVED_NOTE: `Nota removida por ${hist.user.name}`,
			ADDED_ATTACHMENT: `Novo anexo adicionado por ${hist.user.name}`,
			REMOVED_ATTACHMENT: `Anexo removido por ${hist.user.name}`,
			EDITED_EXAM: `Exame editado por ${hist.user.name}`,
			REPORT_SENT_BY_WHATSAPP: `Laudo enviado por whatsapp`,
		}

		return `${DateUtils.formatDate(hist.date, DateTimeFormatEnum.BR_WITH_TIME_H_M)}: ${messages[hist.event]}`
	}
}
