import { IFormItemInputCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import React, { useEffect, useState } from 'react'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MentionCP, MentionOptionTP } from 'submodules/nerit-framework-ui/common/components/form-fields/mention/MentionCP'

interface ICPProps<MentionRowTP> extends IFormItemInputCommonProps {
	prefix: string
	rows?: number
	requestConfigTP: () => RequestConfigTP
	renderMentionOption: (row: MentionRowTP) => MentionOptionTP
}

/**
 */
export function MentionFromApiCP<MentionRowTP>(props: ICPProps<MentionRowTP>): JSX.Element {
	const [mentionOptions, setMentionOptions] = useState<MentionOptionTP[]>([])
	const getListRequest = useRequest<ListResponseDTO<any>>()
	useEffect(onGetListRequestChange, [getListRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Carrega.
	 */
	function init(): void {
		getListRequest.runRequest(props.requestConfigTP())
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetListRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getListRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		const result = getListRequest.responseData!
		setMentionOptions(result.list.map(props.renderMentionOption))
	}

	return <MentionCP loading={getListRequest.isAwaiting} options={mentionOptions} {...props} />
}
