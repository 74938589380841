import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamContrastEnum, ExamContrastLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamContrastEnum'

interface ICPProps {
	formStateManager: IFormStateManager<any>
	fieldName: string
	disabled?: boolean
}

/**
 * Drawer para triar um exam.
 */
export function SelectContrastCP(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
			label={'Administrou Contraste?'}
			isMultiple={true}
			disabled={props.disabled}
			options={Object.values(ExamContrastEnum).map((contrast) => ({
				label: ExamContrastLabelEnum[contrast],
				value: contrast,
			}))}
		/>
	)
}
