import React, { useEffect, useState } from 'react'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { SelectClinicFromLoggedUserCP } from 'modules/clinic/components/select-clinic/SelectClinicFromLoggedUserCP'

interface ICPProps {
	clinicCode?: number
	onChangeClinic: (clinicCode: number) => void
}

/**
 * Header da tela de gerenciamento de clínica.
 */
export function ClinicsAdminScreenHeaderCP(props: ICPProps): JSX.Element {
	const [clinicCode, setClinicCode] = useState<number>()
	useEffect(() => setClinicCode(props.clinicCode), [props.clinicCode])

	return (
		<SelectClinicFromLoggedUserCP
			permissions={['isAdmin', 'isDoctorGroupAdmin']}
			value={{
				byOnChange: {
					value: clinicCode,
					onChange: (clinicCode) => {
						props.onChangeClinic(clinicCode)
						NeritFrameworkRoutingHelper.historyPush(`${window.location.pathname}?clinicCode=${clinicCode}`)
					},
				},
			}}
			width={300}
		/>
	)
}
