import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { MedicalReportsDoneRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/MedicalReportsDoneRequestDTO'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { RectificationReportRequestDTO } from 'submodules/beerads-sdk/services/dashboard/dashboard/dtos/request/RectificationReportRequestDTO'

export class CommonReportsFilterFormModelConverter {
	static formatDto(reportType: ReportTypeEnum, model?: CommonReportsFilterFormModel): MedicalReportsDoneRequestDTO {
		return {
			reportType: reportType,
			doctorCode: reportType === ReportTypeEnum.DOCTOR_REPORT ? AppStateUtils.getLoggedUser()?.code : model?.doctorCode,
			clinicCodes: model?.clinicCodes,
			dateRange: model?.dateRange!,
			doctorGroupCode: model?.doctorGroupCode ?? AppStateUtils.getCurrentDoctorGroup()?.code,
			shouldConsiderMedicalReportDate: model?.shouldConsiderMedicalReportDate ?? false,
		}
	}

	static formatRectificationDto(reportType: ReportTypeEnum, model?: CommonReportsFilterFormModel): RectificationReportRequestDTO {
		return {
			reportType: reportType,
			doctorCode: reportType === ReportTypeEnum.DOCTOR_REPORT ? AppStateUtils.getLoggedUser()?.code : model?.doctorCode,
			clinicCodes: model?.clinicCodes,
			dateRange: model?.dateRange!,
			doctorGroupCode: model?.doctorGroupCode,
			rectificationReasons: model?.rectificationReasons,
		}
	}
}
