import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { ExamModalityEnum, ExamModalityFullLabelEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	isMultiple?: boolean
	value?: any
	showFullLabel?: boolean
	onChange?: (selected: any) => void
	required?: boolean
	disabled?: boolean
	excludedModalities?: ExamModalityEnum[]
}

/**
 * Select de modalidades de exame
 */
export function SelectExamModalityCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	// Fix temporário: Sempre ocultar DX/RX_D dos filtros de modalidade.

	return (
		<SelectCP
			label={props.label ?? 'Escolha as modalidades de exame'}
			value={props.value}
			fieldName={props.fieldName}
			isMultiple={props.isMultiple}
			formStateManager={props.formStateManager}
			required={props.required}
			onChange={props.onChange}
			disabled={props.disabled}
			options={Object.values(ExamModalityEnum)
				.filter((mod) => !props.excludedModalities?.includes(mod) && mod !== ExamModalityEnum.DX)
				.map((mod) => ({
					value: mod,
					label: props.showFullLabel ? ExamModalityFullLabelEnum[mod] : ExamModalityLabelEnum[mod],
				}))}
		/>
	)
}
