import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { ScreenPrivateUser } from 'app/screens/screen-private-user/ScreenPrivateUser'
import React, { useEffect, useState } from 'react'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'
import { AuthActions } from 'app/utils/AuthActions'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { NeritFrameworkProjectConfig } from 'config/NeritFrameworkProjectConfig'
import { LoadingVxCP } from 'app/components/loading-vx/LoadingVxCP'

/**
 * Componente verificador se esta logado, pois se caiu aqui esta chamando rota privada.
 */
export function PrivateAccessVerifierCP(): JSX.Element {
	const loggedUserData = AppStateUtils.getLoggedUser()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	useEffect(updateLoggedUserData, [])

	/**
	 */
	function updateLoggedUserData(): void {
		if (!loggedUserData) return

		setIsLoading(true)
		AuthActions.refreshLoggedUserData()
			.then(() => setIsLoading(false))
			.catch(() => NeritFrameworkProjectConfig.onUnauthorized())
	}

	if (!loggedUserData) return <RedirectCP path={PublicRouter.USER_LOGIN} />

	if (isLoading) {
		return <LoadingOverlayCP show={true} customLogo={<LoadingVxCP />} />
	}

	// Se estiver logado, inclui a tela do sistema e nela tera o roteador das rotas
	return <ScreenPrivateUser />
}
