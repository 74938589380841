import { ExamModalityEnum, ExamModalityLabelEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamModalityEnum'
import React from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface IModalityOptionICPProps {
	isActive: boolean
	count: number
	onSelect: () => void
	modality?: ExamModalityEnum
}

/**
 * Componente de seleção de modalidades no gráfico.
 *
 * @author Lucas Rosa
 * @author Stella
 */
export function ModalityOptionICP(props: IModalityOptionICPProps): JSX.Element {
	return (
		<OptionSCP isActive={props.isActive} onClick={props.onSelect}>
			<span>{!!props.modality ? ExamModalityLabelEnum[props.modality] : 'Todos'}</span>
			<QtdBadgeSCP isActive={props.isActive}>{props.count}</QtdBadgeSCP>
		</OptionSCP>
	)
}

const OptionSCP = styled.div<{ isActive: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	width: inherit;
	height: 60px;
	padding-left: 20px;
	padding-right: 12px;
	cursor: pointer;
	font-weight: ${(props) => (props.isActive ? 600 : 400)};
	background-color: ${(props) => (props.isActive ? ThemeProject.primary : '')};
	color: ${(props) => (props.isActive ? ThemeFrameworkCommon.white : ThemeProject.secondary)};
	&:hover {
		background-color: ${(props) => (props.isActive ? '' : `${ThemeProject.primary}22`)};
	}
	&:after {
		content: '';
		width: ${(props) => (props.isActive ? '14px' : '0px')};
		height: 14px;
		background-color: ${(props) => (props.isActive ? ThemeProject.primary : '')};
		transform: rotate(45deg);
		position: absolute;
		right: -7px;
	}
`

const QtdBadgeSCP = styled.span<{ isActive: boolean }>`
	background: ${ThemeFrameworkCommon.white};
	font-weight: ${(props) => (props.isActive ? 600 : 400)};
	min-width: 20px;
	height: 20px;
	padding: 0 6px;
	align-items: center;
	justify-content: center;
	color: ${(props) => (props.isActive ? ThemeFrameworkCommon.black : '')};
	border-radius: 10px;
	box-shadow: 0 0 0 1px white;
`
