import React, { useEffect, useState } from 'react'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { MedicalTimetableRequests } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/MedicalTimetableRequests'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalTimetableFilterFormModel } from 'modules/medical-timetable/components/sider-medical-timetable-filters/inner/MedicalTimetableFilterFormModel'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { MedicalTimetableStructureSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSearchRequestDTO'
import { TableMedicalTimetableConfigUtils } from 'modules/medical-timetable/components/tables-medical-timetable/table-medical-timetable-config/inner/TableMedicalTimetableConfigUtils'
import { IMedicalTimeTableStructure } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/interfaces/IMedicalTimeTableStructure'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { MedicalTimetableIdEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetableIdEnum'
import { MedicalTimetablePeriodEnum } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/enums/MedicalTimetablePeriodEnum'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { RadioGroupMedicalTimetableConfigTP } from 'modules/medical-timetable/components/radio-group-medical-timetable-config-type/RadioGroupMedicalTimetableConfigTypeCP'

interface ICPProps {
	selectedConfigType: RadioGroupMedicalTimetableConfigTP
	medicalTimetableStructureEdit: IMedicalTimeTableStructure[]
	onChangeMedicalTimetableStructure: (structure: MedicalTimetableStructureResponseDTO[]) => void
	filterFormStateManager: IFormStateManager<MedicalTimetableFilterFormModel>
	onStartEditing: () => void
	reload?: number
}

/**
 */
export function TableMedicalTimetableConfigCP(props: ICPProps): JSX.Element {
	const [filteredMedicalTimetableStructure, setFilteredMedicalTimetableStructure] = useState<MedicalTimetableStructureResponseDTO[]>([])
	const [medicalTimetableStructure, setMedicalTimetableStructure] = useState<MedicalTimetableStructureResponseDTO[]>([])
	const getMedicalTimetableStructureRequest = useRequest<ListResponseDTO<MedicalTimetableStructureResponseDTO>>()
	useEffect(onGetMedicalTimetableStructureRequestChange, [getMedicalTimetableStructureRequest.isAwaiting])

	const [isMounting, setIsMounting] = useState<boolean>(false)
	useEffect(mountTableRecordsFromStructure, [
		props.filterFormStateManager.getFieldValue('id'),
		props.filterFormStateManager.getFieldValue('period'),
		props.filterFormStateManager.getFieldValue('modality'),
		props.filterFormStateManager.getFieldValue('groupBy'),
	])
	useEffect(init, [props.reload])

	/**
	 */
	function init(): void {
		props.onChangeMedicalTimetableStructure([])
		setMedicalTimetableStructure([])
		setFilteredMedicalTimetableStructure([])
		if (!AppStateUtils.getCurrentDoctorGroup()) {
			NotificationHelper.error('Ops', 'Selecione um grupo de médicos primeiro')
			return
		}

		if (props.selectedConfigType === 'week' && !props.filterFormStateManager.getFieldValue('beginDate')) {
			NotificationHelper.error('Ops', 'Para salvar uma semana em específico passe uma data')
			return
		}

		const filters: MedicalTimetableStructureSearchRequestDTO = {
			doctorGroupCode: AppStateUtils.getCurrentDoctorGroup()!.code,
			date: props.selectedConfigType === 'week' ? props.filterFormStateManager.getFieldValue('beginDate') : undefined,
		}
		getMedicalTimetableStructureRequest.runRequest(MedicalTimetableRequests.getStructure(filters))
	}

	/**
	 */
	function onGetMedicalTimetableStructureRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getMedicalTimetableStructureRequest, 'Erro ao buscar estrutura da escala')) return

		const result = getMedicalTimetableStructureRequest.responseData!.list
		setMedicalTimetableStructure(result)
		setFilteredMedicalTimetableStructure(result)
		props.onChangeMedicalTimetableStructure([...result])
	}

	/**
	 */
	function mountTableRecordsFromStructure(structureData?: MedicalTimetableStructureResponseDTO[]): void {
		setIsMounting(true)

		const filteredIds: MedicalTimetableIdEnum[] = props.filterFormStateManager.getFieldValue('id') ?? []
		const filteredPeriods: MedicalTimetablePeriodEnum[] = props.filterFormStateManager.getFieldValue('period') ?? []
		const filteredModalities = props.filterFormStateManager.getFieldValue('modality') ?? []
		const filteredGroupBy = props.filterFormStateManager.getFieldValue('groupBy') ?? []

		// Se não tiver enviado do parametro utiliza a do escopo, ja carregada anteriormente
		if (!structureData) structureData = medicalTimetableStructure

		const generatedTableRecords: MedicalTimetableStructureResponseDTO[] = []
		structureData.forEach((structure) => {
			// Skipa em casos de filtros
			if (
				(!ArrayUtils.isEmpty(filteredIds) && !filteredIds.includes(structure.id)) ||
				(!ArrayUtils.isEmpty(filteredPeriods) && !filteredPeriods.includes(structure.period)) ||
				(!ArrayUtils.isEmpty(filteredModalities) && !filteredModalities.includes(structure.modality)) ||
				(!ArrayUtils.isEmpty(filteredGroupBy) && !filteredGroupBy.includes(structure.groupBy))
			)
				return

			generatedTableRecords.push(structure)
		})

		setFilteredMedicalTimetableStructure(generatedTableRecords)

		// Precisa esperar para recarregar a tabela
		SystemUtils.sleep(10).then(() => {
			setIsMounting(false)
		})
	}

	/**
	 */
	function onChangeTotalDoctors(record: MedicalTimetableStructureResponseDTO, weekDay: WeekDaysEnum, total: number): void {
		const foundStructureToEdit = props.medicalTimetableStructureEdit.find(
			(structure) =>
				structure.id == record.id &&
				structure.period == record.period &&
				structure.modality === record.modality &&
				structure.groupBy === record.groupBy,
		)

		if (!foundStructureToEdit) {
			NotificationHelper.error('Ops', 'Não conseguimos encontrar essa linha na escala')
			return
		}

		const weekDayValue = foundStructureToEdit.schedules.find((scheduleStructure) => scheduleStructure.dayOfWeek === weekDay)
		if (!weekDayValue) {
			foundStructureToEdit.schedules.push({
				dayOfWeek: weekDay,
				numberOfRows: total,
			})
		} else {
			weekDayValue.numberOfRows = Number(total)
		}

		props.onStartEditing()
	}

	if (isMounting) return <LoadingOverlayCP show={true} />

	return (
		<TableCP<MedicalTimetableStructureResponseDTO>
			data={filteredMedicalTimetableStructure}
			wrappedOnCard={true}
			bordered={true}
			loading={getMedicalTimetableStructureRequest.isAwaiting}
			columns={TableMedicalTimetableConfigUtils.getColumns(filteredMedicalTimetableStructure, onChangeTotalDoctors)}
		/>
	)
}
