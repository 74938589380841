import React from 'react'
import { SubtitleTableItemICP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/inner/SubtitleTableItemICP'
import { SubtitleTableWrapperCP } from 'submodules/nerit-framework-ui/common/components/table/subtitle/SubtitleTableWrapperCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	role: UserTypeEnum
}

/**
 */
export function SubtitleTableDoctorGroupPeopleCP(props: ICPProps): JSX.Element {
	return (
		<SubtitleTableWrapperCP>
			{props.role === UserTypeEnum.USER && (
				<SubtitleTableItemICP
					icon={<IconICP size={13} iconName={'crown'} color={`${ThemeFrameworkCommon.browserDefaultColorLight}`} />}
					text={'Usuário com "coroa" tem prerrogativa de administrador do grupo. Com isso conseguirá editar dados e configurações do grupo.'}
					color={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
					canWrap={true}
				/>
			)}

			{props.role === UserTypeEnum.DOCTOR && (
				<SubtitleTableItemICP
					icon={<IconICP size={13} iconName={'crown'} color={`${ThemeFrameworkCommon.browserDefaultColorLight}`} />}
					text={'Médico com "coroa" tem prerrogativa de "coordenador" do grupo, como ver exames fora do laudo.'}
					color={`${ThemeFrameworkCommon.browserDefaultColorDark}`}
					canWrap={true}
				/>
			)}
		</SubtitleTableWrapperCP>
	)
}
