import React from 'react'
import { QRCodeCanvas } from 'qrcode.react'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { BasicStyleWrapperCP, BasicStyleWrapperCPProps } from 'submodules/nerit-framework-ui/common/components/basic-wrappers/BasicStyleWrapperCP'

interface IFileUploadCPProps extends BasicStyleWrapperCPProps {
	url: string
	size?: number
}

/**
 * QR Code
 */
export function QrCodeCP(props: IFileUploadCPProps): JSX.Element {
	return (
		<BasicStyleWrapperCP margin={props.margin}>
			<QRCodeCanvas
				value={props.url}
				size={props.size}
				imageSettings={
					!ThemeProject.qrCodeLogoUrl
						? undefined
						: {
								src: ThemeProject.qrCodeLogoUrl,
								height: 20,
								width: 20,
								excavate: true,
						  }
				}
			/>
		</BasicStyleWrapperCP>
	)
}
