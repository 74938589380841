/**
 * Enum de ambientes do sistema
 */
export enum EnvironmentEnum {
	DEV = 'DEV',
	TEST = 'TEST',
	PROD = 'PROD',
	PROD2 = 'PROD2',
	HOM = 'HOM',
}
