import React, { useEffect } from 'react'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { FindPersonBoxCP } from 'modules/person/components/common/find-person-box/FindPersonBoxCP'
import { UserTypeEnum, UserTypeLabelEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { DoctorGroupsRequests } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/DoctorGroupsRequests'
import { AddPersonToDoctorGroupRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/AddPersonToDoctorGroupRequestDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DoctorGroupDoctorSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/dtos/requests/DoctorGroupDoctorSaveRequestDTO'

interface IAddUserModalCPProps {
	visible: boolean
	onCancel: () => void
	onSave: () => void
	doctorGroupCode: number
	role: UserTypeEnum
}

/**
 * Adicionar usuario em uma clinica.
 */
export function ModalAddPersonToDoctorGroupCP(props: IAddUserModalCPProps): JSX.Element {
	const doLinkRequest = useRequest<void>('none')
	useEffect(onDoLinkReturn, [doLinkRequest.isAwaiting])

	/**
	 * Linka com o grupo de medico a pessoa informada
	 */
	function doLink(personCode: number, doctorData?: DoctorGroupDoctorSaveRequestDTO): void {
		const dto: AddPersonToDoctorGroupRequestDTO = {
			doctorGroupCode: props.doctorGroupCode,
			personCode: personCode,
			role: props.role,
			extraDoctorData: doctorData,
		}
		doLinkRequest.runRequest(DoctorGroupsRequests.addPerson(dto))
	}

	/**
	 * Retorno do link
	 */
	function onDoLinkReturn(): void {
		if (!RequestUtils.isValidRequestReturn(doLinkRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, 'Vinculado com sucesso', true)) return

		props.onSave()
	}

	return (
		<ModalCP
			visible={props.visible}
			actionLoading={doLinkRequest.isAwaiting}
			noFooter={true}
			onCancel={props.onCancel}
			title={`Adicionar ${UserTypeLabelEnum[props.role]}`}
			width={400}
		>
			<FindPersonBoxCP type={props.role} onClickToAdd={doLink} isAdding={doLinkRequest.isAwaiting} doctorGroupCode={props.doctorGroupCode} />
		</ModalCP>
	)
}
