import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PatientPortalDataPdfHeaderICP } from 'modules/patient-portal/components/patient-portal-data-pdf/inner/PatientPortalDataPdfHeaderICP'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { PatientPortalDataPdfFooterICP } from 'modules/patient-portal/components/patient-portal-data-pdf/inner/PatientPortalDataPdfFooterICP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	exam: ExamResponseDTO
	onPrinterReady: () => void
}

/**
 */
export const PatientPortalDataPdfCP = React.forwardRef((props: ICPProps, ref: any) => {
	const [clinic, setClinic] = useState<ClinicResponseDTO>()
	const getClinicRequest = useRequest<ClinicResponseDTO>()
	useEffect(onGetClinicRequestChange, [getClinicRequest.isAwaiting])

	useEffect(init, [props.exam])

	/**
	 * Inicializa a tela.
	 */
	function init(): void {
		getClinicRequest.runRequest(ClinicsRequests.get(props.exam.clinic.code))
	}

	/**
	 * Retorno da requisicao.
	 */
	function onGetClinicRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(getClinicRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setClinic(getClinicRequest.responseData)
		props.onPrinterReady()
	}

	if (!clinic) return <></>

	return (
		<WrapperSCP ref={ref}>
			<BorderWrapperSCP>
				<PatientPortalDataPdfHeaderICP exam={props.exam} clinic={clinic} />

				<PatientPortalDataPdfFooterICP />
			</BorderWrapperSCP>
		</WrapperSCP>
	)
})

const WrapperSCP = styled.div`
	display: none;

	@media print {
		display: block !important;
	}

	padding: 40px;

	.ant-typography {
		color: ${ThemeFrameworkCommon.browserDefaultColorDark};
		margin: 0;
	}
`

const BorderWrapperSCP = styled.div`
	border: dashed #dedede 1px;
	border-radius: 5px;
	padding: 10px;
`
