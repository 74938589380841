import React from 'react'
import { WeekDaysEnum } from 'submodules/nerit-framework-utils/utils/date/WeekDaysEnum'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { ButtonMedicalTimetableAssignCP } from 'modules/medical-timetable/components/buttons/button-medical-timetable-assign/ButtonMedicalTimetableAssignCP'
import { BlockedMedicalTimetableCP } from 'modules/medical-timetable/components/blocked-medical-timetable/BlockedMedicalTimetableCP'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'
import { IMedicalTimetableRecord } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableRecord'
import { IMedicalTimetableStatistics } from 'modules/medical-timetable/components/tables-medical-timetable/types/IMedicalTimetableStatistics'
import { DoctorOnMedicalTimetableCP } from 'modules/medical-timetable/components/doctor-on-medical-timetable/DoctorOnMedicalTimetableCP'

interface ICPProps {
	beginWeekDate: Date
	dayOfWeek: WeekDaysEnum
	record: IMedicalTimetableRecord
	doctors: DoctorOnMedicalTimetableResponseDTO[]
	structure: MedicalTimetableStructureResponseDTO[]
	statistics: IMedicalTimetableStatistics[]
	onAssign: () => void
}

/**
 */
export function ColumnMedicalTimetableWeekDayDoctorCP(props: ICPProps): JSX.Element {
	let index = 0

	let sta = props.statistics.find(
		(st) =>
			st.id === props.record.id &&
			st.period === props.record.period &&
			st.modality === props.record.modality &&
			st.groupBy === props.record.groupBy &&
			st.dayOfWeek === props.dayOfWeek,
	)

	if (!!sta?.stastistics) {
		index = sta.stastistics.lastRowIndex + 1
		sta.stastistics.lastRowIndex = index
	} else {
		sta = {
			id: props.record.id,
			period: props.record.period,
			modality: props.record.modality,
			groupBy: props.record.groupBy,
			dayOfWeek: props.dayOfWeek,
			stastistics: {
				lastRowIndex: 0,
				totalNeedDoctors: 0,
			},
		}
		props.statistics.push(sta)
	}

	const structureSameConditions = props.structure.find(
		(st) =>
			st.id === props.record.id && st.period === props.record.period && st.modality === props.record.modality && st.groupBy === props.record.groupBy,
	)

	const structureTotal = structureSameConditions?.schedules.find((scond) => scond.dayOfWeek === props.dayOfWeek)

	if (!structureTotal || index >= structureTotal.numberOfRows) return <BlockedMedicalTimetableCP />

	const doctorsFilteredOnConditions = props.doctors.filter(
		(st) =>
			st.id === props.record.id &&
			st.period === props.record.period &&
			st.modality === props.record.modality &&
			st.groupedBy === props.record.groupBy &&
			st.dayOfWeek === props.dayOfWeek,
	)
	const currentDoctor = doctorsFilteredOnConditions[index]

	if (!!currentDoctor?.doctor) {
		return (
			<DoctorOnMedicalTimetableCP
				record={props.record}
				doctors={doctorsFilteredOnConditions}
				onAssign={props.onAssign}
				dayOfWeek={props.dayOfWeek}
				numberOfRows={structureTotal.numberOfRows}
				beginWeekDate={props.beginWeekDate}
				recordIndex={index}
			/>
		)
	}

	sta.stastistics.totalNeedDoctors = sta.stastistics.totalNeedDoctors + 1

	return (
		<ButtonMedicalTimetableAssignCP
			doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
			groupBy={props.record.groupBy}
			id={props.record.id}
			modality={props.record.modality}
			period={props.record.period}
			onSave={props.onAssign}
			date={DateUtils.add(props.beginWeekDate, props.dayOfWeek, TimeBaseEnum.DAY)}
			dayOfWeek={props.dayOfWeek}
		/>
	)
}
