import React, { useEffect, useState } from 'react'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SelectDoctorGroupPeopleCP } from 'modules/doctor-groups/components/doctor-groups/select-doctor-group-people/SelectDoctorGroupPeopleCP'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { UserDoctorGroupStatusEnum } from 'submodules/beerads-sdk/services/doctor-groups/doctor-groups/enums/UserDoctorGroupStatusEnum'

interface ICPProps extends BasicModalCPProps {
	doctorGroupCode: number
	examCodes?: number[]
	audit?: {
		codes: number[]
		doctorCode?: number
	}
}

/**
 */
export function ModalCreateAuditsCP(props: ICPProps): JSX.Element {
	const [selectedDoctorCode, setSelectedDoctorCode] = useState<number>()
	useEffect(() => setSelectedDoctorCode(props.audit?.doctorCode), [props.audit?.doctorCode])

	const creatAuditsRequest = useRequest<void>('none')
	useEffect(onCreateAuditsRequestChange, [creatAuditsRequest.isAwaiting])

	/**
	 * Atribui os exames.
	 */
	function assignExams(): void {
		if (!selectedDoctorCode) return NotificationHelper.error('Ops', 'Selecione um médico primeiro')

		if (!!props.audit?.codes) {
			creatAuditsRequest.runRequest(
				AuditsRequests.reassign({
					auditCodes: props.audit.codes,
					doctorCode: selectedDoctorCode,
				}),
			)
			return
		}

		if (!!props.examCodes) {
			creatAuditsRequest.runRequest(
				AuditsRequests.create({
					examCodes: props.examCodes,
					doctorCode: selectedDoctorCode,
				}),
			)
			return
		}

		NotificationHelper.error('Ops', 'Não foi informado exame nem auditoria')
	}

	/**
	 * Retorno da requisicao de atribuir.
	 */
	function onCreateAuditsRequestChange(): void {
		if (
			!RequestUtils.isValidRequestReturn(
				creatAuditsRequest,
				NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE,
				NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE,
				true,
			)
		)
			return

		props.onSave()
	}

	return (
		<ModalCP
			visible={props.visible}
			onCancel={props.onCancel}
			title={props.audit?.codes ? 'Reatribuir Auditoria' : 'Criar Auditoria'}
			onOk={assignExams}
			actionLoading={creatAuditsRequest.isAwaiting}
			width={450}
		>
			<SelectDoctorGroupPeopleCP
				doctorGroupCode={props.doctorGroupCode}
				role={UserTypeEnum.DOCTOR}
				required={true}
				namesConfig={{ showSpecialty: true }}
				value={selectedDoctorCode}
				onChange={(code) => setSelectedDoctorCode(code as number)}
				isMultiple={false}
				filters={{
					isAuditor: true,
					status: [UserDoctorGroupStatusEnum.ACTIVE],
				}}
			/>
		</ModalCP>
	)
}
