import { useButtonPopoverExamBillingFlags } from 'modules/exams/components/exams/button-popover-exam-billing-flags/useButtonPopoverExamBillingFlags'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ExamExtraDataTP } from 'submodules/beerads-sdk/services/exams/exams/types/ExamExtraDataTP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { IconCP } from 'submodules/nerit-framework-ui/common/components/icon/IconCP'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'

interface IButtonPopoverExamBillingFlagsCPProps {
	onChangeBillingFlags?: () => void
	exam: {
		extraData?: ExamExtraDataTP
		id: string
	}
}

export function ButtonPopoverExamBillingFlagsCP(props: IButtonPopoverExamBillingFlagsCPProps): JSX.Element {
	const {
		flags,
		isPopoverVisible,
		setBillingFlagsRequest,
		handlePopperClose,
		onBillingFlagChange,
		setIsPopoverVisible,
		handleBillingFlagsSave,
		onSetBillingFlagsRequestChange,
	} = useButtonPopoverExamBillingFlags({ exam: props.exam, onChangeBillingFlags: props.onChangeBillingFlags })

	useEffect(onSetBillingFlagsRequestChange, [setBillingFlagsRequest.isAwaiting])

	const hasChanges =
		!!flags.doNotBilling !== !!props.exam.extraData?.doNotBilling || !!flags.forceDoctorRevenue !== !!props.exam.extraData?.forceDoctorRevenue

	const shouldDisablePopoverButton = setBillingFlagsRequest.isAwaiting || !hasChanges

	return (
		<PopOverCP
			trigger="click"
			visible={isPopoverVisible}
			title="Opções de faturamento"
			onClose={handlePopperClose}
			placement="topLeft"
			content={
				<FlexCP flexDirection="column">
					<FlexCP alignItems="center" flexDirection="column" justifyContent="flex-end">
						<SwitchCP
							label="Forçar repasse do médico"
							value={flags.forceDoctorRevenue}
							onChange={() => onBillingFlagChange('forceDoctorRevenue')}
						/>
						<SwitchCP
							label="Não cobrar a unidade hospitalar"
							value={flags.doNotBilling}
							onChange={() => onBillingFlagChange('doNotBilling')}
						/>
					</FlexCP>

					<ButtonCP
						marginTop={8}
						loading={setBillingFlagsRequest.isAwaiting}
						disabled={shouldDisablePopoverButton}
						onClick={handleBillingFlagsSave}
						type={!shouldDisablePopoverButton ? 'primary' : undefined}
						confirmMsg="Você tem certeza que deseja alterar as configurações de faturamento?"
					>
						Salvar
					</ButtonCP>
				</FlexCP>
			}
		>
			<WrapperSCP showBorder>
				<ButtonCP
					size="small"
					onClick={() => setIsPopoverVisible(true)}
					type={'default'}
					loading={setBillingFlagsRequest.isAwaiting}
					disabled={setBillingFlagsRequest.isAwaiting}
					tooltip="Alterar as opções de faturamento"
					tooltipPlacement="topRight"
				>
					{!setBillingFlagsRequest.isAwaiting && <IconCP antIcon="dollar" />}
				</ButtonCP>
			</WrapperSCP>
		</PopOverCP>
	)
}

const WrapperSCP = styled.div<{ showBorder?: boolean }>`
	button {
		border: ${(props) => (props.showBorder ? undefined : 'none')};
	}
`
