import React from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'
import { DoctorGroupNamesFromClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/DoctorGroupNamesFromClinicResponseDTO'

interface ICPProps {
	clinicCode: number
	formStateManager: IFormStateManager<any>
	formField: string
	onLoadOptions: (options: DoctorGroupNamesFromClinicResponseDTO[]) => void
	onChange?: (code: number) => void
}

/**
 */
export function SelectClinicDoctorGroupCP(props: ICPProps): JSX.Element {
	return (
		<AutocompletePickerCP
			label={'Selecione um Grupo de Médicos da Clínica'}
			showOptionsOnLoad={true}
			requestConfigGetter={() => ClinicsRequests.namesDoctorGroups(props.clinicCode)}
			formStateManager={props.formStateManager}
			fieldName={props.formField}
			required={true}
			onLoadOptions={props.onLoadOptions}
			onChange={props.onChange}
		/>
	)
}
