import React from 'react'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { SwitchExamCriticalCP } from 'modules/exams/components/exams/switch-exam-critical/SwitchExamCriticalCP'
import { ExamStatusEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusEnum'

interface ICPProps {
	exam: ExamResponseDTO
	formStateManager: IFormStateManager<MedicalReportFormModel>
}

/**
 */
export function FinalizeMedicalReportFormFieldsICP(props: ICPProps): JSX.Element {
	return (
		<>
			<SwitchExamCriticalCP formStateManager={props.formStateManager} fieldName={'isCritical'} margin={{ top: 20 }} />

			{!ArrayUtils.isEmpty(props.exam.contrasts) && (
				<SwitchCP
					label={'Exame com contraste, você considerou no laudo?'}
					formStateManager={props.formStateManager}
					fieldName={'wasConsideredContrast'}
				/>
			)}

			{props.exam.status === ExamStatusEnum.RECTIFICATION && (
				<SwitchCP
					label={'A retificação teve impacto clínico?'}
					formStateManager={props.formStateManager}
					fieldName={'rectificationClinicalImpact'}
				/>
			)}
		</>
	)
}
