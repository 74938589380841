import { IsOptional } from 'class-validator'
import { Moment } from 'moment'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsValidCpf } from 'submodules/nerit-framework-utils/validators/decorators/IsValidCpf'
import { IsEmail } from 'submodules/nerit-framework-utils/validators/decorators/IsEmail'
import { IsPhoneBR } from 'submodules/nerit-framework-utils/validators/decorators/IsPhoneBR'
import { DoctorTreatmentEnum } from 'submodules/beerads-sdk/services/people/people/enums/DoctorTreatmentEnum'
import { DoctorIdTP } from 'submodules/beerads-sdk/services/people/people/types/DoctorIdTP'
import { ObjectPropsTP } from 'submodules/nerit-framework-utils/utils/types/ObjectPropsTP'

export class DoctorProfileFormModel extends FormModel {
	@IsRequired()
	name: string

	@IsValidCpf()
	@IsRequired()
	cpf: string

	@IsRequired()
	@IsEmail()
	email: string

	@IsRequired()
	@IsPhoneBR()
	phoneOne?: string

	@IsOptional()
	specialty?: string

	@IsOptional()
	nationality?: string

	@IsOptional()
	birthDate?: Moment

	@IsRequired()
	treatment: DoctorTreatmentEnum

	@IsOptional()
	hasDigitalCertificate?: boolean

	@IsOptional()
	ids?: DoctorIdTP[]

	constructor(dto?: ObjectPropsTP<DoctorProfileFormModel>) {
		super(dto)
		Object.assign(this, dto)
	}
}
