import React from 'react'
import { Dropdown, Menu } from 'antd'
import { InfoIconCP } from '_old/main/common/components/icons/InfoIconCP'
import { MicrophoneIconCP } from '_old/main/common/components/icons/MicrophoneIconCP'
import { ToolbarButtonICP } from 'modules/exams/components/medical-report/editor-medical-report/toolbar/inner/ToolbarButtonICP'
import styled from 'styled-components'
import { ThemeProject } from 'config/theme/project/ThemeProject'

/**
 * COMPONENTE
 * Botao de para exibir Ajuda (tooltips) para utilizacao do editor
 * wysiwyg / Draft JS customizado.
 *
 * @todo: Incluir novas instrucoes
 *
 * @author hjcostabr
 * @author Stella
 */
export function HelpButtonICP(): JSX.Element | null {
	function renderInfoData(): JSX.Element {
		return (
			<Menu>
				<SubMenuSCP title={'Comandos de voz'}>
					{renderCommandListItem('Nova Linha', 'para parágrafo')}
					{renderCommandListItem('Vírgula', 'para vírgula')}
					{renderCommandListItem('Ponto Final', 'para ponto final')}
				</SubMenuSCP>
			</Menu>
		)
	}

	function renderCommandListItem(command: string, description: string): JSX.Element {
		return (
			<Menu.Item>
				<CommandNameContainerSCP>
					<SpeakIconContainerSCP>
						<MicrophoneIconCP size={13} />
						<span>Fale</span>
					</SpeakIconContainerSCP>

					<span>&quot;{command}&quot;</span>
				</CommandNameContainerSCP>

				<CommandDescriptionSCP>{description}</CommandDescriptionSCP>
			</Menu.Item>
		)
	}

	return (
		<Dropdown overlay={renderInfoData()} trigger={['click']}>
			<ToolbarButtonICP innerContent={<InfoIconCP size={22} color={ThemeProject.gray700} />} />
		</Dropdown>
	)
}

const SubMenuSCP = styled(Menu.SubMenu)`
	li {
		padding: 2px 5px 0px 5px;
	}

	li:not(:last-of-type) {
		border-bottom: 1px solid ${ThemeProject.gray};
	}
`

const CommandNameContainerSCP = styled.div`
	padding: 4px;
	display: flex;
	align-items: center;

	> span {
		flex: 1;
		text-align: center;
	}
`

const SpeakIconContainerSCP = styled.div`
	flex-direction: column;
	align-items: center;
	display: flex;
	margin-right: 4px;

	> span {
		font-size: 10px;
		line-height: 19px;
	}
`

const CommandDescriptionSCP = styled.p`
	font-size: 12px;
	line-height: 12px;
	font-style: italic;
	text-align: center;
`
