import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'

interface ICPProps {
	onGoBack: () => void
	onEditMedicalReport: () => void
	visible: boolean
}

/**
 */
export function ModalConfirmStartExamCP(props: ICPProps): JSX.Element {
	return (
		<ModalCP
			title={'O que deseja fazer?'}
			visible={props.visible}
			closable={false}
			disableOutsideClickClosing={true}
			footer={
				<FlexCP gap="5px" justifyContent="flex-end">
					<ButtonCP onClick={props.onEditMedicalReport}>Quero editar o laudo</ButtonCP>
					<ButtonCP type="primary" onClick={props.onGoBack}>
						Voltar para listagem
					</ButtonCP>
				</FlexCP>
			}
		>
			<p>
				Este laudo está <b>CONCLUÍDO</b>.
			</p>
			<p>Clique em "Quero editar o laudo" e o exame voltará para o status "em progresso".</p>
			<p>Caso tenha apertado o botão de voltar do navegador continue até encontrar a página desejada ou clique em "Voltar para listagem"</p>
		</ModalCP>
	)
}
