import { IMedicalReportLocalStorage } from 'modules/exams/components/medical-report/status-save-report-local-storage/inner/IMedicalReportLocalStorage'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'

export class MedicalReportLocalStorageUtil {
	/**
	 * Inicializa o localstorage para a key do exame
	 */
	static initMedicalReport(examCode: number): void {
		const medicalReportLocalStorageKey = MedicalReportLocalStorageUtil.getMedicalReportLocalStorageKey(examCode)

		const localStorageStr = localStorage.getItem(medicalReportLocalStorageKey)
		if (!localStorageStr) {
			const medicalReportToStore: IMedicalReportLocalStorage = {
				examCode: examCode,
			}
			localStorage.setItem(medicalReportLocalStorageKey, JSON.stringify(medicalReportToStore))
			return
		}

		// Ao iniciar se ja tiver algo no LS guarda o corrente (anterior) no historico atual
		// O antigo corrente eh o atual historico
		const medicalReportOnLocalStorage: IMedicalReportLocalStorage = JSON.parse(localStorageStr)
		const medicalReportToStore: IMedicalReportLocalStorage = {
			examCode: examCode,
			medicalReportContentsHistory: medicalReportOnLocalStorage.medicalReportContentsCurrent,
		}
		localStorage.setItem(medicalReportLocalStorageKey, JSON.stringify(medicalReportToStore))
	}

	/**
	 * Sava o medical report no LS ao ir digitando
	 */
	static saveMedicalReportOnTyping(examCode: number, medicalReportContents: MedicalReportContentTP[]): void {
		const medicalReportLocalStorageKey = MedicalReportLocalStorageUtil.getMedicalReportLocalStorageKey(examCode)

		// Ao inicializar verifica se tem algo no corrente
		const localStorageStr = localStorage.getItem(medicalReportLocalStorageKey)
		if (!localStorageStr) {
			MedicalReportLocalStorageUtil.initMedicalReport(examCode)
			return
		}

		// O historico continua imutavel e vai atualizando o corrente
		const medicalReportOnLocalStorage: IMedicalReportLocalStorage = JSON.parse(localStorageStr)
		const medicalReportToStore: IMedicalReportLocalStorage = {
			examCode: examCode,
			medicalReportContentsCurrent: medicalReportContents,
			medicalReportContentsHistory: medicalReportOnLocalStorage.medicalReportContentsHistory,
		}
		localStorage.setItem(medicalReportLocalStorageKey, JSON.stringify(medicalReportToStore))
	}

	/**
	 */
	static getMedicalReportLocalStorage(examCode: number): IMedicalReportLocalStorage | undefined {
		// Ao inicializar verifica se tem algo no corrente
		const localStorageStr = localStorage.getItem(MedicalReportLocalStorageUtil.getMedicalReportLocalStorageKey(examCode))
		if (!localStorageStr) return

		return JSON.parse(localStorageStr)
	}

	/**
	 */
	static discardMedicalReportLocalStorage(examCode: number): void {
		localStorage.removeItem(MedicalReportLocalStorageUtil.getMedicalReportLocalStorageKey(examCode))
	}

	/**
	 */
	private static getMedicalReportLocalStorageKey(examCode: number): string {
		return `br-mr-${examCode}`
	}
}
