import React, { useEffect, useState } from 'react'
import { SurveyQuestionBoxCP } from 'submodules/space4leads-components-ui/survey/components/survey-question-box/SurveyQuestionBoxCP'
import { SurveyResponseDTO } from 'submodules/space4leads-sdk/services/surveys/surveys/dtos/response/SurveyResponseDTO'
import styled from 'styled-components'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import { SurveyAnswersRequests } from 'submodules/space4leads-sdk/services/surveys/survey-answers/SurveyAnswersRequests'
import { SurveyAnswerUpdateRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerUpdateRequestDTO'
import { SurveyAnswerResponseDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/response/SurveyAnswerResponseDTO'
import { SurveyAnswerCreateRequestDTO } from 'submodules/space4leads-sdk/services/surveys/survey-answers/dtos/request/SurveyAnswerCreateRequestDTO'

interface IScreenContentSurveyLandingPageProps {
	selectedScore?: number
	tags?: string[]
	surveyToken: string
	projectCode?: number
	personToken?: string
	survey: SurveyResponseDTO
	surveyAnswer?: SurveyAnswerResponseDTO
	onSaveAnswer: () => void
	viewMode: 'fullpage' | 'widget'
}

/**
 * Conteudo da Landing Page para captar respostas
 */
export function SurveyCustomerViewCP(props: IScreenContentSurveyLandingPageProps): JSX.Element {
	const [surveyAnswerCode, setSurveyAnswerCode] = useState<number>()
	const [wasFinalSaveRequest, setWasFinalSaveRequest] = useState<boolean>()

	const saveSurveyAnswerRequest = useRequest<SurveyAnswerResponseDTO>()
	useEffect(onSaveSurveyAnswerRequestChange, [saveSurveyAnswerRequest.isAwaiting])

	useEffect(init, [])

	/**
	 * Ao inicializar a tela.
	 */
	function init(): void {
		if (!props.selectedScore) return

		// Se ja tiver selecionado a nota ao entrar na tela, salva a nota informada
		saveAnswer(props.selectedScore)
	}

	/**
	 * Salva resposta.
	 */
	function saveAnswer(score: number, comments?: string): void {
		const answerCode = surveyAnswerCode ?? props.surveyAnswer?.code
		if (!answerCode) {
			const dto: SurveyAnswerCreateRequestDTO = {
				personToken: props.personToken,
				score,
				comments,
				tags: props.tags,
			}
			saveSurveyAnswerRequest.runRequest(SurveyAnswersRequests.create(props.surveyToken, dto, props.projectCode))
			return
		}

		setWasFinalSaveRequest(!!comments)

		const dto: SurveyAnswerUpdateRequestDTO = {
			score,
			comments,
			tags: props.tags,
		}
		saveSurveyAnswerRequest.runRequest(SurveyAnswersRequests.update(answerCode, dto, props.projectCode))
	}

	/**
	 * Apos reotorno da api de salvar
	 */
	function onSaveSurveyAnswerRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(saveSurveyAnswerRequest, 'Ocorreu algun erro ao salvar conta bancaria')) return

		NotificationHelper.success('Yes!', 'Obrigado, sua resposta foi salva com sucesso')
		setSurveyAnswerCode(saveSurveyAnswerRequest.responseData!.code)

		if (wasFinalSaveRequest) props.onSaveAnswer()
	}

	return (
		<WrapperSCP className={props.viewMode}>
			<LoadingOverlayCP show={saveSurveyAnswerRequest.isAwaiting} />

			<MainSCP>
				<SurveyQuestionBoxCP
					type={props.survey.type}
					selectedScore={props.selectedScore ?? props.surveyAnswer?.score}
					comment={props.surveyAnswer?.comments}
					questionLabels={{
						mainQuestionLabel: props.survey.mainQuestionLabel,
						goodAnswerQuestionLabel: props.survey.goodAnswerQuestionLabel,
						badAnswerQuestionLabel: props.survey.badAnswerQuestionLabel,
					}}
					showScoreAfterAnswer={props.viewMode === 'fullpage'}
					onSave={saveAnswer}
				/>
			</MainSCP>

			<FooterSCP>Powered by Space4Leads</FooterSCP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	.fullpage {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
`

const MainSCP = styled.div`
	flex-grow: 1;
`

const FooterSCP = styled.div`
	margin-top: 30px;
	flex-shrink: 0;
	padding-bottom: 15px;
	text-align: right;
`
