export type RadioOptionTP<OptionTP = string> = {
	value?: OptionTP
	content: JSX.Element | string
	tooltip?: string
	disabled?: boolean
	hide?: boolean
}

export const RadioOptionsYesOrNo: RadioOptionTP<boolean>[] = [
	{ content: 'Sim', value: true },
	{ content: 'Não', value: false },
]
