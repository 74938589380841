import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ExamHistorySearchRequestDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/request/ExamHistorySearchRequestDTO'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { ExamHistoryResponseDTO } from 'submodules/beerads-sdk/services/exams/history/dtos/response/ExamHistoryResponseDTO'

export class ExamHistoryRequests {
	public static CONTROLLER_ROOT = 'exam-history'
	public static SEARCH_EP = 'search'

	static search = (dto: ExamHistorySearchRequestDTO): RequestConfigTP<ListResponseDTO<ExamHistoryResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${ExamHistoryRequests.CONTROLLER_ROOT}/${ExamHistoryRequests.SEARCH_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})
}
