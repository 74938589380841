import React from 'react'
import { ButtonScoreCP } from 'submodules/space4leads-components-ui/survey/components/button-score/ButtonScoreCP'
import { SurveyTypeEnum } from 'submodules/space4leads-sdk/services/surveys/surveys/enums/SurveyTypeEnum'
import { CSAT_COLORS, NPS_COLORS } from 'submodules/space4leads-components-ui/survey/components/buttons-survey-score/inner/SurveyButtonScoreColors'

interface IButtonsSurveyScoreCPProps {
	selectedScore?: number
	onClick: (selectedScore: number) => void
	disabled?: boolean
	type: SurveyTypeEnum
}

/**
 * Botoes para resposta de uma pergunta CSAT
 */
export function ButtonsSurveyScoreCP(props: IButtonsSurveyScoreCPProps): JSX.Element {
	const totalButtons = props.type === SurveyTypeEnum.NPS ? 10 : 5
	const result: JSX.Element[] = []

	const colors = props.type === SurveyTypeEnum.NPS ? NPS_COLORS : CSAT_COLORS

	for (let i = 1; i <= totalButtons; i++) {
		result.push(
			<ButtonScoreCP
				scoreLabel={i}
				color={colors[i - 1]}
				isSelected={props.selectedScore === i}
				onClick={() => props.onClick(i)}
				disabled={props.disabled}
			/>,
		)
	}

	return <>{result}</>
}
