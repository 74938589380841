import React, { useEffect, useState } from 'react'
import { AutoComplete } from 'antd'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormItemICP } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/FormItemICP'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import styled from 'styled-components'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
	requestConfigGetter: () => RequestConfigTP
}

/**
 */
export function SimpleAutocompleteFromApi<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {
	const [options, setOptions] = useState<string[]>()

	const searchRequest = useRequest<ListResponseDTO<string>>()
	useEffect(onSearchRequestChange, [searchRequest.isAwaiting])

	useEffect(init, [])

	/**
	 */
	function init(): void {
		searchRequest.runRequest(props.requestConfigGetter())
	}

	/**
	 */
	function onSearchRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(searchRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		setOptions(searchRequest.responseData?.list.filter((option) => !!option))
	}

	return (
		<WrapperSCP>
			<FormItemICP<FModelTP, IFormItemCommonProps>
				label={props.label}
				fieldName={props.fieldName}
				formStateManager={props.formStateManager}
				required={props.required}
				width={props.width}
				errorMessage={props.errorMessage}
				noValidation={props.noValidation}
				placeholder={props.placeholder}
				onBlur={props.onBlur}
				hint={props.hint}
			>
				<AutoComplete dataSource={options ?? []} filterOption={true} />
			</FormItemICP>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	input {
		border-radius: 0 !important;
	}
`
