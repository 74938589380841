import { ThemeProject } from 'config/theme/project/ThemeProject'
import { IAutocompleteOption } from 'modules/exams/components/medical-report/editor-medical-report/inner/plugins/autocomplete-plugin/inner/IAutocompleteOption'
import React, { useState } from 'react'
import styled from 'styled-components'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface IOptionEntryICPProps {
	completion: IAutocompleteOption
	index: number
	onCompletionFocus: (index: number) => void
	onCompletionSelect: (completion: IAutocompleteOption) => void
}
/**
 * Renderiza uma opção para o plugin do autocomplete
 */
export function OptionEntryICP(props: IOptionEntryICPProps): JSX.Element {
	const [mouseDown, setMouseDown] = useState(false)

	function onMouseDown(event: React.MouseEvent) {
		event.preventDefault()
		setMouseDown(true)
	}

	function onMouseUp() {
		if (mouseDown) {
			setMouseDown(false)
			props.onCompletionSelect(props.completion)
		}
	}
	function onMouseEnter() {
		props.onCompletionFocus(props.index)
	}

	return (
		<OptionSCP onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseEnter={onMouseEnter} role={'option'}>
			{props.completion.name}
		</OptionSCP>
	)
}

const OptionSCP = styled.div`
	padding: 3px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundDark};
	:hover {
		background: ${ThemeProject.primary};
	}
`
