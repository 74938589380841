import React, { useEffect, useState } from 'react'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { UploadFileCP } from 'submodules/nerit-framework-ui/common/components/file/upload-file/UploadFileCP'
import { FileUtils } from 'submodules/nerit-framework-ui/common/components/file/utils/FileUtils'
import styled from 'styled-components'
import { MimeTypeEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/MimeTypeEnum'

interface ScpProps {
	width?: string
	height?: string
}

interface ICPProps {
	entityCode: number
	imgUrl?: string
	requestsConfig: {
		uploadRequestConfigTP: (code: number, formData: FormData) => RequestConfigTP
	}
	onUploadDone?: () => void
	styled?: ScpProps
	fileTypes?: MimeTypeEnum[]
}

/**
 * Upload de arquivo integracom com API.
 */
export function UploadFileFromApiCP(props: ICPProps): JSX.Element {
	const [imgUrl, setImgUrl] = useState<string | undefined>(props.imgUrl)

	const uploadFileRequest = useRequest<void>('none')
	useEffect(onUploadFileRequestChange, [uploadFileRequest.isAwaiting])

	/**
	 * Faz upload do arquivo.
	 */
	function uploadFile(file: any): void {
		const formData = new FormData()
		formData.append('file', file)

		const requestCofigTP = props.requestsConfig.uploadRequestConfigTP(props.entityCode, formData)
		requestCofigTP.avoidParamTransformer = true
		uploadFileRequest.runRequest(requestCofigTP)

		FileUtils.getBase64(file, (imageUrl) => {
			setImgUrl(imageUrl)
		})
	}

	/**
	 * Retorno do upload de arquivos.
	 */
	function onUploadFileRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(uploadFileRequest, 'Ocorreu algum erro ao fazer o upload do arquivo', 'Upload realizado com sucesso', true))
			return

		if (!!props.onUploadDone) props.onUploadDone()
	}

	return (
		<WrapperCP {...props.styled}>
			<UploadFileCP uploadFiles={uploadFile} loading={uploadFileRequest.isAwaiting} maxMbSize={5} imgSrc={imgUrl} fileTypes={props.fileTypes} />
		</WrapperCP>
	)
}

const WrapperCP = styled.div<ScpProps>`
	display: flex;
	justify-content: center;

	img {
		width: ${(props) => props.width ?? 'initial'};
		height: ${(props) => props.height ?? 'initial'};
	}
`
