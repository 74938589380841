import { AppStateUtils } from 'app/redux/AppStateUtils'
import { AuthActions } from 'app/utils/AuthActions'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ModalChangePasswordCP } from 'submodules/nerit-framework-ui/features/user/components/change-password-modal/ModalChangePasswordCP'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { TimeBaseEnum } from 'submodules/nerit-framework-utils/utils/enums/TimeBaseEnum'

export function TopBarPasswordExpirationCP(): JSX.Element {
	const [isModalChangePasswordVisible, setIsModalChangePasswordVisible] = useState(false)
	const [expiryInDays, setExpiryInDays] = useState<number>(0)

	const loggedUserData = AppStateUtils.getLoggedUser()

	const refreshLoginRequest = useRequest<AuthResponseDTO>()

	useEffect(onRefreshLoginRequestChange, [refreshLoginRequest.isAwaiting])

	useEffect(init, [])

	const shouldHideBanner = !loggedUserData || !loggedUserData.passwordExpiresAt || expiryInDays === 0 || expiryInDays > 15

	function onRefreshLoginRequestChange(): void {
		const isValidRequest = RequestUtils.isValidRequestReturn(refreshLoginRequest)

		if (!isValidRequest || !refreshLoginRequest.responseData) {
			return
		}

		const authData = refreshLoginRequest.responseData

		AuthActions.setLoggedUser(authData)

		init(authData.user.passwordExpiresAt)
	}

	function init(passwordExpiresAt?: string): void {
		if (!passwordExpiresAt && !loggedUserData?.passwordExpiresAt) {
			return
		}

		const now = DateUtils.formatDate(new Date(), DateFormatEnum.US_WITHOUT_TIME)
		const passwordExpirationDate = passwordExpiresAt ?? loggedUserData?.passwordExpiresAt

		setExpiryInDays(DateUtils.getDiff(TimeBaseEnum.DAY, now, passwordExpirationDate))
	}

	if (shouldHideBanner) {
		return <></>
	}

	return (
		<>
			<ModalChangePasswordCP
				visible={isModalChangePasswordVisible}
				onClose={() => {
					refreshLoginRequest.runRequest(AuthRequests.refreshLogin())
					setIsModalChangePasswordVisible(false)
				}}
				userCode={loggedUserData!.code}
				requestConfig={AuthRequests.changePassword}
			/>

			<WrapperSCP>
				Sua senha expira em {expiryInDays} dias.
				<ButtonCP
					horizontalPadding={4}
					type="link"
					color={ThemeProject.primary}
					onClick={() => {
						setIsModalChangePasswordVisible(true)
					}}
					style={{
						fontWeight: 'bold',
						color: ThemeProject.primary300,
						cursor: 'pointer !important',
					}}
				>
					Clique aqui
				</ButtonCP>
				para altera-lá.
			</WrapperSCP>
		</>
	)
}

const WrapperSCP = styled.div<{ positionFixed?: boolean }>`
	background: ${ThemeProject.warning};
	display: flex;
	justify-content: center;
	padding: 10px;
	width: 100%;
	font-weight: bold;
	color: ${ThemeProject.primary};
`
