import React, { useState } from 'react'
import { PopOverCP } from 'submodules/nerit-framework-ui/common/components/pop-over/PopOverCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DescriptionCP } from 'submodules/nerit-framework-ui/common/components/description/DescriptionCP'
import { AlertExamCountImagesCP } from 'modules/exams/components/exams/alert-exam-count-images/AlertExamCountImagesCP'
import styled from 'styled-components'
import { ExamResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamResponseDTO'

interface ICPProps {
	exam: ExamResponseDTO
}

/**
 */
export function PopoverExamImageDetailsCP(props: ICPProps): JSX.Element {
	const [isVisible, setIsVisible] = useState<boolean>(false)

	return (
		<PopOverCP
			trigger={'click'}
			placement={'bottomRight'}
			visible={isVisible}
			title={'Detalhes das imagens do Exame'}
			onClose={() => setIsVisible(false)}
			content={
				<WrapperSCP>
					<AlertExamCountImagesCP
						examCode={props.exam.code}
						examId={props.exam.id}
						imageData={props.exam.imageData}
						examDescription={props.exam.description}
						patientName={props.exam.patient.name}
						buttonsConfig={{
							hideExamDetails: true,
							hideDownload: true,
						}}
					/>

					<DescriptionCP
						numberOfColumns={1}
						items={[
							{
								label: 'StudyID',
								text: props.exam.studyId,
							},
							{
								label: 'Accession Number',
								text: props.exam.extraData?.accessionNumber ?? '-',
							},
							{
								label: 'Total de Imagens',
								text: props.exam.imageData.totalImages ?? 0,
							},
							{
								label: 'Imagens DICOM?',
								text: !!props.exam.imageData.isDicom ? (props.exam.imageData.isDicom ? 'Sim' : 'Não') : '-',
							},
							{
								label: 'Versão de Metadados',
								text: props.exam.imageData.metadata?.version ?? '-',
							},
						]}
					/>
				</WrapperSCP>
			}
		>
			<ButtonCP icon={'picture'} size={'small'} tooltip={'Ver dados do estudo'} tooltipPlacement={'topRight'} onClick={() => setIsVisible(true)} />
		</PopOverCP>
	)
}

const WrapperSCP = styled.div`
	min-width: 500px;
`
