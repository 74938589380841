import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'

interface ICPProps<FModelTP extends FormModel = any> {
	label?: string
	fieldName?: keyof FModelTP
	formStateManager?: IFormStateManager<FModelTP>
	required?: boolean
}

/**
 */
export function SelectExamSlaCP<FModelTP extends FormModel = any>(props: ICPProps): JSX.Element {
	return (
		<SelectCP
			label={props.label ?? 'SLA'}
			fieldName={props.fieldName}
			formStateManager={props.formStateManager}
			allowClear={true}
			required={props.required}
			options={[
				{
					label: 'Atras.',
					value: 0,
				},
				{
					label: '< 2h',
					value: 2,
				},
				{
					label: '< 6h',
					value: 6,
				},
				{
					label: '< 24h',
					value: 24,
				},
			]}
		/>
	)
}
