import React from 'react'
import { ButtonDeleteAuditsCP } from 'modules/quality-assurance/components/buttons/button-delete-audits/ButtonDeleteAuditsCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonCreateAuditsCP } from 'modules/quality-assurance/components/button-create-audits/ButtonCreateAuditsCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ButtonExportTableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/inner/ButtonExportTableFromApiCP'
import { AuditsRequests } from 'submodules/beerads-sdk/services/exams/audits/AuditsRequests'
import { AuditSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/audits/dtos/request/AuditSearchRequestDTO'
import { ButtonMultiActionDownloadExamDicomCP } from 'modules/exams/components/exams/button-download-exam/button-multi-action-dicom-download/ButtonMultiActionDownloadExamDicomCP'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	auditCodes: number[]
	examIds: string[]
	onChangeAnyData: () => void
	filterFormStateManager: AuditSearchRequestDTO
}

/**
 */
export function ToolbarTableAuditsICP(props: ICPProps): JSX.Element {
	return (
		<ButtonGroupCP mode={'separeted'}>
			<ButtonExportTableFromApiCP
				requestConfigTP={() => AuditsRequests.export(props.filterFormStateManager)}
				fileName={'auditorias'}
				buttonSize={'default'}
			/>

			{!ArrayUtils.isEmpty(props.auditCodes) && (
				<>
					<ButtonDeleteAuditsCP auditCodes={props.auditCodes} onDelete={props.onChangeAnyData} appearance={{ showLabel: true }} />

					<ButtonCreateAuditsCP
						doctorGroupCode={AppStateUtils.getCurrentDoctorGroup()!.code}
						audit={{
							codes: props.auditCodes,
						}}
						onSave={props.onChangeAnyData}
						appearance={{
							size: 'small',
							type: 'default',
						}}
					/>

					{DoctorGroupPermissionUtils.canSeeBulkExamData() && (
						<ButtonMultiActionDownloadExamDicomCP
							downloadConfig={{
								multiple: {
									examIds: props.examIds,
								},
								downloadBy: 'user',
							}}
							size="small"
						/>
					)}

					<TextCP
						text={`${props.auditCodes.length} auditoria(s) selecionada(s)`}
						size={'small'}
						fontStyle={'italic'}
						color={ThemeProject.gray300}
						marginLeft={10}
					/>
				</>
			)}
		</ButtonGroupCP>
	)
}
