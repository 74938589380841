import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ContentCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/content/ContentCP'
import styled from 'styled-components'
import { IntercomUtils } from 'submodules/nerit-framework-ui/common/components/intercom/inner/IntercomUtils'
import { IntercomCP } from 'app/components/intercom/IntercomCP'
import { IntercomEventEnum } from 'app/components/intercom/inner/IntercomEventEnum'

/**
 */
export function ScreenContentDevIntercom(): JSX.Element {
	return (
		<ContentCP overflowVertical={true}>
			<IntercomCP forceShow={true} />

			<WrapperSCP>
				<div>
					<ButtonCP onClick={() => IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_OPEN)}>TrackEvent - Abriu Leorad</ButtonCP>

					<ButtonCP onClick={() => IntercomUtils.trackEvent(IntercomEventEnum.LEORAD_EXPORT_MEDICAL_REPORT)}>
						TrackEvent - Laudou com Leorad
					</ButtonCP>
				</div>

				<div></div>
			</WrapperSCP>
		</ContentCP>
	)
}

const WrapperSCP = styled.div`
	padding: 20px;
	display: flex;
	justify-content: space-between;

	button {
		width: 400px;
		margin-top: 20px;

		span {
			width: 100%;
		}
	}
`
