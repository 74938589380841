import { TableClinicDoctorGroupsCP } from 'modules/clinic/components/table-clinic-doctor-groups/TableClinicDoctorGroupsCP'
import React from 'react'

interface ICPProps {
	clinicCode: number
}

/**
 * Conteúdo do submenu grupo de médicos da tela de configurações da clinica
 */
export function ClinicsAdminDoctorGroupsScreenContent(props: ICPProps): JSX.Element {
	return (
		<>
			<TableClinicDoctorGroupsCP clinicCode={props.clinicCode} reloadTable={props.clinicCode} />
		</>
	)
}
