import { ColumnProps } from 'antd/lib/table'
import React from 'react'
import { Button } from 'antd'
import { DateUtils } from '_old/main/common/utils/DateUtils'
import { DateTimeFormatEnum } from '_old/main/common/enums/DateFormatEnum'
import ButtonGroup from 'antd/es/button/button-group'
import { TagClinicTypeCP } from 'modules/clinic/components/tag-clinic-type/TagClinicTypeCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { FontAwsomeIconCP } from 'submodules/nerit-framework-ui/common/components/icon/FontAwsomeIconCP'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { TagCP } from '_old/main/common/components/tag/TagCP'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'
import { ClinicBilledByLabelEnum } from 'submodules/beerads-sdk/services/clinics/clinics/enums/ClinicBilledByEnum'
import { ClinicResponseDTO } from 'submodules/beerads-sdk/services/clinics/clinics/dtos/response/ClinicResponseDTO'
import { InputMaskTypeEnum } from 'submodules/nerit-framework-ui/common/form-state-manager/enums/InputMaskTypeEnum'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

export const TableClinicsUtils = {
	getColumns(onEdit: (clinic: ClinicResponseDTO) => void, onEditLogo: (clinic: ClinicResponseDTO) => void): Array<ColumnProps<ClinicResponseDTO>> {
		return [
			{
				title: 'Cód.',
				key: 'code',
				render: (text, item) => item.code,
			},
			{
				title: 'Ativo',
				key: 'active',
				render: (text, item) => (item.active ? 'Sim' : 'Não'),
			},
			{
				title: 'Tipo',
				render: (text, item) => <TagClinicTypeCP type={item.type} />,
			},
			{
				title: 'Data de Criação',
				key: 'date',
				render: (text, item) => DateUtils.getFormatted(item.createdAt, DateTimeFormatEnum.BR_WITHOUT_TIME),
			},
			{
				title: 'Cliente',
				key: 'name',
				render: (text, item) => (
					<>
						<TextCP text={item.fantasyName} />
						<TextCP text={`Contato: ${item.responsible.name}`} color={ThemeProject.gray300} />
						<TextCP text={`CNPJ: ${MaskUtils.applyMask2(InputMaskTypeEnum.CPFCNPJ, item.cnpj)}`} color={ThemeProject.gray300} />
					</>
				),
			},
			{
				title: 'Faturado por',
				key: 'billed',
				render: (text, item) => (
					<>
						<span>{item.billingConfig?.billedBy ? ClinicBilledByLabelEnum[item.billingConfig.billedBy] : undefined}</span>
						{item.billingConfig?.hasWhatsappIntegration && (
							<TagCP
								color={'green'}
								content={
									<FlexCP>
										<FontAwsomeIconCP type={faWhatsapp} />
										<TextCP text={'Tem módulo Whatsapp'} marginLeft={5} />
									</FlexCP>
								}
							/>
						)}
					</>
				),
			},
			{
				key: 'action',
				width: 130,
				render: (text, item) => (
					<FlexCP justifyContent={'flex-end'}>
						<ButtonGroup style={{ marginRight: '10px' }}>
							<Button key={'editar'} icon={'edit'} onClick={() => onEdit(item)} title={'Editar dados da Unidade Hospitalar'} />
							<Button key={'image'} icon={'picture'} onClick={() => onEditLogo(item)} title={'Editar Logo'} />
						</ButtonGroup>
						<ButtonGroup>
							<Button
								icon={'dollar'}
								href={item.billingConfig?.billingPageUrl}
								title={'Ver Fatura'}
								target={'_blank'}
								disabled={!item.billingConfig?.billingPageUrl}
							/>
						</ButtonGroup>
					</FlexCP>
				),
			},
		]
	},
}
