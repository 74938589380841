import { LockIconCP } from '_old/main/common/components/icons/LockIconCP'
import { MailIconCP } from '_old/main/common/components/icons/MailIconCP'

import { AuthActions } from 'app/utils/AuthActions'
import { UserLoginFormValidator } from 'modules/auth/components/user-login-form/inner/UserLoginFormValidator'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { UserLoginRequestDTO } from 'submodules/beerads-sdk/services/auth/dtos/requests/UserLoginRequestDTO'
import { AuthRequests } from 'submodules/beerads-sdk/services/auth/AuthRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { AuthResponseDTO } from 'submodules/beerads-sdk/services/auth/dtos/responses/AuthResponseDTO'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PublicRouter } from 'app/routers/public/PublicRouter'
import { PrivateUserRouter } from 'app/routers/private/PrivateUserRouter'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'
import { OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM } from 'app/components/menu-item-profile/MenuItemProfileCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import styled from 'styled-components'
import * as md5 from 'md5'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

/**
 */
export function UserLoginFormCP(): JSX.Element {
	const [formValidator] = useState<UserLoginFormValidator>(new UserLoginFormValidator())
	const formStateManager = useFormStateManager(formValidator)

	const screenSize = useScreenSize()
	const loginRequest = useRequest<AuthResponseDTO>()

	useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

	async function onFormSubmit(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(formStateManager))) {
			return
		}

		const formValues = formStateManager.getFormValues()!

		const loginDto: UserLoginRequestDTO = {
			email: formValues.email,
			password: md5(formValues.password),
		}

		loginRequest.runRequest(AuthRequests.login(loginDto))
	}

	/**
	 * Retorno da requisao de login
	 */
	function onLoginRequestChange(): void {
		const isValidRequest = RequestUtils.isValidRequestReturn(
			{
				request: loginRequest,
				onUnauthorized: (request) => {
					if (request?.error?.data?.message?.message) {
						NotificationHelper.error('Erro ao efetuar login', request.error.data.message.message)
					}
				},
			},
			loginRequest.error?.data?.message,
		)

		if (!isValidRequest) {
			return
		}

		const result = loginRequest.responseData!
		AuthActions.setLoggedUser(result)
		NeritFrameworkRoutingHelper.historyPush(
			`${PrivateUserRouter.HOME}?${OPEN_DIGITAL_CERTIFICATE_AUTH_PARAM}=${result.user.hasDigitalCertificate ?? false}`,
		)
	}

	return (
		<FormContainerSCP>
			<InputCP
				label={'E-mail / Login'}
				fieldName={'email'}
				formStateManager={formStateManager}
				required={true}
				onFormSubmit={onFormSubmit}
				icon={<MailIconCP />}
			/>
			{screenSize.xs && <div style={{ height: 15 }} />}
			<InputCP
				label={'Senha'}
				type={'password'}
				fieldName={'password'}
				formStateManager={formStateManager}
				required={true}
				onFormSubmit={onFormSubmit}
				icon={<LockIconCP />}
			/>

			<ButtonWrapperSCP>
				<ButtonCP onClick={() => NeritFrameworkRoutingHelper.historyPush(PublicRouter.FORGOT_PASSWORD)}>Esqueci minha senha</ButtonCP>

				<ButtonCP type={'primary'} loading={loginRequest.isAwaiting} onClick={onFormSubmit}>
					Entrar
				</ButtonCP>
			</ButtonWrapperSCP>
		</FormContainerSCP>
	)
}

const FormContainerSCP = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 365px;
`

const ButtonWrapperSCP = styled.div`
	width: 100%;
	margin-top: 20px;
	padding: 10px 0;
	display: flex;
	justify-content: space-between;
`
