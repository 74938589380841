import React from 'react'
import { PatientPortalExamsSearchResponseDTO } from 'submodules/beerads-sdk/services/patient-portal/dtos/response/PatientPortalExamsSearchResponseDTO'
import { CardPatientExamCP } from 'modules/patient-portal/components/card-patient-exam/CardPatientExamCP'
import { TitleCP } from 'submodules/nerit-framework-ui/common/components/title/TitleCP'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'

interface ICPProps {
	patientPortalData: PatientPortalExamsSearchResponseDTO
	onChangeData: () => void
}

/**
 * Conteudo principal da tela de resultado de exame de paciente.
 */
export function PatientExamCP(props: ICPProps): JSX.Element {
	return (
		<>
			<CardPatientExamCP exam={props.patientPortalData.currentExam} onChangeData={props.onChangeData} />

			{!ArrayUtils.isEmpty(props.patientPortalData.pastExams) && (
				<>
					<TitleCP underLine={true} marginLeft={20} marginTop={40}>
						Exames Anteriores
					</TitleCP>
					{props.patientPortalData.pastExams.map((exam) => (
						<CardPatientExamCP exam={exam} onChangeData={props.onChangeData} />
					))}
				</>
			)}
		</>
	)
}
