import React from 'react'
import { LayoutSubmenuCP } from 'submodules/nerit-framework-ui/common/components/screen/layout-with-submenu/layout/LayoutSubmenuCP'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import {
	REPORTS_SUB_MENU_CLINIC_KEY,
	REPORTS_SUB_MENU_DOCTOR_GROUP_KEY,
	REPORTS_SUB_MENU_DOCTOR_KEY,
	ReportsScreenUtils,
	ReportsViewTP,
} from 'modules/reports/screens/screen-reports/inner/ReportsScreenUtils'
import { ClinicPermissionUtils } from 'modules/auth/permissions/ClinicPermissionUtils'
import { DoctorGroupPermissionUtils } from 'modules/auth/permissions/DoctorGroupPermissionUtils'
import { CommonPermissionUtils } from 'modules/auth/permissions/CommonPermissionUtils'
import { UserTypeEnum } from 'submodules/beerads-sdk/services/people/people/enums/UserTypeEnum'
import { ScreenResultCP } from 'submodules/nerit-framework-ui/common/components/screen/screen-result/ScreenResultCP'

/**
 */
export function ScreenReports(): JSX.Element {
	let defaultView: ReportsViewTP | undefined

	if (ClinicPermissionUtils.hasAnyPermissionsInAnyClinic(['isAdmin', 'isUser'])) defaultView = 'clinicMainView'
	else if (DoctorGroupPermissionUtils.hasAnyPermissionsInAnyDoctorGroup(['isDoctorGroupAdmin', 'isDoctorGroupUser'])) defaultView = 'doctorGroupMainView'
	else if (CommonPermissionUtils.canAccessByType(UserTypeEnum.DOCTOR)) defaultView = 'doctorMainView'

	if (!defaultView) return <ScreenResultCP title={'Ops'} message={'Não encontramos o seu perfil de acess'} status={'404'} />

	return (
		<LayoutSubmenuCP<ReportsViewTP>
			titleBrowser={'Relatórios'}
			loggedDomain={AppStateUtils.getDomain()}
			menuTitle={'Relatórios'}
			defaultSubmenu={defaultView}
			defaultOpenKeys={[REPORTS_SUB_MENU_CLINIC_KEY, REPORTS_SUB_MENU_DOCTOR_GROUP_KEY, REPORTS_SUB_MENU_DOCTOR_KEY]}
			submenuConfigs={ReportsScreenUtils.getSubMenuConfigs()}
		/>
	)
}
