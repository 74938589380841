import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import React from 'react'
import { ColumnExamPendingActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/inner/ColumnExamPendingActionsICP'
import { ColumnExamAwaitingActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/inner/ColumnExamAwaitingActionsICP'
import { ColumnExamPendingAnalysisActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/inner/ColumnExamPendingAnalysisActionsICP'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ColumnExamDoneActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/inner/ColumnExamDoneActionsICP'
import { ColumnExamArchivedActionsICP } from 'modules/exams/components/exams/table-exams/columns/actions/inner/ColumnExamArchivedActionsICP'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'

interface ICPProps {
	exam: ExamListItemResponseDTO
	examStatusGroup: ExamStatusGroupEnum
	onSave: () => void
	viewAsDoctor: boolean
	searchExamDto?: ExamSearchRequestDTO
}

/**
 * Renderiza coluna de ações da tabela de exames.
 */
export function ColumnExamActionsICP(props: ICPProps): JSX.Element {
	// Obtem as colunas para cada tipo de status
	if (props.examStatusGroup === ExamStatusGroupEnum.PENDING) {
		return <ColumnExamPendingActionsICP viewAsDoctor={props.viewAsDoctor} exam={props.exam} onSave={props.onSave} />
	}

	if (props.examStatusGroup === ExamStatusGroupEnum.PENDING_ANALYSIS) {
		return <ColumnExamPendingAnalysisActionsICP viewAsDoctor={props.viewAsDoctor} exam={props.exam} onSave={props.onSave} />
	}

	if (props.examStatusGroup === ExamStatusGroupEnum.DONE) {
		return <ColumnExamDoneActionsICP exam={props.exam} onChangeData={props.onSave} searchExamDto={props.searchExamDto} />
	}

	if (props.examStatusGroup === ExamStatusGroupEnum.AWAITING) {
		return (
			<ColumnExamAwaitingActionsICP
				exam={props.exam}
				onChangeData={props.onSave}
				viewAsDoctor={props.viewAsDoctor}
				searchExamDto={props.searchExamDto}
			/>
		)
	}

	if (props.examStatusGroup === ExamStatusGroupEnum.ARCHIVED) {
		return <ColumnExamArchivedActionsICP exam={props.exam} onChangeData={props.onSave} />
	}

	return <></>
}
