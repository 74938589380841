import React, { useEffect, useState } from 'react'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { MedicalReportFormModel } from 'modules/exams/screens/screen-medical-report/inner/MedicalReportFormModel'
import { ArrayUtils } from 'submodules/nerit-framework-utils/utils/ArrayUtils'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { AvcFormModel } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/form-model/AvcFormModel'
import { AvcQuestion1ICP } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/sections/AvcQuestion1ICP'
import { AvcMedicalReportFormModelConverter } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/form-model/AvcMedicalReportFormModelConverter'
import { AvcContentReportTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/AvcContentReportTP'
import styled from 'styled-components'
import { AvcQuestion2ICP } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/sections/AvcQuestion2ICP'
import { AvcQuestion3ICP } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/sections/AvcQuestion3ICP'
import { AvcQuestion4ICP } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/sections/AvcQuestion4ICP'
import { AvcQuestion5ICP } from 'modules/exams/components/medical-report/editor-avc-medical-report/inner/sections/AvcQuestion5ICP'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

interface ICPProps {
	formStateManager: IFormStateManager<MedicalReportFormModel>
}

/**
 */
export function EditorAvcMedicalReportCP(props: ICPProps): JSX.Element {
	const [formValidator, setFormValidator] = useState<AvcFormModel>()
	const formStateManager = useFormStateManager(formValidator)

	useEffect(init, [props.formStateManager.getFieldValue('medicalReports')])

	useEffect(onChange, [formStateManager.getFormValues()])

	/**
	 * Inicializa.
	 */
	function init(): void {
		const medicalReports = props.formStateManager.getFieldValue('medicalReports')
		if (ArrayUtils.isEmpty(medicalReports)) {
			return
		}

		setFormValidator(AvcMedicalReportFormModelConverter.toFormModel(medicalReports[0].json as AvcContentReportTP))
	}

	/**
	 * Ao mudar alguma valor
	 */
	function onChange(): void {
		const avcMedicalReport = AvcMedicalReportFormModelConverter.toContentTP(formStateManager)
		if (!avcMedicalReport) return

		const medicalReports: MedicalReportContentTP[] = props.formStateManager.getFieldValue('medicalReports')
		if (ArrayUtils.isEmpty(medicalReports)) return NotificationHelper.error('Ops!', 'Laudo não inicializado')

		medicalReports[0].json = avcMedicalReport
	}

	return (
		<ReportWrapperSCP>
			<AvcQuestion1ICP formStateManager={formStateManager} />
			<AvcQuestion2ICP formStateManager={formStateManager} />
			<AvcQuestion3ICP formStateManager={formStateManager} />
			<AvcQuestion4ICP formStateManager={formStateManager} />
			<AvcQuestion5ICP formStateManager={formStateManager} />
		</ReportWrapperSCP>
	)
}

const ReportWrapperSCP = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background: ${ThemeFrameworkCommon.browserDefaultBackgroundLight};
`
