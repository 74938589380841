import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DrawerCreateExamCP } from 'modules/exams/components/exams/drawer-create-exam/DrawerCreateExamCP'
import { useScreenSize } from 'submodules/nerit-framework-ui/common/responsiveness/use-screen-size/UseScreenSize'

interface ICPProps {
	onCreateExam: () => void
}

/**
 */
export function ButtonCreateExam(props: ICPProps): JSX.Element {
	const screenSize = useScreenSize()

	const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP icon={'plus'} onClick={() => setIsDrawerVisible(true)} marginRight={20}>
				{screenSize.sm || screenSize.xs ? undefined : 'Criar Exame'}
			</ButtonCP>

			<DrawerCreateExamCP
				onClose={(dataChanged) => {
					setIsDrawerVisible(false)
					if (dataChanged) props.onCreateExam()
				}}
				visible={isDrawerVisible}
			/>
		</>
	)
}
