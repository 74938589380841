import { BeeRadsApiEnum } from 'submodules/beerads-sdk/common/BeeRadsApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DoctorOnMedicalTimetableResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorOnMedicalTimetableResponseDTO'
import { DoctorOnMedicalTimetableDeleteRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableDeleteRequestDTO'
import { DoctorOnMedicalTimetableSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableSaveRequestDTO'
import { MedicalTimetableStructureResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableStructureResponseDTO'
import { MedicalTimetableStructureSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSearchRequestDTO'
import { MedicalTimetableStructureSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableStructureSaveRequestDTO'
import { DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO'
import { MedicalTimetableDoctorEventsSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/MedicalTimetableDoctorEventsSearchRequestDTO'
import { MedicalTimetableDoctorEventResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/MedicalTimetableDoctorEventResponseDTO'
import { DoctorOnMedicalTimetableUpdateRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorOnMedicalTimetableUpdateRequestDTO'
import { DoctorScheduleSearchRequestDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/request/DoctorScheduleSearchRequestDTO'
import { DoctorScheduleSearchResponseDTO } from 'submodules/beerads-sdk/services/doctor-groups/medical-timetable/dtos/response/DoctorScheduleSearchResponseDTO'

export class MedicalTimetableRequests {
	public static CONTROLLER_ROOT = 'medical-timetables'
	public static GET_STRUCTURE_EP = 'get-structure'
	public static SAVE_MEDICAL_TIME_TABLE_STRUCTURE_EP = 'save-structure'
	public static SEARCH_DOCTORS_ON_MEDICAL_TIMETABLE_EP = 'doctors-on-medical-timetable'
	public static SEARCH_DOCTOR_EVENTS_EP = 'doctor-events'
	public static ADD_DOCTOR_TO_SCHEDULE_EP = 'add-doctor-to-schedule'
	public static UPDATE_DOCTOR_TO_SCHEDULE_EP = 'update-doctor-to-schedule/:code'
	public static DELETE_DOCTOR_TO_SCHEDULE_EP = 'delete-doctor-to-schedule'
	public static ADD_DOCTOR_RECURRENTLY_TO_SCHEDULE_EP = 'add-doctor-recurrently-to-schedule'
	public static GET_DOCTOR_SCHEDULED_EP = 'get-doctor-scheduled'

	static getStructure = (dto: MedicalTimetableStructureSearchRequestDTO): RequestConfigTP<ListResponseDTO<MedicalTimetableStructureResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.GET_STRUCTURE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static getDoctorScheduled = (dto: DoctorScheduleSearchRequestDTO): RequestConfigTP<DoctorScheduleSearchResponseDTO> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.GET_DOCTOR_SCHEDULED_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static saveStructure = (dto: MedicalTimetableStructureSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.SAVE_MEDICAL_TIME_TABLE_STRUCTURE_EP}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static searchDoctorsOnMedicalTimetable = (
		dto: MedicalTimetableStructureSearchRequestDTO,
	): RequestConfigTP<ListResponseDTO<DoctorOnMedicalTimetableResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.SEARCH_DOCTORS_ON_MEDICAL_TIMETABLE_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static searchDoctorEvents = (
		dto: MedicalTimetableDoctorEventsSearchRequestDTO,
	): RequestConfigTP<ListResponseDTO<MedicalTimetableDoctorEventResponseDTO>> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.SEARCH_DOCTOR_EVENTS_EP}`,
		method: HttpMethodEnum.GET,
		params: dto,
	})

	static addDoctorToSchedule = (dto: DoctorOnMedicalTimetableSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.ADD_DOCTOR_TO_SCHEDULE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static addDoctorRecurrentlyToSchedule = (dto: DoctorsOnMedicalTimetableRecurrenceSaveRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.ADD_DOCTOR_RECURRENTLY_TO_SCHEDULE_EP}`,
		method: HttpMethodEnum.POST,
		params: dto,
	})

	static deleteDoctorsOnMedicalTimetable = (dto: DoctorOnMedicalTimetableDeleteRequestDTO): RequestConfigTP => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.DELETE_DOCTOR_TO_SCHEDULE_EP}`,
		method: HttpMethodEnum.DELETE,
		params: dto,
	})

	static updateDoctorToSchedule = (doctorMedicalTimeTableSpotCode: number, dto: DoctorOnMedicalTimetableUpdateRequestDTO): RequestConfigTP<void> => ({
		baseUrlType: BeeRadsApiEnum.BEERADS_API,
		url: `${MedicalTimetableRequests.CONTROLLER_ROOT}/${MedicalTimetableRequests.UPDATE_DOCTOR_TO_SCHEDULE_EP.replace(
			':code',
			doctorMedicalTimeTableSpotCode.toString(),
		)}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})
}
