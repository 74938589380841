import React from 'react'
import { CustomIconContainerICP } from '_old/main/common/components/icons/inner/CustomIconContainerICP'
import { IIconProps } from 'submodules/nerit-framework-ui/common/components/icon/inner/IIconProps'
import { ThemeFrameworkCommon } from 'submodules/nerit-framework-ui/theme/framework/ThemeFrameworkCommon'

/**
 * ICONE
 * Microfone.
 *
 * @author hjcostabr
 */
export const MicrophoneIconCP = (props: IIconProps): JSX.Element => {
	const DEFAULT_COLOR = `${ThemeFrameworkCommon.browserDefaultColorDark}`

	return <CustomIconContainerICP {...props}>{getSvg(props.color ?? DEFAULT_COLOR)}</CustomIconContainerICP>
}

const getSvg = (color: string): JSX.Element => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" width="50" height="50">
			<path
				// tslint:disable-next-line: max-line-length
				d="M412.113 170.747c-6.637 0-12.02 5.381-12.02 12.02v75.104c0 79.452-64.639 144.09-144.092 144.09S111.91 337.321 111.91 257.87v-75.104c0-6.639-5.383-12.02-12.02-12.02-6.639 0-12.02 5.381-12.02 12.02v75.104c0 88.666 68.993 161.512 156.111 167.696v62.395h-62.174c-6.637 0-12.02 5.381-12.02 12.02 0 6.639 5.382 12.02 12.02 12.02h148.386c6.637 0 12.02-5.381 12.02-12.02 0-6.639-5.382-12.02-12.02-12.02H268.02v-62.395c87.119-6.184 156.111-79.031 156.111-167.696v-75.104c.002-6.638-5.381-12.019-12.018-12.019z"
				fill={color}
			/>

			<path
				// tslint:disable-next-line: max-line-length
				d="M264.011 0h-16.02c-54.949 0-99.653 44.704-99.653 99.653V265.88c0 54.949 44.704 99.653 99.653 99.653h16.02c54.949 0 99.653-44.704 99.653-99.653V99.653C363.664 44.704 318.96 0 264.011 0zm75.614 130.853h-43.572c-6.639 0-12.02 5.381-12.02 12.02 0 6.639 5.381 12.02 12.02 12.02h43.572v33.458h-43.572c-6.639 0-12.02 5.381-12.02 12.02s5.381 12.02 12.02 12.02h43.572v33.46h-43.572c-6.639 0-12.02 5.381-12.02 12.02s5.381 12.02 12.02 12.02h43.464c-2.091 39.836-35.157 71.603-75.505 71.603h-16.02c-40.348 0-73.414-31.767-75.505-71.603h43.464c6.639 0 12.02-5.381 12.02-12.02s-5.381-12.02-12.02-12.02h-43.572v-33.46h43.572c6.639 0 12.02-5.381 12.02-12.02s-5.381-12.02-12.02-12.02h-43.572v-33.458h43.572c6.639 0 12.02-5.381 12.02-12.02 0-6.639-5.381-12.02-12.02-12.02h-43.572v-31.2c0-29.964 17.52-55.914 42.854-68.143v33.983c0 6.639 5.382 12.02 12.02 12.02s12.02-5.381 12.02-12.02V24.558c2.863-.331 30.595-.331 33.458 0v40.935c0 6.639 5.381 12.02 12.02 12.02 6.637 0 12.02-5.381 12.02-12.02V31.51c25.334 12.229 42.854 38.177 42.854 68.142v31.201z"
				fill={color}
			/>
		</svg>
	)
}
