import React, { useState } from 'react'
import { ModalMedicalReportContentCP } from 'modules/exams/components/medical-report/modal-medical-report-content/ModalMedicalReportContentCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { MedicalReportContentTP } from 'submodules/beerads-sdk/services/exams/medical-reports/types/MedicalReportContentTP'

interface ICPProps {
	medicalReportContent: MedicalReportContentTP
	onCopy?: () => void
}

/**
 */
export function ButtonShowMedicalReportContentCP(props: ICPProps): JSX.Element {
	const [isMedicalReportContentModalVisible, setIsMedicalReportContentModalVisible] = useState<boolean>(false)

	return (
		<>
			<ButtonCP icon={'audit'} size={'small'} onClick={() => setIsMedicalReportContentModalVisible(true)} type={'primary'} />

			<ModalMedicalReportContentCP
				visible={isMedicalReportContentModalVisible}
				onCancel={() => setIsMedicalReportContentModalVisible(false)}
				onCopy={
					!!props.onCopy
						? () => {
								setIsMedicalReportContentModalVisible(false)
								props.onCopy!()
						  }
						: undefined
				}
				medicalReportContent={props.medicalReportContent}
			/>
		</>
	)
}
