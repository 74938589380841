import React from 'react'
import { IFormItemCommonProps } from 'submodules/nerit-framework-ui/common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { SimpleAutocompleteFromApi } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/SimpleAutocompleteFromApi'
import { ClinicsRequests } from 'submodules/beerads-sdk/services/clinics/clinics/ClinicsRequests'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
	clinicCode: number
}

/**
 */
export function SelectExamRequestingDoctorCP<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {
	return (
		<SimpleAutocompleteFromApi
			label={'Médico Solicitante'}
			requestConfigGetter={() => ClinicsRequests.namesRequestingDoctor(props.clinicCode)}
			formStateManager={props.formStateManager}
			fieldName={props.fieldName}
		/>
	)
}
