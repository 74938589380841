import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ExamListItemResponseDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/response/ExamListItemResponseDTO'
import { ExamsRequests } from 'submodules/beerads-sdk/services/exams/exams/ExamsRequests'
import styled from 'styled-components'
import { ExamSearchRequestDTO } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/ExamSearchRequestDTO'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ExamFilterFormModel } from 'modules/exams/components/exams/table-exams/filters/ExamFilterFormModel'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { ExamFiltersFormModelConverter } from 'modules/exams/components/exams/table-exams/filters/ExamFiltersFormModelConverter'
import { ExamSearchOrderingEnum } from 'submodules/beerads-sdk/services/exams/exams/dtos/request/inner/ExamSearchOrderingEnum'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { AvatarExamModalityCP } from 'modules/exams/components/exams/avatar-exam-modality/AvatarExamModalityCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonCreateAuditsCP } from 'modules/quality-assurance/components/button-create-audits/ButtonCreateAuditsCP'
import { ExamStatusUtils } from 'submodules/beerads-sdk/services/exams/exams/utils/ExamStatusUtils'
import { ExamStatusGroupEnum } from 'submodules/beerads-sdk/services/exams/exams/enums/ExamStatusGroupEnum'
import { ExamsToAuditTableBulkActionsBarICP } from 'modules/quality-assurance/components/table-exams-to-audit/bulk-actions-bar/ExamsToAuditTableBulkActionsBarICP'

interface ICPProps {
	reloadTable: number
	onLoading?: (isLoading: boolean) => void
	filterStateManager?: IFormStateManager<ExamFilterFormModel>
}

/**
 */
export function TableExamsToAuditCP(props: ICPProps): JSX.Element {
	const [selectedExamCodes, setSelectedExamCodes] = useState<number[]>([])

	const [reload, setReload] = useState<number>(props.reloadTable)
	useEffect(() => setReload(props.reloadTable), [props.reloadTable])

	return (
		<WrapperSCP>
			<ExamsToAuditTableBulkActionsBarICP
				doctorGroupCode={props.filterStateManager?.getFieldValue('doctorGroupCode')}
				selectedExamCodes={selectedExamCodes}
				onSave={() => {
					setSelectedExamCodes([])
					setReload(TableUtils.getReloadNumber())
				}}
			/>

			<TableFromApiCP<ExamListItemResponseDTO, ExamSearchRequestDTO>
				appearance={{
					showReloadButton: true,
					reloadButtonSize: 'default',
					wrappedOnCard: true,
					recordLabel: 'exames',
					showTotalOnHeader: true,
				}}
				shouldLoadData={reload}
				onLoading={props.onLoading}
				apiConfig={{
					hasPagination: true,
					pageSize: 20,
					filters: {
						...ExamFiltersFormModelConverter.formatDto(props.filterStateManager?.getFormValues()),
						considerOnlyWithoutAudit: true,
						statuses: ExamStatusUtils.examStatusOfExamStatusGroupMap.get(ExamStatusGroupEnum.DONE),
					},
					requestConfigTP: (filters) => ExamsRequests.search(filters!),
				}}
				row={{
					onSelect: {
						rowKey: 'code',
						selectedCodes: selectedExamCodes,
						onSelectRow: setSelectedExamCodes,
					},
				}}
				columns={[
					{
						key: 'doctor',
						title: 'Médico',
						render: (text, record) => record.doctor?.name,
					},
					{
						key: ExamSearchOrderingEnum.DATE,
						title: 'Data do Exame',
						render: (text, record) => DateUtils.formatDate(record.examDate, DateFormatEnum.BR_WITHOUT_TIME),
					},
					{
						title: 'Data do Laudo',
						render: (text, record) =>
							record.medicalReportDate ? DateUtils.formatDate(record.medicalReportDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
					},
					{
						key: ExamSearchOrderingEnum.PATIENT,
						title: 'Paciente',
						render: (text, record) => record.patient.name,
					},
					{
						key: ExamSearchOrderingEnum.EXAM,
						title: 'Exame',
						render: (text, record) => (
							<AvatarExamModalityCP modality={record.modality} description={record.description} size={28} fontSize={'small'} />
						),
					},
					{
						key: 'imgs',
						title: 'Imgs',
						align: 'center',
						render: (text, record) => record.imageData.totalImages ?? '-',
					},
					{
						key: ExamSearchOrderingEnum.CLINIC,
						title: 'Unidade Hospitalar',
						render: (text, record) => record.clinic.name,
					},
					{
						key: 'actions',
						render: (text, record) => (
							<ButtonGroupCP mode={'separeted'}>
								<ButtonCreateAuditsCP
									doctorGroupCode={props.filterStateManager?.getFieldValue?.('doctorGroupCode')}
									selectedExamCodes={[record.code]}
									onSave={() => setReload(TableUtils.getReloadNumber())}
									appearance={{
										size: 'small',
										type: 'default',
									}}
								/>
							</ButtonGroupCP>
						),
					},
				]}
				sorter={{
					initialSorter: props.filterStateManager?.getFieldValue('sorter'),
				}}
			/>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	position: relative;
`
