import { DoctorGroupFormModel } from 'modules/doctor-groups/components/doctor-groups/drawer-doctor-group-data/inner/DoctorGroupFormModel'
import { FormDoctorGroupCertificateData } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/FormDoctorGroupCertificateData'
import { DoctorGroupDigitalCertificateDataFormModel } from 'modules/doctor-groups/components/doctor-groups/form-doctor-group-digital-certificate-data/inner/DoctorGrouṕDigitalCertificateDataFormModel'
import React from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
	formStateManager: IFormStateManager<DoctorGroupFormModel>
	digitalCertificateStateManager: IFormStateManager<DoctorGroupDigitalCertificateDataFormModel>
}

export function DoctorGroupDataConfigTabICP(props: ICPProps): JSX.Element {
	return (
		<>
			<InputCP
				type={'number'}
				label={'Número máximo de exames por médico'}
				fieldName={'maxExamsPerDoctor'}
				formStateManager={props.formStateManager}
				hint={{
					type: 'text',
					text: 'Caso deseje limitar a quantidade de exames que um médico pode pegar ao mesmo tempo.',
				}}
			/>

			<SwitchCP
				formStateManager={props.formStateManager}
				fieldName={'hasAdministrativeAnalysis'}
				label={'Possui Análise Administrativa?'}
				hint={{
					type: 'text',
					text: 'A Análise Administrativa é a interface entre o médico e a clínica.',
				}}
			/>

			<SwitchCP
				formStateManager={props.formStateManager}
				fieldName={'isAwaitingFirstStatus'}
				label={'Ir para fila de aguardando ao criar exame?'}
				hint={{
					type: 'text',
					text: 'Caso essa opção esteja marcada como sim, o sistema pula a triagem e vai direto para fila dos médicos, aguardando. Caso contrário, o exame irá para triagem.',
				}}
			/>

			<SwitchCP
				formStateManager={props.formStateManager}
				fieldName={'shouldValidateExamDescription'}
				label={'Devemos validar o nome do exame?'}
				hint={{
					type: 'text',
					text: 'Só serão aceitos nomes de exames pré-definidos pelo grupo. Deve-se cadastrar os exames na aba de configurações do grupo, e marcar a opção de não ir para fila aguardando como padrão.',
				}}
			/>

			<SwitchCP
				formStateManager={props.formStateManager}
				fieldName={'blockMultipleMedicalReportsOnExam'}
				label={'Bloquear múltiplos laudos?'}
				hint={{
					type: 'text',
					text: 'Quando o médico for laudar o exame, caso esteja habilitado, não será permitido criar mais de um laudo para o mesmo exame',
				}}
			/>

			<SwitchCP
				formStateManager={props.formStateManager}
				fieldName={'canSeeExamOutsideMedicalReport'}
				label={'Médico pode ver dados do exame fora do Laudo?'}
				hint={{
					type: 'text',
					text: 'Caso essa opção esteja habilitada o médico pode visualizar os dados do exame, incluindo as imagens, antes de entrar na tela do laudo',
				}}
			/>

			<SwitchCP formStateManager={props.formStateManager} fieldName={'hasDigitalCertificateData'} label={'Possui certificado digital?'} />
			{!!props.formStateManager.getFieldValue('hasDigitalCertificateData') && (
				<FormDoctorGroupCertificateData digitalCertificateStateManager={props.digitalCertificateStateManager} />
			)}
		</>
	)
}
