import { Menu } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { StringUtils } from 'submodules/nerit-framework-utils/utils/StringUtils'
import { IMultiActionButtonOption } from 'submodules/nerit-framework-ui/common/components/button/inner/IMultiActionButtonOption'

interface IMultiActionButtonItemICPProps {
	items: IMultiActionButtonOption[]
}

/**
 * COMPONENTE Botoes internos de botoes de multipla escolha.
 */
export function MultiActionButtonItemICP(props: IMultiActionButtonItemICPProps): JSX.Element | null {
	return (
		<WrapperSCP>
			<Menu>
				{props.items.map((item, index) => {
					return (
						<Menu.Item
							key={typeof item.label === 'string' ? StringUtils.getSlugStyleString(item.label) : index}
							onClick={item.onClick}
							disabled={item.disabled}
						>
							<RowSCP>
								{item.icon}

								<SideSpaceSCP>{item.label}</SideSpaceSCP>
							</RowSCP>
						</Menu.Item>
					)
				})}
			</Menu>
		</WrapperSCP>
	)
}

const WrapperSCP = styled.div`
	.ant-menu-item {
		height: 30px !important;
		line-height: 30px !important;
	}
	border: solid 1px #e8e8e8;
`

const RowSCP = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const SideSpaceSCP = styled.div`
	margin: 0 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: normal;
	overflow-wrap: normal;
	overflow: hidden;
	text-overflow: ellipsis;
`
