import FileSaver from 'file-saver'
import React, { useEffect } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { CommonReportsFilterFormModel } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModel'
import { DashboardRequests } from 'submodules/beerads-sdk/services/dashboard/dashboard/DashboardRequests'
import { CommonReportsFilterFormModelConverter } from 'modules/reports/components/filters-report-common/inner/CommonReportsFilterFormModelConverter'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { AppStateUtils } from 'app/redux/AppStateUtils'
import { ReportTypeEnum } from 'submodules/beerads-sdk/services/dashboard/dashboard/types/ReportTypeEnum'

interface ICPProps {
	reportType: ReportTypeEnum
	filterStateManager: IFormStateManager<CommonReportsFilterFormModel>
}

/**
 */
export function ButtonExportTableExamsDoneICP(props: ICPProps): JSX.Element {
	const exportRequest = useRequest<Blob>('none')
	useEffect(onExportRequestChange, [exportRequest.isAwaiting])

	/**
	 */
	async function doExport(): Promise<void> {
		if (!(await FormStateManagerUtils.validateRequiredFields(props.filterStateManager))) return

		const dto = CommonReportsFilterFormModelConverter.formatDto(props.reportType, props.filterStateManager.getFormValues())
		exportRequest.runRequest(DashboardRequests.medicalReportsDoneExport(dto))
	}

	/**
	 */
	function onExportRequestChange(): void {
		if (!RequestUtils.isValidRequestReturn(exportRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE)) return

		FileSaver.saveAs(exportRequest.responseData!, `${AppStateUtils.getDomainName()}_relatorio_exames_${new Date().getTime()}.csv`)
	}

	return (
		<ButtonCP onClick={doExport} icon={'file-excel'} loading={exportRequest.isAwaiting} tooltip={'Exportar resultados para excel'}>
			Exportar
		</ButtonCP>
	)
}
