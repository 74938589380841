import React from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { HeaderTitleICP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/inner/HeaderTitleICP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
	title: string
	children?: JSX.Element
}

/**
 */
export function HeaderVxCP(props: ICPProps): JSX.Element {
	return (
		<HeaderCP
			title={
				<FlexCP alignItems={'center'}>
					<img height={40} src={ThemeProject.logoHeaderUrl} alt={'Logo VX'} style={{ marginRight: '10px' }} />
					<HeaderTitleICP title={props.title} />
				</FlexCP>
			}
		>
			{props.children}
		</HeaderCP>
	)
}
